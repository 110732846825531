<template>
  <article class="app-hostingadmin-single-screen" :class="navigationAnimation">
    <side-screen-header-partial>{{$translate('app-hostingadmin-add-title')}}</side-screen-header-partial>
    <section class="content-container">

      <data-grid-partial class="content">
        <section class="data-grid-container">

          <section class="data-grid-single full">
            <h4>{{$translate('app-hostingadmin-certificatesActions-domains')}}</h4>
            <p>{{ content.domains.join(',') }}</p>
          </section>

          <section class="data-grid-single">
            <h4>{{$translate('app-hostingadmin-certificatesActions-companyProject')}}</h4>
            <p>{{ content.company }} {{ content.project }}</p>
          </section>

          <section class="data-grid-single">
            <h4>{{$translate('app-hostingadmin-certificatesActions-state')}}</h4>
            <p>{{ content.state }}</p>
          </section>
          

          <section class="data-grid-single">
            <h4>{{$translate('app-hostingadmin-certificatesActions-type')}}</h4>
            <p>{{ content.type }}</p>
          </section>

          <section class="data-grid-single">
            <h4>{{$translate('app-hostingadmin-certificatesActions-contentType')}}</h4>
            <p>{{ content.contentType }}</p>
          </section>

        </section>

        <section class="data-grid-container">
          
          <section class="data-grid-single">
            <h4>{{$translate('app-hostingadmin-certificatesActions-title-created')}}</h4>
            <p>{{ $date(content.history.created) }}</p>
          </section>
          
          <section class="data-grid-single">
            <h4>{{$translate('app-hostingadmin-certificatesActions-title-updated')}}</h4>
            <p>{{ $date(content.history.updated) }}</p>
          </section>
          
          <section class="data-grid-single">
            <h4>{{$translate('app-hostingadmin-certificatesActions-title-planned')}}</h4>
            <p>{{ $date(content.planned) }}</p>
          </section>

          <section class="data-grid-single full">
            <h4>{{$translate('app-hostingadmin-certificatesActions-attributes')}}</h4>
            <p>
              <template v-if="content.renew">{{ $translate('app-hostingadmin-certificatesActions-attributes-renew') }}</template>
              <template v-if="content.forceFile">{{ $translate('app-hostingadmin-certificatesActions-attributes-forceFile') }}</template>
              <template v-if="content.saveInVault">{{ $translate('app-hostingadmin-certificatesActions-attributes-saveInFault') }}</template>
            </p>
          </section>
          
        </section>

        <section v-if="content.results" class="data-grid-container">
          <header>
            <h3>{{$translate('app-hostingadmin-certificatesActions-results')}}</h3>
          </header>

          <section class="data-grid-single full">
            <h4>{{$translate('app-hostingadmin-certificatesActions-tries')}}</h4>
            <p>{{ content.retryCount || 0 }} / {{  content.maxRetries }}</p>
          </section>

          <table class="data-grid-single full table twoColumns">
            <tr v-for="(single, index) in content.results" :key="`result-${index}`">
              <td>{{single.error}}</td>
              <td>{{$translate(single.message)}}</td>
            </tr>
          </table>
        </section>

        <button-group-partial v-if="isActive">
          <button-partial doublecheck @confirmed="cancel">{{ $translate('app-hostingadmin-certificatesActions-cancel') }}</button-partial>
        </button-group-partial>

      </data-grid-partial>


    </section>
  </article>
</template>

<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import { SDKResponse } from '@/libs/StudioSDK';
import { CertificateAction } from '@lilaquadrat/studio/interfaces';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppHostingAdminSettingsScreen extends ExtComponent {

  componentName = 'app-hostingadmin-certificatesActions-company-single';

  get content(): CertificateAction {

    return this.$store.state.AppHostingAdminCertificatesActions.single;

  }

  get isActive() {

    return ['new', 'planned', 'retry'].includes(this.content.state);

  }

  async cancel() {

    await this.$store.dispatch('AppHostingAdminCertificatesActions/cancel', this.content._id);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store
      .dispatch('AppHostingAdminCertificatesActions/single', params.id);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<CertificateAction>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppHostingAdminCertificatesActions/single', preloadedData.data);
    return Promise.resolve();

  }


}
</script>
<style lang="less">


.app-hostingadmin-single-screen {


}
</style>
