<template>
  <section class="compare-module-editor open editor" :class="{ changed: safeContent.changed }">
    <module-editor-header-partial v-model="content" :position="position" :index="index" :type="type" @move="move($event)" />
    <crumbs-partial :type="safeContent.type" @clearCrumbs="unsetCurrentChild" />

    <section :class="{ optionsVisible: !child }" class="editor-module-container">
      <moduleList-partial v-if="!child" class="module-editor-navigation">
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('textblock')">{{$translate('textblock')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('structure')">{{$translate('structure')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('elements')">{{$translate('elements')}}</button-partial>
        </li>
      </moduleList-partial>

      <textblock-partial-editor v-if="child === 'textblock'" v-model="safeContent.textblock" :inactive="['text', 'list']" />

      <list-elements-partial-editor v-if="child === 'structure'" v-model="safeContent.structure" type="compareStructureElement-partial" :model="structureListModel" partial @input="update($event, 'structure')" />

      <list-elements-partial-editor
        v-if="child === 'elements'"
        v-model="safeContent.elements"
        type="compareElement-partial"
        :model="elementsListModel"
        :parent="safeContent.structure"
        partial
        @input="update($event, 'elements')" />

      <module-options-partial
        v-if="child === 'options' || !child"
        v-model="safeContent"
        :available-variants="availableVariants"
        :selected-variants="safeContent.variant"
        @setChild="setChild('options', true)"
        @unsetChild="setChild('options')"
        @remove="remove()"
        @clone="clone()"
      />

    </section>
  </section>
</template>

<script lang="ts">

import { Component, ModuleEditorComponent } from '@/libs/ModuleEditorComponent';
import CompareModule from '../models/CompareModule.model';

@Component
export default class CompareModuleEditor extends ModuleEditorComponent<CompareModule> {

  model = 'compare-module';

  structureListModel = 'compare-structure-element';

  elementsListModel = 'compare-element';

  get elements() {

    return this.safeContent.elements;

  }

  set elements(elements: any) {

    this.safeContent.elements = elements;

  }

  update(event, target) {

    console.error(event, target);

    this.safeContent[target] = event;

  }

}

</script>
