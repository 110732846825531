import GenericStore from '@/interfaces/GenericStore.interface';
import MainStore, { state } from '@/store/main.store';
import { DataObject, List } from '@lilaquadrat/studio/lib/interfaces';
import StudioSDK from '@/libs/StudioSDK';
import isObjectId from '@/mixins/isObjectId';
import AppListsMainState from '../interfaces/main-state.interface';
// import StudioSDK from '@/libs/StudioSDK';

const AppListsStore: GenericStore<AppListsMainState> = {
  namespaced: true,
  strict: true,

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },


  state: {

    data: {},

    search: {
      tags: [],
      search: null,
      type: null,
      sort: '_id',
      order: '1',
    },

    single: null,

  },

  mutations: {

    data(store, data: DataObject<List>) {

      store.data = data;

    },

    single(store, data: List) {

      store.single = data;

    },


    updateSearch(appListsState, update: { data: any, type: string }) {

      console.log(update, update.data.length, !update.data.length, appListsState.search);

      if (!update.data.length) {

        appListsState.search[update.type] = null;
        return;

      }

      console.log(update, appListsState.search);

      appListsState.search[update.type] = update.data;

    },

    setSearch(appListsState, search: { tags?: string[], search?: string, type?: string }) {

      appListsState.search = search;

    },

  },

  actions: {
    // sdkListExample(store, data: {data: Test, id: string}) {

    //   const sdk = new StudioSDK('test-app', AppTestStore.sdkOptions());

    //   return sdk.test.list(data.id, data.data);

    // },
    add(store, data: List) {

      const sdk = new StudioSDK('lists-app', AppListsStore.sdkOptions());

      return sdk.lists.add(data);

    },

    update(store, data: {id: string, data: List}) {

      const sdk = new StudioSDK('lists-app', AppListsStore.sdkOptions());

      return sdk.lists.update(data.id, data.data);

    },


    remove(store, id: string) {

      const sdk = new StudioSDK('lists-app', AppListsStore.sdkOptions());

      return sdk.lists.remove(id);

    },


    get(store, data: {query: {search: string, filterState: List['state'], filterMode: List['mode'], sort: string, order: number}, settings: {company: string, project: string, site: number}}) {

      const sdk = new StudioSDK('lists-app', AppListsStore.sdkOptions());

      return sdk.lists.list(data.settings.site, data.query.search, undefined, data.query.filterState, data.query.filterMode, data.query.sort, data.query.order);

    },

    getAgreement(store, data: {id: string, version: number}) {

      const sdk = new StudioSDK('lists-app', AppListsStore.sdkOptions());

      return isObjectId(data.id) 
        ? sdk.editor.getByInternalId(data.id)
        : sdk.content.predefined(data.id);

    },

    single(store, id: string) {

      const sdk = new StudioSDK('lists-app', AppListsStore.sdkOptions());

      return sdk.lists.single(id);

    },

    /**
     * This action will be called, when the app will be activated
     */
    activate(store, params) {

      return Promise.resolve(true);

    },
  },

};

export default AppListsStore;
