<template>
  <section class="textblock-editor">
    <section v-if="!child" class="editor-content-container">
      <!-- <content-generator-partial v-model="value" mode="textblock" /> -->
      <input-partial v-if="active('headline')" v-model="value.headline" placeholder="headline" @keyup="$emit('keyup', $event)">{{$translate('headline')}}</input-partial>
      <input-partial v-if="active('subline')" v-model="value.subline" placeholder="subline" @keyup="$emit('keyup', $event)">{{$translate('subline')}}</input-partial>
      <input-partial v-if="active('intro')" v-model="value.intro" placeholder="intro" @keyup="$emit('keyup', $event)">{{$translate('intro')}}</input-partial>

      <textarea-partial v-if="active('text')" v-model="text" @keyup="$emit('keyup', $event)">{{$translate('text')}}</textarea-partial>
    </section>
  </section>
</template>

<script lang="ts">
import Textblock from '@/apps/editor/interfaces/textblock.interface';
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import { Component, Prop } from '@/libs/lila-component';

@Component
// eslint-disable-next-line global-require
export default class TextblockEditor extends PartialEditorComponent<Textblock> {

  @Prop(Array) inactive: ('headline' | 'subline' | 'intro' | 'text')[];

  get text() {

    if (typeof this.value.text === 'undefined') return undefined;

    if (typeof this.value.text !== 'object') {

      return '';

    }

    return this.value.text.join('\n');

  }

  set text(text: string) {

    const baseArray = text.trim().split('\n');
    let cleanArray: string[] = [];

    cleanArray = baseArray.filter((string: string) => string.trim().length > 0);

    this.value.text = cleanArray;

  }

  active(value: 'headline' | 'subline' | 'intro' | 'text') {

    if (!this.inactive) return true;
    return this.inactive.indexOf(value) === -1;

  }


}

</script>
