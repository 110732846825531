<template>
  <router-link :to="to" class="app-link" :class="[available, { disabled, hasIcon: icon && useIcon }]">
    <picture-internal-partial v-if="icon" v-bind="{ picture: { src: icon } }" @error="toggleIcon('error')" @loaded="toggleIcon('loaded')" />
    <h3 v-if="!icon || !useIcon">{{$translate(translateName)}}</h3>
    <template v-if="status">
      <span v-if="active" class="status active"><icons-partial type="checked" :color-scheme="icon && useIcon ? 'colorScheme1' : 'white'" /></span>
      <span v-if="!active" class="status available"><icons-partial type="add" :color-scheme="icon && useIcon ? 'colorScheme1' : 'white'" /></span>
    </template>
    <span v-if="isPreviewPublic" class="ribbon public">{{$translate('app-internal')}}</span>
    <span v-if="isPreviewInternal" class="ribbon internal">{{$translate('app-preview')}}</span>
  </router-link>
</template>
<script lang="ts">
import { Prop } from '@/libs/lila-component';
import { ExtPartial } from '@/libs/lila-partial';
import Component from 'vue-class-component';

@Component
export default class appLinkPartial extends ExtPartial {

  @Prop(String) name: string;

  @Prop(Object) to: Record<string, string>;

  @Prop(String) icon: string;

  @Prop(Boolean) disabled: boolean;

  @Prop(Boolean) active: boolean;

  @Prop(String) available: 'unavailable' | 'internal' | 'preview' | 'public';

  @Prop(Boolean) status: boolean;

  @Prop(Array) attributes: string[];

  useIcon: boolean = false;

  get translateName() {

    return `app-${this.name}`;

  }

  get isPreviewPublic() {

    return this.available === 'preview';

  }

  get isPreviewInternal() {

    return this.available === 'internal';

  }

  get isUnavailable() {

    return this.available === 'unavailable';

  }

  toggleIcon(event: 'loaded' | 'error') {

    if (event === 'error') this.useIcon = false;
    if (event === 'loaded') this.useIcon = true;

  }

}
</script>
<style lang="less">

.app-link {
  position: relative;
  display: grid;
  align-content: center;
  justify-content: center;
  overflow: hidden;

  width: 100%;

  background-color: @color1;

  text-align: center;
  text-transform: uppercase;

  transition: transform @aTimeFast @aType, background @aTimeFast @aType;
  aspect-ratio: 1;
  .multi(padding, 4);

  &.hasIcon {
    background-color: transparent;

    text-indent: 0;

    &:hover {
      background-color: transparent;
    }

  }

  h3 {
    color: @white;
    font-size: @fontText;
  }

  picture {

    position: absolute;
    width: 100%;
    height: 100%;

    img {
      min-width: 100%;
      max-width: 100%;
      max-height: 100%;
    }

  }

  &:hover {
    background-color: @color3;
    transform: scale(1.05);
  }

  &.disabled {
    background-color: @grey;
    pointer-events: none;

    h3 {
      color: @textColor;
    }
  }

  &.unavailable {
    background-color: @grey;

    &.hasIcon {
      background-color: transparent;

      img {
        filter: grayscale(1);
      }

    }

    h3 {
      color: @textColor;
    }
  }

  .status {
    position: absolute;
    display: grid;

    align-self: start;
    justify-self: end;
    width: 40px;
    height: 40px;
    color: @white;

    .icon {
      display: grid;
      align-self: center;
      justify-self: center;
      text-indent: 0;

      svg {
        width: 15px;
        height: 15px;

        fill: @white;
      }
    }
  }

  .ribbon {
    position: absolute;
    top: 19px;
    left: -21px;
    display: grid;
    font-size: @fontSmall;
    transform: rotate(-45deg);
    .multi(padding, 0, 4);
    .font-head;

    &.internal {
      background-color: @error;
      color: @white;
    }

    &.public {
      background-color: @white;
      color: @color1;
    }
  }
}
</style>
