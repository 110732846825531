<template>
  <article class="app-payment-single-screen" :class="navigationAnimation">
    <side-screen-header-partial>{{content._id}}</side-screen-header-partial>
    <section class="content-container">

      <data-grid-partial class="content">
        <section class="data-grid-container">
          <section v-if="content.test" class="data-grid-single full">
            <h4>{{$translate('app-payment-title-test')}}</h4>
          </section>
          <section v-if="!content.test" class="data-grid-single">
            <h4>{{$translate('app-payment-customer')}}</h4>
            <p>{{content.customer.name}} <router-link :to="{ name: 'app-customers-company-single', params: { id: content.customer._id.toString() } }">{{$translate('app-payment-customer-link')}}</router-link></p>
          </section>
          <section class="data-grid-single">
            <h4>{{$translate('app-payment-title-state')}}</h4>
            <p>{{ $translate(`app-payment-state-${content.state}`) }}</p>
          </section>
          <section class="data-grid-single">
            <h4>{{$translate('app-payment-provider')}}</h4>
            <p>{{content.provider}}</p>
          </section>
          <section class="data-grid-single">
            <h4>{{$translate('app-payment-source')}}</h4>
            <p>{{ content.history.source }}</p>
          </section>
          <section class="data-grid-single">
            <h4>{{$translate('app-payment-created')}}</h4>
            <p>{{$date(content.history.created)}}</p>
          </section>
          <section class="data-grid-single full">
            <h4>{{$translate('app-payment-title-amount')}}</h4>
            <p>{{ $currency(content.amount) }}</p>
          </section>
          <section class="data-grid-single full">
            <h4>{{$translate('app-lists-participant-items')}}</h4>
            <app-lists-participant-items-partial :items="content.items" />
          </section>
        </section>
      </data-grid-partial>


    </section>
  </article>
</template>

<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import { SDKResponse } from '@/libs/StudioSDK';
import { BasicData, Payment, PaymentWithCustomer } from '@lilaquadrat/interfaces';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppPaymentSettingsScreen extends ExtComponent {

  componentName = ['app-payment-project-single', 'app-payment-project-tests-single'];

  get content(): BasicData<PaymentWithCustomer> {

    return this.$store.state.AppPayment.single;

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store
      .dispatch('AppPayment/single', {
        _id: params.id,
        settings: {
          project: params.project,
          company: params.company,
        },
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<BasicData<Payment>>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppPayment/single', preloadedData.data);
    return Promise.resolve();

  }


}
</script>
<style lang="less">


.app-payment-single-screen {


}
</style>
