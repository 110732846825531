<template>
  <section class="open editor-publish-editor editor">
    <editor-pre-header-partial>{{$translate('Publish')}}</editor-pre-header-partial>

    <section class="editor-module-container">

      <editor-content-group-partial>
        <add-publish-ext v-if="readyToPublish" type="editor" app="editor" :content-groups="['single']" :reference-id="editor._id">
          <notice-partial type="notice">{{$translate('filename has to be set to deploy via ftp')}}</notice-partial>
        </add-publish-ext>
        <notice-partial v-if="!readyToPublish" type="notice">
          {{ $translate('in order to publish single data, the state has to be publish') }}
        </notice-partial>
      </editor-content-group-partial>

      <status-publish-ext v-if="editor._id" :_id="editor._id" />
      <base-media-ext file :content="editor" />
    </section>

  </section>
</template>

<script lang="ts">
import { Component, ModuleEditorComponent, Prop } from '@/libs/ModuleEditorComponent';


@Component
// eslint-disable-next-line global-require
export default class EditorMainEditor extends ModuleEditorComponent<any> {

  @Prop(Object) value;

  get editor() {

    return this.$store.state.AppEditorData.content;

  }

  get readyToPublish() {

    return this.$store.state.AppEditorData.content?.state === 'publish';

  }


}
</script>
