import Models, { ModelDeclaration, ModelHooks } from '@/libs/Models.class';
import { Content } from '@lilaquadrat/studio/lib/interfaces';

export default interface GenericData {
  editor: string[]
  lists: string[]
  customers: string[]
  media: string[]
  structures: string[]
  data: Record<string, Content>
}

const declaration: ModelDeclaration<GenericData> = {
  editor: { type: 'array', contains: { type: 'string' } },
  lists: { type: 'array', contains: { type: 'string' } },
  customers: { type: 'array', contains: { type: 'string' } },
  media: { type: 'array', contains: { type: 'string' } },
  structures: { type: 'array', contains: { type: 'string' } },
  data: { type: 'object', default: {} },
};
const functions: ModelHooks<GenericData> = {
  preSave: (data) => {

    delete data.data;

  }
};

Models.register('generic-data', declaration, functions);