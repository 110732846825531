<template>
  <article :class="navigationAnimation" class="app-payment-home-screen">
    <div class="pagenator full" v-if="content">
      <header class="main">

        <section class="stats">
          <h1>{{ $translate( !isTests ? 'app-payment' : 'app-payment-tests' ) }}</h1>
          <h3 v-if="content.sites">
            <template v-if="!content.sites.last">{{ content.count }}</template>
            <template v-if="content.sites.last">{{ content.sites.range[0] }} - {{ content.sites.range[1] }} ({{ content.count }})</template>
            / {{ content.all }} {{ $translate('total') }}
          </h3>
        </section>

        <quickmenu-partial v-if="$scope(['payment:create'])">
          <router-link v-if="!isTests" :title="$translate('app-payment-tests')" :aria-label="$translate('app-payment-tests')" class="main icon" :to="{ name: 'app-payment-project-tests-home' }"><icons-partial type="test" size="large" /></router-link>
          <router-link v-if="isTests" :title="$translate('app-payment')" :aria-label="$translate('app-payment')" class="main icon" :to="{ name: 'app-payment-project-home' }"><icons-partial type="home" size="large" /></router-link>
          <router-link :title="$translate('app-payment-providers')" :aria-label="$translate('app-payment-providers')" class="main icon" :to="{ name: 'app-payment-providers-home' }"><icons-partial type="endpoints" size="large" /></router-link>
        </quickmenu-partial>
      </header>

      <quickfilter-partial noSearch reload categoryType="payment" store="AppPayment" useEmit @update="updateSearch" @reload="reload">
        <select-customers-ext v-if="!isTests" :value="customer" @input="updateSearch({data: $event, type: 'customer'})" />
      </quickfilter-partial>

      <section class="scroll-container">
        <table class="isSticky contentHead">
          <thead-partial :siteCurrent="currentSite" :search="$route.query.search" :sort="sort" :order="order" :content="headContent" />
        </table>

        <table class="data-switch contentTable" :class="contentAnimation">
          <tbody>
            <tr v-for="(single, index) in content.data" :key="`data-overview-${index}`">
              <td class="id fullHeight action" :class="{ hasAttribute: single.app || single.model }">
                <router-link
                  :to="{
                    name: !isTests ? 'app-payment-project-single' : 'app-payment-project-tests-single',
                    params: {
                      id: single._id.toString(),
                      company: $store.state.Company.company,
                      project: $store.state.Project.project,
                    },
                  }"
                >
                  <div>
                    <span class="name">
                      <template v-if="isTests">
                        {{$translate('app-payment-tests-customer-name')}}
                      </template>
                      <template v-if="!isTests">
                        {{single.customer.name}}
                      </template>
                    </span>
                    <span class="id">
                      <template v-if="isTests">
                        {{$translate('app-payment-tests-customer-id')}}
                      </template>
                      <template v-if="!isTests">
                        {{single.customer.id}}
                      </template>
                    </span>
                  </div>
                </router-link>
              </td>

              <td>
                {{$currency(single.amount)}}
              </td>

              <td class="attributes">
                <span class="attribute color1">{{ $translate(`app-payment-state-${single.state}`) }}</span>
              </td>

              <td>
                {{$date(single.history.created)}}
              </td>

            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <modal-module />

  </article>
</template>
<script lang="ts">
import { ExtComponent, Component, Watch } from '@/libs/lila-component';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import { BasicData, Payment, PaymentWithCustomer } from '@lilaquadrat/interfaces';
import { DataObject } from '@lilaquadrat/studio/lib/interfaces';
import cleanObject from '@/mixins/cleanObject';
import hardCopy from '@/mixins/hardCopy';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary, Route } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppPaymentHomeScreen extends ExtComponent {

  componentName = ['app-payment-project-home', 'app-payment-project-tests-home'];

  headContent = [
    {
      text: 'app-payment-title-customer',
    },
    {
      text: 'app-payment-title-amount',
    },
    {
      text: 'app-payment-title-state',
    },
    {
      key: 'history.created',
      text: 'app-payment-title-date',
    },
  ];

  // stateOptions = [
  //  {
  //    text: 'app-payment-type-company',
  //    value: 'company',
  //  },
  //  {
  //   text: 'app-payment-type-persons',
  //    value: 'person',
  //  },
  // ];

  @Watch('$route')
  watchRoute() {

    this.setSearch();

  }

  get isTests() {

    /**
    * Check if the current component is the tests component or the single tests component
    * either way show the tests
    */
    return ['app-payment-project-tests-home', 'app-payment-project-tests-single'].includes(this.$route.name);

  }

  get currentSite() {

    return this.$route.params.site ? +this.$route.params.site : null;

  }

  get type() {

    return this.$store.state.AppPayment.search?.type;

  }

  get sort() {

    return this.$store.state.AppPayment.search?.sort;

  }

  get order() {

    return this.$store.state.AppPayment.search?.order;

  }

  get customer() {

    return this.$store.state.AppPayment.search?.customer;

  }

  get content(): DataObject<BasicData<PaymentWithCustomer>[]> {

    return this.$store.state.AppPayment.data || undefined;

  }

  mounted() {

    this.setSearch();

  }


  getSearch() {

    const query: Record<string, string | string[]> = {};

    query.sort = this.$route.query.sort as string || 'history.created';
    query.order = this.$route.query.order as string || '-1';

    query.tags = this.$route.query.tags || null;
    query.type = this.$route.query.type || null;
    query.search = this.$route.query.search || null;
    query.customer = this.$route.query.customer || null;

    return query;

  }

  setSearch() {

    // only update search if the current component is used and no child is active
    if (this.componentName.includes(this.$route.name)) return;
    this.$store.commit('AppPayment/setSearch', this.getSearch());

  }


  async updateSearch(data: {data: string, type: string}) {

    console.log(data);

    this.$store.commit('AppPayment/updateSearch', { data: data.data, type: data.type });

    const query = hardCopy(this.$store.state.AppPayment.search);

    cleanObject(query);

    this.$router.push({ params: { site: '1' }, query });

  }

  async reload() {

    StudioSDK.flushCache('payments', this.isTests ? 'list-tests' : 'list');

    const action = this.isTests
      ? 'AppPayment/getTests'
      : 'AppPayment/get';
    const data = await this.$store
      .dispatch(action, {
        query: this.$route.query,
        settings: {
          project: this.$route.params.project,
          company: this.$route.params.company,
          site: this.$route.params.site,
        },
      });

    this.$store.commit('AppPayment/data', data.data);

  }

  // eslint-disable-next-line class-providers-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>, to: Route) {

    console.log(params, query, to.name);

    const action = to.name === 'app-payment-project-tests-home' 
      ? 'AppPayment/getTests'
      : 'AppPayment/get';

    return store
      .dispatch(action, {
        query,
        settings: {
          project: params.project,
          company: params.company,
          site: params.site,
        },
      });

  }

  // eslint-disable-next-line class-providers-use-this
  preloadDataPost(preloadedData: SDKResponse<DataObject<Payment>>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    console.log(preloadedData);
    store.commit('AppPayment/data', preloadedData.data);
    return Promise.resolve();

  }

}
</script>
<style lang="less">


.app-payment-home-screen {

  .filter {
    .multi(padding, 2, 8, 4, 8);
    .basePadding;

    display: grid;
    grid-template-rows: min-content;
  }

  table.contentTable, table.contentHead {

    tr {
      grid-template-columns: 1fr 75px 75px 100px;

      td {

        .id {
          display: grid;
          color: @textColor;
          text-transform: uppercase;
        }

        .name {
          .font-bold;
          color: @color1;
        }

      }

    }

  }

  .sites-filter-container {

    display: grid;
    grid-template-columns: 1fr 1fr;
    .multi(padding-top, 2);
    .multi(padding-bottom, 4);
    .basePadding;

    .sites-container {
      justify-self: end;
    }

    .select {
      display: grid;
      grid-template-columns: 150px 1fr;
    }
  }
}
</style>


