// import modules from '@/apps/editor/editor-modules';
// import ChildDataModel from '@/models/ChildData.model';
// import ModuleModel from '@/models/Module.model';
// import TextblockModel from '@/models/Textblock.model';

// export default class ContactModuleModel extends ModuleModel {

//   type: keyof typeof modules = 'contact-module';

//   uuid = true;

//   textblock = TextblockModel;

//   childData = ChildDataModel;

// }

import Models, { ModelDeclarationExtended, ModelOptions } from '@/libs/Models.class';
import { GenericData } from '@lilaquadrat/studio/lib/interfaces';
import Module from '@/models/Module.model';
import Textblock from '@/models/Textblock.model';

export default interface ContactModule extends Module {
  genericData: GenericData,
  textblock: Textblock
  categoryTextblock: Textblock
  categories: { type: 'array', contains: { model: 'contact-category-element' } },
}

const declaration: ModelDeclarationExtended<ContactModule, Module> = {
  textblock: { model: 'textblock' },
  categoryTextblock: { model: 'textblock' },
  genericData: { model: 'generic-data' },
  categories: { type: 'array', contains: { model: 'contact-category-element' } },
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('contact-module', declaration, {}, options);