<template>
  <section class="sites-filter-container" :class="{ showCategory: category, hasSlot, reload }">
    <section class="filter-text">

      <span class="group" v-if="!noSearch || category">
        <input-partial v-if="!noSearch" debounce="500" placeholder="search" :value="search.search" @input="updateSearch($event, 'search')" />
        <tags-partial v-if="category && !useTags" :type="categoryType" filter-mode category="category" placeholder="category" :value="tags" @input="updateSearch($event, 'tags')">{{$translate('category')}}</tags-partial>
        <tags-partial v-if="category && useTags" :type="categoryType" filter-mode placeholder="tags" :value="tags" @input="updateSearch($event, 'tags')">{{$translate('tags')}}</tags-partial>
      </span>
      <slot />
    </section>

    <button-partial v-if="reload" icon="replay" color-scheme="transparent" button-content-type="icon" @click="$emit('reload')" />
    <sites-partial param-key="site" :data="content" :mode="mode" @update="updateSite" />
  </section>
</template>

<script lang="ts">
import {
  ExtComponent, Component, Prop,
} from '@/libs/lila-component';
import cleanObject from '@/mixins/cleanObject';
import equal from 'fast-deep-equal';

@Component
export default class QuickfilterPartial extends ExtComponent {

  @Prop({ type: String, default: 'AppEditorMain' }) store: string;

  @Prop({ type: String, default: 'editor' }) categoryType: string;

  @Prop(Boolean) category: boolean;

  @Prop(Boolean) useTags: boolean;

  // emit the changes instead of saving them in the store
  @Prop(Boolean) useEmit: boolean;

  @Prop(Boolean) reload: boolean;

  @Prop(Boolean) noPush: boolean;

  @Prop(Boolean) noSearch: boolean;

  @Prop({ type: String, default: 'history' }) mode: 'history' | 'inline';

  @Prop(String) selectPlaceholder: string;

  @Prop(Array) options: { value: string; name: string; }[];

  mounted() {

    if (this.useEmit) return;

    if (this.$route.query.search || (this.category && this.$route.query.tags)) {

      if (this.search.search !== this.$route.query.search || !equal(this.search.tags, this.$route.query.tags)) {

        this.setSearch(this.$route.query, false);

      }

    }

  }

  get hasSlot() {

    return !!this.$slots.default;

  }

  get usedStore() {

    return this.$store.state[this.store];

  }

  get content() {

    return this.usedStore?.data;

  }

  get total() {

    return this.usedStore?.length;

  }

  get search() {

    return this.usedStore?.search || {};

  }

  get tags() {

    if (typeof this.usedStore?.search?.tags === 'string') return [this.usedStore?.search?.tags];

    return this.usedStore?.search?.tags || [];

  }

  get filterActive() {

    return this.usedStore?.filterActive;

  }

  get sites() {

    return this.usedStore?.content?.sites;

  }

  get currentSite() {

    return +this.$route.params.site || 0;

  }

  get query() {

    const query = { ...this.$route.query };

    console.log(this.search);

    // if (!this.search.length) delete query.search;

    const realQuery = { ...query, ...this.search };

    cleanObject(realQuery);
    return realQuery;

  }

  updateSearch(data: any, type: string) {

    if (this.useEmit) {

      this.$emit('update', { data, type });

    } else {

      this.$store.commit(`${this.store}/updateSearch`, { data, type });

      if(this.noPush) this.$store.commit(`${this.store}/setSite`, 1);
      if(!this.noPush) this.$router.push({ params: { site: '1' }, query: this.query });

    }


  }

  updateSite(site: number) {

    if(this.mode === 'inline') this.$store.commit(`${this.store}/setSite`, site);

  }

  setSearch(search: any, push: boolean = true) {

    this.$store.commit(`${this.store}/setSearch`, search);

    if (push && !this.noPush) {

      this.$router.replace({ query: this.query });

    }

  }

}
</script>
<style lang="less" scoped>

.sites-filter-container {
  .multi(padding, 2, 8, 4, 8);
  .basePadding;

  display: grid;
  grid-template-rows: min-content;
  grid-template-columns: 1fr max-content;

  &.showCategory {

    .group {
      display: grid;
      grid-template-columns: max-content min-content;
      gap: 10px;
    }
  }

  &.reload {
    grid-template-columns: 1fr min-content max-content;
    gap: 10px;
  }

  .filter-text {
    display: grid;

    grid-template-columns: max-content min-content;
    gap: 0 10px;
    font-size: @fontText;
    line-height: @buttonHeight;

    .group  {

      &:empty {
        display: none;
      }

      .input-partial::v-deep {

        margin: 0;

        input {

          max-width: 100px;
          padding: 0;
          border-bottom: solid 1px @grey;
          height: @buttonHeight;
          font-size: @fontTextSmaller;
          line-height: 33px;

        }

        .label-container {
          display: none;
        }

      }

      .tags-search::v-deep {

        .label-container {
          display: none;
        }

        .input-partial {

          margin: 0;

          input {

            max-width: 100px;
            padding: 0;
            border-bottom: solid 1px @grey;
            font-size: @fontTextSmaller;
            line-height: 33px;

          }

          .label-container {
            display: none;
          }

        }

      }

    }
  }
}

</style>
