import { UnifiedRouteGuard } from '@/libs/lila-auth';
import HomeScreen from './screens/home.screen.vue';
import EditScreen from './screens/edit.screen.vue';
import UploadScreen from './screens/upload.screen.vue';

export default [
  {
    path: '/:company/:project/a/media/:site?',
    name: 'app-media-project-home',
    beforeEnter: UnifiedRouteGuard,
    component: HomeScreen,
    meta: {
      groups: ['media'],
      app: 'media',
      parent: true,
      rememberSettings: true,
    },
    children: [
      {
        path: 'e/:filename/edit',
        name: 'app-media-project-edit',
        components: {
          children: EditScreen,
        },
        meta: {
          groups: ['media', 'edit'],
          app: 'media',
        },
      },
      {
        path: 'upload',
        name: 'app-media-project-upload',
        components: {
          modal: UploadScreen,
        },
        meta: {
          sidescreen: true,
          defaultRoute: 'app-media-project-upload',
          groups: ['media'],
          app: 'media',
        },
      },
    ],
  },
];
