// import PartialModel from '@/libs/lila-model';

// export default class CustomerPersonModel extends PartialModel {

//   type = 'person';

//   uuid = false;

//   email: string = '';

//   streetNumber: string = '';

//   zipcode: string = '';

//   city: string = '';

//   country: string = '';

//   phone: string = '';

//   note: string = '';

//   prename: string = '';

//   name: string = '';

//   // birthday: Date = new Date();

//   gender: string = '';

//   tags: string[] = [];

// }

import Models, { ModelDeclaration } from '@/libs/Models.class';
import { Address } from '@lilaquadrat/studio/interfaces';

type AppCustomersPerson = {
  email: string
  street: string
  streetNumber: string
  zipcode: string
  city: string
  country: string
  phone: string
  note: string
  prename: string
  name: string
  // birthday: Date = new Date();
  gender: string
  tags: string[]
  type: string
} & Address;


const declaration: ModelDeclaration<AppCustomersPerson> = {
  email: { type: 'string' },
  osm_id: { type: 'number' },
  street: { type: 'string' },
  streetNumber: { type: 'string' },
  zipcode: { type: 'string' },
  addressAddition: { type: 'string' },
  city: { type: 'string' },
  country: { type: 'string' },
  phone: { type: 'string' },
  note: { type: 'string' },
  prename: { type: 'string' },
  name: { type: 'string' },
  gender: { type: 'string' },
  type: { type: 'string' },
  tags: { type: 'array', contains: { type: 'string' } }
};

Models.register('app-customers-person', declaration);

export default AppCustomersPerson;