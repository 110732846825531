<template>
  <article :class="navigationAnimation">
    <side-screen-header-partial>{{$translate('settings')}}</side-screen-header-partial>

    <section class="content-container">
      <form class="content" v-if="model" @submit="save">
        <fieldset>
          <legend>
            <span class="text">{{$translate('company-name')}}</span>
          </legend>

          <input-partial v-model="model.id" placeholder="name" disabled>{{$translate('id')}}</input-partial>

          <input-partial v-model="model.name" placeholder="name" :validation="/^[a-z0-9 \.\_\-\&\!\:]{1,50}$/i" required>{{$translate('name')}}</input-partial>

          <textarea-partial v-model="model.description" max-length="200">{{$translate('short description of this company')}}</textarea-partial>
        </fieldset>

        <fieldset>
          <legend>
            <span class="text">{{$translate('company-address')}}</span>
          </legend>

          <input-partial v-model="model.streetNumber" placeholder="company-streetNumber" :error="errorsObject.streetNumber" required>{{$translate('company-streetNumber')}}</input-partial>

          <input-partial v-model="model.city" placeholder="company-city" :error="errorsObject.city" required>{{$translate('company-city')}}</input-partial>

          <input-partial v-model="model.zipcode" placeholder="company-zipcode" :error="errorsObject.zipcode" required>{{$translate('company-zipcode')}}</input-partial>

          <select-partial v-model="model.country" :options="availableCountries" placeholder="company-country-select" required :error="errorsObject.country" :multiple="false">{{$translate('company-country')}}</select-partial>

        </fieldset>

        <fieldset>
          <legend>
            <span class="text">{{$translate('company-legal')}}</span>
          </legend>

          <input-partial v-model="model.legalRepresentative" placeholder="company-representive" :error="errorsObject.legalRepresentative">{{$translate('company-representive')}}</input-partial>

          <input-partial v-model="model.vat" placeholder="company-vat" :error="errorsObject.vat">{{$translate('company-vat')}}</input-partial>

          <input-partial v-model="model.register" placeholder="company-register" :error="errorsObject.range">{{$translate('company-register')}}</input-partial>

        </fieldset>

        <fieldset>
          <legend>
            <span class="text">{{$translate('company-contact')}}</span>
          </legend>

          <input-partial v-model="model.internet" placeholder="company-internet" :error="errorsObject.internet">{{$translate('company-internet')}}</input-partial>

          <input-partial v-model="model.phone" placeholder="company-phone" :error="errorsObject.phone">{{$translate('company-phone')}}</input-partial>

        </fieldset>

        <fieldset v-if="$scope(['company:create'], 'lilaquadrat')">
          <legend>
            <span class="text">{{$translate('admin')}}</span>
          </legend>

          <select-partial placeholder="company-attributes" :options="attributesOptions" v-model="model.attributes" allow-empty>{{$translate('company-attributes')}}</select-partial>
        </fieldset>

        <action-notice-partial :state="state" translation-pre="company" :errors="errors" @update="updateErrors">
          <button-partial v-model="state" type="submit" save>{{$translate('SAVE_CHANGES')}}</button-partial>
        </action-notice-partial>
      </form>
    </section>
  </article>
</template>

<script lang="ts">
import SelectOption from '@/interfaces/selectOption.interface';
import { ErrorsObject } from '@/libs/ActionNotice';
import { Auth } from '@/libs/lila-auth';
import {
  ExtComponent, Component,
} from '@/libs/lila-component';
import ModelsClass from '@/libs/Models.class';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import Company from '@/models/Company.model';
import { ErrorObject } from 'ajv';

@Component
// eslint-disable-next-line global-require
export default class CompanySettingsScreen extends ExtComponent {

  model: Company = null;

  errors: {message?: string, errors?: ErrorObject[]} = {};

  errorsObject: ErrorsObject = {};

  state: string = '';

  componentName = 'company-settings';

  availableCountries: SelectOption[] = [
    { value: 'deu', text: 'country-deu' },
    { value: 'aut', text: 'country-aut' },
    { value: 'che', text: 'country-che' },
  ];

  attributesOptions = [
    {
      text: 'app-app-preview',
      value: 'previewPublic',
    },
    {
      text: 'app-app-internal',
      value: 'previewInternal',
    },
    {
      text: 'app-app-admin',
      value: 'admin',
    },
    {
      text: 'partner',
      value: 'partner',
    }
  ];


  updateErrors(errorsObject: ErrorsObject) {

    this.errorsObject = errorsObject;

  }

  beforeMount() {

    this.$store
      .dispatch('Company/getSettings', this.$route.params.company)
      .then((response: SDKResponse<Company>) => {

        this.model = ModelsClass.add(response.data, 'company');

      })
      .catch((e) => {

        console.log(e);

      });

  }

  async save($event: MouseEvent) {

    $event.preventDefault();
    this.state = '';

    const action = Auth.checkScope(this.$store.state.user, ['company:create'], 'lilaquadrat')
      ? 'Company/saveSettingsAdmin'
      : 'Company/saveSettings';
    const saveData = ModelsClass.save<Company>(this.model, 'company');

    if(action === 'Company/saveSettings') {

      delete saveData.attributes;
    
    }

    console.log(saveData, action);

    delete saveData.id;
    delete saveData.partner;

    try {

      await this.$store.dispatch(action, { companyId: this.$store.state.Company.company, companyAdminId: 'lilaquadrat', data: saveData });
      this.state = 'success';

      StudioSDK.flushCache('company', 'single');

      const settings: SDKResponse<Company> = await this.$store.dispatch('Company/getSettings', this.$store.state.Company.company);

      this.$store.commit('Company/settings', settings.data);
      this.$store.dispatch('Company/validate', settings.data);

    } catch (error) {

      this.errors = error.response.data;
      this.state = 'error';

    }


  }

}


</script>
