
import Models, { ModelDeclaration } from '@/libs/Models.class';
import { HostingSettings } from '@lilaquadrat/studio/lib/interfaces';


const declaration: ModelDeclaration<Pick<HostingSettings, 'baseDomain' | 'ipAdresses'>> = {
  baseDomain: { type: 'string' },
  ipAdresses: { type: 'array', contains: {type: 'string'} },
};

Models.register('app-hostingadmin-settings', declaration);