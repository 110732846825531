<template>

  <article class="editmode-container">

    <details-partial label="ORIGINALFILENAME">

      <section class="version">
        <div class="name">

          <button-partial :aria-label="file.id" :title="file.id" color-scheme="inline" class="clean" @confirmed="show">
            {{file.id}}
          </button-partial>

          <button-partial
            :aria-label="$translate('copy to clipboard')"
            :title="$translate('copy to clipboard')"
            icon="link"
            button-content-type="icon"
            color-scheme="transparent"
            size="small"
            class="icon"
            @confirmed="copy(file.id)" />

          <input v-show="copyElement === file.id" :ref="`copy-${file.id}`" readonly class="copy-element" :value="`${file.basePath}${file.id}`" />
        </div>
      </section>

    </details-partial>

    <section>
      <details-partial label="FILETYPE">{{file.mimetype}}</details-partial>
      <details-partial label="SIZE"><format-bytes-partial :decimals="2">{{file.size}}</format-bytes-partial></details-partial>
      <details-partial v-if="file.image" label="DIMENSIONS">{{file.info.width}} x {{file.info.height}}px</details-partial>
    </section>

    <section class="versions">
      <h5>Versions</h5>

      <section v-for="(version, index) in versions" :key="`version-${index}`" class="version">
        <div class="name">

          <button-partial :aria-label="version.filename" :title="version.filename" color-scheme="inline" class="clean" @confirmed="show(index)">{{version.name}}</button-partial>
          <button-partial :aria-label="$translate('copy to clipboard')" :title="$translate('copy to clipboard')" icon="link" button-content-type="icon" color-scheme="transparent" size="small" @confirmed="copy(version.filename)" />
          <input v-show="copyElement === version.filename" :ref="`copy-${version.filename}`" readonly class="copy-element" :value="`${file.basePath}${version.filename}`" />

        </div>
        <div class="version-info">
          <div><template v-if="version.width">{{version.width}} x {{version.height}}px</template></div>
          <div><format-bytes-partial :decimals="2">{{version.size}}</format-bytes-partial></div>
        </div>

      </section>
    </section>


    <tags-partial class="filter" allow-new :value="tags" type="media" @input="setTags">{{$translate('tags')}}</tags-partial>
    <tags-partial class="filter" category="folder" allow-new :value="tags" type="media" @input="setTags">{{$translate('folder')}}</tags-partial>

    <base-media-ext v-if="baseContent" inline :content="baseContent" />

    <button-group-partial class="actions">
      <button-partial v-model="state" save class="colorScheme1 base" @save="save">{{$translate('save')}}</button-partial>
      <button-partial doublecheck class="base colorScheme2" @confirmed="deleteAction">{{$translate('remove')}}</button-partial>
    </button-group-partial>

  </article>

</template>
<script lang="ts">
import { Component, ExtPartial } from '@/libs/lila-partial';
import { Media } from '@lilaquadrat/studio/lib/interfaces';
import StudioSDK from '@/libs/StudioSDK';

@Component
export default class MediaEditMode extends ExtPartial {

  entries: string[] = [];

  tagsTemp: string[] = [];

  state: string = '';

  copyElement: string = null;

  get file(): Media {

    return this.$store.state.AppMedia.file;

  }

  get versions(): Media['versions'] {

    return this.$store.state.AppMedia.file?.versions;

  }

  get tags() {

    return this.tagsTemp?.length ? this.tagsTemp : this.$store.state.AppMedia.file.tags;

  }

  get baseContent() {

    if (!this.file.baseContent?.id) return null;

    return this.file;

  }

  copy(filename: string) {

    this.copyElement = filename;

    this.$nextTick()
      .then(() => {

        console.log(filename, this.file.basePath, this.$refs);

        const element = this.$refs[`copy-${filename}`] as HTMLInputElement;

        if (Array.isArray(element)) {

          element[0].select();

        } else {

          element.select();

        }

        document.execCommand('copy');
        this.copyElement = null;

      });

  }

  show(index = 0) {

    console.log(this.versions[index]);
    this.$store.commit('AppMedia/version', index);

  }

  setTags(tags: string[]) {

    console.log('Set tags', tags);

    this.tagsTemp = tags;

  }

  save() {

    this.$store.dispatch('AppMedia/update', {
      company: this.$store.state.Company.company,
      project: this.$store.state.Project.project,
      id: this.$store.state.AppMedia.file.id,
      tags: this.tags,
    })
      .then(() => {

        StudioSDK.flushId(this.$store.state.AppMedia.file.id);
        this.state = 'success';
        this.$emit('saved');

      })
      .catch((e) => {

        console.error(e);
        this.state = 'error';

      });

  }

  deleteAction() {

    this.$store.dispatch('AppMedia/delete', this.$store.state.AppMedia.file._id)
      .then(() => {

        StudioSDK.flushCache('media', 'get');
        this.state = 'success';
        this.$emit('deleted');

      })
      .catch(() => {

        this.state = 'error';

      });

  }

}

</script>
<style lang="less" scoped>

.editmode-container {
  .multi(padding, 4, 6, 4, 6);
  display: grid;
  grid-auto-rows: min-content;
  gap: 15px;

  margin-left: 0;

  background-color: @white;

  @media @desktop {
    .multi(margin-left, 5);
    .multi(padding, 16, 6, 4, 6);
  }

  @media @desktop {

    background-color: @grey2;

  }

  .versions {
    display: grid;
    margin-bottom: 40px;
  }

  .version {

    display: grid;

    .name {

      display: grid;
      grid-template-columns: 1fr max-content;

      button {
        .font-head;
        text-transform: uppercase;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

    }

    .version-info {

      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 5px;
      font-size: @fontTextSmaller;

      :last-child {
        text-align: right;
      }

    }

    .copy-element {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

  }

}
</style>
