<template>
  <figure class="picture-group">
    <picture v-for="(picture, i) in pictures" :class="{ notLoaded: !loading }" v-bind:key="`picturegroup-${i}`">
      <template v-if="loading">
        <source v-for="(source, i) in picture.source" :key="`p-${i}`" :media="`${source.media}`" :srcset="source.source" />
        <img :src="picture.src" :alt="picture.alt" />
      </template>
      <template v-if="!loading">
        <img :alt="picture.alt" />
      </template>
    </picture>
  </figure>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import inview from '@/libs/lila-inview';

@Component
export default class pictureGroupPartial extends Vue {

  /**
   *
   *
   * @memberof picturePartial
   */
  @Prop(Array) pictures: Picture[];

  loading: boolean = false;

  view: string;

  mounted() {

    if (!this.$el.querySelector) return;
    this.checkInview();
    inview.checkImage(this);

  }

  checkInview() {

    window.addEventListener('scrolled', () => {

      if (!this.loading) inview.checkImage(this);
      inview.checkImage(this);

    });

  }

}
</script>
