<template>
  <article :class="navigationAnimation" class="app-tracker-statistics-screen">
    <section class="calendar">

      <section class="titleContainer">
        <h2 class="name">
          {{monthName}}
          <button-partial variant="iconColor" size="medium" buttonContentType="icon" colorScheme="icon-color1" icon="replay" :title="$translate('app-tracker-button-restart')" @click="refresh">app-tracker-button-refresh</button-partial>
        </h2>
        <button-group-partial class="controls">
          <router-link class="button base icon" :title="$translate('app-tracker-next-month')" :to="nextLink"><icons-partial type="arrow-right" /></router-link>
          <router-link class="button base icon" :to="prevLink" :title="$translate('app-tracker-prev-month')"><icons-partial type="arrow-left" /></router-link>
          <router-link :class="{ disabled: isCurrentMonth }" class="button base" :to="currentLink">{{$translate('app-tracker-current-month')}}</router-link>
        </button-group-partial>
      </section>

      <section class="statisticsContainer">
        <app-tracker-range-statistics-partial includeDays noHeadlineMobile :value="monthStatistics">{{$translate('app-tracker-statistics-title')}}</app-tracker-range-statistics-partial>
        <overlay-background-partial v-if="statisticsDay && isMobile" background="tablet" ref="options" @close="hideDetails">
          <app-tracker-day-statistics-partial v-if="statisticsDay" :value="statisticsDay" />
        </overlay-background-partial>
        <app-tracker-day-statistics-partial v-if="statisticsDay && !isMobile" :value="statisticsDay" />
      </section>

      <section class="monthContainer">

        <section class="weekdayContainer">
          <section class="day">{{$translate('app-tracker-monday-short')}}</section>
          <section class="day">{{$translate('app-tracker-tuesday-short')}}</section>
          <section class="day">{{$translate('app-tracker-wednesday-short')}}</section>
          <section class="day">{{$translate('app-tracker-thursday-short')}}</section>
          <section class="day">{{$translate('app-tracker-friday-short')}}</section>
          <section class="day">{{$translate('app-tracker-saturday-short')}}</section>
          <section class="day">{{$translate('app-tracker-sunday-short')}}</section>
        </section>

        <section class="daysContainer">
          <section
            v-for="(day, index) in month"
            :key="`day-${index}`"
            class="day"
            @keyup="showDetails(day.dateObject)"
            @click="showDetails(day.dateObject)"
            :class="{
              currentMonth: day.currentMonth, today: day.today, hasStatistics: day.statistics, selected: day.selected,
            }">
            <span class="number">{{day.day}} <span v-if="day.statistics" class="indicator" /></span>
            <app-tracker-range-statistics-partial v-if="day.statistics" inline :value="day.statistics" />
            <span class="weekday">{{day.weekday}}</span>
          </section>
        </section>

      </section>


    </section>

  </article>
</template>
<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import {
  TrackerStatistics, TrackerData, TrackerCategories, TrackerStatisticsDay,
} from '@lilaquadrat/studio/lib/interfaces';
import MainStoreState from '@/store/mainStoreState.interface';
import dayjs from 'dayjs';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';
import duration from 'dayjs/plugin/duration';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import TrackerDay from '../interfaces/TrackerDay.interface';

dayjs.extend(duration);

@Component
export default class AppTrackerHomeScreen extends ExtComponent {

  componentName = 'app-tracker-project-statistics';

  calculatedOptions = {};

  get isMobile() {

    console.log();

    return this.$store.state.media === 'mobile' || this.$store.state.media === 'tablet';

  }

  get optionsStyle() {

    if (this.$store.state.media === 'mobile') return {};

    return this.calculatedOptions;

  }

  get selectedMonth() {


    if (this.$route.params.month) {

      const month = +this.$route.params.month.slice(5, 7);
      const year = +this.$route.params.month.slice(0, 4);

      return dayjs().set('month', month - 1).set('year', year).startOf('month');

    }

    return dayjs();

  }

  get statistics(): TrackerStatistics {

    return this.$store.state.AppTracker.statistics;

  }

  get statisticsDay(): TrackerStatistics {

    return this.$store.state.AppTracker.day;

  }

  // eslint-disable-next-line class-methods-use-this
  get monthName() {

    return dayjs(this.selectedMonth).format('MMMM YYYY');

  }

  get isCurrentMonth() {

    return this.$route.params.month === dayjs().format('YYYY-MM') || !this.$route.params.month;

  }

  get monthStatistics() {

    return this.statistics.categories?.byMonth[dayjs(this.selectedMonth).format('YYYY-MM')];

  }

  // eslint-disable-next-line class-methods-use-this
  get month() {

    const monthArray = [];
    const baseDate = dayjs(this.selectedMonth);
    const currentMonth = baseDate.get('month');

    for (let a = 0; a < 42; a += 1) {

      const date = baseDate.startOf('month').startOf('week').add(a, 'day');
      const dayObject: TrackerDay & {statistics?: Partial<Record<TrackerCategories, TrackerData>>} = {
        dateObject: date,
        date: date.format('DD.MM.YYYY'),
        day: date.format('DD'),
        month: date.format('MM'),
        year: date.format('YYYY'),
        weekday: date.format('dddd'),
        currentMonth: date.get('month') === currentMonth,
        today: date.format('DDMMYYYY') === dayjs().format('DDMMYYYY'),
        selected: date.format('YYYY-MM-DD') === this.$route.params.day,
      };

      if (this.statistics?.categories.byDay[date.format('YYYY-MM-DD')]) {

        const dayStatistics: Partial<Record<TrackerCategories, TrackerData>> = this.statistics?.categories.byDay[date.format('YYYY-MM-DD')];
        const statistics: Partial<Record<TrackerCategories, TrackerData>> = {};

        if (dayStatistics.call?.count) {

          statistics.call = {
            count: dayStatistics.call.count,
            time: dayStatistics.call?.time,
          };

        }

        if (dayStatistics.worktime?.count) {

          statistics.worktime = {
            count: dayStatistics.worktime?.count,
            time: dayStatistics.worktime?.time,
          };

        }

        dayObject.statistics = statistics;

      }

      monthArray.push(dayObject);

    }

    return monthArray;

  }

  get nextLink() {

    const baseMonth = dayjs(this.selectedMonth);
    const month = baseMonth.add(1, 'month').format('YYYY-MM');

    return { name: 'app-tracker-project-statistics', params: { month }, query: this.$route.query };

  }

  get prevLink() {

    const baseMonth = dayjs(this.selectedMonth);
    const month = baseMonth.subtract(1, 'month').format('YYYY-MM');

    return { name: 'app-tracker-project-statistics', params: { month }, query: this.$route.query };

  }

  // eslint-disable-next-line class-methods-use-this
  get currentLink() {

    return { name: 'app-tracker-project-statistics' };

  }

  // eslint-disable-next-line class-methods-use-this
  timePassed(date: number, includeDays?: boolean) {

    if (!date) return '00:00:00';

    const days = dayjs.duration(date, 'milliseconds').get('days').toString()
      .padStart(2, '0');
    const hours = dayjs.duration(date, 'milliseconds').get('hours').toString()
      .padStart(2, '0');
    const minutes = dayjs.duration(date, 'milliseconds').get('minutes').toString()
      .padStart(2, '0');
    const seconds = dayjs.duration(date, 'milliseconds').get('seconds').toString()
      .padStart(2, '0');

    return includeDays ? `${days}:${hours}:${minutes}:${seconds}` : `${hours}:${minutes}:${seconds}`;

  }

  showDetails(date: dayjs.Dayjs) {

    this.$router.push({ params: { month: this.$route.params.month || date.format('YYYY-MM'), day: date.format('YYYY-MM-DD') }, query: this.$route.query });

  }

  hideDetails() {

    this.$router.push({ params: { month: this.$route.params.month, day: null }, query: this.$route.query });

  }

  async refresh() {

    StudioSDK.flushCache('tracker');

    const preloadedData = await this.preloadDataPre(this.$route.params, this.$route.query, this.$store);

    this.$store.commit('AppTracker/statistics', preloadedData.statistics.data);
    this.$store.commit('AppTracker/day', preloadedData?.dayData?.data);

  }


  // eslint-disable-next-line class-methods-use-this
  async preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    let month: number;
    let year: number;
    let dayData: SDKResponse<TrackerStatisticsDay>;

    if (params.month) {

      month = +params.month.slice(5, 7);
      year = +params.month.slice(0, 4);

    } else {

      month = +dayjs().format('MM');
      year = +dayjs().format('YYYY');

    }

    if (params.day) dayData = await store.dispatch('AppTracker/day', { date: params.day, user: query.user });


    const statistics = await store.dispatch('AppTracker/statistics', { year, month, user: query.user });

    return { statistics, dayData };

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: {statistics: SDKResponse<TrackerStatistics>, dayData?: SDKResponse<TrackerStatisticsDay>}, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppTracker/statistics', preloadedData.statistics.data);
    store.commit('AppTracker/day', preloadedData?.dayData?.data);
    return Promise.resolve();

  }

}
</script>
<style lang="less" scoped>

.app-tracker-statistics-screen {

  .calendar {
    .basePadding;
    display: grid;
    grid-template-rows: min-content min-content min-content;

    gap: 40px;

    @media @desktop {
      grid-template-rows: min-content min-content;
      grid-template-columns: 2fr 1fr;
    }

    .titleContainer {
      display: grid;
      gap: 20px;

      @media @desktop {
        grid-template-columns: 1fr 1fr;
        gap: 0;
      }

      .name {
        display: grid;
        grid-template-columns: max-content min-content;
        line-height: @buttonLineHeight;
      }

      a.button.base {

        .trans(color);

        &.disabled {
          color: @grey;
          pointer-events: none;

        }
      }
    }

    .monthContainer {

      @media @desktop {
        grid-column-start: 1;
        grid-column-end: 2;
      }
    }

    .statisticsContainer {
      display: grid;
      grid-template-rows: min-content;
      grid-row-start: 2;
      gap: 20px;

      @media @desktop {
        grid-column-start: 2;
        grid-column-end: 3;
      }

      .container-title {
        display: none;
        line-height: @headlineLineHeight_XS;

        @media @desktop {
          display: grid;
        }
      }
    }

    .day {
      .multi(padding, 2, 2);

      .trans(background);
      display: grid;

      grid-template-columns: 1fr 1fr;
      grid-auto-rows: min-content;
      gap: 10px;
      overflow: hidden;
      border-top: solid 1px @grey;
      color: @grey;
      font-variant-numeric: tabular-nums;

      @media @desktop {
        grid-template-columns: 1fr;
      }

      &.selected {
        border-top: solid 1px @color1;
        background-color: @grey1;
      }

      &.hasStatistics {
        grid-template-columns: min-content 1fr min-content;
        cursor: pointer;

        @media @desktop {
          grid-template-columns: min-content;
        }

        .number {
          display: grid;
          grid-template-columns: min-content min-content;
          grid-template-rows: min-content;
          gap: 5px;

          .indicator {
            display: grid;
            align-self: center;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: @color1;
          }
        }

      }

      .weekday {
        display: grid;
        justify-content: end;

        @media @desktop {
          display: none;
        }
      }

      &:hover {
        background-color: @grey1;
      }

      &.currentMonth {
        color: @textColor;

        &.today {
          color: @color1;
          .font-head;
        }

      }

      &.today {
        color: @color1;
        .font-head;
      }

      .statistics {
        display: grid;
        // grid-column-start: 1;
        // grid-column-end: 3;

        gap: 5px;

        @media @desktop {
          grid-column-start: 1;
          grid-column-end: 2;
        }

        .single-statistic {
          display: grid;
        }
      }


    }

    .weekdayContainer {
      display: grid;

      display: none;
      grid-template-rows: 30px;
      grid-template-columns: repeat(7, 1fr);

      @media @desktop {
        display: grid;
      }

      .day {
        align-items: end;
        border-top: 0;
        color: @textColor;
        .multi(padding, 0, 2);

        &:hover {
          background-color: transparent;
        }
      }
    }

    .daysContainer {
      display: grid;

      @media @desktop {
        grid-template-rows: repeat(6, 150px);
        grid-template-columns: repeat(7, calc(100% / 7));

      }
    }

  }

}
</style>

