import Models, { ModelDeclaration } from '@/libs/Models.class';
import { Content } from '@lilaquadrat/interfaces';

export default interface AppPublishSettingEmail {
  type: 'email'
  label: string
  active: boolean
  availableForApps: string[]
  affectedStates: string[]
  availableForContentGroups: string[]
  contextData: Content[]
  mailFrom: string
}

const declaration: ModelDeclaration<AppPublishSettingEmail> = {
  type: { type: 'string', default: 'email' },
  label: { type: 'string' },
  active: { type: 'boolean', default: false },
  availableForApps: { type: 'array', contains: { type: 'string' } },
  affectedStates: { type: 'array', contains: { type: 'string' } },
  availableForContentGroups: { type: 'array', contains: { type: 'string' } },
  contextData: { type: 'array', contains: { type: 'string' } },
  mailFrom: { type: 'string' },
};

Models.register('app-publish-settings-email', declaration);