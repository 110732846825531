<template>
  <article :class="navigationAnimation" class="app-hostingadmin-commands-home-screen">
    <div v-if="content" class="pagenator full">
      <header class="main">

        <section class="stats">
          <h1>{{ $translate('app-hostingadmin-commands') }}</h1>
          <h3 v-if="content.sites">
            <template v-if="!content.sites.last">{{ content.count }}</template>
            <template v-if="content.sites.last">{{ content.sites.range[0] }} - {{ content.sites.range[1] }} ({{ content.count }})</template>
            / {{ content.all }} {{ $translate('total') }}
          </h3>
        </section>

        <app-hostingadmin-quickmenu-partial/>
      </header>

      <filter-partial reload category-type="hostingadmin" store="AppHostingAdminCommands" use-emit @update="updateSearch" @reload="reload">
        <span class="group filter">
          <select-partial inline mobile placeholder="app-hostingadmin-commands-state" :options="stateOptions" :multiple="false" allow-empty :value="filterState" @input="updateSearch({ data: $event, type: 'state' })" />
          <select-partial inline mobile placeholder="app-hostingadmin-commands-target" :options="targetOptions" :multiple="false" allow-empty :value="target" @input="updateSearch({ data: $event, type: 'target' })" />
          <select-partial inline mobile placeholder="app-hostingadmin-commands-command" :options="commandOptions" :multiple="false" allow-empty :value="command" @input="updateSearch({ data: $event, type: 'command' })" />
        </span>
        <section class="group sites">
          <button-partial class="replay" icon="replay" color-scheme="transparent" button-content-type="icon" @click="reload" />
          <sites-partial :data="content" />
        </section>
      </filter-partial>

      <section class="scroll-container">
        <table class="isSticky contentHead">
          <thead-partial :site-current="currentSite" :search="$route.query.search" :sort="sort" :order="order" :content="headContent" />
        </table>

        <table class="data-switch contentTable" :class="contentAnimation">
          <tbody>
            <tr v-for="(single, index) in content.data" :key="`data-overview-${index}`">
              <td class="id fullHeight action" :class="{ hasAttribute: single.app || single.model }">
                <router-link
                  :to="{
                    name: 'app-hostingadmin-company-commands-single',
                    params: {
                      id: single._id.toString(),
                      company: $store.state.Company.company,
                      project: $store.state.Project.project,
                    },
                  }"
                >
                <div class="id">{{ single.target }} {{ single.command }}</div>
                <div class="description">{{single.company}} - {{single.project}}</div>
                    
                </router-link>
              </td>

              <td>
                {{ $translate(`app-hostingadmin-commands-state-${single.state}`) }}
              </td>
              
              <td class="center">
                {{ $date(single.date) }} 
              </td>
              
              <td>
                  {{ $date(single.history.updated) }} 
              </td>

            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <modal-module />

  </article>
</template>
<script lang="ts">
import { ExtComponent, Component, Watch } from '@/libs/lila-component';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import { Hosting, HostingCommand } from '@lilaquadrat/studio/interfaces';
import { DataObject } from '@lilaquadrat/studio/lib/interfaces';
import cleanObject from '@/mixins/cleanObject';
import hardCopy from '@/mixins/hardCopy';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';
import MainStoreState from '@/store/mainStoreState.interface';

@Component
export default class AppHostingAdminHomeScreen extends ExtComponent {

  componentName = 'app-hostingadmin-company-commands-home';

  headContent = [
    {
      text: 'app-hostingadmin-commands-action',
    },
    {
      text: 'app-hostingadmin-commands-state',
    },
    {
      key: 'created',
      text: 'app-hostingadmin-commands-created',
    },
    {
      text: 'app-hostingadmin-commands-updated',
    },
  ];

  stateOptions: {text: string, value: HostingCommand['state']}[] = [
   {
     text: 'app-hostingadmin-commands-state-new',
     value: 'new',
   },
   {
     text: 'app-hostingadmin-commands-state-inProgress',
     value: 'inProgress',
   },
   {
     text: 'app-hostingadmin-commands-state-success',
     value: 'success',
   },
   {
     text: 'app-hostingadmin-commands-state-error',
     value: 'error',
   },
  ];

  targetOptions: {text: string, value: HostingCommand['target']}[] = [
   {
     text: 'app-hostingadmin-commands-target-docker',
     value: 'docker',
   },
   {
     text: 'app-hostingadmin-commands-target-nginx',
     value: 'nginx',
   },
  ];

  commandOptions: {text: string, value: HostingCommand['command']}[] = [
   {
     text: 'app-hostingadmin-commands-command-up',
     value: 'up',
   },
   {
     text: 'app-hostingadmin-commands-command-down',
     value: 'down',
   },
   {
     text: 'app-hostingadmin-commands-command-restart',
     value: 'restart',
   },
   {
     text: 'app-hostingadmin-commands-command-start',
     value: 'start',
   },
   {
     text: 'app-hostingadmin-commands-command-stop',
     value: 'stop',
   },
   {
     text: 'app-hostingadmin-commands-command-rebuild',
     value: 'rebuild',
   },
   {
     text: 'app-hostingadmin-commands-command-kill',
     value: 'kill',
   },
   {
     text: 'app-hostingadmin-commands-command-check',
     value: 'check',
   },
   {
     text: 'app-hostingadmin-commands-command-status',
     value: 'status',
   },
  ];

  @Watch('$route')
  watchRoute() {

    this.setSearch();

  }

  get currentSite() {

    return this.$route.params.site ? +this.$route.params.site : null;

  }

  get filterState() {

    return this.$store.state.AppHostingAdminCommands.search?.state;

  }

  get target() {

    return this.$store.state.AppHostingAdminCommands.search?.target;

  }

  get command() {

    return this.$store.state.AppHostingAdminCommands.search?.command;

  }

  get sort() {

    return this.$store.state.AppHostingAdminCommands.search?.sort;

  }

  get order() {

    return this.$store.state.AppHostingAdminCommands.search?.order;

  }

  get content(): DataObject<HostingCommand[]> {

    return this.$store.state.AppHostingAdminCommands.data || undefined;

  }

  mounted() {

    this.setSearch();

  }


  getSearch() {

    const query: Record<string, string | string[]> = {};

    query.sort = this.$route.query.sort as string || 'date';
    query.order = this.$route.query.order as string || '-1';

    query.tags = this.$route.query.tags || null;
    query.target = this.$route.query.target || null;
    query.command = this.$route.query.command || null;
    query.state = this.$route.query.state || null;
    query.search = this.$route.query.search || null;

    return query;

  }

  setSearch() {

    // only update search if the current component is used and no child is active
    if (this.$route.name !== this.componentName) return;
    this.$store.commit('AppHostingAdminCommands/setSearch', this.getSearch());

  }


  async updateSearch(data: {data: string, type: string}) {

    this.$store.commit('AppHostingAdminCommands/updateSearch', { data: data.data, type: data.type });

    const query = hardCopy(this.$store.state.AppHostingAdminCommands.search);

    cleanObject(query);

    this.$router.push({ params: { site: '1' }, query });

  }

  async reload() {

    StudioSDK.flushCache('commands', 'list');

    const data = await this.$store
      .dispatch('AppHostingAdminCommands/get', {
        query: this.$route.query,
        settings: {
          project: this.$route.params.project,
          company: this.$route.params.company,
          site: this.$route.params.site,
        },
      });

    this.$store.commit('AppHostingAdminCommands/data', data.data);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    console.log(227, params, query, store.state.AppHostingAdminCommands.search);
    return store
      .dispatch('AppHostingAdminCommands/get', {
        site: params.site,
        query,
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<DataObject<Hosting>>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppHostingAdminCommands/data', preloadedData?.data);
    return Promise.resolve();

  }

}
</script>
<style lang="less">


.app-hostingadmin-commands-home-screen {

  .filter-partial {
    display: grid;
    grid-template-columns: max-content 1fr;

    .group {
      display: grid;

      &.filter, &.sites {
        grid-template-columns: max-content max-content max-content;
      }

      &.sites {
        justify-self: end;
      }

    }

    .filter {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 5px;
    }
  }

  table.contentTable, table.contentHead {

    tr {
      grid-template-columns: 1fr 100px 75px 75px;

      td {

        &.hasDescription {

          .id {
            display: grid;
            color: @textColor;
            text-transform: uppercase;
          }

        }

      }

    }

  }

  .sites-filter-container {

    display: grid;
    grid-template-columns: 1fr 1fr;
    .multi(padding-top, 2);
    .multi(padding-bottom, 4);
    .basePadding;

    .sites-container {
      justify-self: end;
    }

    .select {
      display: grid;
      grid-template-columns: 150px 1fr;
    }
  }
}
</style>


