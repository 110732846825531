<script lang="ts" setup>
import { useStore } from '@/store/main.store';
import { watch, computed, ref } from 'vue';
import Apps from '@/apps/apps';
import { vue } from '@/libs/lila-partial';
import OverlayApp from '@/interfaces/OverlayApp.interface';

const store = useStore();
const style = ref({});
const container = ref(null);
const runningApps = ref<Record<string, OverlayApp[]>>({});
const availableAppsVisible = ref(false);
const getRunningApps = async () => {

   runningApps.value = {};

   store.state.Appstore.appsForOverlay.map(async (single) => {

      const app = Apps[single.app];
      const storeName = app.store;

      if(!storeName) return;

      const elements: OverlayApp[] = await store.dispatch(`${storeName}/overlayElements`, {company: single.company, project: single.project});

      if(!elements.length) return;

      const companyProjektKey = `${single.company}${single.project}`;

      if(!runningApps.value[companyProjektKey]) vue.set(runningApps.value, companyProjektKey, []);

      runningApps.value[companyProjektKey].push(...elements);

   });

   return runningApps;

};
// const hasRunningApps = computed<boolean>(() => Object.keys(runningApps.value).length > 0);
const toggleAvailableApps = async () => {

   availableAppsVisible.value = !availableAppsVisible.value;
   /**   
   *
   */
   if(availableAppsVisible.value) await getRunningApps();

};
const startApp = async (appToStart: OverlayApp) => {

   const app = Apps[appToStart.app];
   const storeName = app.store;

   await store.dispatch(`${storeName}/addOverlayElement`, {company: appToStart.company, project: appToStart.project});

   await getRunningApps();

   toggleAvailableApps();

};
const compatibleApps = computed(() => store.state.Appstore.appsForOverlay);

watch(container, async () => {

   if(container.value) {

      await getRunningApps();
   
   } else {

      runningApps.value = {};
      availableAppsVisible.value = false;

   }
   

});


</script>
<template>
   <article ref="container" class="apps-overlay" :style="style">
      <header>
         <h3>Apps</h3>
         <button-partial v-if="!availableAppsVisible" color-scheme="transparent" @click="toggleAvailableApps">{{ $translate('app-app-start-app') }}</button-partial>
         <button-partial v-if="availableAppsVisible" color-scheme="transparent" @click="toggleAvailableApps">apps anzeigen</button-partial>
      </header>

      <section class="scroll-container">

         <section class="runningApps" v-if="!availableAppsVisible">
            <template v-for="(apps, indexApp) in runningApps">
               <template v-for="(app, index) in apps">
                  <component :is="app.element" v-bind="app" overlay :key="`overlay-app-${indexApp}-${index}`">{{ app }} - {{ index }}</component>
               </template>
            </template>
            <!-- <button v-if="!hasRunningApps" class="start-app-button" type="button" @click="toggleAvailableApps">{{ $translate('app-app-start-app') }}</button> -->
         </section>

         <section class="availableApps" v-if="availableAppsVisible">
            <template v-for="(app, index) in compatibleApps">
               <section role="button" tabindex="0" @click="startApp(app)" @keyup="startApp(app)" class="start-app-single" :key="`app-overlay-start-app-${index}`">
                  <h3>{{ $translate(app.element) }}</h3>
                  <div class="companyProject">{{ $translate(`company:${app.company}`) }} - {{ $translate(`project:${app.company}:${app.project}`) }} </div>
               </section>
            </template>
         </section>

      </section>
   </article> 
</template>
<style lang="less" scoped>
.apps-overlay {
  display: grid;
  grid-template-rows: max-content 1fr;
  overflow: hidden;

  header {
    display: grid;
    grid-template-columns: 1fr max-content;
    .multi(padding, 0, 4);

    h3 {
      color: @color1;
      font-size: @fontTextSmaller;
      line-height: @buttonLineHeight;
      text-transform: uppercase;
      .font-head;
    }
  }

  .scroll-container {
    overflow: auto;

    .runningApps, .availableApps {
      display: grid
    }
  }

  .start-app-single {
    .trans(background);
    display: grid;
    cursor: pointer;
    .multi(padding, 2, 4);

    h3 {
      color: @color1;
    }

    &:hover {
      background-color: @grey1;
    }

  }

  .start-app-button {
    display: grid;
    text-align: center;
    .multi(padding, 4);
    .font-head;
    text-transform: uppercase;
    .trans(color);

    &:hover {
      color: @color2;
    }
  }
}
</style>