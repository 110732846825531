<template>
  <section :class="[variant, { hasBackground: colorScheme === 'transparent' }]" class="dialog-partial">
    <slot />

    <section class="message-container">
      <h3 v-if="message">{{$translate(message)}}</h3>
      <p v-if="description">{{$translate(description)}}</p>
    </section>

    <button-group-partial v-if="type === 'check'" gap>
      <button-partial :color-scheme="colorScheme" @confirmed="confirm">{{$translate('confirm')}}</button-partial>
      <button-partial color-scheme="transparent" @confirmed="cancel">{{$translate('cancel')}}</button-partial>
    </button-group-partial>

    <button-group-partial v-if="type === 'confirm'" gap>
      <button-partial color-scheme="transparent" @confirmed="confirm">{{$translate('acknowledge')}}</button-partial>
    </button-group-partial>

  </section>
</template>
<script lang="ts">
import { ExtPartial } from '@/libs/lila-partial';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class DialogPartial extends ExtPartial {

  @Prop(String) type: 'confirm' | 'check';

  @Prop(String) message: string;

  @Prop(String) description: string;

  @Prop(Array) variant: string[];

  @Prop(Object) translations?: {confirm?: string, cancel?: string, acknowledge?: string};

  @Prop(Function) callback?: (confirm: boolean) => void;

  get colorScheme(): string {

    return this.variant?.some((single) => ['error', 'success', 'color1', 'color3'].includes(single)) ? 'transparent' : 'colorScheme1';

  }

  confirm(): void {

    this.$emit('confirm');
    if (this.callback) this.callback(true);

  }

  cancel(): void {

    this.$emit('cancel');
    if (this.callback) this.callback(false);

  }

  get CONFIRM(): string {

    return this.translations?.confirm ?? 'confirm';

  }

  get CANCEL(): string {

    return this.translations?.cancel ?? 'cancel';

  }

  get ACKNOWLEDGE(): string {

    return this.translations?.acknowledge ?? 'acknowledge';

  }

}
</script>
<style lang="less">


.dialog-partial {
  max-width: @moduleWidth_XS;

  .multi(padding, 4);

  &.hasBackground {
    .multi(padding, 4, 4, 2, 4);
  }

  display: grid;
  gap: 20px 10px;

  border: solid 2px;

  box-shadow: 0 0 5px @color1;
  border-color: @grey1;
  background-color: @grey1;
  border-left: solid 2px @color1;

  .message-container {
    h3 {
      color: @color1;
    }
  }

  &.success {
    background-color: @success;
    border: solid 2px @success;
    box-shadow: 0 0 5px @success;
  }

  &.error {
    background-color: @error;
    border: solid 2px @error;
    box-shadow: 0 0 5px @error;
  }

  &.color1 {
    background-color: @color1;
    border: solid 2px @color1;
  }

  &.color3 {
    background-color: @color3;
    border: solid 2px @color3;
  }

  &.success,
  &.error,
  &.color1,
  &.color3 {

    button {
      color: @white;
    }

    .message-container {
      button,
      h3,
      p {
        color: @white;
      }
    }

  }

  .message-container {
    display: grid;
    gap: 10px;

    h3 {
      .font-head;
      font-size: @headline_XS;
      text-transform: uppercase;
    }
  }

}
</style>
