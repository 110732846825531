<template>
  <article :class="navigationAnimation" class="app-publish-endpoints-screen">
    <side-screen-header-partial>{{$translate('app-publish-methods')}}</side-screen-header-partial>
    <router-view class="content-container" :key="`app-publish-methods-overview-${$route.params.link}`" />
  </article>
</template>
<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';

@Component
export default class AppPublishHomeScreen extends ExtComponent {

  componentName = 'app-publish-methods-overview';

}
</script>
