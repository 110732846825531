<template>
  <article class="app-tracker-add-screen" :class="navigationAnimation">

    <side-screen-header-partial>{{$translate('app-tracker-add')}}</side-screen-header-partial>

    <section class="content-container">

      <form v-if="model" class="content" @submit="save">

        <select-partial v-model="model.category" placeholder="app-tracker-category" :multiple="false" required :error="errorsObject.categories" :options="categories">{{$translate('app-tracker-description')}}</select-partial>

        <textarea-partial v-model="model.description" max-length="200" placeholder="app-tracker-description">{{$translate('app-tracker-description')}}</textarea-partial>
        <datepicker-partial range time :from="model.startTime" :to="model.endTime" @updateTo="(toDate) => model.endTime = toDate" @updateFrom="(fromDate) => model.startTime = fromDate" />

        <action-notice-partial :state="state" translation-pre="app-tracker" :errors="errors" @update="updateErrors">
          <button-partial type="submit" v-model="state" save>{{$translate('app-tracker-add-button')}}</button-partial>
        </action-notice-partial>
        
      </form>

    </section>

  </article>
</template>
<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { ErrorObject } from 'ajv';
import { ErrorsObject } from '@/libs/ActionNotice';
import SelectOption from '@/interfaces/selectOption.interface';
import ModelsClass from '@/libs/Models.class';
import Tracker from '../models/Tracker.model';

dayjs.extend(duration);

@Component
export default class AppTrackerHomeScreen extends ExtComponent {

  componentName = 'app-tracker-project-add';

  model: Tracker = null;

  state: string = '';

  errors: {message?: string, errors?: ErrorObject[]} = {};

  errorsObject: ErrorsObject = {};

  categories: SelectOption[] = [
    { value: 'call', text: 'app-tracker-calls' },
    { value: 'worktime', text: 'app-tracker-worktime' },
  ];


  mounted() {

    this.setModel();

  }

  setModel() {

    this.model = ModelsClass.add<Tracker>({ startTime: new Date(), endTime: dayjs().add(10, 'minutes').toDate(), company: this.$store.state.Company.company, project: this.$store.state.Project.project }, 'tracker');

  }

  async save($event: MouseEvent) {

    $event.preventDefault();

    const cleanData = ModelsClass.save(this.model, 'tracker');

    cleanData.currentTime = new Date(cleanData.endTime).getTime() - new Date(cleanData.startTime).getTime();

    this.state = '';

    console.log(cleanData);


    try {

      const response = await this.$store.dispatch('AppTracker/save', cleanData);

      this.state = 'success';

      console.log(response);

      // const single = await this.$store.dispatch('AppTracker/single', this.$route.params.id);

      // this.$store.commit('AppTracker/single', single.data);
      // this.setModel();
      this.$router.push({ name: 'app-tracker-project-single', params: { id: response.data._id } });

    } catch (error) {

      this.errors = error.response.data;
      this.state = 'error';

    }


  }

  updateErrors(errorsObject: ErrorsObject) {

    this.errorsObject = errorsObject;

  }


}
</script>
<style lang="less" scoped>

.app-tracker-single-screen {

  .edit-container {
    display: grid;
    gap: 20px;
  }

}
</style>

