<template>
  <label class="input-partial" :class="[{ error: hasError }, mode, type]" tabindex="" >
    <input
      ref="input"
      :type="type"
      :placeholder="$translate(placeholder)"
      :name="name"
      :disabled="disabled"
      :value="value"
      @input="input($event.target.value)"
      @keydown="checkInput($event)"
      @keyup="checkKeyup($event)"
      @focus="onFocus"
      @blur="$emit('blur')"
      @paste="paste($event)"
    />

    <button-partial v-if="clearIcon" variant="clear" @click="clear" />

    <div v-if="slotUsed || required || disabled" class="label-container">
      <span v-if="slotUsed" class="label">
        <slot />
      </span>
      <span v-if="required && !disabled" class="required">{{$translate('required')}}</span>
      <span v-if="disabled" class="required">{{$translate('disabled')}}</span>
    </div>

    <notice-partial v-if="errorMessage" type="error">
      {{errorMessage}}
    </notice-partial>

    <description-partial v-if="description" class="input-description">{{$translate(description)}}</description-partial>

  </label>
</template>
<script lang="ts">
import { ParsedError } from '@/libs/ActionNotice';
import { ExtPartial } from '@/libs/lila-partial';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class inputPartial extends ExtPartial {

  @Prop(String) type: string;

  @Prop(String) placeholder: string;

  @Prop(String) name: string;

  @Prop([String, Number]) value: string;

  @Prop(Object) error: ParsedError;

  @Prop(String) regex: string;

  @Prop(String) description: string;

  @Prop([RegExp, String]) validation: RegExp | string;

  @Prop(Boolean) required: boolean;

  @Prop(Boolean) disabled: boolean;

  @Prop(String) debounce: number;

  @Prop(Boolean) dontClear: boolean;

  @Prop(Boolean) clearIcon: boolean;

  @Prop(Boolean) autofocus: boolean;

  @Prop(Boolean) selectOnFocus: boolean;

  @Prop(String) mode: 'inlineHead' | 'inlineHeadBackground';

  timeout: any;

  whitelistedKeys = ['Escape', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Home', 'End', 'Delete', 'Tab'];

  $refs: {
    input: HTMLInputElement
  };

  get slotUsed() {

    return !!this.$slots.default?.length;

  }

  get errorMessage() {

    return this.error?.keyword !== 'required' ? this.error?.error : null;

  }

  get hasError() {

    return !!this.error?.error;

  }

  onFocus() {

    this.$emit('focus');
    if (this.selectOnFocus) this.$refs.input.select();

  }

  checkInput($event: KeyboardEvent) {

    const input = this.$refs.input;
    let value: string;


    if (this.selectOnFocus && (input.selectionStart < input.selectionEnd) && !this.whitelistedKeys.includes($event.key)) {

      value = $event.key;

    } else {

      value = !this.whitelistedKeys.includes($event.key) ? input.value + $event.key : input.value;

    }

    if ($event.key === 'Enter') {

      this.$emit('enter');
      $event.preventDefault();

    } else {

      this.$emit('keydown', $event);

    }


    if (['Backspace', 'Enter'].includes($event.key)) return;

    if (this.regex && !this.whitelistedKeys.includes($event.key)) {

      if (!$event.key.match(new RegExp(this.regex))) {

        $event.preventDefault();
        return;

      }

      if (!this.validate(value)) $event.preventDefault();

      return;

    }

    if ($event.key === 'Escape') {

      if (this.dontClear) {

        input.blur();

      } else if (input.value.length > 0) {

        this.clear();

      } else if (input.value.length === 0) {

        input.blur();

      }

      return;

    }

    if (!this.validate(value)) $event.preventDefault();

  }

  clear() {

    const input = this.$refs.input as HTMLInputElement;

    input.value = '';
    this.input('');

  }

  mounted() {

    if (this.autofocus) {

      this.$nextTick()
        .then(() => {

          this.$refs.input.focus();

        });

    }

  }

  // eslint-disable-next-line class-methods-use-this
  paste($event: ClipboardEvent): void {

    const text = $event.clipboardData?.getData('text');

    // if (!text) $event.preventDefault();
    // if (!this.validate(text)) $event.preventDefault();

  }


  input(value: string) {

    if (!this.debounce) return this.emitValue(value);

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {

      this.emitValue(value);

    }, +this.debounce);

    return value;

  }


  checkKeyup($event: KeyboardEvent) {

    const input: HTMLInputElement = this.$el.querySelector('input');

    this.$emit('keyup', $event);

    if ($event.keyCode === 229) {

      if (!this.validate(input.value)) {

        input.value = input.value?.slice(0, -1);

      }

      this.input(input.value);

    }

  }

  validate(input: string) {

    if (this.validation) {

      return input.match(new RegExp(this.validation));

    }

    if (this.type === 'number') {

      return input.match(/^[0-9]*$/);

    }

    return true;

  }

  emitValue(value: string | number) {

    const typeValue = this.type === 'number' ? +value : value;

    this.$emit('input', typeValue);

  }

}
</script>
<style lang="less" scoped>

.input-partial {
  position: relative;
  display: grid;
  gap: 5px;

  input {
    .formBorder;
    .font-normal;
    .lineHeight(@fontText);
    .multi(padding, 1, 0);

    .trans(border);

    position: relative;

    display: block;

    width: 100%;
    background-color: transparent;

    color: @textColor;

    font-size: @fontText;
    caret-color: @color1;

    &::selection {
      background: @color1;
      color: @white;
    }

    &:hover {
      border-color: @grey;
    }

  }

  &.date {

    input {

      border-bottom: 0;
    }
  }

  &.inlineHead, &.inlineHeadBackground {

    input {
      height: 35px;
      padding: 0;
      border: 0;
      color: @color1;
      line-height: 35px;

      &:hover {
        background-color: @grey2;
      }
    }

  }

  &.inlineHeadBackground {

    input {

      &:hover {
        background-color: transparent;
      }

      &::selection {
        background: @white;
        color: @textColor;
      }

    }
  }

  .label-container {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  &.error {

    input {
      border-color: @error;
    }

    .required {
      color: @error;
    }

  }

  &.number {
    position: relative;

    input {
      font-size: @fontText;

      text-align: center;

      &::placeholder {
        font-size: @fontText;
      }
    }
  }

  button {
    position: absolute;

    top: 0;
    right: 20px;
    height: 35px;

    .icon {
      display: grid;
      text-indent: 0;

      svg {
        align-self: center;
        justify-self: end;
        width: 10px;

        height: 10px;

        .trans(fill);
      }
    }

    &:hover {

      .icon {

        svg {
          fill: @color2;
        }
      }
    }

  }
}
</style>
