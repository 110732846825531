<template>
  <section class="editor-controls-editor">
    <input-partial v-model="safeContent.id" placeholder="ID">ID</input-partial>

    <editor-content-group-partial v-if="layoutMode" title="layout">
      <select-partial placeholder="NO_VARIANTS_CHOOSEN" :multiple="false" :options="layoutPosition" v-model="safeContent.layout">{{ $translate('LAYOUT_POSITION') }}</select-partial>
    </editor-content-group-partial>

    <editor-content-group-partial title="position">
      <select-partial placeholder="NO_VARIANTS_CHOOSEN" :multiple="false" :options="positions" @input="$store.commit('AppEditorData/active', false)" v-model="safeContent.position">{{ $translate('position')}}</select-partial>
    </editor-content-group-partial>

    <editor-content-group-partial v-if="!noExport" title="export" closed>
      <textarea-partial readonly :safeContent="exportData" description="copy the content and import it in your target data" />
    </editor-content-group-partial>

    <editor-content-group-partial title="options">
      <ul>
        <li><button-partial class="colorScheme2 base full" doublecheck @confirmed="remove()">{{ $translate('remove this module')}}</button-partial></li>
        <li><button-partial class="colorScheme2 base full" doublecheck @confirmed="clone()">{{ $translate('clone this module') }}</button-partial></li>
      </ul>
    </editor-content-group-partial>
  </section>
</template>
<script lang="ts">
import { Component, PartialEditorComponent, Prop } from '@/libs/PartialEditorComponent';
import { ModuleGeneric } from '@lilaquadrat/studio/lib/interfaces';

@Component
export default class EditorControlsEditor extends PartialEditorComponent<ModuleGeneric> {

  @Prop(Boolean) noExport: boolean;

  positions = [
    { value: 'top', text: 'POSITION_TOP' },
    { value: 'content', text: 'POSITION_CONTENT' },
    { value: 'bottom', text: 'POSITION_BOTTOM' },
  ];

  layoutPosition = [
    { value: 'first', text: 'LAYOUT_POSITION_FIRST' },
    { value: 'last', text: 'LAYOUT_POSITION_LAST' },
  ];

  remove(): void {

    this.$emit('remove');

  }

  clone(): void {

    this.$emit('clone');

  }

  get exportData(): string {

    return JSON.stringify([this.safeContent]);

  }

  get layoutMode(): boolean {

    return this.$store.state.AppEditorData.content.layout;

  }

}
</script>
