<template>
  <header>
    <h3><slot /></h3>
    <button-partial buttonContentType="icon" icon="arrow-up" colorScheme="colorScheme2" @click="toggle" />
  </header>
</template>

<script lang="ts">
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import { Component, Prop } from '@/libs/lila-component';
import ModuleGenericExtended from '../interfaces/ModuleGenericExtended.interface';

@Component
// eslint-disable-next-line global-require
export default class ModuleEditorHeaderPartial extends PartialEditorComponent<ModuleGenericExtended> {

  @Prop(String) position: string;

  toggle() {

    if (this.type !== 'overview') {

      this.$store.dispatch('AppEditorData/toggleModule', false);

    } else {

      this.$store.dispatch('AppEditorData/toggleModule', { position: this.position, uuid: this.content.uuid });

    }

  }

}
</script>
<style lang="less" scoped>

header {
  display: grid;
  grid-template-columns: 1fr 35px;
  gap: 10px;
  width: 100%;

  .basePadding;
  .multi(padding-top, 2);

  @media @tablet, @desktop {

    .multi(padding, 4, 4, 0, 4);
  }

  h3 {
    .font-head;
    display: grid;
    align-content: center;
    color: @color1;
    font-size: @fontText;
    line-height: 35px;
    text-transform: uppercase;
    .lineHeight(@fontText);
  }

}
</style>
