<template>
  <article :class="navigationAnimation">
    <side-screen-header-partial>{{$translate(title)}}</side-screen-header-partial>

    <section v-if="isAdmin" class="content-container">
      <form class="content" @submit="save">
        <copy-partial id="app-appstore-project-single-add" :value="model" @paste="handlePaste" />
        <fieldset>
          <input-partial v-model="model.name" placeholder="APP_NAME">{{$translate('APP_NAME')}}</input-partial>
          <input-partial v-model="model.id" :disabled="mode !== 'add'" placeholder="id">{{$translate('id')}}</input-partial>

          <textarea-partial v-model="model.description" placeholder="Description" max-length="250">{{$translate('APP_DESCRIPTION')}}</textarea-partial>

          <input-partial v-model="model.icon" placeholder="icon">{{$translate('icon')}}</input-partial>

          <checkbox-partial v-model="model.companyAvailable">{{$translate('APP_COMPANY_SUPPORT')}}</checkbox-partial>
          <checkbox-partial v-model="model.projectAvailable">{{$translate('APP_PROJECT_SUPPORT')}}</checkbox-partial>

          <checkbox-partial v-model="model.universal">{{$translate('universal')}}</checkbox-partial>

          <select-partial v-model="model.attributes" placeholder="app-app-attributes" :options="attributes">{{$translate('app-app-attributes')}}</select-partial>

          <select-partial v-model="model.available" placeholder="app-app-state" :multiple="false" :options="availableOptions">{{$translate('app-app-state')}}</select-partial>

          <list-partial-editor v-model="model.models" no-head>{{$translate('app-app-used-models')}}</list-partial-editor>
        </fieldset>

        <button-group-partial>
          <button-partial v-model="state" type="submit" class="base colorScheme1" save>
            <template v-if="mode === 'edit'">{{$translate('Save Changes')}}</template>
            <template v-if="mode === 'add'">{{$translate('APP_ADD')}}</template>
          </button-partial>
          <button-partial v-if="mode === 'edit'" class="base colorScheme2" doublecheck @confirmed="remove">
            {{$translate('APP_REMOVE')}}
          </button-partial>
        </button-group-partial>
      </form>
    </section>

    <section v-if="!isAdmin" class="content-container">
      <form class="content" @submit="toggle">
        <div class="description-partial">
          <p>{{ model.description }}</p>
        </div>

        <checkbox-partial v-model="model.active">{{$translate('active')}}</checkbox-partial>
        <button-group-partial>
          <button-partial v-model="state" type="submit" class="base colorScheme1" save>
            <template v-if="mode === 'edit'">{{$translate('Save Changes')}}</template>
          </button-partial>
        </button-group-partial>
      </form>
    </section>
  </article>
</template>
<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import AppInterface from '@/interfaces/App.interface';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import { Project } from '@lilaquadrat/studio/lib/interfaces';
import ModelsClass from '@/libs/Models.class';
import { App } from '@lilaquadrat/interfaces';
import AppAppstore from '../models/app.model';


@Component
export default class AppAppSingleScreen extends ExtComponent {

  componentName = ['app-appstore-company-single', 'app-appstore-project-single-add', 'app-appstore-project-single', 'app-appstore-company-single-add'];

  model: AppAppstore = null;

  mode: 'edit' | 'add' = 'add';

  state: string = '';

  attributes = [
    {
      value: 'previewInternal',
      text: 'app-app-preview-internal',
    },
    {
      value: 'previewPublic',
      text: 'app-app-preview-public',
    },
    {
      value: 'paid',
      text: 'app-app-paid',
    },
  ];

  availableOptions = [
    {
      value: 'unavailable',
      text: 'app-app-unavailable',
    },
    {
      value: 'admin',
      text: 'app-app-admin',
    },
    {
      value: 'internal',
      text: 'app-app-internal',
    },
    {
      value: 'preview',
      text: 'app-app-preview',
    },
    {
      value: 'public',
      text: 'app-app-public',
    },
  ];

  get content(): AppInterface {

    return this.$store.state.Appstore.single;

  }

  get available(): AppInterface {

    return this.$store.state.Appstore.single.available;

  }

  get active(): AppInterface {

    return this.$store.state.Appstore.single.active;

  }

  get title(): string {

    return this.mode === 'edit' ? this.content.name : 'APP_ADD';

  }

  get isPartner() {

    const { attributes } = this.$store.state.Company.settings;

    return attributes?.includes('partner');

  }

  get isAdmin(): boolean {

    const { scope } = this.$store.state.Company.permissions;
    const { attributes } = this.$store.state.Company.settings;

    return attributes?.includes('admin') && scope?.includes('app:edit') && this.$store.state.Appstore.admin;

  }

  handlePaste(data: AppAppstore) {

    delete data._id;
    this.model = ModelsClass.add<AppAppstore>(data, 'app-appstore');

  }

  beforeMount() {

    this.mode = ['app-appstore-project-single-add', 'app-appstore-company-single-add']?.includes(this.$route.name) ? 'add' : 'edit';

    this.model = ModelsClass.add(this.mode === 'edit' ? this.$store.state.Appstore.single : {}, 'app-appstore');


  }

  // eslint-disable-next-line class-methods-use-this
  asyncData(params, query, store, to) {

    if (['app-appstore-project-single-add', 'app-appstore-company-single-add'].includes(to.name)) return Promise.resolve();

    const isPartner = store.state.Company.settings.attributes?.includes('admin') && store.state.Appstore.admin;

    return store.dispatch('Appstore/single', {
      settings: {
        company: store.state.Company.company,
        project: store.state.Project.project,
        id: to.params.app,
        admin: isPartner,
      },
    });

  }

  remove() {

    this.$store.dispatch('Appstore/remove', this.model._id);

  }

  toggle($event: MouseEvent) {

    $event.preventDefault();
    this.$store
      .dispatch('Appstore/toggle', {
        id: this.model.id,
        active: this.model.active,
        company: this.$store.state.Company.company,
        project: this.$store.state.Project.project,
      })
      .then(async () => {

        StudioSDK.flushCache('apps');
        StudioSDK.flushCache('projects');
        
        this.$store.commit('Appstore/installed', false);
        this.$store.dispatch('Appstore/getActive', {
          company: this.$store.state.Company.company,
          project: this.$store.state.Project.project,
        });

        const project: SDKResponse<Project> = await this.$store.dispatch('Project/getSettings', {
          company: this.$store.state.Company.company,
          project: this.$store.state.Project.project,
        });

        this.$store.commit('Project/settings', project.data);

        this.state = 'success';
        return true;

      })
      .catch(() => {

        this.state = 'error';

      });

  }

  save($event: MouseEvent) {

    // const data = new AppModel().setData(this.model, false, 'save');
    const data = ModelsClass.save(this.model, 'app-appstore');

    $event.preventDefault();
    console.log($event);
    this.state = '';

    this.$store
      .dispatch('Appstore/save', { data, admin: this.isPartner })
      .then(() => {

        this.state = 'success';
        return true;

      })
      .catch(() => {

        this.state = 'error';

      });

  }

}
</script>
