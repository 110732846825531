import Models, { ModelDeclaration } from '@/libs/Models.class';
import { AppEditorConfiguration } from '@lilaquadrat/interfaces';

const declaration: ModelDeclaration<AppEditorConfiguration> = {
  editorUrl: { type: 'string' },
  title: { model: 'editor-title-description' },
  description: { model: 'editor-title-description' },
  preloadImages: { type: 'boolean', default: false },
  breakpointTablet: { type: 'string', default: 'only screen and (min-width: 600px) and (max-width: 899px)' },
  breakpointDesktop: { type: 'string', default: 'only screen and (min-width: 900px)' },
  breakpointWide: { type: 'string', default: 'only screen and (min-width: 1200px)' },
};

Models.register('editor-configuration', declaration);