import Models, { ModelDeclaration } from '@/libs/Models.class';
import ListString from './ListString.model';
import ListLink from './ListLink.model';
import Textblock from './Textblock.model';

export default interface AccordionElement {
  textblock: Textblock
  list: ListString
  links: ListLink
}

const declaration: ModelDeclaration<AccordionElement> = {
  textblock: { model: 'textblock' },
  list: { model: 'list-string' },
  links: { model: 'list-link' },
};

Models.register('accordion-element', declaration);