// import modules from '@/apps/editor/editor-modules';
// import ModuleModel from '@/models/Module.model';

// export default class PartialModuleModel extends ModuleModel {

//   type: keyof typeof modules = 'partial-module';

//   company = '';

//   project = '';

//   modules = [];

//   partialId: string = '';

//   uuid = true;

// }


import Models, { ModelDeclarationExtended, ModelOptions } from '@/libs/Models.class';
import Module from '@/models/Module.model';

export default interface PartialModule extends Module {
  company: string
  project: string
  partialId: string
  modules: string[]
}

const declaration: ModelDeclarationExtended<PartialModule, Module> = {
  company: { type: 'string' },
  project: { type: 'string' },
  partialId: { type: 'string' },
  modules: { type: 'array', contains: { type: 'object' } },
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('partial-module', declaration, {}, options);