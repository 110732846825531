import vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import inview from '@/libs/lila-inview';
import uuid from '@/mixins/uid';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import relativeTime from 'dayjs/plugin/relativeTime';
import moment from '@/mixins/moment';
import { Auth } from './lila-auth';

dayjs.extend(relativeTime);

dayjs.locale('de');

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave',
]);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@Component
abstract class BaseEditorComponent extends vue {

  @Prop(String) type: string;

  @Prop(Number) index: number;

  child: string = '';

  timeout: NodeJS.Timeout;

  public uuid: string;


  debounce(time: number, func: () => unknown) {

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {

      func();

    }, time);

  }

  /**
   * if deep is true, the child command came from a child element and therefore no update or crumbs
   */
  setChild(child: string, deep?: boolean) {

    if (this.child === child) {

      this.unsetCurrentChild();
      return;

    }

    if (!deep) this.$store.commit('AppEditorData/add', { title: child, unset: this.unsetChild(this), parent: this.uuid });
    this.child = child;

  }

  unsetCurrentChild() {

    this.child = '';

  }

  // eslint-disable-next-line class-methods-use-this
  constructor() {

    super();
    this.uuid = uuid();

  }

  // eslint-disable-next-line class-methods-use-this
  unsetChild(ref: any) {

    return () => {

      ref.child = '';

    };

  }

  scope(scope: string[]): boolean {

    if (!scope) return true;
    return Auth.checkScope(this.$store.state.user, scope, this.$store.state.Company.company, this.$store.state.Project.project);

  }

}


export {
  Component,
  Prop,
  Watch,
  inview,
  vue,
  BaseEditorComponent,
};
