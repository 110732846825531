<template>
  <section class="prices-module-editor open editor" :class="{ changed: safeContent.changed }">
    <module-editor-header-partial v-model="content" :position="position" :index="index" :type="type" @move="move($event)" />
    <crumbs-partial :type="safeContent.type" @clearCrumbs="unsetCurrentChild" />

    <section :class="{ optionsVisible: !child }" class="editor-module-container">
      <moduleList-partial v-if="!child" class="module-editor-navigation">
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('textblock')">{{$translate('textblock')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('elements')">{{$translate('elements')}}</button-partial>
        </li>
      </moduleList-partial>

      <textblock-partial-editor v-if="child === 'textblock'" v-model="safeContent.textblock" :inactive="['text', 'list']" />

      <list-elements-partial-editor v-if="child === 'elements'" v-model="elements" type="pricesElement-partial" :model="listModel" partial @input="update($event)" />

      <module-options-partial
        v-if="child === 'options' || !child"
        v-model="safeContent"
        :available-variants="availableVariants"
        :selected-variants="safeContent.variant"
        @setChild="setChild('options', true)"
        @unsetChild="setChild('options')"
        @remove="remove()"
        @clone="clone()"
      />

    </section>
  </section>
</template>

<script lang="ts">
import { Component, ModuleEditorComponent } from '@/libs/ModuleEditorComponent';
import PricesModule from '../models/PricesModule.model';


@Component
// eslint-disable-next-line global-require
export default class PricesModuleEditor extends ModuleEditorComponent<PricesModule> {

  model = 'prices-module';

  listModel = 'prices-element';

  get elements() {

    return this.safeContent.elements;

  }

  set elements(elements: any) {

    this.safeContent.elements = elements;

  }

  update(event) {

    this.safeContent.elements = event;

  }

}

</script>
