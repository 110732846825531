<template>
  <section class="date-picker" :class="[mode]">
    <section class="label-replacement">
      <span class="label"><slot /></span>
      <app-tracker-time-partial :value="timePassed" text />
    </section>
    <section class="picker-container">
      <datetime-picker-partial @input="set($event, 'from')" :mode="mode === 'rangeTime' ? 'time' : 'dateTime'" :value="tempFrom">{{$translate('date-picker-from')}}</datetime-picker-partial>
      <datetime-picker-partial @input="set($event, 'to')" :mode="mode === 'rangeTime' ? 'time' : 'dateTime'" :value="tempTo" v-if="mode === 'range' || mode === 'rangeTime'">{{$translate('date-picker-to')}}</datetime-picker-partial>
    </section>
    <notice-partial v-if="error" type="error">
      {{$translate(`date-picker-${error}`)}}
    </notice-partial>
  </section>
</template>

<script lang="ts">
import DateRange from '@/interfaces/DateRange.interface';
import {
  ExtComponent, Component, Prop,
} from '@/libs/lila-component';
import dayjs from 'dayjs';

@Component
export default class datePickerPartial extends ExtComponent {

  @Prop(Object) value: {from: Date, to?: Date};

  @Prop(String) after: string;

  @Prop(String) before: string;

  @Prop(String) mode: 'range' | 'date' | 'dateTime' | 'rangeTime';

  error: 'after' | 'before' | 'fromAfterTo' | null = null;

  tempFrom = new Date();

  tempTo = new Date();

  mounted() {

    this.setTemp();

  }

  get timePassed() {

    const timePassed = this.tempTo.getTime() - this.tempFrom.getTime();

    if(timePassed < 0) return 0;

    return timePassed;

  }

  setTemp() {

    this.tempFrom = this.value?.from || dayjs().startOf('hour').toDate();
    this.tempTo = this.value?.to || dayjs().startOf('hour').toDate();

  }


  set(date: Date, type: 'from' | 'to') {

    if (type === 'from') this.tempFrom = date;
    if (type === 'to') this.tempTo = date;

    this.update();

  }

  update() {

    const outputFrom = dayjs(this.tempFrom);
    const output: DateRange = { from: outputFrom.toDate() };
    let outputTo: dayjs.Dayjs;

    if (this.mode === 'range' || this.mode === 'rangeTime') {

      outputTo = dayjs(this.tempTo);
      output.to = outputTo.toDate();

    }


    if (outputFrom.isAfter(outputTo)) {

      this.error = 'fromAfterTo';
      return;

    }

    // if (this.after) {

    //   if (!outputDate.isAfter(dayjs(this.after))) {

    //     console.log('not after');
    //     this.error = 'after';
    //     return;

    //   }

    // }

    // if (this.before) {

    //   if (!outputDate.isBefore(dayjs(this.before))) {

    //     console.log('not before');
    //     this.error = 'before';
    //     return;

    //   }

    // }

    console.log(output);

    this.$emit('input', output);
    this.error = null;

  }


}
</script>
<style lang="less" scoped>

.date-picker {
  display: grid;
  gap: 10px;

  .picker-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(225px,min-content));
    gap: 10px;
  }

  &.rangeTime {

    .picker-container {
      grid-template-columns: max-content max-content;
    }
  }


}
</style>
