<template>
  <article :class="navigationAnimation" class="app-learn-single-screen">
    <header-partial>
      <div class="overview-link">
        <router-link :to="{ name: 'app-learn-project-home' }">{{$translate('app-learn-back-to-overview')}}</router-link>
        <icons-partial type="arrow-right" size="small" />
      </div>
      <section class="title">
        <h4>{{content.settings.title}}</h4>
        <p>{{content.settings.description}}</p>
        <div>
          <span v-for="(single, index) in contentRaw.tags" :key="`search-result-data-tags-${index}`">#{{single}} </span>
        </div>
      </section>
    </header-partial>

    <lila-content-module class="contentUpdate" :class="contentAnimation" :routeBase="routeBase" v-if="content" :content="content" />
    <lila-content-module class="footer" v-if="searchFooter" inline :content="searchFooter" />

  </article>
</template>
<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import { prepareContent } from '@lilaquadrat/studio/lib/frontend';
import { state } from '@/store/main.store';
import MainStoreState from '@/store/mainStoreState.interface';
import { Route } from 'vue-router';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppLearnSingleScreen extends ExtComponent {

  componentName = 'app-learn-project-content';

  routeBase: string = '';

  constructor() {

    super();

    this.routeBase = `/${[state.Company.company, state.Project.project, 'a', 'learn', 'c'].filter((single) => single).join('/')}`;

  }

  mounted() {

    this.$store.dispatch('AppLearn/footer');

  }

  get contentRaw() {

    return this.$store.state.AppLearn.single;

  }

  get content() {


    return prepareContent(this.$store.state.AppLearn.single);

  }

  get searchFooter() {

    return this.$store.state.AppLearn.footer ? prepareContent(this.$store.state.AppLearn.footer, null) : null;

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string|string[]>, store: Store<MainStoreState>, to: Route) {

    return store.dispatch('AppLearn/single', { link: params.link });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData, params: Dictionary<string>, query: Dictionary<string|string[]>, store: Store<MainStoreState>, to: Route) {

    store.commit('AppLearn/single', preloadedData.data);
    return Promise.resolve();

  }


}
</script>
<style lang="less" scoped>


.app-learn-single-screen {

  .multi(margin-bottom, 10);
  display: grid;
  gap: 40px;

  .header-partial {

    .multi(padding, 4);
    .index(8);

    display: grid;
    grid-template-rows: min-content;
    grid-template-columns: max-content 1fr;
    gap: 5px;

    background-color: @grey1;

    @media @tablet, @desktop {
      .multi(padding, 4, 8);
    }

    .overview-link {
      display: grid;
      grid-template-rows: min-content;
      grid-template-columns: max-content 1fr;
      gap: 5px;

      a {
        font-size: @fontText;
        .font-head;
      }
    }

    .title {
      display: grid;
      gap: 5px;
    }

  }

  .lila-content-module.footer {
    display: grid;
    .multi(margin-top, 20);
  }
}

</style>

