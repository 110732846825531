<template>
  <section class="upload-partial">
    <section :class="{ finished: isReady }">

      <router-link v-if="!stateOnly" class="main" :class="{ warning: uploadState.error > 0 }" :to="{ name: `app-media-${name}-upload` }">
        <icons-partial class="warning" v-if="uploadState.error > 0" type="warning" colorScheme="red" size="large" />
        <icons-partial :title="$translate('MEDIA_UPLOAD_HISTORY')" :aria-label="$translate('MEDIA_UPLOAD_HISTORY')" size="large" :animate="uploading" type="upload" />
      </router-link>
      <section v-if="stateOnly" class="main" :class="{ warning: uploadState.error > 0 }">
        <icons-partial class="warning" v-if="uploadState.error > 0" type="warning" colorScheme="red" size="large" />
        <icons-partial :title="$translate('MEDIA_UPLOAD_HISTORY')" :aria-label="$translate('MEDIA_UPLOAD_HISTORY')" size="large" :animate="uploading" type="upload" />
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import UploadState from '@/apps/media/interfaces/uploadState.interface';
import {
  ExtComponent, Component, Prop,
} from '@/libs/lila-component';

@Component
export default class uploadPartial extends ExtComponent {

  @Prop(Object) uploadState: UploadState;

  /**
  * show only the icons for the upload state and not the link to the upload page
  */
  @Prop(Boolean) stateOnly: boolean;

  name = 'project';

  get percent() {

    return Math.round(100 - this.uploadState.new / (this.uploadState.all / 100))
      .toString()
      .padStart(3, '0');

  }

  get isReady() {

    return this.uploadState.new === 0 && this.uploadState.all > 0;

  }

  get isStarted() {

    return this.uploadState.all > 0;

  }

  get uploading() {

    return this.$store.state.AppMedia.uploading;

  }

}
</script>

<style lang="less" scoped>

.upload-partial {

  .main {
    display: grid;
    grid-template-rows: 35px;
    grid-template-columns: 35px;

    &.warning {
      grid-template-columns: 35px 35px;
    }

  }

}
</style>
