<script setup lang="ts">
import { useStore } from '@/store/main.store';
import { onUnmounted , onMounted , computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router/composables';

const mainStore = useStore();
const route = useRoute();
const overlayVisible = ref<boolean>(false);
const navigationVisible = ref<boolean>(false);
const element = ref<HTMLElement>();
const appOverlayButton = ref<HTMLElement>();
const navigationButton = ref<HTMLElement>();
const overflowContainer = ref<HTMLElement>();
const isOverflowing = ref<boolean>(false);
const app = computed(() => mainStore.state.activeApp);
const appTranslateName = computed(() => `app-${app.value}`);
const hasProject = computed(() => mainStore.state.Project.project);
const company = computed(() => mainStore.state.Company.company);
const project = computed(() => mainStore.state.Project.project);
const init = computed(() => mainStore.state.Navigation.init);
const appHome = computed(() => hasProject.value 
    ? `app-${app.value}-project-home`
    : `app-${app.value}-company-home`
);
const routeSub = computed(() => route.meta.routeSub || mainStore.state.Navigation.activeElement);
const lastEntry = computed(() => {

    if(routeSub.value) return routeSub.value;
    if(app.value) return appTranslateName.value;
    if(mainStore.state.Project.project) return mainStore.state.Project.settings.name;
    if(mainStore.state.Company.company) return mainStore.state.Company.settings.name;
    return mainStore.state.user.prename;

});

async function calcOverflow() {

    if(!overflowContainer.value || !element.value) return;

    const bounds = overflowContainer.value?.getBoundingClientRect();
    const parent = element.value;
    const parentBounds = parent?.getBoundingClientRect();

    isOverflowing.value = bounds?.width > (parentBounds?.width || 0) - 35;


}

onMounted(() => {

    const observer = new MutationObserver(() => {

      calcOverflow();
  
    });

    /**
    * recalculate every time the overflow container or its children changes
    */
    observer.observe(overflowContainer.value, { childList: true });

    calcOverflow();

    onUnmounted(() => {

        observer.disconnect();
    
    });

});

window.addEventListener('resized', () => {

    calcOverflow();

});

watch(route, () => {

    overlayVisible.value = false;
    navigationVisible.value = false;

});

function toggleAppOverlay() {

    overlayVisible.value = !overlayVisible.value;

}

function toggleNavigation() {

    navigationVisible.value = !navigationVisible.value;

}

</script>
<template>
    <section ref="element" class="top-navigation" :class="{isOverflowing}">
        <button-partial ref="appOverlayButton" button-content-type="icon" color-scheme="icon-color1" icon="logo"  @click="toggleAppOverlay" />

        <overlay-module :visible="overlayVisible" :attachTo="appOverlayButton" @hide="toggleAppOverlay">
            <overlay-appstore-ext />
            <actions-overlay-partial />
            <me-overlay-partial />
        </overlay-module>

        <section ref="overflowContainer" class="overflow-container">

            <router-link :to="{ name: 'me-home' }">{{ $store.state.user.prename }}</router-link>
            <icons-partial v-if="company" type="arrow-right" color-scheme="colorScheme1" size="smaller" />
    
            <router-link v-if="company" :to="{ name: 'company-home' }">{{ $store.state.Company.settings.name }}</router-link>
            <icons-partial v-if="project" type="arrow-right" color-scheme="colorScheme1" size="smaller" />
    
            <router-link v-if="project" :to="{ name: 'project-home' }">{{ $store.state.Project.settings.name }}</router-link>
            <icons-partial v-if="app" type="arrow-right" color-scheme="colorScheme1" size="smaller" />
    
            <router-link v-if="app" :to="{ name: appHome }">{{$translate(appTranslateName)}}</router-link>
            <icons-partial v-if="routeSub" type="arrow-right" color-scheme="colorScheme1" size="smaller" />
    
            <div v-if="routeSub" class="subRoute">{{$translate(routeSub)}}</div>
            
        </section>


        <template v-if="isOverflowing">

            <button-partial class="navigationButton" ref="navigationButton" colorScheme="inline" @click="toggleNavigation">
                {{ $translate(lastEntry) }}
                <icons-partial type="arrow-down" color-scheme="colorScheme1" size="smaller" />
            </button-partial>

            <overlay-module :visible="navigationVisible" :attachTo="navigationButton" @hide="toggleNavigation">

                <section class="top-navigation-elements-container">

                    <router-link :to="{ name: 'me-home' }">{{ $store.state.user.prename }}</router-link>
                    <icons-partial v-if="company" type="arrow-right" color-scheme="colorScheme1" size="smaller" />
            
                    <router-link v-if="$store.state.Company.company" :to="{ name: 'company-home' }">{{ $store.state.Company.settings.name }}</router-link>
                    <icons-partial v-if="project" type="arrow-right" color-scheme="colorScheme1" size="smaller" />
            
                    <router-link v-if="$store.state.Project.project" :to="{ name: 'project-home' }">{{ $store.state.Project.settings.name }}</router-link>
                    <icons-partial v-if="app" type="arrow-right" color-scheme="colorScheme1" size="smaller" />
            
                    <router-link v-if="app" :to="{ name: appHome }">{{$translate(appTranslateName)}}</router-link>
                    <icons-partial v-if="routeSub" type="arrow-right" color-scheme="colorScheme1" size="smaller" />

                </section>

        
            </overlay-module>
        

        </template>

    </section>
</template>
<style lang="less" scoped>

.top-navigation-elements-container {
  .multi(padding, 4, 4);
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  overflow: hidden;

  a {
    display: inline-flex;
    .font-head;
    font-size: @fontTextSmaller;
    white-space: nowrap;
  }

}

.top-navigation {
  position: relative;
  display: grid;
  grid-template-columns: 35px 1fr;
  overflow: hidden;

  line-height: 35px;

  .overflow-container {

    display: grid;
    grid-auto-columns: max-content;
    grid-auto-flow: column;

  }

  &.isOverflowing {

    .overflow-container {
      position: absolute;
      visibility: hidden;
      pointer-events: none;
    }
  }

  a, .subRoute {

    .multi(padding, 0, 1);
    .font-head;
    position: relative;

    display: block;
    height: 35px;

    color: @color1;
    font-size: 1em;

    line-height: 35px;

  }

  :deep(.navigationButton) {

    &.base.inline {
      position: relative;
      justify-items: left;
      height: 35px;

      color: @color1;
      font-size: 1em;

      line-height: 35px;

      .text {
        display: grid;
        grid-template-columns: max-content max-content;
        gap: 5px;

      }
      .multi(padding, 0, 1);
      .font-head;
    }
  }

  a, .icon-partial, .subRoute {
    transition: color @aTime @aType,
      background @aTime @aType,
      transform @aTime @aType,
      opacity @aTime @aType;

    &.v-leave-active {
      opacity: 0;
      transform: translateY(10%);
    }

    &.v-enter-active {
      opacity: 0;
      transform: translateY(-10%);
    }

  }

  a {

    &:hover {
      background-color: @grey2;
    }

  }

  .subRoute {

    &:hover {
      background-color: transparent;
    }
  }

  .font-head;

}

</style>