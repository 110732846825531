<template>
  <section :class="{ error: error, options: open, search: search, colors: colors, disabled, inline, filterMode }" class="select-input label-replacement" tabindex="">
    <button v-if="!isMobile" ref="trigger" type="button" class="options-trigger" @click="toggleOptions">
      <span v-if="selected.length === 0 || selected === 0" class="selected-container">{{$translate(placeholder)}}</span>
      <span v-if="(selected.length > 0 || selected > 0) && !colors" class="selected-container">
        {{ selectedText }}
      </span>
      <template v-if="singleSelected && colors">
        <div class="selectedColor" :class="singleSelected.class">{{$translate(singleSelected.text)}}</div>
      </template>
      <icons-partial type="arrow-down" size="small" />
    </button>
    <button v-if="isMobile" ref="trigger" type="button" class="options-trigger mobile" @click="toggleOptions">
      <span class="selected-container">
        <span class="placeholder">{{$translate(placeholder).slice(0,1)}}</span>
        <span v-if="selectedLength" class="indicator">{{ selectedLength }}</span>
      </span>
    </button>

    <overlay-background-partial v-if="open" ref="options" :class="{colors: colors, inline}" @mounted="calculateOptionsStyle" @close="closeOptions">
      <section ref="options" class="options-container content-container" :class="{inline}" :style="optionsStyle">
        
        <search-partial-editor v-if="search" ref="search" :placeholder="searchPlaceholder" :add-data="searchAddData" callback inline no-render :active="active" :layout="layout" @searchInput="setSearchInput" @update="updateSearch" />
        
        <section class="select-options">
          <button v-if="notEmpty && search" class="single-option-button" type="button" @click="clear()">
            <span class="text">{{$translate('REMOVE_SELECTED')}}</span>
          </button>
          <h4 v-if="!filteredOptions.length && searchInput" class="no-entries">{{$translate('app-editor-no-children-found')}}</h4>
          <button v-for="(option, index) in filteredOptions" :key="`option-index-${index}`" class="single-option-button" type="button" :title="$translate(option.text)" :value="option.value" :class="[{ selected: isSelected(option.value), colorMode: option.color, hasDescription: option.description }, option.class]" @click="toggle(option)">
            <span v-if="!isSelected(option.value)" class="box"></span>
            <icons-partial v-if="isSelected(option.value)" type="checked" size="small" />
            <span v-if="option.text" class="text">{{$translate(option.text)}}</span>
            <p v-if="option.description" class="description">{{$translate(option.description)}}</p>
          </button>
        </section>

      </section>
    </overlay-background-partial>

    <div v-if="slotUsed || required || disabled" class="label-container">
      <span v-if="slotUsed" class="label">
        <slot></slot>
      </span>
      <span v-if="required && !disabled" class="required">{{$translate('required')}}</span>
      <span v-if="disabled" class="required">{{$translate('disabled')}}</span>
    </div>

    <notice-partial v-if="errorMessage" type="error">
      {{errorMessage}}
    </notice-partial>

    <description-partial v-if="description" class="input-description">{{$translate(description)}}</description-partial>

  </section>
</template>
<script lang="ts">
import { ExtComponent, Component, Prop, Watch } from '@/libs/lila-component';
import hardCopy from '@/mixins/hardCopy';
import SelectOption from '../interfaces/selectOption.interface';

@Component
export default class selectPartial extends ExtComponent {

  @Prop(String) type: string;

  @Prop(String) placeholder: string;

  @Prop(String) name: string;

  @Prop([Array, String, Number]) value: [] | string | number;

  @Prop(Object) error: InputError;

  @Prop(Boolean) required: boolean;

  @Prop(Boolean) disabled: boolean;

  @Prop(Boolean) search: boolean;

  @Prop(String) searchPlaceholder: string;

  @Prop(Object) searchAddData: Record<string, string>;

  @Prop(Boolean) allowEmpty: boolean;

  @Prop(Boolean) colors: boolean;

  @Prop(Boolean) inline: boolean;

  @Prop(Boolean) filterMode: boolean;

  /** when true the options trigger will be 35px on mobile */
  @Prop(Boolean) mobile: boolean;

  @Prop({type: Boolean, default: null}) layout: boolean;

  @Prop({type: Boolean, default: null}) active: boolean;

  @Prop(String) description: string;

  @Prop({type: String, default: 'dropdown'}) mode: 'list' | 'dropdown';

  @Prop({ default: true }) multiple: boolean;

  @Prop(Array) options: SelectOption[];

  selected: string[] | string | number[] | number = [];

  selectedText: string = null;

  searchInput: string = '';

  searchOptions: SelectOption[] = [];

  calculatedOptions = {};

  open = false;

  @Watch('value')
  watchValue(post: [] | string | number, pre: [] | string | number) {

    if(JSON.stringify(post) !== JSON.stringify(pre)) {

      this.setSelected();
      this.updateSelectedText();
    
    }
    
  }

  @Watch('options')
  watchOptions(post: [] | string | number, pre: [] | string | number) {

    if(JSON.stringify(post) !== JSON.stringify(pre)) {

      this.setSelected();
      this.updateSelectedText();
    
    }
    
  }

  get errorMessage() {

    return this.error?.keyword !== 'required' ? this.error?.error : null;

  }

  get filteredOptions() {

    if (!this.search) return this.options;
    return this.searchOptions;

  }

  get slotUsed() {

    return !!this.$slots.default?.length;

  }

  get notEmpty() {

    if(Array.isArray(this.selected)) return this.selected.length > 0;
    if(typeof this.selected === 'string') return this.selected.length > 0;
    if(typeof this.selected === 'number') return this.selected > 0;

    return false;

  }

  get singleSelected() {

    return this.options?.find(single => single.value === this.selected);
  
  }


  get isMobile() {

    return this.$store.state.media === 'mobile' && this.mobile;

  }

  get selectedLength() {

    if(Array.isArray(this.selected)) {

      return this.selected.length;

    }

    if(this.selected.toString().length) return 1;

  }


  calculateOptionsStyle() {

    // eslint-disable-next-line no-unused-expressions
    this.$store.state.height;
    // eslint-disable-next-line no-unused-expressions
    this.$store.state.width;

    const element = this.$refs.trigger as HTMLElement;
    const optionsContainer = document.querySelector('.overlay-background .options-container') as HTMLElement;
    const bounds = element.getBoundingClientRect();
    let top = bounds.top + element.offsetHeight;
    const body = document.querySelector('body');
    const positionTop = bounds.bottom + optionsContainer.offsetHeight + 50 > body.offsetHeight;

    if(positionTop) {

      top = bounds.top - 5 - optionsContainer.offsetHeight;
    
}

    this.calculatedOptions = {
      top: `${top}px`,
      left: `${bounds.left}px`,
      'min-width': '200px',
      'max-width': `${element.offsetWidth}px`,
    };

    console.log('calc style',this.calculatedOptions);

    if(this.inline) {

      this.calculatedOptions = {
        top: `${top}px`,
        left: `${bounds.left}px`,
      };
    
}
  
}

  get optionsStyle() {

    if (this.$store.state.media === 'mobile') return {};

    return this.calculatedOptions;

  }

  mounted() {

    this.setSelected();
    this.updateSelectedText();

    window.addEventListener('scrolled', () => {

      if(this.open) this.closeOptions();
    
    });

    window.addEventListener('resized', () => {

      if(this.open) this.calculateOptionsStyle();
    
    });

  }

  setSelected() {

    if (this.value === 0) {

      this.selected = 0;

    } else if(!this.value && this.colors) {

      this.selected = 0;

    } else if(!this.value) {

      this.selected = [];

    } else {

        this.selected = JSON.parse(JSON.stringify(this.value)) || [];

    }

  }

  updateSelectedText() {

    const textArray = [];

    if (this.multiple) {

      this.options?.forEach((value: SelectOption) => {

        if  (Array.isArray(this.selected)) {

          if (this.selected?.includes(value.value)) {

            textArray.push(this.$translate(value.text));
          
}

        } else if (this.selected === value.value) textArray.push(this.$translate(value.text));

      });

      this.selectedText = textArray.join(', ');

    } else if (this.search) {

      this.selectedText = this.selected?.toString();

    } else {

      this.selectedText = this.$translate(this.filteredOptions?.find(single => single.value === this.selected)?.text as string);
    
    }
  
}

  updateSearch(search: any) {

    this.searchOptions = [];

    search?.data.forEach(single => {

      this.searchOptions.push({
        value: single.id,
        text: single.id
      });

    });

    this.open = true;
  
}

  setSearchInput(searchString: string) {

    this.searchInput = searchString;

  }

  isSelected(option: string | number) {

    if (Array.isArray(this.selected)) return this.selected.includes(option);
    if (typeof this.selected === 'string' || typeof this.selected === 'number') return this.selected === option;

  }

  clear() {

    this.selected = '';
    this.emitChanges();
  
}

  toggle(option: { value: string; text: string; selected: boolean }) {

    if(this.disabled) {

      this.open = false;
      return;
    
}

    if (!this.multiple) {

      if (this.selected === option.value && this.allowEmpty) {

        this.selected = '';
      
} else {

        this.selected = option.value;
      
}

      this.updateSelectedText();
      this.emitChanges();
      this.open = false;

    } else if (this.selected.includes(option.value)) {

      option.selected = false;
      this.remove(option.value);

    } else {

      option.selected = true;
      this.add(option.value);

    }

  }

  add(value: string) {

    if (!this.selected.includes(value)) {

      (<string[]>this.selected).push(value);
      this.emitChanges();

    }

    this.updateSelectedText();
  
}

  remove(value: string) {

    const index = this.selected.indexOf(value);

    if (index > -1) {

      (<string[]>this.selected).splice(index, 1);
      this.emitChanges();

    }

    this.updateSelectedText();
  
}

  emitChanges() {

    this.$emit('input', hardCopy(this.selected));
    
  }

  closeOptions() {

    this.open = false;

  }

  toggleOptions() {

    if(this.disabled) {

      this.open = false;
      return;
    
}

    if (this.open) {

      this.open = false;

    } else {

      this.open = true;

    }
  
}

}
</script>

<style lang="less" scoped>

.select-input {
  position: relative;
  display: grid;
  gap: 5px;

  min-width: 35px;
  @media @desktop {
    min-width: 100px;
  }

  &.inline {
    max-width: 100px;

    @media @tablet, @desktop {
      max-width: 200px;
    }

    .options-trigger {
      border-bottom: 0;
    }
  }

  &.disabled {
    pointer-events: none;

  }

  .label-container {
    display: grid;
    grid-template-columns: 1fr min-content;

    .required {

    }
  }

  &.error {

    .options-trigger {
      border-color: @error;
    }

    .required {
      color: @error;
    }

  }

  &.filterMode {

    .options-trigger {
      border: 0;
    }
  }

  .selectedColor {

    display: grid;
    align-content: center;
    height: @buttonHeight;
    font-size: @fontSmall;
    text-align: center;
  }

  .options-trigger {
    .trans(border);
    position: relative;

    display: grid;
    width: 100%;
    height: @buttonHeight;

    border-bottom: solid 1px @color1;

    font-size: @fontText;
    line-height: @buttonHeight;

    text-align: left;

    &.mobile {
      width: @buttonHeight;

      .selected-container {
        position: relative;
        display: grid;
        justify-items: center;
        width: @buttonHeight;
        padding: 0;
        font-size: @headline_XS;
        color: @color1;

        .font-head;

        .indicator {
          position: absolute;
          display: grid;
          align-content: center;
          justify-content: center;
          width: 15px;
          height: 15px;
          padding: 0;
          background-color: @grey;
          color: @color1;
          font-size: @fontSmall;
          border-radius: 50%;

          align-self: center;
          justify-self: center;
          margin-top: 10px;
          margin-left: 10px;
        }
      }
    }

    .selected-container {
      position: relative;
      overflow: hidden;
      width: calc(100% - 15px);

      font-size: @fontTextSmaller;
      line-height: @buttonHeight;
      text-indent: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .icon-partial {
      position: absolute;
      display: grid;
      justify-self: end;
    }

    span {
      padding-right: 2px;
    }
  }

  &:hover {

    .options-trigger {
      border-color: @grey;
    }
  }

  &.options {

    .options-trigger {
      border-color: @grey;
    }
  }

  &.colors {

    .options-trigger {
      height: 100%;
      min-height: 30px;
      padding: 0;

      border: none;

      .icon-partial {
        margin-right: 5px;
      }

      .text {
        width: 100%;
      }
    }

  }

  .search-partial-editor {

    .multi(padding, 0);

    position: absolute;
    top: 29px;
    left: 0;
    width: 100%;

    margin-bottom: 10px;
    background-color: @white;
    opacity: 0;
    pointer-events: none;
    user-select: none;

    transition: opacity .3s ease, transform .3s ease;

    @media @tablet, @desktop {
      box-shadow: 0 5px 5px @grey;
    }

    .searchInput {
      .multi(padding, 0);

      input {
        .multi(padding, 2, 3);
      }
    }

    button {
      display: none;
    }
  }

  &.select-options {

    .select-options, .search-partial-editor {
      opacity: 1;
      pointer-events: all;
    }
  }

  &.search {

    .select-options {
      top: 78px;
    }
  }
}

.overlay-background {

  .options-container {
    display: grid;
    align-self: center;
    justify-self: center;
    width: 100%;
    .basePadding;

    @media @tablet, @desktop {
      max-height: 30vh;
      padding: 0;
    }

    .select-options, .search-partial-editor {
      opacity: 1;
      pointer-events: all;
    }

    .no-entries {
      .multi(padding, 2);
      font-size: @fontTextSmaller;
    }

    &.inline {

      .select-options {

        @media @tablet, @desktop {
          width: 200px;
        }
      }
    }

  }

  .select-options {
    display: grid;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    max-width: 100%;

    background-color: @white;

    transition: opacity .3s ease, transform .3s ease;

    @media @tablet, @desktop {
      max-height: 30vh;
    }

    @media @tablet, @desktop {
      box-shadow: 0 0 5px -3px @textColor;
    }

    .single-option-button {
      position: relative;
      display: grid;

      grid-template-columns: 45px 1fr 20px;

      gap: 5px 0;
      width: 100%;
      border-bottom: solid 1px @grey1;

      font-size: @fontTextSmaller;
      text-align: left;
      text-overflow: ellipsis;

      white-space: normal;

      .multi(padding, 3, 0);

      &.colorMode {
        height: @buttonHeight;
        padding: 0;
        font-size: @fontSmall;
        text-align: center;

        .box,
        .icon-partial {
          display: none;
        }

        .text {
          display: grid;
          grid-column-start: 1;
          grid-column-end: 4;
          align-content: center;
          width: 100%;
          margin: 0;
        }

        .basicHover;
      }

      &:last-child {
        border: 0;
      }

      &:hover {
        color: @color1;
      }

      .box {
        width: 15px;
        height: 15px;
        border: solid 1px @grey;
        background-color: @white;
      }

      .box,
      .icon-partial {
        display: grid;
        grid-column-start: 1;
        align-self: center;
        justify-self: center;
      }

      .text, .description {
        display: grid;
        grid-column-start: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .description {
        font-size: @fontTextSmaller;
      }

      &.selected {
        color: @color1;
      }
    }
  }
}
</style>
