<template>
  <ul>
    <li><router-link :to="{ name: 'company-home' }">Home</router-link></li>
    <li><router-link :to="{ name: 'team-home-company' }">team</router-link></li>
    <li>
      <router-link :to="{ name: 'company-settings' }">settings</router-link>
    </li>
  </ul>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class CompanyQuickmenuPartial extends Vue {}
</script>
