<template>
  <article :class="navigationAnimation" class="app-payment-endpoints-screen">
    <div class="pagenator full">
      <header class="main">

        <section class="stats">
          <h1>{{ $translate('app-payment-providers-overview') }}</h1>
          <h3>
            <template v-if="!content.sites.last">{{ content.count }}</template>
            <template v-if="content.sites.last">{{ content.sites.range[0] }} - {{ content.sites.range[1] }} ({{
              content.count }})</template>
            / {{ content.all }} {{ $translate('total') }}
          </h3>
        </section>

        <quickmenu-partial v-if="$scope(['paymentProviders:create'])">
          <router-link 
            :title="$translate('app-payment-providers-add')"
            :aria-label="$translate('app-payment-providers-add')" class="main icon"
            :to="{ name: 'app-payment-providers-add' }"><icons-partial type="add" size="medium" /></router-link>
        </quickmenu-partial>
      </header>

      <section class="sites-filter-container">
        <section class="select" />
        <sites-partial param-key="site" :data="content" />
      </section>

      <section class="scroll-container">
        <table class="isSticky">
          <thead-partial 
            :site-current="currentSite" 
            :search="$route.query.search" 
            :sort="sort" :order="order"
            :content="headContent" />
        </table>

        <table class="data-switch" :class="contentAnimation">
          <tbody>
            <tr v-for="(single, index) in content.data" :key="`data-overview-${index}`">
              <td class="id fullHeight action" :class="{ hasAttribute: single.app || single.model }">
                <router-link 
                :to="{
                  name: 'app-payment-providers-edit',
                  params: {
                    id: single._id.toString(),
                    company: $store.state.Company.company,
                    project: $store.state.Project.project,
                  },
                }">
                  <div>
                    <div><span v-if="single.app" class="attribute">{{ single.app }}</span> <span v-if="single.model" class="attribute">{{ single.model }}</span></div>
                    <span class="name">
                      <template v-if="!single.label">{{ $translate(`app-payment-providers-type-${single.type}`)
                        }}</template>
                      <template v-if="single.label">
                        {{ single.label }}
                      </template>
                    </span>
                  </div>
                </router-link>
              </td>

              <td class="status center">
                <span>
                  <icons-partial v-if='!single.active' :title="$translate('app-payment-providers-inactive')" color-scheme='grey' type='checked' size='small' />
                  <icons-partial v-if='single.active' :title="$translate('app-payment-providers-active')" type='checked' size='small' />
                </span>
              </td>

              <td class="status fullHeight">
                {{ $translate(`app-payment-providers-type-${single.type}`) }}
              </td>

            </tr>
          </tbody>
        </table>
      </section>

    </div>

  </article>
</template>
<script lang="ts">
import {
  DataObject, ListOfModels, Publish, PublishMethod,
} from '@lilaquadrat/studio/lib/interfaces';
import { ExtComponent, Component } from '@/libs/lila-component';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';
import hardCopy from '@/mixins/hardCopy';
import cleanObject from '@/mixins/cleanObject';
import { SDKResponse } from '@/libs/StudioSDK';
import MainStoreState from '@/store/mainStoreState.interface';

@Component
export default class AppPaymentHomeScreen extends ExtComponent {

  componentName = 'app-payment-providers-home';

  order: string = '-1';

  sort: string = 'id';

  query: Record<string, string> = {};

  headContent = [
    {
      key: 'label',
      text: 'label',
      classes: 'id',
    },
    {
      classes: 'publish',
      text: 'app-payment-providers-active',
    },
    {
      key: 'created',
      text: 'app-payment-providers-type',
    }
  ];


  get content(): DataObject<PublishMethod[]> {

    return this.$store.state.AppPayment.providers;

  }

  get currentSite() {

    return this.$route.params.site ? +this.$route.params.site : null;

  }

  updateSearch(value: string, type: string) {

    const newQuery = this.query;

    newQuery[type] = value;
    cleanObject(newQuery);

    this.query = newQuery;

    this.$router.replace({ params: { site: '1' }, query: this.query });

  }

  mounted() {

    this.query = hardCopy(this.$route.query);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store
      .dispatch('AppPayment/getProviders', {
        settings: {
          project: params.project,
          company: params.company,
          site: params.site,
        },
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<ListOfModels<Publish>>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppPayment/providers', preloadedData.data);
    return Promise.resolve();

  }

}
</script>
<style lang="less">
.app-payment-endpoints-screen {

  table {

    tr {
      grid-template-columns: 1fr 100px 100px;
    }


  }

  .pagenator.full {

    header.main {

      grid-template-columns: max-content 1fr;

    }

    table {

      &.isSticky {
        top: 0;
      }
    }
  }

  .sites-filter-container {

    .select {
      display: grid;
      grid-template-columns: 150px 1fr;
    }
  }

}
</style>
