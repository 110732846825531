<template>
  <article :class="navigationAnimation" class="app-hostingadmin-domains-home-screen">
    <div v-if="content" class="pagenator full">
      <header class="main">

        <section class="stats">
          <h1>{{ $translate('app-hostingadmin-domains') }}</h1>
          <h3 v-if="content.sites">
            <template v-if="!content.sites.last">{{ content.count }}</template>
            <template v-if="content.sites.last">{{ content.sites.range[0] }} - {{ content.sites.range[1] }} ({{ content.count }})</template>
            / {{ content.all }} {{ $translate('total') }}
          </h3>
        </section>

        <app-hostingadmin-quickmenu-partial/>
      </header>

      <!-- <quickfilter-partial reload store="AppHostingAdmin" use-emit @update="updateSearch" @reload="reload" /> -->
      <!-- quickfilter with select static options and category selection -->
      <quickfilter-partial reload category-type="hostingadmin" store="AppHostingAdminDomains" use-emit @update="updateSearch" @reload="reload">
        <select-partial inline placeholder="app-hostingadmin-domains-filter" :options="stateOptions" allow-empty :value="filterState" @input="updateSearch({ data: $event, type: 'filterState' })" />
      </quickfilter-partial>

      <section class="scroll-container">
        <table class="isSticky contentHead">
          <thead-partial :site-current="currentSite" :search="$route.query.search" :sort="sort" :order="order" :content="headContent" />
        </table>

        <table class="data-switch contentTable" :class="contentAnimation">
          <tbody>
            <tr v-for="(single, index) in content.data" :key="`data-overview-${index}`">
              <td class="id fullHeight action" :class="{ hasAttribute: single.app || single.model }">
                <router-link
                  :to="{
                    name: 'app-hostingadmin-company-domains-single',
                    params: {
                      id: single._id.toString(),
                      company: $store.state.Company.company,
                      project: $store.state.Project.project,
                    },
                  }"
                >
                <div class="id">{{ single.domain }}</div>
                <div class="description">{{single.company}} - {{single.project}}</div>
                    
                </router-link>
              </td>

              <td>
                {{ single.certificateNeeded }}
              </td>
              
              <td class="center">
                <template v-if="!single.isDefault && single.certificate">
                  <icons-partial v-if="single.certificate.valid.isValid" type="checked" color-scheme="green" />
                  <icons-partial v-if="!single.certificate.valid.isValid" type="warning" color-scheme="red" />
                </template>
                <template v-if="single.isDefault">
                  <icons-partial type="checked" color-scheme="grey" />
                </template>
              </td>
              
              <td>
                <template v-if="!single.isDefault && single.certificate">
                  {{ $date(single.certificate.valid.to) }} 
                  <div>( {{ $date(single.certificate.valid.to, undefined, true) }} )</div>
                </template>
              </td>

            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <modal-module />

  </article>
</template>
<script lang="ts">
import { ExtComponent, Component, Watch } from '@/libs/lila-component';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import { DomainsList, Hosting } from '@lilaquadrat/studio/interfaces';
import { DataObject } from '@lilaquadrat/studio/lib/interfaces';
import cleanObject from '@/mixins/cleanObject';
import hardCopy from '@/mixins/hardCopy';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';
import MainStoreState from '@/store/mainStoreState.interface';

@Component
export default class AppHostingAdminHomeScreen extends ExtComponent {

  componentName = 'app-hostingadmin-company-domains-home';

  headContent = [
    {
      key: 'name',
      text: 'app-hostingadmin-domains-domain',
      classes: 'name',
    },
    {
      text: 'app-hostingadmin-domains-certificateNeeded',
    },
    {
      text: 'app-hostingadmin-domains-title-isValid',
    },
    {
      text: 'app-hostingadmin-domains-title-valid-date',
    },
  ];

  stateOptions = [
   {
     text: 'app-hostingadmin-domains-certificateNeeded',
     value: 'certificateNeeded',
   },
  ];

  @Watch('$route')
  watchRoute() {

    this.setSearch();

  }

  get currentSite() {

    return this.$route.params.site ? +this.$route.params.site : null;

  }

  get filterState() {

    return this.$store.state.AppHostingAdminDomains.search?.filterState;

  }

  get sort() {

    return this.$store.state.AppHostingAdminDomains.search?.sort;

  }

  get order() {

    return this.$store.state.AppHostingAdminDomains.search?.order;

  }

  get content(): DataObject<DomainsList[]> {

    return this.$store.state.AppHostingAdminDomains.data || undefined;

  }

  mounted() {

    this.setSearch();

  }


  getSearch() {

    const query: Record<string, string | string[]> = {};

    query.sort = this.$route.query.sort as string || 'name';
    query.order = this.$route.query.order as string || '1';

    query.tags = this.$route.query.tags || null;
    query.type = this.$route.query.type || null;
    query.filterState = this.$route.query.filterState || null;
    query.search = this.$route.query.search || null;

    return query;

  }

  setSearch() {

    // only update search if the current component is used and no child is active
    if (this.$route.name !== this.componentName) return;
    this.$store.commit('AppHostingAdminDomains/setSearch', this.getSearch());

  }


  async updateSearch(data: {data: string, type: string}) {

    this.$store.commit('AppHostingAdminDomains/updateSearch', { data: data.data, type: data.type });

    const query = hardCopy(this.$store.state.AppHostingAdminDomains.search);

    cleanObject(query);

    this.$router.push({ params: { site: '1' }, query });

  }

  async reload() {

    StudioSDK.flushCache('domains', 'list');

    const data = await this.$store
      .dispatch('AppHostingAdminDomains/get', {
        query: this.$route.query,
        settings: {
          project: this.$route.params.project,
          company: this.$route.params.company,
          site: this.$route.params.site,
        },
      });

    this.$store.commit('AppHostingAdminDomains/data', data.data);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    console.log(227, params, query, store.state.AppHostingAdminDomains.search);
    return store
      .dispatch('AppHostingAdminDomains/get', {
        site: params.site,
        query,
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<DataObject<Hosting>>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppHostingAdminDomains/data', preloadedData?.data);
    return Promise.resolve();

  }

}
</script>
<style lang="less">


.app-hostingadmin-domains-home-screen {

  table.contentTable, table.contentHead {

    tr {
      grid-template-columns: 1fr 100px 50px 120px;

      td {

        &.hasDescription {

          .id {
            display: grid;
            color: @textColor;
            text-transform: uppercase;
          }

        }

      }

    }

  }

  .sites-filter-container {

    display: grid;
    grid-template-columns: 1fr 1fr;
    .multi(padding-top, 2);
    .multi(padding-bottom, 4);
    .basePadding;

    .sites-container {
      justify-self: end;
    }

    .select {
      display: grid;
      grid-template-columns: 150px 1fr;
    }
  }
}
</style>


