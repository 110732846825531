<template>
  <ul class="module-crumbs" :class="{ inline }">
    <li>
      <button-partial v-if="editorCrumbs.length" :variant="['crumbs']" @confirmed="clearNavigation">
        {{$translate(type || 'overview')}}
      </button-partial>
      <span v-if="!editorCrumbs.length">{{$translate(type || 'overview')}}</span>
    </li>
    <li v-for="(single, index) in editorCrumbs" :key="`single-${index}`">
      <button-partial v-if="!isLast(index)" :variant="['crumbs']" @confirmed="navigate(index)">
        {{$translate(single.title || single.name)}}
      </button-partial>
      <span v-if="isLast(index)">{{$translate(single.title)}}</span>
    </li>
  </ul>
</template>

<script lang="ts">
import { Component, ExtPartial, Prop } from '@/libs/lila-partial';


@Component
// eslint-disable-next-line global-require
export default class CrumbsPartial extends ExtPartial {

  @Prop(String) value: string;

  @Prop(String) type: string;

  @Prop(Boolean) inline: boolean;

  navigate(index: number) {

    this.$store.commit('AppEditorData/remove', index);

  }

  clearNavigation() {

    this.$store.commit('AppEditorData/clearCrumbs');
    this.$emit('clearCrumbs');

  }

  get editorCrumbs() {

    return this.$store.state.AppEditorData.crumbs;

  }

  isLast(index: number) {

    return index + 1 === this.editorCrumbs.length;

  }

}
</script>
<style lang="less" scoped>

ul.module-crumbs {
  .multi(padding, 0, 4);

  &.inline {
    padding: 0;
  }

  li {
    display: inline;

    span {
      color: @color1;
    }

    button,
    span {
      height: 20px;
      padding: 0;
      font-size: @fontTextSmaller;
      line-height: 20px;
      text-transform: uppercase;
      .font-normal;
    }

    button {
      position: relative;
    }
  }
}

</style>

