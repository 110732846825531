export default [
    {
        text: 'app-lists-participant-state-enlisted',
        value: 'enlisted',
    },
    {
        text: 'app-lists-participant-state-confirmed',
        value: 'confirmed',
    },
    {
        text: 'app-lists-participant-state-reserved',
        value: 'reserved',
    },
    {
        text: 'app-lists-participant-state-waiting',
        value: 'waiting',
    },
    {
        text: 'app-lists-participant-state-finalized',
        value: 'finalized',
    },
    {
        text: 'app-lists-participant-state-paid',
        value: 'paid',
    },
    {
        text: 'app-lists-participant-state-blocked',
        value: 'blocked',
    },
    {
        text: 'app-lists-participant-state-notuseful',
        value: 'notuseful',
    },
];