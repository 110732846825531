import InstallableApp from '@/interfaces/InstallableApp.interface';
import routes from './routes';
import translations from './translations/translations';

import './models/app.model';

const app: InstallableApp = {
  name: 'appstore',
  publisher: 'lilaquadrat',
  translations,
  routes,
};

export default app;
