<template>
  <section class="edit-mode-controls" :class="{favoriteMode}">
    <h3 class="title">
      <span class="name"> {{ translatedName }}</span>
      <span v-if="favoriteMode" class="company">{{ company }}</span>
    </h3>
    <div class="buttons-container">
      <button-partial v-if="!favoriteMode" :variant="['iconColor']" button-content-type="icon" :color-scheme="isFavorite ? 'icon-green' : 'icon-color1'" icon="star" @click="update('favorites')" />
      <button-partial v-if="!favoriteMode" :variant="['iconColor']" button-content-type="icon" :color-scheme="isHidden ? 'icon-red' : 'icon-color1'" icon="hide" @click="update('hidden')" />
      <button-partial v-if="favoriteMode" :variant="['iconColor']" button-content-type="icon" color-scheme="icon-color1" icon="close" @click="update('favorites')" />
    </div>
  </section>
</template>
<script lang="ts">
import { Prop } from '@/libs/lila-component';
import { ExtPartial } from '@/libs/lila-partial';
import Component from 'vue-class-component';

@Component
export default class appLinkPartial extends ExtPartial {

  @Prop(String) id: string;

  @Prop(String) company: string;

  @Prop(String) mode: 'project' | 'app';

  @Prop(Boolean) isFavorite: boolean;

  @Prop(Boolean) isHidden: boolean;

  @Prop(String) name: string;

  @Prop(Boolean) favoriteMode: boolean;

  update(type: 'favorites' | 'hidden') {

    this.$emit('toggle', {id: this.id, name: this.name, company: this.company, mode: this.mode, type});

  }

  get translatedName() {

    if(this.mode === 'app') {

      return this.$translate(`app-${this.name}`);

    }

    return this.name;

  }

}
</script>
<style lang="less" scoped>

.edit-mode-controls {
  display: grid;
  grid-template-rows: 1fr max-content;

  align-items: center;
  border: dashed 2px @textColor;
  text-align: center;
  text-transform: uppercase;
  aspect-ratio: 1;
  .multi(padding, 4);

  h3 {
    display: grid;
    gap: 5px;
    color: @textColor;
  }

  &:hover {
    border-color: @color1;

    h3 {
      color: @color1;
    }
  }

  .buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &.favoriteMode {

    .buttons-container {
      display: grid;
      grid-template-columns: 1fr;
    }

    .company {
      font-size: @fontSmall;
    }
  }
}
</style>
