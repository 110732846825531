import Models, { ModelDeclaration } from '@/libs/Models.class';

export default interface CompareStructureElement {
  headline: string
  description: string
  list: string[]
}

const declaration: ModelDeclaration<CompareStructureElement> = {
  headline: { type: 'string' },
  description: { type: 'string' },
  list: { type: 'array', contains: { type: 'string' } },
};


Models.register('compare-structure-element', declaration);