<template>
  <section class="picturegroup-module-editor open editor" :class="{ open: type !== 'overview' }">
    <module-editor-header-partial v-model="content" :position="position" :index="index" :type="type" @move="move($event)" />
    <crumbs-partial :type="safeContent.type" @clearCrumbs="unsetCurrentChild" />

    <section :class="{ optionsVisible: !child }" class="editor-module-container">
      <moduleList-partial v-if="!child" class="module-editor-navigation">
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('title')">{{$translate('title')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('elements')">{{$translate('elements')}}</button-partial>
        </li>
      </moduleList-partial>

      <textblock-partial-editor v-if="child === 'title'" v-model="safeContent.textblock" />
      <list-elements-partial-editor v-if="child === 'elements'" v-model="elements" type="picturegroupElement-partial" :model="listModel" partial @input="update($event)" />

      <module-options-partial
        v-if="child === 'options' || !child"
        v-model="safeContent"
        :available-variants="availableVariants"
        :selected-variants="safeContent.variant"
        @setChild="setChild('options', true)"
        @unsetChild="setChild('options')"
        @remove="remove()"
        @clone="clone()"
      />

    </section>
  </section>
</template>

<script lang="ts">

import { Component, ModuleEditorComponent } from '@/libs/ModuleEditorComponent';
import PicturegroupElement from '@/models/PicturegroupElement.model';
import PicturegroupModule from '../models/PicturegroupModule.model';

@Component
// eslint-disable-next-line global-require
export default class PictureGroupModuleEditor extends ModuleEditorComponent<PicturegroupModule> {

  get elements() {

    return this.safeContent.elements;

  }

  set elements(elements: any) {

    this.safeContent.elements = elements;

  }

  update(event) {

    console.log('update', event, this.content);
    this.safeContent.elements = event;

  }

  model = 'picturegroup-module';

  listModel = 'picturegroup-element';

}
</script>
