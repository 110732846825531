<template>
  <section class="compareElement-editor">
    <section class="editor-content-container">

      <input-partial v-model="value.headline" placeholder="headline" @keyup="$emit('keyup', $event)">{{$translate('headline')}}</input-partial>

      <textarea-partial v-model="value.description" @keyup="$emit('keyup', $event)">{{$translate('description')}}</textarea-partial>
    </section>

    <list-partial-editor v-model="value.list" no-head />
  </section>
</template>

<script lang="ts">
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import { Component } from '@/libs/lila-component';
import CompareStructureElement from '@/models/CompareStructureElement.model';

@Component
export default class CompareStructureElementEditor extends PartialEditorComponent<CompareStructureElement> {

  model = 'compare-structure-element';

}

</script>
