<template>
  <article class="app-publish-methods-single-screen" :class="navigationAnimation">
    <form class="content" @submit="save">
      <fieldset>
        <legend>
          <span class="text">{{$translate('app-publish-method-type')}}</span>
        </legend>
        <button-group-partial left>
          <label class="radio">
            <input v-model="type" :disabled="edit" value="ftp" name="type" type="radio" />
            {{$translate('app-publish-method-type-ftp')}}
          </label>
          <label class="radio">
            <input v-model="type" :disabled="edit" type="radio" value="webhook" name="type" />
            {{$translate('app-publish-method-type-webhook')}}
          </label>
          <label class="radio">
            <input v-model="type" :disabled="edit" type="radio" value="internal" name="type" />
            {{$translate('app-publish-method-type-internal')}}
          </label>
          <label class="radio">
            <input v-model="type" :disabled="edit" type="radio" value="pdf" name="type" />
            {{$translate('app-publish-method-type-pdf')}}
          </label>
          <label class="radio">
            <input v-model="type" :disabled="edit" type="radio" value="email" name="type" />
            {{$translate('app-publish-method-type-email')}}
          </label>
        </button-group-partial>

        <input-partial v-model="label" required placeholder="label">{{$translate('app-publish-method-label')}}</input-partial>

        <select-partial
          v-model="affectedStates"
          :options="affectedStatesOptions"
          :error="errorsObject.affectedStates"
          description="app-publish-affectedStates-description"
          placeholder="app-publish-affectedStates-all">{{$translate('app-publish-affectedStates')}}</select-partial>

        <search-partial
          v-model="availableForApps"
          required
          description="app-publish-method-availableForApps-description"
          :loading="loading"
          :callback="true"
          :possible-entries="possibleEntries"
          :error="errorsObject.availableForApps"
          @callback="search">{{$translate('app-publish-method-availableForApps')}}</search-partial>

          <template v-if="model">
            <select-editor-ext v-model="model.contextData" multiple>{{$translate('context')}}</select-editor-ext>
            <description-partial>{{$translate('app-publish-method-contextData')}}</description-partial>
          </template>

      </fieldset>

      <fieldset v-if="type">
        <legend>
          <span class="text">{{$translate(`app-publish-method-type-${type}`)}}</span>
        </legend>

        <template v-if="type === 'ftp'">
          <input-partial v-model="model.host" placeholder="app-publish-method-ftp-host" :error="errorsObject.host" required>{{$translate('app-publish-method-ftp-host')}}</input-partial>
          <input-partial v-model="model.port" placeholder="app-publish-method-ftp-port" :error="errorsObject.port">{{$translate('app-publish-method-ftp-port')}}</input-partial>

          <input-partial v-model="model.username" placeholder="app-publish-method-ftp-username" :error="errorsObject.username">{{$translate('app-publish-method-ftp-username')}}</input-partial>
          <input-partial v-model="model.password" type="password" placeholder="app-publish-method-ftp-password" :error="errorsObject.password">{{$translate('app-publish-method-ftp-password')}}</input-partial>

          <select-partial v-model="model.mode" required :multiple="false" :options="[{ value: 'passive', text: 'passive' }, { value: 'active', text: 'active' }]">
            {{$translate('app-publish-method-ftp-mode')}}
          </select-partial>

          <select-partial
            v-model="model.availableForContentGroups"
            :options="availableForContentGroups.ftp"
            :error="errorsObject.availableForContentGroups"
            description="app-publish-method-availableForContentGroups-description"
            placeholder="app-publish-method-availableForContentGroups-all">{{$translate('app-publish-method-availableForContentGroups')}}
          </select-partial>

          <checkbox-partial v-model="model.active">{{$translate('app-publish-method-active')}}</checkbox-partial>
        </template>

        <template v-if="type === 'webhook'">
          <input-partial v-model="model.url" :error="errorsObject.url" required placeholder="url">{{$translate('app-publish-method-webhook-url')}}</input-partial>
          <secret-partial v-if="scope(['publish:secret'])" />

          <select-partial
            v-model="model.availableForContentGroups"
            :options="availableForContentGroups.webhook"
            :error="errorsObject.availableForContentGroups"
            description="app-publish-method-availableForContentGroups-description"
            placeholder="app-publish-method-availableForContentGroups-all">{{$translate('app-publish-method-availableForContentGroups')}}
          </select-partial>

          <checkbox-partial v-model="model.active">{{$translate('app-publish-method-active')}}</checkbox-partial>
        </template>

        <template v-if="type === 'internal'">
          <select-partial
            v-model="model.availableForContentGroups"
            :options="availableForContentGroups.internal"
            :error="errorsObject.availableForContentGroups"
            description="app-publish-method-availableForContentGroups-description"
            placeholder="app-publish-method-availableForContentGroups-all">{{$translate('app-publish-method-availableForContentGroups')}}
          </select-partial>

          <checkbox-partial v-model="model.active">{{$translate('app-publish-method-active')}}</checkbox-partial>
        </template>

        <template v-if="type === 'pdf'">

          <select-partial
            v-model="model.availableForContentGroups"
            :options="availableForContentGroups.pdf"
            :error="errorsObject.availableForContentGroups"
            :allow-empty="false"
            disabled
            description="app-publish-method-availableForContentGroups-description"
            placeholder="app-publish-method-availableForContentGroups-all">{{$translate('app-publish-method-availableForContentGroups')}}
          </select-partial>

          <checkbox-partial v-model="model.active">{{$translate('app-publish-method-active')}}</checkbox-partial>
        </template>

        <template v-if="type === 'email'">

          <select-partial
            v-model="model.availableForContentGroups"
            :options="availableForContentGroups.email"
            :error="errorsObject.availableForContentGroups"
            :allow-empty="false"
            disabled
            description="app-publish-method-availableForContentGroups-description"
            placeholder="app-publish-method-availableForContentGroups-all">{{$translate('app-publish-method-availableForContentGroups')}}
          </select-partial>

          <select-partial
            v-model="model.mailFrom"
            :options="mailFromPossibleEntries"
            :error="errorsObject.availableForContentGroups"
            :allow-empty="false"
            :multiple="false"
            description="app-publish-method-mailFrom-description"
            placeholder="app-publish-method-mailFrom">{{$translate('app-publish-method-mailFrom')}}
          </select-partial>

          <checkbox-partial v-model="model.active">{{$translate('app-publish-method-active')}}</checkbox-partial>
        </template>

      </fieldset>

      <action-notice-partial :state="state" :translation-pre="translationPre" :errors="errors" @update="updateErrors">
        <button-partial v-if="edit" class="base colorScheme2" doublecheck @confirmed="remove">{{$translate('app-publish-method-remove-button')}}</button-partial>
        <button-partial v-model="state" type="submit" save :disabled="!type">
          <template v-if="!edit">{{$translate('app-publish-method-create-button')}}</template>
          <template v-if="edit">{{$translate('app-publish-method-edit-button')}}</template>
        </button-partial>
      </action-notice-partial>

    </form>

  </article>
</template>

<script lang="ts">
import { ExtComponent, Component, Watch } from '@/libs/lila-component';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import {
  DataObject,
  PublishMethod,
} from '@lilaquadrat/studio/lib/interfaces';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';
import { ErrorsObject } from '@/libs/ActionNotice';
import { ErrorObject } from 'ajv/dist/types';
import AppInterface from '@/interfaces/App.interface';
import hardCopy from '@/mixins/hardCopy';
import SelectOption from '@/interfaces/selectOption.interface';
import MainStoreState from '@/store/mainStoreState.interface';
import ModelsClass from '@/libs/Models.class';
import { BasicData, MailFrom } from '@lilaquadrat/interfaces';
import AppPublishSettingFtp from '../models/ftp.model';
import AppPublishSettingWebhook from '../models/webhook.model';
import AppPublishSettingInternal from '../models/internal.model';
import AppPublishSettingPdf from '../models/pdf.model';
import AppPublishSettingEmail from '../models/email.model';

@Component
export default class AppEditorSettingsScreen extends ExtComponent {

  componentName = ['app-publish-methods-add', 'app-publish-methods-edit'];

  realType = '';

  modelsList = {
    ftp: 'app-publish-settings-ftp',
    webhook: 'app-publish-settings-webhook',
    internal: 'app-publish-settings-internal',
    pdf: 'app-publish-settings-pdf',
    email: 'app-publish-settings-email',
  };

  models: {ftp: AppPublishSettingFtp, webhook: AppPublishSettingWebhook, internal: AppPublishSettingInternal, pdf: AppPublishSettingPdf, email: AppPublishSettingEmail} = {
    ftp: null,
    webhook: null,
    internal: null,
    pdf: null,
    email: null
  };

  affectedStatesOptions: SelectOption[] = [
    { value: 'draft', text: 'Draft' },
    { value: 'publish', text: 'Publish' },
  ];

  model = null;

  label = '';

  state: string = '';

  edit: boolean = false;

  errors: {message?: string, errors?: ErrorObject[]} = {};

  errorsObject: ErrorsObject = {};

  possibleEntries: {name: string, value: AppInterface['id']}[] = [];

  mailFromPossibleEntries: {text: string, description: string, value: string}[] = [];

  availableForApps: string[] = [];

  availableForContentGroupsArray: SelectOption[] = [
    { value: 'all', text: 'app-publish-method-availableForContentGroups-all' },
    { value: 'single', text: 'app-publish-method-availableForContentGroups-single' },
    { value: 'category', text: 'app-publish-method-availableForContentGroups-category' },
  ];

  availableForContentGroups = {
    ftp: this.availableForContentGroupsArray,
    webhook: this.availableForContentGroupsArray,
    internal: this.availableForContentGroupsArray,
    pdf: [this.availableForContentGroupsArray[1]],
    email: [this.availableForContentGroupsArray[1]],
  };

  affectedStates: string[] = [];

  loading: boolean = false;

  loadingIndicatorTimeout = null;

  @Watch('$route')
  watchRouteFunction() {

    this.setModel();

  }

  get content(): PublishMethod {

    return this.$store.state.AppPublish.singleMethod;

  }

  get translationPre() {

    return `app-publish-method-${this.realType}`;

  }

  get type() {

    return this.realType;

  }

  set type(type: string) {

    this.realType = type;
    this.model = this.models[type];

    if(type === 'email') {

      this.getMailFromOptions();

    }

  }

  updateErrors(errorsObject: ErrorsObject) {

    this.errorsObject = errorsObject;

  }

  mounted(): void {

    this.setModel();
      
  }

  setModel() {

    this.edit = this.$route.name === 'app-publish-methods-edit';

    if (this.edit) {

      const type = this.$store.state.AppPublish.singleMethod.type;

      // this.models[type] = new this.modelsList[type]().setData(this.$store.state.AppPublish.singleMethod, true, 'add');
      this.models[type] = ModelsClass.add(this.$store.state.AppPublish.singleMethod, `app-publish-settings-${type}`);
      this.type = type;
      this.label = this.$store.state.AppPublish.singleMethod.label;

      this.availableForApps = this.$store.state.AppPublish.singleMethod.availableForApps;
      this.affectedStates = this.$store.state.AppPublish.singleMethod.affectedStates;

    } else {

      this.models.ftp = ModelsClass.add<AppPublishSettingFtp>({ affectedStates: ['publish'], availableForContentGroups: ['all', 'single', 'category'] }, 'app-publish-settings-ftp');
      this.models.webhook = ModelsClass.add<AppPublishSettingWebhook>({ affectedStates: ['publish'], availableForContentGroups: ['all', 'single', 'category'] }, 'app-publish-settings-webhook');
      this.models.internal = ModelsClass.add<AppPublishSettingInternal>({ affectedStates: ['publish'], availableForContentGroups: ['all', 'single', 'category'] }, 'app-publish-settings-internal');
      this.models.pdf = ModelsClass.add<AppPublishSettingPdf>({ affectedStates: ['publish'], availableForContentGroups: ['single'] }, 'app-publish-settings-pdf');
      this.models.email = ModelsClass.add<AppPublishSettingEmail>({ affectedStates: ['publish'], availableForContentGroups: ['single'] }, 'app-publish-settings-email');

      this.affectedStates = ['publish'];

    }

  }

  search(search: string) {

    this.loading = true;
    clearTimeout(this.loadingIndicatorTimeout);

    this.$store.dispatch('Appstore/listActive', { search, company: this.$store.state.Company.company, project: this.$store.state.Project.project })
      .then((response: SDKResponse<DataObject<AppInterface[]>>) => {

        this.loadingIndicatorTimeout = setTimeout(() => {

          this.loading = false;

        }, 1000);

        this.possibleEntries = [];

        response.data.data.forEach((single) => {

          this.possibleEntries.push({ name: this.$translate(`app-${single.id}`), value: single.id });

        });

        this.availableForApps.forEach((single) => {

          this.possibleEntries.push({ name: this.$translate(`app-${single}`), value: single });

        });

      });

  }

  save($event: MouseEvent) {

    let action: string;
    let data: PublishMethod | {id: string, data: PublishMethod};

    $event.preventDefault();
    this.state = '';

    const model = hardCopy<PublishMethod>(this.model);

    model.label = this.label;
    model.availableForApps = this.availableForApps;
    model.affectedStates = this.affectedStates as ('draft' | 'publish')[];

    if(!model.contextData?.length) delete model.contextData;

    if (model.port?.length) {

      model.port = +model.port;

    } else {

      delete model.port;

    }

    this.errors = null;

    if (this.edit) {

      action = 'AppPublish/updateMethod';
      data = { id: this.$route.params.id, data: model };

    } else {

      action = 'AppPublish/addMethod';
      data = {
        ...model,
      };

    }


    this.$store
      .dispatch(
        action,
        data,
      )
      .then((response) => {

        this.state = 'success';

        console.log(response);

        if (!this.edit) {

          this.$router.push({ name: 'app-publish-methods-edit', params: { id: response.data._id } });
          StudioSDK.flushCache('methods', 'list');

        } else {

          StudioSDK.flushId(this.$route.params.id);

        }


        return true;

      })
      .catch((e) => {

        this.errors = e.response.data;
        this.state = 'error';

      });

  }

  remove() {

    this.$store
      .dispatch(
        'AppPublish/removeMethod',
        this.$route.params.id,
      )
      .then(() => {

        this.state = 'success';
        return true;

      })
      .catch((e) => {

        console.error(e);
        this.state = 'error';

      });

      StudioSDK.flushCache('methods', 'list');

  }

  async getMailFromOptions() {


    const newPossibleEntries = [];
    const mailFromOptionsBase: SDKResponse<BasicData<MailFrom>[]> = await this.$store.dispatch('AppPublish/getMailFrom');

    mailFromOptionsBase.data.forEach((single) => {

      newPossibleEntries.push({text: single.email, description: single.displayName, value: single._id});

    });

    this.mailFromPossibleEntries = newPossibleEntries;

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    if (!params.id) return Promise.resolve();
    
    return store
      .dispatch('AppPublish/singleMethod', {
        _id: params.id,
        settings: {
          project: params.project,
          company: params.company,
        },
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<PublishMethod>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    if (!params.id) return Promise.resolve();

    store.commit('AppPublish/singleMethod', preloadedData.data);
    return Promise.resolve();

  }


}
</script>
