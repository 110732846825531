import { UnifiedRouteGuard } from '@/libs/lila-auth';
import StructureHomeScreen from '@/screens/structure-home.screen.vue';
import StructureOverviewScreen from '@/screens/structure-overview.screen.vue';
import StructureAddScreen from '@/screens/structure-single.screen.vue';
import HomeScreen from './screens/home.screen.vue';
import SingleScreen from './screens/single.screen.vue';
import HomeSingleListScreen from './screens/home-single-list.screen.vue';
import ParticipantScreen from './screens/participant.screen.vue';

export default [
  {
    path: '/:company/:project/a/lists',
    name: 'app-lists-project-home',
    beforeEnter: UnifiedRouteGuard,
    component: HomeScreen,
    meta: {
      animationGroup: 'lists',
      app: 'lists',
      parent: true,
      rememberSettings: true,
    },
    children: [
         {
        path: 'structures',
        component: StructureOverviewScreen,
        meta: {
          sidescreen: true,
          defaultRoute: 'app-lists-project-structures-home',
          app: 'lists',
          model: 'listsParticipants',
          linkBase: 'app-lists-project'
        },
        children: [
          {
            path: 'add',
            component: StructureAddScreen,
            name: 'app-lists-project-structures-add',
            meta: {
              sidescreen: true,
              app: 'lists',
              model: 'listsParticipants',
              linkBase: 'app-lists-project'
            },
          },
          {
            path: 'edit/:id',
            component: StructureAddScreen,
            name: 'app-lists-project-structures-edit',
            meta: {
              sidescreen: true,
              app: 'lists',
              model: 'listsParticipants',
              linkBase: 'app-lists-project'
            },
          },
          {
            path: ':site?',
            component: StructureHomeScreen,
            name: 'app-lists-project-structures-home',
            meta: {
              sidescreen: true,
              app: 'lists',
              model: 'listsParticipants',
              linkBase: 'app-lists-project'
            },
          },
        ]
      },
      {
        path: 'add',
        component: SingleScreen,
        name: 'app-lists-project-add',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-lists-project-add',
          app: 'lists',
        },
      },
    ],
  },
  {
    path: '/:company/:project/a/lists/:id/:site?',
    name: 'app-lists-project-single-home',
    beforeEnter: UnifiedRouteGuard,
    component: HomeSingleListScreen,
    meta: {
      animationGroup: 'lists',
      app: 'list-participants',
      parent: true,
      rememberSettings: true,
      addParams: ['id']
    },
    children: [
      {
        path: 'edit',
        component: SingleScreen,
        name: 'app-lists-project-single-edit',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-lists-project-single-edit',
          app: 'list-participants',
        },
      },
      {
        path: 'participant/:participantId',
        component: ParticipantScreen,
        name: 'app-lists-project-participant',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-lists-project-participant',
          app: 'list-participants',
        },
      },
    ],
  },
];
