import Models, { ModelDeclaration } from '@/libs/Models.class';
import Link from './Link.model';

export default interface FooterSitemap {
  title: string
  elements: Link[]
}

const declaration: ModelDeclaration<FooterSitemap> = {
  title: { type: 'string' },
  elements: { type: 'array', contains: { model: 'link' } },
};

Models.register('footer-sitemap', declaration);