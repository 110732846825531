<template>
  <section class="apps-extension">
    <h3 v-if="!apps.length">{{$translate('NO_APPS_AVAILABLE')}}</h3>
    <slot />
    <checkbox-partial v-for="(app, index) in apps" :key="`apps-extension-${index}`" :description="app.description" @input="change(app.id)" :value="app.active">{{ app.name }}</checkbox-partial>
  </section>
</template>
<script lang="ts">
import AppInterface from '@/interfaces/App.interface';
import { ExtPartial, Prop } from '@/libs/lila-partial';
import Component from 'vue-class-component';

@Component
export default class AppsExtension extends ExtPartial {

  @Prop(Array) value: string[];

  @Prop(Boolean) active: boolean;

  @Prop(Boolean) available: boolean;

  @Prop(Boolean) admin: boolean;

  apps: AppInterface[] = [];

  selected: string[] = [];

  change(app: string) {

    const singleApp = this.apps.find((single) => single.id === app);

    singleApp.active = !singleApp.active;

    this.emit();

  }

  mounted() {

    const query: Record<string, string | string[]> = {};
    const settings = {
      ...this.$store.getters.companyProject,
      admin: this.admin,
    };

    if (this.active) {

      query.filter = ['active'];

    }

    if (this.available) {

      query.available = 'true';

    }

    this.$store
      .dispatch('Appstore/get', {
        settings,
        query,
      })
      .then((apps) => {

        this.apps = apps.data.data;
        this.emit();

      });

  }

  emit() {

    const apps = this.apps.map((single) => (single.active ? single.id : null)).filter((single) => single);

    this.$emit('input', apps);

  }

}
</script>
<style lang="less">

.apps-extension {
  display: grid;
  gap: 10px;
}
</style>
