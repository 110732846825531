import { UnifiedRouteGuard } from '@/libs/lila-auth';
import ExportsHomeScreen from './screens/exports-home.screen.vue';
import AddScreen from './screens/add.screen.vue';
import SingleScreen from './screens/single.screen.vue';
import JobsHomeScreen from './screens/jobs-home.screen.vue';
import SingleJobScreen from './screens/single-job.screen.vue';

export default [
  {
    path: '/:company/a/importadmin/exports',
    name: 'app-importadmin-company-exports',
    beforeEnter: UnifiedRouteGuard,
    component: ExportsHomeScreen,
    meta: {
      animationGroup: 'importadmin',
      app: 'importadmin',
      parent: true,
      rememberSettings: true,
    },
    children: [
      {
        path: 'add',
        component: AddScreen,
        name: 'app-importadmin-company-add',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-importadmin-company-add',
          app: 'importadmin',
        },
      },
      {
        path: 'single/:exportCompany/:exportProject/:timestamp',
        component: SingleScreen,
        name: 'app-importadmin-company-single',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-importadmin-company-single',
          app: 'importadmin',
        },
      },
    ],
  },
  {
    path: '/:company/a/importadmin/:site?',
    name: 'app-importadmin-company-home',
    beforeEnter: UnifiedRouteGuard,
    component: JobsHomeScreen,
    meta: {
      animationGroup: 'importadmin',
      app: 'importadmin',
      parent: true,
      rememberSettings: true,
    },
    children: [
      {
        path: 'single/:id',
        component: SingleJobScreen,
        name: 'app-importadmin-company-single-job',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-importadmin-company-single-job',
          app: 'importadmin',
        },
      },
    ],
  },
];
