<template>
  <section class="accordion-partial-editor">

    <list-elements-partial-editor v-if="!child" v-model="value.elements" type="accordionElement-partial" :model="accordionElementModel" partial />

    <editor-content-group-partial v-if="child === 'options'">

      <checkbox-partial v-model="value.multiOpen" description="app-editor-accordion-multiOpen-description" type="text">
        {{$translate('app-editor-accordion-multiOpen')}}
      </checkbox-partial>
      <checkbox-partial v-model="value.disableControls" description="app-editor-accordion-disableControls-description" type="text">
        {{$translate('app-editor-accordion-disableControls')}}
      </checkbox-partial>

      <select-partial v-model="value.openOnStart" :options="openOnStartOptions" :multiple="false" placeholder="app-editor-accordion-openOnStart-none">{{$translate('app-editor-accordion-openOnStart')}}</select-partial>

    </editor-content-group-partial>

    <moduleList-partial v-if="!child" class="module-editor-navigation">
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('options')">{{$translate('options')}}</button-partial>
      </li>
    </moduleList-partial>

  </section>
</template>

<script lang="ts">
import SelectOption from '@/interfaces/selectOption.interface';
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import { Component } from '@/libs/lila-component';
import Accordion from '@/models/Accordion.model';

@Component
// eslint-disable-next-line global-require
export default class AccordionPartialEditor extends PartialEditorComponent<Accordion> {

  openOnStartOptions: SelectOption[] = [
    { value: '', text: 'app-editor-accordion-openOnStart-none' },
    { value: 'first', text: 'app-editor-accordion-openOnStart-first' },
    { value: 'all', text: 'app-editor-accordion-openOnStart-all' },
  ];

  get elements() {

    return this.value.elements;

  }

  set elements(elements: any) {

    this.value.elements = elements;

  }

  update(event) {

    this.value.elements = event;

  }

  accordionElementModel = 'accordion-element';

}

</script>
