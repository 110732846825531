<template>
  <section class="upload-edit edit" :key="file._id">

    <div class="infos">
      <a
        v-if="$store.state.AppMedia.file.download"
        target="_blank" 
        rel="noopener noreferrer"
        :href="$store.state.AppMedia.file.download"
        class="button colorScheme2 base">
        {{$translate('DOWNLOAD')}}

      </a>
      <router-link class="icon base button transparent" :to="close">
        <icons-partial type="close" />
      </router-link>

    </div>

    <div class="picture-container" v-if="fileIndex">
      <div class="controls">

        <router-link class="icon base button colorScheme2 prev" :class="{ active: prev }" :to="{ name: $route.name, params: { filename: prev } }">
          <icons-partial type="arrow-left" />
        </router-link>

        <router-link class="icon base button colorScheme2 next" :class="{ active: next }" :to="{ name: $route.name, params: { filename: next } }">
          <icons-partial type="arrow-right" />
        </router-link>

      </div>

      <picture-internal-partial
        v-if="fileIndex.image"
        :class="contentAnimation"
        :key="fileIndex.view.src"
        :picture="{ src: fileIndex.view.src, mimetype: fileIndex.mimetype }" />

      <lila-video-partial
        preload="none"
        controlsList="nodownload nofullscreen"
        oncontextmenu="return false;"
        v-if="file.video"
        :class="contentAnimation"
        v-bind="{ src: file.view.src, attributes: ['controls'] }" />

      <div class="placeholder" v-if="!file.video && !file.image">
        <h3>{{file.id}}</h3>
        <div>{{file.mimetype}} - {{$formatBytes(file.size, 2)}}</div>
        <div>{{$translate('app-media-no-preview-filetype')}}</div>
      </div>

    </div>

    <media-editmode-module @deleted="deleted" />

  </section>
</template>
<script lang="ts">

import { Media } from '@lilaquadrat/studio/lib/interfaces';
import {
  ExtComponent, Component, Watch,
} from '@/libs/lila-component';
import { SDKResponse } from '@/libs/StudioSDK';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class MediaEditScreen extends ExtComponent {

  componentName = 'app-editor-media-edit';

  version: number = null;

  next = null;

  prev = null;

  index = null;

  /**
  watch file and content to update index and controls.
  on mount via deeplink, it is possible, that content finishes after file
  */
  @Watch('file')
  fileChange() {

    this.updateIndex();
    this.updateControls();

  }

  @Watch('content')
  contentChange() {

    this.updateIndex();
    this.updateControls();

  }

  @Watch('$route')
  async routeChange() {

    await this.getData();

  }

  mounted() {

    this.updateIndex();
    this.updateControls();

  }

  getData() {

    // return this.$store.dispatch('AppMedia/single', {
    //   company: this.$store.state.Company.company,
    //   project: this.$store.state.Project.project,
    //   filename: this.$route.params.filename,
    // });

  }

  get file(): Media {

    return this.$store.state.AppMedia.file;

  }

  get content() {

    return this.$store.state.AppMedia.data;

  }

  get fileIndex() {

    if (this.$store.state.AppMedia.version || this.$store.state.AppMedia.version === 0) {

      const version = this.$store.state.AppMedia.file?.versions[this.$store.state.AppMedia.version];

      return {
        view: {
          src: `${this.$store.state.AppMedia.file.basePath}${version.filename}`,
        },
        image: this.$store.state.AppMedia.file.image,
      };

    }

    return this.$store.state.AppMedia.file;

  }

  updateIndex() {

    let index: number = null;

    if (!this.$store.state.AppMedia.data.data) return;
    if (!this.$store.state.AppMedia.file) return;

    index = this.$store.state.AppMedia.data.data
      .findIndex((file: Media) => file.id === this.$store.state.AppMedia.file.id);

    this.index = index;

  }

  // eslint-disable-next-line class-methods-use-this
  get close() {

    return {name: 'app-media-project-home'};

  }

  get edit() {

    return this.$store.state.AppMedia.edit;

  }

  deleted() {

    this.$router.push(this.close);

  }

  updateControls() {

    const data: Media[] = this.content.data;
    const prevSearchArray = this.index ? data?.slice(0, this.index).reverse() : [];
    const nextSearchArray = data?.slice(this.index + 1);

    console.log(190, data, this.index);


    this.next = nextSearchArray.find((single) => !single.folder && single.id)?.id;
    this.prev = prevSearchArray.find((single) => !single.folder && single.id)?.id;

  }

  toggleEdit() {

    this.$store.commit('AppMedia/setEditMode', this.$store.state.AppMedia.edit === false);

  }

  // eslint-disable-next-line class-methods-use-this
  async preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store.dispatch('AppMedia/single', params.filename);


  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<Media>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    // store.commit('AppMedia/data', preloadedData.data);
    console.log(preloadedData);
    store.commit('AppMedia/version', null);
    store.commit('AppMedia/setFile', preloadedData.data);
    return Promise.resolve();

  }

}

</script>
<style lang="less" scoped>

.upload-edit{

  position: absolute;

  display: grid;

  grid-template-rows: min-content 30vh 1fr 1fr;
  width: 100%;
  height: 100%;

  background-color: @white;

  @media @desktop {
    grid-template-rows: 1fr;
  }

  .index(3);

  &.edit {

    grid-template-columns: none;
    overflow-y: scroll;

    @media @desktop {
      grid-template-columns: 1fr 360px;
    }
  }

  .picture-container {

    position: relative;
    display: grid;

    overflow: unset;

    @media @desktop {
      overflow: hidden;
    }

    .controls a {
      z-index: 1000;
    }

    .lila-video-partial {
      display: grid;
      align-self: center;
      overflow: hidden;
      width: 100%;
    }

    .placeholder {
      display: grid;
      align-self: center;
      justify-self: center;
      text-align: center;
    }

  }

  .infos {

    position: initial;

    top: 20px;
    right: 20px;

    width: 100%;
    height: 40px;
    margin-top: 5px;
    .index(5);

    @media @desktop {
      position: absolute;
      margin-top: 0;
    }

  }

  .controls {
    position: absolute;
    display: grid;

    grid-template-columns: 1fr 1fr;

    align-self: center;
    width: 100%;
    height: 40px;

    pointer-events: none;

    a {

      &.active {
        pointer-events: all;
      }

      &:last-child {
        justify-self: end;
      }
    }

  }

  .infos {
    display: grid;
    grid-template-columns: max-content max-content max-content;
    grid-gap: 10px;
    justify-content: end;

    a,
    button {

      &.close {
        width: 35px;
        height: 35px;
        background-color: rgba(255, 255, 255, .1);
        background-size: 15px;

        span {
          background-color: @textColor;
        }
      }

    }
  }
}

</style>
