import Models, { ModelDeclaration } from '@/libs/Models.class';


export default interface Textblock {
  headline: string
  subline: string
  intro: string
  text: string[]
}

const declaration: ModelDeclaration<Textblock> = {
  headline: { type: 'string' },
  subline: { type: 'string' },
  intro: { type: 'string' },
  text: { type: 'array', contains: { type: 'string' } },
};

Models.register('textblock', declaration);