

import Models, { ModelDeclaration } from '@/libs/Models.class';
import AppPublishSettingFtp from './ftp.model';
import AppPublishSettingWebhook from './webhook.model';
// import AppPublishSettingShopify from './shopify.model';
import AppPublishSettingInternal from './internal.model';

export default interface AppPublishProject {
  ftp: AppPublishSettingFtp
  webhook: AppPublishSettingWebhook
  // shopify: AppPublishSettingShopify
  internal: AppPublishSettingInternal
}

const declaration: ModelDeclaration<AppPublishProject> = {
  ftp: { type: 'array', contains: { model: 'app-publish-setting-ftp' } },
  webhook: { type: 'array', contains: { model: 'app-publish-setting-webhook' } },
  internal: { type: 'array', contains: { model: 'app-publish-setting-internal' } },
};

Models.register('app-publish-project', declaration);