<template>
  <router-link :to="to" class="project-link" :class="[available, { disabled, hasIcon: icon && useIcon }]">
    <picture-internal-partial v-if="icon" v-bind="{ picture: { src: icon } }" @error="toggleIcon('error')" @loaded="toggleIcon('loaded')" />
    <h3 v-if="!icon || !useIcon"><slot /></h3>
    <template v-if="status">
      <span v-if="active" class="status active"><icons-partial type="checked" :color-scheme="icon && useIcon ? 'colorScheme1' : 'white'" /></span>
      <span v-if="!active" class="status available"><icons-partial type="add" :color-scheme="icon && useIcon ? 'colorScheme1' : 'white'" /></span>
    </template>
    <span v-if="isPreviewPublic" class="ribbon public">{{$translate('app-internal')}}</span>
    <span v-if="isPreviewInternal" class="ribbon internal">{{$translate('app-preview')}}</span>
  </router-link>
</template>
<script lang="ts">
import { Prop } from '@/libs/lila-component';
import { ExtPartial } from '@/libs/lila-partial';
import Component from 'vue-class-component';

@Component
export default class appLinkPartial extends ExtPartial {

  @Prop(String) name: string;

  @Prop(Object) to: Record<string, string>;

  @Prop(String) icon: string;

  @Prop(Boolean) disabled: boolean;

  @Prop(Boolean) active: boolean;

  @Prop(String) available: 'unavailable' | 'internal' | 'preview' | 'public';

  @Prop(Boolean) status: boolean;

  @Prop(Array) attributes: string[];

  useIcon: boolean = false;


  get isPreviewPublic() {

    return this.available === 'preview';

  }

  get isPreviewInternal() {

    return this.available === 'internal';

  }

  get isUnavailable() {

    return this.available === 'unavailable';

  }

  toggleIcon(event: 'loaded' | 'error') {

    if (event === 'error') this.useIcon = false;
    if (event === 'loaded') this.useIcon = true;

  }

}
</script>
<style lang="less">

.project-link {
  position: relative;
  display: grid;
  align-content: center;
  justify-content: center;
  overflow: hidden;

  width: 100%;

  background-color: @grey2;

  text-align: center;
  text-transform: uppercase;

  transition: transform @aTimeFast @aType, background @aTimeFast @aType;
  aspect-ratio: 1;
  .multi(padding, 4);

  h3 {
    display: grid;
    gap: 5px;
    color: @color1;
    font-size: @fontText;

    .company {
      font-size: @fontSmall;
    }
  }

  &.hasIcon {
    background-color: transparent;

    text-indent: 0;

    &:hover {
      background-color: transparent;
    }

  }

  &:hover {
    background-color: @color2;
    transform: scale(1.05);
  }

}
</style>
