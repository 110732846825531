import InstallableApp from '../../interfaces/InstallableApp.interface';
import routes from './routes';
import translations from './translations/translations';

import './models/agreements.model';
import './models/categories.model';
import './models/list.model';
import './models/list-shopping.model';
import './models/participant-state.model';
import './models/participant-note.model';
import './models/participants.model';
import './models/price.model';
import './models/content.model';
import './models/content-shopping.model';
import './models/emails.model';

const app: InstallableApp = {
  name: 'lists',
  publisher: 'lilaquadrat',
  translations,
  routes,
  store: 'AppLists',
};

export default app;
