<template>
  <section
    class="input search"
    :class="{
      loading, right: selectedPosition === 'right', filterMode, showAlwaysText,
    }">
    <div class="values">

      <template v-if="!filterMode">
        <span v-if="!noSelected && !filterMode" class="values-container">
          <div v-for="(entry, i) in outputSelected" :key="`searchEntry-${i}`" class="single">
            <span class="text">{{ name(entry) }}</span>
            <button type="button" class="unset" @click="removeEntry($event, entry)"><icons-partial type='close' size='small' color-scheme='white' /></button>
          </div>
        </span>

        <label v-if="!complete" tabindex="">
          <div class="inputContainer">
            <div class="position-container">
              <indicator-partial v-show="loading" small />
              <span v-if="allowNew && input.length > 0" class="add-notice">{{$translate('ADD_WITH_ENTER')}}</span>
              <input-partial
                ref="input"
                v-model="input"
                type="text"
                :placeholder="placeholder"
                @keydown.esc="clear()"
                @enter="addEntry(null, { name: input, value: input }, true)"
                @keydown="validate($event)"
                @keyup="checkInput($event.target.value, $event)"
                @click="togglePossibleEntries()"
                @focus="togglePossibleEntries()"
              />
            </div>

            <div class="label-container">
              <span class="label">
                <slot />
              </span>
              <span v-if="required" class="required">{{$translate('required')}}</span>
            </div>

            <notice-partial v-if="errorMessage" type="error">
              {{errorMessage}}
            </notice-partial>

            <description-partial v-if="description" class="input-description">{{$translate(description)}}</description-partial>

            <overlay-background-partial v-if="open && !filterMode" ref="options" background="none" @mounted="calculateOptionsStyle" @close="closeOptions">
              <div class="search-content" :style="optionsStyle">
                <ul>
                  <li v-for="(possible, index) of entries" :key="`searchPossibleEntry-${index}`" :class="{ highlight: index === currentHighlight }">
                    <button type="button" class="element" @click="addEntry($event, possible)">
                      {{ cleanName(possible.name) }}
                    </button>
                  </li>
                  <li v-if="!entries.length" class="empty">
                    <div class="element">
                      {{$translate('NO_ENTRIES')}}
                    </div>
                  </li>
                </ul>
              </div>
            </overlay-background-partial>
          </div>
        </label>
      </template>

      <template v-if="filterMode">
        <span v-if="!noSelected" ref="filtermodeContainer" class="values-container">
          <button-partial v-if="!isMobile || showAlwaysText" color-scheme="filter" @click="toggleOpen">
            <template v-if="outputSelectedText">{{outputSelectedText}}</template>
            <template v-if="!outputSelectedText">{{$translate(placeholder)}}</template>
          </button-partial>
          <button-partial v-if="!isMobile || showAlwaysText" button-content-type="icon" size="small" :rotate="90" icon="arrow-right" color-scheme="transparent" @click="toggleOpen" />
          <button-partial v-if="isMobile && !showAlwaysText" :title="$translate(placeholder)" button-content-type="iconText" size="small" icon="tags" color-scheme="transparent" @click="toggleOpen">
            <template v-if="selected.length">{{selected.length}}</template>
          </button-partial>
        </span>

        <overlay-background-partial v-if="open" ref="options" @mounted="calculateOptionsFiltermodeStyle" @close="closeOptions">
          <section class="filterModeOverlay" :style="optionsStyle">
            <label tabindex="">
              <div class="inputContainer">
                <div class="position-container">
                  <indicator-partial v-show="loading" small />
                  <span v-if="allowNew && input.length > 0" class="add-notice">{{$translate('ADD_WITH_ENTER')}}</span>
                  <input-partial
                    ref="input"
                    v-model="input"
                    type="text"
                    :placeholder="placeholder"
                    autofocus
                    @keydown.esc="clear()"
                    @enter="addEntry(null, { name: input, value: input }, true)"
                    @keydown="validate($event)"
                    @keyup="checkInput($event.target.value, $event)"
                  />
                </div>

                <div class="label-container">
                  <span class="label">
                    <slot />
                  </span>
                  <span v-if="required" class="required">{{$translate('required')}}</span>
                </div>

                <notice-partial v-if="errorMessage" type="error">
                  {{errorMessage}}
                </notice-partial>

                <description-partial v-if="description" class="input-description">{{$translate('description ')}}</description-partial>
              </div>
            </label>
            <div v-if="input.length" class="search-content filterMode">
              <ul>
                <li v-for="(single, index) of renderList.search" :key="`searchPossibleEntry-${index}`" :class="{ highlight: index === currentHighlight }">
                  <button type="button" class="element" @click="toggleEntry($event, single, 'search')">
                    <span v-if="!single.selected" class="box" />
                    <icons-partial v-if="single.selected" type="checked" size="small" />
                    {{single.name}}
                  </button>
                </li>
                <li v-if="!entries.length && input.length && !loading" class="empty" />
              </ul>
            </div>
            <div v-if="!input.length && renderList.selected.length" class="selected">
              <ul>
                <li v-for="(single, index) of renderList.selected" :key="`selectedEntry-${index}`" :class="{ highlight: index === currentHighlight }">
                  <button type="button" class="element" @click="toggleEntry($event, single, 'selected')">
                    <span v-if="!single.selected" class="box" />
                    <icons-partial v-if="single.selected" type="checked" size="small" />
                    {{single.name}}
                  </button>
                </li>
              </ul>
            </div>
            <div v-if="!input.length && renderList.recent.length" class="selected recent">
              <h3>{{$translate('RECENT_SELECTED')}}</h3>
              <ul>
                <li v-for="(single, index) of renderList.recent" :key="`selectedEntry-${index}`" :class="{ highlight: index === currentHighlight }">
                  <button type="button" class="element" @click="toggleEntry($event, single, 'recent')">
                    <span v-if="!single.selected" class="box" />
                    <icons-partial v-if="single.selected" type="checked" size="small" />
                    {{single.name}}
                  </button>
                </li>
              </ul>
            </div>
          </section>
        </overlay-background-partial>

      </template>

    </div>
  </section>
</template>

<script lang="ts">
import SelectOption from '@/interfaces/selectOption.interface';
import Vue from 'vue';
import { ParsedError } from '@/libs/ActionNotice';
import {
  ExtPartial, Prop, Watch, Component,
} from '@/libs/lila-partial';

@Component
// eslint-disable-next-line global-require
export default class searchPartial extends ExtPartial {

  @Prop(Array) value: string[];

  @Prop(String) type: string;

  @Prop(String) placeholder: string;

  @Prop(String) baseInput: string;

  @Prop(Boolean) required: boolean;

  @Prop(Array) possibleEntries: { name: string; value: string }[];

  @Prop(Boolean) disabled: boolean;

  @Prop(Boolean) callback: boolean;

  @Prop(Boolean) allowNew: boolean;

  @Prop(RegExp) validation: RegExp;

  @Prop(Boolean) useValues: boolean;

  @Prop(Boolean) noSelected: boolean;

  @Prop(String) selectedPosition: 'bottom' | 'right';

  @Prop(Boolean) loading: boolean;

  @Prop(Boolean) callbackEmpty: boolean;

  @Prop(String) category: string;

  @Prop(String) description: string;

  @Prop(Object) error: ParsedError;

  @Prop(Boolean) showAlwaysText: boolean;

  @Prop({ default: true }) multiple: boolean;

  @Prop(Array) options: SelectOption[];

  @Prop(Boolean) filterMode: boolean;

  open: boolean = false;

  selected: string[] = [];

  outputSelected: string[] = [];

  entries: { name: string; value: string }[] = [];

  input: string = '';

  debounceTime: number = 500;

  timeout: any;

  cache: string;

  currentHighlight: number = null;

  complete: boolean = false;

  calculatedOptions = {};

  recent: string[] = [];

  renderList: Record<'selected'|'recent'|'search', { name: string; value: string, selected: boolean }[]> = { selected: [], recent: [], search: [] };

  @Watch('possibleEntries')
  entriesFunction(): void {

    this.updateEntries(this.possibleEntries);
    this.updateComplete();

    this.currentHighlight = null;

  }

  @Watch('value')
  valueFunction(): void {

    this.updateSelected(this.value, this.category, true);

    this.currentHighlight = null;

  }

  get errorMessage() {

    return this.error?.error;

  }

  get optionsStyle() {

    if (this.$store.state.media === 'mobile' && this.filterMode) return {};

    return this.calculatedOptions;

  }

  get isMobile() {

    return this.$store.state.media === 'mobile';

  }

  get outputSelectedText() {

    const useArray = this.outputSelected.map((single) => this.name(single))?.sort((a, b) => a.localeCompare(b));

    console.log(useArray, this.category);

    return useArray ? useArray?.join(', ') : false;

  }

  closeOptions() {

    this.open = false;

  }

  calculateOptionsStyle() {

    const vueElement = this.$refs.input as Vue;
    const element = vueElement.$el as HTMLElement;
    const optionsContainer = document.querySelector('.overlay-background .search-content') as HTMLElement;
    const bounds = element.getBoundingClientRect();
    let top = bounds.top + element.offsetHeight;
    const body = document.querySelector('body');
    const positionTop = bounds.bottom + optionsContainer.offsetHeight + 50 > body.offsetHeight;

    if (positionTop) {

      top = bounds.top - 5 - optionsContainer.offsetHeight;

    }

    this.calculatedOptions = {
      top: `${top}px`,
      left: `${bounds.left}px`,
      'min-width': `${element.offsetWidth}px`,
    };

  }

  calculateOptionsFiltermodeStyle() {

    const element = this.$refs.filtermodeContainer as HTMLElement;
    // const element = vueElement.$el as HTMLElement;
    const optionsContainer = document.querySelector('.overlay-background .filterModeOverlay') as HTMLElement;

    console.log(element, this.$refs);

    const bounds = element.getBoundingClientRect();
    let top = bounds.top + element.offsetHeight;
    const body = document.querySelector('body');
    const positionTop = bounds.bottom + optionsContainer.offsetHeight + 50 > body.offsetHeight;

    if (positionTop) {

      top = bounds.top - 5 - optionsContainer.offsetHeight;

    }

    this.calculatedOptions = {
      top: `${top}px`,
      left: `${bounds.left}px`,
    };

  }


  name(value: string): string {

    if (this.useValues) {

      return this.category ? value.split(`${this.category}:`)[1] : value;

    }

    if (!this.possibleEntries?.length) return value;

    const entry = this.possibleEntries?.find((single) => (single.value === value));

    return this.category ? entry?.name?.split(`${this.category}:`)[0] : entry?.name;

  }

  cleanName(name: string) {

    if (!name.includes(':')) return name;

    return this.category ? name.split(`${this.category}:`)[1] : name;

  }

  updateSelected(selected: string[], category: string | undefined, init: boolean = false): void {

    if (category) {

      this.outputSelected = selected?.filter((single) => single.match(new RegExp(`^${category}:`)));

    } else {

      this.outputSelected = selected?.filter((single) => !single.match(':'));

    }

    console.log(selected);
    this.selected = [...selected];

    if (!init) this.$emit('input', this.selected);

  }

  mounted(): void {

    if (this.baseInput) {

      this.input = this.baseInput;
      this.$emit('callback', this.baseInput, true);

    }

    this.updateEntries(this.possibleEntries);
    if (this.value) this.updateSelected(this.value, this.category, true);

  }

  updateEntries(entries: { name: string; value: string }[]): void {

    this.renderList.search = entries.map((single) => ({ value: single.value, name: this.cleanName(single.value), selected: this.selected.includes(single.value) }));
    this.entries = [...entries.filter((entry) => this.selected.indexOf(entry.value) === -1)];

    this.togglePossibleEntries();

  }

  clear(): void {

    this.input = '';
    if (!this.filterMode) this.open = false;

  }

  toggleEntry(e: KeyboardEvent, element: {value: string, name: string, selected: boolean}, source: 'recent' | 'search' | 'selected') {

    if (element.selected) {

      this.removeEntry(e, element.value);
      this.updateRenderSelected({ ...element, selected: false }, source);

      if (source === 'search') {

        this.updateRenderList('selected');

      }

      return;

    }

    if (!element.selected) {

      if (!this.multiple) {

        this.updateSelected([element.value], this.category);
        this.deselectAll();

      }

      this.addEntry(e, element);
      this.updateRenderSelected({ ...element, selected: true }, source);

      if (source === 'search') {

        this.updateRenderList('selected');
        this.updateRenderSelected({ ...element, selected: true }, 'search');
        this.updateRenderSelected({ ...element, selected: true }, 'selected');

      }

      if (source === 'recent') {

        this.updateRenderSelected({ ...element, selected: true }, 'recent');

      }

    }

  }

  removeEntry(e: KeyboardEvent, key: string): void {

    e.preventDefault();

    console.log('REMOVE', this.selected.indexOf(key), key, JSON.stringify(this.selected));
    console.trace();

    this.selected.splice(this.selected.indexOf(key), 1);
    if (!this.recent.includes(key)) this.recent.push(key);

    console.log('REMOVE1', this.selected);

    this.updateSelected(this.selected, this.category);
    if (!this.filterMode) this.updateEntries(this.entries);
    this.updateComplete();

  }

  addEntry(e: KeyboardEvent, key: { name: string; value: string }, isNew?: true): void {

    // e.preventDefault();

    console.log('ADD', key, JSON.stringify(this.selected));
    console.trace();

    let cleanValue: string;
    let realValue: string;

    if (this.currentHighlight || this.currentHighlight === 0) {

      if (!this.entries[this.currentHighlight]) return;

      this.selected.push(this.entries[this.currentHighlight].value);
      this.recent.splice(this.recent.indexOf(this.entries[this.currentHighlight].value), 1);

      this.updateSelected(this.selected, this.category);
      if (!this.filterMode) this.updateEntries(this.entries);

      if (!this.filterMode) this.input = '';

      this.currentHighlight = null;
      this.updateComplete();
      return;

    }

    if (!this.allowNew && isNew) return;
    if (!key) return;


    if (this.category) {

      // if not a new entry remove the category part for validation
      cleanValue = isNew ? key.value : key.value.split(':')[1];
      realValue = isNew ? `${this.category}:${cleanValue}` : key.value;

    } else {

      cleanValue = key.value;
      realValue = key.value;

    }

    // if key is set and not already selected
    if (!this.selected.includes(realValue)) {


      if (!cleanValue.match(new RegExp(this.validation))) return;

      this.selected.push(realValue);
      this.recent.splice(this.recent.indexOf(realValue), 1);
      this.updateSelected(this.selected, this.category);
      if (!this.filterMode) this.updateEntries(this.entries);

      if (!this.filterMode) this.input = '';
      this.updateComplete();

    }

  }

  /**
   * hides the the search content and the input if the max entries are reached
   */
  updateComplete(): void {

    if (!this.multiple && this.selected.length >= 1) {

      if (!this.filterMode) this.open = false;
      this.entries = [];
      this.complete = true;

      return;

    }

    this.complete = false;

  }

  togglePossibleEntries(): void {

    console.log(this.filterMode, this.entries);

    if (this.filterMode) return;

    if (this.entries.length) {

      this.open = true;
      return;

    }


    this.open = false;

  }

  toggleOpen() {

    this.updateRenderList();
    this.open = true;
    this.input = '';

  }

  updateRenderSelected(element: {value: string, name: string, selected: boolean}, target: 'search' | 'selected' | 'recent') {

    const useArray = this.renderList[target];
    const foundElement = useArray.find((single) => single.value === element.value);

    if (!foundElement) return;

    foundElement.selected = element.selected;

  }

  deselectAll() {

    Object.keys(this.renderList).forEach((key) => {

      const list = this.renderList[key];

      list.forEach((single) => {

        single.selected = false;

      });

    });

  }

  updateRenderList(target?: 'search' | 'selected' | 'recent') {

    if (!target || target === 'search') this.renderList.search = [];

    if (!target || target === 'selected') {

      this.renderList.selected = this.outputSelected.map((single) => ({ value: single, name: this.cleanName(single), selected: true })).sort((a, b) => a.name.localeCompare(b.name));

    }

    if (!target || target === 'recent') {

      this.renderList.recent = this.recent.map((single) => ({ value: single, name: this.cleanName(single), selected: false })).sort((a, b) => a.name.localeCompare(b.name));

    }


  }

  validate(event: KeyboardEvent): void {

    if (!this.validation) return;
    if (event.key === 'Enter' || event.key === 'Escape') return;

    if (!event.key.match(new RegExp(this.validation))) {

      event.preventDefault();

    }

  }

  /**
   * move item hightlight
   *
   * @param {string} direction
   * @returns
   * @memberof searchPartial
   */
  move(direction: string): void {

    let setHightlight: number = 0;

    /**
     * if no highlight, hightlight the first
     */
    if (!this.currentHighlight && this.currentHighlight !== 0) {

      this.currentHighlight = 0;
      return;

    }

    setHightlight = this.currentHighlight;

    if (direction === 'ArrowDown') {

      setHightlight += 1;

    }

    if (direction === 'ArrowUp') {

      setHightlight -= 1;

    }

    if (setHightlight < 0) setHightlight = null;
    if (setHightlight > this.entries.length - 1) return;

    this.currentHighlight = setHightlight;

  }

  // isSelected(option: string) {

  //   return this.outputSelected.includes(option);

  // }

  /**
   *
   *
   * @param {string} value
   * @param {KeyboardEvent} event
   * @returns
   * @memberof searchPartial
   */
  checkInput(value: string, event: KeyboardEvent): void {

    if (event.key === 'Enter') return;

    if ((event.key === 'ArrowDown' || event.key === 'ArrowUp') && this.entries.length) {

      this.move(event.key);
      return;

    }


    if (this.callback) {

      if (!value && !this.callbackEmpty) return;
      if (value === this.cache && !this.filterMode) return;

      if (value === this.cache && this.filterMode) {

        const matches = this.possibleEntries.filter((e) => e.value.match(new RegExp(value, 'i')));

        this.updateEntries(matches);
        return;

      }

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {

        this.$emit('callback', value);
        this.cache = value;

      }, this.debounceTime);

      return;

    }

    if (!this.possibleEntries) return;

    const matches = this.possibleEntries.filter((e) => e.value.match(new RegExp(value, 'i')));

    this.updateEntries(matches);

  }

}
</script>
<style lang="less" scoped>


.input.search {

  @import './src/assets/less/00-atoms/search/single.less';

  &.filterMode {
    max-width: 200px;

    .values-container {
      display: grid;
      grid-template-columns: min-content min-content;
      // grid-template-columns: minmax(min-content, 150px) 35px;
      gap: 0;

      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

  }

  .label-container {
    display: grid;
    grid-template-columns: 1fr min-content;
  }

  &.right {

    .values {

      display: grid;
      grid-template-columns: auto 1fr;
      gap: 10px;

      label, .values-container {
        grid-row-start: 1;
      }

      label {
        grid-column-start: 1;
      }

      .values-container {
        grid-column-start: 2;
        align-items: center;
      }
    }

  }

  .loading-indicator {

    position: absolute;
    display: grid;
    align-self: center;
    justify-self: end;

  }

  .position-container {
    position: relative;
    display: grid;
  }

}

.filterModeOverlay {
  position: absolute;
  display: grid;

  top: 15%;
  justify-self: center;
  overflow: hidden;
  width: calc(100% - 40px);
  background-color: @white;
  box-shadow: 0 0 5px -3px @textColor;

  @media @tablet, @desktop {
    width: 200px;
    max-height: 30vh;
    padding: 0;
  }

  .position-container {
    position: relative;
    display: grid;

    .loading-indicator {
      position: absolute;
      display: grid;
      align-self: center;
      justify-self: end;
      margin-right: 10px;
    }
  }

  .input-partial::v-deep {

    input {
      .multi(padding, 2);
      border-bottom: solid 1px @grey;
      font-size: @fontTextSmaller;
    }

  }

  .label-container {
    display: none
  }
}

.search-content.filterMode, .search-content, .selected {

  &.recent {

    h3 {
      .multi(padding, 0, 3);
      color: @grey;
      font-size: @fontSmall;
      line-height: 40px;
      text-transform: uppercase;
      .font-normal;
    }
  }

  li {

    &.empty {
      .multi(padding, 0, 2);
    }

    .element {
      width: 100%;
      .multi(padding, 2);
      color: @textColor;

      font-size: @fontTextSmaller;
      line-height: 20px;
      text-align: left;
      text-transform: uppercase;

      &:hover {
        background-color: @color2;
        color: @textColor;
      }
    }
  }

}

.search-content.filterMode, .selected {

  button {
    position: relative;
    display: grid;

    grid-template-columns: 45px 1fr 20px;

    gap: 5px 0;
    width: 100%;
    border-bottom: solid 1px @grey1;

    font-size: @fontText;
    text-align: left;
    text-overflow: ellipsis;

    white-space: normal;

    .multi(padding, 3, 0);

    &:last-child {
      border: 0;
    }

    &:hover {
      color: @color1;
    }

    .box {
      width: 15px;
      height: 15px;
      border: solid 1px @grey;
      background-color: @white;
    }

    .box,
    .icon-partial {
      display: grid;
      grid-column-start: 1;
      align-self: center;
      justify-self: center;
    }

    .text, .description {
      display: grid;
      grid-column-start: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .description {
      font-size: @fontTextSmaller;
    }

    &.selected {
      color: @color1;
    }
  }

  li {

    .element {
      .multi(padding, 2, 0)
    }
  }

}

.search-content {

  .index(5);
  position: absolute;

  overflow-y: auto;

  background-color: @white;

  box-shadow: 0 0 5px -3px @textColor;

  &.filterMode {
    position: relative;

    width: 100%;
    box-shadow: none;

  }

  li {
    margin: 0;

    &.empty {

      &:hover {

        .element {
          background-color: transparent;
          pointer-events: none;
        }
      }
    }

    &.highlight {

      .element {
        background-color: @color2;
        color: @textColor;
      }

    }

  }

}
</style>

