<template>
  <article class="quickmenu company">
    <component v-if="partial" :is="partial" />
  </article>
</template>

<script lang="ts">
import {
  Component, ExtComponent, Prop, Watch,
} from '@/libs/lila-component';

@Component
// eslint-disable-next-line global-require
export default class QuickmenuMeHeader extends ExtComponent {

  @Prop(String) partial: string;

  @Watch('$route')
  watchRoute() {

    console.log('ROUTE CHANGED');
    this.$store.commit('clearQuickmenu');

  }

  mounted() {

    this.DOM.on('click', '', (e: MouseEvent) => {

      const clickedElement = e.target as Element;

      if (clickedElement.classList.contains('quickmenuTrigger')) return;

      if (!this.$el.contains(clickedElement)) {

        this.$store.commit('clearQuickmenu');
        // this.$store.commit('Actions/show', false);

      }

    });

  }

}
</script>
