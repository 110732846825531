import Models, { ModelDeclaration, ModelHooks } from '@/libs/Models.class';
import ModulePdf from './ModulePdf.model';
import ModuleIndex from './ModuleIndex.model';
import EditorSettings from '@/apps/editor/models/EditorSettings.model';

export default interface Module {
  id: string
  position: string
  layout: string
  changed: boolean
  variant: string[]
  index: ModuleIndex
  pdf: ModulePdf
  editor: EditorSettings
}

const declaration: ModelDeclaration<Module> = {
  id: { type: 'string' },
  position: { type: 'string' },
  layout: { type: 'string' },
  index: { model: 'module-index' },
  pdf: { model: 'module-pdf' },
  variant: { type: 'array', contains: { type: 'string' } },
  changed: { type: 'boolean', default: false },
  editor: {model: 'editor-settings'}
};
const hooks: ModelHooks<Module> = {
  preSave(data) {
    
    delete data.editor;

  },
};

Models.register('module', declaration, hooks);