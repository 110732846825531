<template>
  <section class="link-editor">
    <h4 v-if="$slots.default"><slot /></h4>
    
    <section class="mode-selection-container">

      <section class="mode-selection">
        <button :class="{active: mode === 'internal'}" type="button" @click="setMode('internal')">{{$translate('app-editor-link-mode-internal')}}</button>
        <button :class="{active: mode === 'external'}" type="button" @click="setMode('external')">{{$translate('app-editor-link-mode-external')}}</button>
        <button :class="{active: mode === 'event'}" type="button" @click="setMode('event')">{{$translate('app-editor-link-mode-event')}}</button>
      </section>
      <description-partial>{{$translate(`app-editor-link-mode-${mode}-description`)}}</description-partial>
    
    </section>

    <editor-content-group-partial>
      <input-partial v-if="active('text')" v-model="value.text" placeholder="text" type="text" @keyup="check($event)">{{$translate('text')}}</input-partial>
      <input-partial v-if="mode !== 'event'" :description="`app-editor-link-mode-${mode}-hint`" v-model="value.link" placeholder="link" type="text" @keyup="check($event)">{{$translate('link')}}</input-partial>
      <input-partial v-if="mode === 'event' && !events.length" :description="`app-editor-link-mode-event-hint`" v-model="value.event" placeholder="event" type="text" @keyup="check($event)">{{$translate('event')}}</input-partial>
      <select-partial v-if="mode === 'event' && events.length" allowEmpty :placeholder="$translate('event')"  :options="eventsSelectOptions" :multiple="false" v-model="value.event">{{$translate('event')}}</select-partial>
    </editor-content-group-partial>

    <editor-content-group-partial v-if="mode === 'event' && selectedEvent && selectedEvent.additionalData">
      <input-partial :description="selectedEvent.additionalData.description" v-model="value.additionalData" placeholder="additionalData" type="text" @keyup="check($event)">{{selectedEvent.additionalData.name || $translate('additionalData')}}</input-partial>
      <categories-lists-ext  v-model="value.additionalData"/>
    </editor-content-group-partial>

    <editor-content-group-partial>
      <select-partial v-model="value.variant" placeholder="NO_VARIANTS_CHOOSEN" :options="availableVariants">{{$translate('variant')}}</select-partial>
      <input-partial v-model="value.icon" regex="[a-z_-]{1,10}" placeholder="icon" type="text">{{$translate('icon')}}</input-partial>
    </editor-content-group-partial>

    <editor-content-group-partial v-if="child === 'legacy'">
      <select-partial v-model="value.attributes" placeholder="NO_ATTRIBUTES_CHOOSEN" :options="attributes">{{$translate('attributes')}}</select-partial>
    </editor-content-group-partial>

    <moduleList-partial v-if="!child" class="module-editor-navigation">
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('legacy')">{{$translate('legacy')}}</button-partial>
        </li>
    </moduleList-partial>

  </section>
</template>

<script lang="ts">
import SelectOption from '@/interfaces/selectOption.interface';
import { Component, Prop } from '@/libs/lila-component';
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import Link from '@/models/Link.model';

@Component
// eslint-disable-next-line global-require
export default class LinkEditorPartial extends PartialEditorComponent<Link> {

  @Prop(Object) value: Link;

  @Prop(String) title: string;

  @Prop(Array) inactive: string[];

  attributes: SelectOption[] = [
    { value: 'static', text: 'static' },
    { value: 'event', text: 'event' },
  ];

  availableVariants: SelectOption[] = [
    { value: 'callToAction', text: 'callToAction' },
  ];
  
  model = 'link';

  mode = 'internal';

  mounted() {

    this.checkMode();

  }

  checkMode() {

    if(this.value.event) {

      this.mode = 'event';
    
    } else if(this.value.link?.startsWith('http')) {

      this.mode = 'external';

    } else {
      
      this.mode = 'internal';

    }

  }

  get events() {

    return this.$store.state.AppEditorData.events;

  }

  get eventsSelectOptions() {

    return this.events.map((single) => ({
        value: single.id,
        text: single.name,
        description: single.description
      }));

  }

  get selectedEvent() {

    return this.events.find((single) => single.id === this.value.event);

  }

  setMode(type: 'internal' | 'external' | 'event') {

    this.mode = type;

  }

  check(event: any) {

    if (event.key === 'Backspace' && this.value.text.length === 0) {

      this.$emit('remove', event);

    }

    if (event.key === 'Enter' && this.value.text.length > 0) {

      this.$emit('add', event);

    }

    this.$emit('keyup', event);

  }

  isEmpty() {

    return this.value.link.length === 0;

  }

  active(value: any) {

    if (!this.inactive) return true;
    return !this.inactive.includes(value);

  }

}
</script>
<style lang="less" scoped>
.link-editor {

  .mode-selection-container {
    display: grid;
    .multi(padding, 0, 4, 8, 4);
    gap: 5px;

    .mode-selection {
      display: grid;
      grid-template-columns: repeat(3, max-content);
      gap: 10px;

      button {
        .multi(padding, 2, 0);
        color: @grey;
        text-transform: uppercase;
        .font-head;

        &.active {
          color: @color1;
        }
      }
    }
  }

}
</style>