<template>
  <section class="location-module-editor open editor" :class="{ changed: safeContent.changed }">
    <module-editor-header-partial v-model="safeContent" :position="position" :index="index" :type="type" @move="move($event)" />
    <crumbs-partial :type="safeContent.type" @clearCrumbs="unsetCurrentChild" />

    <section :class="{ optionsVisible: !child }" class="editor-module-container">
      <moduleList-partial v-if="!child" class="module-editor-navigation">
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('textblock')">{{$translate('textblock')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('list')">{{$translate('list')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('links')">{{$translate('links')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('map')">{{$translate('location-module-google-maps')}}</button-partial>
        </li>
      </moduleList-partial>
      <textblock-partial-editor v-if="child === 'textblock'" v-model="safeContent.textblock" />

      <list-partial-editor v-if="child === 'list'" v-model="safeContent.list" />
      <link-list-partial-editor v-if="child === 'links'" v-model="safeContent.links" />

      <editor-content-group-partial v-if="child === 'map'">

         <input-partial v-model="safeContent.map" description="location-module-google-maps-description" placeholder="artist">{{$translate('location-module-google-maps')}}</input-partial>
       
      </editor-content-group-partial>

      <module-options-partial
        v-if="child === 'options' || !child"
        v-model="safeContent"
        :available-variants="availableVariants"
        :selected-variants="safeContent.variant"
        @setChild="setChild('options', true)"
        @unsetChild="setChild('options')"
        @remove="remove()"
        @clone="clone()"
      />

    </section>
  </section>
</template>

<script lang="ts">
import { Component, ModuleEditorComponent } from '@/libs/ModuleEditorComponent';
import TextModule from '../models/TextModule.model';

@Component
export default class LocationModuleEditor extends ModuleEditorComponent<TextModule> {

  model = 'location-module';

}

</script>

