<template>
  <section :class="[state, { open: type !== 'overview' }]" class="editor-options-editor editor">
    <div class="left">
      <router-link class="exit colorScheme2 button icon" :to="{ name: 'app-editor-project-home' }">
        <icons-partial type="arrow-left" />
      </router-link>
    </div>

    <div class="right">
      <div class="state">
        <icons-partial v-if="state === 'changes'" size="large" color-scheme="red" type="warning" />
        <!-- <span v-if="lastSaved">({{lastSaved | moment('HH:mm')}})</span> -->
      </div>

      <action-notice-partial :state="state" :preparsed-errors="errors">
        <button-partial v-if="state === 'new' && scope(['editor:create']) && !isHistory" :class="{ error: state === 'changes' }" save :value="buttonState" @save="save">{{$translate('CREATE_DATA')}}</button-partial>
        <button-partial v-if="state !== 'new' && scope(['editor:edit']) && !isHistory" :class="{ error: state === 'changes' }" color-scheme="colorScheme1" save :value="buttonState" @save="save">{{$translate('save')}}</button-partial>

      </action-notice-partial>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, ModuleEditorComponent, Prop } from '@/libs/ModuleEditorComponent';

@Component
export default class EditorControlsEditor extends ModuleEditorComponent<any> {

  @Prop() isHistory: boolean;

  get state() {

    // if (this.$store.state.AppEditorData.state === 'changes') return '';
    return this.$store.state.AppEditorData.state;

  }

  get buttonState() {

    console.log(this.$store.state.AppEditorData.state);
    if (this.$store.state.AppEditorData.state === 'changes') return '';
    return this.$store.state.AppEditorData.state;

  }

  get lastSaved() {

    return this.$store.state.AppEditorData.lastSaved;

  }

  get errors() {

    return this.$store.state.AppEditorData.errors;

  }

  save() {

    this.$emit('save');

  }

  deleteSite() {

    this.$emit('deleteSite');

  }

}
</script>

<style lang="less" scoped>

.left {

  .icon-partial {
    width: 35px;
    height: 35px;
    background-color: @grey;
    .trans(background);

    &:hover {
      background-color: @grey1;
    }
  }
}

.right {

  .icon-partial {
    width: 20px;
    height: 35px;
  }
}
</style>
