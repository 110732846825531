import vue from 'vue';

export default class loadElements {

  /**
   * app variable with e.g. `media` and filename `select.extension.vue` will result in
   * component named `<select-media-ext />`
   */
  static extensions(): void {

    const appExtensions = import.meta.glob([
      '../apps/**/extensions/*.extension.vue',
      '../extensions/*.extension.vue'  // Added main extensions folder
    ]);
    const appFiles = Object.keys(appExtensions).filter(path => path.endsWith('.vue'));

    appFiles.forEach((path) => {

      // Handle app-specific extensions
      if (path.includes('/apps/')) {

        const matches = path.match(/([a-z-]+)\/extensions\/(([a-z-]+)\.(extension)).vue$/i);

        if (!matches) return;

        const componentName = `${matches[3]}-${matches[1]}-ext`;

        vue.component(
          componentName,
          () => import(`../apps/${matches[1]}/extensions/${matches[2]}.vue`),
        );

      } else if (path.includes('/extensions/')) {

        const matches = path.match(/extensions\/(([a-z-]+)\.(extension)).vue$/i);

        if (!matches) return;

        const componentName = `${matches[2]}-extension`;

        vue.component(
          componentName,
          () => import(`../extensions/${matches[1]}.vue`),
        );

      }

    });

  }

  static components(): void {

    // Get all Vue files from components and apps folders
    const files = import.meta.glob([
      '../components/partials/*.partial.vue',
      '../components/partials/*.partial.editor.vue',
      '../components/modules/*.module.vue',
      '../apps/**/modules/*.module.vue',
      '../apps/**/partials/*.partial.vue',
      '../apps/**/modules/*.module.editor.vue',
      '../apps/**/partials/*.partial.vue',
      '../apps/**/partials/*.partial.editor.vue'
    ]);
    const appFiles = Object.keys(files).filter(path => path.endsWith('.vue'));

    appFiles.forEach((path) => {

      // Handle components folder files
      if (path.includes('/components/')) {

        const matches = path.match(/(([a-z-]+)\.(partial|module))(\.editor)?\.vue$/i);

        if (!matches) {

          return;

        }

        const componentName = matches[4]
          ? `${matches[2]}-${matches[3]}-editor`
          : `${matches[2]}-${matches[3]}`;

        vue.component(
          componentName,
          () => import(`../components/${matches[3]}s/${matches[1]}${matches[4] || ''}.vue`),
        );

      }

      // Handle apps folder files
      if (path.includes('/apps/')) {

        const matches = path.match(/apps\/([a-z-]+)\/(partials|modules)\/([a-z-]+)\.(partial|module)(?:\.(v[1-9]))?(?:\.(editor))?\.vue$/i);

        if (!matches) {

          return;

        }

        const componentArray = [matches[3], matches[4]];

        if (matches[6]) {

          componentArray.push(matches[6]);

        }

        const componentName = componentArray.join('-');
        const fileName = [matches[3], matches[4], matches[6]].filter((single) => single).join('.');

        vue.component(
          componentName,
          () => import(`../apps/${matches[1]}/${matches[2]}/${fileName}.vue`),
        );

      }

    });

  }

  static designComponents(namespace: string = 'lila'): void {

    const files = import.meta.glob([
      '../components/design/**/*.vue',
    ]);
    const filteredFiles = Object.keys(files).filter(path => path.endsWith('.vue'));

    filteredFiles.forEach((path) => {

      const matches = path.match(/(([a-z-]+)\.(module|partial)).(vue)$/i);

      if (!matches) {

        return;

      }

      const componentName = namespace ? `${namespace}-${matches[2]}-${matches[3]}` : `${matches[2]}-${matches[3]}`;

      vue.component(
        componentName,
        () => import(`../components/design/${matches[3]}s/${matches[1]}.vue`),
      );

    });

  }

}
