<template>
  <article class="pagination" :class="navigationAnimation">
    <header>
      <div>
        <h1>{{$translate('team')}}</h1>
        <stats-partial v-if="content.count" v-bind="content.sites" :count="content.count" />
      </div>

      <quickmenu-partial>
        <router-link class="button base colorScheme1" :to="{ name: addMemberLink }">{{$translate('ADD_USER')}}</router-link>
      </quickmenu-partial>
    </header>

    <table class="isSticky">
      <thead-partial :content="headContent" />
    </table>

    <table>
      <tbody>
        <tr v-for="(single, index) in content.data" :key="`single-team-${index}`">
          <td class="fullHeight action">
            <router-link class="full" :to="{ name: editMemberLink, params: { id: single._id } }">{{ single.user.lastname }}, {{ single.user.prename }}</router-link>
          </td>
          <td class="fullHeight">{{ single.user.email }}</td>
        </tr>
      </tbody>
    </table>

    <modal-module />
  </article>
</template>
<script lang="ts">
import { ExtComponent, Component, Watch } from '@/libs/lila-component';
import { CallResponse } from '@lilaquadrat/studio/lib/interfaces';
import MainStoreState from '@/store/mainStoreState.interface';
import { Route } from 'vue-router';
import { Store } from 'vuex';

@Component
export default class AppTeamHomeScreen extends ExtComponent {

  componentName = ['app-team-company-home', 'app-team-project-home'];

  state: string = '';

  addMemberLink: string = '';

  editMemberLink: string = '';

  headContent = [];

  get content() {

    return this.$store.state.AppEditorMain.data;

  }

  @Watch('$route')
  updateRoute(): void {

    this.setEditRoute();

  }

  setEditRoute() {

    if (this.$route.name === 'app-team-company-home') {

      this.addMemberLink = 'app-team-company-add';
      this.editMemberLink = 'app-team-company-edit';

    }

    if (this.$route.name === 'app-team-project-home') {

      this.addMemberLink = 'app-team-project-add';
      this.editMemberLink = 'app-team-project-edit';

    }

  }

  mounted() {

    this.setEditRoute();

    this.headContent.push({
      key: 'id',
      text: 'Name',
      classes: 'id',
    });

    this.headContent.push({
      key: 'info',
      text: 'E-Mail',
    });

  }

  // eslint-disable-next-line class-methods-use-this
  asyncData(params: Record<string, string>, query: Record<string, string | string[]>, store: Store<MainStoreState>, to?: Route): Promise<unknown> {

    const searchObject: any = {
      query,
      settings: { endpoint: 'projects', company: params.company, add: ['team'] },
    };

    console.log(to);

    if (to.name === 'app-team-company-home') {

      searchObject.settings.endpoint = 'companies';

    }

    if (to.name === 'app-team-project-home') {

      searchObject.settings.endpoint = 'projects';
      searchObject.settings.project = store.state.Project.project;

    }

    return store.dispatch('AppEditorMain/getEndpoint', searchObject).then((data: CallResponse<unknown>) => {

      store.commit('AppEditorMain/data', data.r);
      return data.r;

    });

  }

}
</script>
