<script setup lang="ts">
import { computed } from 'vue';


const props = defineProps<{
    value: string;
    name: string,
    defaultValue: string
    description?: string
    disabled?: boolean
}>();
const emit = defineEmits(['input']);

function update($event) {

    if($event.originalTarget.checked) {

        emit('input', props.defaultValue);

    }

}

const isChecked = computed(() => props.value === props.defaultValue);

</script>
<template>
    <label class="radio-partial" :class="{active: isChecked, hasDescription: description, disabled}">
        <input type="radio" :name="name" :value="defaultValue" :checked="isChecked" @change="update" />
        <span class="indicator">
            <span class="active-indicator"></span>
        </span>
        <span class="label"><slot /></span>
        <description-partial v-if="description" inline>{{$translate(description)}}</description-partial>
    </label>
</template>
<style lang="less" scoped>
.radio-partial {
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: max-content 1fr;
  gap: 10px;

  align-items: center;

  &.hasDescription {
    grid-template-rows: max-content max-content;

    .description {
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }

  input {
    display: none;
  }

  .indicator {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 20px;
    height: 20px;
    border: solid 1px @color1;
    border-radius: 50%;
  }

  .active-indicator {
    display: grid;
    width: 70%;
    height: 70%;
    border-radius: 50%;
    background-color: @color1;
    opacity: 0;

    .trans(opacity);
  }

  .label {
    padding: 0;
    .font-bold;
  }

  &.active {

    .active-indicator {
      opacity: 1;
    }
  }

  &:hover {

    .active-indicator {
      opacity: .5;
    }

  }

  &.disabled {
    pointer-events: none;

    .indicator {
      border: solid 1px @grey;
    }

    .label, .description {
      color: @grey;
    }

    &.active {

      .indicator {

        .active-indicator {
          background-color: @grey;
        }
      }

    }
  }

}
</style>