<template>
  <span class="app-tracker-time-partial">
    <template v-if="!text">{{timePassed}}</template>
    <template v-if="text">{{timePassedText}}</template>
  </span>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { Prop } from 'vue-property-decorator';

dayjs.extend(duration);

@Component
export default class AppTrackerTimePartial extends Vue {

  @Prop(Number) value: number;

  @Prop(Date) start: Date;

  @Prop(Date) end: Date;

  @Prop(Boolean) text: boolean;

  @Prop(Boolean) seconds: boolean;

  @Prop(Boolean) days: boolean;

  @Prop(Boolean) years: boolean;

  get useValue() {


    if(this.value) return this.value;

    if(this.start && this.end) {

      const returnValue = this.end.getTime() - this.start.getTime();

      return returnValue;

    }

    return null;

  }

  // eslint-disable-next-line class-methods-use-this
  get timePassed() {

    if (!this.useValue) return '00:00:00';

    const years = dayjs.duration(this.useValue, 'milliseconds').get('years').toString();
    const days = dayjs.duration(this.useValue, 'milliseconds').get('days').toString()
      .padStart(2, '0');
    const hours = dayjs.duration(this.useValue, 'milliseconds').get('hours').toString()
      .padStart(2, '0');
    const minutes = dayjs.duration(this.useValue, 'milliseconds').get('minutes').toString()
      .padStart(2, '0');
    const seconds = dayjs.duration(this.useValue, 'milliseconds').get('seconds').toString()
      .padStart(2, '0');
    const combineArray = [];

    if(this.years) combineArray.push(years);  
    if(this.days) combineArray.push(days);  
    combineArray.push(...[hours, minutes]);
    if(this.seconds) combineArray.push(seconds);  

    return combineArray.join(':');

  }

  get timePassedText() {

    if (!this.useValue) return '';

    const parts: ('years'|'month'|'days'|'hours'|'minutes'|'seconds')[] = ['years','month','days', 'hours', 'minutes', 'seconds'];
    const singular = {
      years: 'app-tracker-datestring-year',
      month: 'app-tracker-datestring-month',
      days: 'app-tracker-datestring-day',
      hours: 'app-tracker-datestring-hour',
      minutes: 'app-tracker-datestring-minute',
      seconds: 'app-tracker-datestring-second',
    };
    const plural = {
      years: 'app-tracker-datestring-years',
      month: 'app-tracker-datestring-months',
      days: 'app-tracker-datestring-days',
      hours: 'app-tracker-datestring-hours',
      minutes: 'app-tracker-datestring-minutes',
      seconds: 'app-tracker-datestring-seconds',
    };
    const textArray = [];
    const textArrayComplete = [];

    parts.forEach((single) => {

      if(single === 'seconds' && !this.seconds) return;

      const value = +dayjs.duration(this.useValue, 'milliseconds').get(single);

      if (value) {

        const text = value === 1
          ? singular[single]
          : plural[single];

        textArray.push(`${value} ${this.$translate(text)}`);

      }

    });


    textArray.forEach((single, index) => {

      textArrayComplete.push(single);

      if (index + 1 < textArray.length - 1 && textArray.length > 2) {

        textArrayComplete.push(', ');

      }

      if ((index + 1 === textArray.length - 1 && textArray.length > 2) || (index === 0 && textArray.length === 2)) {

        textArrayComplete.push(` ${this.$translate('app-tracker-datestring-and')} `);

      }

    });


    return textArrayComplete.join('');

  }

}
</script>
