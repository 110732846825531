import Models, { ModelDeclaration } from '@/libs/Models.class';

export default interface AppPaymentProvidersStripe {
  type: 'shopify'
  label: string
  active: boolean
  token: string
  validationKey: string
}

const declaration: ModelDeclaration<AppPaymentProvidersStripe> = {
  type: { type: 'string', default: 'stripe' },
  label: { type: 'string' },
  active: { type: 'boolean', default: false },
  validationKey: { type: 'string' },
  token: { type: 'string' },
};

Models.register('app-payment-providers-stripe', declaration);