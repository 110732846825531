<template>
  <section class="editor-responsive-editor editor">
    <button-partial color-scheme="colorScheme3" button-type="responsiveNavigationButton" :class="{ active: viewport === 'mobile' }" @confirmed="setSize('mobile')"> MOBILE </button-partial>
    <button-partial color-scheme="colorScheme3" button-type="responsiveNavigationButton" :class="{ active: viewport === 'tablet' }" @confirmed="setSize('tablet')"> TABLET </button-partial>
    <button-partial color-scheme="colorScheme3" button-type="responsiveNavigationButton" :class="{ active: viewport === 'desktop' }" @confirmed="setSize('desktop')"> DESKTOP </button-partial>
    <button-partial color-scheme="colorScheme3" button-type="responsiveNavigationButton" :class="{ active: viewport === 'hd' }" @confirmed="setSize('hd')"> HD </button-partial>
    <button-partial color-scheme="colorScheme3" button-type="responsiveNavigationButton" @confirmed="setSize(null)">FULL</button-partial>
  </section>
</template>

<script lang="ts">
import { Component, ExtPartial } from '@/libs/lila-partial';


@Component
export default class EditorResponsiveEditor extends ExtPartial {

  setSize(media: string) {

    this.$store.commit('AppEditorData/viewport', media);

  }

  get viewport() {

    return this.$store.state.AppEditorData.viewport;

  }

}
</script>

<style lang="less">
.editor-responsive-editor {
  margin: auto;
}
</style>
