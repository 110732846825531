<template>

  <section class="app-drawer" :class="{ padding }">
    <slot />
  </section>

</template>
<script lang="ts">
import { ExtComponent, Component, Prop } from '@/libs/lila-component';

@Component
export default class appDrawerPartial extends ExtComponent {

  @Prop(Boolean) padding: boolean;

}
</script>

<style lang="less" scoped>

.app-drawer {

  display: grid;
  grid-auto-rows: min-content;
  gap: 40px;

  &.padding {
    .basePadding;
  }

}

</style>
