import { VueConstructor } from 'vue/types/umd';
import { Auth } from '@/libs/lila-auth';
import { Store } from 'vuex';
import MainStoreState from '@/store/mainStoreState.interface';

const plugin = {
  install: (vue: VueConstructor, options: { store: Store<MainStoreState> }): void => {

    vue.prototype.$scope = (scope: string[], company?: string, project?: string) => Auth.checkScope(options.store.state.user, scope, company || options.store.state.Company.company, project || options.store.state.Project.project);

  },
};

export default plugin;
