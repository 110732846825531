<template>
  <article>
    <picture-partial-editor v-if="!child" v-model="value.picture" />

    <moduleList-partial v-if="!child" class="module-editor-navigation">
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('description')">{{$translate('description')}}</button-partial>
      </li>
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('link')">{{$translate('link')}}</button-partial>
      </li>
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('list')">{{$translate('list')}}</button-partial>
      </li>
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('links')">{{$translate('links')}}</button-partial>
      </li>
    </moduleList-partial>

    <list-partial-editor v-if="child === 'list'" v-model="value.list" />
    <link-list-partial-editor v-if="child === 'links'" v-model="value.links" />

    <textblock-partial-editor v-if="child === 'description'" v-model="value.textblock" />

    <link-partial-editor v-if="child === 'link'" v-model="value.link" :inactive="['text', 'classes']" />
  </article>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import PicturegroupElement from '@/models/PicturegroupElement.model';

@Component
// eslint-disable-next-line global-require
export default class PicturegroupElementEditor extends PartialEditorComponent<PicturegroupElement> {

  check(event: any) {

    if (event.key === 'Backspace' && this.value.picture.src.length) {

      this.$emit('remove', event);

    }

    if (event.key === 'Enter' && event.target.value.length > 0) {

      this.$emit('add', event);

    }

    this.$emit('keyup', event);

  }

  model = 'picturegroup-element';

}

</script>
