import { UnifiedRouteGuard } from '@/libs/lila-auth';
import EditorScreen from './screens/editor.screen.vue';
import HistoryScreen from './screens/history.screen.vue';
import HomeScreen from './screens/home.screen.vue';
import SettingsScreen from './screens/settings.screen.vue';
import SettingsBaseScreen from './screens/settings-base.screen.vue';
import SettingsMainScreen from './screens/settings-main.screen.vue';
import SettingsOverviewScreen from './screens/settings-overview.screen.vue';

export default [
  {
    path: '/:company/:project/a/editor/new/:base?/:type?',
    name: 'app-editor-project-new',
    beforeEnter: UnifiedRouteGuard,
    meta: {
      animationGroup: 'editor',
      app: 'editor',
    },
    component: EditorScreen,
  },
  {
    path: '/:company/:project/a/editor/edit/:id/history/:index',
    name: 'app-editor-project-edit-history-index',
    beforeEnter: UnifiedRouteGuard,
    meta: {
      animationGroup: 'editor',
      app: 'editor',
    },
    component: EditorScreen,
  },
  {
    path: '/:company/:project/a/editor/edit/:id/:history?/:use?',
    component: EditorScreen,
    beforeEnter: UnifiedRouteGuard,
    name: 'app-editor-project-edit',
    meta: {
      animationGroup: 'editor',
      app: 'editor',
    },
  },
  {
    path: '/:company/:project/a/editor/history/:id/:site?/:version?',
    component: HistoryScreen,
    beforeEnter: UnifiedRouteGuard,
    name: 'app-editor-project-history',
    meta: {
      animationGroup: 'editor',
      app: 'editor',
    },
  },
  {
    path: '/:company/:project/a/editor/:site?',
    component: HomeScreen,
    beforeEnter: UnifiedRouteGuard,
    name: 'app-editor-project-home',
    meta: {
      animationGroup: 'editor',
      app: 'editor',
      parent: true,
      rememberSettings: true,
    },
    children: [
      {
        path: 'settings',
        component: SettingsScreen,
        meta: {
          sidescreen: true,
          defaultRoute: 'app-editor-project-settings-overview',
          app: 'editor',
        },
        children: [
          {
            path: 'base',
            name: 'app-editor-project-settings-base',
            component: SettingsBaseScreen,
            meta: {
              sidescreen: true,
              name: 'app-editor-project-settings-base',
              app: 'editor',
            },
          },
          {
            path: 'main',
            name: 'app-editor-project-settings-main',
            component: SettingsMainScreen,
            meta: {
              sidescreen: true,
              name: 'app-editor-project-settings-main',
              app: 'editor',
            },
          },
          {
            path: '',
            name: 'app-editor-project-settings-overview',
            component: SettingsOverviewScreen,
            meta: {
              sidescreen: true,
              name: 'app-editor-project-settings-overview',
              app: 'editor',
            },
          },
        ],
      },
    ],
  },
];
