
import StructureOptions from '@/interfaces/StructureOptions.interface';
import Models, { ModelDeclaration } from '@/libs/Models.class';

const declaration: ModelDeclaration<StructureOptions> = {
  value: { type: 'string' },
  text: { type: 'string' },
  description: { type: 'string' },
};

Models.register('structure-options', declaration);
