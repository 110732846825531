import InstallableApp from '../../interfaces/InstallableApp.interface';
import routes from './routes';
import translations from './translations/translations';

import './models/ftp.model';
import './models/internal.model';
import './models/pdf.model';
import './models/ProjectPublish.model';
import './models/publish.model';
import './models/webhook.model';
import './models/email.model';

const app: InstallableApp = {
  name: 'publish',
  publisher: 'lilaquadrat',
  translations,
  routes,
  store: 'AppPublish',
};

export default app;
