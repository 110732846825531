import Models, { ModelDeclaration } from '@/libs/Models.class';
import PicturePartial from './PicturePartial.model';
import Textblock from './Textblock.model';

export default interface FactsElement {
  picture: PicturePartial
  textblock: Textblock
}

const declaration: ModelDeclaration<FactsElement> = {
  picture: { model: 'picture-partial' },
  textblock: { model: 'textblock' },
};

Models.register('facts-element', declaration);