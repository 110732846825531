import Models, { ModelDeclaration } from '@/libs/Models.class';

export default interface ListPartial {
  key: string
}

const declaration: ModelDeclaration<ListPartial> = {
  key: { type: 'string', default: 'string' },
};

Models.register('list-partial', declaration);