<template>
  <section class="quickmenu">
    <slot />
  </section>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@Component
export default class quickmenuPartial extends Vue {}
</script>
<style lang="less" scoped>

.quickmenu {

  gap: 5px;
  display: flex;
  clear: both;

  justify-content: end;
  flex-wrap: wrap;
  height: @buttonHeight;

  .submenu {
    height: @buttonHeight;
  }

  .submenuButton {
      width: 35px;
      height: 35px;
  }

}
</style>
