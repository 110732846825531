<template>
  <article class="all-lists-agreements-editor">
    <crumbs-partial inline @clearCrumbs="unsetCurrentChild" />

    <template v-if="value.predefined">
      <description-partial>{{ $translate(value.predefinedDescription) }}</description-partial>
      <section class="selected-content-container">
        <h4>{{contentId}}</h4>
      </section>
      <textarea-partial v-model="value.text" disabled max-length="500" :error="errors.text" required placeholder="app-lists-agreements-text">{{$translate('app-lists-agreements-text')}}</textarea-partial>
      <checkbox-partial v-model="value.required" disabled>{{$translate('app-lists-agreements-required')}}</checkbox-partial>
    </template>

    <template v-if="!value.predefined">
      <select-editor-ext v-model="contentId" :partial="false" :layout="false" placeholder="app-lists-agreements-search">{{$translate('app-lists-agreements-search')}}</select-editor-ext>

      <textarea-partial v-model="value.text" max-length="500" :error="errors.text" required placeholder="app-lists-agreements-text">{{$translate('app-lists-agreements-text')}}</textarea-partial>
      <checkbox-partial v-model="value.required">{{$translate('app-lists-agreements-required')}}</checkbox-partial>

    </template>
  </article>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { ErrorsObject } from '@/libs/ActionNotice';
import { Content, DataObject } from '@lilaquadrat/studio/lib/interfaces';
import { ExtPartial, Prop } from '@/libs/lila-partial';
import AgreementsModel from '../models/agreements.model';

@Component
export default class AppListsCategoriesEditor extends ExtPartial {

  @Prop(Object) value: AgreementsModel;

  @Prop({ type: Object, default: () => ({}) }) errors: ErrorsObject;

  layout: string = '';

  searchData: DataObject<Content> = {};


  reset() {

    if(!this.value.predefined) this.value.contentId = null;

  }

  get contentId() {

    return this.value?.contentId;

  }

  set contentId(id: string) {

    this.value.contentId = id;

  }

}
</script>
<style lang="less" scoped>

.all-lists-agreements-editor {

  display: grid;
  gap: 20px;

  .selected-content-container {

    display: grid;
    grid-template-columns: max-content min-content;
    align-items: center;
    line-height: @buttonHeight;

    .selected {
      font-size: @fontText;
    }
  }

  .searchData {

    li {

      display: grid;

      button {

        display: grid;

        grid-template-columns: 1fr 40px;
        align-content: center;
        justify-items: start;
        height: 50px;
        font-size: @fontText;
        line-height: 20px;
        text-align: left;
        .font-normal;

        &.hasDescription {

          grid-template-rows: 20px 20px;

          p {
            display: grid;

            grid-column-start: 1;
            grid-column-end: 3;
            font-size: @fontTextSmaller;
            text-transform: none;
          }

        }

        .icon-partial {
          display: grid;
          align-self: center;
          justify-self: end;
        }

        &:hover {
          color: @color1;
        }

      }

    }

  }

}
</style>
