import { UnifiedRouteGuard } from '@/libs/lila-auth';
import HomeScreen from './screens/home.screen.vue';
import SingleScreen from './screens/single.screen.vue';

export default [
  {
    path: '/:company/a/publishadmin',
    name: 'app-publishadmin-company-home',
    beforeEnter: UnifiedRouteGuard,
    component: HomeScreen,
    meta: {
      animationGroup: 'publishadmin',
      app: 'publishadmin',
      parent: true,
      rememberSettings: true,
    },
    children: [
      {
        path: 'add',
        component: SingleScreen,
        name: 'app-publishadmin-company-add',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-publishadmin-company-add',
          app: 'publishadmin',
        },
      },
      {
        path: 'edit/:id',
        component: SingleScreen,
        name: 'app-publishadmin-company-edit',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-publishadmin-company-edit',
          app: 'publishadmin',
        },
      },
    ],
  },
];
