<template>
  <article class="linkGroupElement-editor">
    <link-partial-editor v-if="!child" v-model="value" />
    <list-elements-partial-editor v-if="child === 'links'" v-model="value.links" type="link-partial" :model="linkModel" />

    <moduleList-partial v-if="!child" class="module-editor-navigation">
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('links')">{{$translate('links')}}</button-partial>
      </li>
    </moduleList-partial>
  </article>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import LinkGroupElement from '@/models/LinkGroupElement.model';

@Component
// eslint-disable-next-line global-require
export default class LinkGroupElementEditor extends PartialEditorComponent<LinkGroupElement> {

  linkModel = 'link';

}

</script>
