<template>
  <article class="all-lists-categories-editor">
    <crumbs-partial inline @clearCrumbs="unsetCurrentChild" />

    <input-partial v-model="value.name" :error="errors.name" required placeholder="app-lists-categories-name">{{$translate('app-lists-categories-name')}}</input-partial>
    <textarea-partial v-model="value.description" :error="errors.description">{{$translate('app-lists-categories-description-title')}}</textarea-partial>
    <checkbox-partial v-model="value.disabled">{{$translate('app-lists-categories-deactivated')}}</checkbox-partial>

    <input-partial v-model="value.amount" type="number" placeholder="amount">{{$translate('app-lists-categories-amount')}}</input-partial>
    <input-partial v-model="value.externalId" placeholder="externalId">{{$translate('app-lists-categories-externalId')}}</input-partial>

    <section class="price">
      <input-partial v-model="value.price.amount" type="number" placeholder="app-lists-price-amount">{{$translate('app-lists-price-amount')}}</input-partial>
      <input-partial v-model="value.price.currency" placeholder="app-lists-price-currency">{{$translate('app-lists-price-currency')}}</input-partial>
      <input-partial v-model="value.price.tax" type="number" placeholder="app-lists-price-tax">{{$translate('app-lists-price-tax')}}</input-partial>
    </section>

  </article>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { ErrorsObject } from '@/libs/ActionNotice';
import { ExtPartial } from '@/libs/lila-partial';
import { Prop } from 'vue-property-decorator';
import { ListCategory } from '@lilaquadrat/studio/lib/interfaces';

@Component
export default class AppListsCategoriesEditor extends ExtPartial {

  @Prop(Object) value: ListCategory;

  @Prop({ type: Object, default: () => ({}) }) errors: ErrorsObject;


}
</script>
<style lang="less" scoped>

.all-lists-categories-editor {

  display: grid;
  gap: 20px;

  .price {
    display: grid;
    grid-template-columns: 2fr 2fr 3fr;
    gap: 5px;
  }

}
</style>
