// import PartialModel from '@/libs/lila-model';

// export default class AppEditorDataSettingsModel extends PartialModel {

//   filename: string = '';

//   versioning: boolean = false;

// }

import Models, { ModelDeclaration } from '@/libs/Models.class';

export default interface EditorDataPdf {
  filename: string
  versioning: boolean
}

const declaration: ModelDeclaration<EditorDataPdf> = {
  filename: { type: 'string' },
  versioning: { type: 'boolean', default: false },
};

Models.register('editor-data-pdf', declaration);