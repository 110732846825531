import InstallableApp from '../../interfaces/InstallableApp.interface';
import routes from './routes';
import translations from './translations/translations';

import './models/mailfrom.model';

const app: InstallableApp = {
  name: 'publishadmin',
  publisher: 'lilaquadrat',
  translations,
  routes,
  store: 'AppPublishadmin',
};

export default app;
