<template>
  <article class="upload extension" :class="[{ hover }, uploadState]">

    <section v-if="!filename" ref="dropzone" @click="selectFile" @keyup="selectFile" class="dropzone">
      <h3>{{$translate('DRAG_AND_DROP_FILES')}}</h3>
      <div>{{$translate('or')}}</div>
      <h3>{{$translate('CLICK_HERE_TO_ADD_FILE')}}</h3>
    </section>
    <input class="file" ref="fileSelect" type="file">

    <section v-if="filename" class="upload">
      <section class="info-container">
        <h3>{{filename}} - {{$formatBytes(file.size)}}</h3>
        <div class="percentage">{{progress}}%</div>
      </section>
      <div class="progress-container"><div :style="progressStyle" class="progress" /></div>
      <div class="uploadState">
        <div>
          <button-partial v-if="notInProgress" colorScheme="transparent" @confirmed="selectFile">{{$translate('SELECT_NEW_FILE')}}</button-partial>
        </div>
        <div>{{$translate('uploadState ')}} <template v-if="uploadState === 'error'">- {{$translate('response ')}}</template></div>
      </div>
    </section>

  </article>
</template>
<script lang="ts">
import { Auth } from '@/libs/lila-auth';
import {
  ExtComponent, Component, Prop, Watch,
} from '@/libs/lila-component';
import log from 'loglevel';

@Component
export default class UploadMediaExtension extends ExtComponent {

  @Prop(String) app: string;

  @Prop(String) partner: string;

  @Prop(Boolean) admin: boolean;

  @Prop(Boolean) callback: boolean;

  @Prop(String) callbackState: string;

  hover: boolean = false;

  file: File = null;

  response = null;

  progress = 1;

  files: File[] = [];

  uploadState: string = 'unintialized';

  @Watch('callbackState')
  watchFinished() {

    console.log(this.callbackState, this.uploadState);

    if (this.uploadState === 'waiting_for_callback') {

      this.uploadState = this.callbackState;


    }

  }

  get progressStyle() {

    return {
      width: `${this.progress}%`,
    };

  }

  mounted() {

    this.DOM.onElement('drop', this.$refs.dropzone as Element, (event: DragEvent) => {

      this.uploadEvent(event);
      this.$emit('dropped');
      this.hover = false;
      this.$store.commit('AppMedia/setDropzoneActive', false);

    });

    this.DOM.onElement('dragenter', this.$refs.dropzone as Element, (event: DragEvent) => {

      log.info('dragenter', event);
      this.hover = true;
      if (this.notInProgress) this.file = null;

    });

    this.DOM.onElement('dragleave', this.$refs.dropzone as Element, (event: DragEvent) => {

      log.info('dragleave', event);
      this.hover = false;

    });


    this.DOM.onElement('change', this.$refs.fileSelect as Element, (event: any) => {

      for (let a = 0; a < event.target.files.length; a += 1) {

        const file = event.target.files[a];

        this.upload(file);

      }

    });


  }

  get filename() {

    return this.file?.name;

  }

  get notInProgress() {

    return this.uploadState === 'error' || this.uploadState === 'done';

  }

  selectFile() {

    const input = this.$refs.fileSelect as HTMLElement;

    input.click();

  }

  uploadEvent(event: DragEvent) {

    const { files } = event.dataTransfer;

    event.preventDefault();
    event.stopPropagation();

    for (let a = 0; a < files.length; a += 1) {

      this.upload(files[a]);

    }

  }

  upload(file: File) {

    log.debug(file);

    this.file = file;
    this.$emit('finished', false);

    const uploadFile = {
      file: file.name,
      state: 'new',
      size: file.size,
      progress: 0,
    };
    
    const uploadWorker = new Worker(new URL('@/worker/upload.worker.ts', import.meta.url), { type: 'module' });

    log.debug({
      file,
      authHeader: Auth.getAuthHeader(),
      api: this.$store.state.settings.endpoints[1].url,
      company: this.$store.state.Company.company,
      project: this.$store.state.Project.project,
      partner: this.partner,
      admin: !!this.admin,
    });

    this.$emit('finished', false);
    this.uploadState = 'uploading';

    uploadWorker.postMessage({
      file,
      authHeader: Auth.getAuthHeader(),
      api: this.$store.state.settings.endpoints[1].url,
      company: this.$store.state.Company.company,
      project: this.$store.state.Project.project,
      app: this.app,
      partner: this.partner,
      admin: !!this.admin,
    });

    uploadWorker.onmessage = (event) => {

      console.log('worker update', event.data);

      if (event.data.type === 'error') {

        this.uploadState = 'error';
        this.response = event.data.message;
        this.$emit('error', event.data.message);
        this.$emit('finished', true);

      }

      if (event.data.progress >= 100) {

        this.response = event.data.r;

        this.progress = event.data.progress;

        if (this.callback) {

          this.$emit('ready', event.data.r);
          this.uploadState = 'waiting_for_callback';

        } else {

          this.$emit('done', event.data.r);
          this.$emit('finished', true);
          this.uploadState = 'finished';

        }

      }

    };

  }


}
</script>
<style lang="less">

.upload.extension {

  display: grid;
  gap: 20px;

  .dropzone, .upload {
    min-height: 125px;
  }

  input {
    display: none;
  }

  .dropzone {

    .multi(padding, 4);
    display: grid;

    grid-template-rows: min-content min-content min-content;
    align-content: center;

    align-items: center;
    justify-items: center;

    border: dashed 2px transparent;
    background-color: @grey2;
    cursor: pointer;

    transition: transform @aTime @aType, border @aTime @aType;

    h3 {
      color: @color1;
      text-align: center;
    }

    h3, button, div {
      pointer-events: none;
    }

    h3, button {
      color: @color1;
      font-weight: normal;
      font-size: @fontTextSmaller;
      text-transform: uppercase;
      .font-head;
      .lineHeight(@fontTextSmaller)
    }

  }

  &.hover {

    .dropzone {
      border: dashed 2px @color1;
      transform: scale(103%);
    }
  }

  .upload {
    display: grid;
    grid-template-rows: min-content min-content min-content;
    gap: 10px;

    .info-container {
      display: grid;
      grid-template-columns: 1fr max-content;

      h3, div {
        color: @textColor;
        font-size: @fontTextSmaller;
        .font-normal;
      }

      .percentage {
        .font-head;
      }

    }

    .uploadState {

      .trans(color);
      display: grid;
      grid-template-columns: 50% 50%;

      div {
        text-align: right;
      }

      div, button {
        .font-bold;
        height: 20px;
        font-size: @fontTextSmaller;
        line-height: 20px;
      }
    }

    .progress-container {
      width: 100%;

      height: 2px;
      background-color: @grey;

      .progress {
        height: 2px;
        background-color: @color1;
        transition: width .5s ease, color @aTime @aType;
      }

    }

  }

  &.done {

    .upload {

      .progress-container {

        .progress {
          background-color: @success;
        }
      }

      .percentage, .uploadState {
        color: @success;
      }

    }

  }

  &.error {

    .upload {

      .progress-container {

        .progress {
          background-color: @error;
        }
      }

      .percentage, .uploadState {
        color: @error;
      }

    }

  }


}
</style>
