<template>
  <section class="editor-content-group" :class="{ viewContent: viewContent }">
    <button-partial v-if="title" :variant="['moduleNavigation']" :class="{ open: viewContent }" @confirmed="toggle">
      {{$translate(title)}}
    </button-partial>

    <div v-if="viewContent" class="group-content-container">
      <slot />
    </div>
  </section>
</template>

<script lang="ts">
import { ExtComponent, Component, Prop } from '@/libs/lila-component';

@Component
export default class EditorContentGroupPartial extends ExtComponent {

  @Prop(String) title: string;

  @Prop(Boolean) closed: boolean;

  viewContent: boolean = true;

  mounted() {

    this.viewContent = !this.closed;

  }

  toggle() {

    this.viewContent = !this.viewContent;

  }

}
</script>
<style lang="less" scoped>


.editor-content-group {
  display: grid;

  .editor-publish-editor {
    margin-bottom: 30px;
  }

  .moduleNavigationButton {

    &.textIcon {
      padding: 0;
    }
  }

  .group-content-container {
    display: grid;
    gap: 20px;
  }
}

.editor-controls-editor {

  .button {
    font-size: 12px;
  }
}
</style>
