<script setup lang="ts">
import { Auth } from '@/libs/lila-auth';
import { useStore } from '@/store/main.store';
import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router/composables';

const router = useRouter();
const store = useStore();
const actions = ref([]);

onBeforeMount(() => {

  const permissions = Auth.getPermissions(store.state.Company.company, store.state.Project.project);
  const company = store.state.Company;


  console.log(router.currentRoute.name, permissions);
  actions.value = store.state.actions.filter((single) => {

    if(!single.matchingRoutes.includes(router.currentRoute.name)) return false;

    if(single.scope) {

      if(!single.scope.every((single) => permissions.scope.includes(single))) return false;

    }

    if(single.attributes) {

      if(!single.attributes.every((single) => company?.settings?.attributes?.includes(single))) return false;

    }

    return true;


  });


});


</script>
<template>
    <article class="actions-overlay">
      <overlay-header-partial>
        <h3>{{$translate('actions')}}</h3>
      </overlay-header-partial>
      <section class="scroll-container">
        <ul>
          <li v-for="(link, index) in actions" :key="`single-action-${index}`"><router-link :to="link.to">{{ $translate(link.text) }}</router-link></li>
        </ul>
      </section>
    </article>
</template>
<style lang="less" scoped>
.actions-overlay {
  display: grid;

  grid-template-rows: @buttonHeight;
  align-items: center;

  border-top: solid 1px @grey;

  .logout {
    grid-column-start: 3;
  }

  .scroll-container {

    a {
      .multi(padding-left, 4);
      .multi(padding-right, 4);
      display: grid;
      line-height: @buttonHeight;
      text-transform: uppercase;

      .trans(background);

      &:hover {
        background-color: @color1;
        color: @white;
      }
    }
  }

}
</style>