import GenericStore from '@/interfaces/GenericStore.interface';
import MainStore, { state } from '@/store/main.store';
import { DataObject, List, ListParticipants } from '@lilaquadrat/studio/lib/interfaces';
import StudioSDK from '@/libs/StudioSDK';
import AppListParticipantsState from '../interfaces/participants-state.interface';

const AppListParticipantsStore: GenericStore<AppListParticipantsState> = {
  namespaced: true,
  strict: true,

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },


  state: {

    data: {},

    search: {
      tags: [],
      search: null,
      filterState: [],
      sort: '_id',
      order: '1',
    },

    single: null,

  },

  mutations: {

    data(store, data: DataObject<ListParticipants>) {

      store.data = data;

    },

    single(store, data: ListParticipants) {

      store.single = data;

    },


    updateSearch(appListsState, update: { data: any, type: string }) {

      console.log(update, update.data.length, !update.data.length, appListsState.search);

      if (!update.data.length) {

        appListsState.search[update.type] = null;
        return;

      }

      console.log(update, appListsState.search);

      appListsState.search[update.type] = update.data;

    },

    setSearch(appListsState, search: { tags?: string[], search?: string, type?: string }) {

      appListsState.search = search;

    },

  },

  actions: {
    // sdkListExample(store, data: {data: Test, id: string}) {

    //   const sdk = new StudioSDK('test-app', AppTestStore.sdkOptions());

    //   return sdk.test.list(data.id, data.data);

    // },


    get(store, data: { query: { listId: string, search: string, filterState: ListParticipants['state'][], filterCategory: string[], sort: string, order: number }, settings: { company: string, project: string, site: number } }) {

      const sdk = new StudioSDK('lists-app', AppListParticipantsStore.sdkOptions());

      return sdk.lists.participants.list(data.query.listId, data.settings.site, data.query.search, undefined, data.query.filterCategory, data.query.filterState, data.query.sort, data.query.order);

    },

    single(store, data: { list: string, id: string }) {

      const sdk = new StudioSDK('lists-app', AppListParticipantsStore.sdkOptions());

      return sdk.lists.participants.single(data.list, data.id);

    },

    updateState(store, data: { list: string, id: string, state: ListParticipants['state'] }) {

      const sdk = new StudioSDK('lists-app', AppListParticipantsStore.sdkOptions());

      return sdk.lists.participants.udpateState(data.list, data.id, data.state);

    },

    updateNote(store, data: { list: string, id: string, note: ListParticipants['note'] }) {

      const sdk = new StudioSDK('lists-app', AppListParticipantsStore.sdkOptions());

      return sdk.lists.participants.udpateNote(data.list, data.id, data.note);

    },

    remove(store, data: { list: string, id: string }) {

      const sdk = new StudioSDK('lists-app', AppListParticipantsStore.sdkOptions());

      return sdk.lists.participants.remove(data.list, data.id);

    },

  },

};

export default AppListParticipantsStore;
