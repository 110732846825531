<template>
  <section :class="{ changed: safeContent.changed }" class="partial-module-editor open editor">
    <module-editor-header-partial v-model="content" :position="position" :index="index" :type="type" @move="move($event)" />
    <crumbs-partial :type="safeContent.type" @clearCrumbs="unsetCurrentChild" />

    <section :class="{ optionsVisible: !child }" class="editor-module-container">
      <module-options-partial
        v-if="child === 'options' || !child"
        v-model="safeContent"
        :available-variants="availableVariants"
        :selected-variants="safeContent.variant"
        no-export
        @setChild="setChild('options', true)"
        @unsetChild="setChild('options')"
        @remove="remove()"
        @clone="clone()"
      />
    </section>

  </section>
</template>

<script lang="ts">
import { Component, ModuleEditorComponent } from '@/libs/ModuleEditorComponent';
import PartialModule from '../models/PartialModule.model';


@Component
// eslint-disable-next-line global-require
export default class PartialModuleEditor extends ModuleEditorComponent<PartialModule> {

  model ='partial-module';

}

</script>
