<template>
  <section class="list-container list-editor text editor-content-container" :class="{ input: !noEdit }">
    <h4 v-if="$slots.default">
      <slot />
    </h4>
    <input-partial v-if="!noHead" v-model="editTitle" placeholder="title" type="text">{{$translate('title')}}</input-partial>

    <ul v-if="predefined" input="predefined">
      <li v-for="(element, index) in predefinedFiltered" :key="`list-editor-predefined-${index}`">
        <checkbox-partial color-scheme="inline" :value="element.checked" @input="addToList($event, element.value)">{{ element.title }}</checkbox-partial>
      </li>
    </ul>

    <ul ref="input">
      <li v-for="(element, index) in list" :key="`list-editor-${index}`">
        <template v-if="list[index] || (!list[index] && !noEdit)">
          <div>
            <input-partial v-if="!noEdit" v-model="list[index]" :regex="regex" type="text" @keyup="check($event, index)" />
            <div v-if="noEdit" class="text-element">{{ element }}</div>
          </div>

          <div class="list-item-options">
            <button-partial type="button" class="mode up" button-content-type="icon" icon="arrow-up" size="small" color-scheme="transparent" @confirmed="moveItem(index, 'up')" />
            <button-partial type="button" class="mode down" button-content-type="icon" icon="arrow-down" size="small" color-scheme="transparent" @confirmed="moveItem(index, 'down')" />
            <button-partial type="button" class="remove" button-content-type="icon" icon="close" size="small" color-scheme="transparent" @confirmed="removeItem(index, false)" />
          </div>

        </template>
      </li>
    </ul>

  </section>
</template>
<script lang="ts">
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import ArrayTools from '@/libs/lila-array';
import {
  Component, Prop, vue,
} from '@/libs/lila-component';

@Component
export default class ListEditorPartial extends PartialEditorComponent<string[] | {title: string, value: string[]}> {

  @Prop([Object, Array]) value: string[] | {title: string, value: string[]};

  @Prop({type: Array, default: () => []}) predefined: string[];

  @Prop(Boolean) noHead: boolean;

  @Prop(Boolean) noEdit: boolean;

  @Prop(String) regex: string;

  list: string[] = [];

  listPredefined: string[] = [];

  predefinedTranslatedSorted: {title: string, value: string}[] = [];

  title: string = '';

  get editTitle(): string {

    if('title' in this.value) {

      return this.value?.title;
    
}

    return undefined;

  }

  set editTitle(title: string) {

    this.title = title;
    this.$emit('input', this.removeEmpty());

  }

  get predefinedFiltered() {

    return this.predefinedTranslatedSorted.map((single) => ({checked: this.listPredefined.includes(single.value), value: single.value, title: single.title}));

  }

  addToList(checked: boolean, element: string) {

    console.log(checked, element);

    if(checked) {

      this.listPredefined.push(element);

    } else {

      ArrayTools.findAndRemove(element, this.listPredefined);

    }

    this.$emit('input', this.removeEmpty());

  }

  mounted() {

    if (this.noHead) {

      this.setListArray();

    } else {

      this.setList();

    }

    this.setPredefinedList();

  }

  setList() {

    if('title' in this.value) {

      if (!this.value?.value || this.value?.value?.length === 0) {
  
        this.list.push('');
        // this.$emit('input', this.removeEmpty());
  
      } else {
  
        // only add values to the editable list if they are predefined
        this.list = [...this.value.value.filter((single) => !this.predefined.includes(single))];
        
        if(!this.list.length) this.list.push('');
        this.title = this.value.title;
  
      }
    
    }

  }

  setPredefinedList() {

    if(Array.isArray(this.value)) {

      this.value.forEach((single) => {

        if(this.predefined.includes(single)) {

          this.listPredefined.push(single);
        
        }

      });

    }

    this.predefinedTranslatedSorted = this.predefined.map((single) => ({title: this.$translate(single), value: single})).sort((a, b) => a.title.localeCompare(b.title));

  }

  setListArray() {

    if(Array.isArray(this.value)) {

      if (!this.value) {
  
        this.list.push('');
  
      }
  
      if (this.value.length === 0) {

        this.list.push(''); 

      } else {

        // only add values to the editable list if they are predefined
        this.list = this.value.filter((single) => !this.predefined.includes(single)).slice(0);
      
      }

      if(!this.list.length) this.list.push('');
    
    }


  }

  check(event: KeyboardEvent, index: number): void {

    if (event) {

      const target = event.target as HTMLInputElement;

      /**
       * remove item only when hit backspace and the text of it is empty
       * empty link or empty classes should not remove a item
       */
      if (event.key === 'Backspace' && this.list[index].length === 0) {

        event.preventDefault();
        this.removeItem(index);

      }

      if (event.key === 'Enter' && target.value.length > 0) {

        this.addItem(index + 1);
        this.focus(index + 1);

      }

      event.preventDefault();

    }

    this.$emit('input', this.removeEmpty());

  }

  removeEmpty() {

    if (!this.noHead) {

      let cleanedObject: {
        value?: string[];
        title?: string;
      } = {};

      cleanedObject = {
        value: this.list.filter((single) => single.length > 0),
      };

      cleanedObject.value?.push(...this.listPredefined);
      // cleanedObject.value?.sort();

      if (this.title) cleanedObject.title = this.title;

      return cleanedObject;

    }

    let list = this.list.filter((single) => single.length > 0);

    list.push(...this.listPredefined);

    // list.sort();

    if (list.length === 0) list = [];

    return list;

  }

  addItem(index: number, focus: boolean = true) {

    const useIndex = !index 
      ? this.list.length 
      : index;

    if (focus) this.focus(useIndex - 1);
    this.list.splice(useIndex, 0, '');

  }

  removeItem(index: number, focus: boolean = true) {

    if (this.list.length === 1) {

      this.list = [''];

    } else {

      this.list.splice(index, 1);
      if (focus) this.focus(index - 1);

    }

    this.$emit('input', this.removeEmpty());

  }

  moveItem(index: number, direction: string) {

    const to = direction === 'up' ? index - 1 : index + 1;

    if (to < 0) return;
    if (index > this.list.length - 1) return;

    this.list.splice(to, 0, this.list.splice(index, 1)[0]);

    this.$emit('input', this.removeEmpty());

  }

  focus(index: number) {

    vue.nextTick().then(() => {

      const ul = this.$refs.input as HTMLElement;
      const li = ul.querySelectorAll('li');

      if (li.length === 0) return;

      const newFocus = li[index].querySelector('input') as HTMLElement;

      newFocus.focus();

    });

  }

}
</script>
<style lang="less" scoped>

.list-editor {

  ul {

    li::v-deep {

      min-height: 35px;

      .list-item-options {
        grid-template-rows: 35px;
        grid-auto-columns: 20px 20px 20px;
      }

      .button {
        height: 35px;
      }

      input {
        .multi(padding-right, 18);
      }
    }
  }
}
</style>
