<template>
  <section class="editor-history">
    <details-partial>
      <h5>{{$translate('date')}}</h5>
      <h4>{{$date(content.history.date, null, true)}} - {{$date(content.history.date, 'DD.MM.YYYY HH:mm')}}</h4>
    </details-partial>

    <details-partial>
      <h5>{{$translate('reason')}} - {{$translate('user')}}</h5>
      <h4>
        {{$translate(`history_${content.history.reason}`)}}
        {{$translate('von')}}
        {{ content.history.user }}
      </h4>
    </details-partial>

    <details-partial>
      <router-link class="button base colorScheme2" :to="{ name: 'app-editor-project-edit', params: { id: parentId, history: content._id, use: 'use' } }"> {{$translate('start editing this history entry')}}</router-link>
      <p>
        {{$translate('Be aware that you will overwrite all changes that are made after this version')}}
      </p>
      <p>{{$translate('This will become the current version')}}</p>
    </details-partial>


    <details-partial>
      <router-link class="button base colorScheme2" :to="{ name: 'app-editor-project-new', params: { base: content._id, type: 'history' } }">{{$translate('create a copy from this history')}}</router-link>
      <p>{{$translate('This site will not be altered')}}</p>
    </details-partial>

    <router-link class="button base colorScheme2" :to="{ name: 'app-editor-project-edit', params: { id: parentId } }">{{$translate('start editing the current version')}}</router-link>
  </section>
</template>

<script lang="ts">
import {
  ExtComponent, Component, Watch, Prop,
} from '@/libs/lila-component';

@Component
export default class AppEditorHistoryOptionsPartial extends ExtComponent {

  get parentId() {

    return this.content.id;

  }

  get viewSingle() {

    return this.$store.state.AppHistory.details;

  }

  get content() {

    if (!this.viewSingle && this.viewSingle !== 0) return {};

    return this.$store.state.AppHistory.data?.data?.find((single) => single.history.version === this.viewSingle);

  }

}
</script>
<style lang="less">


.editor-history {
  .multi(padding, 2, 4);

  display: grid;
  gap: 40px;
}
</style>
