import Models, { ModelDeclaration } from '@/libs/Models.class';
import { Content } from '@lilaquadrat/interfaces';
import { PublishContentGroup } from '@lilaquadrat/studio/lib/interfaces';

export default interface AppPublishSettingInternal {
  type: 'internal'
  label: string
  active: boolean
  availableForApps: string[]
  affectedStates: string[]
  availableForContentGroups: PublishContentGroup[]
  contextData: Content[]
}

const declaration: ModelDeclaration<AppPublishSettingInternal> = {
  type: { type: 'string', default: 'internal' },
  label: { type: 'string' },
  active: { type: 'boolean', default: false },
  availableForApps: { type: 'array', contains: { type: 'string' } },
  affectedStates: { type: 'array', contains: { type: 'string' } },
  availableForContentGroups: { type: 'array', contains: { type: 'string' } },
  contextData: { type: 'array', contains: { type: 'string' } },
};

Models.register('app-publish-settings-internal', declaration);