<template>
  <section class="tags-search">
    <search-partial
      callback
      :selected-position="selectedPosition"
      :category="category"
      :placeholder="placeholder"
      :allow-new="allowNew"
      :validation="/^[a-z0-9-_+\/]{1,30}$/i"
      :possible-entries="entries"
      :value="tags"
      :no-selected="noSelected"
      :filter-mode="filterMode"
      :multiple="multiple"
      :show-always-text="showAlwaysText"
      :loading="loading"
      use-values
      @callback="search($event)"
      @input="updateTags"
    >
      <slot />
    </search-partial>
  </section>
</template>

<script lang="ts">
import {
  ExtComponent, Component, Prop, Watch,
} from '@/libs/lila-component';
import { Call } from '@/libs/lila-call';
import { Auth } from '@/libs/lila-auth';
import { CallResponse } from '@lilaquadrat/studio/lib/interfaces';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import MainStore, { state } from '@/store/main.store';

@Component
// eslint-disable-next-line global-require
export default class tagsPartial extends ExtComponent {

  @Prop(Array) value: string[];

  @Prop(String) type: string;

  @Prop(String) placeholder: string;

  @Prop(Boolean) allowNew: boolean;

  @Prop(String) apiEndpoint: string;

  @Prop({ default: true }) multiple: boolean;

  @Prop(Boolean) noSelected: boolean;

  @Prop(Boolean) filterMode: boolean;

  @Prop(Boolean) showAlwaysText: boolean;

  /**
   * adds category to search, new entries and only shows tags with that category.
   *
   * ``category:entry``
   */
  @Prop(String) category: string;

  @Prop(String) selectedPosition: 'bottom' | 'right';

  @Prop(Array) predefined: { value: string; name: string; }[];

  entries: { value: string; name: string; }[] = [];

  tags: string[] = [];

  loading: boolean = false;

  @Watch('value')
  valueFunction() {

    this.tags = this.value?.filter((single) => single) || [];

  }

  mounted() {

    if (this.predefined) this.entries = this.predefined;
    this.tags = this.value?.filter((single) => single) || [];

  }

  updateTags(tags: string[]) {

    this.$emit(
      'input',
      tags.filter((single) => single),
    );

  }

  search(input: string) {

    if (this.predefined) return Promise.resolve();

    this.loading = true;

    const inputWithCategory = this.category ? `${this.category}:${input}` : input;
    let apiEndpoint: string = this.apiEndpoint ?? 'api';
    const urlArray = [this.$store.state.Company.company, this.$store.state.Project.project, 'tags', inputWithCategory];
    const call = new Call('media', this.$store.getters.endpoints);


    if (this.type === 'customers') {

      const sdk = new StudioSDK('studio-app', {
        authToken: state.authToken,
        customEndpoints: MainStore.getters.endpointsSDK,
        ...MainStore.getters.companyProject,
      });

      return sdk.customers.tags(inputWithCategory)
        .then((response) => {

          this.handleResponse(response);

        })
        .catch((e) => {

          console.log(e);
          this.loading = true;
          throw new Error(e);

        });


    }

    if (this.type !== 'media') {

      urlArray.unshift(this.type);

    } else {

      apiEndpoint = 'media';

    }

    return call
      .get(apiEndpoint, `/${urlArray.join('/')}`, {}, Auth.getAuthHeader())
      .then((data: CallResponse<string[]>) => {

        this.entries = data.r.map((single) => ({ value: single, name: single }));
        this.loading = false;

      })
      .catch((e) => {

        console.log(e);
        this.loading = true;
        throw new Error(e);

      });

  }

  handleResponse(response: SDKResponse<string[]>) {

    this.entries = response.data.map((single) => ({ value: single, name: single }));
    this.loading = false;

  }

}
</script>
