<template>
  <article :class="navigationAnimation" class="app-hosting-domains-screen">
    <div class="pagenator full">
      <header class="main">

        <section class="stats">
          <h1>{{$translate('app-hosting-domains-overview')}}</h1>
          <h3>
            <template v-if="!content.sites.last">{{ content.count }}</template>
            <template v-if="content.sites.last">{{ content.sites.range[0] }} - {{ content.sites.range[1] }} ({{ content.count }})</template>
            / {{ content.all }} {{$translate('total')}}
          </h3>
        </section>

        <quickmenu-partial v-if="$scope(['publishMethods:create'])">
          <router-link :title="$translate('app-hosting-project-domains-single-add')" :aria-label="$translate('app-hosting-project-domains-single-add')" class="main icon" :to="{ name: 'app-hosting-project-domains-single-add' }"><icons-partial type="add" size="medium" /></router-link>
        </quickmenu-partial>
      </header>

      <section class="sites-filter-container">
        <section class="select" />
        <sites-partial paramKey="site" :data="content" />
      </section>

      <section class="scroll-container">
        <table class="isSticky">
          <thead-partial :siteCurrent="currentSite" :search="$route.query.search" :sort="sort" :order="order" :content="headContent" />
        </table>

        <table class="data-switch" :class="contentAnimation">
          <tbody>
            <tr v-for="(single, index) in content.data" :key="`data-overview-${index}`">
              <td class="id fullHeight action" :class="{ hasAttribute: single.app || single.model }">
                <router-link
                  :to="{
                    name: 'app-hosting-project-domains-single-edit',
                    params: {
                      id: single._id,
                      company: $store.state.Company.company,
                      project: $store.state.Project.project,
                    },
                  }"
                >
                  <div>
                    <div><span v-if="single.app" class="attribute">{{single.app}}</span> <span v-if="single.model" class="attribute">{{single.domain}}</span></div>
                    <span class="name">
                      {{single.domain}}
                    </span>
                  </div>
                </router-link>
              </td>

              <td class="attributes fullHeight">
                <icons-partial v-if='!single.active' :title="$translate('app-hosting-domains-inactive')" colorScheme='grey' type='checked' size='small' />
                <icons-partial v-if='single.active' :title="$translate('app-hosting-domains-active')" type='checked' size='small' />
                <icons-partial v-if='!single.validated && !single.isDefault' :title="$translate('app-hosting-domains-validated')" colorScheme='red' type='warning' size='small' />
                <icons-partial v-if='single.isDefault' :title="$translate('app-hosting-domains-default')" type='server' size='small' />
                <icons-partial v-if='single.isMain' :title="$translate('app-hosting-domains-main')" type='home' size='small' />
              </td>

            </tr>
          </tbody>
        </table>
      </section>

    </div>

  </article>
</template>
<script lang="ts">
import {
  DataObject, Domain,
} from '@lilaquadrat/studio/lib/interfaces';
import { ExtComponent, Component } from '@/libs/lila-component';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';
import hardCopy from '@/mixins/hardCopy';
import cleanObject from '@/mixins/cleanObject';
import { SDKResponse } from '@/libs/StudioSDK';
import MainStoreState from '@/store/mainStoreState.interface';

@Component
export default class AppPublishHomeScreen extends ExtComponent {

  componentName = 'app-hosting-project-domains';

  headContent: any[] = [];

  order: string = '-1';

  sort: string = 'id';

  query: Record<string, string> = {};

  constructor() {

    super();

    this.headContent.push({
      key: 'domain',
      text: 'domain',
      classes: 'id',
    });

    this.headContent.push({
      classes: 'attributes',
      text: '',
    });


  }


  get content(): DataObject<Domain[]> {

    return this.$store.state.AppHosting.domains;

  }

  get currentSite() {

    return this.$route.params.site ? +this.$route.params.site : null;

  }

  updateSearch(value: string, type: string) {

    const newQuery = this.query;

    newQuery[type] = value;
    cleanObject(newQuery);

    this.query = newQuery;

    this.$router.replace({ params: { site: '1' }, query: this.query });

  }

  mounted() {

    this.query = hardCopy(this.$route.query);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store
      .dispatch('AppHosting/getDomains', {
        settings: {
          project: params.project,
          company: params.company,
          site: params.site,
        },
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<Domain>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppHosting/domains', preloadedData.data);
    return Promise.resolve();

  }

}
</script>
<style lang="less">

.app-hosting-domains-screen {

  table {

    tr {
      grid-template-columns: 4fr 1fr;
    }

  }

  .pagenator.full {

    header.main {

      grid-template-columns: max-content 1fr;

    }

    table {

      &.isSticky {
        top: 0;
      }

      td {

        &.attributes {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        }
      }
    }
  }

  .sites-filter-container {

    display: grid;
    grid-template-columns: 1fr 1fr;
    .multi(padding-top, 2);
    .multi(padding-bottom, 4);
    .basePadding;

    .sites-container {
      justify-self: end;
    }

    .select {
      display: grid;
      grid-template-columns: 150px 1fr;
    }
  }

}

</style>

