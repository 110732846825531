import Models, { ModelDeclaration } from '@/libs/Models.class';
import GenericData from './GenericData.model';

export default interface ContactCategoryElement {
  genericData: GenericData
  title: string
  description: string
  required: string[]
}

const declaration: ModelDeclaration<ContactCategoryElement> = {
  title: { type: 'string' },
  description: { type: 'string' },
  genericData: { model: 'generic-data' },
  required: { type: 'array', contains: { type: 'string' } }
};

Models.register('contact-category-element', declaration);