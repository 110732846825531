<template>
  <section class="secret">
    <data-grid-partial>
      <section class="data-grid-container">
        <section class="data-grid-single full">
          <h4>{{$translate('app-publish-webhook-key')}}</h4>
          <p v-if="key">{{key}}</p>
          <button-group-partial v-if="!key" left>
            <button-partial type="button" doublecheck @confirmed="getSecret">{{$translate('app-publish-secret-get')}}</button-partial>
          </button-group-partial>
        </section>
        <section v-if="key && scope(['publish:refreshSecret'])" class="data-grid-single full">
          <notice-partial type="notice">{{$translate('if the secret gets refreshed, all systems that consume the webhook, need to be updated to the new secret.')}}</notice-partial>
        </section>
        <section v-if="key && scope(['publish:refreshSecret'])" class="data-grid-single full">
          <h4>{{$translate('app-publish-actions')}}</h4>
          <button-group-partial left>
            <button-partial v-if="key && scope(['publish:refreshSecret'])" type="button" doublecheck @confirmed="refreshSecret">{{$translate('app-publish-secret-refresh')}}</button-partial>
          </button-group-partial>
        </section>
      </section>
    </data-grid-partial>
  </section>
</template>
<script lang="ts">
import { ExtComponent } from '@/libs/lila-component';
import Component from 'vue-class-component';

@Component
export default class secretPartial extends ExtComponent {

  key: string = '';

  getSecret() {

    this.$store.dispatch('AppPublish/secret', this.$store.getters.companyProject)
      .then((response) => {

        this.key = response.r.key;

      })
      .catch((e) => {

        console.error(e);

      });

  }

  refreshSecret() {

    this.$store.dispatch('AppPublish/refreshSecret', this.$store.getters.companyProject)
      .then(() => this.getSecret())
      .catch((e) => {

        console.error(e);

      });

  }

}
</script>
<style lang="less" scoped>


.secret {


}
</style>
