<template>
  <article class="files media screen pagenator full" :class="navigationAnimation">

    <header class="main">
      <section class="stats" v-if="content.sites">
          <h1>{{$translate('data')}}</h1>
          <h3>
            <template v-if="!content.sites.last">{{ content.count }}</template>
            <template v-if="content.sites.last">{{ content.sites.range[0] }} - {{ content.sites.range[1] }} ({{ content.count }})</template>
            / {{ content.all }} {{$translate('total')}} - {{ $formatBytes(used) }}
          </h3>
      </section>

      <quickmenu-partial>
        <upload-partial :uploadState="uploadState" />
        <button type="button" v-if="bigScreen" class="base colorScheme1 text" @click="triggerDropzone">{{$translate('ADD_FILES')}}</button>
        <button-partial :title="$translate('ADD_FILES')" v-if="!bigScreen" buttonContentType="icon" icon="add" colorScheme="colorScheme4" @click="triggerDropzone" />
      </quickmenu-partial>
    </header>

    <!-- <media-sites-filter-partial @update="updateSearch" :search="search" :content="$store.state.AppMedia.content" /> -->
    <quickfilter-partial category useTags reload categoryType="media" store="AppMedia" @reload="reload">
      <section class="folder-container" v-if="folder">
        <button-partial buttonContentType="iconText" icon="close" size="small" colorScheme="colorScheme2" @confirmed="resetFolder">{{ folder }}</button-partial>
      </section>
    </quickfilter-partial>

    <section class="scroll-container">
      <section class="dropfield" :class="contentAnimation">
        <template v-for="(item, index) in content.data">
          <router-link
            v-if="!item.folder"
            :key="`item-${item.id}`"
            @click.native="setActive(index)"
            :to="{ name: `app-media-project-edit`, params: { filename: item.id, s: $route.params.s } }"
            class="item"
            :class="{ image: item.image }"
            :style="{ width: `${item.view.width}px`, flexGrow: item.view.width }">
            <i :style="{ paddingBottom: `${item.view.padding}%` }" />

            <picture-internal-partial v-if="item.image" :key="`item-picture-${item.id}`" :picture="{ src: item.view.thumb, mimetype: item.mimetype }" />

            <section v-if="item.video" class="video-placeholder">VIDEO</section>
            <!-- <lila-video-partial v-if="item.video" preload="none" v-bind="{ src: item.view.src, preview: true }" /> -->

            <div class="placeholder" v-if="!item.video && !item.image" />

            <div class="infos" v-if="item.video || (!item.video && !item.image)">
              <div class="filename"><span>{{ item.filename }}</span></div>
              <div class="typeSize">
                <span v-if="!item.video && !item.image">{{ item.mimetype }}</span>
                <span>{{ $formatBytes(item.size, 2) }}</span>
              </div>
            </div>
          </router-link>

          <router-link :style="{ width: '200px', flexGrow: 350 }" :key="`folder-${item.filename}`" :to="{ params: { site: '1' }, query: { folder: item.filename } }" class="item folder" v-if="item.folder">
            <i :style="{ paddingBottom: `50%` }" />
            <div class="placeholder" />
            <div class="infos">
              <span>{{ item.filename }}</span>
            </div>
            <icons-partial type="folder" colorScheme="white" size="big" />
          </router-link>
        </template>
      </section>
    </section>

    <modal-module />

    <media-dropzone-partial @refresh="callContent" v-show="$store.state.AppMedia.dropzoneActive" />
  </article>
</template>
<script lang="ts">
import {
  ExtComponent, Component,
} from '@/libs/lila-component';
import { DataObject, Media } from '@lilaquadrat/studio/lib/interfaces';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';
import { BasicData } from '@lilaquadrat/interfaces';

@Component
export default class AppMediaHomeScreen extends ExtComponent {

  MediaStore = this.$store.state.AppMedia;

  dropfield: HTMLElement;

  routedName: string = null;

  componentName = 'app-media-project-home';

  // updateMetadata(metadata: any, index: number) {

  //   this.$store.commit('AppMedia/updateMetadata', { metadata, index });

  // }

  get tags() {

    return this.$store.state.AppMedia.search.tags;

  }


  get bigScreen() {

    if (this.$store.state.media === 'desktop' || this.$store.state.media === 'wide') {

      return true;

    }

    return false;

  }

  get content(): DataObject<BasicData<Media>[]> {

    return this.$store.state.AppMedia.data;

  }


  get filterActive() {

    return this.$store.state.AppMedia.filterActive;

  }


  get currentSite() {

    return +this.$route.params.site || 0;

  }

  get routeName() {

    return this.$route.name;

  }

  get params() {

    return this.$route.params;

  }

  get folder() {

    return this.$route.query.folder;

  }

  toggleFilter() {

    this.$store.commit('AppMedia/toggleFilter', this.$store.state.AppMedia.filterActive !== true);

  }

  setActive(index: number) {

    this.$store.commit('AppMedia/setActive', index);

  }

  get uploadState() {

    return this.$store.state.AppMedia.uploadState;

  }

  resetFolder() {

    const query = { ...this.$route.query };

    delete query.folder;

    this.$router.push({ query });

  }

  triggerDropzone() {

    this.$store.commit('AppMedia/setDropzoneActive', true);

  }

  get used() {

    return this.$store.state.AppMedia.stats?.used;

  }

  async reload() {

    StudioSDK.flushCache('media', 'get');
    await this.callContent();

  }

  async callContent() {

    await this.$store.dispatch(
      'AppMedia/stats',
      {
        company: this.$store.state.Company.company,
        project: this.$store.state.Project.project,
      },
    );

    try {
      
      const data = await this.$store.dispatch(
          'AppMedia/get',
          {
            company: this.$store.state.Company.company,
            project: this.$store.state.Project.project,
            site: this.$route.params.site,
            query: this.$route.query,
          },
        );

      this.$store.commit('AppMedia/data', data.data);
    
    } catch (error) {
          
        console.error(error);
        
    }

  }

  mounted() {

    this.routedName = this.$route.name;

    this.dropfield = this.$el as HTMLElement;
    this.DOM.onElement('dragover dragstart', this.$el, () => {

      if (this.$store.state.AppMedia.dropzoneActive) return;
      this.$store.commit('AppMedia/setDropzoneActive', true);

    });

  }

    // eslint-disable-next-line class-methods-use-this
  async preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    await store.dispatch(
      'AppMedia/stats',
      {
        company: store.state.Company.company,
        project: store.state.Project.project,
      },
    );

    return store.dispatch(
      'AppMedia/get',
      {
        company: store.state.Company.company,
        project: store.state.Project.project,
        site: params.site,
        query,
      },
    );


  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<Media>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppMedia/data', preloadedData.data);
    return Promise.resolve();

  }


}

</script>

<style lang="less" scoped>

.media.screen {

  .scroll-container {

    .basePadding;
    .multi(padding-top, 4);
    .multi(padding-bottom, 4);

    .dropfield {

      padding: 0;

    }

  }

}

</style>
