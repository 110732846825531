<template>
  <article :class="navigationAnimation" class="app-hostingadmin-home-screen">
    <div v-if="content" class="pagenator full">
      <header class="main">

        <section class="stats">
          <h1>{{ $translate('app-hostingadmin') }}</h1>
          <h3 v-if="content.sites">
            <template v-if="!content.sites.last">{{ content.count }}</template>
            <template v-if="content.sites.last">{{ content.sites.range[0] }} - {{ content.sites.range[1] }} ({{ content.count }})</template>
            / {{ content.all }} {{ $translate('total') }}
          </h3>
        </section>

        <app-hostingadmin-quickmenu-partial/>
      </header>

      <quickfilter-partial reload store="AppHostingAdmin" use-emit @update="updateSearch" @reload="reload" />

      <section class="scroll-container">
        <table class="isSticky contentHead">
          <thead-partial :site-current="currentSite" :search="$route.query.search" :sort="sort" :order="order" :content="headContent" />
        </table>

        <table class="data-switch contentTable" :class="contentAnimation">
          <tbody>
            <tr v-for="(single, index) in content.data" :key="`data-overview-${index}`">
              <td class="id fullHeight action" :class="{ hasAttribute: single.app || single.model }">
                <router-link
                  :to="{
                    name: 'app-hostingadmin-company-certificatesActions-home',
                    params: {
                      id: single._id,
                      company: $store.state.Company.company,
                      project: $store.state.Project.project,
                    },
                  }"
                >
                    {{single.company}} - {{single.project}}
                </router-link>
              </td>

              <td>
                <icons-partial v-if='!single.active' :title="$translate('app-hostingadmin-hosting-inactive')" color-scheme='grey' type='checked' size='small' />
                <icons-partial v-if='single.active' :title="$translate('app-hostingadmin-hosting-active')" type='checked' size='small' />
              </td>

            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <modal-module />

  </article>
</template>
<script lang="ts">
import { ExtComponent, Component, Watch } from '@/libs/lila-component';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import { Hosting } from '@lilaquadrat/studio/interfaces';
import { DataObject } from '@lilaquadrat/studio/lib/interfaces';
import cleanObject from '@/mixins/cleanObject';
import hardCopy from '@/mixins/hardCopy';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppHostingAdminHomeScreen extends ExtComponent {

  componentName = 'app-hostingadmin-company-home';

  headContent = [
    {
      key: 'name',
      text: 'name',
      classes: 'name',
    },
    {
      text: 'app-hostingadmin-title-active',
    },
  ];

  // stateOptions = [
  //  {
  //    text: 'app-hostingadmin-type-company',
  //    value: 'company',
  //  },
  //  {
  //   text: 'app-hostingadmin-type-persons',
  //    value: 'person',
  //  },
  // ];

  @Watch('$route')
  watchRoute() {

    this.setSearch();

  }

  get currentSite() {

    return this.$route.params.site ? +this.$route.params.site : null;

  }

  get type() {

    return this.$store.state.AppHostingAdmin.search?.type;

  }

  get sort() {

    return this.$store.state.AppHostingAdmin.search?.sort;

  }

  get order() {

    return this.$store.state.AppHostingAdmin.search?.order;

  }

  get content(): DataObject<Hosting[]> {

    return this.$store.state.AppHostingAdmin.data || undefined;

  }

  mounted() {

    this.setSearch();

  }


  getSearch() {

    const query: Record<string, string | string[]> = {};

    query.sort = this.$route.query.sort as string || 'name';
    query.order = this.$route.query.order as string || '1';

    query.tags = this.$route.query.tags || null;
    query.type = this.$route.query.type || null;
    query.search = this.$route.query.search || null;

    return query;

  }

  setSearch() {

    // only update search if the current component is used and no child is active
    if (this.$route.name !== this.componentName) return;
    this.$store.commit('AppHostingAdmin/setSearch', this.getSearch());

  }


  async updateSearch(data: {data: string, type: 'string'}) {

    this.$store.commit('AppHostingAdmin/updateSearch', { data: data.data, type: data.type });

    const query = hardCopy(this.$store.state.AppHostingAdmin.search);

    cleanObject(query);

    this.$router.push({ params: { site: '1' }, query });

  }

  async reload() {

    StudioSDK.flushCache('HostingAdmin', 'list');

    const data = await this.$store
      .dispatch('AppHostingAdmin/get', {
        query: this.$route.query,
        settings: {
          project: this.$route.params.project,
          company: this.$route.params.company,
          site: this.$route.params.site,
        },
      });

    this.$store.commit('AppHostingAdmin/data', data.data);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    console.log(params, query);
    return store
      .dispatch('AppHostingAdmin/get', {
        query,
        settings: {
          project: params.project,
          company: params.company,
          site: params.site,
        },
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<DataObject<Hosting>>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppHostingAdmin/data', preloadedData?.data);
    return Promise.resolve();

  }

}
</script>
<style lang="less">
.app-hostingadmin-home-screen {

  table.contentTable, table.contentHead {

    tr {
      grid-template-columns: 1fr 100px;

      td {

        &.hasDescription {

          .id {
            display: grid;
            color: @textColor;
            text-transform: uppercase;
          }

        }

      }

    }

  }

  .sites-filter-container {

    display: grid;
    grid-template-columns: 1fr 1fr;
    .multi(padding-top, 2);
    .multi(padding-bottom, 4);
    .basePadding;

    .sites-container {
      justify-self: end;
    }

    .select {
      display: grid;
      grid-template-columns: 150px 1fr;
    }
  }
}
</style>


