import { UnifiedRouteGuard } from '@/libs/lila-auth';
import HomeScreen from './screens/home.screen.vue';
import SingleScreen from './screens/single.screen.vue';

export default [
  {
    path: '/:company/:project/a/learn/:site?',
    name: 'app-learn-project-home',
    beforeEnter: UnifiedRouteGuard,
    component: HomeScreen,
    meta: {
      app: 'learn',
    },
  },
  {
    path: '/:company/:project/a/learn/c/:link+',
    name: 'app-learn-project-content',
    pathToRegexpOptions: { start: true, end: false },
    component: SingleScreen,
    meta: {
      app: 'learn',
    },
  },
];
