const ArrayTools = {

  add(index: number, input: string[]): string[] {

    const useIndex = !index
      ? input.length
      : index;

    input.splice(useIndex, 0, '');

    return input;

  },

  remove(index: number, input: string[]): string[] {

    input.splice(index, 1);

    return input;

  },

  move(index: number, direction: string, input: string[]): void {

    const to = direction === 'up'
      ? index - 1
      : index + 1;

    if (to < 0) return;
    if (index > input.length - 1) return;

    input.splice(to, 0, input.splice(index, 1)[0]);

  },

  findAndRemove(element: string, input: string[]) {

    const index = input.findIndex((single) => single === element);

    if (index !== -1) {

        input.splice(index, 1);
    
    }

  }

};

export default ArrayTools;
