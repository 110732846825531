<template>
  <section v-if="sites" class="sites-container" :class="[mode, { center }]">

    <button-group-partial v-if="mode === 'history'" no-gap :left="!center" :center="center">

      <router-link v-if="sites.last !== 0" class="button base" :class="[current === sites.first ? 'colorScheme1' : 'colorScheme2']" :to="{ params: first, query: $route.query }">1</router-link>
      <router-link v-if="current !== sites.first && current !== sites.last" class="button base colorScheme1" :to="{ params: { site: current }, query: $route.query }">{{current}}</router-link>
      <router-link v-if="(sites.first !== sites.last) && sites.last !== 0" class="button base" :class="[current === sites.last ? 'colorScheme1' : 'colorScheme2']" :to="{ params: last, query: $route.query }">{{sites.last}}</router-link>

      <router-link class="button base icon colorScheme2 next" :class="{ inactive: !prevSite }" :to="{ params: prev, query: $route.query }"><icons-partial size="medium" type="arrow-left" /></router-link>
      <router-link class="button base icon colorScheme2 prev" :class="{ inactive: !nextSite }" :to="{ params: next, query: $route.query }"><icons-partial size="medium" type="arrow-right" /></router-link>

    </button-group-partial>


    <button-group-partial v-if="mode === 'inline'" no-gap :left="!center" :center="center">

      <button-partial v-if="sites.last !== 0" :class="[current === sites.first ? 'colorScheme1' : 'colorScheme2']" @confirmed="setParams(null, 1)">1</button-partial>
      <button-partial v-if="current !== sites.first && current !== sites.last">{{current}}</button-partial>
      <button-partial v-if="(sites.first !== sites.last) && sites.last !== 0" :class="[current === sites.last ? 'colorScheme1' : 'colorScheme2']" @confirmed="setParams(null, sites.last)">{{sites.last}}</button-partial>

      <button-partial color-scheme="colorScheme2" button-content-type="icon" icon="arrow-left" :class="{ inactive: !prevSite }" @confirmed="setParams('prev')" />
      <button-partial color-scheme="colorScheme2" button-content-type="icon" icon="arrow-right" :class="{ inactive: !nextSite }" @confirmed="setParams('next')" />
    
    </button-group-partial>

  </section>

</template>

<script lang="ts">
import { DataObject } from '@lilaquadrat/studio/lib/interfaces';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component
// eslint-disable-next-line global-require
export default class sitesPartial extends Vue {

  @Prop(Object) data: DataObject<unknown>;

  @Prop(String) paramKey: string;

  @Prop({ type: String, default: 'history' }) mode: 'history' | 'inline';

  @Prop(Boolean) center: boolean;

  nextSite: number = null;

  prevSite: number = null;

  next: any = {};

  prev: any = {};

  first: any = {};

  last: any = {};

  inlineSite: number = 1;

  get sites() {

    return this.data?.sites;

  }

  get current() {

    return this.data?.sites?.current;

  }

  get realKey() {

    return this.paramKey ? this.paramKey : 'site';

  }

  @Watch('sites')
  updateSites() {

    this.update();

  }

  @Watch('current')
  updateCurrent() {

    this.update();

  }

  @Watch('$route.params')
  updateRoute() {

    this.update();

  }

  mounted() {

    this.update();

  }

  setParams(direction: 'prev' | 'next' | null, site?: number | null) {

    let pushUpdate = false;

    if (direction === 'prev') {

      if (this.inlineSite > 0) {

        this.inlineSite -= 1;
        pushUpdate = true;

      }

    }

    if (direction === 'next') {

      if (this.inlineSite + 1 <= this.sites.last) {

        this.inlineSite += 1;
        pushUpdate = true;

      }

    }

    if (site && site !== this.current) {

      this.inlineSite = site;
      pushUpdate = true;

    }

    if (pushUpdate) this.$emit('update', this.inlineSite);

  }

  update() {


    const params = JSON.parse(JSON.stringify(this.$route.params));

    if (this.mode === 'history') {

      this.inlineSite = params.site || 1;

    }

    if (this.mode === 'inline') {

      this.inlineSite = this.data.sites?.current;

    }

    this.prevSite = +this.current - 1;

    if (this.prevSite < 0) this.prevSite = null;

    this.nextSite = +this.current + 1;
    if (this.nextSite > this.sites?.last) this.nextSite = null;

    this.next = { ...params };
    this.next[this.realKey] = this.nextSite;

    this.prev = { ...params };
    this.prev[this.realKey] = this.prevSite;

    this.first = { ...params };
    this.first[this.realKey] = this.sites?.first;

    this.last = { ...params };
    this.last[this.realKey] = this.sites?.last;

  }

}
</script>

<style lang="less">

.sites-container {

  .button, .base.button {
    display: grid;
    overflow: hidden;
  }

  &.inline {

    button {
      display: grid;
    }

  }

}
</style>
