import { UnifiedRouteGuard } from '@/libs/lila-auth';
import HomeScreen from './screens/home.screen.vue';
import MethodsOverviewScreen from './screens/methods-overview.screen.vue';
import MethodsSingleScreen from './screens/methods-single.screen.vue';
import MethodsHomeScreen from './screens/methods-home.screen.vue';
import SingleScreen from './screens/single.screen.vue';

export default [
  {
    path: '/:company/:project/a/publish/:site?',
    name: 'app-publish-project-home',
    beforeEnter: UnifiedRouteGuard,
    component: HomeScreen,
    meta: {
      animationGroup: 'publish',
      app: 'publish',
      parent: true,
    },
    children: [
      {
        path: 'methods',
        component: MethodsOverviewScreen,
        meta: {
          sidescreen: true,
          defaultRoute: 'app-publish-methods-home',
          app: 'publish',
        },
        children: [
          {
            path: 'add',
            component: MethodsSingleScreen,
            name: 'app-publish-methods-add',
            meta: {
              sidescreen: true,
              defaultRoute: 'app-publish-methods-home',
              app: 'publish',
            },
          },
          {
            path: ':id',
            component: MethodsSingleScreen,
            name: 'app-publish-methods-edit',
            meta: {
              sidescreen: true,
              defaultRoute: 'app-publish-methods-home',
              app: 'publish',
            },
          },
          {
            path: ':site?',
            component: MethodsHomeScreen,
            name: 'app-publish-methods-home',
            meta: {
              sidescreen: true,
              defaultRoute: 'app-publish-methods-home',
              app: 'publish',
            },
          },
        ],
      },
      {
        path: 'single/:id',
        component: SingleScreen,
        name: 'app-publish-project-single',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-publish-project-single',
          app: 'publish',
        },
      },
    ],
  },
];
