<template>
  <section class="grid-module">
    <slot />
  </section>
</template>
<script lang="ts">
import { ExtComponent, Component, Prop } from '@/libs/lila-component';

@Component
export default class GridModule extends ExtComponent {

}
</script>
<style lang="less">


.grid-module {

  .multi(padding, 8);

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 250px;
  overflow: auto;
  gap: 20px;

}
</style>
