import MainStore, { state } from '@/store/main.store';
import GenericStore from '@/interfaces/GenericStore.interface';
import StudioSDK from '@/libs/StudioSDK';
import BaseCallData from '@/interfaces/BaseCallData.interface';
import AppEditorExtensionState from '../interfaces/extension-state.interface';

const AppEditorExtensionsStore: GenericStore<AppEditorExtensionState> = {

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },

  namespaced: true,
  strict: true,

  state: {

    data: {},
    search: {
      tags: [],
      search: null,
    },

    site: null,
  },

  mutations: {

    data(appEditorState, data) {

      appEditorState.data = data;

    },

    setSearch(appEditorState, search: { tags?: string[], search?: string }) {

      if (search.tags) {

        if (typeof search.tags === 'string') {

          appEditorState.search.tags = [search.tags];

        } else {

          appEditorState.search.tags = search.tags;

        }

      } else {

        appEditorState.search.tags = [];

      }

      if (search.search) {

        appEditorState.search.search = search.search;

      } else {

        delete appEditorState.search.search;

      }

    },

    updateSearch(appEditorState, update: { data: any, type: string }) {

      console.log(update, update.data.length, !update.data.length, appEditorState.search);

      if (!update.data.length) {

        appEditorState.search[update.type] = null;
        return;

      }

      console.log(update, appEditorState.search);

      appEditorState.search[update.type] = update.data;

    },

    setSite(appEditorState, site: number) {

      appEditorState.site = site || 0;

    },

    // toggleFilter(appEditorState, active: boolean) {

    //   appEditorState.filterActive = active;

    // },

  },

  actions: {

    get(store, data: { settings: BaseCallData, query?: any, site?: any }) {

      const sdk = new StudioSDK('editor', AppEditorExtensionsStore.sdkOptions());

      return sdk.editor.list(+(data?.site || 1), data?.query?.sort, data?.query?.order, data?.query);

    },

  },

};

export default AppEditorExtensionsStore;
