<template>
  <article class="find-user extension">
    <search-partial @callback="search" callback noSelected :multiple="true" :loading="loadingIndicator" :possibleEntries="possibleEntries" @input="updateUsers" :value="userIds">{{$translate('user last name')}}</search-partial>
    <slot />
    <ul class="selected">
      <li v-for="(single, index) in value" :key="`single-user-${index}`">
        <div>{{single.lastname}}, {{single.prename}}</div>
        <button-partial type="button" class="mode up" buttonContentType="icon" icon="close" size="small" colorScheme="transparent" @confirmed="remove(index)" />
      </li>
    </ul>

  </article>
</template>
<script lang="ts">
import {
  ExtComponent, Component, Prop, Watch,
} from '@/libs/lila-component';
import { CallResponse } from '@lilaquadrat/studio/lib/interfaces';

@Component
export default class SelectMediaExtension extends ExtComponent {

  possibleEntries: Record<string, string>[] = [];

  userDataCache: Record<string, string>[] = [];

  loadingIndicator: boolean = false;

    @Prop(Array) value: Record<string, string>[];

    @Prop(Object) addData: Record<string, string>;

    @Watch('value')
    watchValueFunction(): void {

      if (this.value) this.userDataCache = [...this.value];

    }

    get userIds(): string[] {

      return this.value?.map((single) => single.id);

    }

    mounted(): void {

      if (this.value) this.userDataCache = [...this.value];

    }


    remove(removeIndex: number): void {

      console.log('remove index', removeIndex, this.value, this.value.filter((value, index) => index !== removeIndex));
      this.$emit('input', this.value.filter((value, index) => index !== removeIndex));

    }

    updateUsers(users: any[]): void {

      console.log(users, this.possibleEntries, this.value);
      this.$emit('input', users.map((single) => this.userDataCache.find((singleCache) => singleCache.id === single)));

    }

    search(search: string): void {

      this.loadingIndicator = true;

      this.$store
        .dispatch(
          'AppPartner/user',
          {
            admin: true,
            search,
            ...this.$store.getters.companyProject,
            ...this.addData,
          },
        )
        .then((data: CallResponse<any>) => {

          const entries = [];

          if (data.r) {

            this.userDataCache = [...this.userDataCache, ...data.r];

            data.r?.forEach((single) => {

              entries.push({ name: `${single.lastname}, ${single.prename}`, value: single.id });

            });

            this.possibleEntries = entries;

          }


          this.loadingIndicator = false;

        })
        .catch((e) => {

          console.error(e);

        });

    }

}
</script>
<style lang="less">


.find-user.extension {

  display: grid;
  gap: 20px;

  ul.selected li {

    display: grid;
    grid-template-columns: 1fr 40px;
    line-height: @buttonHeight;

    font-size: @fontText;

  }

}
</style>
