import { RouteConfig } from 'vue-router';
import { UnifiedRouteGuard } from '@/libs/lila-auth';

import ErrorScreen from '@/screens/error.screen.vue';
import LoginScreen from '@/screens/login.screen.vue';
import MainScreen from '@/screens/main.screen.vue';
import MeHomeScreen from '@/screens/me-home.screen.vue';
import MeSettingsScreen from '@/screens/me-settings.screen.vue';
import MeSettingsPersonalScreen from '@/screens/me-settings-personal.screen.vue';
import MeSettingsOverviewScreen from '@/screens/me-settings-overview.screen.vue';
import CompanyHomeScreen from '@/screens/company-home.screen.vue';
import ProjectCreateScreen from '@/screens/project-create.screen.vue';
import CompanyCreateScreen from '@/screens/company-create.screen.vue';
import CompanySettingsScreen from '@/screens/company-settings.screen.vue';
import ProjectHomeScreen from '@/screens/project-home.screen.vue';
import ProjectSettingsScreen from '@/screens/project-settings.screen.vue';

const routes: RouteConfig[] = [
  {
    path: '/login',
    name: 'login',
    component: LoginScreen,
  },
  {
    path: '/login/callback',
    name: 'login-callback',
    component: LoginScreen,
  },
  {
    path: '/error/:type',
    name: 'error',
    component: ErrorScreen,
  },
  {
    path: '',
    beforeEnter: UnifiedRouteGuard,
    component: MainScreen,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: '',
        name: 'me-home',
        beforeEnter: UnifiedRouteGuard,
        component: MeHomeScreen,
        meta: {
          authRequired: true,
          parent: true
        },
        children: [
          {
            path: '/me/settings',
            component: MeSettingsScreen,
            meta: {
              sidescreen: true,
              name: 'me-settings-sidescreen',
              defaultRoute: 'me-settings-overview'
            },
            children: [
              {
                path: 'personal',
                name: 'me-settings-personal',
                component: MeSettingsPersonalScreen,
                meta: {
                  sidescreen: true,
                  name: 'me-settings-personal-sidescreen'
                },
              },
              {
                path: '',
                name: 'me-settings-overview',
                component: MeSettingsOverviewScreen,
                meta: {
                  sidescreen: true,
                  name: 'me-settings-overview-sidescreen'
                },
              }
            ]
          },

        ],
      },
      {
        path: '/:company',
        name: 'company-home',
        beforeEnter: UnifiedRouteGuard,
        component: CompanyHomeScreen,
        meta: {
          parent: true
        },
        children: [
          {
            path: 'project-create',
            name: 'project-create',
            component: ProjectCreateScreen,
            meta: {
              sidescreen: true,
              name: 'project-create-sidescreen',
              defaultRoute: 'project-create'
            },
          },
          {
            path: 'company-create',
            name: 'company-create',
            component: CompanyCreateScreen,
            meta: {
              sidescreen: true,
              name: 'company-create-sidescreen',
              defaultRoute: 'company-create'
            },
          },
          {
            path: 'settings',
            name: 'app-settings-company-home',
            component: CompanySettingsScreen,
            meta: {
              sidescreen: true,
              name: 'company-settings-sidescreen',
              defaultRoute: 'company-settings'
            },
          },
        ],
      },
      {
        path: '/:company/:project',
        name: 'project-home',
        beforeEnter: UnifiedRouteGuard,
        component: ProjectHomeScreen,
        meta: {
          parent: true,
        },
        children: [
          {
            path: 'settings',
            name: 'app-settings-project-home',
            component: ProjectSettingsScreen,
            meta: {
              sidescreen: true,
              defaultRoute: 'project-settings-home'
            }
          },
        ],
      },
    ],
  },
  {
    path: '',
    name: 'app-main-internal-screen',
    component: MainScreen,
    meta: {
      authRequired: true,
    }
  },
  {
    path: '',
    name: 'app-main-screen',
    component: MainScreen,
    meta: {
      authRequired: true,
    }
  }
];

export default routes;