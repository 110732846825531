<template>
  <article :class="navigationAnimation" class="data-overview-screen">
    <div class="pagenator full">
      <header class="main">

        <section class="stats">
          <h1>{{ $translate('data') }}</h1>
          <h3>
            <template v-if="!content.sites.last">{{ content.count }}</template>
            <template v-if="content.sites.last">{{ content.sites.range[0] }} - {{ content.sites.range[1] }} ({{
              content.count }})</template>
            / {{ content.all }} {{ $translate('total') }}
          </h3>
        </section>

        <quickmenu-partial>
          <all-publish-ext type="editor" app="editor" :content-groups="['all', 'category']" />
          <router-link
:title="$translate('app-editor-new')" :aria-label="$translate('NEW_DATA')" class="icon"
            :to="{ name: 'app-editor-project-new' }"><icons-partial type="editor-add" size="large" /></router-link>
          <router-link
:title="$translate('app-editor-project-settings')"
            :aria-label="$translate('app-editor-project-settings')" class="icon"
            :to="{ name: 'app-editor-project-settings-overview' }"><icons-partial
type="settings"
              size="large" /></router-link>
          <submenu-partial :links="links" />
        </quickmenu-partial>
      </header>

      <!-- <filter-partial type="data" /> -->

      <quickfilter-partial category reload @reload="reload" />

      <section class="scroll-container">
        <table class="isSticky">
          <thead-partial
:site-current="currentSite" :search="$route.query.search" :sort="sort" :order="order"
            :content="headContent" />
        </table>

        <table class="contentUpdate" :class="contentAnimation">
          <tbody>
            <tr v-for="(single, index) in content.data" :key="`data-overview-${index}`">
              <td
class="id fullHeight action"
                :class="{ hasDescription: single.description, hasAttribute: single.layout || single.partial || single.target === 'mail' }">
                <router-link
:to="{
                  name: 'app-editor-project-edit',
                  params: {
                    id: single.id,
                    company: $store.state.Company.company,
                    project: $store.state.Project.project,
                  },
                }">
                  <div v-if="single.description" class="description" :title="single.description">{{ single.description
                    }} ( {{
                      single.id }} )</div>
                  <div>
                    <span v-if="single.partial" :title="$translate('partial')" class="attribute">
                      <span class="text-full">{{ $translate('partial') }}</span>
                      <span class="text-short">{{ $translate('P') }}</span>
                    </span>
                    <span v-if="single.layout" :title="$translate('layout')" class="attribute">
                      <span class="text-full">{{ $translate('layout') }}</span>
                      <span class="text-short">{{ $translate('L') }}</span>
                    </span>
                    <span v-if="single.target === 'mail'" :title="$translate('mail')" class="attribute">
                      <span class="text-full">{{ $translate('mail') }}</span>
                      <span class="text-short">{{ $translate('m') }}</span>
                    </span>
                    <span v-if="!single.description" class="name">{{ single.id }}</span>
                    <span v-if="single.settings && single.settings.filename" class="filenames">{{
                      single.settings.filename.join(', ') }}</span>
                  </div>
                </router-link>
              </td>

              <td class="category">
                <span :title='$categories(single.tags)'>{{ $categories(single.tags) }}</span>
              </td>

              <td class="publish">
                <span>
                  <icons-partial
v-if='!single.state || single.state === "draft"' :title="$translate('draft')"
                    color-scheme='grey' type='checked' size='small' />
                  <icons-partial
v-if='single.state === "publish"' :title="$translate('publish')" type='checked'
                    size='small' />
                </span>
              </td>

              <td class="lastUpdate fullHeight action last">
                <router-link :to="{ name: 'app-editor-project-history', params: { id: single.id } }">
                  <template v-if="single.history.updated">
                    <abbr :title="$date(single.history.updated, 'DD.MM.YYYY HH:mm')">{{ $date(single.history.updated,
                      null, true) }}</abbr>
                  </template>
                  <template v-if="!single.history.updated && single.history.created">
                    <div>{{ $translate('app-editor-created') }}</div>
                    <abbr :title="$date(single.history.created, 'DD.MM.YYYY HH:mm')">{{ $date(single.history.created,
                      'DD.MM.YYYY HH:mm') }}</abbr>
                  </template>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <modal-module />

  </article>
</template>
<script lang="ts">
import { ExtComponent, Component, Watch } from '@/libs/lila-component';
import { Store } from 'vuex';
import { Dictionary } from 'vue-router/types/router';
import log from 'loglevel';
import { DataObject, Editor } from '@lilaquadrat/studio/lib/interfaces';
import { BasicData, Content } from '@lilaquadrat/interfaces';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import MainStoreState from '@/store/mainStoreState.interface';
import { reactive } from 'vue';
import { useTraceable } from '@/plugins/traceable';

@Component
export default class AppEditorHomeScreen extends ExtComponent {

  componentName = 'app-editor-project-home';

  headContent: any[] = [];

  order: string = '-1';

  sort: string = 'id';

  traceId = '';

  constructor() {

    super();

    this.headContent.push({
      key: 'id',
      text: 'id',
      classes: 'id',
    });

    this.headContent.push({
      text: 'category',
      classes: 'category',
    });


    this.headContent.push({
      classes: 'publish',
      text: 'P',
      icon: {
        type: 'publish',
        title: this.$translate('PUBLISH_STATE'),
        colorScheme: 'colorScheme1',
        size: 'medium',
      },
    });

    this.headContent.push({
      key: 'updated',
      text: 'LAST_UPDATE',
    });

  }

  @Watch('$route')
  watchRoute() {

    this.updateSort();

  }

  updateSort() {

    if (this.$route.query.sort) this.sort = this.$route.query.sort as string;
    if (this.$route.query.order) this.order = this.$route.query.order as string;

  }

  mounted() {

    log.debug(this.$store.state.Settings.settings['data.query']);
    this.DOM.title = `${this.$store.state.Project.settings.name} DATA`;
    this.updateSort();

  }

  get links() {

    const { scope } = this.$store.state.Project.permissions;
    const links = [];

    if (scope.includes('data:create')) {

      links.push({ text: 'NEW_DATA', to: { name: 'app-editor-project-new' } });

    }

    return links;

  }

  get content(): DataObject<BasicData<Content>[]> {

    return this.$store.state.AppEditorMain.data;

  }

  get currentSite() {

    return this.$route.params.site ? +this.$route.params.site : null;

  }

  async reload() {

    StudioSDK.flushCache('editor', 'list');

    const preloadedData = await this.preloadDataPre(this.$route.params, this.$route.query, this.$store);

    this.preloadDataPost(preloadedData, this.$route.params, this.$route.query, this.$store);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store
      .dispatch('AppEditorMain/get', {
        query,
        settings: {
          project: params.project,
          company: params.company,
          site: params.site,
        },
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<Editor>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppEditorMain/data', preloadedData.data);
    return Promise.resolve();

  }

}
</script>
<style lang="less" scoped>
.data-overview-screen {

  table {

    tbody,
    thead::v-deep {

      tr {
        grid-template-columns: [start] 10fr 25px [end] 100px;

        @media @tablet, @desktop {
          grid-template-columns: [start] 10fr 120px 25px [end] 100px;
        }

        td {

          &.category {

            display: none;

            justify-content: start;

            @media @tablet, @desktop {
              display: grid;
            }

            span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

          }

          &.publish {
            align-items: center;
            justify-items: center;

            span {
              display: grid;
              height: 50px;
              cursor: help;
            }
          }

          .filenames {
            grid-column-start: 1;
            grid-column-end: 3;
          }

          .description,
          .name {
            .font-bold;
          }
        }

      }
    }

  }

}
</style>
