<template>
  <section class="editor-history editor open">
    <header>
      <h1>{{$translate('history')}}</h1>
      <h4>
        {{ content.immutable_history + 1 }} {{$translate('from')}}
        {{ history }}
      </h4>
    </header>

    <section class="editor-module-container">
      <section class="controls">
        <router-link
          class="prev button base colorScheme2"
          :class="{ inactive: !content.previous && content.previous !== 0 }"
          :to="{
            name: 'app-editor-project-edit-history-index',
            params: { id: content.immutable_parent, index: content.previous },
          }"
        >
          {{$translate('older')}}</router-link
        >
        <router-link
          class="next button base colorScheme2"
          :class="{ inactive: !content.next }"
          :to="{
            name: 'app-editor-project-edit-history-index',
            params: { id: content.immutable_parent, index: content.next },
          }"
        >{{$translate('newer')}}
        </router-link>
      </section>

      <section class="controls">
        <toggle-partial v-model="showHistoryCurrent" :on="$translate('current')" :off="$translate('history')" />
      </section>

      <section class="details-container">
        <section class="details">
          <h5>{{$translate('date')}}</h5>
          <h4>{{$date(content.immutable_date, 'DD.MM.YYYY HH:mm')}}</h4>
        </section>

        <section class="details">
          <h5>{{$translate('reason')}} - {{$translate('user')}}</h5>
          <h4>
            {{ $translate(`history_${content.immutable_reason}`) }} -
            {{ content.immutable_user }}
          </h4>
        </section>
      </section>

      <ul class="options">
        <li>
          <router-link
            class="button base colorScheme2"
            :to="{
              name: 'app-editor-project-edit',
              params: { id: parentId, history: content._id, use: 'use' },
            }"
          >
            {{$translate('start editing this history entry')}}</router-link
          >
          <div class="description-partial">
            <p>
              {{$translate('Be aware that you will overwrite all changes that are made after this version')}}
            </p>
            <p>{{$translate('This will become the current version')}}</p>
          </div>
        </li>

        <li>
          <router-link class="button base colorScheme2" :to="{ name: 'app-editor-project-new', params: { base: content._id } }">{{$translate('create a copy from this history')}}</router-link>
          <div class="description-partial">
            <p>{{$translate('This site will not be altered')}}</p>
          </div>
        </li>

        <li>
          <router-link class="button base colorScheme2" :to="{ name: 'app-editor-project-edit', params: { id: parentId } }">{{$translate('start editing the current version')}}</router-link>
        </li>
      </ul>
    </section>
  </section>
</template>

<script lang="ts">
import { Component, ModuleEditorComponent, Prop } from '@/libs/ModuleEditorComponent';


@Component
// eslint-disable-next-line global-require
export default class EditorHistoryEditor extends ModuleEditorComponent<any> {

  @Prop() content: any;

  @Prop() history: number;

  get isCurrent() {

    return this.content.immutable_history + 1 === this.history;

  }

  get parentId() {

    return this.$store.state.AppEditorData.content.id;

  }

  get showHistoryCurrent() {

    return this.$store.state.AppEditorData.showHistoryCurrent;

  }

  set showHistoryCurrent(value) {

    this.$store.commit('Editor/showHistoryCurrent', value);

  }

  pushData() {

    this.$emit('pushData', this.content);

  }

}
</script>
