<template>
  <span>{{ formatted }}</span>
</template>

<script lang="ts">
import { ExtComponent, Component, Prop } from '@/libs/lila-component';

@Component
export default class colorsPartial extends ExtComponent {

  @Prop(Number) decimals: number;

  get formatted() {

    let bytes = 0;

    if(this.$slots.default) {

        bytes = +this.$slots.default[0].text;

    }

    const decimals = this.decimals || 0;

    if (!bytes) return '0 Bytes';

    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;

  }

}
</script>
