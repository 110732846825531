<template>
  <section class="file-partial" :class="{ preview: !noPreview, noFile: !filename }">

    <a v-if="!noPreview && filename" :href="downloadUrl" download class="preview">
      <icons-partial type="file" size="xl" />
    </a>

    <div v-if="!noPreview && !filename" class="preview">
      <icons-partial type="file" size="xl" />
    </div>

    <section v-if="filename" class="info-container">
      <a class="title" :href="downloadUrl" download>{{file.filename}}</a>

      <section class="infos">
        <div>{{$date(file.date, 'DD MMM YYYY HH:MM')}} - {{user.lastname}}, {{user.prename}} - {{file.size | formatBytes}}</div>
        <slot />
      </section>

    </section>

    <section v-if="!filename" class="info-container">
      <div class="title">{{$translate('NO_FILE')}}</div>

      <section class="infos">
        <slot />
      </section>

    </section>

  </section>
</template>
<script lang="ts">
import PartialModuleEditor from '@/apps/editor/modules/partial.module.editor.vue';
import { ExtPartial, Prop } from '@/libs/lila-partial';
import Component from 'vue-class-component';

@Component
export default class filePartial extends PartialModuleEditor {

  @Prop(Object) file: {filename: string, size: number, mimetype: string, path: string};

  @Prop(Object) user: {prename: string, lastname: string};

  @Prop(Boolean) noPreview: boolean;

  @Prop(String) url: string;

  get downloadUrl() {

    return `${this.url}/${this.file.path}${this.file.filename}`;

  }

  get filename() {

    return this.file?.filename;

  }


}
</script>
<style lang="less">


.file-partial {

  display: grid;
  grid-template-rows: minmax(100px, 1fr);
  align-items: center;

  .info-container {
    .multi(padding, 4);
  }

  &.preview {

    grid-template-columns: 75px 1fr;
    align-items: center;
    gap: 20px;

    .info-container {
      .multi(padding, 4, 4, 4, 0);
    }

  }


  .info-container {

    display: grid;
    grid-template-rows: min-content;
    gap: 5px;

    .title {
      .font-head;
      font-size: @fontText;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }

  .preview {
    display: grid;

    align-items: center;
    justify-items: center;
    .multi(padding, 4, 0);

    .icon-partial {
      svg {
        width: 100%;
      }
    }
    .basicHover;
  }

  &.noFile {

    .preview {
      &:hover {
        opacity: 1;
      }
    }

  }
}


</style>
