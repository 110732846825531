<template>
  <article :class="navigationAnimation">
    <section class="content-container pagenator-sidescreen">
      <side-screen-header-partial>{{$translate('history')}}</side-screen-header-partial>

      <article class="scroll-container">
        <section class="content pagenatorHead">
          <section class="stats upload">
            <h3 v-if="$store.state.details.data.sites">
              <template v-if="!$store.state.details.data.sites.last">{{ $store.state.details.data.count }}</template>
              <template v-if="$store.state.details.data.sites.last">{{ $store.state.details.data.sites.range[0] }} - {{ $store.state.details.data.sites.range[1] }} ({{ $store.state.details.data.count }})</template> /
              {{ $store.state.details.data.all }} total
            </h3>
          </section>

          <sites-partial paramKey="childSite" :data="$store.state.details.data" />
        </section>

        <template v-if="content">
          <section>
            <table class="isSticky">
              <thead-partial :siteCurrent="currentSite" :search="$route.query.search" :sort="sort" :order="order" :content="headContent" />
            </table>

            <table class="history">
              <tbody>
                <tr v-for="(single, index) of content" :key="`history-index-${index}`">
                  <td>{{$date(single.immutable_date, 'DD.MM.YYYY HH:mm') }}</td>
                  <td>{{ single.immutable_user }}</td>
                  <td>{{$translate(`history_${single.immutable_reason}`)}}</td>
                  <td>
                    <router-link class="button base colorScheme2" :to="{ name: 'app-editor-project-edit-history-index', params: { id: single.immutable_parent, index: single.immutable_history } }">{{$translate('view')}}</router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </template>
      </article>
    </section>
  </article>
</template>

<script lang="ts">
import {
  ExtComponent, Component, Watch, Prop,
} from '@/libs/lila-component';

@Component
// eslint-disable-next-line global-require
export default class DataDetailsScreen extends ExtComponent {

  componentName = 'app-editor-project-details';

  headContent: any[] = [];

  order: string = '-1';

  sort: string = 'id';

  constructor() {

    super();

    this.headContent.push({
      key: '_id',
      text: 'date',
      classes: 'id',
    });

    this.headContent.push({
      key: 'immutable_user',
      text: 'user',
    });

    this.headContent.push({
      key: 'immutable_reason',
      text: 'reason',
    });

    this.headContent.push({});

  }

  @Watch('$route')
  watchRoute() {

    this.updateSort();

  }

  updateSort() {

    if (this.$route.query.sort) this.sort = this.$route.query.sort as string;
    if (this.$route.query.order) this.order = this.$route.query.order as string;

  }

  mounted() {

    this.updateSort();

  }

  get content() {

    return this.$store.state.details.data.data;

  }

  get currentSite() {

    return this.$route.params.site ? +this.$route.params.site : null;

  }

  // eslint-disable-next-line class-methods-use-this
  asyncData(params: any, query: any, store?: any): Promise<any> {

    return store.dispatch(
      'getDetails',
      {
        company: store.state.Company.company,
        project: store.state.Project.project,
        id: params.id,
        site: params.childSite,
        sort: query.sort ?? '_id',
        order: query.order ?? '-1',
      },
      store,
    );

  }

}
</script>
