// import PartialModel from '@/libs/lila-model';
// import ChildDataModel from '@/models/ChildData.model';
// import GenericDataModel from '@/models/GenericData.model';
// import AppEditorDataSettingsModel from './data-settings.model';
// import AppEditorDataPdfModel from './data-pdf.model';
// import ModulesFactory from '../functions/ModulesFactory';
// import ModulesModelFactory from '../functions/ModulesModelFactory';

// export default class AppEditorDataModel extends PartialModel {

//   settings = AppEditorDataSettingsModel;

//   pdf = AppEditorDataPdfModel;

//   tags: string[] = [];

//   id: string = '';

//   _id: string = '';

//   state: string = '';

//   description: string = '';

//   language: string = '';

//   partial: boolean = false;

//   layout: boolean = false;

//   modules = [];

//   childData = ChildDataModel;

//   genericData = GenericDataModel;

// }

import Models, { ModelDeclaration } from '@/libs/Models.class';
import ChildData from '@/models/ChildData.model';
import GenericData from '@/models/GenericData.model';
import AppEditorDataPdf from './DataPDF.model';
import EditorDataHtmlSettings from './DataHtmlSettings.model';

export default interface EditorData {
  id: string
  _id: string
  state: string
  description: string
  language: string
  modules: string[]
  tags: string[]
  partial: boolean
  layout: boolean
  target: string
  childData: ChildData
  genericData: GenericData
  settings: EditorDataHtmlSettings
  pdf: AppEditorDataPdf
}

const declaration: ModelDeclaration<EditorData> = {
  id: { type: 'string' },
  _id: { type: 'string' },
  state: { type: 'string', default: 'draft' },
  description: { type: 'string' },
  language: { type: 'string' },
  modules: { type: 'array', contains: { type: 'object' } },
  tags: { type: 'array', contains: { type: 'string' } },
  settings: { model: 'editor-data-html-settings' },
  pdf: { model: 'editor-data-pdf' },
  childData: { model: 'child-data' },
  genericData: { model: 'generic-data' },
  partial: { type: 'boolean', default: false },
  layout: { type: 'boolean', default: false },
  target: { type: 'string', default: 'browser' },
};


Models.register('editor-data', declaration);