import Models, { ModelDeclarationExtended, ModelOptions } from '@/libs/Models.class';
import FactsElement from '@/models/FactsElement.model';
import Module from '@/models/Module.model';
import Textblock from '@/models/Textblock.model';

export default interface FactsModule extends Module {
  textblock: Textblock
  elements: FactsElement[]
}

const declaration: ModelDeclarationExtended<FactsModule, Module> = {
  textblock: { model: 'textblock' },
  elements: { type: 'array', contains: { model: 'facts-element' } },
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('facts-module', declaration, undefined, options);