<template>
  <article class="video-editor">
    <mediaSelect-partial v-model="value" />

    <moduleList-partial v-if="!child" class="module-editor-navigation">
      <li><button-partial :variant="['moduleNavigation']" @confirmed="setChild('src')">src</button-partial></li>
      <!-- <li><button-partial :variant="['moduleNavigation']" @confirmed="setChild('srcset')">srcset</button-partial></li> -->
      <li><button-partial :variant="['moduleNavigation']" @confirmed="setChild('attributes')">attributes</button-partial></li>
      <li><button-partial :variant="['moduleNavigation']" @confirmed="setChild('poster')">poster</button-partial></li>
    </moduleList-partial>

    <section v-if="child === 'src'" class="editor-content-container">
      <input-partial v-model="value.src" placeholder="source url">src</input-partial>
      <input-partial v-model="value.alt" placeholder="alternative text">alt</input-partial>
    </section>

    <!-- <section v-if="child === 'srcset'" class="editor-content-container">
      <input-partial v-model="value.source1.media" placeholder="media query">media query 1</input-partial>
      <input-partial v-model="value.source1.source" placeholder="source url">srcset 1</input-partial>

      <input-partial v-model="value.source2.media" placeholder="media query">media query 2</input-partial>
      <input-partial v-model="value.source2.source" placeholder="source url">srcset 2</input-partial>

      <input-partial v-model="value.source3.media" placeholder="media query">media query 3</input-partial>
      <input-partial v-model="value.source3.source" placeholder="source url">srcset 3</input-partial>
    </section> -->

    <list-partial-editor v-if="child === 'attributes'" v-model="value.attributes" no-head />

    <section v-if="child === 'poster'" class="editor-content-container">
      <input-partial v-model="value.poster" placeholder="poster url">poster</input-partial>
    </section>
  </article>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import Video from '@/apps/editor/interfaces/video.interface';

@Component
// eslint-disable-next-line global-require
export default class VideoEditor extends PartialEditorComponent<Video> {}


</script>

<style lang="less" scoped>

video {
  display: grid;
  align-self: center;
  overflow: hidden;
  width: 100%;
}
</style>
