<template>
  <section class="submenu" :class="{ closed: !open }">
    <button-partial v-if="open" class="trigger" color-scheme="transparent" button-content-type="icon" icon="close" size="large" @click="toggle()" />
    <button-partial v-if="!open" class="trigger" color-scheme="transparent" button-content-type="icon" icon="menu" size="large" @click="toggle()" />
    <section v-if="open" class="content-container">
      <section class="content">
        <router-link v-for="(link, i) in links" :key="`submenu-${i}`" class="dynamic" :to="link.to" @click.native="toggle()">
          {{$translate(link.text)}}
        </router-link>

        <button-partial v-if="$route.path !== myHome" variant="submenu" @confirmed="home">{{$translate('SET_AS_HOME')}}</button-partial>
        <button-partial v-if="$route.path === myHome" variant="submenu">{{$translate('THIS_IS_YOUR_HOME')}}</button-partial>
      </section>
    </section>

  </section>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from '@/libs/lila-component';
import { ExtPartial } from '@/libs/lila-partial';

@Component
// eslint-disable-next-line global-require
export default class checkboxPartial extends ExtPartial {

  open: boolean = false;

  @Prop(Array) links: {text: string, to: string}[];

  toggle() {

    this.open = !this.open;

  }

  home() {

    this.$store.dispatch('Settings/update', { home: this.$route.path });

  }

  get myHome() {

    return this.$store.state.Settings.settings.home;

  }

}
</script>
<style lang="less" scoped>


.submenu {
  position: relative;

  height: @buttonHeight;
  width: @buttonHeight;

  .content-container {
    .index(5);
  }

  button {
    display: grid;
    align-items: center;
    justify-items: center;

    height: @buttonHeight;
    width: @buttonHeight;

  }

  .content-container {

    .content {
      text-align: left;
      display: grid;
      grid-template-columns: max-content;

      button {

        width: 100%;

        letter-spacing: 0.2px;
        line-height: 30px;

        padding: 0px 20px;

        font-size: @fontTextSmaller;
        display: block;
        text-transform: uppercase;

        color: @white;

        border-top: solid 1px @white;
        border-bottom: solid 1px @white;

        background-color: @color3;

        text-align: left;
      }

      a, button {
        min-width: 150px;

        .trans(background);

        // &:hover {
        //   opacity: 1;
        //   background-color: @color1;
        // }

      }

    }


  }

  .content {
    box-shadow: 0 0 10px @grey2;
    border: solid 1px @grey1;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transition-delay: 0.5s;

        a {
      .font-normal;
      .lineHeight(@fontText, 2);
      .multi(padding, 0, 4);
      .basicHover();

      font-size       : @fontTextSmaller;

      display         : block;

      text-transform  : uppercase;

      color           : @white;
      border-top      : solid 1px @white;
      border-bottom   : solid 1px @white;
      background-color: @color3;
    }
  }
  .content-container {
    position: absolute;
    top: 35px;
    right: 0;

    width: 150px;

    text-align: left;
  }
}
</style>
