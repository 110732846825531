import Models, { ModelDeclaration } from '@/libs/Models.class';
import PicturePartial from './PicturePartial.model';

export default interface MenuItemElement {
  title: string
  description: string
  price: string
  properties: string[]
  allergens: string[]
  picture: PicturePartial
}

const declaration: ModelDeclaration<MenuItemElement> = {
  title: { type: 'string' },
  description: { type: 'string' },
  price: {  type: 'string' },
  properties: { type: 'array', contains: { type: 'string' } },
  allergens: { type: 'array', contains: { type: 'string' } },
  picture: { model: 'picture-partial' },
};

Models.register('menu-item-element', declaration);
