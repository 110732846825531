<template>
  <section class="mediaSelect">
    <h2 v-if="media">{{$translate(media)}}</h2>
    <section class="button-position-container">

      <template v-if="value">
      
        <button-partial class="mediaSelectButton" :class="{ colorScheme1: !value.file.thumb, selected: value.file.thumb && value.type }" @confirmed="toggleMedia">
          <picture-internal-partial v-if="type === 'picture' && value.file.thumb" :key="`picure-select-${value.file.thumb}`" :class="{ notSelected: !value.file.thumb }" :picture="{ src: value.file.thumb }" />
          <template v-if="!value.file.thumb && value.type !== 'video'">
            {{$translate('MEDIA_SELECT')}}
          </template>
          <template v-if="!value.file.thumb && value.type === 'video'">{{$translate('NO_VIDEO_PREVIEW')}}</template>
        </button-partial>
        <span v-if="required" class="required">{{$translate('required')}}</span>
  
        <button v-if="value.file.src" type="button" class="base colorScheme2 remove" @click="remove">{{$translate('REMOVE_MEDIA')}}</button>

      </template>

      <template v-if="!value">
        <button-partial class="mediaSelectButton" @confirmed="toggleMedia">
          {{$translate('MEDIA_SELECT')}}
        </button-partial>

        <input-partial v-model="src" :required="required" placeholder="MEDIA_URL" description="url if the picture is not hosted in media">{{$translate('MEDIA_URL')}}</input-partial>
      </template>


    </section>

    <template v-if="value">
      <input-partial v-if="showSrc" v-model="value.src" :required="required" placeholder="MEDIA_URL" description="url if the picture is not hosted in media">{{$translate('MEDIA_URL')}}</input-partial>
    </template>

  </section>
</template>

<script lang="ts">
import { MediaModel } from '@/interfaces/mediaModel.interface';
import { Component, Prop } from '@/libs/lila-component';
import { ExtPartial, Watch } from '@/libs/lila-partial';

@Component
export default class MediaSelectPartial extends ExtPartial {

  @Prop(Object) value;

  @Prop(String) src: string;

  @Prop(String) media: string;

  @Prop(Boolean) required: boolean;

  @Prop({ type: String, default: 'picture' }) type: string;

  @Watch('value.src')
  watchValue() { 

    if(this.value.file.id) return;
    if(!this.value.src) this.value.type = '';

    this.value.type = this.detectType(this.value.src);

  }

  // eslint-disable-next-line class-methods-use-this
  detectType(url: string) {

    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg', 'tiff'];
    const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv', 'webm', 'm4v'];
    // Extract the file extension from the URL
    const extension = url.split('.').pop().toLowerCase();

    // Check if the extension matches any image extensions
    if (imageExtensions.includes(extension)) {

        return 'image';
    
    }

    // Check if the extension matches any video extensions
    if (videoExtensions.includes(extension)) {

        return 'video';
        
    }

    return '';

  }

  toggleMedia() {

    this.$store.commit('AppMedia/showMediaSelect', true);
    this.$store.commit('AppMedia/setSelectModel', this.fetchData);

  }

  fetchData(file: MediaModel) {

    if(this.value) {

      this.value.src = file.view.src;
  
      this.value.file.id = file._id;
      this.value.file.filename = file.filename;
      this.value.file.src = file.view.src;
  
      if (file.video) {
  
        this.value.poster = '';
        this.value.attributes = [];
        this.value.type = 'video';
        this.value.file.thumb = '';
        
      } else if(file.image) {

        delete this.value.poster;
        delete this.value.attributes;
        
        this.value.type = 'image';
        this.value.file.thumb = file.view.thumb;
        
      } else {

        delete this.value.poster;
        delete this.value.attributes;

        this.value.type = 'file';
        this.value.file.thumb = '';
        
      }


    } else {

      this.$emit('input', file.view.src);
    
    }


  }

  remove() {

    this.value.src = '';
    this.value.type = '';

    this.value.file.id = '';
    this.value.file.filename = '';
    this.value.file.src = '';
    this.value.file.thumb = '';

    delete this.value.poster;
    delete this.value.attributes;

  }

  refresh() {

    const newBase = `${this.$store.state.settings.cdn}/${this.$store.state.Company.company}/${this.$store.state.Project.project}`;

    this.value.src = `${newBase}/${this.value.file.filename}`;
    this.value.file.src = `${newBase}/${this.value.file.filename}`;
    this.value.file.thumb = `${newBase}/250_${this.value.file.filename}`;

  }

  get showSrc() {

    return this.value.file?.src?.length === 0;

  }

}
</script>
<style lang="less">

.mediaSelect {
  .multi(padding, 0, 4);
  display: grid;
  gap: 10px;

  .button-position-container {
    position: relative;

    .mediaSelectButton {
      display: grid;
      width: 100%;
      height: 100px;
      min-height: 100px;
      padding: 0;
      line-height: 100px;

      &.selected {
        height: auto;
        line-height: 15px;
      }

    }

    .required {
      .font-normal;
      .multi(padding, 1, 0, 0, 0);
      display: grid;
      align-self: end;

      color: @textColor;

      font-size: @fontTextSmaller;

      text-align: right;
      text-transform: uppercase;
    }

    .remove {
      position: absolute;
      top: 0;
      right: 0;
    }

    a {
      display: grid;

      h3.notice {
        position: absolute;

        align-self: center;
        justify-self: center;
        color: @white;
        text-transform: uppercase;
        .font-head;
      }
    }
  }

  picture {
    min-height: 100px;
    text-align: center;

    &.notSelected {
      position: relative;
      display: grid;
      background-color: @color1;
    }

    img {
      max-width: 100%;
    }
  }
}
</style>
