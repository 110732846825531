<template>
  <section class="contact-list-editor">
    <section class="editor-content-container" v-if="!child">

      SELECT LIST
      <search-partial-editor v-if="!child" inline mode="list" placeholder="app-editor-training-search" v-model="value" />

    </section>
  </section>
</template>

<script lang="ts">
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import { ExtComponent, Component, Prop } from '@/libs/lila-component';
import { ChildData } from '@lilaquadrat/studio/lib/interfaces';

@Component
// eslint-disable-next-line global-require
export default class ContactListEditor extends PartialEditorComponent<ChildData> {

}

</script>
