import Models, { ModelDeclaration } from '@/libs/Models.class';
import DomainSecret from './DomainSecret.model';

export default interface Domain {
  domain: string
  project: string
  company: string
  isMain: boolean
  isDefault: boolean
  active: boolean
  validated: boolean
  secret: DomainSecret
}

const declaration: ModelDeclaration<Domain> = {
  domain: { type: 'string' },
  project: { type: 'string' },
  company: { type: 'string' },
  isMain: { type: 'boolean', default: false },
  isDefault: { type: 'boolean', default: false },
  active: { type: 'boolean', default: false },
  validated: { type: 'boolean', default: false },
  secret: { model: 'domain-secret' },
};

Models.register('domain', declaration);