<template>
  <section class="media-view-partial">
    <slot />
  </section>
</template>
<script lang="ts">
import { ExtComponent } from '@/libs/lila-component';
import Component from 'vue-class-component';

@Component
export default class mediaViewPartial extends ExtComponent {

  mounted() {

    this.attachScrollCheck([this.$el as HTMLElement]);

  }

}
</script>
<style lang="less">

.media-view-partial {
  display: flex;
  flex-wrap: wrap;

  overflow: hidden;

  .basePadding;
  .multi(padding-top, 4);
  .multi(padding-bottom, 4);

  &:after {
    content: '';
    flex-grow: 1e4;
    min-width: 20%;
  }

  .item {
    position: relative;

    display: grid;
    overflow: hidden;
    margin: 2px;
    background-color: @background;
    cursor: pointer;

    transition: opacity .5s @aType;

    h3 {

      position: absolute;
      align-self: center;
      justify-self: center;
      color: @white;
      text-transform: uppercase;
      visibility: hidden;
      mix-blend-mode: soft-light;

    }

    &::after {
      content: '';
      flex-grow: 999999999;
    }

    &:hover {

      video,
      .placeholder {
        opacity: .5;
      }

      h3 {
        visibility: visible;
      }
    }

    &.folder {
      display: grid;

      .icon-partial {
        position: absolute;
        align-self: center;
        justify-self: center;

        width: 40px;
        height: 40px;

        svg {
          display: block;
          fill: @white;
        }

        .index(1);
      }
    }

    i {
      display: block;
    }

    .infos {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 100%;

      span {
        display: inline-block;
        background-color: @color1;
        color: @white;

        .multi(padding, 1, 2);
      }
    }

    .selectButton {
      position: absolute;
      top: 5px;
      left: 5px;
    }

    &:hover {
      opacity: .8;
    }

    &.image {

      background-color: @white;
      opacity: 1;

      transition: background @aTime @aType;

      &:hover {
        background-color: rgba(63, 45, 86, .8);
      }
    }

    picture {
      display: grid;
      transition: opacity .5s @aType;
      transition-delay: .5s;
      mix-blend-mode: multiply;

      img {
        position: absolute;
        align-self: end;
        width: 100%;
      }

      &.svg {

        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          min-width: 40px;

          max-width: 50%;
          margin: auto;
        }
      }

      &.notLoaded {
        opacity: 0;
      }
    }

    video,
    .placeholder {
      position: absolute;
      top: 0;
      vertical-align: bottom;
      object-fit: cover;
      overflow: hidden;
      width: 100%;

      height: 100%;
    }
  }
}
</style>
