<template>
  <section class="app-editor-renderer-module" :class="[state, contentState]">
    <section :class="{ visible: state === 'init' || contentState === 'loading' }" class="indicator">
      <template v-if="state === 'init'">{{$translate('app-editor-init')}}</template>
      <template v-if="state === 'inited' && contentState === 'loading'">{{$translate('app-editor-content-loading')}}</template>
      <indicator-partial small />
    </section>
    <iframe ref="frame" title="preview" :class="[viewport]" :src="iframeUrl" />
  </section>
</template>

<script lang="ts">
import { prepareContent } from '@lilaquadrat/studio/lib/frontend';
import { Editor } from '@lilaquadrat/studio/lib/interfaces';
import {
  Component, ExtPartial, Prop, Watch,
} from '@/libs/lila-partial';
import ModelsClass from '@/libs/Models.class';

@Component
export default class AppEditorRenderModule extends ExtPartial {

  @Prop() content: Editor;

  @Prop(String) contentState: 'error' | 'ready' | 'loading';

  state: 'init' | 'inited' = 'init';

  @Watch('modules')
  updateWatcher() {

    const target = 'main';

    this.updateFrame(target, 'content', this.modules);
    this.updateFrame(target, 'editor-settings', ModelsClass.add(this.$store.state.AppEditorData.history, 'editor-data-html-settings'));

  }

  get iframeUrl() {

    return this.$store.state.AppEditorMain.settings?.editorUrl || `${this.$store.state.settings.cdn}/${this.$store.state.Company.company}/${this.$store.state.Project.project}/editor/index.html`;

  }

  get viewport() {

    return this.$store.state.AppEditorData.viewport;

  }

  get modules() {

    const preparedContent = prepareContent(this.$store.state.AppHistory.single);

    return [...preparedContent.top, ...preparedContent.content, ...preparedContent.bottom];

  }


  mounted() {

    /**
     * wait for the ready message from the iframe
     * send the inital data
     */
    window.addEventListener(
      'message',
      (message: any) => {

        const target = 'main';


        if (message.data === 'studio-design-settings') {

          this.updateFrame(target, 'settings', this.$store.state.AppEditorMain.settings);

        }

        if (message.data === 'studio-design-ready') {

          this.state = 'inited';

          this.updateFrame(target, 'content', this.modules);
          this.updateFrame(target, 'editor-settings', ModelsClass.add(this.$store.state.AppEditorData.history, 'editor-data-html-settings'));

        }

      },

      false,
    );

  }

  updateFrame(target: string, type: string, data: any) {

    const frame = this.$refs.frame as HTMLIFrameElement;

    console.log('UPDATE', target, type, data, this.$refs, frame);

    if (frame) {

      frame?.contentWindow.postMessage({ type: `studio-${type}`, data }, '*');

    }


  }

}
</script>
<style lang="less">


.app-editor-renderer-module {

  position: relative;
  display: grid;
  width: 100%;
  height: 100%;

  .indicator {
    position: absolute;
    display: grid;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    width: 100%;
    height: 100%;
    background-color: @grey1;
    color: @color1;
    font-size: @headline_M;
    text-transform: uppercase;
    opacity: 0;

    pointer-events: none;

    .font-head;

    .trans(opacity);

    &.visible {
      opacity: 1;
    }
  }

  iframe {

    display: block;
    align-self: center;
    width: 100%;
    height: 100%;

    margin: auto;
    border: 0;

    transition: width .3s ease, height .3s ease;

    &.mobile {
      width: 360px;
      height: 640px;
    }

    &.tablet {
      width: 768px;
      height: 1024px;
    }

    &.desktop {
      width: 1280px;
      height: 768px;
    }

    &.hd {
      width: 1920px;
      height: 1280px;
    }

    &.mobile,
    &.tablet,
    &.desktop,
    &.hd {
      border: solid 2px @grey;
      box-shadow: 0 0 5px @grey;
    }
  }
}
</style>
