import MainStore, { state } from '@/store/main.store';
import GenericStore from '@/interfaces/GenericStore.interface';
import StudioSDK from '@/libs/StudioSDK';
import { ListOfModels, ObjectIdString, Structure } from '@lilaquadrat/interfaces';
import StructureExtensionState from './StructuresExtension-state.interface';

const StructuresStore: GenericStore<StructureExtensionState> = {

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },

  namespaced: true,
  strict: true,

  state: {

    single: null,

    data: {},
    search: {
      tags: [],
      filterState: null,
      search: null,
      type: null,
      sort: '_id',
      order: '1',
    },

    site: null,
  },

  mutations: {

    data(structuresState, data: ListOfModels<Structure>) {

      structuresState.data = data;

    },

    single(structuresState, data: Structure) {

      structuresState.single = data;

    },

    setSearch(structuresState, search: { tags?: string[], search?: string }) {

      if (search.tags) {

        if (typeof search.tags === 'string') {

          structuresState.search.tags = [search.tags];

        } else {

          structuresState.search.tags = search.tags;

        }

      } else {

        structuresState.search.tags = [];

      }

      if (search.search) {

        structuresState.search.search = search.search;

      } else {

        delete structuresState.search.search;

      }

    },

    updateSearch(structuresState, update: { data: any, type: string }) {

      console.log(update, update.data.length, !update.data.length, structuresState.search);

      if (!update.data.length) {

        structuresState.search[update.type] = null;
        return;

      }

      console.log(update, structuresState.search);

      structuresState.search[update.type] = update.data;
    
    },

    setSite(structuresState, site: number) {

      structuresState.site = site || 0;

    },

    // toggleFilter(appEditorState, active: boolean) {

    //   appEditorState.filterActive = active;

    // },

  },

  actions: {

    get(store, data: { query?: {search?: string, sort?: string, order?: number, models: string[]}, site?: number }) {

      const sdk = new StudioSDK('structures', StructuresStore.sdkOptions());

      return sdk.structures.list(+(data?.site || 1), {search: data?.query?.search, sort: data?.query?.sort, order: data?.query?.order, models: data.query.models});

    },

    single(store, internalId: ObjectIdString) {

      const sdk = new StudioSDK('structures', StructuresStore.sdkOptions());

      return sdk.structures.getByInternalId(internalId);

    },

    add(store, data: Structure) {

      const sdk = new StudioSDK('structures', StructuresStore.sdkOptions());

      return sdk.structures.add(data);

    },

    update(store, data: {internalId: ObjectIdString, data: Structure}) {

      const sdk = new StudioSDK('structures', StructuresStore.sdkOptions());

      return sdk.structures.update(data.internalId, data.data);

    },

    remove(store, internalId: ObjectIdString) {

      const sdk = new StudioSDK('structures', StructuresStore.sdkOptions());

      return sdk.structures.remove(internalId);

    },
    

  },

};

export default StructuresStore;
