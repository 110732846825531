<template>
  <article class="app-publish-single-screen" :class="navigationAnimation">

    <data-grid-partial class="content">

      <section class="data-grid-container">
        <h3>{{$translate('app-customers-general')}} <router-link :to="{ name: 'app-customers-company-edit', params: { id: $route.params.id } }">{{$translate('edit')}}</router-link></h3>

        <section class="data-grid-single">
          <h4>{{$translate('app-customers-id')}}</h4>
          <p class="id">{{content.id}}</p>
        </section>

        <section class="data-grid-single">
          <h4>{{$translate('app-customers-type')}}</h4>
          <p>{{$translate(`app-customers-type-${content.type}`)}}</p>
        </section>

        <section class="data-grid-single">
          <h4>{{$translate('app-customers-categories')}}</h4>
          <p><tags-list-partial categories-only show-all :value="content.tags" /></p>
        </section>
      </section>

      <section v-if="content.type === 'company'" class="data-grid-container">
        <h3>{{$translate('app-customers-companyData')}} <router-link :to="{ name: 'app-customers-company-edit', params: { id: $route.params.id } }">{{$translate('edit')}}</router-link></h3>
        <section class="data-grid-single">
          <h4>{{$translate('app-customers-legalRepresentative')}}</h4>
          <p>{{$translate(content.legalRepresentative)}}</p>
        </section>
        <section class="data-grid-single">
          <h4>{{$translate('app-customers-register')}}</h4>
          <p>{{$translate(content.register)}}</p>
        </section>
        <section class="data-grid-single">
          <h4>{{$translate('app-customers-vat')}}</h4>
          <p>{{$translate(content.vat)}}</p>
        </section>
        <section class="data-grid-single">
          <h4>{{$translate('app-customers-internet')}}</h4>
          <p>{{$translate(content.internet)}}</p>
        </section>
      </section>

      <section v-if="content.type === 'person'" class="data-grid-container">
        <h3>{{$translate('app-customers-personal')}} <router-link :to="{ name: 'app-customers-company-edit', params: { id: $route.params.id } }">{{$translate('edit')}}</router-link></h3>
        <!-- <section class="data-grid-single">
          <h4>{{$translate('app-customers-birthday')}}</h4>
          <p>{{$translate(content.birthday)}}</p>
        </section> -->
        <section class="data-grid-single">
          <h4>{{$translate('app-customers-gender')}}</h4>
          <p v-if="content.gender">{{$translate(`gender-${content.gender}`)}}</p>
        </section>
      </section>

      <section class="data-grid-container">
        <h3>{{$translate('app-customers-contact')}} <router-link :to="{ name: 'app-customers-company-edit', params: { id: $route.params.id } }">{{$translate('edit')}}</router-link></h3>
        <section class="data-grid-single">
          <h4>{{$translate('app-customers-email')}}</h4>
          <p>{{$translate(content.email)}}</p>
          <p>({{ $translate(content.emailConfirmed ? 'app-customers-email-confirmed' : 'app-customers-email-unconfirmed') }}) </p>
        </section>
        <section class="data-grid-single">
          <h4>{{$translate('app-customers-phone')}}</h4>
          <p>{{$translate(content.phone)}}</p>
        </section>
        <section class="data-grid-single">
          <h4>{{$translate('app-customers-address')}}</h4>
          <p>{{$translate(content.street)}} {{$translate(content.streetNumber)}}</p>
          <p>{{content.zipcode}} {{content.city}}</p>
          <p v-if="content.addressAddition">{{content.addressAddition}}</p>
          <p v-if="content.country">{{$translate(`country-${content.country}`)}}</p>
        </section>
      </section>

      <section class="data-grid-container">
        <h3>{{$translate('app-customers-structures')}}</h3>
        <section class="data-grid-single" v-for="(structure, index) in structures" :key="`structure-${index}`">
          <h4>{{$translate(structure.question)}}</h4>
          <p>{{ structure.outputValue }}</p>
        </section>
      </section>

      <section class="data-grid-container">
        <section class="data-grid-single full">
          <h4>{{$translate('app-customers-note')}}</h4>
          <p>{{content.note}}</p>
        </section>
      </section>
    </data-grid-partial>

  </article>
</template>

<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import { SDKResponse } from '@/libs/StudioSDK';
import { Customers } from '@lilaquadrat/interfaces';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';
import mapStructuresWithData from '@/mixins/mapStructuresWithData';

@Component
export default class AppEditorSettingsScreen extends ExtComponent {

  componentName = 'app-customers-company-single';

  cancelState = '';

  get structures() {

    return mapStructuresWithData(this.content);

  }


  get content(): Customers {

    return this.$store.state.AppCustomers.single;

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store
      .dispatch('AppCustomers/single', {
        _id: params.id,
        settings: {
          project: params.project,
          company: params.company,
        },
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<Customers>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppCustomers/single', preloadedData.data);
    return Promise.resolve();

  }


}
</script>
<style lang="less">

.app-publish-single-screen {

  .id {
    text-transform: uppercase;
  }
}
</style>


