<script setup lang="ts">
import { App } from '@lilaquadrat/studio/lib/interfaces';
import hardCopy from '@/mixins/hardCopy';
import { useStore } from '@/store/main.store';
import { watch, getCurrentInstance, onMounted, ref, computed, } from 'vue';
import { useRoute } from 'vue-router/composables';

const vm = getCurrentInstance().proxy;
const store = useStore();
const route = useRoute();
const apps = ref<(Partial<App> & { translatedName?: string })[]>([]);
const props = defineProps<{
    project?: boolean
    editMode?: boolean
    company?: string,
}>();
const $translate = ((text: string, values?: (string | number)[]) => vm.$translate(text, values));
/**
* get installable apps, translates and sorts them
*/
const baseAppsCompany: Partial<App & { to?: { name: string }, checkRights?: string }>[] = [
    { id: 'appstore', name: 'appstore', checkRights: 'app' },
    { id: 'settings', name: 'settings' },
    { id: 'team', name: 'team', checkRights: 'permissions' },

];
const baseAppsProject: Partial<App & { to?: { name: string }, checkRights?: string }>[] = [
    { id: 'appstore', name: 'appstore', checkRights: 'app' },
    { id: 'settings', name: 'settings' },
    { id: 'team', name: 'team', checkRights: 'permissions' },
];
const companySettings = computed(() => store.state.Settings.settings?.companySettings);
const createApps = () => {

    const permissions = props.project 
    ? store.state.Project.permissions.scope
    : store.state.Company.permissions.scope;
    const appsBase = props.project
        ? store.state.Project.settings?.apps
        : store.state.Company.settings?.apps;
    const baseApps = props.project
        ? baseAppsProject
        : baseAppsCompany; 
    const appStoreBase = props.project
        ? store.state.Appstore.project
        : store.state.Appstore.company;
    const installable: Partial<App & {checkRights?: string}>[] = appStoreBase.installable?.filter((single: Partial<App>) => appsBase?.includes(single.id));
    let hidden = [];
    let favorites = []; 

    if (props.company && companySettings.value) {

        const company = companySettings.value[props.company];


        if(company?.hidden) {

            hidden = company.hidden[props.company] || [];

        }

        if (company?.favorites) {

            favorites = company?.favorites[props.company] || [];

        }

    }

    installable.push(...baseApps);

    const translatedApps: (Partial<App> & { translatedName?: string, isHidden: boolean, isFavorite: boolean })[] = [];
    const useInstallable = hardCopy(installable);

    useInstallable?.forEach((single) => {
        
        let useRights: string; 
        const isHidden = !!hidden?.find((singleHidden) => (singleHidden.name === single.name) && singleHidden.mode === 'app');
        const isFavorite = !!favorites?.find((singleHidden) => (singleHidden.name === single.name) && singleHidden.mode === 'app');
        
        if(single.checkRights) {

            useRights = `${single.checkRights}:read`;
            
        } else if(single.id.endsWith('admin')) {
            
            useRights = `${single.id.replace('admin', '')}:admin`;
            
        } else {

            useRights = `${single.id}:read`;

        }

        const hasRights = permissions.includes(useRights);

        if (isHidden && !props.editMode) return;
        if(!hasRights) return;

        const appTranslated: Partial<App> & { translatedName?: string, isHidden: boolean, isFavorite: boolean } = { ...single, translatedName: vm.$translate(`app-${single.name}`), isHidden, isFavorite };

        translatedApps.push(appTranslated);

    });


    translatedApps?.sort((a, b) => a.translatedName.localeCompare(b.translatedName));

    apps.value = translatedApps;

};
const appLink = (app: Partial<App & { to?: { name: string } }>) => {

    const link = props.project
        ? `app-${app.id}-project-home`
        : `app-${app.id}-company-home`;


    return { ...app, to: { name: link, params: route.params } };

};

watch(() => props.editMode, () => createApps());
watch(companySettings, () => createApps());
onMounted(() => createApps());

</script>
<template>
    <app-drawer-module v-if="apps.length" padding>

        <app-group-module>
            <template #title><apps-group-title-partial
                    :editMode="props.editMode && !props.company">{{ $translate('apps') }}</apps-group-title-partial></template>
            <template v-for="(app, index) in apps">
                <app-link-partial v-if="!props.editMode" :key="app.id" v-bind="appLink(app)" />
                <project-link-edit-partial
v-if="props.editMode" mode="app" v-bind="app" :key="`app-link-edit-${index}`"
                    @toggle="$emit('toggle', $event)" />
            </template>
        </app-group-module>

    </app-drawer-module>
</template>