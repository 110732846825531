<template>
  <section class="open editormain-editor editor">
    <editor-pre-header-partial>{{$translate('settings')}}</editor-pre-header-partial>
    <crumbs-partial @clearCrumbs="unsetCurrentChild" />

    <section v-if="safeContent.settings" class="editor-module-container">

      <moduleList-partial v-if="!child" description class="module-editor-navigation">
        <li>
          <button-partial class="hasPreview" :variant="['moduleNavigationVariant']" @confirmed="setChild('app-editor-base-settings')">
            {{$translate('app-editor-base-settings')}}
            <span class="preview">{{$translate('app-editor-base-settings-description')}}</span>
          </button-partial>
        </li>
        <li>
          <button-partial class="hasPreview" :variant="['moduleNavigationVariant']" @confirmed="setChild('app-editor-html-settings')">
            {{$translate('app-editor-html-settings')}}
            <span class="preview">{{$translate('app-editor-html-settings-description')}}</span>
            <span class="preview">Beschreibung</span>
          </button-partial>
        </li>
        <li>
          <button-partial class="hasPreview" :variant="['moduleNavigationVariant']" @confirmed="setChild('app-editor-pdf-print-settings')">
            {{$translate('app-editor-pdf-print-settings')}}
            <span class="preview">{{$translate('app-editor-pdf-print-settings-description')}}</span>
          </button-partial>
        </li>
        <li>
          <button-partial class="hasPreview" :variant="['moduleNavigationVariant']" @confirmed="setChild('app-editor-design-settings')">
            {{$translate('app-editor-design-settings')}}
            <span class="preview">{{$translate('app-editor-design-settings-description')}}</span>
          </button-partial>
        </li>
      </moduleList-partial>

      <template v-if="child === 'app-editor-base-settings'">
        <editor-content-group-partial>
          <input-partial v-model="safeContent.id" description="ID_DESCRIPTION" :disabled="$store.state.AppEditorData.type !== 'new'" type="text" :required="true" :error="errors.id" :validation="/^[a-z0-9-_+]{1,30}$/">ID</input-partial>

          <checkbox-partial v-model="safeContent.partial" :disabled="$store.state.AppEditorData.type !== 'new' || safeContent.layout" description="app-editor-partial-description" type="text">
            {{$translate('partial')}}
          </checkbox-partial>
          <checkbox-partial v-model="safeContent.layout" :disabled="$store.state.AppEditorData.type !== 'new' || safeContent.partial" description="app-editor-layout-description" type="text">
            {{$translate('layout')}}
          </checkbox-partial>
        </editor-content-group-partial>

        <editor-content-group-partial>

          <notice-partial>{{ $translate('app-editor-target-description') }}</notice-partial>


          <radio-partial :disabled="$store.state.AppEditorData.type !== 'new'" type="radio" name="target" v-model="safeContent.target" description="app-editor-target-browser-description" defaultValue="browser">{{$translate('app-editor-target-browser')}}</radio-partial>
          <radio-partial :disabled="$store.state.AppEditorData.type !== 'new'" type="radio" name="target" v-model="safeContent.target" description="app-editor-target-mail-description" defaultValue="mail">{{$translate('app-editor-target-mail')}}</radio-partial>
        </editor-content-group-partial>

        <editor-content-group-partial>
          <input-partial v-model="safeContent.language" :validation="/^[a-z]{0,2}$/">{{$translate('app-editor-language')}}</input-partial>
        </editor-content-group-partial>

        <editor-content-group-partial>
          <tags-partial v-model="safeContent.tags" type="editor" :allow-new="true">{{$translate('tags')}}</tags-partial>
        </editor-content-group-partial>

        <editor-content-group-partial>
          <tags-partial v-model="safeContent.tags" type="editor" category="category" :allow-new="true">{{$translate('category')}}</tags-partial>
        </editor-content-group-partial>

        <editor-content-group-partial>
          <input-partial v-model="safeContent.description" description="DATA_DESCRIPTION" placeholder="DATA_DESCRIPTION_NAME" type="text" validation="^.{0,50}$">{{$translate('DATA_DESCRIPTION_NAME')}}</input-partial>
        </editor-content-group-partial>

        <editor-content-group-partial>
          <select-partial v-model="safeContent.state" :options="availableStates" :multiple="false">{{$translate('state')}}</select-partial>
        </editor-content-group-partial>

        <editor-content-group-partial v-if="!safeContent.layout && !safeContent.partial">
          <select-editor-ext v-model="safeContent.settings.useLayout" :partial="false" layout @input="updateLayout">{{$translate('layout')}}</select-editor-ext>
        </editor-content-group-partial>


      </template>


      <editor-content-group-partial v-if="child === 'app-editor-html-settings'">
        <select-partial v-model="safeContent.settings.mode" :options="availableModes" :multiple="false">{{$translate('mode')}}</select-partial>

        <input-partial v-if="!safeContent.partial" v-model="safeContent.settings.title" type="text">{{$translate('sitetitle')}}</input-partial>

        <section class="details-partial">
          <h5>{{$translate('TITLE_PREVIEW')}}</h5>
          <h4>{{ safeContent.settings.title }} {{ titleShort }}</h4>
          <p>{{$translate('TITLE_DESCRIPTION')}}</p>
        </section>

        <textarea-partial v-model="safeContent.settings.description" placeholder="description" max-length="175">{{$translate('DESCRIPTION_SHORT')}}</textarea-partial>
        <section class="details-partial">
          <h5>{{$translate('DESCRIPTION_PREVIEW')}}</h5>
          <h4>{{ safeContent.settings.description }} {{ descriptionShort }}</h4>
          <p>{{$translate('DESCRIPTION_DESCRIPTION')}}</p>
        </section>

        <input-partial v-if="!safeContent.partial" v-model="safeContent.settings.url" description="The url that gets passed to the router" type="text">{{$translate('url')}}</input-partial>

        <editor-filename-partial v-if="safeContent.settings.filename && !safeContent.partial" v-model="safeContent" />

      </editor-content-group-partial>

      <editor-content-group-partial v-if="child === 'app-editor-pdf-print-settings'">

        <input-partial v-if="!safeContent.partial" v-model="safeContent.pdf.filename" type="text">{{$translate('app-editor-pdf-filename')}}</input-partial>
        <checkbox-partial v-model="safeContent.pdf.versioning" description="app-editor-pdf-versioning-description" type="text">
          {{$translate('app-editor-pdf-versioning')}}
        </checkbox-partial>

      </editor-content-group-partial>

      <editor-content-group-partial v-if="child === 'app-editor-design-settings'">

        <description-partial>{{$translate('app-editor-design-reset-cookies-description')}}</description-partial>
        <button-partial doublecheck @confirmed="$emit('cookieReset')">{{$translate('app-editor-design-reset-cookies')}}</button-partial>

      </editor-content-group-partial>
    </section>
  </section>
</template>

<script lang="ts">
import SelectOption from '@/interfaces/selectOption.interface';
import {
  Component, ModuleEditorComponent, Watch,
} from '@/libs/ModuleEditorComponent';
import EditorData from '../models/Data.model';

@Component
export default class EditorMainEditor extends ModuleEditorComponent<EditorData> {

  model = 'editor-data';

  @Watch('active')
  watchActive() {

    this.updateChild();

  }

  availableStates: SelectOption[] = [
    { value: 'draft', text: 'Draft' },
    { value: 'publish', text: 'Publish' },
  ];

  availableModes: SelectOption[] = [
    { value: 'presentation', text: 'MODE_PRESENTATION' },
    { value: 'content', text: 'MODE_CONTENT' },
  ];

  mounted() {

    this.updateChild();

  }

  updateChild() {

    if (this.$store.state.AppEditorData.active?.child?.length > 0 && this.child.length === 0) {

      this.setChild(this.$store.state.AppEditorData.active?.child);

    }

  }

  destroyed() {

    this.$store.commit('AppEditorData/clearCrumbs');
    this.unsetCurrentChild();

  }

  get active() {

    return this.$store.state.AppEditorData.active?.child;

  }

  get errors() {

    return this.$store.state.AppEditorData.errors || {};

  }

  get titleShort() {

    return this.$store.state.Project.settings?.content?.html.title.short;

  }

  get descriptionShort() {

    return this.$store.state.Project.settings?.content?.html.description.short;

  }

  // get pdfFilename() {

  //   let versionAddition = '';
  //   const id = this.$store.state.AppEditorData.content.id;
  //   const historyNumber = this.$store.state.AppEditorData.content?.history?.version || 1;
  //   const pdfFilename = this.$store.state.AppEditorData.content?.pdf?.filename;
  //   const pdfVersioning = this.$store.state.AppEditorData.content?.pdf?.versioning;

  //   if (!id) return '';
  //   if (pdfVersioning) versionAddition = `_v${historyNumber}`;


  //   return `${pdfFilename || id}${versionAddition}.pdf`;

  // }

  async updateLayout() {

    if (this.safeContent.settings.useLayout && this.$store.state.AppEditorData.layout?.id !== this.safeContent.settings.useLayout) {

      try {
        
        this.$store.dispatch('AppEditorData/layout', {
          company: this.$store.state.Company.company,
          project: this.$store.state.Project.project,
          id: this.safeContent.settings.useLayout,
        });
      
      } catch (error) {

        console.error(error);
        this.safeContent.settings.useLayout = undefined;
        
      }

    }

    if (!this.safeContent.settings.useLayout) {

      this.$store.commit('AppEditorData/layout');

    }

  }

  publishState(type: string) {

    let state: any;

    try {

      state = this.$store.state.AppEditorData.content.publish[type];

    } catch (e) {

      state = null;

    }

    return state;

  }

}
</script>
