<template>
  <section class="module-options" :class="{ childActive: child }">

    <moduleList-partial v-if="!child" class="module-editor-navigation">
      <li>
        <button-partial :disabled="!variantsAvailable" class="hasPreview" :variant="['moduleNavigationVariant']" @confirmed="setParentChild('variant')">
          <span class="title">{{$translate('variant')}}</span>
          <span v-if="variantsAvailable" class="preview">{{variants}}</span>
          <span v-if="!variantsAvailable" class="preview">{{$translate("NO_VARIANTS_AVAILABLE")}}</span>
        </button-partial>
      </li>
      <li>
        <button-partial v-if="editorModes" :variant="['moduleNavigationVariant']" @confirmed="setParentChild('MODULE_VIEW_MODES')">{{$translate('MODULE_VIEW_MODES')}}</button-partial>
      </li>
      <li>
        <button-partial :variant="['moduleNavigationVariant']" @confirmed="setParentChild('MORE_MODULE_OPTIONS')">{{$translate('MORE_MODULE_OPTIONS')}}</button-partial>
      </li>
    </moduleList-partial>

    <section v-if="child === 'variant'" class="variants">
      <section v-for="(variantGroup, index) in groupedVariants" :key="`variant-group-${index}`" class="variantGroup">
        <h3>{{$translate(`app-editor-variant-group-${index}`)}}</h3>
        <template v-for="(variant, index) in variantGroup.variants">
          <checkbox-partial :key="`variant-checkbox-${index}`" :description="variant.description" type="text" :value="isSelected(variant.value)" @input="toggleVariant(variant.value)">
            {{$translate(variant.text)}}
          </checkbox-partial>
        </template>
      </section>
    </section>

    <section v-if="child === 'MODULE_VIEW_MODES'">

      <section class="variants">
        <template v-for="(mode, index) in editorModes">
          <checkbox-partial :key="`variant-checkbox-${index}`" :description="mode.description" type="text" :value="modeIsSelected(mode.key)" @input="toggleMode(mode.key)">
            {{$translate(mode.name)}}
          </checkbox-partial>
        </template>
      </section>

    </section>

    <module-more-options-partial v-if="child === 'MORE_MODULE_OPTIONS'" v-model="value" :no-export="noExport" />

    <section v-if="!child" class="module-editor-options">
      <button-partial class="colorScheme2 base" doublecheck @confirmed="clone()">{{$translate('app-editor-drag-clone')}}</button-partial>
      <button-partial class="colorScheme2 base" doublecheck @confirmed="remove()">{{$translate('app-editor-drag-delete')}}</button-partial>
    </section>

    <slot />
  </section>
</template>
<script lang="ts">
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import ArrayTools from '@/libs/lila-array';
import { ModuleGeneric, AvailableVariant, EditorMode } from '@lilaquadrat/studio/lib/interfaces';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ModuleOptionsPartial extends PartialEditorComponent<ModuleGeneric> {

  @Prop(Object) value: ModuleGeneric;

  @Prop(Boolean) noExport: boolean;

  @Prop(Array) availableVariants: AvailableVariant[];

  @Prop(Array) editorModes: EditorMode[];

  remove(): void { this.$emit('remove'); }

  clone(): void { this.$emit('clone'); }


  positions = [
    { value: 'top', text: 'POSITION_TOP' },
    { value: 'content', text: 'POSITION_CONTENT' },
    { value: 'bottom', text: 'POSITION_BOTTOM' },
  ];


  get exportData(): string {

    return JSON.stringify([this.value]);

  }


  get variants() {

    return this.value.variant.map((single) => this.availableVariants?.find((variant) => single === variant.value)?.text || single).join(', ');

  }

  get variantsAvailable() {

    return !!this.availableVariants?.length;

  }

  get groupedVariants() {

    const groups: Record<string, Record<string, AvailableVariant[]>> = {
      base: {
        variants: [],
      },
    };

    this.availableVariants.forEach((single) => {

      console.log(single.group);

      if (single.group) {

        if (!groups[single.group]) groups[single.group] = { variants: [] };

        groups[single.group].variants.push(single);

      } else {

        groups.base.variants.push(single);

      }

    });

    return groups;

  }

  setParentChild(type: string) {

    this.setChild(type);
    this.$emit('setChild', type);

  }

  isSelected(key: string) {

    return this.value?.variant.includes(key);

  }

  modeIsSelected(key: string) {

    return this.value?.editor?.modes?.includes(key);

  }

  toggleVariant(key: string) {


    if (this.value?.variant.includes(key)) {

      ArrayTools.remove(this.value.variant.indexOf(key), this.value.variant);

    } else {

      this.value.variant.push(key);

    }

  }
  
  toggleMode(key: string) {


    if (this.value?.editor?.modes?.includes(key)) {

      ArrayTools.remove(this.value.editor.modes.indexOf(key), this.value?.editor.modes);

    } else {

      this.value.editor?.modes?.push(key);

    }

  }

}
</script>
<style lang="less" scoped>

.module-options::v-deep {
  display: grid;
  gap: 20px;

  .multi(padding, 4, 0, 0, 0);

  &.childActive {
    padding: 0;
  }

  h4, .variants, .position, .export {
    .multi(padding, 0, 4);
  }

  h4 {
    .font-normal;
    color: @textColor;
    text-transform: uppercase;
  }

  .module-editor-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    margin: 0 20px;

    @media @tablet, @desktop {
      gap: 0;
      margin: 0;
    }

    button {
      align-content: center;
      align-items: center;
      justify-content: center;
      justify-items: center;
      height: 45px;
    }
  }

  .variants {
    display: grid;
    gap: 40px;

    h3 {
      .font-normal;
      color: @textColor;
    }

    .variantGroup {
      display: grid;
      gap: 20px;

    }
  }

}
</style>
