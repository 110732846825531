import MainStore, { state } from '@/store/main.store';
import Vue from 'vue';
import GenericStore from '@/interfaces/GenericStore.interface';
import { BasicData, ListOfModels, Media, MediaStats } from '@lilaquadrat/interfaces';
import StudioSDK from '@/libs/StudioSDK';
import MediaStoreState from '../interfaces/MediaStoreState.interface';
import generateFileInfo from '../functions/generateFileInfo';

const MediaStore: GenericStore<MediaStoreState> = {

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },

  namespaced: true,
  strict: true,

  state: {
    data: {},
    upload: [],
    active: null,
    file: {},
    edit: false,
    filterActive: false,
    site: 1,
    search: {
      tags: [],
      search: '',
    },
    // cache: null,
    uploadState: {
      new: 0,
      uploading: 0,
      processing: 0,
      success: 0,
      error: 0,
      all: 0,
    },
    uploading: false,
    dropzoneActive: false,
    selectMode: false,
    selectModel: null,
    showMediaSelect: false,
    mediaSelectCache: null,
    version: null,
    stats: null,
  },

  mutations: {
    data(mediaStoreState, data: ListOfModels<BasicData<Media>>) {

      mediaStoreState.data = data;

    },

    addUpload(mediaStoreState, uploadFile: FileUpload) {

      const fileIndex = mediaStoreState.upload.findIndex((single) => single.file === uploadFile.file);

      if (fileIndex > -1) {

        Vue.set(mediaStoreState.upload, fileIndex, uploadFile);

      } else {

        mediaStoreState.upload.push(uploadFile);

      }

      this.commit('AppMedia/updateUploadState');

    },

    updateUpload(mediaStoreState, data: { file: File, data: any }) {

      const index = mediaStoreState.upload.findIndex((single) => single.file === data.file.name);

      if (!mediaStoreState.upload[index]) return;

      const upload = mediaStoreState.upload[index];

      if (data.data.type === 'error') {

        upload.state = 'error';
        upload.message = data.data.message;

      }

      if (data.data.type === 'progress') {

        if (data.data.progress < 100) {

          upload.state = 'uploading';
          upload.progress = data.data.progress;

        } else {

          upload.state = 'success';
          upload.progress = 100;

        }

      }

      if (data.data.type === 'processing') {

        upload.state = 'processing';

      }

      this.commit('AppMedia/updateUploadState');

    },

    setActive(mediaStoreState, index: number) {

      mediaStoreState.active = index;

    },


    setFile(mediaStoreState, file: any) {

      mediaStoreState.file = file;

    },

    setSearch(mediaStoreState, search: { tags?: string[], search?: string, folder: string }) {

      if (search.tags) {

        if (typeof search.tags === 'string') {

          mediaStoreState.search.tags = [search.tags];

        } else {

          mediaStoreState.search.tags = search.tags;

        }

      } else {

        mediaStoreState.search.tags = [];

      }

      if (search.search) {

        mediaStoreState.search.search = search.search;

      } else {

        delete mediaStoreState.search.search;

      }

      if (search.folder) {

        mediaStoreState.search.folder = search.folder;

      } else {

        delete mediaStoreState.search.folder;

      }

    },

    updateSearch(mediaStoreState: MediaStoreState, update: { data: any, type: string }): void {

      console.log(195, !update.data?.length, update.data, update.type);

      if (!update.data?.length) {

        mediaStoreState.search[update.type] = null;
        return;

      }

      mediaStoreState.search[update.type] = update.data;

    },

    setSite(mediaStoreState, site: number) {

      mediaStoreState.site = site || 1;

    },

    toggleFilter(mediaStoreState, active: boolean) {

      mediaStoreState.filterActive = active;

    },

    setEditMode(mediaStoreState, edit: boolean) {

      mediaStoreState.edit = edit;

    },

    updateMetadata(mediaStoreState, data: any) {

      mediaStoreState.content.data[data.index].info = data.metadata;

    },

    updateUploadState(mediaStoreState) {

      mediaStoreState.uploadState = {
        new: 0,
        uploading: 0,
        processing: 0,
        success: 0,
        error: 0,
        all: 0,
      };

      mediaStoreState.uploadState.all = mediaStoreState.upload.length;

      mediaStoreState.upload.forEach((upload: FileUpload) => {

        mediaStoreState.uploadState[upload.state] += 1;

      });

      this.commit('AppMedia/setUploading', mediaStoreState.uploadState.new > 0 || mediaStoreState.uploadState.uploading > 0 || mediaStoreState.uploadState.processing > 0); 

    },

    setUploading(mediaStoreState, uploading: boolean) {

      mediaStoreState.uploading = uploading;
      if (!uploading) StudioSDK.flushCache('media', 'get');

    },

    setDropzoneActive(mediaStoreState, active: boolean) {

      mediaStoreState.dropzoneActive = active;

    },

    setSelectMode(mediaStoreState, active: boolean) {

      mediaStoreState.selectMode = active;

    },

    setSelectModel(mediaStoreState, model: any) {

      mediaStoreState.selectModel = model;

    },

    version(mediaStoreState, index: number) {

      mediaStoreState.version = index;

    },

    showMediaSelect(mediaStoreState, status: boolean) {

      mediaStoreState.showMediaSelect = status;

    },

    // mediaSelectCache(mediaStoreState, cache: Record<string, string>) {

    //   mediaStoreState.mediaSelectCache = cache;

    // },

    stats(mediaStoreState, stats: MediaStats) {

      mediaStoreState.stats = stats;

    },

  },

  actions: {

    get(mediaStoreState, data: { company: string, project: string, site?: number, query?: { tags?: string[], filename?: string } }) {

      const sdk = new StudioSDK('media', MediaStore.sdkOptions());
      const queryArray: (string | number)[] = [data.company, data.project];

      if (data.site) queryArray.push(data.site);

      return sdk.media.get(data)
        .then((response) => {

          response.data.data.forEach((file) => {

            generateFileInfo(file, MainStore.getters.cdn);

          });

          return response;

        })
        .catch((e) => {

          console.log(e);
          throw new Error(e);

        });


    },

    update(mediaStoreState, data: { id: string, tags: string[] }) {

      const sdk = new StudioSDK('media', MediaStore.sdkOptions());

      return sdk.media.update(data.id, data.tags);

    },

    delete(mediaStoreState, internalId: string) {

      const sdk = new StudioSDK('media', MediaStore.sdkOptions());

      return sdk.media.remove(internalId);

    },

    single(mediaStoreState, filename: string) {

      const sdk = new StudioSDK('media', MediaStore.sdkOptions());

      return sdk.media.single(filename)
        .then((single) => {

          generateFileInfo(single.data, MainStore.getters.cdn);

          return single;

        });

    },

    stats(store, data: { company: string, project: string}) {

      const sdk = new StudioSDK('media', MediaStore.sdkOptions());

      return sdk.media.stats(data)
        .then((stats) => {

          store.commit('stats', stats.data);

          return stats.data;

        });

    },
  },

};


export default MediaStore;
