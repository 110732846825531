<template >
  <article :class="navigationAnimation">
    <side-screen-header-partial>{{$translate('MEDIA_UPLOAD_HISTORY')}}</side-screen-header-partial>
    <section class="content-container">
      <section class="media pagenator upload-history">
        <header class="main">
          <section class="stats">
            <h3>{{ recent.length }} {{$translate('total')}}</h3>
          </section>

        </header>

        <table>
          <tbody>
            <tr v-for="(file, i) in recent" :class="[file.state]" v-bind:key="`media-upload-${i}`">
              <td>
                <icons-partial v-if="file.state === 'uploading' || file.state === 'processing' || file.state === 'new'" type="upload" animate />
                <icons-partial v-if="file.state === 'success'" type="checked" colorScheme="green" />
                <icons-partial v-if="file.state === 'error'" type="warning" colorScheme="red" />
              </td>
              <td>
                <div class="filename">{{ file.file }}</div>
                <div v-if="file.message" class="message">{{$translate(file.message)}}</div>
              </td>
              <td>
                <template v-if="file.state !== 'processing'"> {{ file.progress }}% </template>
                <template v-if="file.state === 'processing'">
                  {{$translate('processing')}}
                </template>
              </td>
            </tr>
          </tbody>
        </table>

        <media-dropzone-partial v-if="$store.state.AppMedia.dropzoneActive" />
      </section>
    </section>
  </article>

</template>

<script lang="ts">
import {
  ExtComponent, Component,
} from '@/libs/lila-component';

@Component
// eslint-disable-next-line global-require
export default class MediaUploadScreen extends ExtComponent {

  componentName = ['app-media-project-upload', 'editor-media-upload'];

  mounted() {

    this.DOM.onElement('dragover dragstart', this.$el, () => {

      if (this.$store.state.AppMedia.dropzoneActive) return;
      this.$store.commit('AppMedia/setDropzoneActive', true);

    });

  }

  get back() {

    const match = this.$route.name.match(/(.*)-upload$/);

    return `${match[1]}-home`;

  }

  get recent() {

    // return [
    //   {
    //     state: 'new',
    //     progress: 75,
    //     file: 'some file.jpg',
    //   },
    //   {
    //     state: 'uploading',
    //     progress: 76,
    //     file: 'some file #1.jpg',
    //   },
    //   {
    //     state: 'error',
    //     progress: 10,
    //     file: 'some file.jpg',
    //     message: 'UPLOAD_ERROR',
    //   },
    //   {
    //     state: 'success',
    //     progress: 100,
    //     file: 'some file.jpg',
    //   },
    //   {
    //     state: 'uploading',
    //     progress: 76,
    //     file: 'some file #1.jpg',
    //   },
    //   {
    //     state: 'error',
    //     progress: 10,
    //     file: 'some file.jpg',
    //     message: 'UPLOAD_ERROR',
    //   },
    //   {
    //     state: 'success',
    //     progress: 100,
    //     file: 'some file.jpg',
    //   },
    //   {
    //     state: 'uploading',
    //     progress: 76,
    //     file: 'some file #1.jpg',
    //   },
    //   {
    //     state: 'error',
    //     progress: 10,
    //     file: 'some file.jpg',
    //     message: 'UPLOAD_ERROR',
    //   },
    //   {
    //     state: 'success',
    //     progress: 100,
    //     file: 'some file.jpg',
    //   },
    //   {
    //     state: 'uploading',
    //     progress: 76,
    //     file: 'some file #1.jpg',
    //   },
    //   {
    //     state: 'error',
    //     progress: 10,
    //     file: 'some file.jpg',
    //     message: 'UPLOAD_ERROR',
    //   },
    //   {
    //     state: 'success',
    //     progress: 100,
    //     file: 'some file.jpg',
    //   },
    //   {
    //     state: 'uploading',
    //     progress: 76,
    //     file: 'some file #1.jpg',
    //   },
    //   {
    //     state: 'error',
    //     progress: 10,
    //     file: 'some file.jpg',
    //     message: 'UPLOAD_ERROR',
    //   },
    //   {
    //     state: 'success',
    //     progress: 100,
    //     file: 'some file.jpg',
    //   },
    //   {
    //     state: 'uploading',
    //     progress: 76,
    //     file: 'some file #1.jpg',
    //   },
    //   {
    //     state: 'error',
    //     progress: 10,
    //     file: 'some file.jpg',
    //     message: 'UPLOAD_ERROR',
    //   },
    //   {
    //     state: 'success',
    //     progress: 100,
    //     file: 'some file.jpg',
    //   },
    // ];
    return this.$store.state.AppMedia.upload;

  }

}
</script>
<style lang="less" scoped>

.upload-history {

  display: grid;

  table {

    tr {
      grid-template-columns: max-content 1fr max-content;

      td {
        display: grid;
        text-align: left;
      }
    }
  }

}

</style>
