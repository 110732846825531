import InstallableApp from '../../interfaces/InstallableApp.interface';
import routes from './routes';
import translations from './translations/translations';

import './models/shopify.model';
import './models/stripe.model';

const app: InstallableApp = {
  name: 'payment',
  publisher: 'lilaquadrat',
  translations,
  routes,
  store: 'AppPayment',
};

export default app;
