<template>
  <section class="copy-partial">

    <button-group-partial>
      <button-partial :aria-label="$translate('copy to clipboard')" class="fontNormal" :class="{ success: pasted }" :title="$translate('copy to clipboard')" colorScheme="transparent" @click="paste">
        <template v-if="!pasted">{{$translate('paste')}}</template>
        <template v-if="pasted">{{$translate('pasted')}}</template>
      </button-partial>
      <button-partial :aria-label="$translate('copy to clipboard')" class="fontNormal" :class="{ success: copied }" :title="$translate('copy to clipboard')" colorScheme="transparent" @click="copy">
        <template v-if="!copied">{{$translate('copy')}}</template>
        <template v-if="copied">{{$translate('copied')}}</template>
      </button-partial>
    </button-group-partial>

    <overlay-background-partial v-if="showPaste" ref="options" @close="closePaste">
      <section class="importContainer">
        <textarea-partial ref="importTextarea" v-model="importTextModel" />
        <button-group-partial>
          <button-partial @click="importText">{{$translate('import')}}</button-partial>

        </button-group-partial>
      </section>
    </overlay-background-partial>

    <input v-show="visible" readonly class="copy-element" ref="copyElement" :value="stringifiedValue" />

  </section>
</template>

<script lang="ts">
import { ExtComponent, Component, Prop } from '@/libs/lila-component';
import hardCopy from '@/mixins/hardCopy';
import Vue from 'vue/types/umd';

@Component
export default class copyPartial extends ExtComponent {

  @Prop([String, Object]) value: string | object;

  @Prop(String) id: string;

  visible: boolean = false;

  showPaste: boolean = false;

  $refs: {
    copyElement: HTMLElement,
    importTextarea: Vue,
  };

  importTextModel = '';

  copied = false;

  copiedTimeout = null;

  pasted = false;

  pastedTimeout = null;

  copy() {

    this.visible = true;

    this.$nextTick()
      .then(() => {

        const element = this.$refs.copyElement as HTMLInputElement;

        element.select();

        document.execCommand('copy');
        this.visible = false;

        this.$store.commit('copy', { id: this.id, value: hardCopy(this.value) });
        this.copied = true;

        clearTimeout(this.copiedTimeout);
        this.copiedTimeout = setTimeout(() => {

          this.copied = false;

        }, 3000);

      });

  }

  paste() {

    if (this.$store.state.clipboard) {

      if (this.$store.state.clipboard?.id !== this.id) return;
      if (!this.$store.state.clipboard?.value) return;

      this.$emit('paste', hardCopy(this.$store.state.clipboard.value));
      this.pasted = true;

      clearTimeout(this.pastedTimeout);
      this.pastedTimeout = setTimeout(() => {

        this.pasted = false;

      }, 3000);

    } else {

      this.showPaste = true;

    }


  }

  importText() {

    let content;

    console.log(this.importTextModel);

    try {

      content = JSON.parse(this.importTextModel);

    } catch (error) {

      this.showPaste = false;
      console.error(error);

    }

    if (content) {

      if (content?.id !== this.id) return;
      if (!content?.value) return;

      this.$emit('paste', content.value);
      this.pasted = true;

      clearTimeout(this.pastedTimeout);
      this.pastedTimeout = setTimeout(() => {

        this.pasted = false;

      }, 3000);


    }

    this.showPaste = false;
    this.importTextModel = '';


  }

  closePaste() {

    this.showPaste = false;

  }

  // eslint-disable-next-line class-methods-use-this
  get stringifiedValue() {

    return JSON.stringify({ id: this.id, value: this.value });


  }

  mounted() {

    this.listenCommands();

  }

  destroyed() {

    this.$root.$off('command');

  }


  // eslint-disable-next-line class-methods-use-this
  listenCommands(): void {

    this.$root.$on('command', (key: string) => {

      if (key === 'c') {

        this.copy();

      }

      if (key === 'v') {

        this.paste();

      }

    });

  }

}
</script>
<style lang="less" scoped>


.copy-element {

  .copy-element {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

}

.importContainer {

  position: absolute;
  display: grid;
  align-self: center;
  justify-self: center;
  width: 100%;
  max-width: 600px;
  .multi(padding, 4);
  background-color: @grey1;
  box-shadow: 0 0 5px -3px @textColor;

}
</style>
