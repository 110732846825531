<template>
  <article class="menuCategoryElement-editor">

    <textblock-partial-editor v-if="child === 'textblock'" v-model="value.textblock" class="top" />
    <list-elements-partial-editor v-if="child === 'app-editor-menu-items'" v-model="value.items" type="menuItemElement-partial" :model="itemModel" partial @input="update($event)" />

    <moduleList-partial v-if="!child" class="module-editor-navigation">
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('textblock')">{{$translate('textblock')}}</button-partial>
      </li>
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('app-editor-menu-items')">{{$translate('app-editor-menu-items')}}</button-partial>
      </li>
    </moduleList-partial>
  </article>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import MenuCategoryElement from '@/models/MenuCategoryElement.model';

@Component
// eslint-disable-next-line global-require
export default class MenuCategoryElementEditor extends PartialEditorComponent<MenuCategoryElement> {

  itemModel = 'menu-item-element';

}

</script>
