<template>
  <article class="data-grid">
    <slot />
  </article>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class dataGridPartial extends Vue {


}
</script>
<style lang="less" scoped>

.data-grid {
  display: grid;
  gap: 50px;

  .data-grid-container {
    display: grid;

    @media @tablet, @desktop {
      grid-template-columns: 1fr 1fr;
    }
    gap: 25px;

    h3 {
      display: grid;

      grid-template-rows: min-content;
      grid-template-columns: max-content min-content;
      grid-column-start: 1;
      grid-column-end: 3;
      gap: 10px;
      color: @color1;

      a {
        display: grid;
        align-content: end;
        font-size: @fontTextSmaller;
        line-height: 21px;
      }
    }

    &.focusFirst {
      grid-template-columns: 8fr 2fr;
    }

    &.focusLast {
      grid-template-columns: 2fr 8fr;
    }

    header, .button-group {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .data-grid-single {
      display: grid;
      grid-template-rows: min-content;
      gap: 5px;

      &.full {
        grid-column-start: 1;
        grid-column-end: 3;
      }

      &.table {
        gap: 10px;

        tr {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;

          td {
            display: grid;
            align-content: center;
            align-items: center;
            height: @buttonLineHeight;

            &:last-child {
              justify-content: end;
            }
          }
        }

        &.twoColumns {

          tr {
            grid-template-columns: 1fr 1fr;
          }

        }
      }

      h4 {
        color: @color1;
        color: @textColor;
        font-size: @fontTextSmaller;
        text-transform: uppercase;
      }

      p {
        display: grid;
        align-content: center;
        align-items: center;
        min-height: 35px;
        color: @textColor;
        font-size: @fontText;
        .font-bold;
      }

      .action {
        .font-bold;
        .multi(margin-top, 2);
      }

    }
  }
}
</style>
