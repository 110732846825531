import GenericModel from '@/interfaces/GenericModel.interface';
import Models, { ModelDeclarationExtended, ModelOptions } from '@/libs/Models.class';
import GenericData from '@/models/GenericData.model';
import Module from '@/models/Module.model';

export default interface ShoppingCartModule extends GenericModel {
  genericData: GenericData,
}

const declaration: ModelDeclarationExtended<ShoppingCartModule, Module> = {
  genericData: { model: 'generic-data' },
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('shopping-cart-module', declaration, undefined, options);