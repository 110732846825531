<script setup lang="ts">
import auth from '@/libs/lila-auth';

function logout() {

  auth.logout();

}
</script>
<template>
    <section class="me-overlay">
        <router-link class="settings link" :to="{name: 'me-settings-overview'}">{{$translate('settings')}}</router-link>
        <button-partial class="logout" @click="logout" color-scheme="transparent">{{$translate('logout')}}</button-partial>
    </section>
</template>
<style lang="less" scoped>
.me-overlay {
  display: grid;

  grid-template-rows: @buttonHeight;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  .multi(padding-left, 4);
  .multi(padding-right, 4);

  border-top: solid 1px @grey;

  .logout {
    grid-column-start: 3;
  }

}
</style>