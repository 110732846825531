<template>
  <section class="time-picker label-replacement" :class="{ hasSlot, full }">
    <input-partial selectOnFocus dontClear ref="hours" regex="[0-9]" validation="^(([0-1]([0-9])?)|([2]([0-3])?)){1}$" @input="update($event, 'hours')" :value="tempHours" />
    <span class="delimiter">:</span>
    <input-partial selectOnFocus dontClear ref="minutes" regex="[0-9]" validation="^([0-5]([0-9])?){1}$" @input="update($event, 'minutes')" :value="tempMinutes" />
    <span class="delimiter">:</span>
    <input-partial selectOnFocus dontClear ref="seconds" regex="[0-9]" validation="^([0-5]([0-9])?){1}$" @input="update($event, 'seconds')" :value="tempSeconds" />
    <span v-if="hasSlot" class="label"><slot /></span>
  </section>
</template>

<script lang="ts">
import {
  ExtComponent, Component, Prop, Watch,
} from '@/libs/lila-component';
import dayjs from 'dayjs';

@Component
export default class timePickerPartial extends ExtComponent {

  @Prop(String) value: string;

  @Prop(Boolean) full: boolean;

  @Watch('value')
  watchValue() {

    console.log(this.value);
    this.updateValues();

  }

  selected = [];

  tempHours: string = '15';

  tempMinutes: string = '00';

  tempSeconds: string = '00';

  $refs: {
    minutes: any
    hours: any
    seconds: any
  };

  mounted() {

    this.updateValues();

  }

  get hasSlot() {

    return !!this.$slots.default?.length;

  }

  update(value: string, type: 'minutes' | 'hours' | 'seconds') {

    if (type === 'hours') {

      this.tempHours = value;

      if (this.tempHours.length === 2) {

        this.$refs.minutes.$el.focus();

      }

    }

    if (type === 'minutes') this.tempMinutes = value;
    if (type === 'seconds') this.tempSeconds = value;

    this.validate();

  }

  updateValues() {

    if (dayjs(this.value).isValid()) {

      this.tempHours = dayjs(this.value).get('hour').toString().padStart(2, '0');
      this.tempMinutes = dayjs(this.value).get('minute').toString().padStart(2, '0');
      this.tempSeconds = dayjs(this.value).get('second').toString().padStart(2, '0');

    }

    if (this.value.match(/^(([0-1][0-9]):([0-5][0-9])(:[0-5][0-9]))|(([2][0-3]):([0-5][0-9])(:[0-5][0-9]))|(([0]{2}):([0-5][0-9])(:[0-5][0-9]))$/i)) {

      const splitValue = this.value.split(':');

      this.tempHours = splitValue[0];
      this.tempMinutes = splitValue[1];
      this.tempSeconds = splitValue[2] || '00';

    }

  }

  validate() {

    if (`${this.tempHours}:${this.tempMinutes}:${this.tempSeconds}`.match(/^(([0-1][0-9]):([0-5][0-9])(:[0-5][0-9]))|(([2][0-3]):([0-5][0-9])(:[0-5][0-9]))|(([0]{2}):([0-5][0-9])(:[0-5][0-9]))$/i)) {

      this.$emit('input', { hours: this.tempHours, minutes: this.tempMinutes, seconds: this.tempSeconds });

    }

  }


}
</script>
<style lang="less" scoped>

.time-picker {
  display: grid;
  grid-template-rows: @buttonHeight;
  grid-template-columns: 20px 5px 20px 5px 20px;
  align-items: center;

  &.hasSlot {
    grid-template-columns: 20px 5px 20px 5px 20px min-content;

    .label {
      display: grid;
      height: 100%;
      font-size: @fontTextSmaller;
      line-height: 25.5px;
      .multi(padding-left, 1);
    }
  }

  .delimiter {
    display: grid;
    align-content: center;
    justify-content: center;
  }

  .input-partial::v-deep {

    input {
      padding: 0;
      border: 0;
      font-size: @fontTextSmaller;
      text-align: center;
      font-variant-numeric: tabular-nums;
    }

  }

  &.full {

    grid-template-columns: 25px 5px 25px 5px 25px;

    &.hasSlot {
      grid-template-columns: 25px 5px 25px 5px 25px 20px;

      .label {
        font-size: @fontText;
      }
    }

    .input-partial::v-deep {

      input {
        font-size: @fontText;
      }

    }

  }
}
</style>
