<template>
  <section class="media-base-extension" :class="{ inline }" v-if="visible">

    <description-partial v-if="baseContent && mode === 'content'">
      {{$translate('app-media-file-linked')}} <router-link :to="{ name: 'app-editor-project-edit', params: { id: baseContent.content.id } }">{{baseContent.content.id}}</router-link>
    </description-partial>

    <template v-if="mode === 'file' && contentFile">

      <description-partial>
        {{$translate('app-media-base-file-description')}}
      </description-partial>

      <section class="title">
        <a target="_blank" rel="noopener noreferrer" :href="url"><h4>{{contentFile.filename}}</h4> <icons-partial size="large" type="file" /></a>
        <div class="statistics">{{$translate('contentFile.mimetype ')}} {{contentFile.size | formatBytes(2)}}</div>
      </section>

      <section class="info">
        <p v-if="!isCurrentVersion">{{$translate('app-media-old-version')}}</p>
        <router-link :to="editLink">{{$translate('app-media-view-in-app')}}</router-link>
        <!-- <router-link :to="viewLink" v-if="contentFile.availableVersions > 0">{{'app-media-more-version' | translate([contentFile.availableVersions])}}</router-link> -->
      </section>

    </template>
  </section>
</template>
<script lang="ts">
import { ExtComponent, Prop, Watch } from '@/libs/lila-component';
import StudioSDK from '@/libs/StudioSDK';
import { Media, MediaContentFiles } from '@lilaquadrat/studio/lib/interfaces';
import Component from 'vue-class-component';
import AppMediaStore from '../store/app.store';

@Component
export default class mediaBaseExtension extends ExtComponent {

  @Prop(Boolean) file?: boolean;

  @Prop(Boolean) inline: boolean;

  @Prop(Object) content?: Media;

  contentFile: MediaContentFiles = null;

  @Watch('content')
  watchContentFunction() {

    this.getFiles();

  }

  get visible() {

    if ((this.mode === 'file' && this.contentFile) || (this.mode === 'content' && this.baseContent)) {

      return true;

    }

    return false;

  }

  get mode() {

    if (this.file) return 'file';
    return 'content';

  }

  get baseContent() {

    if (!this.content?.baseContent?.id) return null;

    return this.content.baseContent;

  }

  get url() {

    return `${this.$store.state.settings.cdn}/${this.content.company}/${this.content.project}/${this.contentFile.id}`;

  }

  get editLink() {

    return { name: 'app-media-project-edit', params: { company: this.content.company, project: this.content.project, filename: this.contentFile.filename } };

  }

  get viewLink() {

    return { name: 'app-media-project-home', params: { company: this.content.company, project: this.content.project }, query: { folder: 'pdf' } };

  }

  get isCurrentVersion() {

    return this.contentFile?.baseContent.version === this.content.history.version;

  }

  mounted() {


    this.getFiles();


  }

  async getFiles() {

    if (this.mode === 'file' && this.content) {


      const sdk = new StudioSDK('publish-app', AppMediaStore.sdkOptions());
      const response = await sdk.media.fileForContent({ company: this.content.company, project: this.content.project, contentId: this.content?._id as unknown as string });

      this.contentFile = response.data;

    }

  }

}
</script>
<style lang="less" scoped>

.media-base-extension {
  display: grid;
  gap: 20px;
  .multi(padding, 4);

  &.inline {
    padding: 0;
  }

  .title {
    a {
      display: grid;
      grid-template-columns: 1fr min-content;
      line-height: 30px;
    }
  }

  .info {
    display: grid;
  }


}

</style>
