<template>
  <article :class="navigationAnimation" class="app-customers-single-overview">
    <side-screen-header-partial>{{$translate(name)}}</side-screen-header-partial>
    <router-view :key="`app-customers-single-overview-${$route.params.link}`" class="content-container" />
  </article>
</template>
<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';

@Component
export default class AppPublishHomeScreen extends ExtComponent {

  componentName = 'app-customers-single-overview';

  get name() {

    if (this.$route.name === 'app-customers-company-add') return 'app-customers-add';

    if (this.$store.state.AppCustomers.single.type === 'person') return `${this.$store.state.AppCustomers.single.name}, ${this.$store.state.AppCustomers.single.prename} (${this.$store.state.AppCustomers.single.id})`;

    return `${this.$store.state.AppCustomers.single.name} (${this.$store.state.AppCustomers.single.id})`;

  }

}
</script>
