<template>
  <article class="app-hostingadmin-commands-add-screen" :class="navigationAnimation">
    <side-screen-header-partial>{{ $translate('app-hostingadmin-single-command-add') }}</side-screen-header-partial>
    <section class="content-container">

      <form class="content" @submit="save">

        <fieldset>
          <legend><span class="text">{{ $translate('app-hostingadmin-commands-docker') }}</span></legend>
          <description-partial>{{ $translate('app-hostingadmin-commands-docker-description') }}</description-partial>

          <section class="selection-container hasProject">
            <label>
              <input v-model="type" type="radio" name="action" value="docker">
              docker
            </label>
            <select-partial v-model="dockerCommand" inline placeholder="app-hostingadmin-commands-command" :options="commandOptions" :multiple="false" allow-empty />
            <section class="project">
              <input-partial v-model="model.company" placeholder="company" />
              <input-partial v-model="model.project" placeholder="project" />
            </section>
          </section>
          <description-partial>{{ $translate('app-hostingadmin-commands-docker-project-description') }}</description-partial>

        </fieldset>

        <fieldset>
          <legend><span class="text">{{ $translate('app-hostingadmin-commands-nginx') }}</span></legend>
          <description-partial>{{ $translate('app-hostingadmin-commands-nginx-description') }}</description-partial>

          <section class="selection-container">
            <label>
              <input v-model="type" type="radio" name="type" value="nginx">
              nginx
            </label>
            <select-partial v-model="nginxCommand" inline placeholder="app-hostingadmin-commands-nginx" :options="nginxOptions" :multiple="false" allow-empty />
          </section>

        </fieldset>

        <fieldset>
          <legend><span class="text">{{ $translate('app-hostingadmin-commands-refresh') }}</span></legend>
          <description-partial>{{ $translate('app-hostingadmin-commands-refresh-description') }}</description-partial>

          <section class="selection-container hasProject">
            <label>
              <input v-model="type" type="radio" name="type" value="refresh">
              refresh
            </label>
            <select-partial v-model="refreshCommand" inline placeholder="app-hostingadmin-commands-refresh" :options="refreshOptions" :multiple="false" allow-empty />
            <section v-if="refreshCommand === 'nginx'" class="project">
              <input-partial v-model="model.company" placeholder="company" />
              <input-partial v-model="model.project" placeholder="project" />
            </section>
          </section>

        </fieldset>

        <fieldset>
          <legend><span class="text">{{ $translate('app-hostingadmin-commands-command-preview') }}</span></legend>

          <h3>{{ commandPreview }}</h3>

          <button-group-partial>
            <button-partial type="submit">{{$translate('app-lists-edit-button')}}</button-partial>
          </button-group-partial>
        </fieldset>

      </form>


    </section>
  </article>
</template>

<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import ModelsClass from '@/libs/Models.class';
import { ErrorsObject } from '@/libs/ActionNotice';
import { HostingCommand } from '@lilaquadrat/studio/interfaces';
import { AppHostingAdminCommand } from '../models/command.model';

@Component
export default class AppHostingAdminSingleCommandScreen extends ExtComponent {

  componentName = 'app-hostingadmin-company-commands-add';

  model: AppHostingAdminCommand = null;

  type = '';

  project = '';

  company = '';

  dockerCommand = '';

  nginxCommand = '';

  refreshCommand = '';

  commandOptions: { text: string, value: HostingCommand['command'] }[] = [
    {
      text: 'app-hostingadmin-commands-command-up',
      value: 'up',
    },
    {
      text: 'app-hostingadmin-commands-command-down',
      value: 'down',
    },
    {
      text: 'app-hostingadmin-commands-command-restart',
      value: 'restart',
    },
    {
      text: 'app-hostingadmin-commands-command-start',
      value: 'start',
    },
    {
      text: 'app-hostingadmin-commands-command-stop',
      value: 'stop',
    },
    {
      text: 'app-hostingadmin-commands-command-rebuild',
      value: 'rebuild',
    },
    {
      text: 'app-hostingadmin-commands-command-kill',
      value: 'kill',
    },
    {
      text: 'app-hostingadmin-commands-command-check',
      value: 'check',
    },
    {
      text: 'app-hostingadmin-commands-command-status',
      value: 'status',
    },
  ];

  refreshOptions: { text: string, description: string, value: 'stream' | 'compose' | 'nginx' | 'nginxMain' }[] = [
    {
      text: 'app-hostingadmin-commands-refresh-stream',
      description: 'app-hostingadmin-commands-refresh-stream-description',
      value: 'stream',
    },
    {
      text: 'app-hostingadmin-commands-refresh-compose',
      description: 'app-hostingadmin-commands-refresh-compose-description',
      value: 'compose',
    },
    {
      text: 'app-hostingadmin-commands-refresh-nginx',
      description: 'app-hostingadmin-commands-refresh-nginx-description',
      value: 'nginx',
    },
    {
      text: 'app-hostingadmin-commands-refresh-nginxMain',
      description: 'app-hostingadmin-commands-refresh-nginxMain-description',
      value: 'nginxMain',
    },
  ];

  nginxOptions: { text: string, value: 'start' | 'stop' | 'restart' }[] = [
    {
      text: 'start',
      value: 'start',
    },
    {
      text: 'stop',
      value: 'stop',
    },
    {
      text: 'restart',
      value: 'restart',
    },
  ];

  public errorsObject: ErrorsObject = {};

  beforeMount() {

    this.model = ModelsClass.add({}, 'app-hostingadmin-command');

  }

  get commandPreview() {

    if (this.type === 'docker') {

      return `${this.type} ${this.dockerCommand} ${this.model.company} ${this.model.project}`;

    }

    if (this.type === 'nginx') {

      return `${this.type} ${this.nginxCommand}`;

    }

    if (this.type === 'refresh') {

      if (this.refreshCommand === 'nginx') {

        return `${this.type} ${this.refreshCommand} ${this.model.company} ${this.model.project}`;

      }

      return `${this.type} ${this.refreshCommand}`;


    }

    return '';


  }

  save($event: MouseEvent) {

    $event.preventDefault();

    let command: string = '';

    if (this.type === 'docker') {

      command = this.dockerCommand;
      
    }
    
    if (this.type === 'nginx') {
      
      command = this.nginxCommand;

    }

    if (this.type === 'refresh') {

      command = this.refreshCommand;

    }

    this.$store.dispatch('AppHostingAdminCommands/command', { type: this.type, command, project: this.model.project, company: this.model.company  });

  }


}
</script>
<style lang="less" scoped>
.app-hostingadmin-commands-add-screen {

  .selection-container {
    display: grid;
    grid-template-rows: @buttonHeight;
    grid-template-columns: max-content max-content;
    gap: 10px;

    align-items: center;

    &.hasProject {
      grid-template-columns: max-content max-content 1fr;

      .project {
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr 1fr;
      }
    }
  }

}</style>