<template>
  <section class="filter-partial">
      <slot />
  </section>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class filterPartial extends Vue {

  get sitesSlotUsed() {

    return !!this.$slots.sites;

  }

}
</script>
<style lang="less">

.filter-partial {
  display: grid;
  gap: 10px;
  width: 100%;

  .multi(padding, 4, 8, 4, 8);
  .basePadding;

  .group {
    display: grid;
    gap: 10px;

    // label {
    //   height: @buttonHeight;
    //   align-items: end;
    // }

    label.input-partial {
      display: grid;
      max-width: 100px;

      input {
        padding: 0;
        border-bottom: solid 1px @grey;
        font-size: @fontTextSmaller;
        line-height: @buttonHeight;
      }
    }

  }

  .sites-container {
    justify-self: end;
  }

}
</style>
