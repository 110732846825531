<template>
  <section class="text-module-editor open editor" :class="{ changed: safeContent.changed }">
    <module-editor-header-partial v-model="safeContent" :position="position" :index="index" :type="type" @move="move($event)" />
    <crumbs-partial :type="safeContent.type" @clearCrumbs="unsetCurrentChild" />

    <section :class="{ optionsVisible: !child }" class="editor-module-container">
      <moduleList-partial v-if="!child" class="module-editor-navigation">
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('event-infos')">{{$translate('event-module-infos')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('textblock')">{{$translate('textblock')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('list')">{{$translate('list')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('links')">{{$translate('links')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('callToAction')">{{$translate('callToAction')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('media')">{{$translate('media')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('list-model')">{{$translate('list-model')}}</button-partial>
        </li>
      </moduleList-partial>
      <textblock-partial-editor v-if="child === 'textblock'" :inactive="['headline', 'subline']" v-model="safeContent.textblock" />

      <editor-content-group-partial v-if="child === 'list-model'">
        <select-lists-ext v-model="safeContent.genericData.lists" is-generic-data />
      </editor-content-group-partial>

      <link-partial-editor v-if="child === 'callToAction'" v-model="safeContent.callToAction" />

      <editor-content-group-partial v-if="child === 'event-infos'">

        <input-partial v-model="safeContent.artist" placeholder="artist">{{$translate('event-module-artist')}}</input-partial>
        <input-partial v-model="safeContent.name" placeholder="name">{{$translate('event-module-name')}}</input-partial>
        <input-partial v-model="safeContent.location" placeholder="location">{{$translate('event-module-location')}}</input-partial>
       
        <datepicker-partial time allowEmpty :from="safeContent.start" @updateFrom="(fromDate) => safeContent.start = fromDate">{{$translate('event-module-start')}}</datepicker-partial>
        <datepicker-partial time allowEmpty :from="safeContent.end" @updateFrom="(fromDate) => safeContent.end = fromDate">{{$translate('event-module-end')}}</datepicker-partial>

      </editor-content-group-partial>

      <media-partial-editor v-if="child === 'media'" v-model="safeContent.media" />

      <list-partial-editor v-if="child === 'list'" v-model="safeContent.list" />
      <link-list-partial-editor v-if="child === 'links'" v-model="safeContent.links" />

      <module-options-partial
        v-if="child === 'options' || !child"
        v-model="safeContent"
        :available-variants="availableVariants"
        :selected-variants="safeContent.variant"
        @setChild="setChild('options', true)"
        @unsetChild="setChild('options')"
        @remove="remove()"
        @clone="clone()"
      />

    </section>
  </section>
</template>

<script lang="ts">
import { Component, ModuleEditorComponent } from '@/libs/ModuleEditorComponent';
import EventModule from '../models/EventModule.model';

@Component
export default class EventModuleEditor extends ModuleEditorComponent<EventModule> {

  model = 'event-module';

  get range() {

    return {from: this.safeContent.start, to: this.safeContent.end};

  }

  set range(range) {

    console.log(range);

    this.safeContent.start = range.from;
    this.safeContent.end = range.to;


  }

}

</script>

