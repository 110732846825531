<template>
  <article :class="navigationAnimation" class="project-home-screen">

    <header-partial />
    <apps-module project />
    <modal-module />

  </article>
</template>

<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import { App, Project } from '@lilaquadrat/studio/lib/interfaces';

@Component
export default class ProjectHomeScreen extends ExtComponent {

  componentName = 'project-home';

  /**
  * get installable apps, translates and sorts them
  */
  get apps(): (App & {translatedName?: string})[] {

    const projectApps: Project['apps'] = this.$store.state.Project.settings?.apps;
    const installable: App[] = this.$store.state.Appstore.project.installable?.filter((single: App) => projectApps?.includes(single.id));
    const translatedApps: (App & {translatedName?: string})[] = installable?.map((single) => {

      const appTranslated: App & {translatedName?: string} = single;

      appTranslated.translatedName = this.$translate(single.name);
      return single;

    });

    translatedApps?.sort((a, b) => a.translatedName.localeCompare(b.translatedName));

    return translatedApps;

  }

  get project() {

    return this.$store.state.Project.settings?.name || 'NO_PROJECT';

  }

  get company() {

    return this.$store.state.Company.settings?.name;

  }

  appLink(app: App) {

    return { ...app, to: { name: `app-${app.id}-project-home`, params: { ...this.$route.params } } };

  }

}
</script>