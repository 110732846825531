<template>
  <article class="prices-elements-editor">
    <moduleList-partial v-if="!child" class="module-editor-navigation">
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('textblock')">{{$translate('textblock')}}</button-partial>
      </li>
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('facts')">{{$translate('facts')}}</button-partial>
      </li>
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('price')">{{$translate('price')}}</button-partial>
      </li>
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('calltoaction')">{{$translate('calltoaction')}}</button-partial>
      </li>
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('more')">{{$translate('more link')}}</button-partial>
      </li>
    </moduleList-partial>
    <textblock-partial-editor v-if="child === 'textblock'" v-model="value.textblock" :inactive="['list']" />
    <list-partial-editor v-if="child === 'facts'" v-model="value.facts" no-head />

    <link-partial-editor v-if="child === 'calltoaction'" v-model="value.callToAction" />
    <link-partial-editor v-if="child === 'more'" v-model="value.more" />

    <section v-if="!child" class="editor-content-container">
      <select-partial v-model="value.variant" placeholder="NO_VARIANTS_CHOOSEN" :options="availableVariants">{{$translate('variant')}}</select-partial>
    </section>

    <section v-if="child === 'price'" class="editor-content-container">
      <input-partial v-model="value.price" placeholder="price" @keyup="$emit('keyup', $event)">{{$translate('price')}}</input-partial>
      <input-partial v-model="value.interval" placeholder="interval" @keyup="$emit('keyup', $event)">{{$translate('interval')}}</input-partial>
    </section>
  </article>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import SelectOption from '@/interfaces/selectOption.interface';
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import PricesElement from '@/models/PricesElement.model';

@Component
export default class PricesElementEditor extends PartialEditorComponent<PricesElement> {

  availableVariants: SelectOption[] = [{ value: 'highlight', text: 'highlight' }];

  check(event: any) {

    // if (event.key === 'Backspace' && this.value.picture.src.length) {

    //   this.$emit('remove', event);

    // }

    if (event.key === 'Enter' && event.target.value.length > 0) {

      this.$emit('add', event);

    }

    this.$emit('keyup', event);

  }

  model = 'prices-element';

}

</script>
