import Models, { ModelDeclaration } from '@/libs/Models.class';

export default interface ListString {
  title: string
  value: string[]
}

const declaration: ModelDeclaration<ListString> = {
  title: { type: 'string' },
  value: { type: 'array', contains: { type: 'string' } },
};

Models.register('list-string', declaration);