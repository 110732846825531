<template>
  <article :class="navigationAnimation">
    <form class="content">
      <fieldset>
        <legend>
          <span class="text">{{$translate('personal')}}</span>
        </legend>

        <input-partial v-model="model.prename" placeholder="prename" required>{{$translate('prename')}}</input-partial>
        <input-partial v-model="model.lastname" placeholder="lastname" required>{{$translate('lastname')}}</input-partial>
      </fieldset>

      <fieldset>
        <legend>
          <span class="text">{{$translate('contact')}}</span>
        </legend>

        <input-partial placeholder="e-mail" disabled :value="user.email">{{$translate('email')}}</input-partial>
      </fieldset>

      <fieldset>
        <legend>
          <span class="text">{{$translate('TERMS_CONDITIONS')}}</span>
        </legend>

        <section>
          <h3>{{$translate('Terms and conditions are already accepted.')}}</h3>
          <button-partial v-if="!newUser" type="button" color-scheme="inline" @click="toggleContent(true)">{{$translate('Click here')}}</button-partial> {{$translate('to show terms and conditions.')}}
        </section>

        <content-container-partial :visible="showContent" :type="'studio'" :category="['conditions-of-use']" latest overlay @closed="toggleContent(false)" />
        <checkbox-partial v-if="newUser" v-model="model.termsAccepted" required type="text" :text="$translate('ACCEPT_TERMS_CONDITIONS')">
          <button-partial type="button" color-scheme="inline" @click="toggleContent(true)">{{$translate('Click here')}}</button-partial> {{$translate('to show terms and conditions.')}}
        </checkbox-partial>
      </fieldset>

      <button-group-partial>
        <button-partial v-model="state" type="button" :disabled="!ready" save @input="dispatchSave">
          <template v-if="!newUser">{{$translate('SAVE_CHANGES')}}</template>
          <template v-if="newUser">{{$translate('COMPLETE_REGISTRATION')}}</template>
        </button-partial>
      </button-group-partial>


    </form>
  </article>
</template>

<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import { Me } from '@lilaquadrat/studio/lib/interfaces';
import ModelsClass from '@/libs/Models.class';

@Component
export default class MeSettingsPersonalScreen extends ExtComponent {

  showContent: boolean = false;

  model: Me = null;

  state: string = 'init';

  registrationComplete: boolean = false;

  isRegistration: boolean = false;

  componentName = 'me-settings-sidescreen';

  sidescreen = true;

  beforeMount() {

    this.model = ModelsClass.add(this.user, 'me');
    this.isRegistration = this.newUser;

    return this.user;

  }


  get user(): Me {

    return this.$store.state.user;

  }

  get newUser() {

    return this.user.termsAccepted === false || !this.user.termsAccepted;

  }

  get ready() {

    return this.model.lastname && this.model.prename && this.model.termsAccepted;

  }

  toggleContent(show: boolean) {

    this.showContent = show;

  }

  dispatchSave() {

    if (!this.ready) {

      this.state = 'error';

      setTimeout(() => {

        this.state = '';

      }, 5000);

      return;

    }

    this.$store
      .dispatch('saveMe', this.model)
      .then(() => this.$store.dispatch('getMe'))
      .then((me: Me) => {

        if (me.termsAccepted && this.isRegistration) {

          if (this.$store.state.lock) this.$store.commit('unlock');
          this.registrationComplete = true;
          this.$router.push({ name: 'me-home' });

        }

        this.state = 'success';
        return true;

      })
      .catch((e) => {

        console.error(e);

        this.state = 'error';

        setTimeout(() => {

          this.state = '';

        }, 5000);

      });

  }

}
</script>
