<template>
  <span class="tags-list-partial" v-if="tags">
    <span>{{tags}}</span> <span v-if="more">(+{{more}})</span>
  </span>
</template>
<script lang="ts">
import Component from 'vue-class-component';
import { vue } from '@/libs/lila-partial';
import { Prop } from 'vue-property-decorator';

@Component
export default class tagsListPartial extends vue {

  @Prop(Array) value: string[];

  @Prop(Boolean) showAll: boolean;

  @Prop(Boolean) categoriesOnly: boolean;

  @Prop(Boolean) tagsOnly: boolean;

  get tags() {

    const categoryTranslation = this.$translate('category');
    let cleanedTags = this.value?.map((single) => {

      if (single.includes(':')) {

        if (this.tagsOnly) return null;

        return this.categoriesOnly ? single.split(':')[1] : `${categoryTranslation}: ${single.split(':')[1]}`;

      }

      if (this.categoriesOnly) return null;

      return single;

    }).filter((single) => single);

    if (!this.showAll) cleanedTags = cleanedTags?.slice(0, 3);

    console.log(cleanedTags);

    return cleanedTags?.length ? cleanedTags.join(', ') : '';

  }

  get more() {

    if (this.showAll || this.value?.length < 3) return 0;

    return this.value?.length - 3;

  }


}
</script>
<style lang="less" scoped>


.tags-list-partial {}
</style>
