<template>

  <article class="search-partial-editor" :class="{ inline: inline }">

    <input-partial ref="input" class="searchInput" clear-icon debounce="500" :placeholder="placeholder" @focus="focus" @input="checkInput" />
    <template v-if="show && !noRender">

      <ul class="searchData">
        <li v-for="(element, index) in checkedEntries" :key="`list-entries-${index}`">
          <button type="button" class="choose" :class="{ hasDescription: element.description }" @keyup="add(element._id)" @click="add(element._id)">
            <span>{{element.id}}</span>
            <icons-partial v-if='!element.state || element.state === "draft"' :title="$translate('draft')" color-scheme='grey' type='checked' size='small' />
            <icons-partial v-if='element.state === "publish"' :title="$translate('publish')" type='checked' size='small' />
            <p v-if="element.description">{{element.description}}</p>
          </button>
        </li>
        <li v-if="!checkedEntries.length">
          <h4 class="no-entries">{{$translate('app-editor-no-children-found')}}</h4>
        </li>
      </ul>

    </template>

    <section v-if="!show && !noRender" class="list-container list-editor text editor-content-container">

      <ul>
        <li v-for="(element, index) in renderList" :key="`list-editor-${index}`">

          <div class="text-element" :class="{ hasDescription: element.description }">
            <span class="id">{{element.id}}</span>
            <span class="description">
              {{element.description}}
            </span>
          </div>

          <div class="list-item-options">
            <button-partial class="mode up" button-content-type="icon" icon="arrow-up" size="small" color-scheme="transparent" @confirmed="moveElement(index, 'up')" />
            <button-partial class="mode down" button-content-type="icon" icon="arrow-down" size="small" color-scheme="transparent" @confirmed="moveElement(index, 'down')" />
            <button-partial class="remove" button-content-type="icon" icon="close" size="small" color-scheme="transparent" @confirmed="removeElement(index)" />
          </div>

        </li>
      </ul>

    </section>

  </article>

</template>
<script lang="ts">
import Component from 'vue-class-component';
import ArrayTools from '@/libs/lila-array';
import { Store } from 'vuex';
import StudioSDK from '@/libs/StudioSDK';
import MainStore, { state } from '@/store/main.store';
import { Prop } from '@/libs/lila-partial';
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import MainStoreState from '@/store/mainStoreState.interface';

@Component
export default class SearchPartialEditor extends PartialEditorComponent<string[]> {

  @Prop(Array) value: string[];

  @Prop(Boolean) noRender: boolean;

  @Prop(Boolean) callback: boolean;

  @Prop(Boolean) inline: boolean;

  @Prop({type: Boolean, default: null}) layout: boolean;

  @Prop({type: Boolean, default: null}) active: boolean;

  @Prop({ type: Object, default: () => ({}) }) addData: Record<string, string>;

  @Prop(String) placeholder: string;

  @Prop({ type: String, default: 'editor' }) mode: 'list' | 'editor';

  entries = [];

  entriesWithDescription = [];

  selectedData = [];

  cache: string = null;

  cacheData: any = null;

  show: boolean = false;

  sdk: StudioSDK = null;

  get checkedEntries() {

    return this.entriesWithDescription.filter((single) => !this.selectedData.includes(single._id));

  }

  get renderList() {

    if (this.mode === 'editor' && this.$store.state.AppEditorData.genericDataCache.editor) {

      return this.selectedData.map((single) => {

        const content = this.$store.state.AppEditorData.genericDataCache.editor[single];

        return {
          id: content?.id,
          description: content?.description,
        };

      });

    }

    return [];

  }

  static updateCache(idArray: string[], store: Store<MainStoreState>) {

    return store.dispatch(
      'Cache/genericDataCache',
      {
        idArray,
        type: 'editor',
      },
    );

  }

  mounted() {

    this.sdk = new StudioSDK('studio-app', {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    });

    if (this.callback) return;

    this.selectedData = this.value;

    SearchPartialEditor.updateCache(this.value, this.$store)
      .then(() => this.emit());


  }

  add(value: string) {

    this.selectedData.push(value);

    this.$store.dispatch('AppEditorData/editGenericDataIndex', { add: this.selectedData, type: 'editor' });

    SearchPartialEditor.updateCache(this.value, this.$store)
      .then(() => this.emit());

  }

  removeElement(index: number): void {

    this.$store.dispatch('AppEditorData/editGenericDataIndex', { remove: [this.selectedData[index]], type: 'editor' });

    ArrayTools.remove(index, this.selectedData);

    this.emit();

  }

  moveElement(index: number, direction: string): void {

    ArrayTools.move(index, direction, this.selectedData);
    this.emit();

  }

  focus() {

    console.log('focus');
    if (!this.cache) return;
    this.toggleEntries(true);

  }

  checkInput(value: string) {

    if (value) {

      if (this.cache !== value) {

        this.find(value);

      }

      this.toggleEntries(true);

    } else {

      this.toggleEntries(false);

    }

    this.cache = value;
    this.$emit('searchInput', value);

  }

  toggleEntries(show?: boolean) {

    console.log(show);

    if (show === undefined) this.show = !!this.show;
    this.show = show;

  }

  find(input: string) {

    if (this.mode === 'editor') {

      this.findFilterEditor(input);

    }

    if (this.mode === 'list') {

      this.findList(input);

    }

  }

  async findList(input: string) {

    const lists = await this.sdk.lists.list(1, input);

    this.entriesWithDescription = lists.data.data.map((single) => single);

  }

  findFilterEditor(input: string) {

    console.log(input);

    const sdk = new StudioSDK('studio-app', {
        authToken: state.authToken,
        customEndpoints: MainStore.getters.endpointsSDK,
        ...MainStore.getters.companyProject,
      });

      sdk.editor.list(0, 'id', 1, {layout: this.layout, search: input, active: this.active})
      .then((response) => {

        console.log(response, this.$store.state.AppEditorData.content.id);

        if (this.callback) {

          this.$emit('update', response.data);

        }

        this.entries = response.data.data.filter((single) => {

          if (single.id && this.$store.state.AppEditorData.content.id !== single.id) return single.id;
          return false;

        })
          .map((single) => single.id);

        this.entriesWithDescription = response.data.data.map((single) => {

          if (single.id && this.$store.state.AppEditorData.content.id !== single.id) {

            return {
              id: single.id,
              _id: single._id,
              description: single.description,
              state: single.state,
            };

          }

          return false;

        });

      })
      .catch((e) => {

        console.log(e);
        throw new Error(e);

      });

  }

  // update(e) {

  //   console.info('update', e);
  //   this.selectedData = e;

  // }

  emit() {

    this.$emit('input', this.selectedData);

  }

}

</script>
<style lang="less" scoped>

.search-partial-editor {

  display: grid;
  gap: 20px;

  .input-partial::v-deep {

    button {
      right: 0
    }
  }

  .searchInput {

    .multi(margin, 0, 4);
    padding: 0;
    background-color: @white;

    .label {
      display: none;
    }

  }

  .no-entries {
    .multi(padding, 0, 4);
  }

  &.inline {

    .searchInput {
      .multi(padding, 0);
      .multi(margin, 0);
    }

    .no-entries {
      .multi(padding, 0);
    }

  }

  .searchData {

    li {

      display: grid;

      button {

        display: grid;

        grid-template-columns: 1fr 40px;
        align-content: center;
        justify-items: start;
        height: 50px;
        font-size: @fontText;
        line-height: 20px;
        text-align: left;
        .font-normal;
        .multi(padding, 0, 4);

        &.hasDescription {

          grid-template-rows: 20px 20px;

          p {
            display: grid;

            grid-column-start: 1;
            grid-column-end: 3;
            font-size: @fontTextSmaller;
            text-transform: none;
          }

        }

        .icon-partial {
          display: grid;
          align-self: center;
          justify-self: end;
        }

        &:hover {
          color: @color1;
        }

      }

    }

  }

}
</style>
