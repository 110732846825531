<template>
  <article :class="navigationAnimation" class="structures-home-screen">
    <div class="pagenator full">
      <header class="main">

        <section class="stats">
          <h1>{{ $translate('structures-overview-title') }}</h1>
          <h3>
            <template v-if="!content.sites.last">{{ content.count }}</template>
            <template v-if="content.sites.last">{{ content.sites.range[0] }} - {{ content.sites.range[1] }} ({{
              content.count }})</template>
            / {{ content.all }} {{ $translate('total') }}
          </h3>
        </section>

        <quickmenu-partial v-if="$scope([`${$route.meta.model}:structuresCreate`])">
        <!-- <quickmenu-partial v-if="$scope(['publishMethods:create'])"> -->
          <router-link :title="$translate('app-publish-methods-add')" :aria-label="$translate('app-publish-methods-add')" class="main icon" :to="{ name: `${linkBase}-structures-add` }"><icons-partial type="add" size="medium" /></router-link>
        </quickmenu-partial>
      </header>

      <quickfilter-partial store="Structures" reload @reload="reload" />

      <section class="scroll-container">
        <table class="isSticky">
          <thead-partial :site-current="currentSite" :search="$route.query.search" :content="headContent" />
        </table>

        <table class="data-switch" :class="contentAnimation">
          <tbody>
            <tr v-for="(single, index) in content.data" :key="`data-overview-${index}`">
              <td class="id fullHeight action" :class="{ hasAttribute: single.app || single.model }">
                <router-link
                :to="{
                  name: `${linkBase}-structures-edit`,
                  params: {
                    id: single._id.toString(),
                    company: $store.state.Company.company,
                    project: $store.state.Project.project,
                  },
                }">
                    <span class="name">{{ single.question }}</span>
                    <span class="description">{{ single.id }}</span>
                </router-link>
              </td>

              <td class="status fullHeight">
                {{ $translate(`structures-type-${single.type}`) }}
              </td>

            </tr>
          </tbody>
        </table>
      </section>

    </div>

  </article>
</template>

<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import MainStoreState from '@/store/mainStoreState.interface';
import { DataObject, Customers, Structure, BasicData } from '@lilaquadrat/interfaces';
import { Route } from 'vue-router';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class StructureHomeScreen extends ExtComponent {

  componentName = ['app-customers-company-structures-home', 'app-customers-company-structures-home'];

  headContent = [
    {
      text: 'structures-question',
    },
    {
      text: 'structures-type',
    },
  ];

  query: Dictionary<string | string[]> = {};

  get linkBase() {

    return this.$route.meta.linkBase;

  }

  get content(): DataObject<BasicData<Structure>[]> {

    return this.$store.state.Structures.data;

  }


  get currentSite() {

    return this.$route.params.site ? +this.$route.params.site : null;

  }

  async reload() {

    StudioSDK.flushCache('structures', 'list');

    const preloadedData = await this.preloadDataPre(this.$route.params, this.$route.query, this.$store, this.$route);

    this.preloadDataPost(preloadedData, this.$route.params, this.$route.query, this.$store);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>, to?: Route) {

    const settings: Record<string, string | string[]> = {
      site: to.params.site,
      models: [to.meta.model],
    };

    if(query.search) {

      settings.search = query.search;
    
    }

    console.log(params, query, to, settings);
    return store
      .dispatch('Structures/get', {
        query: {
          ...query,
          ...settings,
        },
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<DataObject<Customers>>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    console.log(preloadedData);
    store.commit('Structures/data', preloadedData.data);
    return Promise.resolve();

  }


}
</script>
<style lang="less" scoped>
.structures-home-screen {

  table::v-deep {

    tr {
      grid-template-columns: 1fr 100px;
    }

  }
}
</style>