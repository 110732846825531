<template>
  <section class="integrated-container" :class="{ resultsVisible: activeTabindex === 0, hasTabs: tabs.length > 1 }">

    <section class="integrated-sidebar">
      <h1>{{$translate('app-learn')}}</h1>
      <learn-search-module mode="integrated" visible="input" :base-search="searchCache" @focus="selectTab(0)" @search="updateSearch" />
      <button-partial button-content-type="icon" icon="close" color-scheme="transparent" @click="toggleLearn" />
    </section>

    <section v-if="activeTab" class="tabs-content-container">
      <section v-if="tabs.length > 1" class="tabs-container">
        <section v-for="(tab, index) in tabs" :key="`app-learn-tab-${index}`" class="single-tab" :class="[{ active: index === activeTabindex }, `app-learn-tab-${index}`]">
          <button-partial color-scheme="transparent" class="select-tab-button" @click="selectTab(index)">
            <template v-if="tab.state === 0">{{$translate('app-learn-content-loading')}}</template>
            <template v-if="tab.state === 204 || tab.state === 404">{{$translate('app-learn-content-not-found')}}</template>
            <template v-if="tab.title && tab.state === 200">{{tab.title}}</template>
          </button-partial>
          <button-partial button-content-type="icon" size="small" icon="close" color-scheme="transparent" @click="closeTab(index)" />
        </section>
      </section>

      <section class="scroll-container">
        <learn-single-module v-show="activeTab.content" mode="integrated" :content="activeTab.content" @showType="updateShowType" />
        <learn-search-module v-show="activeTabindex === 0" visible="results" mode="integrated" :site="site" :base-search="searchCache" @search="updateSearch" @showType="updateShowType" />
      </section>

    </section>

  </section>
</template>
<script lang="ts">
import {
  ExtComponent, Component,
} from '@/libs/lila-component';

@Component
export default class IntegratedLearnExtension extends ExtComponent {

  searchCache: string = '';

  inited: boolean = false;

  mounted() {

    if (!this.tabs.length) {

      this.$store.commit('AppLearnIntegrated/addTab');
      this.$store.commit('AppLearnIntegrated/activeTab', 0);

    }

    this.$root.$on('integratedLink', (data: {link: string, text?: string}) => {

      if (data.text?.indexOf('#') === 0) {

        this.$store.dispatch('AppLearnIntegrated/addTags', [data.text?.slice(1)]);

      } else {

        this.addOrSelectTab(data.link);

      }

    });

  }

  async updateSearch(search: string, site: number = 1) {

    const searchData = await this.$store.dispatch('AppLearnIntegrated/search', { search, site });

    // if searchData is not set, the search was cached and doesnt need to be updated
    if (searchData) {

      this.$store.commit('AppLearnIntegrated/data', searchData);

    }

  }

  get site() {

    return +this.$route.params.site || 1;

  }

  get showType() {

    return this.$store.state.AppLearnIntegrated.integratedShowType;

  }

  get tabs() {

    return this.$store.state.AppLearnIntegrated.tabs as any[];

  }

  get visibleTabs() {

    return this.$store.state.AppLearnIntegrated.tabs?.slice(1);

  }

  get activeTab() {

    return this.$store.state.AppLearnIntegrated.tabs[this.$store.state.AppLearnIntegrated.activeTab];

  }

  get activeTabindex() {

    return this.$store.state.AppLearnIntegrated.activeTab;

  }

  async updateShowType(update: {type: 'search' | 'content', url?: string}) {

    this.$store.commit('AppLearnIntegrated/integratedShowType', update.type);

    if (update.type === 'content') {

      this.addOrSelectTab(update.url);

    }

  }

  async updateContent(url: string) {

    const single = await this.$store.dispatch('AppLearnIntegrated/single', { link: url });

    this.$store.commit('AppLearnIntegrated/single', single.data);

  }

  addOrSelectTab(url?: string) {

    this.$store.dispatch('AppLearnIntegrated/addOrSelectTab', url);

  }

  closeTab(index: number) {

    this.$store.dispatch('AppLearnIntegrated/closeTab', index);

  }

  selectTab(index: number) {

    if (this.inited === false && this.activeTabindex > 0) {

      this.inited = true;
      return;

    }

    this.$store.commit('AppLearnIntegrated/activeTab', index);
    this.inited = true;


  }


  toggleLearn() {

    this.$store.dispatch('AppLearnIntegrated/toggle', !this.$store.state.AppLearnIntegrated.open);

  }


}
</script>
<style lang="less" scoped>


.integrated-container {
  display: grid;
  grid-auto-rows: max-content 1fr;
  height: 100%;

  .scroll-container {
    display: grid;
    grid-template-rows: 1fr;
    overflow: hidden;
  }

  .integrated-sidebar {

    display: grid;
    grid-template-rows: 60px;
    grid-template-columns: min-content 1fr 35px;
    gap: 20px;

    align-items: center;

    border-bottom: solid 1px @grey1;

    @media @tablet, @desktop {
      .multi(padding, 0, 8);
    }

    .multi(padding, 0, 4);

    h1 {
      font-size: @headline_XS;
    }
  }

  &.hasTabs {

    .tabs-content-container {
      grid-template-rows: min-content 1fr;
    }

  }

  &.hasTabs.resultsVisible {

    gap: 0;

    .tabs-content-container {
      grid-auto-rows: unset;
      gap: 20px;
    }

  }

  &.resultsVisible {

    gap: 20px;

  }

  .tabs-content-container {

    display: grid;
    overflow: hidden;

    .tabs-container {
      display: grid;

      grid-template-columns: 1fr 1fr;
      gap: 5px;
      border-bottom: solid 1px @grey1;
      .multi(padding, 1, 4);

      @media @tablet, @desktop {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin-left: -10px;
        .multi(padding, 1, 8);
      }

      .single-tab {
        display: grid;
        grid-template-columns: calc(100% - 30px) 30px;
        overflow: hidden;
        border: solid 1px @grey2;
        .multi(padding-left, 2);

        button::v-deep {

          &.select-tab-button {
            justify-content: start;
            width: 110px;

            .text {
              overflow: hidden;
              max-width: 110px;
              text-overflow: ellipsis;
            }
          }

        }

        &.active {
          background-color: @grey1;
        }
      }

      .app-learn-tab-0 {
        display: none;
      }
    }
  }

}

</style>
