<template>
  <section class="app-tracker-day-statistics-partial">

    <app-tracker-range-statistics-partial :value="value.statistics">{{date}}</app-tracker-range-statistics-partial>

    <section class="tracker-list-container">

      <table class="tracker-list-table head">
        <thead>
          <tr>
            <td>{{$translate('app-tracker-category')}}</td>
            <td>{{$translate('app-tracker-startEnd')}}</td>
            <td />
          </tr>
        </thead>
      </table>

      <section class="scroll-container">

        <table class="tracker-list-table">
          <tbody>
            <tr v-for="(statisticsDay, index) in value.data" class="single-tracker" :key="`single-tracker-${index}`">
              <td class="category-name id fullHeight action" :class="{ hasName: statisticsDay.name }">
                <span class="category">{{$translate(`app-tracker-category-${statisticsDay.category}`)}}</span>
                <span class="name">{{statisticsDay.name}}</span>
              </td>
              <td class="date">
                <template v-if="day">{{$date(statisticsDay.startTime, 'DD.MM.YYYY')}}</template>
                {{$date(statisticsDay.startTime, 'HH:mm:ss')}} -
                {{$date(statisticsDay.endTime, 'HH:mm:ss')}}
              </td>
              <td>
                <app-tracker-time-partial seconds :value="statisticsDay.currentTime" />
              </td>
            </tr>
          </tbody>
        </table>

      </section>

    </section>

  </section>
</template>
<script lang="ts">
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import { TrackerStatisticsDay } from '@lilaquadrat/studio/lib/interfaces';
import dayjs from 'dayjs';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class AppTrackerDayStatisticsPartial extends PartialEditorComponent<any> {

  @Prop() value: TrackerStatisticsDay;

  @Prop(Boolean) day: boolean;

  get date() {

    return dayjs(this.value.date).format('DD.MM.YYYY');

  }

}
</script>
<style lang="less">


.app-tracker-day-statistics-partial {
  position: absolute;
  top: 15%;

  display: grid;
  grid-template-rows: min-content;
  gap: 20px;
  justify-self: center;
  overflow: hidden;
  width: calc(100% - 40px);

  background-color: @white;
  box-shadow: 0 0 5px -3px @textColor;
  max-width: @moduleWidth_S;
  .multi(padding, 4);

  @media @desktop {
    position: relative;
    top: 0;
    width: 100%;
    box-shadow: none;
    padding: 0;
  }

  .tracker-list-container {

    .scroll-container {
      overflow-y: auto;
      height: 200px;
    }

    .tracker-list-table {

      tbody {

        tr {
          padding: 0;

          &:hover {
            background-color: transparent;

            td {
              background-color: transparent;
              color: @color1;
              .font-bold;
            }
          }

          td {
            height: 40px;
            padding: 0;
          }
        }
      }

      thead {

        tr {
          display: grid;
          grid-template-rows: min-content;
          grid-template-columns: 1fr 1fr 100px;
          gap: 10px;
          padding: 0;

          td {
            display: grid;
            height: 30px;
            padding: 0;
          }

          &:hover {
            background-color: transparent;
          }
        }
      }


    }

    .single-tracker {
      display: grid;
      grid-template-columns: 1fr 1fr 100px;
      gap: 10px;


      td {
        display: grid;
        align-content: center;
        align-items: center;

      }

      .category-name {
        display: grid;
        grid-template-rows: min-content min-content;

        .category {
          color: @color1;
        }

        .name {
          color: @grey;
        }

      }

      .date, .app-tracker-time-partial {
        font-variant-numeric: tabular-nums;
      }

      .app-tracker-time-partial {
        justify-self: end;
      }

    }

  }

}

</style>
