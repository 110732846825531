<template>
  <section class="list-container list-editor" :class="{ text: !type, inline }">
    <h4 v-if="$slots.default"><slot /></h4>

    <ul>

      <template v-for="(element, index) in filteredList">

        <dropzone-partial v-if="index === 0 && !child" :key="`pre-list-element-${index}`" :drag-active="dragActive" :source-index="draggedElement" :index="0" @dropped="handleDropped" />
        <li :key="`list-editor-element-${index}`" :class="{ error: element.hasErrors }">
          <div v-if="!child" class="list-item-options">
            <button v-if="!element.picture" type="button" class="elementDescription" draggable @dragstart="handledrag($event, index)" @dragend="handledrag($event, index)" @click="setChild(element[listTitle] || `element-${index}`), setDataByIndex(index)">
              <template v-if="element.title && !listTitle && !element.cacheId">{{ element.title }}</template>
              <template v-if="!element.title && !listTitle && !element.cacheId">{{ element.text }}</template>
              <template v-if="listTitle && !element.cacheId">{{ $translate(element[listTitle] || translations.noTitle || `element-no-title`, [index]) }}</template>
              <template v-if="element.cacheId">{{ element.cacheId }}</template>
            </button>

            <button v-if="element.picture" type="button" draggable class="elementDescription" :class="{ hasImage: element.thumb }" @dragstart="handledrag($event, index)" @dragend="handledrag($event, index)" @click="setChild(`element-${index}`), setDataByIndex(index)">
              <picture-internal-partial v-if="element.thumb" :picture="{ src: element.thumb }" @click="setChild(`element-${index}`), setDataByIndex(index)" />

              <template v-if="!element.thumb">{{$translate('element')}} {{ index }}</template>
            </button>

            <div v-if="!element.predefined" class="button-container" :class="{ imageMode: element.picture }">
              <button-partial type="button" button-content-type="icon" icon="arrow-up" color-scheme="transparent" size="small" class="mode" @confirmed="move(index, 'up')" />
              <button-partial type="button" button-content-type="icon" icon="arrow-down" color-scheme="transparent" size="small" class="move" @confirmed="move(index, 'down')" />
              <button-partial type="button" button-content-type="icon" icon="close" color-scheme="transparent" size="small" class="remove" @confirmed="remove(index, false)" />
            </div>
          </div>
        </li>

        <dropzone-partial v-if="!child" :key="`post-list-element-${index}`" :drag-active="dragActive" :source-index="draggedElement" :index="index + 1" @dropped="handleDropped" />

      </template>

      <li v-if="child">
        <component :is="`${type}-editor`" v-if="child" v-model="childData" :index="currentIndex" :parent="parent" :additionalData="additionalData" :errors="childErrors" />
      </li>

      <section class="add-element-container">
        <button v-if="!child && !dragActive" type="button" class="add-element colorScheme2" @click="add(0, false, null, true)">{{$translate(translations.add || 'ADD_NEW_ELEMENT')}}</button>
      </section>
      <section v-if="dragActive" class="drag-options">
        <dropzone-partial :drag-active="dragActive" option @drop="clone(draggedElement)">{{$translate('drag-clone')}}</dropzone-partial>
        <dropzone-partial :drag-active="dragActive" option @drop="remove(draggedElement)">{{$translate('drag-delete')}}</dropzone-partial>
      </section>
    </ul>
  </section>
</template>

<script lang="ts">
import {
  Component, Prop, Watch,
} from '@/libs/lila-component';
import hardCopy from '@/mixins/hardCopy';
import { ParsedError } from '@/libs/ActionNotice';
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import ModelsClass from '@/libs/Models.class';
import ListElement from '@/models/ListElement.model';

@Component
export default class ListElementsEditorPartial extends PartialEditorComponent<any> {

  @Prop([Object, Array]) value: any;

  @Prop(Array) parent: any[];

  @Prop(String) type: string;

  @Prop(String) listTitle?: string;

  /** 
    where to match the id to the cache.

    type is the type of content and key is which key of the content is use as id

    ``{type: 'editor', key: 'contentId'}``
   */
  @Prop(Object) cache?: {type: string, key: string};

  @Prop(Boolean) inline: boolean;

  @Prop(String) model: string;

  @Prop(Boolean) noHead: boolean;

  @Prop(Array) content: any;

  @Prop(Object) additionalData: Record<string, unknown>;

  @Prop({ type: Object, default: () => ({}) }) errors: Record<string, ParsedError>;

  @Prop({ type: Object, default: () => ({}) }) translations: Record<'add' | 'noTitle', string>;

  list: any[] = [];

  childData: any = null;

  childErrors: ParsedError = null;

  dragActive: boolean = false;

  draggedElement = null;

  currentIndex = null;

  mouseHold = null;

  @Watch('value')
  watchValueFunction() {

    if(this.cache) this.fillCache();

  }

  @Watch('errors')
  watchErrorsFunction() {

    this.childErrors = this.errors[this.currentIndex];

  }

  @Watch('child')
  watchChildFunction() {

    console.log(this.child);
    if (!this.child) this.currentIndex = null;
    console.log(this.currentIndex);

  }

  get title() {

    if (this.noHead) return '';
    return 'LIST ITEMS';

  }

  get genericCache() {

    return this.$store.state.Cache.genericDataCache;

  }

  // fill the cache with the values
  fillCache() {

    const addToCache = this.value.map((single) => {

      if(single.predefined) return null;
      if(single[this.cache.key]) return single[this.cache.key];
      return null;

    })
    .filter((single) => single);

    this.$store.dispatch('Cache/genericDataCache', {idArray: addToCache, type: this.cache.type});

  }

  beforeMount() {

    if(this.cache) this.fillCache();

  }

  // eslint-disable-next-line class-methods-use-this
  handledrag(event: DragEvent, index: number) {

    if (event.type === 'dragstart') {

      setTimeout(() => {

        console.log('DRAGSTART', event, index);
        this.dragActive = true;
        this.draggedElement = index;

      }, 250);

    }

    if (event.type === 'dragend') {

      console.log('DRAGEND', event, index);
      this.dragActive = false;
      this.draggedElement = undefined;

    }

  }

  handleDropped(dropzone: {index: number}) {

    console.log(this.draggedElement, 'to', dropzone.index);
    this.move(this.draggedElement, undefined, dropzone.index);

  }

  get filteredList() {

    return this.value?.map((single, index) => {

      const newElement = { ...single };

      if (this.errors) {

        if (this.errors[index.toString()]) {

          newElement.hasErrors = true;

        }

      }

      if (this.child && this.child !== `element-${index}`) return null;

      // check if we need to catch the id from the cached element
      if (this.cache && !single.predefined) {

        if(this.genericCache[this.cache.type]) {
          
          // Retrieve the cached item based on the cache key provided in the 'single' object.
          const cachedItem = this.genericCache[this.cache.type][single[this.cache.key]];

          // If the cached item exists, set the 'cacheId' property of 'newElement' to the ID of the cached item.
          // If not, set it to the value of the cache key from the 'single' object.
          newElement.cacheId = cachedItem 
            ? cachedItem.id 
            : single[this.cache.key];

          return newElement;
                  
        }

      }

      newElement.picture = ['picturegroupElement-partial', 'galleryElement-partial'].includes(this.type);
      newElement.thumb = single.picture?.file?.thumb;

      if (this.type === 'linkGroupElement-partial' && single.link.text) newElement.text = single.link.text;
      if (this.type === 'link-partial' && single.link.text) newElement.text = single.text;
      if (this.type === 'pricesElement-partial' && single.textblock.headline) newElement.text = single.textblock.headline;
      if (this.type === 'accordionElement-partial' && single.textblock.headline) newElement.text = single.textblock.headline;

      if (this.type === 'app-lists-emails-element-partial') {

        if(single.contentId) {

          newElement.text = `${this.$translate(`app-lists-emails-audience-${single.audience}`)} - ${this.$translate(`app-lists-participant-state-${single.affectedState}`)}`;
        
}

      }


      if (!newElement.text && single.headline?.length) newElement.text = single.headline;
      if (!newElement.text && single.textblock?.headline?.length) newElement.text = single.textblock.headline;
      if (!newElement.text) newElement.text = `${this.$translate('element')} ${index}`;

      return newElement;

    })
      ?.filter((single) => single);

  }

  setDataByIndex(index: number) {

    this.currentIndex = index;
    this.childData = this.value[index];
    this.childErrors = this.errors[index.toString()];

  }

  removeEmpty() {

    /**
     * TODO
     * build a check to remove empty entries before submit
     */
    console.log(this.value);
    return this.value;

  }

  add(index: number, focus: boolean = true, data: any | undefined, emit: boolean = true) {

    const useIndex = !index ? this.value.length : index + 1;

    this.value.splice(useIndex, 0, ModelsClass.add(data || {}, this.model));

    if (emit) {

      this.$emit('input', this.removeEmpty());

    }

  }

  remove(index: number, focus: boolean = true) {

    if (this.value.length === 1) {

      this.value.splice(0, 1, this.getCleanModel());

      return;

    }
    // if (focus) this.focus(index - 1);

    this.value.splice(index, 1);
    this.$emit('input', this.removeEmpty());

  }

  clone(index: number) {

    const element = hardCopy(this.value[index]);

    this.value.push(element);

    this.$emit('input', this.removeEmpty());

  }

  move(index: number, direction?: string, targetIndex?: number) {

    let to: number;


    if (targetIndex || targetIndex === 0) {

      to = targetIndex;
      /**
       * if the module is moved down inside of the current position (top, content, bottom), the index muste be lowered
       * because the module gets first removed and then inserted again
       */
      if (to > index) to -= 1;

    } else {

      to = direction === 'up' ? index - 1 : index + 1;

    }


    if (to < 0) return;
    if (index > this.value.length - 1) return;

    console.log(index, to, this.value[index].text);

    this.value.splice(to, 0, this.value.splice(index, 1)[0]);

    this.$emit('input', this.removeEmpty());

  }

  /**
   *
   *
   * @returns
   * @memberof ListEditorPartial
   */
  getCleanModel() {

    // // eslint-disable-next-line new-cap
    // const newModel = new this.model();

    // return newModel.setData({}, true, 'add');
    return ModelsClass.add<ListElement>({}, this.model);

  }

}
</script>

<style lang="less">

.list-container.list-editor {

  &.inline {

    button.elementDescription {
      padding: 0;
    }

    li {

      &.error {

        button.elementDescription {
          color: @error;
        }
      }
    }

  }

}

.list-item-options {

  .button-container {

    .button {
      grid-template-columns: 30px;
      width: 30px;
    }

  }
}

.drag-options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 45px;
}
</style>
