<template>
  <article :class="navigationAnimation">
    <section class="content app-editor-renderer">

      <section class="single-renderer">
        <header>
          <h2>{{$translate('app-editor-app-renderer')}}</h2>
          <h4>
            <span class="used-renderer">
              {{$translate(appIsCustom ? 'app-editor-custom-renderer-used' : 'app-editor-base-renderer-used')}}
            </span>
            <span class="last-update">
              {{ $translate(app ? 'app-editor-last-update' : 'app-editor-no-update-yet')}}
              <template v-if="app">
                {{$date(app.date, 'DD.MM.YYYY hh:mm') }}
              </template>
            </span>
          </h4>
        </header>

        <button-group-partial v-if="!appIsCustom">
          <button-partial v-if="checkState.app === null" @confirmed="checkUpdate('app')">{{$translate('app-editor-check-for-update')}}</button-partial>
          <button-partial v-if="checkState.app" save :value="buttonState.app" @save="update('app')">{{$translate('app-editor-update-renderer')}}</button-partial>
          <button-partial v-if="checkState.app === false" disabled>{{$translate('app-editor-no-update-available')}}</button-partial>
        </button-group-partial>

        <description-partial v-if="appIsCustom">
          <p>{{$translate('app-editor-custom-renderer-used-description')}}</p>
          <p>{{$translate('app-editor-reset-renderer-description')}}</p>
        </description-partial>

        <button-group-partial v-if="appIsCustom">
          <button-partial doublecheck save :value="buttonState.app" @save="update('app', true)">{{$translate('app-editor-reset-renderer')}}</button-partial>
        </button-group-partial>

      </section>

      <section class="single-renderer">
        <header>
          <h2>{{$translate('app-editor-server-renderer')}}</h2>
          <h4>
            <span class="used-renderer">
              {{$translate(serverIsCustom ? 'app-editor-custom-renderer-used' : 'app-editor-base-renderer-used')}}
            </span>
            <span class="last-update">
              {{ $translate(server ? 'app-editor-last-update' : 'app-editor-no-update-yet') }}
              <template v-if="app">
                {{$date(server.date, 'DD.MM.YYYY hh:mm') }}
              </template>
            </span>
          </h4>
        </header>

        <button-group-partial v-if="!serverIsCustom">
          <button-partial v-if="checkState.server === null" @confirmed="checkUpdate('server')">{{$translate('app-editor-check-for-update')}}</button-partial>
          <button-partial v-if="checkState.server" save :value="buttonState.server" @save="update('server')" @confirmed="update('server')">{{$translate('app-editor-update-renderer')}}</button-partial>
          <button-partial v-if="checkState.server === false" disabled>{{$translate('app-editor-no-update-available')}}</button-partial>
        </button-group-partial>

        <description-partial v-if="serverIsCustom">
          <p>{{$translate('app-editor-custom-renderer-used-description')}}</p>
        </description-partial>

        <button-group-partial v-if="serverIsCustom">
          <button-partial doublecheck save :value="buttonState.app" @save="update('server', true)">{{$translate('app-editor-reset-renderer')}}</button-partial>
        </button-group-partial>

      </section>

      <notice-partial title="notice" type="notice">
        <p>{{$translate('app-editor-render-update-publish-notice')}}</p>
      </notice-partial>
    </section>

  </article>
</template>

<script lang="ts">
import {
  ExtComponent, Component,
} from '@/libs/lila-component';
import { Route } from 'vue-router';
import { Dictionary } from 'vue-router/types/router';

@Component
export default class AppEditorSettingsBaseScreen extends ExtComponent {

  state: string = '';

  checkState = {
    app: null,
    server: null,
  };

  buttonState = {
    app: 'init',
    server: 'init',
  };

  componentName = 'app-hostingadmin-company-settings-base';

  get app() {

    return this.$store.state.AppEditorData.base.app;

  }

  get server() {

    return this.$store.state.AppEditorData.base.server;

  }

  get serverIsCustom() {

    return this.$store.state.AppEditorData.base.server?.custom;

  }

  get appIsCustom() {

    return this.$store.state.AppEditorData.base.app?.custom;

  }

  checkUpdate(type: string) {

    console.log('check', type);
    this.$store.dispatch(
      'AppEditorData/checkBaseUpdate',
      {
        ...this.$store.getters.companyProject,
        type,
      },
    )
      .then((update) => {

        console.log(update, type);
        this.checkState[type] = update.status === 200;

      });

  }

  update(type: string, reset = false) {

    console.log('check', type);
    this.buttonState[type] = 'init';

    this.$store.dispatch(
      reset ? 'AppEditorData/resetBase' : 'AppEditorData/updateBase',
      {
        ...this.$store.getters.companyProject,
        type,
      },
    )
      .then((update) => {

        console.log(update);
        this.buttonState[type] = 'success';

        this.$store.dispatch(
          'AppEditorData/getBase',
          {
            ...this.$store.getters.companyProject,
            type,
          },
        );

      })
      .catch(() => {

        this.buttonState[type] = 'error';

      });

  }

  // eslint-disable-next-line class-methods-use-this
  asyncData(params: Dictionary<string>, query: Dictionary<string | string[]>, store: any, to: Route) {

    return store.dispatch('AppEditorData/getBase', { company: params.company, project: params.project, type: 'app' })
      .then(() => store.dispatch('AppEditorData/getBase', { company: params.company, project: params.project, type: 'server' }));

  }

}

</script>
<style lang="less" scoped>

.app-editor-renderer {
  display: grid;
  gap: 40px;

  .single-renderer {

    display: grid;
    gap: 20px;

    h2 {
      text-transform: uppercase;
    }

    h4 {
      .font-normal;

      display: grid;
      grid-template-columns: 1fr 1fr;
      font-size: @fontTextSmaller;

      .last-update {
        text-align: right;
      }
    }
  }

}
</style>
