<template>
  <article :class="navigationAnimation" class="app-publish-home-screen">
    <div class="pagenator full">
      <header class="main">

        <section class="stats">
          <h1>{{$translate('app-publish')}}</h1>
          <h3 v-if="content.sites">
            <template v-if="!content.sites.last">{{ content.count }}</template>
            <template v-if="content.sites.last">{{ content.sites.range[0] }} - {{ content.sites.range[1] }} ({{ content.count }})</template>
            / {{ content.all }} {{$translate('total')}}
          </h3>
        </section>


        <quickmenu-partial v-if="$scope(['publishMethods:read'])">
          <router-link :title="$translate('app-publish-methods')" :aria-label="$translate('app-publish-methods')" class="main icon" :to="{ name: 'app-publish-methods-home' }"><icons-partial type="endpoints" size="large" /></router-link>
        </quickmenu-partial>
      </header>

      <section class="sites-filter-container">
        <section class="select">
          <select-partial placeholder="state" :options="stateOptions" allowEmpty :multiple="false" @input="updateSearch($event, 'state')" :value="query.state" />
        </section>
        <sites-partial paramKey="site" :data="content" />
      </section>

      <section class="scroll-container">
        <table class="isSticky">
          <thead-partial :siteCurrent="currentSite" :search="$route.query.search" :sort="sort" :order="order" :content="headContent" />
        </table>

        <table class="data-switch" :class="contentAnimation">
          <tbody>
            <tr v-for="(single, index) in content.data" :key="`data-overview-${index}`">
              <td class="id fullHeight action" :class="{ hasAttribute: single.app || single.model }">
                <router-link
                  :to="{
                    name: 'app-publish-project-single',
                    params: {
                      id: single._id,
                      company: $store.state.Company.company,
                      project: $store.state.Project.project,
                    },
                  }"
                >
                  <div>
                    <div><span v-if="single.app" class="attribute">{{single.app}}</span> <span v-if="single.model" class="attribute">{{single.model}}</span></div>
                    <span class="name">
                      <template v-if="single.pre">{{$translate(`app-publish-${single.pre}`)}}</template>
                      <template v-if="!single.pre && single.publishData">
                        {{single.publishData.id}} {{single.publishData.name}}
                      </template>
                      <template v-if="single.contentGroup">
                        <template v-if="single.contentGroup === 'all'">{{$translate(`app-publish-contentGroup-${single.contentGroup}`)}}</template>
                        <template v-if="single.contentGroup === 'category'">{{$translate(`app-publish-contentGroup-${single.contentGroup}`, [single.category.split(':')[1]])}}</template>
                      </template>
                      <template v-if="single.publishMethod">
                        via {{ single.publishMethod.label }} ({{$translate(`app-publish-method-type-${single.publishMethod.type}`)}})
                      </template>
                      <template v-if="!single.publishMethod">
                        via {{ single.method }}
                      </template>
                    </span>
                  </div>
                </router-link>
              </td>

              <td class="status fullHeight">
                {{$translate(`app-publish-state-${single.state}`)}}
                <div v-if="single.planned">{{$date(single.planned, 'DD.MM.YYYY HH:mm')}}</div>
              </td>

              <td class="status fullHeight">
                {{$date(single.history.created, 'DD.MM.YYYY HH:mm')}}
              </td>

            </tr>
          </tbody>
        </table>
      </section>

    </div>

    <modal-module />

  </article>
</template>
<script lang="ts">
import { CallResponse, DataObject, Publish } from '@lilaquadrat/studio/lib/interfaces';
import { ExtComponent, Component } from '@/libs/lila-component';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';
import hardCopy from '@/mixins/hardCopy';
import cleanObject from '@/mixins/cleanObject';
import MainStoreState from '@/store/mainStoreState.interface';

@Component
export default class AppPublishHomeScreen extends ExtComponent {

  componentName = 'app-publish-project-home';

  headContent: any[] = [];

  order: string = '-1';

  sort: string = 'id';

  query: Record<string, string> = {};

  stateOptions = [
    {
      text: 'app-publish-state-inQueue',
      value: 'inQueue',
    },
    {
      text: 'app-publish-state-planned',
      value: 'planned',
    },
    {
      text: 'app-publish-state-success',
      value: 'success',
    },
    {
      text: 'app-publish-state-failed',
      value: 'failed',
    },
    {
      text: 'app-publish-state-partialSuccess',
      value: 'partialSuccess',
    },
    {
      text: 'app-publish-state-canceled',
      value: 'canceled',
    },
  ];

  constructor() {

    super();

    this.headContent.push({
      key: 'id',
      text: 'id',
      classes: 'id',
    });

    this.headContent.push({
      classes: 'publish',
      text: 'app-publish-state',
    });

    this.headContent.push({
      key: 'created',
      text: 'app-publish-created',
    });

  }


  get content(): DataObject<Publish[]> {

    return this.$store.state.AppPublish.data || undefined;

  }

  get currentSite() {

    return this.$route.params.site ? +this.$route.params.site : null;

  }

  updateSearch(value: string, type: string) {

    const newQuery = this.query;

    newQuery[type] = value;
    cleanObject(newQuery);

    this.query = newQuery;

    this.$router.replace({ params: { site: '1' }, query: this.query });

  }

  mounted() {

    this.query = hardCopy(this.$route.query);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store
      .dispatch('AppPublish/get', {
        query,
        settings: {
          project: params.project,
          company: params.company,
          site: params.site,
        },
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: CallResponse<Publish>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppPublish/data', preloadedData.r);
    return Promise.resolve();

  }

}
</script>
<style lang="less">


.app-publish-home-screen {

  table {

    tr {
      grid-template-columns: 1fr 100px 100px;
    }
  }

  .sites-filter-container {

    display: grid;
    grid-template-columns: 1fr 1fr;
    .multi(padding-top, 2);
    .multi(padding-bottom, 4);
    .basePadding;

    .sites-container {
      justify-self: end;
    }

    .select {
      display: grid;
      grid-template-columns: 150px 1fr;
    }
  }
}
</style>

