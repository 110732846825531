<template>
  <section class="quellcode-module-editor open editor" :class="{ changed: safeContent.changed }">
    <module-editor-header-partial v-model="content" :position="position" :index="index" :type="type" @move="move($event)" />
    <crumbs-partial :type="safeContent.type" @clearCrumbs="unsetCurrentChild" />

    <section :class="{ optionsVisible: !child }" class="editor-module-container">
      <moduleList-partial v-if="!child" class="module-editor-navigation">
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('textblock')">{{$translate('textblock')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('code')">{{$translate('Code')}}</button-partial>
        </li>
      </moduleList-partial>

      <textblock-partial-editor v-if="child === 'textblock'" :content="content" />
      <editor-content-group-partial v-if="child === 'code'" :key="'quellcode-code'">
        <textarea-partial v-if="active('code')" v-model="safeContent.code" @keyup="$emit('keyup', $event)">{{$translate('code')}}</textarea-partial>
      </editor-content-group-partial>

      <module-options-partial
        v-if="child === 'options' || !child"
        v-model="safeContent"
        :available-variants="availableVariants"
        :selected-variants="safeContent.variant"
        @setChild="setChild('options', true)"
        @unsetChild="setChild('options')"
        @remove="remove()"
        @clone="clone()"
      />

    </section>
  </section>
</template>

<script lang="ts">
import { Component, ModuleEditorComponent, Prop } from '@/libs/ModuleEditorComponent';
import QuellcodeModule from '../models/QuellcodeModule.model';


@Component
// eslint-disable-next-line global-require
export default class QuellcodeModuleEditor extends ModuleEditorComponent<QuellcodeModule> {

  @Prop(Array) inactive: string[];

  model = 'quellecode-module';

  active(value: any) {

    if (!this.inactive) return true;
    return this.inactive.indexOf(value) === -1;

  }


}

</script>
