<template>
  <section class="module-more-options" :class="{ childActive: child }">

    <moduleList-partial v-if="!child" class="module-editor-navigation">
      <li>
        <button-partial :variant="['moduleNavigationVariant']" @confirmed="setParentChild('index')">{{$translate('index')}}</button-partial>
      </li>
      <li>
        <button-partial :variant="['moduleNavigationVariant']" @confirmed="setParentChild('position')">{{$translate('position')}}</button-partial>
      </li>
      <li>
        <button-partial :disabled="!layoutMode" :variant="['moduleNavigationVariant']" @confirmed="setParentChild('layout')">{{$translate('app-editor-layout-position')}}</button-partial>
      </li>
      <li>
        <button-partial :disabled="noExport" :variant="['moduleNavigationVariant']" @confirmed="setParentChild('export')">{{$translate('export')}}</button-partial>
      </li>
    </moduleList-partial>


    <section v-if="child === 'position'" class="editor-content-container">

      <select-partial
        v-model="value.position"
        placeholder="NO_VARIANTS_CHOOSEN"
        :multiple="false"
        :options="positions"
        @input="$store.commit('AppEditorData/active', false);">{{$translate('position')}}</select-partial>

    </section>

    <section v-if="child === 'index'" class="editor-content-container">

      <input-partial v-model="value.index.title" placeholder="title">{{$translate('title')}}</input-partial>
      <input-partial v-model="value.index.anchor" placeholder="anchor">{{$translate('anchor')}}</input-partial>

    </section>

    <section v-if="layoutMode && child === 'layout'" class="editor-content-container" title="layout">

      <select-partial
        v-model="value.layout"
        placeholder="app-editor-layout-no-position"
        :multiple="false"
        :options="layoutPosition"
        :allow-empty="true">{{$translate('app-editor-layout-position')}}</select-partial>

    </section>

    <section v-if="!noExport && child === 'export'" class="editor-content-container">

      <textarea-partial readonly :value="exportData" description="copy the content and import it in your target data" />

    </section>

    <slot />
  </section>
</template>
<script lang="ts">
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import ArrayTools from '@/libs/lila-array';
import { ModuleGeneric } from '@lilaquadrat/studio/lib/interfaces';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ModuleOptionsPartial extends PartialEditorComponent<ModuleGeneric> {

  @Prop(Object) value: ModuleGeneric;

  @Prop(Boolean) noExport: boolean;

  positions = [
    { value: 'top', text: 'POSITION_TOP' },
    { value: 'content', text: 'POSITION_CONTENT' },
    { value: 'bottom', text: 'POSITION_BOTTOM' },
  ];

  layoutPosition = [
    { value: 'first', text: 'app-editor-layout-position-first' },
    { value: 'last', text: 'app-editor-layout-position-last' },
  ];


  get exportData(): string {

    return JSON.stringify([this.value]);

  }

  get layoutMode(): boolean {

    return this.$store.state.AppEditorData.content.layout;

  }

  get variants() {

    return this.value.variant.map((single) => this.availableVariants?.find((variant) => single === variant.value)?.text || single).join(', ');

  }

  get variantsAvailable() {

    return !!this.availableVariants?.length;

  }

  setParentChild(type: string) {

    this.setChild(type);
    this.$emit('setChild', type);

  }

  isSelected(key: string) {

    return this.value?.variant.includes(key);

  }

  toggleVariant(key: string) {


    if (this.value?.variant.includes(key)) {

      ArrayTools.remove(this.value.variant.indexOf(key), this.value.variant);

    } else {

      this.value.variant.push(key);

    }


  }

}
</script>
<style lang="less" scoped>


.module-options::v-deep {
  display: grid;
  gap: 20px;

  .multi(padding, 4, 0, 0, 0);

  &.childActive {
    padding: 0;
  }

  h4, .variants, .position, .export {
    .multi(padding, 0, 4);
  }

  h4 {
    .font-normal;
    text-transform: uppercase;
    color: @textColor;
  }

  .module-editor-options {
    display: grid;
    grid-template-columns: 1fr 1fr;

    button {
      align-content: center;
      align-items: center;
      justify-content: center;
      justify-items: center;
      height: 45px;
    }
  }

  .variants {
    display: grid;
    gap: 20px;
  }

}
</style>
