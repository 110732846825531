<template>
  <article :class="navigationAnimation">
    <form class="content" @submit="save">
      <fieldset>
        <legend><span class="text">{{$translate('app-hostingadmin-settings-baseDomain')}} </span></legend>

        <input-partial v-model="model.baseDomain" placeholder="url">{{$translate('app-hostingadmin-settings-baseDomain')}}</input-partial>
      </fieldset>

      <fieldset>

        <list-partial-editor v-model="model.ipAdresses" no-head>ip addresses</list-partial-editor>

      </fieldset>

      <button-group-partial>
        <button-partial v-model="state" type="submit" save>{{$translate('Save Changes')}}</button-partial>
      </button-group-partial>
    </form>

  </article>
</template>

<script lang="ts">
import {
  ExtComponent, Component,
} from '@/libs/lila-component';
import ModelsClass from '@/libs/Models.class';
import { SDKResponse } from '@/libs/StudioSDK';
import { HostingSettings } from '@lilaquadrat/studio/interfaces';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppEditorSettingsScreen extends ExtComponent {

  content: any = {};

  model: Pick<HostingSettings, 'baseDomain' | 'ipAdresses'> = null;

  state: string = '';

  componentName = 'app-hostingadmin-company-settings-main';

  beforeMount() {

    // this.model.setData(this.$store.state.AppEditorMain.settings, true, 'add');
    this.model = ModelsClass.add(this.$store.state.AppHostingAdmin.settings, 'app-hostingadmin-settings');
    console.log('MOUNTED SETTINGS', this.$store.state.AppEditorMain.settings);

  }

  save($event: MouseEvent) {

    $event.preventDefault();
    this.state = '';

    const model = ModelsClass.save(this.model, 'app-hostingadmin-settings');

    console.log(model);
    

    this.$store
      .dispatch('AppHostingAdmin/updateSettings', this.model)
      .then(() => {

        this.state = 'success';
        return true;

      })
      .catch(() => {

        this.state = 'error';

      });

  }

    // eslint-disable-next-line class-methods-use-this
    preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store
      .dispatch('AppHostingAdmin/getSettings');

    }

    // eslint-disable-next-line class-methods-use-this
    preloadDataPost(preloadedData: SDKResponse<HostingSettings>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppHostingAdmin/settings', preloadedData.data);
    return Promise.resolve();

    }

}

</script>
