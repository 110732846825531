import InstallableApp from '@/interfaces/InstallableApp.interface';
import routes from './routes';
import translations from './translations/translations';

import './models/customer-company.model';
import './models/customer-person.model';

const app: InstallableApp = {
  name: 'customers',
  publisher: 'lilaquadrat',
  translations,
  routes,
  store: 'AppCustomers',
};

export default app;
