<template>
  <article class="app-tracker-overview-screen">
    <div class="pagenator full">
      <header class="main">

        <section class="stats">
          <h1>{{$translate('app-tracker')}}</h1>
        </section>

        <quickmenu-partial v-if="$scope(['publishMethods:read'])">
          <router-link :title="$translate('app-tracker-add-link')" :aria-label="$translate('app-tracker-add-link')" class="icon" :to="{ name: 'app-tracker-project-add', query: $route.query }"><icons-partial type="add" size="large" /></router-link>
          <router-link :title="$translate('app-tracker-add-multiple-link')" :aria-label="$translate('app-tracker-add-link')" class="icon" :to="{ name: 'app-tracker-project-add-multiple', query: $route.query }"><icons-partial type="add" size="large" /></router-link>
          <router-link :title="$translate('app-tracker-history-link')" :aria-label="$translate('app-tracker-history-link')" class="icon" :to="{ name: 'app-tracker-project-home', query: $route.query }"><icons-partial type="history" size="large" /></router-link>
          <router-link :title="$translate('app-tracker-statistics-link')" :aria-label="$translate('app-tracker-statistics-link')" class="icon" :to="{ name: 'app-tracker-project-statistics', query: $route.query }"><icons-partial type="statistics" size="large" /></router-link>
        </quickmenu-partial>

        <section v-if="scope(['tracker:admin'])" class="admin-container">
          <search-partial @callback="search" @input="update" callback :multiple="false" :possibleEntries="possibleEntries" :value="user">{{$translate('user last name')}}</search-partial>
        </section>
      </header>

      <router-view class="screen main" :key="`app-tracker-children-${$route.params.link}`" />
    </div>

  </article>
</template>
<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import StudioSDK from '@/libs/StudioSDK';
import {
  Tracker,
} from '@lilaquadrat/studio/lib/interfaces';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import MainStore, { state } from '@/store/main.store';

dayjs.extend(duration);

@Component
export default class AppTrackerHomeScreen extends ExtComponent {

  componentName = 'app-tracker-project-home';

  possibleEntries: string[] = [];

  async mounted() {

    this.$store.dispatch('AppTracker/sync');

    const tracker = await this.$store.dispatch('AppTracker/getTrackersLocal');

    this.$store.commit('AppTracker/addMultipleLocal', tracker);

    if (this.$route.query.user) this.loadUser(this.$route.query.user as string);

  }

  createTracker(mode: Tracker['mode'], category: Tracker['category']) {

    this.$store.dispatch('AppTracker/createLocal', { mode, category, ...this.$store.getters.companyProject });

  }

  get viewportSize() {

    return this.$store.state.media;

  }

  get worktimeTracker() {

    return this.trackers?.find((single) => single.category === 'worktime');

  }

  get callTracker() {

    return this.trackers?.find((single) => single.category === 'call');

  }

  get trackers(): Tracker[] {

    return this.$store.state.AppTracker.trackers;

  }

  get user(): string[] {

    return this.$route.query.user
      ? [this.$route.query.user as string]
      : [];

  }

  update(user) {

    console.log(user);

    this.$router.push({ query: { user: user[0] } });

  }

  async loadUser(user: string) {

    const sdk = new StudioSDK('tracker', {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    });
    const userResponse = await sdk.team.single(user);

    this.updateEntries(userResponse.data ? [{ user: userResponse.data }] : []);

  }

  updateEntries(users: any[]) {

    const entries = [];

    users.forEach((single) => {

      entries.push({ name: `${single.user.lastname}, ${single.user.prename}`, value: single.user.id });

    });

    this.possibleEntries = entries;

  }

  async search($event) {


    const sdk = new StudioSDK('tracker', {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    });

    console.log($event);

    const users = await sdk.team.find($event);

    this.updateEntries(users.data.data);


  }

}
</script>
<style lang="less" scoped>


.app-tracker-overview-screen {

  display: grid;
  gap: 10px;

  .pagenator.full {
    display: grid;
    gap: 30px;

    @media @tablet, @desktop {
      gap: 50px;
    }

    .stats {
      display: grid;
      gap: 10px;
    }

    header.main {
      gap: 5px 0;
      padding-bottom: 0;

      .admin-container {
        grid-column-start: 1;
        grid-column-end: 3;
        justify-self: end;

        @media @tablet, @desktop {
          max-width: 300px;
        }
      }
    }
  }

  table::v-deep {

    tr {
      grid-template-columns: 1fr 1fr 1fr;

      @media @tablet, @desktop {
        grid-template-columns: 1fr 275px 150px;
      }
    }
  }

  .tracker-container, .history-preview-container {
    .basePadding;
    display: grid;
    gap: 10px;
  }

  .tracker-container {

    display: grid;
    grid-template-rows: 41px 41px;
    gap: 20px;
    align-items: center;
    justify-items: start;

    width: 100%;

    @media @tablet, @desktop {
      grid-template-rows: 260px;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      justify-self: center;
      max-width: @desktopWidth;
    }

    .button {
      align-content: center;

      .trans(all);

      &:hover {
        border-color: @color1;
      }
    }
  }


}
</style>
