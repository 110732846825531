import { Call } from '@/libs/lila-call';
import { Auth } from '@/libs/lila-auth';
import MainStore, { state } from '@/store/main.store';
import GenericStore from '@/interfaces/GenericStore.interface';
import { Company } from '@lilaquadrat/studio/lib/interfaces';
import StudioSDK from '@/libs/StudioSDK';
import CompanyStoreState from './CompanyStoreState.interface';

const CompanyStore: GenericStore<CompanyStoreState> = {

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },


  namespaced: true,
  strict: true,

  state: {
    settings: {},
    company: null,
    permissions: null,
    lock: '',
  },

  mutations: {

    settings(localState, settings) {

      localState.settings = settings;

    },

    company(localState, project: string) {

      localState.company = project;

    },

    permissions(localState, permission) {

      localState.permissions = permission;

    },

    lock(localState, lock: string) {

      localState.lock = lock;

    },

    unlock(localState) {

      localState.lock = null;

    },

  },

  actions: {
    create(store, data) {

      const call = new Call('system', MainStore.getters.endpoints);

      return call.post('api', `/companies/${data.partner}/create`, data, Auth.getAuthHeader());

    },

    list(store, params: Record<string, string>) {

      const call = new Call('system', MainStore.getters.endpoints);


      return call.get('api', `/companies/admin/${params.company}/list`, { search: params.search }, Auth.getAuthHeader());

    },

    getSettings(store, company: string) {

      const sdk = new StudioSDK('companies', CompanyStore.sdkOptions());

      return sdk.companies.single(company);

    },

    saveSettings(store, data: {companyId: string, data: Company}) {

      const sdk = new StudioSDK('companies', CompanyStore.sdkOptions());

      return sdk.companies.update(data.companyId, data.data);

    },

    saveSettingsAdmin(store, data: {companyId: string, companyAdminId: string, data: Company}) {

      const sdk = new StudioSDK('companies', CompanyStore.sdkOptions());

      return sdk.companies.admin.update(data.companyId, data.companyAdminId, data.data);

    },

    validate(store, data: Company) {

      if (!data.name || !data.streetNumber || !data.zipcode || !data.city || !data.country) {

        store.commit('lock', 'app-settings-company-home');
        return false;

      }

      store.commit('unlock');


      return true;

    },

    unset(store) {

      store.commit('settings', null);
      store.commit('company', null);
      store.commit('permissions', null);
      store.commit('unlock');


    },
  },

};

export default CompanyStore;
