<template>
  <article :class="navigationAnimation" class="app-appstore-home-screen">
    <div class="pagenator full">
      <header v-if="content" class="main">
        <section class="stats">
          <h1>{{$translate('apps')}}</h1>
          <h3>
            <template v-if="!content.sites.last">{{ content.count }}</template>
            <template v-if="content.sites.last">{{ content.sites.range[0] }} - {{ content.sites.range[1] }} ({{ content.count }})</template>
            / {{ content.all }} {{$translate('total')}}
          </h3>
        </section>

        <quickmenu-partial>
          <router-link v-if="isAdmin && admin" class="button base colorScheme1" :to="{ name: 'app-appstore-company-single-add' }">{{$translate('APP_ADD')}}</router-link>
        </quickmenu-partial>
      </header>

      <filter-partial :class="{ admin, adminCompany }">
        <section class="group filter">
          <input-partial debounce="500" placeholder="search" :value="search" @input="update($event, 'search')" />
          <select-partial v-if="!admin" mobile placeholder="filter" :options="filterOptions" inline allow-empty :value="query.filter" @input="update($event, 'filter')" />
          <checkbox-partial v-if="adminCompany && isCompany" v-model="admin" no-indicator>ADMIN</checkbox-partial>
        </section>
        <section class="group sites">
          <button-partial class="replay" icon="replay" color-scheme="transparent" button-content-type="icon" @click="reload" />
          <sites-partial :data="content" />
        </section>
      </filter-partial>

      <app-drawer-module padding>

        <app-group-module v-if="companyApps && isCompany">

          <template #title>
            <notice-partial padding type='notice' title="app-app-apps-for-company-title">{{$translate('app-app-apps-for-company-description')}}</notice-partial>
          </template>

          <app-link-partial v-for="(app, index) in companyApps"  :key="`single-app-${index}`" status v-bind="appLink(app)" />

        </app-group-module>

        <app-group-module v-if="projectApps">

          <template v-if="isCompany" #title>
            <notice-partial padding type='notice' title="app-app-apps-for-project-title">{{$translate('app-app-apps-for-project-description')}}</notice-partial>
          </template>

          <app-link-partial v-for="(app, index) in projectApps" :key="`single-app-${index}`" status v-bind="appLink(app)" />

        </app-group-module>

      </app-drawer-module>
    </div>

    <modal-module />

  </article>
</template>
<script lang="ts">
import { ExtComponent, Component, Watch } from '@/libs/lila-component';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import {
  DataObject, App, ListOfModels,
} from '@lilaquadrat/studio/lib/interfaces';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppAppstoreHomeScreen extends ExtComponent {

  componentName = ['app-appstore-company-home', 'app-appstore-project-home'];

  editRouteName: string = '';

  filter: string[] = [];

  search: string = '';

  filterOptions = [
    {
      text: 'app-app-active',
      value: 'active',
    },
  ];


  @Watch('$route')
  updateRoute(): void {

    this.setEditRoute();
    this.updateSort();

  }

  updateSort() {

    if (this.$route.query.search) this.search = this.$route.query.search as string;
    if (this.$route.query.filter) this.filter = Array.isArray(this.$route.query.filter) ? this.$route.query.filter : [this.$route.query.filter];

  }


  beforeMount() {

    this.setEditRoute();
    this.updateSort();

    if (this.$store.state.Company.settings.attributes?.includes('previewPublic')) {

      this.filterOptions.push({
        text: 'app-app-preview',
        value: 'previewPublic',
      });

    }

    if (this.$store.state.Company.settings.attributes?.includes('previewInternal')) {

      this.filterOptions.push({
        text: 'app-app-internal',
        value: 'previewInternal',
      });

    }

    if (this.$store.state.Company.settings.attributes?.includes('admin') && this.isCompany) {

      this.filterOptions.push({
        text: 'app-app-admin',
        value: 'admin',
      });

    }

  }

  appLink(app: App) {

    return { ...app, to: { name: this.editRouteName, params: { ...this.$route.params, app: app.id } } };

  }

  get query() {

    const query: Record<string, string | string[]> = {};

    if (this.search) {

      query.search = this.search;

    }

    if (this.filter) {

      query.filter = this.filter as string[];

    }

    if (this.admin) {

      query.admin = 'true';
      query.filter = [];

    }

    return query;

  }

  get adminCompany() {

    return this.$store.state.Company.settings.attributes?.includes('admin');

  }

  get admin() {

    return this.$store.state.Appstore.admin;

  }

  set admin(active: boolean) {

    this.$store.commit('Appstore/admin', active);

    this.fetchData(
      this.$store,
      this.$store.state.Company.company,
      this.$store.state.Project.project,
      this.query,
      this.$store.state.Company.settings.attributes?.includes('admin') && this.$store.state.Appstore.admin,
    )
      .then((data) => {

        this.$store.commit('Appstore/data', data.data);

      });

  }


  get isPartner() {

    const { attributes } = this.$store.state.Company.settings;

    return attributes?.includes('partner');

  }

  get isAdmin() {

    const { scope } = this.$store.state.Company.permissions;
    const { attributes } = this.$store.state.Company.settings;

    return attributes?.includes('admin') && scope?.includes('app:create');

  }

  get isCompany() {

    return this.$store.state.Company.company && !this.$store.state.Project.project;

  }

  get content(): DataObject<App> {

    return this.$store.state.Appstore.data;

  }

  get companyApps(): App[] {

    const content: DataObject<App[]> = this.$store.state.Appstore.data;

    return content?.data.filter((single) => single.companyAvailable);

  }

  get projectApps(): App[] {

    const content: DataObject<App[]> = this.$store.state.Appstore.data;

    return content?.data.filter((single) => single.projectAvailable);

  }

  setEditRoute() {

    if (this.$route.name === 'app-appstore-company-home') {

      this.editRouteName = 'app-appstore-company-single';

    }

    if (this.$route.name === 'app-appstore-project-home') {

      this.editRouteName = 'app-appstore-project-single';

    }

  }

  update(event: string[] | string | boolean, type: 'search' | 'filter' | 'admin') {

    if (type === 'search') {

      this.search = event as string;

    }

    if (type === 'filter') {

      this.filter = event as string[];

    }

    this.$router.replace({ query: this.query });

  }

  async reload() {

    StudioSDK.flushCache('apps', 'list');
    StudioSDK.flushCache('adminApps', 'list');

    const isAdmin = this.$store.state.Company.settings.attributes?.includes('admin') && this.$store.state.Appstore.admin;
    const data = await this.fetchData(this.$store, +this.$route.params.site, this.query, {company: this.$store.state.Company.company, project: this.$store.state.Project.project, admin: isAdmin});

    this.$store.commit('Appstore/data', data.data);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    const isAdmin = store.state.Company.settings.attributes?.includes('admin') && store.state.Appstore.admin;

    console.log(isAdmin, store.state.Appstore.admin);

    return this.fetchData(store, (+params.site || 1), query, {company: store.state.Company.company, project: store.state.Project.project, admin: isAdmin});

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<ListOfModels<App>>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('Appstore/data', preloadedData.data);
    return Promise.resolve();

  }

  // eslint-disable-next-line class-methods-use-this
  fetchData(store, site: number | undefined, query: Dictionary<string | string[]>, options: {admin: boolean, company: string, project: string}) {

    if(query?.filter && !Array.isArray(query?.filter)) query.filter = [query.filter];

    return store.dispatch('Appstore/get', { settings: { ...options, site }, query });

  }

}
</script>
<style lang="less">

.app-appstore-home-screen {

  .filter-partial {
    display: grid;
    grid-template-columns: max-content 1fr;

    width: 100%;

    .group {
      display: grid;

      &.filter, &.sites {
        grid-template-columns: max-content max-content;
      }

      &.sites {
        justify-self: end;
      }

    }

    &.adminCompany {

      .group {

        &.filter {
          grid-template-columns: max-content max-content max-content;

        }

      }

    }

  }
}

</style>

