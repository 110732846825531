import InstallableApp from '@/interfaces/InstallableApp.interface';
import routes from './routes';
import translations from './translations/translations';

import './models/Tracker.model';

const app: InstallableApp = {
  name: 'tracker',
  publisher: 'lilaquadrat',
  translations,
  routes,
  store: 'AppTracker',
  overlay: [
    'overlay-tracker-ext'
  ]
};

export default app;
