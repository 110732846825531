<template>
  <article class="structure-single-screen" :class="navigationAnimation">
    <section class="content-container">
      <section class="scroll-container">
        <form class="content" @submit="save">
          <fieldset>
            <legend><span class="text">{{ $translate('structures-type-title') }}</span></legend>

            <button-group-partial left>
              <label class="radio">
                <input :disabled="isEdit" v-model="type" value="string" name="type" type="radio" />
                {{ $translate('structures-type-string') }}
              </label>
              <label class="radio">
                <input :disabled="isEdit" v-model="type" value="text" name="type" type="radio" />
                {{ $translate('structures-type-text') }}
              </label>
              <label class="radio">
                <input :disabled="isEdit" v-model="type" value="number" name="type" type="radio" />
                {{ $translate('structures-type-number') }}
              </label>
              <label class="radio">
                <input :disabled="isEdit" v-model="type" value="select" name="type" type="radio" />
                {{ $translate('structures-type-select') }}
              </label>
              <label class="radio">
                <input :disabled="isEdit" v-model="type" value="boolean" name="type" type="radio" />
                {{ $translate('structures-type-boolean') }}
              </label>
            </button-group-partial>

            <notice-partial v-if="errorsObject.type" type="error">
              {{ $translate('structures-type-select-error') }}
            </notice-partial>
          </fieldset>

          <fieldset>
            <input-partial v-model="question" required :label="$translate('structures-question')" :error="errorsObject.question" :placeholder="$translate('structures-question')">{{ $translate('structures-question') }}</input-partial>
            <textarea-partial v-model="description" :label="$translate('structures-description')" :error="errorsObject.description" :placeholder="$translate('structures-description')">{{ $translate('structures-description') }}</textarea-partial>
          </fieldset>

          <fieldset v-if="type === 'select'">
            <legend><span class="text">{{ $translate('structures-select-title') }}</span></legend>
            <checkbox-partial v-model="model.multiple" :label="$translate('structures-multiple')" :error="errorsObject.multiple" :placeholder="$translate('structures-multiple')">{{ $translate('structures-multiple') }}</checkbox-partial>
            <list-elements-partial-editor inline v-model="model.options" listTitle="text" type="structuresOptionsElement-partial" model="structure-options" partial />
          </fieldset>

          <fieldset v-if="type === 'string' || type === 'text'">
            <legend><span class="text">{{ $translate('structures-string-text-title') }}</span></legend>
            <section class="min-max-container">
              <input-partial v-model="model.min" type="number" :placeholder="$translate('structures-min-length-text')">{{ $translate('structures-min-length-text') }}</input-partial>
              <input-partial v-model="model.max" type="number" :placeholder="$translate('structures-max-length-text')">{{ $translate('structures-max-length-text') }}</input-partial>
            </section>
            <notice-partial type="notice">
              {{ $translate('structures-min-max-string-text') }}
            </notice-partial>
          </fieldset>

          <fieldset v-if="type === 'number'">
            <legend><span class="text">{{ $translate('structures-number-title') }}</span></legend>
            <section class="min-max-container">
              <input-partial v-model="model.min" type="number" :placeholder="$translate('structures-min-length-text')">{{ $translate('structures-min-length-text') }}</input-partial>
              <input-partial v-model="model.max" type="number" :placeholder="$translate('structures-max-length-text')">{{ $translate('structures-max-length-text') }}</input-partial>
            </section>
          </fieldset>

          <action-notice-partial :state="state" :translation-pre="translationPre" :errors="errors" @update="updateErrors($event)">
            <button-partial v-if="isEdit && $scope([`${$route.meta.model}:structuresDelete`])" type="button" class="base colorScheme2" doublecheck @confirmed="remove">{{$translate('structures-remove-button')}}</button-partial>
            <button-partial v-if="(isEdit && $scope([`${$route.meta.model}:structuresEdit`])) || (!isEdit && $scope([`${$route.meta.model}:structuresCreate`]))" v-model="state" :disabled="!isReady" type="submit" save>
              <template v-if="!isEdit">{{$translate('structures-add-button')}}</template>
              <template v-if="isEdit">{{$translate('structures-edit-button')}}</template>
            </button-partial>
          </action-notice-partial>

        </form>
      </section>
    </section>
  </article>
</template>

<script lang="ts">
import StructureMinMax from '@/interfaces/StructureMinMax.interface';
import { ErrorsObject } from '@/libs/ActionNotice';
import { ExtComponent, Component, Watch } from '@/libs/lila-component';
import ModelsClass from '@/libs/Models.class';
import { SDKResponse } from '@/libs/StudioSDK';
import MainStoreState from '@/store/mainStoreState.interface';
import { DataObject, Structure } from '@lilaquadrat/interfaces';
import { ErrorObject } from 'ajv';
import { Route } from 'vue-router';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class StructureAddScreen extends ExtComponent {

  model = null;

  state = '';

  componentName = ['app-customers-company-structures-edit', 'app-customers-company-structures-add'];

  activeApps = [];

  translationPre = 'structures';

  errors: { message?: string, errors?: ErrorObject[] } = {};

  errorsObject: ErrorsObject = {};

  realType: string = '';

  question: string = '';

  description: string = '';

  models: {
    string: StructureMinMax
    text: StructureMinMax
    number: StructureMinMax
    select: StructureMinMax
    boolean: StructureMinMax
  } = {
    string: null,
    text: null,
    number: null,
    select: null,
    boolean: null
  };


  @Watch('$route')
  watchRouteFunction() {

    this.setModel();

  }

  get isEdit() {

    return this.$route.params.id !== undefined;

  }

  get content(): Structure {

    return this.$store.state.Structures.single;

  }

  get type() {

    return this.realType;

  }

  set type(type: string) {

    this.realType = type;
    this.model = this.models[type];

  }

  get isReady() {

    return this.question.length !== 0 && this.type.length !== 0;

  }

  mounted() {

    this.setModel();

  }


  updateErrors(errorsObject: ErrorsObject) {

    this.errorsObject = errorsObject;

  }

  async save($event: MouseEvent): Promise<void> {

    $event.preventDefault();
    console.log('save', this.model);

    let action: string;
    let data: Structure | {
      internalId: string,
      data: Structure
    };
    const structureBase = ModelsClass.save(
      { 
        type: this.type, 
        model: this.$route.meta.model, 
        question: this.question, 
        description: this.description
      }, 
      'structure-base'
    );

    if(this.isEdit) {

      const updateData = ModelsClass.save<Structure>(this.model, `structure-${this.type}`);

      data = {internalId: this.$route.params.id, data: {...structureBase, ...updateData}};
      action = 'Structures/update';

      delete data.data.id;
      delete data.data.type;
      delete data.data.model;

    } else {

      data = ModelsClass.save<Structure>(this.model, `structure-${this.type}`);
      action = 'Structures/add';

    }

    try {

      const response = await this.$store.dispatch(action,  {...data, ...structureBase});

      this.state = 'success';
      this.errors = {};

      if (!this.isEdit) {

        this.$router.push({ name: `${this.$route.meta.linkBase}-structures-edit`, params: { id: response.data._id } });

      }
      
    } catch (e) {

      console.error(e);
      this.state = 'error';
      this.errors = e.response.data;
      
    }


  }

  remove() {

    this.$store
      .dispatch(
        'Structures/remove',
        this.$route.params.id,
      )
      .then(() => {

        this.state = 'success';
        return true;

      })
      .catch((e) => {

        console.error(e);
        this.state = 'error';

      });

  }

  setModel() {

    if (this.isEdit) {

      this.type = this.content.type;
      this.question = this.content.question;
      this.description = this.content.description;

      this.model = ModelsClass.add<Structure>(this.content, `structure-${this.type}`);
      
    } else {

      this.models.string = ModelsClass.add<StructureMinMax>({}, 'structure-string');
      this.models.text = ModelsClass.add<StructureMinMax>({}, 'structure-text');
      this.models.number = ModelsClass.add<StructureMinMax>({}, 'structure-number');
      this.models.select = ModelsClass.add<StructureMinMax>({}, 'structure-select');
      this.models.boolean = ModelsClass.add<StructureMinMax>({}, 'structure-boolean');

    }

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {


    if(!params.id) return Promise.resolve();

    return store
      .dispatch('Structures/single', params.id);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<DataObject<Structure>>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    if(!preloadedData) return Promise.resolve();
    store.commit('Structures/single', preloadedData.data);
    return Promise.resolve();

  }

}
</script>
<style lang="less" scoped>
.structure-single-screen {

  .min-max-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
}
</style>