import Models, { ModelDeclaration } from '@/libs/Models.class';

export default interface ListElement {
  id: string
  variant: string[]
}

const declaration: ModelDeclaration<ListElement> = {
  id: { type: 'string' },
  variant: { type: 'array', contains: { type: 'string' } },
};

Models.register('list-element', declaration);
