<template>
  <article :class="navigationAnimation">
    <side-screen-header-partial>{{ $translate('structures-overview') }}</side-screen-header-partial>
    <router-view class="content-container" :key="`structure-overview-${$route.params.link}`" />
  </article>
</template>

<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';

@Component
export default class StructureOverviewScreen extends ExtComponent {

  componentName = 'structures-overview';

}
</script>