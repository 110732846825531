// import PartialModel from '@/libs/lila-model';

// export default class AgreementsModel extends PartialModel {

//   uuid = false;

//   required = false;

//   text = '';

//   contentId = '';

// }


import Models, { ModelDeclaration, ModelHooks } from '@/libs/Models.class';

export default interface AppListsAgreements {
  text: string
  contentId: string
  required: boolean
  predefined?: boolean
  predefinedDescription?: string
}

const declaration: ModelDeclaration<AppListsAgreements> = {
  text: { type: 'string' },
  contentId: { type: 'string' },
  required: { type: 'boolean', default: false },
  predefined: { type: 'boolean', default: false },
  predefinedDescription: { type: 'string' },
};
const hooks: ModelHooks<AppListsAgreements> = {
  preSave(data) {

    delete data.predefinedDescription;

  },
  preAdd(data) {

    if (data.contentId === 'contact-dsgvo' && data.predefined) {

      data.predefinedDescription = 'app-lists-agreements-dsgvo-description';

    }

  }

};

Models.register('app-lists-agreements', declaration, hooks);
