<template>
  <section class="text-module-editor open editor" :class="{ changed: safeContent.changed }">
    <module-editor-header-partial v-model="safeContent" :position="position" :index="index" :type="type" @move="move($event)" />
    <crumbs-partial :type="safeContent.type" @clearCrumbs="unsetCurrentChild" />

    <section :class="{ optionsVisible: !child }" class="editor-module-container">
      <picture-partial-editor v-if="!child" v-model="safeContent.picture" />

      <textblock-partial-editor v-if="!child" v-model="safeContent.textblock" :inactive="['text', 'list', 'links']" />

      <link-partial-editor v-if="child === 'link'" v-model="safeContent.link" :inactive="['text']" />

      <editor-content-group-partial v-if="!child">
        <input-partial v-model="safeContent.author" placeholder="author">{{$translate('author')}}</input-partial>
        <input-partial v-model="safeContent.date" placeholder="date">{{$translate('date')}}</input-partial>
      </editor-content-group-partial>

      <moduleList-partial v-if="!child" class="module-editor-navigation">
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('link')">{{$translate('link')}}</button-partial>
        </li>
      </moduleList-partial>

      <module-options-partial
        v-if="child === 'options' || !child"
        v-model="safeContent"
        :available-variants="availableVariants"
        :selected-variants="safeContent.variant"
        @setChild="setChild('options', true)"
        @unsetChild="setChild('options')"
        @remove="remove()"
        @clone="clone()"
      />

    </section>
  </section>
</template>
<script lang="ts">
import { Component, ModuleEditorComponent } from '@/libs/ModuleEditorComponent';
import BlogIntroModule from '../models/BlogIntroModule.model';

@Component
export default class BlogIntroModuleEditor extends ModuleEditorComponent<BlogIntroModule> {

  model = 'blog-intro-module';

}

</script>
