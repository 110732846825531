<template>
  <article :class="navigationAnimation">
    <side-screen-header-partial>{{$translate('settings')}}</side-screen-header-partial>
    <section class="content-container">
      <form class="content" @submit="save">

        <fieldset v-if="model.ftp.mode">
          <legend>
            <span class="text">{{$translate('publish')}} {{$translate('settings')}}</span>
          </legend>

          <!-- <fieldset>
          <legend>
            <span class="text">{{$translate('internal hosting')}}</span>
          </legend>
          <checkbox-partial :disabled="!hostingState" v-model="model.internal.active">{{$translate('active')}}</checkbox-partial>
          <notice-partial v-if="!hostingState" class="margin padding" type="notice" title="activating hosting">
            {{ 'internal hosting has to be active, to use this publish method.' | translate }}
          </notice-partial>
        </fieldset> -->

          <fieldset>
            <legend>
              <span class="text">{{$translate('FTP')}}</span>
            </legend>

            <input-partial v-model="model.ftp.host" placeholder="host">{{$translate('host')}}</input-partial>
            <input-partial v-model="model.ftp.port" placeholder="port">{{$translate('port')}}</input-partial>

            <input-partial v-model="model.ftp.username" placeholder="username">{{$translate('username')}}</input-partial>
            <input-partial v-model="model.ftp.password" type="password" placeholder="password">{{$translate('password')}}</input-partial>

            <select-partial v-model="model.ftp.mode" :multiple="false" :options="[{ value: 'passive', text: 'passive' }, { value: 'active', text: 'active' }]">
              Mode
            </select-partial>

            <checkbox-partial v-model="model.ftp.active">{{$translate('active')}}</checkbox-partial>

            <!-- <button class="base colorScheme2" @click="testFtp()">test connection ({{ftpStatus}})</button> -->
          </fieldset>

          <fieldset>
            <legend>
              <span class="text">{{$translate('Webhook')}}</span>
            </legend>

            <input-partial v-model="model.webhook.url" placeholder="url">{{$translate('url')}}</input-partial>
            <secret-partial v-if="scope(['publish:secret'])" />
            <checkbox-partial v-model="model.webhook.active">{{$translate('active')}}</checkbox-partial>
          </fieldset>
        </fieldset>

        <button-group-partial>
          <button-partial v-model="state" save>{{$translate('SAVE_CHANGES')}}</button-partial>
        </button-group-partial>
      </form>
    </section>
  </article>
</template>

<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import ModelsClass from '@/libs/Models.class';
import ProjectPublishModel from '../models/ProjectPublish.model';

@Component
export default class AppEditorSettingsScreen extends ExtComponent {

  content: any = {};

  model: ProjectPublishModel = null;

  state: string = '';

  componentName = 'app-publish-project-settings';

  mounted() {

    // this.model.setData(this.$store.state.AppPublish.settings, true, 'add');
    this.model = ModelsClass.add(this.$store.state.AppPublish.settings, 'app-publish-project');
    console.log('MOUNTED SETTINGS', this.$store.state.AppPublish.settings);

  }

  // get hostingState() {

  //   return this.project.settings.hosting?.active;

  // }

  save($event: MouseEvent) {

    $event.preventDefault();
    this.state = '';

    const cleanedContent = ModelsClass.save(this.model, 'app-publish-project');

    this.$store
      .dispatch('AppPublish/settings', {
        company: this.$store.state.Company.company,
        project: this.$store.state.Project.project,
        settings: cleanedContent,
      })
      .then(() => {

        this.state = 'success';
        return true;

      })
      .catch((e) => {

        console.error(e);
        this.state = 'error';

      });

  }

}
</script>
