<template>
  <section class="link-group" :class="[type]">
    <h4 v-if="title">{{$translate(title)}}<span v-if="beta">{{$translate('beta')}}</span></h4>
    <button-group-partial left v-if="$slots.default">
      <slot />
    </button-group-partial>
  </section>
</template>
<script lang="ts">
import Component from 'vue-class-component';
import { ExtPartial, Prop } from '@/libs/lila-partial';

@Component
export default class LinkGroup extends ExtPartial {

  @Prop(String) type: string;

  @Prop(String) title: string;

  @Prop(Boolean) beta: boolean;

}
</script>
<style lang="less">

.link-group {

  display: grid;
  gap: 10px;

  h4 {
    .font-bold;
    color: @color1;
    font-weight: bold;
    font-style: normal;
    font-size: @fontTextSmaller;
    text-transform: uppercase;

    span {
      vertical-align: super;
      margin-left: 5px;
      padding: 2px;

      background: @color1;
      color: @white;

      font-size: 8px;
    }
  }

  .button, button {
    margin: 0;
  }

  & + .link-group {
    margin-top: 20px;
  }
}

</style>
