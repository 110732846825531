import GenericModel from '@/interfaces/GenericModel.interface';
import Models, { ModelDeclarationExtended, ModelOptions } from '@/libs/Models.class';
import ListLink from '@/models/ListLink.model';
import ListString from '@/models/ListString.model';
import Module from '@/models/Module.model';
import Textblock from '../interfaces/textblock.interface';

export default interface LocationModule extends GenericModel {
  type: 'location-module'
  textblock: Textblock
  map: string,
  list: ListString
  links: ListLink
  variant: string[]
}

const declaration: ModelDeclarationExtended<LocationModule, Module> = {
  variant: { type: 'array', contains: { type: 'string' } },
  map: { type: 'string' },
  list: { model: 'list-string' },
  links: { model: 'list-link' },
  textblock: { model: 'textblock' },
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('location-module', declaration, undefined, options);
