import { VueConstructor } from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { Store } from 'vuex';
import log from 'loglevel';
import authInstance, { Auth } from '@/libs/lila-auth';
import MainStoreState from '@/store/mainStoreState.interface';
import { vue } from '@/libs/lila-partial';
import InstallableApp from '../interfaces/InstallableApp.interface';
import availableApps from '../apps/apps';

class AppsPlugin {

  regex = new RegExp(/^\/(?<company>[a-z0-9-_]+)\/((?<project>[a-z0-9-_]{3,})\/)?a\/(?<app>[a-z0-9-_]+)(.*)?/, 'i');

  store: Store<MainStoreState>;

  router: VueRouter;

  constructor(store: Store<MainStoreState>, router: VueRouter) {

    this.store = store;

    this.router = router;

    router.beforeResolve(async (to, from, next) => {

      const matched = AppsPlugin.matchUrl(to.path, this.regex);
      const name = AppsPlugin.getName(matched);


      if (name) store.commit('Navigation/appEntry', true);

      /** to.meta.app needs to be present to activate an app
        * if it is missing, the app install process is not done and a route.replace will reroute to this point with to.meta.app existing
      */
      if (((store.state.activeApp && store.state.activeApp !== name) || (!store.state.activeApp && name)) && to.meta.app) {

        const matchedApp = availableApps[name];

        if (matchedApp) {

          const storeName = availableApps[name].store;

          if (storeName) {

            await store.dispatch(`${storeName}/activate`, to.params);

            store.commit('activeApp', name);

          }

        }

        store.commit('activeApp', name);


      }

      if (store.state.activeApp && !name) {

        store.commit('activeApp', null);

      }

      const project = AppsPlugin.getProject(matched) || to.params.project;
      const company = AppsPlugin.getCompany(matched) || to.params.company;

      if (company && !project && store.state.Appstore.company.installed) return next();
      if (company && store.state.Appstore.company.installed && project && store.state.Appstore.project.installed) return next();

      if (company || project) {


        try {
          
          authInstance.checkAuth();
        
        } catch (error) {

          console.error(error);
          
          return next('/login');
          
        }

        await Auth.loadCompany(company);
        AppsPlugin.installApps(store, router, { company });


        if (project) await Auth.loadProject(company, project);
        AppsPlugin.installApps(store, router, {company, project});

        if (name) {

          next(false);
          console.log(to.path);
          router.replace({ path: to.fullPath });
          return true;

        }

        return next(to);

      }

      return next();

    });

  }

  static installApps(store: Store<MainStoreState>, router: VueRouter, options: {company: string, project?: string}) {

    const target = options?.company && options?.project ? 'project' : 'company';
    const base = target === 'project'
      ? store.state.Appstore.project.installable 
      : store.state.Appstore.company.installable;

    if (base) {
      
      base.forEach((single) => {
        
        try {
          
          AppsPlugin.register(single.id, options.company, options.project, store, router);
          
        } catch (error) {
          
          console.error(error);
          
        }
        
      });
      
      store.commit(target === 'project' ? 'Appstore/installedProject' : 'Appstore/installedCompany', true);

    }

  }

  static installOverlayApps(store: Store<MainStoreState>, router: VueRouter) {

    const base = store.state.Appstore.appsToInstall;

    if (base) {
      
      base.forEach((app) => {

        try {
          
          AppsPlugin.register(app, undefined, undefined, store, router);
          
        } catch (error) {
          
          console.error(error);
          
        }
        
      });
      
      store.commit('Appstore/installedOverlay', true);

    }

  }

  static matchUrl(url: string, regex: RegExp): RegExpMatchArray {

    return regex.exec(url);

  }

  static getName(matched: RegExpMatchArray): string {

    if (!matched) return null;

    return matched[4] 
      ? matched[4] 
      : matched[3];

  }

  static getProject(matched: RegExpMatchArray): string {

    return matched
      ? matched[3]
      : null;

  }

  static getCompany(matched: RegExpMatchArray): string {

    return matched
      ? matched[1]
      : null;

  }

  static addRoutes(routes: RouteConfig[], targetRoute: string, router: VueRouter): void {

    routes.forEach((single) => {

      if (targetRoute) {

        router.addRoute(targetRoute, single);

      } else {

        router.addRoute(single);

      }

    });

  }

  static register(appName: string, company: string | undefined, project: string | undefined, store: Store<MainStoreState>, router: VueRouter): boolean {

    if (store.state.apps.includes(appName)) return false;

    if(!company && !project) {

      log.info(`%c[STUDIO-APP]%c%c[APPS]%c install app ${appName} (from overlay)`, 'background: #3f2d56; color: #CCC;', 'padding: 10px', 'background: #5A8C99; color: #FFF;');
    
    }

    if(company && project) {

      log.info(`%c[STUDIO-APP]%c%c[APPS]%c install app ${appName} (${company} / ${project})`, 'background: #3f2d56; color: #CCC;', 'padding: 10px', 'background: #5A8C99; color: #FFF;');
    
    }

    if(company && !project) {

      log.info(`%c[STUDIO-APP]%c%c[APPS]%c install app ${appName} (${company})`, 'background: #3f2d56; color: #CCC;', 'padding: 10px', 'background: #5A8C99; color: #FFF;');
    
    }

    const app = AppsPlugin.getApp(appName);

    AppsPlugin.addRoutes(app.routes, 'app-main-screen', router);

    if (app.translations) {

      AppsPlugin.registerTranslations(app);

    }

    if (app.actions) {

      store.dispatch('extendActions', {source: appName, actions: app.actions});

    }

    store.commit('apps', appName);

    return true;

  }

  static registerInternal(appName: string, store: Store<MainStoreState>, router: VueRouter): boolean {

    log.info(`%c[STUDIO-APP]%c%c[APPS]%c install internal ${appName}`, 'background: #3f2d56; color: #CCC;', 'padding: 10px', 'background: #5A8C99; color: #FFF;');

    const app = AppsPlugin.getApp(appName);

    AppsPlugin.addRoutes(app.routes, 'app-main-internal-screen', router);

    if (app.translations) {

      AppsPlugin.registerTranslations(app);

    }

    if (app.actions) {

      store.dispatch('extendActions', {source: appName, actions: app.actions});

    }

    store.commit('apps', appName);

    return true;

  }

  static registerTranslations(app: InstallableApp): void {

    Object.keys(app.translations).forEach((key) => {

      vue.prototype.$translations.extend(app.translations[key], key);

    });

  }

  static getApp(appName: string): InstallableApp {

    const registerApp = availableApps[appName];

    if (!registerApp) throw new Error(`app ${appName} not found`);

    return registerApp;

  }

  static getActive(appName: string, company: string, project: string, store: Store<MainStoreState>): boolean {

    if (company && !project) {

      return store.state.Company.settings?.apps?.includes(appName);

    }

    if (company && project) {

      return store.state.Project.settings?.apps?.includes(appName);

    }

    return false;

  }

}

const plugin = {

  install: (vue: VueConstructor, options: { store: Store<MainStoreState>, router: VueRouter }): void => {

    vue.prototype.$apps = new AppsPlugin(options.store, options.router);

  },

};

export default plugin;
export {
  AppsPlugin,
};
