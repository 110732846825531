<template>
  <article class="content-container add publish-extension">

    <slot />

    <section v-if="showContentGroups" class="radioGroup">
      <h4>{{$translate('app-publish-method-availableForContentGroups')}}</h4>

      <label v-for="(single, index) in filteredContentGroups" :key="`publish-method-${index}`" class="radio">
        <input v-model="model.contentGroup" :value="single" name="contentGroup" type="radio" />
        {{$translate(`app-publish-method-availableForContentGroups-${single}`)}}
      </label>

    </section>

    <section v-if="model.contentGroup" class="radioGroup">
      <h4>{{$translate('app-publish-method')}}</h4>

      <label v-for="(method, index) in filteredMethods" :key="`publish-method-${index}`" class="radio">
        <input v-model="model.method" :value="method._id" name="type" type="radio" />
        <template v-if="method.label">{{method.label}} <span class="type">({{$translate(method.type)}})</span></template>
        <template v-if="!method.label">{{$translate(method.type)}}</template>
      </label>

      <template v-if="!available.length">
        <notice-partial type="notice">{{$translate('app-publish-missing-method')}}</notice-partial>
      </template>

    </section>

    <section v-if="model.contentGroup === 'category'" class="radioGroup">
      <h4>{{$translate('app-publish-category')}}</h4>

      <tags-partial type="editor" filter-mode show-always-text :multiple="false" category="category" placeholder="category" :value="[model.category]" @input="updateCategory($event)">{{$translate('category')}}</tags-partial>

    </section>


    <section v-if="showTime" class="radioGroup">

      <h4>{{$translate('app-publish-time')}}</h4>

      <label class="radio">
        <input v-model="model.timing" name="time" value="now" type="radio" />
        {{$translate('app-publish-now')}}
      </label>
      <label class="radio">
        <input v-model="model.timing" name="time" value="planned" type="radio" />
        {{$translate('app-publish-timePlanned')}}
      </label>

      <section v-if="model.timing === 'planned'" class="selectDate">
        <datetime-picker-partial v-model="model.date" future />
      </section>

    </section>

    <notice-partial v-if="readyToPublish && !isSingleContentGroup" :type="affected ? 'notice' : 'error'">
      <template v-if="affected">{{$translate('app-publish-affected', [affected])}}</template>
      <template v-if="!affected">{{$translate('app-publish-no-affected')}}</template>
    </notice-partial>

    <button-group-partial class="actions">
      <button-partial :disabled='!readyToPublish' doublecheck @confirmed="publish">
        {{$translate('app-publish-publish-add')}}
      </button-partial>
    </button-group-partial>

  </article>
</template>
<script lang="ts">
import { Publish, PublishMethod, PublishContentGroup } from '@lilaquadrat/studio/lib/interfaces';
import dayjs from 'dayjs';
import vue from 'vue';
import { Component } from '@/libs/lila-component';
import { SDKResponse } from '@/libs/StudioSDK';
import hardCopy from '@/mixins/hardCopy';
import { ExtPartial, Prop } from '@/libs/lila-partial';
import ModelsClass from '@/libs/Models.class';
import AppPublishPublish from '../models/publish.model';

@Component
export default class AppPublishExtension extends ExtPartial {

  @Prop({ required: true, type: String }) type: Publish['type'];

  @Prop(Array) contentGroups: PublishContentGroup[];

  @Prop(String) pre?: Publish['contentGroup'];

  @Prop(String) app?: Publish['app'];

  @Prop(String) appModel?: Publish['model'];

  @Prop(String) referenceId?: string;

  model: AppPublishPublish = null;

  methods: PublishMethod[] = [];

  affected = null;

  beforeMount() {

    this.model = ModelsClass.add<AppPublishPublish>(
      {
        date: dayjs().toString(),
        // pre: this.pre,
        modelType: this.type,
        app: this.app,
        appModel: this.appModel,
        referenceId: this.referenceId,
        method: this.available.length === 1 ? this.available[0]._id.toString() : '',
      },
      'app-publish-publish',
    );


    this.$store.dispatch('AppPublish/activeMethods', { app: this.app, contentGroups: this.contentGroups })
      .then((data: SDKResponse<PublishMethod[]>) => {

        this.methods = data.data;

      });


  }

  publish() {

    const publishData = ModelsClass.save(hardCopy(this.model), 'app-publish-publish');
    const type = publishData.modelType;

    delete publishData.modelType;

    console.log(this.model, publishData, this.$store.getters.companyProject);

    this.$store.dispatch(
      'AppPublish/publish',
      {
        ...this.$store.getters.companyProject,
        ...publishData,
        type,
      },
    )
      .then((response: SDKResponse<{id: string}>) => {

        this.$store.commit('AppPublish/statusTracker', { ...publishData, _id: response.data.id });

      })
      .catch((e) => {

        console.error(e);
        this.affected = null;

      });

  }

  updateCategory(category: string[]) {

    this.model.category = category.length ? category[0] : '';

  }

  get readyToPublish() {

    let ready = true;

    this.affected = null;

    if (!this.model.method) ready = false;
    if (!this.model.modelType) ready = false;
    if (!this.model.contentGroup) ready = false;

    if (this.model.contentGroup === 'category') {

      if (!this.model.category.length) ready = false;

    }

    if (this.model.timing === 'planned') {

      if (!dayjs().isBefore(this.model.date)) ready = false;

    }


    if (ready) {

      vue.nextTick(() => {

        this.getAffected();

      });


    } else {

      this.affected = null;

    }

    return ready;

  }

  get showTime() {

    let ready = true;

    if (!this.model.method) ready = false;
    if (!this.model.modelType) ready = false;
    if (!this.model.contentGroup) ready = false;

    if (this.model.contentGroup === 'category' && !this.model.category.length) {

      ready = false;

    }

    return ready;

  }

  getAffected() {

    const affectedStates = this.available.find((single) => single._id === this.model.method as any).affectedStates;
    const query: Record<string, unknown> = {
      type: this.model.modelType,
      app: this.model.app,
      model: this.model.model,
      affectedStates,
    };

    if (this.model.contentGroup === 'category') {

      query.category = this.model.category;

    }

    return this.$store.dispatch(
      'AppPublish/getAffected',
      query,
    )
      .then((response: SDKResponse<{affected: number}>) => {

        console.log('done', response.data.affected);
        this.affected = response.data.affected;

      })
      .catch((e) => {

        console.error(e);

      });


  }

  // eslint-disable-next-line class-methods-use-this
  getDate(date: string, time: string) {

    const newDate = new Date(`${date} ${time}`);

    return newDate.toString() === 'Invalid Date' ? false : newDate;

  }


  get filename() {

    return this.$store.state.AppEditorData.content?.settings?.filename?.filter((single) => single) || [];

  }

  get publishScope() {

    return this.scope(['publish:publish']);

  }

  get available() {

    return this.methods;

  }

  get isSingleContentGroup() {

    return this.contentGroups.length === 1 && this.contentGroups.includes('single');

  }

  get filteredMethods() {

    const filteredMethods = this.available.filter((single) => single.availableForContentGroups.includes(this.model.contentGroup as PublishContentGroup));

    if (filteredMethods.length === 1) this.model.method = filteredMethods[0]._id as unknown as string;

    return filteredMethods;

  }

  get filteredContentGroups() {

    if (this.contentGroups.length === 1) this.model.contentGroup = this.contentGroups[0] as unknown as string;

    return this.contentGroups;

  }

  get showContentGroups() {

    const show = this.contentGroups.length > 1;

    if (!show) this.model.contentGroup = this.contentGroups[0] as unknown as string;

    return show;

  }

}
</script>
<style lang="less" scoped>

.publish-extension.add {
  display: grid;
  gap: 20px;

  label {
    margin: 0;
  }

  .radioGroup {
    display: grid;
    gap: 10px;

    .radio {

      display: grid;
      grid-template-columns: 15px max-content 1fr;
      gap: 5px;
      justify-content: start;
      justify-items: start;

      .type {
        text-transform: uppercase;
        color: @textColor;
      }

    }

  }

  .actions {
    .multi(margin-top, 4);
  }

}
</style>
