import Models, { ModelDeclaration, } from '@/libs/Models.class';
import { ListCategory } from '@lilaquadrat/interfaces';


const declaration: ModelDeclaration<ListCategory> = {
  name: { type: 'string' },
  description: { type: 'string' },
  amount: { type: 'number' },
  disabled: { type: 'boolean' },
  price: { model: 'app-lists-price' },
  _id: { type: 'string' },
  externalId: { type: 'string' }
};

Models.register('app-lists-categories', declaration);
