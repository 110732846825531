import { Call } from '@/libs/lila-call';
import { Auth } from '@/libs/lila-auth';
import MainStore, { state } from '@/store/main.store';
import log from 'loglevel';
import GenericStore from '@/interfaces/GenericStore.interface';
import { DataObject, Editor } from '@lilaquadrat/studio/lib/interfaces';
import StudioSDK from '@/libs/StudioSDK';
import BaseCallData from '@/interfaces/BaseCallData.interface';
import AppEditorMainState from '../interfaces/main-state.interface';

const AppEditorMainStore: GenericStore<AppEditorMainState> = {

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },

  namespaced: true,
  strict: true,

  state: {

    settings: {},
    project: null,
    data: {
      sites: {},
    },
    search: {
      tags: [],
      search: null,
    },

    site: null,
    filterActive: false,

    noticeCache: {},

    extensionData: {}
  },

  mutations: {

    data(appEditorState, data) {

      appEditorState.data = data;

    },

    extentionData(appEditorState, data) {

      appEditorState.extensionData = data;

    },

    setSearch(appEditorState, search: { tags?: string[], search?: string }) {

      if (search.tags) {

        if (typeof search.tags === 'string') {

          appEditorState.search.tags = [search.tags];

        } else {

          appEditorState.search.tags = search.tags;

        }

      } else {

        appEditorState.search.tags = [];

      }

      if (search.search) {

        appEditorState.search.search = search.search;

      } else {

        delete appEditorState.search.search;

      }

    },

    updateSearch(appEditorState, update: { data: any, type: string }) {

      console.log(update, update.data.length, !update.data.length, appEditorState.search);

      if (!update.data.length) {

        appEditorState.search[update.type] = null;
        return;

      }

      console.log(update, appEditorState.search);

      appEditorState.search[update.type] = update.data;

    },

    setSite(appEditorState, site: number) {

      appEditorState.site = site || 0;

    },

    toggleFilter(appEditorState, active: boolean) {

      appEditorState.filterActive = active;

    },

    addNoticeCache(appEditorState, data: { key: string, data: any }) {

      appEditorState.noticeCache[data.key] = data.data;
      appEditorState.noticeCache = { ...appEditorState.noticeCache };

    },

    settings(appEditorState, settings) {

      appEditorState.settings = settings;

    },
  },

  actions: {

    get(store, data: { settings: BaseCallData, query?: any, site?: any }) {

      const sdk = new StudioSDK('editor', AppEditorMainStore.sdkOptions());

      return sdk.editor.list(+(data?.settings?.site || 1), data?.query?.sort, data?.query?.order, data?.query);

    },

    /**
         * get data from the /data endpoint
         *
         * @param {*} store
         * @param {{settings: BaseCallData, query?: any}} data
         * @returns
         */
    getNotice(store, id: string) {

      if (store.state.noticeCache[id]) return new Promise((resolve) => resolve(store.state.noticeCache[id]));

      const urlArray = ['editor', 'studio'];
      const call = new Call<DataObject<Editor>>('editor', MainStore.getters.endpoints);

      urlArray.push(id);
      // `/data/${data.settings.company}/${data.settings.project}`

      return call.get('api', `/${urlArray.join('/')}`, undefined, Auth.getAuthHeader())
        .then((response) => {

          log.debug('GOT RESPONSE', response.r);
          this.commit('Data/addNoticeCache', { key: id, data: response.r });
          return response;

        })
        .catch(() => {

          log.debug('SET NOTICE', id);
          this.commit('Data/addNoticeCache', { key: id, data: {} });

        });

    },

    /**
         * get data from a different endpoint
         */
    getEndpoint<M>(store, data: { settings: BaseCallData, query?: any }) {

      let urlArray = [];
      const call = new Call<DataObject<M>>('editor', MainStore.getters.endpoints);

      urlArray.push(data.settings.endpoint);
      if (data.settings.company) urlArray.push(data.settings.company);

      if (data.settings.project) urlArray.push(data.settings.project);

      if (data.settings.add) {

        urlArray = [...urlArray, ...data.settings.add];

      }

      return call.get('api', `/${urlArray.join('/')}`, data.query, Auth.getAuthHeader());

    },

    save(store, data) {

      let url: string;
      let method = 'post';

      if (data._id) {

        url = `/data/${data.company}/${data.project}/${data._id}`;
        method = 'put';

      } else {

        url = `/data/${data.company}/${data.project}`;
        method = 'post';

      }

      const call = new Call<void>('editor', MainStore.getters.endpoints);

      return call[method]('api', url, data, Auth.getAuthHeader());

    },

    getSettings(store, data) {

      const sdk = new StudioSDK('editor', AppEditorMainStore.sdkOptions());

      return sdk.editor.settings(data);

    },

    settings(store, data) {

      const call = new Call('editor', MainStore.getters.endpoints);

      return call.put('api', `/editor/${data.company}/${data.project}/settings`, data.settings, Auth.getAuthHeader())
        .then(() => store.dispatch('getSettings', data))
        .then((settings) => store.commit('settings', settings.r));

    },

    activate(store, params) {

      if (!store.state.settings?.app) {

        return store.dispatch('getSettings', params)
          .then((settings) => {

            if (!settings.r) settings.r = settings.data;
            store.commit('settings', settings.r?.settings);

          });

      }

      return  Promise.resolve();

    },

  },

};

export default AppEditorMainStore;
