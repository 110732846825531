// import PartialModel from '@/libs/lila-model';

// export default class ParticipantsModel extends PartialModel {

//   uuid = false;

//   unique = false;

//   max = 0;

// }


// export interface List extends BasicDataInterface {
//   _id?: ObjectId;

//   company: string
//   project: string

//   name: string
//   description?: string
//   mode: 'contact' | 'content' | 'reservation'

//   start?: Date
//   end?: Date

//   payment: 'optional' | 'required' | 'free'

//   participants?: {
//     max?: number
//     confirmed?: number
//     reserved?: number
//     available?: number
//     unique?: boolean
//   }

//   state: 'open' | 'closed' | 'waiting'

//   categories: ListCategory[]
// }


import Models, { ModelDeclaration } from '@/libs/Models.class';

export default interface AppListsParticipants {
  max: number
  unique: boolean
  addressRequired: boolean
}

const declaration: ModelDeclaration<AppListsParticipants> = {
  max: { type: 'number' },
  unique: { type: 'boolean', default: false },
  addressRequired: { type: 'boolean', default: false },
};

Models.register('app-lists-participants', declaration);
