<script lang="ts" setup>
import { computed, onUnmounted, ref , onBeforeMount, watch } from 'vue';
import { useStore } from '@/store/main.store';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import ModelsClass from '@/libs/Models.class';
import Tracker from '@/models/Tracker.model';
import hardCopy from '@/mixins/hardCopy';

dayjs.extend(duration);

const categoryOptions = [
    {
      text: 'app-tracker-category-worktime',
      value: 'worktime',
    },
    {
      text: 'app-tracker-category-call',
      value: 'call',
    },
  ];
const props = defineProps<{
  history?: boolean,
  card?: boolean,
  indexedKey?: number,
  overlay?: boolean,
  tracker?: Tracker
}>();
const emit = defineEmits<{
  // eslint-disable-next-line no-unused-vars
  (e: 'restart', data: Tracker): void
}>();
const store = useStore();
const tempTracker = ref<Tracker>(null);
const active = ref(false);
const lastSave = ref();
const lastUpdate = ref();
const refreshInterval = ref();
const isComplete = computed(() => !active.value && tempTracker.value?.endTime && !props.history);
const currentTimeRendered = computed(() => {

  const hours = dayjs.duration(tempTracker.value?.currentTime || 0, 'milliseconds').get('hours').toString()
    .padStart(2, '0');
  const minutes = dayjs.duration(tempTracker.value?.currentTime || 0, 'milliseconds').get('minutes').toString()
    .padStart(2, '0');
  const seconds = dayjs.duration(tempTracker.value?.currentTime || 0, 'milliseconds').get('seconds').toString()
    .padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;

});
const trackerData = computed(() => hardCopy(tempTracker.value));

async function saveTemp() {

  console.log('save temp', trackerData.value, props.indexedKey);
  lastSave.value = performance.now();
  await store.dispatch('AppTracker/updateLocal', { key: props.indexedKey, tracker: trackerData.value });

}

async function save() {

  try {

    await store.dispatch('AppTracker/save', ModelsClass.save<Tracker>(trackerData.value, 'tracker'));
    await store.dispatch('AppTracker/removeLocal', props.indexedKey);
    console.log('saved and removed', props.indexedKey);
  
  } catch (error) {

      console.error(error);
    
  }

}

function updateTime() {

  // if (tempTracker.value.mode === 'stopwatch') {

    if(active.value) {

  //     tempTracker.value.currentTime += performance.now() - lastUpdate.value;
  //     lastUpdate.value = performance.now();


      // if(performance.now() - lastSave.value > 5000) {
        
      //   saveTemp();
      
      // }

  //     requestAnimationFrame(updateTime);

  //   }
    

  // }

  // if (tempTracker.value.mode === 'timeframe') {

    const endTime = active.value ? new Date().getTime() : tempTracker.value.endTime;

    tempTracker.value.currentTime = endTime - tempTracker.value.startTime;

    
    if(performance.now() - lastSave.value > 5000) {

        saveTemp();
      
    }


    requestAnimationFrame(updateTime);


  }

}

function start() {

  if (isComplete.value) return;
  if (!props.indexedKey) return;
  if (!tempTracker.value.startTime) tempTracker.value.startTime = new Date().getTime();


  refreshInterval.value = requestAnimationFrame(updateTime);
  active.value = true;
  lastSave.value = performance.now();
  lastUpdate.value = performance.now();

}

async function stop() {

  cancelAnimationFrame(refreshInterval.value);
  active.value = false;
  tempTracker.value.endTime = new Date().getTime();
  await saveTemp();

}

watch(() => tempTracker.value?.category, async () => {

  if(!active.value) {

    await saveTemp();
  
  }

;

});
watch(() => tempTracker.value?.description, async () => {

  if(!active.value) {

    await saveTemp();

  }

});

function restart() {

  emit('restart', tempTracker.value);

}

// async function stop() {

//   console.log(refreshInterval.value);
//   clearInterval(refreshInterval.value);
//   console.log(refreshInterval.value);

//   tempTracker.value.endTime = Date.now();
//   active.value = false;
//   await saveTemp();
//   await save();

// }

// function setCategory(category) {

//   tempTracker.value.category = category;

// }


onBeforeMount(() => {

  console.log(props.tracker);
  tempTracker.value = ModelsClass.add<Tracker>(props.tracker, 'tracker');
  if (!props.history) start();

});

onUnmounted(async () => {

  console.log('unmount tracker', active.value, trackerData.value);
  cancelAnimationFrame(refreshInterval.value);

  if(active.value) {

    await saveTemp(); 
  
  } else {

    await save();
  
  }

  active.value = false;

});

</script>
<template>
  <article class="app-time-tracker" :class="[{isActive: active, isComplete, isHistory: history, card, overlay}]">
    <header v-if="overlay">
      <h3>{{ $translate(`company:${tempTracker.company}`) }} - {{ $translate(`project:${tempTracker.company}:${tempTracker.project}`) }}</h3>
      <router-link :to="{name: 'app-tracker-project-home', params: {company: tempTracker.company, project: tempTracker.project}}">{{$translate('app-tracker-open-app')}}</router-link>
    </header>
    <section class="description">
      <input-partial v-if="!history" v-model="tempTracker.description" mode="inlineHeadBackground" placeholder="app-tracker-description" debounce="500"></input-partial>
      <p v-if="history">{{tempTracker.description}}</p>
    </section>
    <section class="category">
      <select-partial :options="categoryOptions" inline placeholder="app-tracker-category" :multiple="false" :allowEmpty="false" v-model="tempTracker.category" />
    </section>
    <section class="details">
      <span class="currentTime">
        <span class="border"></span>
        <span class="time">{{currentTimeRendered}}</span>
        <button-partial v-if="active" :variant="['iconColor']" size="large" button-content-type="icon" color-scheme="icon-color1" icon="stop" title="app-tracker-button-stop" @click="stop">app-tracker-button-stop</button-partial>
        <button-partial v-if="!active" :variant="['iconColor']" size="large" button-content-type="icon" color-scheme="icon-green" icon="replay" title="app-tracker-button-restart" @click="restart">app-tracker-button-restart</button-partial>
      </span>
      <!-- <span class="startEnd">{{startTimeRendered}} <template v-if="endTime">- {{endTimeRendered}}</template></span> -->
    </section>
  </article>
</template>
<style lang="less" scoped>

.app-time-tracker {

  .trans(background);

  @keyframes borderAnimation {

    0% {}

    100% {
      transform: rotate(360deg);
    }
  }

  display: grid;
  grid-template-columns: 1fr 1fr max-content;
  gap: 5px 10px;

  &.overlay {
    .multi(padding, 2, 4);

    header {
      display: grid;

      grid-template-columns: 1fr max-content;
      grid-column-start: 1;
      grid-column-end: 4;

      h3 {
        .font-normal;
        font-size: @fontSmall;
      }
    }

    &:hover {
      background-color: @grey1;
    }
  }

  &.isComplete {

    .details {

      .currentTime {
        border-color: @success;

        .time {
          color: @success;
        }

        &:hover {

          .time {
            color: @success;
          }

        }
      }
    }
  }

  &.card {
    grid-template-rows: repeat(4, min-content);
    grid-template-columns: 1fr;
    gap: 5px;

    .details {
      grid-row-start: 1;
      gap: 10px;

      justify-self: center;

      .currentTime {
        width: 120px;
        height: 120px;

        .border {
          position: absolute;

          display: block;
          width: 120px;
          height: 120px;

          border: dashed 2px @color1;
          border-radius: 50%;
        }
      }

    }

    &.isActive {

      .details {

        .border {
          animation: borderAnimation 20s infinite linear;
        }
      }
    }

    &.isComplete {

      .details {

        .currentTime {
          border-color: @success;

          .time {
            color: @success;
          }

          .border {
            border-color: @success;
          }

          &:hover {

            .time {
              color: @success;
            }

          }
        }
      }
    }

    .category {
      grid-row-start: 2;
    }

    .description::v-deep {
      justify-items: center;

      .input-partial {

        input {

          text-align: center;
        }
      }
    }

  }

  &.isActive {
    color: @color1;
  }

  &.isHistory {
    background-color: @grey1;

    &:hover {
      background-color: @grey2;
    }
  }

  .details, .category {
    display: grid;
    text-align: right;
  }

  .currentTime {
    .font-head;

    position: relative;
    display: grid;

    align-content: center;
    color: @textColor;
    font-size: @headline_XS;
    font-variant-numeric: tabular-nums;

    .border {
      display: none;
    }

    .time {
      color: @color1;
      .trans(opacity);
    }

    .button {
      position: absolute;

      width: 100%;
      height: 100%;
      opacity: 0;
      .trans(opacity);
    }

    &:hover {

      .time {
        opacity: .1;
      }

      .button {
        opacity: 1;
      }

      .border {
        animation-play-state: paused;
      }

    }
  }

  .description {
    display: grid;
    align-content: center;
  }

  .category {
    align-content: center;
    color: @textColor;

    @media @tablet, @desktop {
      font-size: @headline_XS;
    }

    .font-head;
  }


}
</style>

