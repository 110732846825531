import GenericStore from '@/interfaces/GenericStore.interface';
import MainStore, { state } from '@/store/main.store';
import { DataObject, Publishadmin } from '@lilaquadrat/studio/lib/interfaces';
import StudioSDK from '@/libs/StudioSDK';
import { MailFrom } from '@lilaquadrat/interfaces';
import AppPublishadminMainState from '../interfaces/main-state.interface';
// import StudioSDK from '@/libs/StudioSDK';

const AppPublishadminStore: GenericStore<AppPublishadminMainState> = {
  namespaced: true,
  strict: true,

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },


  state: {

    data: {},

    search: {
      tags: [],
      search: null,
      type: null,
      sort: '_id',
      order: '1',
    },

    single: null,

  },

  mutations: {

    data(store, data: DataObject<Publishadmin>) {

      store.data = data;

    },

    single(store, data: Publishadmin) {

      store.single = data;

    },
    
    updateSearch(appPublishadminState, update: { data: any, type: string }) {

      console.log(update, update.data.length, !update.data.length, appPublishadminState.search);

      if (!update.data.length) {

        appPublishadminState.search[update.type] = null;
        return;

      }

      console.log(update, appPublishadminState.search);

      appPublishadminState.search[update.type] = update.data;

    },

    setSearch(appPublishadminState, search: { tags?: string[], search?: string, type?: string }) {

      appPublishadminState.search = search;

    },

  },

  actions: {
    add(store, data: MailFrom) {

      const sdk = new StudioSDK('studio-app', AppPublishadminStore.sdkOptions());

      return sdk.publish.admin.mailFrom.save(data);

    },

    get(store, data: {site?: number, search?: string}) {

      const sdk = new StudioSDK('studio-app', AppPublishadminStore.sdkOptions());

      return sdk.publish.admin.mailFrom.list(data.site, {search: data?.search});

    },

    single(store, internalId: string) {

      const sdk = new StudioSDK('studio-app', AppPublishadminStore.sdkOptions());

      return sdk.publish.admin.mailFrom.single(internalId);

    },

    update(store, data: {id: string, data: MailFrom}) {

      const sdk = new StudioSDK('studio-app', AppPublishadminStore.sdkOptions());

      return sdk.publish.admin.mailFrom.update(data.id, data.data);

    },

    remove(store, id: string) {

      const sdk = new StudioSDK('studio-app', AppPublishadminStore.sdkOptions());

      return sdk.publish.admin.mailFrom.remove(id);

    },

    /**
     * This action will be called, when the app will be activated
     */
    activate(store, params) {

      return Promise.resolve(true);

    },
  },

};

export default AppPublishadminStore;
