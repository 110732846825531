import GenericStore from '@/interfaces/GenericStore.interface';
import MainStore, { state } from '@/store/main.store';
import { DataObject, HostingAdmin } from '@lilaquadrat/studio/lib/interfaces';
import AppEditorDataStore from '@/apps/editor/store/data.store';
import StudioSDK from '@/libs/StudioSDK';
import AppHostingAdminCertificatesActions from '../interfaces/certificatesActions-state.interface';

const AppHostingAdminStore: GenericStore<AppHostingAdminCertificatesActions> = {
  namespaced: true,
  strict: true,

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },


  state: {

    data: {},

    search: {
      tags: [],
      search: null,
      type: null,
      sort: '_id',
      order: '1',
    },

    single: null,

  },

  mutations: {

    data(store, data: DataObject<HostingAdmin>) {

      store.data = data;

    },

    single(store, data: HostingAdmin) {

      store.single = data;

    },


    updateSearch(appHostingAdminState, update: { data: any, type: string }) {

      console.log(update, update.data.length, !update.data.length, appHostingAdminState.search);

      if(!['state', 'order', 'sort', 'search'].includes(update.type)) return;

      if (!update.data.length) {

        appHostingAdminState.search[update.type] = null;
        return;

      }

      console.log(update, appHostingAdminState.search);

      appHostingAdminState.search[update.type] = update.data;

    },

    setSearch(appHostingAdminState, search: { tags?: string[], search?: string, type?: string }) {

      appHostingAdminState.search = search;

    },

  },

  actions: {
    // sdkListExample(store, data: {data: Test, id: string}) {

    //   const sdk = new StudioSDK('test-app', AppTestStore.sdkOptions());

    //   return sdk.test.list(data.id, data.data);

    // },

    get(store, data: { site?: number, filter: {state: 'string'}}) {

      const sdk = new StudioSDK('editor-app', AppHostingAdminStore.sdkOptions());

      return sdk.hosting.certificates.actions.admin.list(data.site, data.filter);

    },

    single(store, id: string) {

      const sdk = new StudioSDK('editor-app', AppHostingAdminStore.sdkOptions());

      return sdk.hosting.certificates.actions.admin.single(id);

    },

    cancel(store, id: string) {

      const sdk = new StudioSDK('editor-app', AppHostingAdminStore.sdkOptions());

      return sdk.hosting.certificates.actions.admin.cancel(id);

    },

    /**
     * This action will be called, when the app will be activated
     */
    activate(store, params) {

      return Promise.resolve(true);

    },
  },

};

export default AppHostingAdminStore;
