<template>
  <article class="overlay-partial" @click.self="click" @keyup="click">
    <slot />
  </article>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class OverlayPartial extends Vue {

  click(): void {

    this.$emit('click');

  }

}
</script>
<style lang="less">

.overlay-partial {

  position: fixed;
  width: 100%;
  height: 100%;

  top: 0;
  left: 0;

  .index(8);

  background-color: rgba(0, 0, 0, .7);

  display: grid;

  align-items: center;
  justify-items: center;

}
</style>
