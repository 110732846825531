import { UnifiedRouteGuard } from '@/libs/lila-auth';
import HomeScreen from './screens/home.screen.vue';

export default [
  {
    path: '/:company/a/history/:site?',
    name: 'app-history-company-home',
    beforeEnter: UnifiedRouteGuard,
    component: HomeScreen,
    meta: {
      app: 'history',
    },
  },
  {
    path: '/:company/:project/a/history/:site?',
    name: 'app-history-project-home',
    beforeEnter: UnifiedRouteGuard,
    component: HomeScreen,
    meta: {
      app: 'history',
    },
  },
];
