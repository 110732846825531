<template>
  <article class="app-hostingadmin-single-screen" :class="navigationAnimation">
    <side-screen-header-partial>{{content.domain}}</side-screen-header-partial>
    <section class="content-container">

      <data-grid-partial class="content">
        <section class="data-grid-container">

          <section class="data-grid-single full">
            <h4>{{$translate('app-hostingadmin-domains-domain')}}</h4>
            <p>{{ content.domain }}</p>
          </section>

          <section class="data-grid-single">
            <h4>{{$translate('app-hostingadmin-domains-companyProject')}}</h4>
            <p>{{ content.company }} {{ content.project }}</p>
          </section>

        </section>

        <section class="data-grid-container">
          
          <section class="data-grid-single">
            <h4>{{$translate('app-hostingadmin-domains-title-created')}}</h4>
            <p>{{ $date(content.history.created) }}</p>
          </section>
          
          <section class="data-grid-single">
            <h4>{{$translate('app-hostingadmin-domains-title-updated')}}</h4>
            <p>{{ $date(content.history.updated) }}</p>
          </section>
          
          <section class="data-grid-single full">
            <h4>{{$translate('app-hostingadmin-domains-attributes')}}</h4>
            <p>
              <template v-if="content.isMain">{{ $translate('app-hostingadmin-domains-attributes-isMain') }}</template>
              <template v-if="content.isDefault">{{ $translate('app-hostingadmin-domains-attributes-isDefault') }}</template>
              <template v-if="content.active">{{ $translate('app-hostingadmin-domains-attributes-active') }}</template>
              <template v-if="content.validated">{{ $translate('app-hostingadmin-domains-attributes-validated') }}</template>
            </p>
          </section>
          
        </section>

        <section v-if="content.certificate" class="data-grid-container">
          <h3>{{$translate('app-hostingadmin-domains-title-certificate')}}</h3>
          
          <section class="data-grid-single">
            <h4>{{$translate('app-hostingadmin-domains-title-created')}}</h4>
            <p>{{ content.certificate.domains.join(',') }}</p>
          </section>
          
          <section class="data-grid-single full">
            <h4>{{$translate('app-hostingadmin-domains-title-valid-from-to')}}</h4>
            <p>{{ $date(content.certificate.valid.from) }} - {{ $date(content.certificate.valid.to) }} ({{ $date(content.certificate.valid.to, undefined, true) }})</p>
          </section>
          
        </section>

        <notice-partial v-if="!isValid && content.active" class="padding" type="notice">
          {{ $translate('app-hostingadmin-certificate-domain-active-cert-invalid') }}
        </notice-partial>
        <notice-partial v-if="!isValid && !content.active" class="padding" type="notice">
          {{ $translate('app-hostingadmin-certificate-invalid') }}
        </notice-partial>
        <notice-partial v-if="error" class="padding" type="error">
          {{ $translate(`app-hostingadmin-certificate-error-${error}`) }}
        </notice-partial>

        <button-group-partial v-if="!isValid && (content.active || !content.company && !content.project)">
          <button-partial doublecheck @confirmed="request">{{ $translate('app-hostingadmin-certificate-request') }}</button-partial>
        </button-group-partial>
      </data-grid-partial>


    </section>
  </article>
</template>

<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import { SDKResponse } from '@/libs/StudioSDK';
import { CertificateAction, Domain } from '@lilaquadrat/studio/interfaces';
import { Certificate } from '@lilaquadrat/studio/src/models/certificate.model';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppHostingAdminSettingsScreen extends ExtComponent {

  componentName = 'app-hostingadmin-domains-company-single';

  error: any = '';

  get content(): Omit<Domain, 'certificate'> & {certificate: Certificate} {

    return this.$store.state.AppHostingAdminDomains.single;

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store
      .dispatch('AppHostingAdminDomains/single', params.id);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<CertificateAction>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppHostingAdminDomains/single', preloadedData.data);
    return Promise.resolve();

  }

  get isValid() {

    return this.content.certificate?.valid.to > new Date();

  }

  async request() {

    try {
      
      await this.$store.dispatch('AppHostingAdminDomains/request', this.content._id);
      this.error = '';
    
    } catch (error) {

      this.error = error.response.data.message;
      
    }


  }


}
</script>