<template>
  <article :class="navigationAnimation" class="app-importadmin-home-screen">
    <div class="pagenator full" v-if="content">
      <header class="main">

        <section class="stats">
          <h1>{{ $translate('app-importadmin-exports-title') }}</h1>
          <h3 v-if="content.sites">
            <template v-if="!content.sites.last">{{ content.count }}</template>
            <template v-if="content.sites.last">{{ content.sites.range[0] }} - {{
              content.sites.range[1] }} ({{ content.count }})</template>
            / {{ content.all }} {{ $translate('total') }}
          </h3>
        </section>

        <quickmenu-partial>
          <router-link class="main icon" :title="$translate('app-importadmin')" :aria-label="$translate('app-importadmin')" :to="{ name: 'app-importadmin-company-home' }"><icons-partial type="home" size="large" /></router-link>
          <router-link class="main icon" :title="$translate('app-importadmin-add')" :aria-label="$translate('app-importadmin-add')" :to="{ name: 'app-importadmin-company-add' }"><icons-partial type="add" size="large" /></router-link>
        </quickmenu-partial>
      </header>

      <quickfilter-partial reload store="AppImportadmin" useEmit @update="updateSearch" @reload="reload" />
      <!-- quickfilter with select static options and category selection -->
      <!--<quickfilter-partial reload category categoryType="importadmin" store="Appimportadmin" useEmit @update="updateSearch" @reload="reload">
        <select-partial inline placeholder="app-importadmin-type" :options="stateOptions" allowEmpty :multiple="false" :value="type" @input="updateSearch({ data: $event, type: 'type' })" />
      </quickfilter-partial>-->

      <section class="scroll-container">
        <table class="isSticky contentHead">
          <thead-partial :siteCurrent="currentSite" :search="$route.query.search" :sort="sort" :order="order" :content="headContent" />
        </table>

        <table class="data-switch contentTable" :class="contentAnimation">
          <tbody>
            <tr v-for="(single, index) in content.data" :key="`data-overview-${index}`">
              <td class="id fullHeight action hasDescription" :class="{ hasAttribute: single.app || single.model }">
                <router-link
                  :to="{
                    name: 'app-importadmin-company-single',
                    params: {
                      timestamp: single.created,
                      exportCompany: single.company,
                      exportProject: single.project,
                    },
                  }">
                  <div>
                    <span class="description">
                      {{ single.company }}
                      {{ single.project }}
                    </span>
                  </div>
                </router-link>
              </td>

              <td>
                {{ $date(single.created)}}
              </td>

            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <modal-module />

  </article>
</template>
<script lang="ts">
  import { ExtComponent, Component, Watch } from '@/libs/lila-component';
  import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
  import { DataObject, ExportData } from '@lilaquadrat/interfaces';
  import cleanObject from '@/mixins/cleanObject';
  import hardCopy from '@/mixins/hardCopy';
  import MainStoreState from '@/store/mainStoreState.interface';
  import { Dictionary } from 'vue-router/types/router';
  import { Store } from 'vuex';

  @Component
  export default class AppImportadminHomeScreen extends ExtComponent {

    componentName = 'app-importadmin-company-exports';

    headContent = [
      {
        key: 'name',
        text: 'name',
        classes: 'name',
      },
      {
        text: 'app-importadmin-title-placeholder',
      },
    ];

    // stateOptions = [
    //  {
    //    text: 'app-importadmin-type-company',
    //    value: 'company',
    //  },
    //  {
    //   text: 'app-importadmin-type-persons',
    //    value: 'person',
    //  },
    // ];

    @Watch('$route')
    watchRoute() {

      this.setSearch();

    }

  get currentSite() {

      return this.$route.params.site ? +this.$route.params.site : null;

    }

  get type() {

      return this.$store.state.AppImportadmin.search?.type;

    }

  get sort() {

      return this.$store.state.AppImportadmin.search?.sort;

    }

  get order() {

      return this.$store.state.AppImportadmin.search?.order;

    }

  get content(): DataObject<ExportData[]> {

    return this.$store.state.AppImportadmin.data || undefined;

  }

  mounted() {

    this.setSearch();

  }


  getSearch() {

    const query: Record<string, string | string[]> = {};

    query.sort = this.$route.query.sort as string || 'name';
    query.order = this.$route.query.order as string || '1';

    query.tags = this.$route.query.tags || null;
    query.type = this.$route.query.type || null;
    query.search = this.$route.query.search || null;

    return query;

  }

  setSearch() {

    // only update search if the current component is used and no child is active
    if (this.$route.name !== this.componentName) return;
    this.$store.commit('AppImportadmin/setSearch', this.getSearch());

  }


  async updateSearch(data: { data: string, type: 'string' }) {

    this.$store.commit('AppImportadmin/updateSearch', { data: data.data, type: data.type });

    const query = hardCopy(this.$store.state.AppImportadmin.search);

  cleanObject(query);

  this.$router.push({ params: { site: '1' }, query });

  }

  async reload() {

    StudioSDK.flushCache('Importadmin', 'list');

    const data = await this.$store
      .dispatch('AppImportadmin/get', {
        query: this.$route.query,
        settings: {
          project: this.$route.params.project,
          company: this.$route.params.company,
          site: this.$route.params.site,
        },
      });

    this.$store.commit('AppImportadmin/data', data.data);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary < string >, query: Dictionary < string | string[] >, store: Store < MainStoreState >) {

    console.log(params, query);
    return store
      .dispatch('AppImportadmin/get', {
        query,
        settings: {
          project: params.project,
          company: params.company,
          site: params.site,
        },
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse <DataObject<ExportData>>, params: Dictionary < string >, query: Dictionary < string | string[] >, store: Store < MainStoreState >) {

    console.log(preloadedData);
    store.commit('AppImportadmin/data', preloadedData.data);
    return Promise.resolve();

  }

}
</script>
<style lang="less">
  

  .app-importadmin-home-screen {

    table.contentTable,
    table.contentHead {

      tr {
        grid-template-columns: 1fr 100px;

        td {

          &.hasDescription {

            .id {
              display: grid;
              color: @textColor;
              text-transform: uppercase;
            }

          }

        }

      }

    }

    .sites-filter-container {

      display: grid;
      grid-template-columns: 1fr 1fr;
      .multi(padding-top, 2);
      .multi(padding-bottom, 4);
      .basePadding;

      .sites-container {
        justify-self: end;
      }

      .select {
        display: grid;
        grid-template-columns: 150px 1fr;
      }
    }
  }
</style>