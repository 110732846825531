// import ModuleModel from '@/models/Module.model';
// import PicturegroupElementModel from '@/models/PicturegroupElement.model';
// import TextblockModel from '@/models/Textblock.model';
// import modules from '@/apps/editor/editor-modules';
// import ListModel from '@/apps/lists/models/list.model';

// export default class PictureGroupModuleModel extends ModuleModel {

//   type: keyof typeof modules = 'picturegroup-module';

//   uuid = true;

//   elements = [PicturegroupElementModel];

//   title = TextblockModel;

//   list = ListModel;

//   textblock = TextblockModel;

// }

import Models, { ModelDeclarationExtended, ModelFunctions, ModelOptions } from '@/libs/Models.class';
import ListPartial from '@/models/ListPartialModel.model';
import Module from '@/models/Module.model';
import PicturegroupElement from '@/models/PicturegroupElement.model';
import Textblock from '@/models/Textblock.model';


export default interface PicturegroupModule extends Module {
  elements: PicturegroupElement[]
  title: Textblock
  list: ListPartial
  textblock: Textblock
}

const declaration: ModelDeclarationExtended<PicturegroupModule, Module> = {
  elements: { type: 'array', contains: { model: 'picturegroup-element' } },
  title: { model: 'textblock' },
  list: { model: 'list-string' },
  textblock: { model: 'textblock' },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('picturegroup-module', declaration, functions, options);