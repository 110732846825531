<template>
  <section class="editor-add-editor open editor">
    <editor-pre-header-partial>{{$translate('ADD_NEW_MODULE')}}</editor-pre-header-partial>

    <section class="editor-module-container noGap">
      <input-partial debounce="250" autofocus placeholder="module or partial name" @input="findFilter" />
      <ul v-if="filteredModules.length">
        <li v-for="(module, i) in filteredModules" :key="`module-${i}`">
          <button-partial class="hoverSchema1" color-scheme="transparent" button-type="moduleAddList" @confirmed="add(module.type, module.partial, module.data)">
            {{ module.translatedType }}
            <span v-if="module.partial" class="attribute">{{$translate('partial')}}</span>
          </button-partial>
        </li>
      </ul>
      <h4 v-if="!filteredModules.length" class="no-modules">{{$translate('app-editor-no-modules')}}</h4>
    </section>
  </section>
</template>

<script lang="ts">
import { Call } from '@/libs/lila-call';
import { Auth } from '@/libs/lila-auth';
import { Component, ExtPartial, Prop } from '@/libs/lila-partial';
import EditorAddModule from '../interfaces/EditerAddModule.interface';

@Component
// eslint-disable-next-line global-require
export default class EditorAddEditor extends ExtPartial {

  @Prop(Array) modules: EditorAddModule[];

  input: string = '';

  filteredModules: EditorAddModule[] = [];

  get usedInput() {

    return this.input;

  }

  add(module: string, partial: boolean, data: any) {

    this.$emit('add', module, partial, data);

  }

  mounted() {

    console.log(this.modules);
    this.translateAndSort(this.modules);

  }

  findFilter(input: string) {

    console.log(input);

    const call = new Call('editor', this.$store.getters.endpoints);

    call
      .get(
        'api',
        `/editor/${this.$store.state.Company.company}/${this.$store.state.Project.project}/list`,
        {
          partial: true,
          search: input,
        },
        Auth.getAuthHeader(),
      )
      .then((data: any) => {

        const results = [
          ...data.r.data.map((single) => ({
            type: single.id,
            partial: true,
            data: single,
          })),
          ...this.modules.filter((single) => single.translatedType.match(new RegExp(input, 'i'))),
        ];

        // this.filteredModules = sortSearch(results, input);
        this.translateAndSort(results);

      })
      .catch((e) => {

        console.log(e);
        throw new Error(e);

      });

  }

  translateAndSort(modules: EditorAddModule[]) {

    this.filteredModules = modules.map((single) => {

      single.translatedType = this.$translate(single.type);
      return single;

    })
      .sort((a, b) => a.translatedType.localeCompare(b.translatedType));

  }

}
</script>
<style lang="less" scoped>
.editor-add-editor {

  .no-modules {
    .multi(padding, 2, 4);

  }
}
</style>
