<template>
  <article class="app-learn-single-module" :class="{ isIntegrated: isIntegrated }">
    <header-partial>
      <div v-if="!isIntegrated" class="overview-link">
        <router-link :to="{ name: 'app-learn-project-home' }">{{$translate('app-learn-back-to-overview')}}</router-link>
        <icons-partial type="arrow-right" size="small" />
      </div>
      <section class="title" v-if="contentRaw">
        <h4>{{content.settings.title}}</h4>
        <p>{{content.settings.description}}</p>
        <div>
          <span v-for="(single, index) in contentRaw.tags" :key="`search-result-data-tags-${index}`">#{{single}} </span>
        </div>
      </section>
    </header-partial>

    <section class="scroll-container">
      <lila-content-module class="contentUpdate" :class="contentAnimation" :routeBase="routeBase" :linkEvents="mode === 'integrated' ? true : false" inline v-if="content" :content="useContent" />
      <lila-content-module class="footer" v-if="searchFooter" inline :content="searchFooter" />
    </section>


  </article>
</template>
<script lang="ts">
import { ExtComponent, Component, Prop } from '@/libs/lila-component';
import { prepareContent } from '@lilaquadrat/studio/lib/frontend';
import { Editor } from '@lilaquadrat/studio/lib/interfaces';
import { state } from '@/store/main.store';

@Component
export default class AppLearnSingleModule extends ExtComponent {

  @Prop(String) mode: 'history' | 'integrated';

  @Prop(Object) content: Editor;

  componentName = 'app-learn-project-content-module';

  routeBase: string = '';

  constructor() {

    super();

    this.routeBase = this.mode === 'integrated'
      ? `/${[state.Company.company, state.Project.project, 'a', 'learn', 'c'].filter((single) => single).join('/')}`
      : '/';

  }

  mounted() {

    this.$store.dispatch('AppLearn/footer');

  }

  showSearch() {

    this.$emit('showType', { type: 'search' });

  }

  get isIntegrated() {

    return this.mode === 'integrated';

  }

  get contentRaw() {

    const useContent = this.mode === 'history'
      ? this.$store.state.AppLearn.single
      : this.content;

    return useContent || null;

  }

  get useContent() {

    console.log(this.$store.state.AppLearn.single);
    return this.mode === 'history'
      ? prepareContent(this.$store.state.AppLearn.single)
      : prepareContent(this.content);

  }

  get searchFooter() {

    return this.$store.state.AppLearn.footer ? prepareContent(this.$store.state.AppLearn.footer, null) : null;

  }


}
</script>
<style lang="less" scoped>


.app-learn-single-module {

  display: grid;

  grid-template-rows: min-content 1fr;
  gap: 20px;
  overflow: hidden;

  .scroll-container {
    display: grid;
    gap: 20px;
    overflow-y: scroll;
  }

  .header-partial {

    .multi(padding, 4, 8);
    .index(8);

    display: grid;
    grid-template-rows: min-content;
    gap: 5px;

    border-bottom: solid 1px @grey1;

    .overview-link {
      display: grid;
      grid-template-rows: min-content;
      grid-template-columns: max-content 1fr;
      gap: 5px;

      a {
        font-size: @fontText;
        .font-head;
      }
    }

    .title {
      display: grid;
      gap: 5px;
    }

  }

  &.isIntegrated {

    .header-partial {
      .multi(padding, 2, 4);

      @media @tablet, @desktop {
        .multi(padding, 2, 8);
      }
    }
  }

  .lila-content-module.footer {
    display: grid;
    .multi(margin-top, 20);
  }
}

</style>

