<template>
  <article class="app-hostingadmin-single-screen" :class="navigationAnimation">
    <side-screen-header-partial>{{$translate('app-hostingadmin-single-command-title')}}</side-screen-header-partial>
    <section class="content-container">

      <data-grid-partial class="content">
        <section class="data-grid-container">

          <section class="data-grid-single full">
            <h4>{{$translate('app-hostingadmin-commands-targetCommand')}}</h4>
            <p>{{ content.target }} {{ content.command }}</p>
          </section>

          <section class="data-grid-single">
            <h4>{{$translate('app-hostingadmin-commands-companyProject')}}</h4>
            <p>{{ content.company }} {{ content.project }}</p>
          </section>

          <section class="data-grid-single">
            <h4>{{$translate('app-hostingadmin-commands-state')}}</h4>
            <p>{{ $translate(`app-hostingadmin-commands-state-${content.state}`) }}</p>
          </section>

        </section>

        <section class="data-grid-container">
          
          <section class="data-grid-single">
            <h4>{{$translate('app-hostingadmin-commands-created')}}</h4>
            <p>{{ $date(content.history.created) }}</p>
          </section>
          
          <section class="data-grid-single">
            <h4>{{$translate('app-hostingadmin-commands-updated')}}</h4>
            <p>{{ $date(content.history.updated) }}</p>
          </section>
          
          <section class="data-grid-single full">
            <h4>{{$translate('app-hostingadmin-commands-data')}}</h4>
            <p>
              {{ content.data }}
            </p>
          </section>
          
        </section>

      </data-grid-partial>


    </section>
  </article>
</template>

<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import { SDKResponse } from '@/libs/StudioSDK';
import { CertificateAction } from '@lilaquadrat/studio/interfaces';
import { HostingCommand } from '@lilaquadrat/studio/lib/interfaces';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppHostingAdminSingleCommandScreen extends ExtComponent {

  componentName = 'app-hostingadmin-company-commands-single';

  get content(): HostingCommand {

    return this.$store.state.AppHostingAdminCommands.single;

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store
      .dispatch('AppHostingAdminCommands/single', params.id);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<CertificateAction>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppHostingAdminCommands/single', preloadedData.data);
    return Promise.resolve();

  }


}
</script>