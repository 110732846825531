<template>
  <article :class="navigationAnimation">

    <side-screen-header-partial>{{$translate('app-editor-project-settings')}}</side-screen-header-partial>
    <router-view :key="`app-editor-settings-${$route.params.link}`" class="content-container"/>

  </article>
</template>

<script lang="ts">
import {
  ExtComponent, Component,
} from '@/libs/lila-component';

@Component
export default class AppEditorSettingsScreen extends ExtComponent {

  state: string = '';

  sidescreen = true;

  componentName = 'app-editor-settings-home-sidescreen';

}

</script>
