<template>
  <section class="compareElement-editor">
    <section class="editor-content-container">

      <input-partial v-model="value.title" placeholder="title">{{$translate('title')}}</input-partial>
      <textarea-partial v-model="value.description" placeholder="description">{{$translate('description')}}</textarea-partial>
      <structures-extension v-model="value.genericData.structures" :structuresRequired="value.required" :models="['listsParticipants', 'customers']" multiple is-generic-data />
    </section>

  </section>
</template>
  
<script lang="ts">
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import { Component } from '@/libs/lila-component';
import ContactCategoryElement from '@/models/ContactCategoryElement.model';

@Component
export default class contactCategoryElementEditor extends PartialEditorComponent<ContactCategoryElement> {}

</script>
