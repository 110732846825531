<template>
  <section class="permissionGroup">

    <template v-if="!value.parent">
      <checkbox-partial no-indicator class="title" :description="$translate('Click to (un)select all.')" @input="toggle($event)">{{$translate(`app-permissions-${title}`)}}</checkbox-partial>
      <checkbox-partial v-for="(scope, index) in scopeData" :key="`checkbox-${index}`" :value="checked(`${title}:${scope}`)" :name="`${title}:${scope}`" @input="update($event, scope)">
        {{ $translate(`app-${title}-permissions-${scope}`) }}
      </checkbox-partial>
    </template>

    <template v-if="value.parent">
      <checkbox-partial no-indicator class="title" :description="$translate('Click to (un)select all.')" @input="toggle($event, value.parent)">{{ $translate(`app-${value.parent}-title`) }} - {{ $translate(`app-${value.parent}-${title}`) }}</checkbox-partial>
      <checkbox-partial v-for="(scope, index) in scopeData" :key="`checkbox-${index}`" :value="checked(`${value.parent}:${title}:${scope}`)" :name="`${title}:${scope}`" @input="update($event, scope, value.parent)">
        {{ $translate(`app-${title}-permissions-${scope}`) }}
      </checkbox-partial>
    </template>


  </section>
</template>

<script lang="ts">
import { Component, Prop, ExtComponent } from '@/libs/lila-component';

@Component
export default class PermissionGroupPartial extends ExtComponent {

  @Prop(Object) value;

  @Prop(Array) values: string[];

  scopeData = [];

  title: string = null;

  scopeArray: string[] = [];

  mounted() {

    this.scopeData = this.value.scope;
    this.title = this.value.group;

    if (this.values) {

      this.scopeArray = this.values;

    }

  }

  update(active: boolean, scope: string, parent?: string) {

    const scopeString = parent ? `${parent}:${this.title}:${scope}` : `${this.title}:${scope}`;

    if (active) {

      if (!this.scopeArray.includes(scopeString)) this.scopeArray.push(scopeString);

    }

    if (!active) {

      if (this.scopeArray.includes(scopeString)) {

        this.scopeArray.splice(this.scopeArray.indexOf(scopeString), 1);

      }

    }

    this.$emit('input', this.scopeArray);

  }

  checked(scope: string) {

    return this.scopeArray.includes(scope);

  }

  toggle(active: boolean, parent?: string) {

    if (active) {

      this.scopeArray = [];

      this.scopeData.forEach((single) => {

        const scope = parent ? `${parent}:${this.title}:${single}` : `${this.title}:${single}`;

        this.scopeArray.push(scope);

      });

    } else {

      this.scopeArray = [];

    }

    this.$emit('input', this.scopeArray);

  }

}
</script>
