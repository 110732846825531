import Models, { ModelDeclaration } from '@/libs/Models.class';
import SelectedFile from './SelectedFile.model';
import PictureSource from './PictureSource.model';

export default interface PicturePartial {
  src: string
  alt: string
  copyright: string
  file: SelectedFile
  source: PictureSource[]
}

const declaration: ModelDeclaration<PicturePartial> = {
  src: { type: 'string' },
  alt: { type: 'string' },
  copyright: { type: 'string' },
  file: { model: 'selected-file' },
  source: { type: 'array', contains: { model: 'picture-source' }, defaultItems: 3 },
};

Models.register('picture-partial', declaration);