<template>
  <article class="picture-editor">
    <picture-partial-editor v-if="!child" v-model="value.picture" />
    <textblock-partial-editor v-if="child === 'textblock'" v-model="value.textblock" />

    <moduleList-partial v-if="!child" class="module-editor-navigation">
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('textblock')">{{$translate('description')}}</button-partial>
      </li>
    </moduleList-partial>
  </article>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import GalleryElement from '@/models/GalleryElement.model';

@Component
export default class GalleryElementEditor extends PartialEditorComponent<GalleryElement> {

  check(event: any) {

    if (event.key === 'Backspace' && this.value.picture.src.length) {

      this.$emit('remove', event);

    }

    if (event.key === 'Enter' && event.target.value.length > 0) {

      this.$emit('add', event);

    }

    this.$emit('keyup', event);

  }

  model = 'gallery-element';

}
</script>
