<template>
  <picture v-if="picture" :class="[mimetype, { notLoaded: !loaded && !error, error }]">
    <template v-if="loaded">
      <source v-for="(source, i) in picture.source" :key="`p-${i}`" v-if="source.source" :media="`${source.media}`" :srcset="source.source" />
      <img v-if="picture.src" :src="picture.src" :alt="picture.alt" />
    </template>
    <div v-if="error" class="alt">
      <icons-partial type="warning" colorScheme="red" />
      {{ $translate('PICTURE_NOT_LOADED_ERROR') }}
    </div>
  </picture>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import inview from '@/libs/lila-inview';

@Component
export default class pictureInternalPartial extends Vue {

  @Prop(Object) picture: {
    src: string;
    alt: string;
    mimetype?: string;
    source?: [
      {
        media: string;
        source: string;
      }
    ];
  };

  loading: boolean = false;

  loaded: boolean = false;

  error: boolean = false;

  view: string;

  @Watch('loading')
  watchLoading() {

    if ((this.loading && !this.loaded) || (this.loading && this.error)) {

      this.preload();

    }

  }

  @Watch('picture')
  watchPicture() {

    this.error = false;
    this.preload();
    inview.checkImage(this);

  }

  get mimetype() {

    if (!this.picture.mimetype) return null;

    const mime = this.picture.mimetype.split('/');

    return mime[1].match(/([a-z]+)/i)[0];

  }

  mounted() {

    if (!this.$el.querySelector) return;

    this.checkInview();
    inview.checkImage(this);

  }

  checkInview() {

    window.addEventListener('scrolled', () => {

      if (!this.loading) inview.checkImage(this);
      inview.checkImage(this);

    });

  }

  preload() {

    if (this.error) return;

    const image = new Image();

    image.src = this.picture.src;

    image.onload = () => {

      this.loaded = true;
      this.$emit('loaded');

    };

    image.onerror = (e: ErrorEvent) => {

      this.error = true;

      this.loading = false;
      this.loaded = false;

      this.$emit('error');

    };

  }

}
</script>
<style lang="less" scoped>

picture {
  display: grid;
  overflow: hidden;
  width: 100%;
  height: 100%;

  // transition: opacity .5s @aType, transform .5s @aType;

  img {
    align-self: center;
    justify-self: center;
    max-width: 100%;
    max-height: 100%;
  }

  &.error {
    // opacity: 1;

    .alt {
      position: absolute;
      top: 0;
      left: 0;
      display: grid;
      gap: 5px;
      align-content: center;
      align-self: center;
      justify-content: center;
      justify-items: center;
      justify-self: center;

      width: 100%;
      height: 100%;
      .index(3);
    }

  }

  // &.started {
  //   opacity: 0;
  //   transform: translateY(20px);
  // }

  // &.resolving, &.loading {
  //   opacity: 0;
  //   transform: translateY(-20px);
  // }
}

</style>
