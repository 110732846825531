<template>
  <section class="footer-module-editor open editor" :class="{ changed: safeContent.changed }">
    <module-editor-header-partial v-model="content" :position="position" :index="index" :type="type" @move="move($event)" />
    <crumbs-partial :type="safeContent.type" @clearCrumbs="unsetCurrentChild" />

    <section :class="{ optionsVisible: !child }" class="editor-module-container">
      <editor-content-group-partial v-if="child === 'legal'" :key="'footer-legal'">
        <input-partial v-model="safeContent.legal" placeholder="legal">{{$translate('legal')}}</input-partial>
      </editor-content-group-partial>

      <template v-if="child === 'contact'">
        <input-partial v-model="safeContent.contact.title" class="editor-content-group" placeholder="description">{{$translate('description')}}</input-partial>
        <link-partial-editor v-model="safeContent.contact.link" placeholder="contact" />
      </template>

      <template v-if="child === 'social'">
        <input-partial v-model="safeContent.social.title" class="editor-content-group" placeholder="social">{{$translate('social')}}</input-partial>
        <list-elements-partial-editor v-model="safeContent.social.elements" type="picturegroupElement-partial" :model="socialListModel" partial />
      </template>

      <template v-if="child === 'sitemap'">
        <list-elements-partial-editor v-model="safeContent.sitemap" type="linklistElement-partial" :model="sitemapListModel" partial />
      </template>

      <picture-partial-editor v-if="!child" v-model="safeContent.picture" />

      <textblock-partial-editor v-if="child === 'textblock'" v-model="safeContent.textblock" />

      <list-partial-editor v-if="child === 'list'" v-model="safeContent.list" />
      <link-list-partial-editor v-if="child === 'links'" v-model="safeContent.links" />

      <moduleList-partial v-if="!child" class="module-editor-navigation">
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('textblock')">{{$translate('textblock')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('list')">{{$translate('list')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('links')">{{$translate('links')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('picture')">{{$translate('picture')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('legal')">{{$translate('legal')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('contact')">{{$translate('contact')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('social')">{{$translate('social')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('sitemap')">{{$translate('sitemap')}}</button-partial>
        </li>
      </moduleList-partial>

      <module-options-partial
        v-if="child === 'options' || !child"
        v-model="safeContent"
        :available-variants="availableVariants"
        :selected-variants="safeContent.variant"
        @setChild="setChild('options', true)"
        @unsetChild="setChild('options')"
        @remove="remove()"
        @clone="clone()"
      />

    </section>
  </section>
</template>
<script lang="ts">
import { Component, ModuleEditorComponent } from '@/libs/ModuleEditorComponent';
import FooterModule from '@/apps/editor/models/FooterModule.model';

@Component
export default class FooterModuleEditor extends ModuleEditorComponent<FooterModule> {

  model = 'footer-module';
  
  socialListModel = 'picturegroup-element';

  sitemapListModel = 'footer-sitemap';

}

</script>
