import Vue from 'vue';
import VueRouter from 'vue-router';
import PortalVue from 'portal-vue';
import Vue2TouchEvents from 'vue2-touch-events';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import '@/libs/Models.class';
import './models';

import log from 'loglevel';
import APPComponent from '@/App.vue';
import store, { state } from '@/store/main.store';
import LoadElements from '@/mixins/loadElements';

import '@/mixins/leadingZero';
import '@/mixins/toFixed';
import '@/mixins/scope';
// import '@/mixins/formatBytes';
import '@/mixins/categories';
import Attributes from '@/mixins/attributes';

import authInstance from '@/libs/lila-auth';

import '@/apps/apps';

import mount from '@/mixins/mount';
import Resize from '@/libs/lila-resize';
import StudioSDK from '@/libs/StudioSDK';
import routes from './routes';
import '@/libs/lila-component';

Attributes();

process.env.NODE_ENV === 'development'
  ? log.enableAll()
  : log.setLevel('warn');

const _window: any = window;
const appObject: any = {

  render: (h: any) => h(APPComponent),

  created: () => {

    setTimeout(() => {

      // Toggle Animations on/off
      if (!state.settings.noAnimation) {

        document.body.classList.add('inited');

      }

      document.body.classList.remove('loading');
      store.commit('init');

      if (window) {

        store.commit('setHeight', window.innerHeight);
        store.commit('setWidth', window.innerWidth);

      }

    }, 1000);

  },

  mounted: () => {

    let hash: any;

    if (document.location.href.indexOf('#') > -1) {

      hash = document.location.href.split('#');

      const element = document.getElementById(hash[1]);

      if (!element) return;

      const offSet = element.offsetTop;

      setTimeout(() => {

        window.scroll(0, offSet);

      }, 500);

    }

  },

};

Vue.prototype.$enviroment = 'browser';
Vue.prototype.$namespace = 'design';

Vue.use(VueRouter);
Vue.use(PortalVue);
Vue.use(Vue2TouchEvents);

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {

    if (to.meta?.noScroll) return;

    if (to.hash.length) {

      return {
        selector: to.hash,
      };

    }

    return { x: 0, y: 0 };

  },
});

appObject.router = router;
appObject.store = store;

/**
 * check if a state is stored and use it
 */
// eslint-disable-next-line no-underscore-dangle
if (_window.__INITIAL_STATE__) {

  // eslint-disable-next-line no-underscore-dangle
  store.replaceState(_window.__INITIAL_STATE__);

}

window.addEventListener('drop', (e) => e.preventDefault());
window.addEventListener('dragover', (e) => e.preventDefault());

window.addEventListener('media', () => {

  store.commit('setMedia', Resize.media);

});

window.addEventListener('resized', () => {

  store.commit('setHeight', window.innerHeight);
  store.commit('setWidth', window.innerWidth);

});

store.commit('setMedia', Resize.media);


LoadElements.components();
LoadElements.extensions();
LoadElements.designComponents();

if (state.settings.mount) {

  /**
   * this will be called if it is a SSR file
  */
  mount(appObject);


} else {

  /**
   * everything in this promisechain is resolved before the APP gets mounted and routed
  */

  if (__IS_MOCK__) {

    store.dispatch('setMockSettings')
      .then(() => {

        authInstance.init(true, undefined);
        mount(appObject);

      });

  } else {

    const sdk = new StudioSDK('system', {});

    sdk.settings.settings()
      .then((settings: any) => {

        store.commit('setSettings', settings.data);
        return store.dispatch('health');

      })
      .then(() => {

        console.log('state.settings.dev', state.settings.dev);
        console.log('state.settings.auth0', state.settings.auth0);

        return authInstance.init(state.settings.dev, state.settings.auth0);

      })
      .then(() => mount(appObject))
      .catch((e) => {

        store.dispatch('initError');
        log.error(e);

      });

  }

}
