<template>
  <article class="select-editor extension" :class="{multiple, error: hasError}">

    <portal to="util">
      <section v-if="selectVisible" class="select-editor-overlay" :class="{multiple}">

        <header>
          <section class="stats">
            <h1>{{$translate(multiple ? `app-editor-select-${type}-title-multiple` : `app-editor-select-${type}-title`)}}</h1>
            <h3 v-if="sites">
              <template v-if="!sites.last">{{ searchContent.count }}</template>
              <template v-if="sites.last">{{ sites.range[0] }} - {{ sites.range[1] }} ({{ searchContent.count }})</template> / {{ searchContent.all }} total
            </h3>
          </section>
          <button-group-partial class="controls">
            <button-partial class="colorScheme2" @click="toggle">{{$translate('app-editor-select-cancel')}}</button-partial>
            <button-partial v-if="multiple" class="colorScheme1" @click="update">{{$translate('app-editor-select-confirm', [`${selected.length}`])}}</button-partial>
          </button-group-partial>
        </header>

        <section class="content-container">
          <quickfilter-partial store="AppEditorExtension" no-push mode="inline" category/>

          <section class="scroll-container">
            <table class="isSticky contentHead">
              <thead-partial :site-current="site" :search="$route.query.search" :sort="sort" :order="order" :content="multiple ? headContentMultiple : headContent" />
            </table>

            <table class="contentUpdate">
              <tbody>
                <tr v-for="(single, index) in searchContent.data" :key="`data-overview-${index}`" :class="{selected: isSelected(single._id.toString())}">
                  <td v-if="multiple" class="select">
                    <checkbox-partial :name="single._id" :value="isSelected(single._id.toString())" @input="updateSelected($event, single._id.toString())" />
                  </td>
                  
                  <td class="id fullHeight action" :class="{ hasDescription: single.description, hasAttribute: single.layout || single.partial || single.target === 'mail' }">
                    <button-partial class="inline" color-scheme="transparent" @click="toggleSelected(single._id.toString())">
                      <div v-if="single.description" class="description" :title="single.description">{{ single.description }} ( {{ single.id }} )</div>
                      <div>
                        <span v-if="single.partial" :title="$translate('partial')" class="attribute">
                          <span class="text-full">{{$translate('partial')}}</span>
                          <span class="text-short">{{$translate('P')}}</span>
                        </span>
                        <span v-if="single.layout" :title="$translate('layout')" class="attribute">
                          <span class="text-full">{{$translate('layout')}}</span>
                          <span class="text-short">{{$translate('L')}}</span>
                        </span>
                        <span v-if="single.target === 'mail'" :title="$translate('mail')" class="attribute">
                          <span class="text-full">{{$translate('mail')}}</span>
                          <span class="text-short">{{$translate('m')}}</span>
                        </span>
                        <span v-if="!single.description" class="name">{{ single.id }}</span>
                        <span v-if="single.settings.filename" class="filenames">{{ single.settings.filename.join(', ') }}</span>
                      </div>
                    </button-partial>
                  </td>

                  <td class="category">
                    <span :title='$categories(single.tags)'>{{$categories(single.tags)}}</span>
                  </td>

                  <td class="publish">
                    <span>
                      <icons-partial v-if='!single.state || single.state === "draft"' :title="$translate('draft')" color-scheme='grey' type='checked' size='small' />
                      <icons-partial v-if='single.state === "publish"' :title="$translate('publish')" type='checked' size='small' />
                    </span>
                  </td>

                  <td class="link">
                    <router-link
                      :title="$translate('app-editor-select-open')"
                      :to="{
                        name: 'app-editor-project-edit',
                        params: {
                          id: single.id,
                          company: $store.state.Company.company,
                          project: $store.state.Project.project,
                        },
                      }"
                    >
                      O
                    </router-link>
                  </td>

                </tr>
              </tbody>
            </table>
          </section>
        </section>

      </section>
    </portal>

    <article class="value-container">
      <header>
        <div class="selected-text" :class="{placeholder: !isSet}">
          <template v-if="!isSet">{{$translate(placeholder)}}</template>
          <button-partial v-if="isSet" color-scheme="transparent" class="text" @click="toggle">
            <template v-if="multiple">{{ $translate('app-editor-select-selected-overview', [`${selected.length}`]) }}</template>
            <template v-if="!multiple">{{renderSingle}}</template>
          </button-partial>
          <button-partial v-if="!required && isSet" color-scheme="transparent" button-content-type="icon" icon="close" @click="remove" />
        </div>
        <button-partial v-if="!isSet" color-scheme="transparent" inline @click="toggle">{{$translate(multiple ? 'app-editor-select-button-title-multiple' : 'app-editor-select-button-title')}}</button-partial>
      </header>
      <div v-if="hasSlot || required || disabled" class="label-container label-replacement">
        <span v-if="hasSlot" class="label">
          <slot />
        </span>
        <span v-if="required && !disabled" class="required">{{$translate('required')}}</span>
        <span v-if="disabled" class="required">{{$translate('disabled')}}</span>
      </div>
      <ul v-if="multiple">
        <template v-for="(single, index) in renderList">

          <dropzone-partial v-if="index === 0 && !child" :key="`pre-list-element-${index}`" :drag-active="dragActive" :source-index="draggedElement" :index="0" @dropped="handleDropped" />

          <li
            :key="`value-${index}`" class="single-element" 
            draggable 
            @mousedown="handleHold($event, index)"
            @mouseup="handleHold($event, index)"
            @dragstart="handledrag($event, index)" 
            @dragend="handledrag($event, index)"
          >
            <div class="id">{{single.id}}</div>
          
            <div class="button-container">
              <button-partial type="button" button-content-type="icon" icon="arrow-up" color-scheme="transparent" size="small" class="mode" @confirmed="move(index, 'up')" />
              <button-partial type="button" button-content-type="icon" icon="arrow-down" color-scheme="transparent" size="small" class="move" @confirmed="move(index, 'down')" />
            </div>
          </li>

          <dropzone-partial v-if="!child" :key="`post-list-element-${index}`" :drag-active="dragActive" :source-index="draggedElement" :index="index + 1" @dropped="handleDropped" />
        
        </template>
      </ul>

      <notice-partial v-if="errorMessage" type="error">
        {{errorMessage}}
      </notice-partial>
    </article>


  </article>
</template>
<script lang="ts">
import { BasicData } from '@lilaquadrat/interfaces';
import { Content, DataObject } from '@lilaquadrat/studio/lib/interfaces';
import { ParsedError } from '@/libs/ActionNotice';
import { SDKResponse } from '@/libs/StudioSDK';
import ArrayTools from '@/libs/lila-array';
import { Component, Prop, Watch } from '@/libs/lila-component';
import { ExtPartial } from '@/libs/lila-partial';
import hardCopy from '@/mixins/hardCopy';

@Component
export default class SelectEditorExtension extends ExtPartial {

  selectVisible: boolean = false;

  order: string = '-1';

  sort: string = 'id';

  dragActive: boolean = false;

  draggedElement = null;

  mouseHold = null;

  headContent = [
    {
      text: 'id',
      classes: 'id',
    },
    {
      text: 'category',
      classes: 'category',
    },
    {
      classes: 'publish',
      text: 'P',
      icon: {
        type: 'publish',
        colorScheme: 'colorScheme1',
        size: 'medium',
      },
    },
    {}
  ];

  headContentMultiple = [
    {},
    {
      text: 'id',
      classes: 'id',
    },
    {
      text: 'category',
      classes: 'category',
    },
    {
      classes: 'publish',
      text: 'P',
      icon: {
        type: 'publish',
        colorScheme: 'colorScheme1',
        size: 'medium',
      },
    },
    {}
  ];

  selected: string[] | string = null;

  @Prop(Boolean) multiple: boolean;

  @Prop(Boolean) required: boolean;

  @Prop(Boolean) disabled: boolean;

  @Prop({type: Boolean, default: null}) layout?: boolean;

  @Prop({type: Boolean, default: null}) partial?: boolean;

  @Prop({type: Boolean, default: null}) isGenericData?: boolean;

  @Prop(String) target?: 'mail' | 'browser';

  @Prop(String) placeholder: string;

  @Prop([Array, String]) value: string[] | string;

  @Prop(Object) error: ParsedError;

  @Watch('site')
  watchSite() {

    this.getContent();

  }

  @Watch('search', {deep: true})
  watchSearch() {

    this.getContent();

  }

  @Watch('value')
  watchValue(newValue, oldValue) {

    if(JSON.stringify(newValue) !== JSON.stringify(oldValue)) {

      this.setSelected();
    
    }

  }

  beforeMount() {

    this.setSelected();

  }

  toggle() {

    this.selectVisible = !this.selectVisible;
    this.$store.commit('setFullscreen', this.selectVisible);

    if(this.selectVisible) this.getContent();

    // this.setSelected();

  }

  update() {

    this.emitUpdate();
    this.toggle();

  }

  setSelected() {

    if((this.multiple || this.isGenericData) && Array.isArray(this.value)) {

      this.selected = hardCopy(this.value || []);
    
    } else if(!Array.isArray(this.value)) {

      this.selected = this.value;
        
    }

    this.updateCache();
  
  }

  get cache() {

    return this.$store.state.Cache.genericDataCache;

  }

  get renderList() {

    if (this.cache.editor) {

      if(this.multiple && Array.isArray(this.selected)) {

        return this.selected.map((single) => {
  
          const content = this.cache.editor[single];
  
          return {
            id: content?.id,
            description: content?.description,
          };
  
        });

      } 

    }

    return [];

  }

  get renderSingle() {

    if (this.cache.editor && typeof this.selected === 'string') {

      const content = this.cache.editor[this.selected];

      return content?.id;

    }


    if (this.cache.editor && this.isGenericData && Array.isArray(this.selected)) {

      const content = this.cache.editor[this.selected[0]];

      return content?.id;

    }

    return this.selected;

  }


  get hasError() {

    return !!this.error?.error;

  }

  get errorMessage() {

    return this.error?.keyword !== 'required' ? this.error?.error : null;

  }

  get type() {

    if(this.layout) return 'layout';
    if(this.partial) return 'partial';
    return 'data';

  }

  get hasSlot() {

    const defaultSlot = this.$slots.default;

    if(!defaultSlot?.length) return false;

    return true;

  }

  get isSet() {

    if(!this.selected) return false;
    if(Array.isArray(this.selected) && !this.selected.length) return false;
    return true;

  }

  get site() {

    return this.$store.state.AppEditorExtension.site;
  
  }

  get sites() {

    return this.searchContent?.sites;
  
  }

  get search() {

    return this.$store.state.AppEditorExtension.search;
  
  }

  get searchContent(): DataObject<BasicData<Content>[]> {

    return this.$store.state.AppEditorExtension.data;

  }

  async getContent() {

    const data = {
      site: this.$store.state.AppEditorExtension.site,
      query: this.$store.state.AppEditorExtension.search
    };

    if(this.layout !== undefined) {

      data.query = {...data.query, layout: this.layout};

      if(this.layout) {
        
        data.query = {...data.query, partial: false};
      
      }

    }

    if(this.partial !== undefined) {

      data.query = {...data.query, partial: this.partial};

      if(this.partial) {
        
        data.query = {...data.query, layout: false};
      
      }

    }

    if(this.target !== undefined) {

      data.query = {...data.query, target: this.target};

    }

    const content: SDKResponse<Content> = await this.$store.dispatch(
      'AppEditorExtension/get', 
      data
    );

    this.$store.commit('AppEditorExtension/data', content.data);

  }

  updateSelected(selected: boolean, id: string) {


    if((this.multiple || this.isGenericData) && Array.isArray(this.selected)) {

      if(selected) {
  
        if(!this.isSelected(id)) this.selected.push(id);
  
      } else {

        const index = this.selected.indexOf(id);
  
        if(index > -1) ArrayTools.remove(index, this.selected);
        
  
      }

    } 


  }

  toggleSelected(id: string) {

    if(!this.multiple && !this.isGenericData) {

      this.selected = id;
      this.update();

    } else {

    this.updateSelected(!this.isSelected(id), id);

      if(this.isGenericData) {

        this.update();

      }
      
    }

  }

  isSelected(id: string) {

    return this.selected?.includes(id);

  }

  // eslint-disable-next-line class-methods-use-this
  handledrag(event: DragEvent, index: number) {

    if (event.type === 'dragstart') {

      setTimeout(() => {

        this.dragActive = true;
        this.draggedElement = index;

      }, 250);

    }

    if (event.type === 'dragend') {

      this.dragActive = false;
      this.draggedElement = undefined;

    }

    }

  handleDropped(dropzone: {index: number}) {

    console.log(this.draggedElement, 'to', dropzone.index);
    this.move(this.draggedElement, undefined, dropzone.index);

  }

    // eslint-disable-next-line class-methods-use-this
  handleHold(event: DragEvent | MouseEvent, index: number) {

    if (event.type === 'mouseup') {

      this.dragActive = false;
      clearTimeout(this.mouseHold);
      return;

    }

    this.mouseHold = setTimeout(() => {

      event.preventDefault();
      this.dragActive = true;
      this.draggedElement = index;

    }, 500);

  }

  move(index: number, direction?: string, targetIndex?: number) {

    if(!this.multiple || !Array.isArray(this.selected)) return;

    let to: number;

    if (targetIndex || targetIndex === 0) {

      to = targetIndex;
      /**
      * if the module is moved down inside of the current position (top, content, bottom), the index muste be lowered
      * because the module gets first removed and then inserted again
      */
      if (to > index) to -= 1;

    } else {

      to = direction === 'up' ? index - 1 : index + 1;

    }


    if (to < 0) return;
    if (index > this.selected.length - 1) return;

    this.selected.splice(to, 0, this.selected.splice(index, 1)[0]);

    this.emitUpdate();
  
}

  remove() {

    if(this.required) return;

    if(this.multiple || this.isGenericData) {

      this.selected = [];

    } else {

      this.selected = '';
      
    }

    this.emitUpdate();

  }

  updateCache() {

    
    if(this.selected?.length) {
      
      this.$store.dispatch('AppEditorData/editGenericDataIndex', { add: this.multiple ? this.selected : [this.selected], type: 'editor' });

      return this.$store.dispatch(
        'Cache/genericDataCache',
        {
          idArray: (this.multiple || this.isGenericData) ? this.selected : [this.selected],
          type: 'editor',
        },
      );

    }

    return Promise.resolve();

  }

  async emitUpdate() {

    await this.updateCache();

    console.log(this.selected);

    this.$emit('input', this.selected);

  }


}
</script>
<style lang="less" scoped>

.select-editor.extension {

  .value-container {

    display: grid;
    gap: 5px;

    header {
      display: grid;
      grid-template-columns: 1fr min-content;
      border-bottom: solid 1px @textColor;

      h3 {
        display: grid;
        align-content: center;
        .font-normal;
        text-transform: uppercase;
      }

      .selected-text {
        .font-normal;
        display: grid;
        grid-template-columns: max-content min-content;
        align-content: center;
        align-items: center;
        font-size: @fontTextSmaller;
        text-transform: uppercase;

        .text {
          display: grid;
          line-height: @buttonLineHeight;
          height: @buttonHeight;
        }

        &.placeholder {
          color: @textColor;
        }
      }

    }

    .label-replacement {
      // .multi(padding, 2, 0);
      display: grid;
      grid-template-columns: 1fr min-content;
    }

    ul {

      li.single-element {

        display: grid;
        grid-template-columns: 1fr min-content;

        .id {
          display: grid;
          align-content: center;
          font-size: @fontTextSmaller;
          text-transform: uppercase;
          cursor: grab;
        }

        .button-container {
          display: grid;
          grid-template-columns: min-content min-content;
        }

      }

    }

  }

}

.select-editor-overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: min-content 1fr;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: @white;
  .index(10);

  header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    .basePaddingWithTop;
  }

  .content-container {
    display: grid;
    grid-template-rows: min-content 1fr;
    overflow: hidden;
  }

  .scroll-container {
    overflow: scroll;
  }

  table {

    display: grid;

    &.isSticky {
      position: sticky;
      top: 0;
      border-spacing: 0;
      background-color: @grey2;
      .index(1);
    }

    thead::v-deep {

      tr {

        td {
          align-items: center;
          height: 30px;

          &.publish {
            align-items: center;
            justify-items: center;

            span {
              display: grid;
              height: 30px;
              cursor: help;
            }
          }
        }
      }
    }

    tbody {

      tr {

        td {
          height: 75px;
        }
      }

    }

    tbody, thead::v-deep {

      tr {
        .basePadding;
        display: grid;
        grid-template-columns: 10fr 25px 40px;

        @media @tablet, @desktop {
          grid-template-columns: 10fr 250px 25px 40px;
        }

        td {
          display: grid;
          padding: 0;

          &.publish {
            align-items: center;
            justify-items: center;

            span {
              display: grid;
              cursor: help;
            }
          }

          &.select {
            align-content: center;
          }

          &.link {
            align-content: center;
          }

          &.category {

            display: none;
            align-content: center;
            justify-content: start;

            @media @tablet, @desktop {
              display: grid;
            }

            span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

          }

          button {

            .description, .name {
              .font-bold;
              color: @color1;
            }
  
            .filenames {
              display: grid;
              grid-column-start: 1;
              grid-column-end: 3;
            }

            line-height: 16px;

          }  


          &.action {

            grid-template-columns: 1fr;

            button {
              display: grid;
              align-content: center;
              justify-content: start;
              width: 100%;
              height: 75px;

              text-align: left;
            }

            &.hasDescription {

              button {
                gap: 3px;
                line-height: 16px;
                white-space: nowrap;

                .description, .name {
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                .description {
                  color: @color1;
                }

                .name {
                  color: @grey;
                }
              }
            }

            &.hasAttribute {

              button {

                div {
                  display: grid;
                  grid-template-columns: max-content 1fr;
                  gap: 5px;
                }

                .attribute {
                  align-self: center;
                  height: 16px;
                  line-height: 16px;
                }

              }
            }

          }

        }

      }
    }

  }

  &.multiple {

    table {

      tbody, thead::v-deep {

        tr {

          grid-template-columns: 50px 10fr 25px 40px;

          @media @tablet, @desktop {
            grid-template-columns: 50px 10fr 250px 25px 40px;
          }
        }

      }
    }
  }

}

</style>
