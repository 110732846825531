<template>
  <article class="app-lists-single-screen" :class="navigationAnimation">
    <side-screen-header-partial>{{$translate(edit ? 'app-lists-edit-title' : 'app-lists-add-title')}}</side-screen-header-partial>
    <section class="content-container">

      <form v-if="model" class="content" @submit="save">
        <copy-partial :id="copyId" :value="model" @paste="handlePaste" />

        <fieldset>
          <legend><span class="text">{{$translate('app-lists-base')}}</span></legend>

          <input-partial v-model="model.name" required :error="errorsObject.name" placeholder="app-lists-name">{{$translate('app-lists-name')}}</input-partial>
          <textarea-partial v-model="model.description" placeholder="app-lists-description">{{$translate('app-lists-description')}}</textarea-partial>
          <select-partial v-model="model.state" required :error="errorsObject.state" placeholder="app-lists-state" :options="stateOptions" :multiple="false">{{$translate('app-lists-state')}}</select-partial>
          <select-partial :disabled="edit" v-model="model.mode" required :error="errorsObject.mode" placeholder="app-lists-mode" :options="modeOptions" :multiple="false">{{$translate('app-lists-mode')}}</select-partial>

        </fieldset>

        <fieldset v-if="model.mode !== 'shopping'">
          <legend><span class="text">{{$translate('app-lists-participants')}}</span></legend>

          <input-partial v-model="model.participants.max" type="number" placeholder="app-lists-participants-max">{{$translate('app-lists-participants-max')}}</input-partial>
          <checkbox-partial v-model="model.participants.unique">{{$translate('app-lists-participants-unique')}}</checkbox-partial>
          <checkbox-partial v-model="model.participants.addressRequired" description="app-lists-participants-addressRequired-description">{{$translate('app-lists-participants-addressRequired')}}</checkbox-partial>

        </fieldset>

        <fieldset v-if="model.mode !== 'shopping'">
          <legend><span class="text">{{$translate('app-lists-payment')}}</span></legend>

          <select-partial v-model="model.payment" required :error="errorsObject.payment" placeholder="app-lists-title-payment" :options="paymentOptions" :multiple="false">{{$translate('app-lists-payment')}}</select-partial>

        </fieldset>


        <fieldset>
          <legend><span class="text">{{$translate('app-lists-content')}}</span></legend>

          <section class="content-selection-container">
            <description-partial>
              {{$translate('app-lists-content-description')}}
            </description-partial>

            <app-lists-content-partial :mode="model.mode === 'shopping' ? 'shopping' : undefined" v-model="model.content"/>

          </section>

        </fieldset>

        <fieldset>
          <legend><span class="text">{{$translate('app-lists-emails')}}</span></legend>

          <section class="content-selection-container">
            <description-partial>
              {{$translate('app-lists-emails-description')}}
            </description-partial>
            
            <list-elements-partial-editor
              :errors="errorsObject['categories-elements']"
              type="app-lists-emails-element-partial"
              :translations="translationsEmails"
              :model="emailsModel"
              :value="model.emails"
              partial
              inline
              @input="update($event, 'emails')"
            />

          </section>

        </fieldset>

        <fieldset>
          <legend><span class="text">{{$translate('app-lists-cateogries')}}</span></legend>

          <section class="categories-container">
            <description-partial>
              {{$translate('app-lists-categories-description')}}
            </description-partial>

            <list-elements-partial-editor
              :errors="errorsObject['categories-elements']"
              type="app-lists-categories-element-partial"
              list-title="name"
              :translations="translationsCategories"
              :model="categoriesModel"
              :value="model.categories"
              partial
              inline
              @input="update($event, 'categories')"
            />

          </section>

        </fieldset>

        <fieldset>
          <legend><span class="text">{{$translate('app-lists-agreements')}}</span></legend>

          <section class="categories-container">
            <description-partial>
              {{$translate('app-lists-agreements-description')}}
            </description-partial>

            <list-elements-partial-editor
              :errors="errorsObject['agreements-elements']"
              type="app-lists-agreements-element-partial"
              list-title="contentId"
              :translations="translationsAgreements"
              :model="agreementsModel"
              :value="model.agreements"
              :cache="{type: 'editor', key: 'contentId'}"
              partial
              inline
              @input="update($event, 'agreements')"
            />

          </section>

        </fieldset>

        <action-notice-partial :state="state" :translation-pre="translationPre" :errors="errors" @update="updateErrors">
          <button-partial v-if="edit" type="button" class="base colorScheme2" doublecheck @confirmed="remove">{{$translate('app-lists-remove-button')}}</button-partial>
          <button-partial v-model="state" type="submit" save>
            <template v-if="!edit">{{$translate('app-lists-create-button')}}</template>
            <template v-if="edit">{{$translate('app-lists-edit-button')}}</template>
          </button-partial>
        </action-notice-partial>

      </form>

    </section>
  </article>
</template>

<script lang="ts">
import { Component } from '@/libs/lila-component';
import { EditComponent } from '@/libs/EditComponent';
import { List, Publish } from '@lilaquadrat/studio/lib/interfaces';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';
import hardCopy from '@/mixins/hardCopy';
import ModelsClass from '@/libs/Models.class';
import AppListsList from '../models/list.model';

@Component
export default class AppListsSettingsScreen extends EditComponent<AppListsList> {

  componentName = ['app-lists-project-add', 'app-lists-project-single-edit'];

  copyId = 'app-lists-projects-add-edit';

  useModel = 'app-lists-list';

  edit = false;

  editor = ['kontakt', 'kunden'];

  editorSingle = 'single';

  categoriesModel = 'app-lists-categories';

  agreementsModel = 'app-lists-agreements';

  emailsModel = 'app-lists-emails';

  translationPre = 'app-lists';

  stateOptions = [
    {
      text: 'app-lists-state-open',
      value: 'open',
    },
    {
      text: 'app-lists-state-closed',
      value: 'closed',
    },
    {
      text: 'app-lists-state-waiting',
      value: 'waiting',
    },
  ];

  modeOptions = [
    {
      text: 'app-lists-mode-contact',
      value: 'contact',
      description: 'app-lists-mode-contact-description'
    },
    {
      text: 'app-lists-mode-content',
      value: 'content',
      description: 'app-lists-mode-content-description'
    },
    {
      text: 'app-lists-mode-reservation',
      value: 'reservation',
      description: 'app-lists-mode-reservation-description'
    },
    {
      text: 'app-lists-mode-shopping',
      value: 'shopping',
      description: 'app-lists-mode-shopping-description'
    },
  ];

  paymentOptions = [
    {
      text: 'app-lists-payment-free',
      value: 'free',
    },
    {
      text: 'app-lists-payment-optional',
      value: 'optional',
    },
    {
      text: 'app-lists-payment-required',
      value: 'required',
    },
  ];

  translationsCategories = {
    add: 'app-lists-categories-add',
    noTitle: 'app-lists-categories-no-title',
  };

  translationsEmails = {
    add: 'app-lists-emails-add',
    noTitle: 'app-lists-emails-no-title',
  };

  translationsAgreements = {
    add: 'app-lists-agreements-add',
    noTitle: 'app-lists-agreements-no-title',
  };

  get content(): Publish {

    return this.$store.state.AppLists.single;

  }

  beforeMount() {

    this.edit = this.$route.name === 'app-lists-project-single-edit';

    let useContent: Partial<AppListsList>;

    console.log(this.edit, this.content);

    if(this.edit) {

      useContent = this.content;
    
    } else {

      useContent = { 
        payment: 'free', 
        state: 'waiting', 
        agreements: [
          {
            text: 'beschreibung', 
            contentId: 'contact-dsgvo', 
            required: true, 
            predefined: true, 
          }
        ]
      };

    }

    this.model = ModelsClass.add<AppListsList>(useContent, 'app-lists-list');

  }

  update(data, type: 'categories' | 'agreements' | 'emails') {

    this.model[type] = data;

  }

  remove() {

    this.$store.dispatch('AppLists/remove', this.$route.params.id)
      .then(() => {

        this.state = 'success';
        StudioSDK.flushCache('listParticipants', 'list');
        return true;

      })
      .catch((e) => {

        console.error(e);
        this.state = 'error';
        StudioSDK.flushCache('lists', 'list');

      });


  }

  save($event: MouseEvent) {

    let action: string;
    let data: List | {id: string, data: List};

    $event.preventDefault();

    this.state = '';

    const saveModel = this.model.mode === 'shopping' 
    ? 'app-lists-list-shopping'
    : 'app-lists-list';
    const model = {
      ...hardCopy(ModelsClass.save(this.model, saveModel)),
      ...this.$store.getters.CompanyProject,
    };


    this.errors = null;

    if (this.edit) {

      action = 'AppLists/update';
      data = { id: this.$route.params.id, data: model };

    } else {

      action = 'AppLists/add';
      data = {
        ...model,
      };

    }

    console.log('save', action, data);


    this.$store.dispatch(action, data)
      .then((response) => {

        this.state = 'success';

        if (!this.edit) {

          this.$router.push({ name: 'app-lists-project-single-edit', params: { id: response.data._id } });

        } else {

          StudioSDK.flushId(this.$route.params.id);

        }

        return true;

      })
      .catch((e) => {

        this.errors = e.response.data;
        this.state = 'error';

      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    if (!params.id) return Promise.resolve();

    return store.dispatch('AppLists/single', params.id);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<List>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    if (preloadedData) store.commit('AppLists/single', preloadedData.data);
    return Promise.resolve();

  }


}
</script>
<style lang="less">

.app-lists-single-screen {

  .categories-container {
    display: grid;
    gap: 10px;
  }

  .content-selection-container  {
    display: grid;
    gap: 30px;
  }

}
</style>
