<template>
  <div id="app">
    <router-view :key="`module-${$route.params.link}`" />
    <portal-target name="overlay" multiple />
    <portal-target name="util" multiple />
    <loading-partial v-if="loading" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from './store/main.store';

const store = useStore();
const loading = computed(() => store.state.Navigation.status === 'loading');
</script>

<style lang="less">

@import "@/assets/less/editor.less";

</style>