import GenericStore from '@/interfaces/GenericStore.interface';
import MainStore, { state } from '@/store/main.store';
import { DataObject, HostingAdmin } from '@lilaquadrat/studio/lib/interfaces';
import StudioSDK from '@/libs/StudioSDK';
import AppHostingAdminDomains from '../interfaces/domains-state.interface';

const AppHostingAdminStore: GenericStore<AppHostingAdminDomains> = {
  namespaced: true,
  strict: true,

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },


  state: {

    data: {},

    search: {
      tags: [],
      search: null,
      type: null,
      sort: '_id',
      order: '1',
    },

    single: null,

  },

  mutations: {

    data(store, data: DataObject<HostingAdmin>) {

      store.data = data;

    },

    single(store, data: HostingAdmin) {

      store.single = data;

    },


    updateSearch(appHostingAdminState, update: { data: any, type: string }) {

      console.log(update, update.data.length, !update.data.length, appHostingAdminState.search);

      if(!['filterState', 'order', 'sort', 'search'].includes(update.type)) return;

      if (!update.data.length) {

        appHostingAdminState.search[update.type] = null;
        return;

      }

      console.log(update, appHostingAdminState.search);

      appHostingAdminState.search[update.type] = update.data;

    },

    setSearch(appHostingAdminState, search: { tags?: string[], search?: string, filterState?: string }) {

      appHostingAdminState.search = search;

    },

  },

  actions: {

    get(store, data: { site?: number, query: {state: string[], sort: string, order: string}}) {

      const sdk = new StudioSDK('hostingadmin-app', AppHostingAdminStore.sdkOptions());

      return sdk.hosting.domains.admin.list(data.site, data.query);

    },

    single(store, id: string) {

      const sdk = new StudioSDK('hostingadmin-app', AppHostingAdminStore.sdkOptions());

      return sdk.hosting.domains.admin.single(id);

    },

    request(store, internalId: string) {

      const sdk = new StudioSDK('hostingadmin-app', AppHostingAdminStore.sdkOptions());

      return sdk.hosting.domains.admin.request(internalId);

    },

    /**
     * This action will be called, when the app will be activated
     */
    activate(store, params) {

      return Promise.resolve(true);

    },
  },

};

export default AppHostingAdminStore;
