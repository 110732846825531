<template>
  <section class="training-module-editor open editor" :class="{ changed: safeContent.changed }">
    <module-editor-header-partial v-model="safeContent" :position="position" :index="index" :type="type" @move="move($event)" />
    <crumbs-partial :type="safeContent.type" @clearCrumbs="unsetCurrentChild" />

    <section :class="{ optionsVisible: !child }" class="editor-module-container">

      <!-- <search-partial-editor v-if="!child" v-model="safeContent.genericData.editor" placeholder="app-editor-training-search" /> -->
      <editor-content-group-partial v-if="!child">
        <select-editor-ext v-model="safeContent.genericData.editor" multiple>{{ $translate('app-editor-training-search') }}</select-editor-ext>
      </editor-content-group-partial>

      <moduleList-partial v-if="!child" class="module-editor-navigation">
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('textblock')">{{$translate('textblock')}}</button-partial>
        </li>
      </moduleList-partial>

      <textblock-partial-editor v-if="child === 'textblock'" v-model="safeContent.textblock" />

      <module-options-partial
        v-if="child === 'options' || !child"
        v-model="safeContent"
        :available-variants="availableVariants"
        :selected-variants="safeContent.variant"
        @setChild="setChild('options', true)"
        @unsetChild="setChild('options')"
        @remove="remove()"
        @clone="clone()"
      />

    </section>
  </section>
</template>
<script lang="ts">
import { Component, ModuleEditorComponent } from '@/libs/ModuleEditorComponent';
import TrainingModule from '../models/TrainingModule.model';


@Component
export default class TrainingModuleEditor extends ModuleEditorComponent<TrainingModule> {

  // mounted() {

  //   SearchElementEditor.updateCache(this.safeContent.childData, this.$store);

  // }

  // removeExtended() {

  //   SearchElementEditor.removeChildData(this.safeContent.childData, this.$store).then(() => this.remove());

  // }

  model = 'training-module';

}
</script>
<style lang="less">

.training-module-editor {

  .module-editor-navigation {
    .multi(margin-top, 4);
  }

}
</style>
