// import PartialModel from '@/libs/lila-model';
// import { App } from '@lilaquadrat/studio/lib/interfaces';

// export default class AppAppModel extends PartialModel {

//   _id = '';

//   id = '';

//   name = '';

//   description = '';

//   icon = '';

//   available = '';

//   state = '';

//   companyAvailable: boolean = false;

//   projectAvailable: boolean = false;

//   active: boolean = false;

//   universal: boolean = false;

//   models: string[] = [];

//   attributes: 'internalPreview'|'publicPreview'|'paid'[] = [];

//   // eslint-disable-next-line class-methods-use-this
//   legacy = (data: any) => {

//     console.log(data, data.available);

//     if (typeof data.available === 'boolean' && data.available === true) {

//       data.available = 'public';

//     }

//     if (typeof data.available === 'boolean' && data.available === false) {

//       data.available = 'unavailable';

//     }

//     if (!data.available) {

//       data.available = 'unavailable';

//     }

//     return data;

//   };

// }

import Models, { ModelDeclaration } from '@/libs/Models.class';

export default interface AppAppstore {
  _id: string
  id: string
  name: string
  description: string
  icon: string
  available: string
  state: string
  companyAvailable: boolean
  projectAvailable: boolean
  active: boolean
  universal: boolean
  models: string[]
  attributes: string[]
}

const declaration: ModelDeclaration<AppAppstore> = {
  _id: { type: 'string' },
  id: { type: 'string' },
  name: { type: 'string' },
  description: { type: 'string' },
  icon: { type: 'string' },
  available: { type: 'string' },
  state: { type: 'string' },
  companyAvailable: { type: 'boolean', default: false },
  projectAvailable: { type: 'boolean', default: false },
  active: { type: 'boolean', default: false },
  universal: { type: 'boolean', default: false },
  models: { type: 'array', contains: { type: 'string' } },
  attributes: { type: 'array', contains: { type: 'string' } },
};


Models.register('app-appstore', declaration);