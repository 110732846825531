import InstallableApp from '@/interfaces/InstallableApp.interface';
import routes from './routes';
import translations from './translations/translations';

const app: InstallableApp = {
  name: 'team',
  publisher: 'lilaquadrat',
  translations,
  routes,
};

export default app;
