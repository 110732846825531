<template>
  <section class="content-head">
    <h3><slot /></h3>
    <button-partial button-content-type="icon" icon="close" size="medium" color-scheme="colorScheme2" @click="close" />
  </section>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class contentHeadPartial extends Vue {

  close() {

    this.$emit('close');

  }


}
</script>
<style lang="less" scoped>

.content-head {

  display: grid;
  grid-template-columns: 1fr min-content;
  box-shadow: 0 5px 5px -5px @grey;
  .index(5);

  h3 {
    background-color: @white;
    color: @color1;
    .multi(padding, 0, 8);
    display: grid;
    align-content: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

  }

}
</style>
