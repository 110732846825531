<template>
  <section
    :class="{
      active, dragActive, option, deactivated: noValidTarget,
    }"
    class="dropzone"
    @dragenter="activate"
    @dragleave="deactivate"
    @drop="drop">
    <icons-partial v-if="icon" :color-scheme="active ? 'grey' : 'colorScheme1'" :type="icon" animate />
    <span v-if="option"><slot /></span>
    <hr v-if="!option" />
  </section>
</template>

<script lang="ts">
import { ExtComponent, Component, Prop } from '@/libs/lila-component';

@Component
export default class dropzonePartial extends ExtComponent {

  @Prop(Number) index: number;

  @Prop(Number) sourceIndex: number;

  @Prop(String) sourceContainer: string;

  @Prop(String) targetContainer: string;

  @Prop(Boolean) dragActive: boolean;

  @Prop(Boolean) option: boolean;

  @Prop(String) icon: string;

  @Prop(String) type: string;

  active: boolean = false;

  deactivated: boolean = false;

  get noValidTarget() {

    if (this.option) return false;

    if (!this.targetContainer) {

      return this.index === this.sourceIndex || this.index === this.sourceIndex + 1;

    }

    return (this.index === this.sourceIndex || this.index === this.sourceIndex + 1) && this.targetContainer === this.sourceContainer;

  }

  activate() {

    if (this.noValidTarget) {

      this.active = false;
      return;

    }


    this.active = true;


  }

  deactivate() {

    this.active = false;

  }

  drop(event: DragEvent) {

    this.active = false;
    this.$emit('drop', event);

    if (!this.option) {

      this.$emit('dropped', { index: this.index, targetContainer: this.targetContainer });

    }

  }

}
</script>
<style lang="less" scoped>

.dropzone {
  .trans(all);
  display: grid;
  height: 15px;
  pointer-events: none;

  hr {
    .trans(all);
    align-self: center;
    justify-self: center;
    // width: calc(100% - 40px);
    width: 100%;
    height: 2px;
    border: 0;
    border: dashed 1px transparent;
    pointer-events: none;
  }

  span {
    text-transform: uppercase;
    pointer-events: none;
  }

  &.dragActive {
    height: 15px;
    pointer-events: all;

    hr {
      border-color: @grey;
    }

    &.deactivated {
      pointer-events: none;

      hr {
        border: dashed 1px transparent;
      }
    }
  }

  &.active {
    height: 40px;

    hr {
      border-color: @color1;
    }
  }

  &.option {
    display: grid;
    gap: 5px;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    height: 100%;
    background-color: @grey1;
    color: @textColor;
    text-align: center;
    .font-head;

    &.active {
      background-color: @color1;
      color: @white;
    }
  }
}

</style>
