<template>
  <section class="media-sites-filter-partial">
    <section class="filter-text">
      <span class="group">
        <input-partial debounce="500" class="filter" placeholder="filename" :value="safeSearch.filename" @input="updateSearch($event, 'filename')">{{$translate('filename')}}</input-partial>
        <tags-partial class="filter" filterMode selectedPosition="right" :value="safeSearch.tags" placeholder="Tags" type="media" @input="updateSearch($event, 'tags')">TAGS</tags-partial>
      </span>
    </section>

    <button-partial v-if="reload" icon="replay" color-scheme="transparent" button-content-type="icon" @click="$emit('reload')" />
    <sites-partial v-if="content" @update="updateSearch($event, 'site')" :mode="inline ? 'inline' : 'history'" paramKey="site" :data="content" />

    <section class="folder-container" v-if="safeSearch.folder">
      <button-partial buttonContentType="iconText" icon="close" size="small" colorScheme="colorScheme2" @confirmed="updateSearch(null, 'folder')">{{ safeSearch.folder }}</button-partial>
    </section>
  </section>
</template>
<script lang="ts">
import { ExtComponent, Prop } from '@/libs/lila-component';
import Component from 'vue-class-component';

@Component
export default class mediaViewPartial extends ExtComponent {

  @Prop(Object) search: { tags: string; filename: string; folder: string };

  @Prop(Object) content;

  @Prop(Boolean) inline: boolean;

  @Prop(Boolean) reload: boolean;

  updateSearch(data: any, type: string) {

    this.$emit('update', { data, type });

  }

  get safeSearch() {

    return this.search || {};

  }

}
</script>
<style lang="less">

.media-sites-filter-partial {
  .basePadding;
  .multi(padding-top, 4);
  .multi(padding-bottom, 4);

  display: grid;
  grid-template-rows: min-content;
  grid-template-columns: 1fr max-content max-content;

  gap: 10px;

  .filter-text {
    display: grid;

    grid-template-columns: max-content 100px max-content;
    gap: 0 10px;

    .group {
      .multi(margin-right, 1);

      .description {
        color: @grey;
        text-transform: uppercase;
        .multi(margin-right, 1);
      }

      .tag {
        margin-right: 2px;
        background-color: @color3;
        color: @white;
        font-size: @fontTextSmaller;
        text-transform: uppercase;

        .multi(padding, 1, 2);
      }

      label {

        margin: 0;

        input {
          max-width: 100px;
          padding: 0;
          border-bottom: solid 1px @grey;
          font-size: @fontTextSmaller;
          line-height: 33px;
        }

        .label {
          display: none;
        }
      }
    }
  }

  .filter-text {
    grid-template-columns: 1fr;
    width: 100%;

    .group {
      display: grid;
      grid-template-columns: max-content min-content;
      gap: 10px;
      margin: 0;

      .tags-search {

        // .values {
        //   display: grid;
        //   gap: 5px;
        // //   grid-template-columns: 200px max-content;

        //   label {
        //     grid-column-start: 1;
        //     grid-row-start: 1;

        //     input {
        //       max-width: 200px;
        //     }

        //   }

        //   span {
        //     grid-column-start: 2;
        //     grid-row-start: 1;

        //     .single {
        //       margin: 0;
        //       margin-right: 5px;
        //       padding: 0;
        //       line-height: 21px;

        //       .unset {
        //         line-height: 21px;
        //       }

        //     }

        //   }

        // }

      }
    }
  }
}
</style>
