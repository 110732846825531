<template>
  <article :class="navigationAnimation" class="company-home-screen">

    <header-partial />

    <companies-module showFavorites :company="company" />
    <modal-module>{{$translate('settings')}}</modal-module>

  </article>
</template>

<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';

@Component
export default class CompanyHomeScreen extends ExtComponent {

  componentName = 'company-home';

  sortOrder: number = -1;

  sortKey: string = 'filename';

  mount: boolean = false;

  actions: unknown[] = [
    {to: {name: 'project-create'}, scope: ['project:create'], text: 'create project', matchingRoutes: ['company-home']},
    {to: {name: 'company-create'},  attributes: ['partner'], scope: ['company:create'], text: 'create company', matchingRoutes: ['company-home']}
  ];

  mounted() {

    this.DOM.title = `${this.$store.state.Company.company} Projects`;

  }

  get company() {

    return this.$route.params.company;

  }

}
</script>
