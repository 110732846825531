<template>
  <article
    v-if="publish"
    class="app-publish-status extension"
    :class="{
      indicator: indicator, isRunning, failed, success,
    }">

    <template v-if="indicator">
      <icons-partial v-if="isRunning" type="clock" />
      <icons-partial v-if="!isRunning && success" type="checked" colorScheme="green" />
      <icons-partial v-if="!isRunning && failed" type="warning" colorScheme="red" />
    </template>

    <template v-if="!indicator && publish.state">
      <section class="title">
        <section class="method">{{$date(publish.history.created, null, true)}} </section>
        <router-link class="state" :to="{ name: 'app-publish-project-single', params: { id: publish._id } }">
          <icons-partial v-if="isRunning" type="clock" />
          <icons-partial v-if="!isRunning && success" type="checked" colorScheme="green" />
          <icons-partial v-if="!isRunning && failed" type="warning" colorScheme="red" />
          {{$translate(`app-publish-state-${publish.state}`)}}
        </router-link>
      </section>
      <section class="infos">
        <section>
          {{publish.publishMethod.label}} ({{publish.publishMethod.type}})
        </section>
        <indicator-partial v-if="isRunning" small />
      </section>
    </template>

  </article>
</template>
<script lang="ts">
import {
  Component, ExtComponent, Prop, Watch,
} from '@/libs/lila-component';
import { Publish } from '@lilaquadrat/studio/lib/interfaces';

@Component
export default class AppPublishExtension extends ExtComponent {

  @Prop({ required: true, type: String }) _id: string;

  @Prop(Boolean) indicator: boolean;

  interval: any = null;

  open: boolean = false;

  publishId: string = '';

  @Watch('publish')
  watchPublishFunction() {

    this.update();

  }

  toggle() {

    this.open = !this.open;

  }

  mounted() {

    this.update();

  }

  destroyed() {

    clearInterval(this.interval);
    this.interval = null;

  }

  get publish(): Partial<Publish> {

    const publish = this.$store.state.AppPublish.statusTracker.find((single) => single.referenceId === this._id);

    if (publish) {

      if (this.publishId !== publish._id) {

        this.publishId = publish._id;
        this.$store.dispatch('AppPublish/updateStatus', this.publish._id)
          .then(() => {

            this.update();

          });

      }

      return publish;

    }

    return null;


  }

  get isRunning() {

    return ['new', 'planned', 'inProgress', 'inQueue'].includes(this.publish.state);

  }

  get success() {

    return this.publish.state === 'success';

  }

  get failed() {

    return ['failed', 'partialSuccess', 'noAction', 'canceled'].includes(this.publish.state);

  }

  update() {


    if (!this.publish?._id) return;

    if (this.interval && this.publish.state && !this.isRunning) {

      clearInterval(this.interval);
      this.interval = null;
      return;

    }

    if (!this.interval && (!this.publish?.state || this.isRunning)) {


      this.interval = setInterval(() => {

        this.$store.dispatch('AppPublish/updateStatus', this.publish._id);

      }, 5000);

    }


  }


}
</script>
<style lang="less" scoped>


.app-publish-status.extension {

  display: grid;
  .multi(padding, 0, 4);

  .infos, .title {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
    align-items: center;
    height: @headlineLineHeight_M;
  }

  .title {
    .multi(padding-bottom, 1);
    border-bottom: solid 1px @grey;

    .state {
      display: grid;
      grid-template-columns: min-content max-content;
      gap: 5px;
      justify-content: end;
      color: @color1;
      font-size: @headline_XS;
      .font-head;
    }
  }

  &.failed {

    .title {
      .state {
        color: @error;
      }
    }

  }
  &.success {

    .title {
      .state {
        color: @success;
      }
    }

  }

  .infos {

    .loading-indicator {
      justify-self: end;

    }

  }

  &.indicator {
    display: grid;
    align-content: center;
    padding: 0;
  }


}

</style>
