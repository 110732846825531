import { StudioIframeMessage } from '@lilaquadrat/interfaces';


const timeoutCache: Record<string, number> = {};

/**
 * sends data to the iframe
 */
function updateIframe(iframe: Window, message: StudioIframeMessage['data']): void {

  if (!iframe) {

    console.warn('IFRAME_NOT_SET');
    

  }

  clearTimeout(timeoutCache[message.type]);

  timeoutCache[message.type] = setTimeout(() => {

    iframe.postMessage(message, '*');
    
  }, 150) as unknown as number;


}


export default updateIframe;
