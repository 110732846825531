import Models, { ModelDeclaration } from '@/libs/Models.class';

export default interface Company {
  name: string
  id: string
  company: string
  description: string
  streetNumber: string
  zipcode: string
  city: string
  country: string
  vat: string
  register: string
  legalRepresentative: string
  internet: string
  phone: string
  partner: string
  apps: string[]
  attributes: string[]

}

const declaration: ModelDeclaration<Company> = {
  name: { type: 'string' },
  id: { type: 'string' },
  company: { type: 'string' },
  description: { type: 'string' },
  streetNumber: { type: 'string' },
  zipcode: { type: 'string' },
  city: { type: 'string' },
  country: { type: 'string' },
  vat: { type: 'string' },
  register: { type: 'string' },
  legalRepresentative: { type: 'string' },
  internet: { type: 'string' },
  phone: { type: 'string' },
  partner: { type: 'string' },
  apps: { type: 'array', contains: { type: 'string' } },
  attributes: { type: 'array', contains: { type: 'string' } },
};


Models.register('company', declaration);