<template>
  <section class="layout-placeholder">
    <div>
      {{$translate(content.type)}} <template v-if="content.type === 'partial-module'">( {{content.id}} )</template> {{ $translate("INHERITED_FROM_LAYOUT")  }}
    </div>
  </section>
</template>
<script lang="ts">
import ModuleGenericExtended from '@/apps/editor/interfaces/ModuleGenericExtended.interface';
import { ExtPartial } from '@/libs/lila-partial';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class LayoutPlaceholderPartial extends ExtPartial {

  @Prop(Object) content: ModuleGenericExtended;

}
</script>
<style lang="less">

.layout-placeholder {

  display: grid;
  .multi(padding, 2, 4);
  color: @textColor;

  h2 {
    font-size: @fontTextSmaller;
    text-transform: uppercase;
  }

}
</style>
