// import modules from '@/apps/editor/editor-modules';
// import LinkPartialModel from '@/models/Link.model';
// import ModuleModel from '@/models/Module.model';
// import PicturePartialModel from '@/models/PicturePartialModel';
// import TextblockModel from '@/models/Textblock.model';
// import VideoPartialModel from '@/models/VideoPartial.model';

// export default class EmotionModuleModel extends ModuleModel {

//   uuid = true;

//   fontVariant = [];

//   type: keyof typeof modules = 'emotion-module';

//   background = PicturePartialModel;

//   video = VideoPartialModel;

//   textblock = TextblockModel;

//   picture = PicturePartialModel;

//   links = [LinkPartialModel];

//   legacy = (data: any) => {

//     if (data.textblock?.links) {

//       data.links = data.textblock.links;
//       console.log(data.textblock.links);

//     }

//     return data;

//   };

// }


import Models, { ModelDeclarationExtended, ModelFunctions, ModelOptions } from '@/libs/Models.class';
import Link from '@/models/Link.model';
import Module from '@/models/Module.model';
import PicturePartial from '@/models/PicturePartial.model';
import Textblock from '@/models/Textblock.model';
import VideoPartial from '@/models/VideoPartial.model';

export default interface EmotionModule extends Module {
  fontVariant: string[]
  background: PicturePartial
  video: VideoPartial
  textblock: Textblock
  picture: PicturePartial
  links: Link[]
}

const declaration: ModelDeclarationExtended<EmotionModule, Module> = {
  fontVariant: { type: 'array', contains: { type: 'string' } },
  background: { model: 'picture-partial' },
  video: { model: 'video-partial' },
  textblock: { model: 'textblock' },
  picture: { model: 'picture-partial' },
  links: { type: 'array', contains: { model: 'link' } },
};
const functions: ModelFunctions = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('emotion-module', declaration, functions, options);