<template>
  <article :class="navigationAnimation">

    <side-screen-header-partial>{{$translate('me-settings')}}</side-screen-header-partial>
    <router-view class="content-container" :key="`me-settings-${$route.params.link}`" />

  </article>
</template>

<script lang="ts">
import {
  ExtComponent, Component,
} from '@/libs/lila-component';

@Component
export default class MeSettingsScreen extends ExtComponent {

  state: string = '';

  sidescreen = true;

  componentName = 'me-settings-home-sidescreen';

}

</script>
