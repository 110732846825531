<template>
  <button
    :type="type"
    class="button base"
    :disabled="disabled"
    :class="[
      {
        doublecheck,
        showCheck,
        confirmed,
        save,
        buttonIcon: realButtonContentType === 'icon' && !confirmed && !showCheck && !state,
        iconText: realButtonContentType === 'iconText' && !confirmed && !showCheck && !state,
        textIcon: realButtonContentType === 'textIcon' && !confirmed && !showCheck && !state,
      },
      state,
      realColorScheme,
      realButtonType,
    ]"
    @click="confirm"
  >
    <icons-partial v-if="(realButtonContentType === 'icon' || realButtonContentType === 'iconText') && !confirmed && !showCheck && !state" :type="realIcon" :color-scheme="iconColorScheme" :size="realSize" :rotate="rotate" />
    <span v-if="realButtonContentType !== 'icon' && !showCheck && !confirmed && !state" class="text" :class="[{ indicator: variant == 'settingsOpen' }]">
      <slot />
    </span>
    <span v-if="showCheck">{{$translate('confirm')}}</span>
    <span v-if="confirmed && !showCheck && !save">{{$translate('confirmed')}}</span>

    <span v-if="state === 'init'">{{$translate(SAVING)}}</span>
    <span v-if="state === 'success'">{{$translate(SAVED)}}</span>
    <span v-if="state === 'error'">{{$translate(ERROR)}}</span>
    <icons-partial v-if="realButtonContentType === 'textIcon' && !confirmed && !showCheck && !state" :type="realIcon" :color-scheme="iconColorScheme" :rotate="rotate" :size="realSize" />
  </button>
</template>
<script lang="ts">
import {
  Component, Prop, Watch,
} from 'vue-property-decorator';
import { ExtPartial } from '@/libs/lila-partial';
import { availableIcons } from './icons.partial.vue';

@Component
export default class buttonPartial extends ExtPartial {

  @Prop(String) value: string;

  @Prop(Boolean) doublecheck: boolean;

  @Prop(Boolean) save: boolean;

  @Prop(Boolean) disabled: boolean;

  @Prop(Object) translations?: {
    saving?: string;
    saved?: string;
    error?: string;
  };

  @Prop({ type: String, default: 'colorScheme1' }) colorScheme: string;

  @Prop({ type: String, default: 'button' }) type: 'button' | 'submit';

  @Prop(String) buttonContentType: string;

  @Prop() icon: availableIcons;

  @Prop(String) buttonType: string;

  @Prop(String) size: string;

  @Prop(Number) rotate: number;

  showCheck: boolean = false;

  timeout: any = false;

  confirmed: boolean = false;

  state: string = '';

  finished: boolean = false;

  buttonHtmlType = 'button';

  variants = {
    moduleNavigation: {
      buttonContentType: 'textIcon',
      icon: 'arrow-right',
      colorScheme: 'colorScheme4',
      buttonType: 'moduleNavigationButton',
      size: 'small',
    },
    moduleNavigationVariant: {
      buttonContentType: 'textIcon',
      icon: 'arrow-right',
      colorScheme: 'colorScheme4',
      buttonType: 'moduleNavigationButtonVariant',
      size: 'small',
    },
    moduleNavigationContentGroup: {
      buttonContentType: 'textIcon',
      colorScheme: 'colorScheme4',
      buttonType: 'moduleNavigationButton',
    },
    notification: {
      buttonContentType: 'icon',
      icon: 'notifications',
      colorScheme: 'colorScheme4',
      size: 'large',
    },
    error: {
      buttonContentType: 'icon',
      icon: 'warning',
      colorScheme: 'error',
      size: 'medium',
    },
    settings: {
      buttonContentType: 'icon',
      colorScheme: 'colorScheme4',
      size: 'large',
    },
    settingsOpen: {
      buttonType: 'settingsOpen',
      buttonContentType: 'iconText',
      colorScheme: 'colorScheme4',
      size: 'large',
    },
    settingsBack: {
      buttonType: 'settingsBack',
      colorScheme: 'transparent',
    },
    filter: {
      buttonType: 'filter',
      buttonContentType: 'textIcon',
      icon: 'close',
      colorScheme: 'colorScheme6',
      size: 'medium',
    },
    submenu: {
      buttonType: 'submenu',
    },
    crumbs: {
      buttonType: 'crumbs',
      buttonContentType: 'textIcon',
      icon: 'arrow-right',
      colorScheme: 'transparent',
      size: 'smaller',
    },
    iconColor: {
      buttonType: 'iconColor',
      buttonContentType: 'icon',
    },
    clear: {
      buttonType: 'clear',
      icon: 'close',
      buttonContentType: 'icon',
      colorScheme: 'transparent',
    },
    listElement: {
      buttonType: 'listElement',
      colorScheme: 'transparent'
    }
  };

  @Watch('value')
  valueChange() {

    if (this.value === 'success' || this.value === 'error') {

      this.finished = true;

    }

    if (this.value === '' && this.finished) {

      this.state = '';

    }

  }

  confirm() {

    if (!this.doublecheck && !this.save) {

      this.$emit('click');
      return this.$emit('confirmed');

    }

    if (this.doublecheck) {

      this.check();
      return false;

    }

    if (this.save) {

      this.triggerSave();

    }

    return true;

  }

  triggerSave() {

    this.state = 'init';
    this.finished = false;

    this.$emit('input', 'init');
    this.$emit('save', true);

    this.start();

  }

  start() {

    setTimeout(() => {

      if (!this.finished) {

        this.start();

      } else {

        this.state = this.value;
        this.$emit('finished');

      }

    }, 2000);

  }

  check() {

    if (!this.showCheck) {

      this.showCheck = true;
      this.timeout = setTimeout(() => {

        this.showCheck = false;

      }, 5000);

    } else {

      this.showCheck = false;
      this.confirmed = true;
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {

        this.confirmed = false;

      }, 15000);

      if (this.save) {

        this.triggerSave();

      } else {

        this.$emit('confirmed');

      }

    }

  }

  get SAVING(): string {

    return this.translations?.saving ?? 'saving';

  }

  get ERROR(): string {

    return this.translations?.error ?? 'button-action-error';

  }

  get SAVED(): string {

    return this.translations?.saved ?? 'saved';

  }

  get realButtonContentType() {

    return this.variants[this.variant]?.buttonContentType ? this.variants[this.variant].buttonContentType : this.buttonContentType;

  }

  get realIcon() {

    return this.variants[this.variant]?.icon ? this.variants[this.variant].icon : this.icon;

  }

  get realColorScheme() {

    return this.variants[this.variant]?.colorScheme ? this.variants[this.variant].colorScheme : this.colorScheme;

  }

  get realButtonType() {

    return this.variants[this.variant]?.buttonType ? this.variants[this.variant].buttonType : this.buttonType;

  }

  get realSize() {

    return this.variants[this.variant]?.size ? this.variants[this.variant].size : this.size;

  }

  get iconColorScheme() {

    if (this.realColorScheme === 'colorScheme1') return 'colorScheme4';
    if (this.realColorScheme === 'colorScheme2') return 'colorScheme1';
    if (this.realColorScheme === 'colorScheme3') return 'colorScheme2';
    if (this.realColorScheme === 'colorScheme6' || this.realColorScheme === 'error') return 'white';
    if (this.realColorScheme === 'icon-red') return 'red';
    if (this.realColorScheme === 'icon-green') return 'green';
    if (this.realColorScheme === 'icon-color1') return 'colorScheme1';
    if (this.realColorScheme === 'icon-grey') return 'grey';

    return 'colorScheme1';

  }

}
</script>

<style lang="less">

button,
.button {

  &.base {
    .font-head;
    .multi(padding, 0, 3);

    display: grid;

    justify-items: center;

    overflow: hidden;

    height: @buttonHeight;

    border: none;
    line-height: @buttonLineHeight;

    text-transform: uppercase;

    white-space: nowrap;
    cursor: pointer;

    -webkit-tap-highlight-color: transparent;

    &.buttonIcon {
      grid-template-columns: 35px;
      padding: 0px;
    }

    &.inline {

      .font-normal;
      display: inline;
      padding: 0;
      color: @textColor;
      text-transform: initial;

      &:hover {
        color: @color1;
      }

    }

    &.inherit {

      display: inline;
      padding: inherit;
      color: inherit;
      font-size: inherit;
      font-family: inherit;
      text-transform: inherit;

      &:hover {
        color: inherit;
      }

    }

    &.filter {

      .font-normal;
      display: inline;
      overflow: hidden;
      max-width: 150px;
      color: @textColor;
      text-align: left;
      text-overflow: ellipsis;
      .multi(padding, 0);

      &:hover {
        color: @color1;
      }

    }

    &.inlineBold {

      .font-normal;
      display: inline;
      height: 14px;
      padding: 0;
      color: @textColor;
      line-height: 14px;
      text-transform: initial;

      &:hover {
        color: @color1;
      }

    }

  }

  &.scopeMissing {
    color: @textColor;
    opacity: .8;
    pointer-events: none;
  }

  &.clear {

    .icon-partial {
      justify-self: end;
    }
  }

  &:active {
    border: none;
    background: #fff;
  }

  &:focus {
    background: transparent;
    outline: 0;
  }

  &.doublecheck {
    color: @error;

    &::after {
      background-color: @error;
    }

    &.showCheck {
      background-color: @error;
      color: @white;

      &:hover {
        background-color: @error;
        color: @white;
      }
    }
  }

  &.save {

    &.init {

      span {

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;

          width: 20px;
          height: 20px;

          background-color: @white;

          animation-name: border;
          animation-duration: 2s;
          animation-timing-function: linear;
          animation-iteration-count: infinite;
        }
      }
    }

    &.success {
      background-color: @success;
      color: @white;
    }
  }

  .trans(background);

  &.colorScheme1 {
    background-color: @color3;
    color: @white;

    &:hover {
      background-color: @color1;
    }
  }

  &.colorScheme2 {
    background-color: @grey1;
    color: @textColor;

    &:hover {
      background-color: @color2;
    }

    &.router-link-active {
      background-color: @grey;

      &:hover {
        background-color: @grey1;
        color: @textColor;
      }
    }
  }

  &.colorScheme3 {
    background: transparent;
    color: #3f2d56;

    &.active {
      background-color: @color1;
      color: @white;
    }
  }

  &.colorScheme4 {
    color: @textColor;
  }

  &.colorScheme5 {
    background-color: @color1;
    color: @white;
  }

  &.colorScheme6 {
    background-color: @color1;
    color: @white;

    &:hover {
      background-color: @grey;
    }
  }

  &.error {
    background-color: @error;
    color: @white;

    &:hover {
      background-color: @grey;
    }
  }

  &.transparent {
    padding: 0;
    background-color: transparent;
    color: @textColor;
    .trans(color);

    &:hover {
      color: @color1;
    }

    &.buttonIcon {

      .icon-partial {

        svg {
          .trans(stroke);
        }
      }

      &:hover {

        .icon-partial {

          svg {
            stroke: @grey;
          }
        }
      }
    }

    &.fontNormal {
      .font-normal;
    }

    &.success {
      color: @success;
      .font-head;
    }

  }

  &.hoverSchema1 {

    &:hover {
      background-color: @grey1;
      color: @textColor;
    }
  }

  &.colorScheme1,
  &.colorScheme2 {

    &.save {

      &.init {
        background-color: transparent;
        color: @color1;

        span {

          &:after {
            background-color: @color1;
          }
        }
      }
    }

    &.success {
      background-color: @success;
      color: @white;
    }

    &.error {
      background-color: @error;
      color: @white;
    }
  }

  &.full {
    width: 100%;
    text-align: center;
  }

  &:disabled {
    background-color: @grey;
    pointer-events: none;
    user-select: none;
  }

  &.callToAction {
    .base;
    .colorScheme1;
    .trans(background);
  }

  &.scrollButton {
    text-align: center;
    .font-head;

    .icon-partial {
      justify-self: center;
      margin-top: 5px;
    }
  }

  &.iconText {
    grid-template-columns: 35px auto;
    padding-left: 0px;

    .span {
      line-height: 35px;
    }
  }

  &.textIcon {
    grid-template-columns: auto 35px;
    padding-right: 0px;
  }

  .buttonTextlineHeight {
    line-height: 35px;
  }

  .indicator {
    justify-self: start;
  }

  &.moduleAddList {
    display: block;
    width: 100%;
    padding: 10px 20px;
    font-weight: 200;
    font-size: 15px;
    font-family: 'Nexa';
    line-height: 19.5px;
    letter-spacing: .2px;
    text-align: left;
    text-transform: uppercase;
    transition: background .3s ease-out;
  }

  &.moduleNavigationButton {

    &.open {

      .icon-partial {
        transform: rotate(90deg);
      }
    }

    &.textIcon {
      .multi(padding, 0, 4);
      .font-bold;
      justify-items: start;
      font-size: @fontText;

      .icon-partial {
        justify-self: end;
      }
    }

    .trans(color);

    .icon-partial {

      svg {
        .trans(stroke);
      }
    }

    &:hover {
      color: @grey;

      .icon-partial {

        svg {
          stroke: @grey;
        }
      }
    }
  }

  &.moduleNavigationButtonVariant {

    &.open {

      .icon-partial {
        transform: rotate(90deg);
      }
    }

    &:disabled {
      background-color: transparent;

      .icon-partial {

        svg {
          stroke: @grey;
        }
      }
    }

    &.hasPreview {

      height: auto;

      .text {
        display: grid;
        grid-template-rows: 20px 1fr 5px;
        width: 100%;
        line-height: 20px;
        text-align: left;

        .preview {
          text-transform: none;
          white-space: pre-wrap;
          .font-normal;
        }
      }

      .icon-partial {
        grid-template-rows: 20px 1fr;
        align-self: start;
      }

    }

    &.textIcon {
      .multi(padding, 0, 4);
      .font-bold;
      justify-items: start;
      font-size: @fontTextSmaller;

      .icon-partial {
        justify-self: end;
      }
    }

    .trans(color);

    .icon-partial {

      svg {
        .trans(stroke);
      }
    }

    &:hover {
      color: @grey;

      .icon-partial {

        svg {
          stroke: @grey;
        }
      }
    }
  }

  &.settingsOpen {
    grid-template-columns: 30px auto;
    align-items: center;
    justify-items: start;
    height: 25px;
  }

  &.crumbs {
    display: inline-grid;
    grid-template-columns: auto 15px;
    height: 20px;
    margin: 0;
    padding: 0;
    line-height: 20px;

    span {
      margin-right: 0px;
    }
  }

  &.filter {
    justify-items: start;

    .icon-partial {
      justify-self: end;
    }
  }

  &.submenu {

    span {
      .font-normal;
    }
  }

  &.icon {

    padding: 0;

    svg {
      .trans(stroke);
    }

    &:hover {

      svg {
        stroke: @grey;
      }
    }

  }

  &.icon-grey {

    svg {
      .trans(stroke);
    }

    &:hover {

      svg {
        stroke: @color1;
      }
    }
  }

  &.iconColor {

    justify-content: center;
    background-color: transparent;
    .trans(opacity);

    &.colorScheme2 {
      background-color: transparent;
    }

    &:hover {
      background-color: transparent;
      opacity: .5;

      &.colorScheme2 {
        background-color: transparent;
      }
    }

  }

  &.listElement {
    display: grid;
    height: 35px;
    font-size: @fontText;
    line-height: 35px;

    text-align: left;
    text-transform: uppercase;
    .font-normal;

    &:hover {
      color: @color1;
    }
  }
}

@keyframes border {

  0% {
    bottom: calc(100% - 3px);
    left: -17px;
  }

  25% {
    bottom: calc(100% - 3px);
    left: calc(100% - 3px);
  }

  50% {
    bottom: -17px;
    left: calc(100% - 3px);
  }

  75% {
    bottom: -17px;
    left: -17px;
  }

  100% {
    bottom: calc(100% - 3px);
    left: -17px;
  }
}
</style>
