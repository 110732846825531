import { BasicData, Content, Customers, GenericDataWithContent, List, Media, Structure } from '@lilaquadrat/interfaces';
import findObjectsWithKeys from './findObjetcsWithKeys';

export default function distributeGenericData(modules: Content['modules'], genericData: GenericDataWithContent) {

    let flattenedGenericData: Record<string, BasicData<Content | List | Customers | Media | Structure>> = {};

    if (!genericData) return;

    Object.values(genericData).forEach((single) => {

        flattenedGenericData = { ...flattenedGenericData, ...single };

    });

    modules.forEach((singleModule) => {

        findObjectsWithKeys(singleModule, ['genericData']).forEach((ObjectWithGenericData) => {

            Object.values(ObjectWithGenericData.genericData)
                .filter((single) => Array.isArray(single))
                .forEach((idArray: string[]) => {

                    idArray.forEach((single) => {

                        if (!singleModule.genericData.data) singleModule.genericData.data = {};
                        singleModule.genericData.data[single] = flattenedGenericData[single];

                    });

                });

        });


    });

}

;