<template>
  <section :class="{ open: type !== 'overview' }" class="editor-tools-editor editor">
    <editor-pre-header-partial>{{$translate('tools')}}</editor-pre-header-partial>

    <section v-if="type !== 'overview'" class="editor-module-container">
      <editor-content-group-partial v-if="internalId">
        <button-group-partial left>
          <button-partial v-if="scope(['editor:remove'])" color-scheme="colorScheme2" doublecheck @confirmed="deleteSite">{{$translate('DELETE_THIS_DATA')}}</button-partial>
          <router-link v-if="scope(['editor:create'])" class="button colorScheme2 base" :to="{ name: 'app-editor-project-new', params: { base: internalId } }">{{$translate('DUPLICATE_THIS_DATA')}}</router-link>
          <router-link class="button colorScheme2 base" :to="{ name: 'app-editor-project-history', params: { id } }">{{ 'app-editor-project-history' | translate }}</router-link>
        </button-group-partial>
      </editor-content-group-partial>

      <editor-content-group-partial v-if="scope(['editor:edit'])" title="export" closed>
        <textarea-partial v-model="modules" readonly description="copy the content and import it in your target data" />
      </editor-content-group-partial>

      <editor-content-group-partial v-if="scope(['editor:edit'])" title="import" closed>
        <textarea-partial :debounce="1000" @input="checkImport($event)" />

        <label>
          <template v-if="importState.state === 'error'">
            <p class="input-description">
              {{$translate('input data has error')}}
            </p>
            {{ importState.error }}
          </template>

          <template v-if="importState.state === 'empty'">
            <p class="input-description">
              {{$translate('input data is empty')}}
            </p>
            {{ importState.error }}
          </template>
        </label>

        <button-partial v-if="importState.state === 'ready'" class="colorScheme2 base" doublecheck @confirmed="importExec">{{$translate('import')}}</button-partial>
      </editor-content-group-partial>
    </section>
  </section>
</template>

<script lang="ts">
import { Component, ModuleEditorComponent, Prop } from '@/libs/ModuleEditorComponent';
import { ModuleGeneric } from '@lilaquadrat/studio/lib/interfaces';

@Component
export default class EditorToolsEditor extends ModuleEditorComponent<any> {

  @Prop(Array) value: ModuleGeneric[];

  importData: ModuleGeneric[] = [];

  importState: { state: string; error?: string; count?: number } = { state: '' };

  get internalId() {

    // eslint-disable-next-line no-underscore-dangle
    return this.$store.state.AppEditorData.content._id;

  }

  get id() {

    return this.$store.state.AppEditorData.content.id;

  }

  importExec() {

    this.$emit('import', this.importData);

  }

  checkImport(input: string) {

    let data: ModuleGeneric[] = [];
    let state: string = 'waiting';
    let error: string;

    if (input.length) {

      try {

        data = JSON.parse(input);

      } catch (parseError) {

        state = 'error';
        error = parseError.message;
        data = [];

      }

    } else {

      state = 'empty';

    }

    if (data.length) {

      data = data.filter((single) => single.type);

    }

    if (state !== 'error') state = data.length ? 'ready' : 'empty';
    if (state === 'ready') this.importData = data;

    this.importState = {
      state,
      error,
      count: data.length,
    };

  }

  deleteSite() {

    this.$store.dispatch(
      'AppEditorData/delete',
      {
        company: this.$store.state.Company.company,
        project: this.$store.state.Project.project,
        id: this.$store.state.AppEditorData.content._id,

      },
    )
      .then(() => this.$router.push({ name: 'app-editor-project-home' }));

  }

  get modules() {

    const modules = JSON.parse(JSON.stringify(this.value));

    modules.map((single) => {

      if (single.type === 'partial-module') single.modules = [];
      return single;

    });

    return JSON.stringify(modules);

  }

  set modules(value: string) {}

}
</script>
