<template>
  <article class="linklistElement-editor">
    <moduleList-partial v-if="!child" class="module-editor-navigation">
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('title')">{{$translate('title')}}</button-partial>
      </li>
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('links')">{{$translate('links')}}</button-partial>
      </li>
    </moduleList-partial>

    <input-partial v-if="child === 'title'" v-model="value.title" class="input-padding" placeholder="title">{{$translate('title')}}</input-partial>
    <list-elements-partial-editor v-if="child === 'links'" v-model="value.elements" type="link-partial" :model="linkModel" />
  </article>
</template>
<script lang="ts">
import Component from 'vue-class-component';
import { PartialEditorComponent, Prop } from '@/libs/PartialEditorComponent';

@Component
export default class LinkListElementEditor extends PartialEditorComponent<any> {

  linkModel = 'link';

}
</script>
