import { UnifiedRouteGuard } from '@/libs/lila-auth';
import HomeScreen from './screens/home.screen.vue';
import SingleScreen from './screens/single.screen.vue';
import ProvidersOverviewScreen from './screens/providers-overview.screen.vue';
import ProvidersSingleScreen from './screens/providers-single.screen.vue';
import ProvidersHomeScreen from './screens/providers-home.screen.vue';

export default [
  {
    path: '/:company/:project/a/payment/:site?',
    name: 'app-payment-project-home',
    beforeEnter: UnifiedRouteGuard,
    component: HomeScreen,
    meta: {
      animationGroup: 'payment',
      app: 'payment',
      parent: true,
      rememberSettings: true,
    },
    children: [
      {
        path: 'single/:id',
        component: SingleScreen,
        name: 'app-payment-project-single',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-payment-project-single',
          app: 'payment',
        },
      },
      {
        path: 'providers',
        component: ProvidersOverviewScreen,
        meta: {
          sidescreen: true,
          defaultRoute: 'app-payment-providers-home',
          app: 'publish',
        },
        children: [
          {
            path: 'add',
            component: ProvidersSingleScreen,
            name: 'app-payment-providers-add',
            meta: {
              sidescreen: true,
              defaultRoute: 'app-payment-providers-home',
              app: 'publish',
            },
          },
          {
            path: ':id',
            component: ProvidersSingleScreen,
            name: 'app-payment-providers-edit',
            meta: {
              sidescreen: true,
              defaultRoute: 'app-payment-providers-home',
              app: 'publish',
            },
          },
          {
            path: ':site?',
            component: ProvidersHomeScreen,
            name: 'app-payment-providers-home',
            meta: {
              sidescreen: true,
              defaultRoute: 'app-payment-providers-home',
              app: 'publish',
            },
          },
        ],
      },
      {
        path: 'single/:id',
        component: SingleScreen,
        name: 'app-payment-project-single',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-payment-project-single',
          app: 'publish',
        },
      },
    
    ],
  },
  {
    path: '/:company/:project/a/payment/tests/:site?',
    name: 'app-payment-project-tests-home',
    beforeEnter: UnifiedRouteGuard,
    component: HomeScreen,
    meta: {
      animationGroup: 'payment',
      app: 'payment',
      parent: true,
      rememberSettings: true,
      settingsKey: 'tests',
    },
    children: [
      {
        path: 'single/:id',
        component: SingleScreen,
        name: 'app-payment-project-tests-single',
        meta: {
          sidescreen: true,
          defaultRoute: 'app-payment-project-tests-single',
          app: 'payment',
        },
      },
    ],
  },
];
