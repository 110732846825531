<script setup lang="ts">
import { ref, computed, onBeforeMount } from 'vue';
import type { SDKResponse } from '@/libs/StudioSDK';
import type { ExportData } from '@lilaquadrat/interfaces';
import type { Dictionary } from 'vue-router/types/router';
import { useStore } from '@/store/main.store';
import { useRoute } from 'vue-router/composables';
// // Optionally set the component name (if your build setup supports defineOptions)
// defineOptions({
//   name: 'app-importadmin-project-single'
// });

// Access the Vuex store
const store = useStore();
const route = useRoute();
// Local reactive state
const project = ref<string>('');
const cdn = ref<string>('');
const services = ref<string[]>([]);
// Placeholder for navigationAnimation (e.g. provided by a mixin in ExtComponent)
const navigationAnimation = ref('');
// Computed property for the content (from the Vuex store)
const content = computed(() => store.state.AppImportadmin.single);
// The createImport function dispatches the import action
const createImport = () => store.dispatch('AppImportadmin/createImport', {
    projectId: project.value,
    path: content.value.path,
    services: services.value,
    cdn: cdn.value,
  });

// Preload functions (exposed so that external logic can call them)
function preloadDataPre(
  params: Dictionary<string>,
) {

  return store.dispatch('AppImportadmin/single', {
    timestamp: params.timestamp,
    exportProject: params.exportProject,
    exportCompany: params.exportCompany,
  });

}

const preloadDataPost = (
  preloadedData: SDKResponse<ExportData>,
) => {

  store.commit('AppImportadmin/single', preloadedData.data);
  return Promise.resolve();

};

onBeforeMount(async () => {

  // Preload the data
  const preloadData = await preloadDataPre(route.params);

  await preloadDataPost(preloadData);

});


</script>
<template>
  <article class="app-importadmin-single-screen" :class="navigationAnimation">
    <side-screen-header-partial>
      {{ $translate('app-importadmin-single-title') }}
    </side-screen-header-partial>
    <section class="content-container">
      <data-grid-partial class="content" v-if="content">
        <section class="data-grid-container">
          <section class="data-grid-single full">
            <h4>{{ $translate('app-importadmin-created-at') }}</h4>
            <p>{{ $date(content.metadata.createdAt) }}</p>
          </section>
          <section class="data-grid-single">
            <h4>{{ $translate('app-importadmin-company') }}</h4>
            <p>{{ content.company }}</p>
          </section>
          <section class="data-grid-single">
            <h4>{{ $translate('app-importadmin-project') }}</h4>
            <p>{{ content.project }}</p>
          </section>
          <section class="data-grid-single">
            <h4>{{ $translate('app-importadmin-filesize') }}</h4>
            <p>{{ $formatBytes(content.filesize) }}</p>
          </section>
          <section class="data-grid-single full">
            <h4>{{ $translate('app-importadmin-selected-services') }}</h4>
            <p>{{ content.metadata.selectedServices.join(', ') }}</p>
          </section>
          <section class="data-grid-single full">
            <h4>{{ $translate('app-importadmin-statistics') }}</h4>
            <ul v-if="content.metadata.statistics">
              <li v-for="(value, key) in content.metadata.statistics" :key="key">
                <p>{{ key }}: {{ value }}</p>
              </li>
            </ul>
          </section>
        </section>

        <section class="data-grid-single full action">
          <h4>{{ $translate('app-importadmin-target-project') }}</h4>
          <select-publishadmin-ext v-model="project" />

          <select-import-services-partial v-model="services" />

          <input-partial v-model="cdn" description="app-importadmin-cdn-description">
            {{ $translate('app-importadmin-cdn') }}
          </input-partial>

          <button-group-partial>
            <button-partial :disabled="!project.length" doublecheck @confirmed="createImport">
              {{ $translate('app-importadmin-import-title') }}
            </button-partial>
          </button-group-partial>
        </section>
      </data-grid-partial>
    </section>
  </article>
</template>
<style lang="less">


.app-importadmin-single-screen {

  .content {
    display: grid;
    gap: 20px;

    .services-selection {
      display: grid;
      gap: 5px;
    }
  }

  .action {
    display: grid;
    gap: 20px;
  }
}
</style>
