<template>
  <article class="app-importadmin-add-screen" :class="navigationAnimation">
    <side-screen-header-partial>{{ $translate('app-importadmin-add-title') }}</side-screen-header-partial>
    <section class="content-container">

      <section class="content">

        <description-partial>{{ $translate('app-importadmin-add-description') }}</description-partial>

        <select-import-services-partial v-model="services" />

        <select-publishadmin-ext v-model="project" />

        <button-group-partial>
          <button-partial :disabled="!services.length" doublecheck @confirmed="createExport">{{ $translate('app-importadmin-add-title') }}</button-partial>
        </button-group-partial>

      </section>

    </section>
  </article>
</template>

<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import { SDKResponse } from '@/libs/StudioSDK';
import { ExportData, Publish } from '@lilaquadrat/interfaces';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppImportadminSettingsScreen extends ExtComponent {

  services: string[] = [];

  project: string = '';

  componentName = 'app-importadmin-project-add';

  get content(): Publish {

    return this.$store.state.AppImportadmin.single;

  }

  createExport() {

    console.log('create export', this.services);
    return this.$store
      .dispatch('AppImportadmin/createExport', {
        services: this.services,
        projectId: this.project
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store
      .dispatch('AppImportadmin/single', {
        _id: params.id,
        settings: {
          project: params.project,
          company: params.company,
        },
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<ExportData>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppImportadmin/single', preloadedData.data);
    return Promise.resolve();

  }


}
</script>
<style lang="less">


.app-importadmin-add-screen {

  .content {
    display: grid;
    gap: 20px;

    .services-selection {
      display: grid;
      gap: 5px;
    }
  }


}
</style>
