<template>
  <article :class="navigationAnimation">
    <side-screen-header-partial>{{$translate('CREATE_COMPANY')}}</side-screen-header-partial>
    <section class="content-container">
      <form class="content" @submit="save">
        <fieldset>
          <legend>
            <span class="text">{{$translate('company-name')}}</span>
          </legend>

          <input-partial v-model="model.id" :error="errorsObject.id"  placeholder="name" :validation="/^[a-z0-9]{1,15}$/i" required>{{$translate('id')}}</input-partial>

          <input-partial v-model="model.name" :error="errorsObject.name"  placeholder="name" :validation="/^[a-z0-9 \.\_\-\&\!\:]{1,50}$/i" required>{{$translate('name')}}</input-partial>

          <textarea-partial v-model="model.description" max-length="200">{{$translate('short description of this company')}}</textarea-partial>
        </fieldset>

        <fieldset>
          <legend>
            <span class="text">{{$translate('company-address')}}</span>
          </legend>

          <input-partial v-model="model.streetNumber"  :error="errorsObject.streetNumber" placeholder="company-streetNumber" required>{{$translate('company-streetNumber')}}</input-partial>

          <input-partial v-model="model.city"  :error="errorsObject.city" placeholder="company-city" required>{{$translate('company-city')}}</input-partial>

          <input-partial v-model="model.zipcode" :error="errorsObject.zipcode" placeholder="company-zipcode" required>{{$translate('company-zipcode')}}</input-partial>

          <select-partial v-model="model.country" :error="errorsObject.country"  :options="availableCountries" placeholder="company-country-select" required :multiple="false">{{$translate('company-country')}}</select-partial>

        </fieldset>

        <fieldset>
          <legend>
            <span class="text">{{$translate('company-legal')}}</span>
          </legend>

          <input-partial v-model="model.legalRepresentative" placeholder="company-representive" required>{{$translate('company-representive')}}</input-partial>

          <input-partial v-model="model.vat" placeholder="company-vat">{{$translate('company-vat')}}</input-partial>

          <input-partial v-model="model.register" placeholder="company-register">{{$translate('company-register')}}</input-partial>

        </fieldset>

        <fieldset>
          <legend>
            <span class="text">{{$translate('company-contact')}}</span>
          </legend>

          <input-partial v-model="model.internet" placeholder="company-internet">{{$translate('company-internet')}}</input-partial>

          <input-partial v-model="model.phone" placeholder="company-phone">{{$translate('company-phone')}}</input-partial>

        </fieldset>

        <fieldset>
          <legend>
            <span class="text">{{$translate('company-attributes')}}</span>
          </legend>

          <select-partial placeholder="company-attributes" :options="attributesOptions" v-model="model.attributes" allow-empty>{{$translate('company-attributes')}}</select-partial>
        </fieldset>

        <action-notice-partial :state="state" :translation-pre="translationPre" :errors="errors" @update="updateErrors">
          <button-partial v-model="state" save type="submit">{{$translate('CREATE_COMPANY')}}</button-partial>
        </action-notice-partial>
      </form>
    </section>
  </article>
</template>

<script lang="ts">
import SelectOption from '@/interfaces/selectOption.interface';
import { ErrorsObject } from '@/libs/ActionNotice';
import {
  ExtComponent, Component,
} from '@/libs/lila-component';
import ModelsClass from '@/libs/Models.class';
import StudioSDK from '@/libs/StudioSDK';
import { Company } from '@lilaquadrat/studio/lib/interfaces';
import { ErrorObject } from 'ajv';

@Component
export default class CompanyCreateScreen extends ExtComponent {

  model: Company = null;

  state: string = '';

  componentName = 'company-create';

  translationPre = 'company';

  errors: {message?: string, errors?: ErrorObject[]} = {};

  errorsObject: ErrorsObject = {};

  availableCountries: SelectOption[] = [
    { value: 'deu', text: 'country-deu' },
    { value: 'aut', text: 'country-aut' },
    { value: 'che', text: 'country-che' },
  ];

  attributesOptions = [
    {
      text: 'app-app-preview',
      value: 'previewPublic',
    },
    {
      text: 'app-app-internal',
      value: 'previewInternal',
    },
    {
      text: 'app-app-admin',
      value: 'admin',
    },
    {
      text: 'partner',
      value: 'partner',
    }
  ];


  updateErrors(errorsObject: ErrorsObject) {

  this.errorsObject = errorsObject;

  }

  beforeMount(): void {
    
    this.model = ModelsClass.add<Company>({}, 'company');

  }

  save($event: MouseEvent) {

    $event.preventDefault();

    this.model.partner = this.$store.state.Company.company;

    // const saveData = new CompanyModel().setData(this.model, true, 'save');
    const saveData = ModelsClass.save<Company>(this.model, 'company');

    this.state = '';

    this.$store
      .dispatch('Company/create', saveData)
      .then(() => {

        this.state = 'success';
        StudioSDK.flushCache('me');
        StudioSDK.flushCache('projects');
        this.$store.dispatch('getPermissions');

      })
      .catch((e) => {

        this.errors = e.r;
        this.state = 'error';

      });


  }

}
</script>