import BlogIntroModuleEditor from './modules/blog-intro.module.editor.vue';
import CompareModuleEditor from './modules/compare.module.editor.vue';
import ContactModuleEditor from './modules/contact.module.editor.vue';
import CookiesModuleEditor from './modules/cookies.module.editor.vue';
import EmotionModuleEditor from './modules/emotion.module.editor.vue';
import EventListModuleEditor from './modules/events-list.module.editor.vue';
import FactsModuleEditor from './modules/facts.module.editor.vue';
import FaqModuleEditor from './modules/faq.module.editor.vue';
import FooterModuleEditor from './modules/footer.module.editor.vue';
import GalleryModuleEditor from './modules/gallery.module.editor.vue';
import IndexModuleEditor from './modules/index.module.editor.vue';
import MenuModuleEditor from './modules/menu.module.editor.vue';
import MenuModuleEditorV1 from './modules/menu.module.v1.editor.vue';
import NavigationModuleEditor from './modules/navigation.module.editor.vue';
import PartialModuleEditor from './modules/partial.module.editor.vue';
import PictureModuleEditor from './modules/picture.module.editor.vue';
import PictureAndTextModuleEditor from './modules/pictureandtext.module.editor.vue';
import PictureGroupModuleEditor from './modules/picturegroup.module.editor.vue';
import PlaceholderModuleEditor from './modules/placeholder.module.editor.vue';
import PricesModuleEditor from './modules/prices.module.editor.vue';
import QuellcodeModuleEditor from './modules/quellcode.module.editor.vue';
import QuoteModuleEditor from './modules/quote.module.editor.vue';
import TextModuleEditor from './modules/text.module.editor.vue';
import TrainingModuleEditor from './modules/training.module.editor.vue';
import VideoModuleEditor from './modules/video.module.editor.vue';
import CallToActionModuleEditor from './modules/callToAction.module.editor.vue';
import StoryModuleEditor from './modules/story.module.editor.vue';
import LocationModuleEditor from './modules/location.module.editor.vue';
import ShoppingCartModuleEditor from './modules/shopping-cart.module.editor.vue';

const ModuleMappingV1 = {
  'text-module': TextModuleEditor,
  'picture-module': PictureModuleEditor,
  'emotion-module': EmotionModuleEditor,
  'gallery-module': GalleryModuleEditor,
  'picturegroup-module': PictureGroupModuleEditor,
  'video-module': VideoModuleEditor,
  'pictureandtext-module': PictureAndTextModuleEditor,
  'facts-module': FactsModuleEditor,
  'menu-module': MenuModuleEditorV1,
  'blog-intro-module': BlogIntroModuleEditor,
  'prices-module': PricesModuleEditor,
  'compare-module': CompareModuleEditor,
  'footer-module': FooterModuleEditor,
  'training-module': TrainingModuleEditor,
  'placeholder-module': PlaceholderModuleEditor,
  'faq-module': FaqModuleEditor,
  'index-module': IndexModuleEditor,
  'quote-module': QuoteModuleEditor,
  'quellcode-module': QuellcodeModuleEditor,
  'cookies-module': CookiesModuleEditor,
  'contact-module': ContactModuleEditor,
  'partial-module': PartialModuleEditor,
};
const ModuleMapping = {
  'text-module': TextModuleEditor,
  'picture-module': PictureModuleEditor,
  'emotion-module': EmotionModuleEditor,
  'gallery-module': GalleryModuleEditor,
  'picturegroup-module': PictureGroupModuleEditor,
  'video-module': VideoModuleEditor,
  'pictureandtext-module': PictureAndTextModuleEditor,
  'facts-module': FactsModuleEditor,
  'menu-module': MenuModuleEditor,
  'navigation-module': NavigationModuleEditor,
  'blog-intro-module': BlogIntroModuleEditor,
  'prices-module': PricesModuleEditor,
  'compare-module': CompareModuleEditor,
  'footer-module': FooterModuleEditor,
  'training-module': TrainingModuleEditor,
  'placeholder-module': PlaceholderModuleEditor,
  'faq-module': FaqModuleEditor,
  'index-module': IndexModuleEditor,
  'quote-module': QuoteModuleEditor,
  'quellcode-module': QuellcodeModuleEditor,
  'cookies-module': CookiesModuleEditor,
  'contact-module': ContactModuleEditor,
  'partial-module': PartialModuleEditor,
  'events-list-module': EventListModuleEditor,
  'event-module': EventListModuleEditor,
  'callToAction-module': CallToActionModuleEditor,
  'story-module': StoryModuleEditor,
  'location-module': LocationModuleEditor,
  'shopping-cart-module': ShoppingCartModuleEditor,
};


export {
  ModuleMappingV1,
  ModuleMapping
};