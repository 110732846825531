import vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import inview from '@/libs/lila-inview';
import { Route } from 'vue-router';
import { Dictionary } from 'vue-router/types/router';
import MainStoreState from '@/store/mainStoreState.interface';
import { Store } from 'vuex';
import SettingsClass, { Settings } from '@/libs/lila-settings';
import equal from 'fast-deep-equal';
import cleanObject from '@/mixins/cleanObject';
import hardCopy from '@/mixins/hardCopy';
import { ErrorObject } from 'ajv';
import { SDKResponse } from './StudioSDK';
import { ErrorsObject } from './ActionNotice';
import ModelsClass from './Models.class';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave',
  'beforeUnmount',
  'unmounted',
  'startLoad',
]);

/**
 *  base for edit single elements like lists or publishMethods
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@Component({ inheritAttrs: false })
abstract class EditComponent<T> extends vue {

  /**
    is used to store the state of a action
    e.g. save action is succesful this state should reflect it
    ```TS
    this.state = 'success'
    ```

   */
  public state: string = '';

  public model: T = null;

  public useModel: string;

  public errors: { message?: string, errors?: ErrorObject[] } = {};

  public errorsObject: ErrorsObject = {};

  public fullscreen: boolean = false;

  public child: string = '';

  public componentName: string | string[] = '';

  public animation: string = '';

  /**
   * disables the animation for the next routing
  */
  public animationDisabled: boolean = false;

  public sidescreen: boolean = false;

  @Prop({ type: Array, default: () => [] }) variant: string[];

  @Prop(String) id?: string;

  // eslint-disable-next-line no-unused-vars
  leaveGuard: (to: Route, from: Route, next: () => void, component: this) => void;

  updateErrors(errorsObject: ErrorsObject) {

    this.errorsObject = errorsObject;

  }

  handlePaste(data: T) {

    this.model = ModelsClass.add<T>(data, this.useModel);

  }

  // eslint-disable-next-line class-methods-use-this
  beforeRouteUpdate(to: Route, from: Route, next: () => void) {

    if (to.meta.rememberSettings && (!equal(to.query, {}) || to.params.site)) {

      const cleanedQuery = hardCopy(to.query);

      cleanObject(cleanedQuery);

      if (!equal(cleanedQuery, {}) || to.params.site) {

        SettingsClass.update(Settings.getKeyAppRoute(to), { query: cleanedQuery, site: to.params.site });

      } else {

        SettingsClass.remove(Settings.getKeyAppRoute(to));

      }

    }

    next();

  }

  /**
   * sets the animation to fadeOut
   */
  beforeRouteLeave(to: Route, from: Route, next: (stop) => void): void {

    if (typeof this.leaveGuard === 'function') {

      this.leaveGuard(to, from, (navigate: boolean = true) => {

        if (navigate) {

          this.routeLeave(next);

        } else {

          next(false);

        }

      }, this);

    } else {

      this.routeLeave(next);

    }

  }

  routeLeave(next: (stop?) => void): void {

    if (this.animationDisabled) {

      next();
      return;

    }

    this.animation = 'out';
    next();

  }

  /**
   * checks if the targetComponentname is euqal this componentname or the targetComponentname is in this componentname array
   * sidescreen is always fade in
   * if so, set animation to fade in
   */
  beforeMount() {

    if (this.animationDisabled) {

      this.animationDisabled = false;
      return;

    }

    if (Array.isArray(this.componentName)) {

      if (this.componentName.includes(this.$store.state.Navigation.targetComponent) || this.sidescreen) this.fadeIn();

    } else if ((this.componentName && this.$store.state.Navigation.targetComponent === this.componentName) || this.sidescreen) {

      this.fadeIn();

    }

  }

  @Watch('$route')
  routeChange() {

    this.animationDisabled = false;
    this.animation = '';

  }

  get animationTarget() {

    return this.$store.state.Navigation.targetComponent;

  }

  get loadingState() {

    return this.$store.state.Navigation.status;

  }

  /**
   * if animation is 'out' - we are leaving this component - set the class for fadeout animation
   * if animation is 'in' - we are entering this component - and loadingState is resolving, set class for fadein
   */
  get navigationAnimation() {

    if (this.$store.state.Navigation.contentUpdate) return {};

    return {
      started: this.animation === 'out',
      resolving: this.animation === 'in' && ['resolving', 'loading'].includes(this.loadingState),
    };

  }

  get contentAnimation() {

    if (!this.$store.state.Navigation.contentUpdate) return { contentUpdate: true };

    return {
      contentUpdate: true,
      started: this.loadingState !== 'done',
      resolving: this.loadingState === 'resolving',
    };

  }

  fadeOut() {

    this.animation = 'out';

  }

  fadeIn() {

    this.animation = 'in';

  }

  abstract save($event: MouseEvent): void;

  abstract preloadDataPre?(
    params: Dictionary<string>,
    query: Dictionary<string | string[]>,
    store: Store<MainStoreState>,
    to: Route
  ): Promise<unknown>;

  abstract preloadDataPost?(
    preloadedData: SDKResponse<T>,
    params: Dictionary<string>,
    query: Dictionary<string | string[]>,
    store: Store<MainStoreState>,
    to: Route
  ): Promise<unknown>;


}

export {
  Component,
  EditComponent,
  Prop,
  Watch,
  inview,
  vue,
};
