
import StructureSelect from '@/interfaces/StructureSelect.interface';
import Models, { ModelDeclaration, ModelHooks } from '@/libs/Models.class';

const declaration: ModelDeclaration<StructureSelect> = {
  options: { type: 'array', contains: { model: 'structure-options' } },
  multiple: { type: 'boolean' },
};
const hooks: ModelHooks<StructureSelect> = {
  preAdd(data) {

      console.log('preAdd', data);
  
},
};

Models.register('structure-select', declaration, hooks);
