<template>
  <article :class="navigationAnimation">
    <h1>ERROR</h1>
  </article>
</template>

<script lang="ts">
import {
  ExtComponent, Component, Watch, Prop,
} from '@/libs/lila-component';
import auth from '@/libs/lila-auth';

@Component
export default class ErrorScreen extends ExtComponent {}
</script>
