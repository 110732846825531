<template>
  <article class="app-lists-participant-screen" :class="navigationAnimation">
    <side-screen-header-partial>{{content.user?.name}}</side-screen-header-partial>
    <section class="content-container">

      <data-grid-partial class="content">

        <section class="data-grid-container">
          <section class="data-grid-single">
            <h4>{{$translate('app-lists-participant-customer')}}</h4>
            <p v-if="content.user?._id">{{content.user.name}} <router-link :to="{ name: 'app-customers-company-single', params: { id: content.user?._id.toString() } }">{{$translate('app-lists-participant-link')}}</router-link></p>
            <p v-if="!content.user?._id">{{ $translate('app-lists-participant-missing') }}</p>
          </section>
          <section class="data-grid-single">
            <h4>{{$translate('app-lists-participant-state')}}</h4>
            <p><app-lists-participant-state-partial :list="$route.params.id" :participant="$route.params.participantId" :list-state="content.state" @update="refresh" /></p>
          </section>
          <section class="data-grid-single">
            <h4>{{$translate('app-lists-participant-created')}}</h4>
            <p>{{$date(content.history.created)}}</p>
          </section>
          <section class="data-grid-single">
            <h4>{{$translate('app-lists-participant-category')}}</h4>
            <p>{{content.category}}</p>
          </section>
          <section class="data-grid-single full">
            <h4>{{$translate('app-lists-participant-message')}}</h4>
            <p>{{content.message}}</p>
          </section>
          <section v-if="content.payment" class="data-grid-single full">
            <h4>{{$translate('app-lists-participant-paid')}}</h4>
            <p>{{ $currency(content.payment.amount) }}</p>
          </section>
          <section class="data-grid-single full">
            <h4>{{$translate('app-lists-participant-items')}}</h4>
            <app-lists-participant-items-partial :items="content.payment.items" />
          </section>
          <section class="data-grid-single full">
            <h4>{{$translate('app-lists-participant-accepted-agreements')}}</h4>
            <app-lists-agreement-details-partial v-for="(single, index) in content.agreements" :key="`single-agreeement-${index}`" v-bind="single" />
          </section>
          <section class="data-grid-single full">
            <h4>{{$translate('app-lists-participant-note')}}</h4>
            <p><app-lists-participant-note-partial :list="$route.params.id" :participant="$route.params.participantId" :note="content.note" @update="refresh" /></p>
          </section>
          <section class="data-grid-single full">
            <h4>{{$translate('app-lists-participant-source')}}</h4>
            <p>{{ content.history.source }}</p>
          </section>
        </section>

        <section class="data-grid-container">
          <section class="data-grid-single" v-for="(structure, index) in structures" :key="`structure-${index}`">
            <h4>{{$translate(structure.question)}}</h4>
            <p>{{ structure.outputValue }}</p>
          </section>
        </section>

      </data-grid-partial>

      <button-group-partial class="content">
        <button-partial v-model="state" save @save="remove">Aus Liste entfernen</button-partial>
      </button-group-partial>

    </section>
  </article>
</template>

<script lang="ts">
import { Component, ExtComponent } from '@/libs/lila-component';
import { ListParticipants } from '@lilaquadrat/studio/lib/interfaces';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';
import { ListParticipantWithCustomerAndPayment } from '@lilaquadrat/interfaces';
import mapStructuresWithData from '@/mixins/mapStructuresWithData';

@Component
export default class AppListsSettingsScreen extends ExtComponent {

  componentName = ['app-lists-project-add', 'app-lists-project-single-edit'];

  copyId = 'app-lists-projects-add-edit';

  state = '';

  get structures() {

    return mapStructuresWithData(this.content);
  
  }

  get content(): ListParticipantWithCustomerAndPayment {

    return this.$store.state.AppListParticipants.single;

  }

  async refresh() {

    const participantData = await this.$store.dispatch('AppListParticipants/single', { list: this.$route.params.id, id: this.$route.params.participantId });

    this.$store.commit('AppListParticipants/single', participantData.data);

  }


  remove() {

    this.state = '';

    this.$store.dispatch('AppListParticipants/remove', { list: this.$route.params.id, id: this.$route.params.participantId })
      .then(() => {

        this.state = 'success';
        return true;

      })
      .catch((e) => {

        console.error(e);
        this.state = 'error';

        StudioSDK.flushCache('lists', 'list');
        StudioSDK.flushCache('listParticipants');

      });


  }


  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store.dispatch('AppListParticipants/single', { list: params.id, id: params.participantId });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<ListParticipants>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    if (preloadedData) store.commit('AppListParticipants/single', preloadedData.data);
    return Promise.resolve();

  }


}
</script>
<style lang="less">

.app-lists-single-screen {

  .categories-container {
    display: grid;
    gap: 10px;
  }

}
</style>
