import { VueConstructor } from 'vue';
import formatBytes from '@/mixins/formatBytes';
import moment from '@/mixins/moment';

const plugin = {
  install: (vue: VueConstructor): void => {
    
    vue.prototype.$date = moment;
    vue.prototype.$formatBytes = formatBytes;

  },
};

export default plugin;