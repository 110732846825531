<template>
  <article class="events-list-editor">

    <moduleList-partial v-if="!child" class="module-editor-navigation">
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('event-infos')">{{$translate('event-module-infos')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('textblock')">{{$translate('textblock')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('link')">{{$translate('link')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('callToAction')">{{$translate('callToAction')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('picture')">{{$translate('picture')}}</button-partial>
        </li>
      </moduleList-partial>

      <textblock-partial-editor v-if="child === 'textblock'" :inactive="['headline', 'subline']" v-model="value.textblock" />

      <link-partial-editor v-if="child === 'callToAction'" v-model="value.callToAction" />
      <link-partial-editor v-if="child === 'link'"  :inactive="['text']" v-model="value.link" />

      <editor-content-group-partial v-if="child === 'event-infos'">

        <input-partial v-model="value.artist" placeholder="artist">{{$translate('event-module-artist')}}</input-partial>
        <input-partial v-model="value.name" placeholder="name">{{$translate('event-module-name')}}</input-partial>
        <input-partial v-model="value.location" placeholder="location">{{$translate('event-module-location')}}</input-partial>

        <datepicker-partial time allowEmpty :from="value.start" @updateFrom="(fromDate) => value.start = fromDate">{{$translate('event-module-start')}}</datepicker-partial>
        <datepicker-partial time allowEmpty :from="value.end" @updateFrom="(fromDate) => value.end = fromDate">{{$translate('event-module-end')}}</datepicker-partial>


      </editor-content-group-partial>

      <picture-partial-editor v-if="child === 'picture'" v-model="value.picture" />

  </article>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import EventsListElement from '@/apps/editor/models/EventsListElement.model';

@Component
// eslint-disable-next-line global-require
export default class EventsListElementEditor extends PartialEditorComponent<EventsListElement> {

  itemModel = 'events-list-element';

  allergensPredefined = [
    'celery', 
    'crustaceans', 
    'eggs', 
    'fish', 
    'gluten-containing-grains', 
    'lupin', 
    'milk', 
    'molluscs',
    'mustard', 
    'nuts', 
    'peanuts', 
    'sesame-seeds', 
    'soybeans', 
    'sulfur-dioxide-and-sulphites'
  ];

  foodClassifications = [
    'clean-label',
    'fair-trade', 
    'gluten-free', 
    'gmo-free', 
    'halal',
    'kosher',
    'lactose-free', 
    'low-carb/ketogenic', 
    'organic', 
    'paleo', 
    'raw-food', 
    'sugar-free', 
    'vegan',
    'vegetarian', 
    'whole30',
  ];


}

</script>
