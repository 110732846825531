<template>
  <ul :class="{ withDescription: description }">
    <slot />
  </ul>
</template>

<script lang="ts">
import {
  Vue, Component, Prop,
} from 'vue-property-decorator';

@Component
export default class ModuleListPartial extends Vue {

 @Prop(Boolean) description: boolean;

}
</script>

<style lang="less">

.module-editor-navigation {
  display: grid;

  &.withDescription {
    gap: 10px;
  }

  li {
    display: grid;
  }
}
</style>
