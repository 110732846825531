<template>
  <article :class="navigationAnimation" class="app-lists-home-screen">
    <div v-if="content" class="pagenator full">
      <header class="main">

        <section class="stats">
          <h1>{{$translate('app-lists')}}</h1>
          <h3 v-if="content.sites">
            <template v-if="!content.sites.last">{{ content.count }}</template>
            <template v-if="content.sites.last">{{ content.sites.range[0] }} - {{ content.sites.range[1] }} ({{ content.count }})</template>
            / {{ content.all }} {{$translate('total')}}
          </h3>
        </section>

        <quickmenu-partial>
          <router-link v-if="$scope(['lists:create'])" :title="$translate('app-lists-add')" :aria-label="$translate('app-lists-add')" class="main icon" :to="{ name: 'app-lists-project-add' }"><icons-partial type="add" size="large" /></router-link>
          <router-link v-if="$scope(['listsParticipants:structuresRead'])" :title="$translate('app-lists-project-structures-home')" :aria-label="$translate('app-lists-project-structures-home')" class="main icon" :to="{ name: 'app-lists-project-structures-home' }"><icons-partial type="structure" size="large" /></router-link>
        </quickmenu-partial>
      </header>

      <!-- quickfilter with select static options and category selection -->
      <quickfilter-partial reload store="AppLists" use-emit @update="updateSearch" @reload="reload">
        <span class="filter-group">
          <select-partial inline placeholder="app-lists-title-state" :options="stateOptions" allow-empty :multiple="false" :value="filterState" @input="updateSearch({ data: $event, type: 'filterState' })" />
          <select-partial inline placeholder="app-lists-title-mode" :options="modeOptions" allow-empty :multiple="false" :value="filterMode" @input="updateSearch({ data: $event, type: 'filterMode' })" />
        </span>
      </quickfilter-partial>

      <section class="scroll-container">
        <table class="isSticky contentHead">
          <thead-partial :site-current="currentSite" :search="$route.query.search" :sort="sort" :order="order" :content="headContent" />
        </table>

        <table class="data-switch contentTable" :class="contentAnimation">
          <tbody>
            <tr v-for="(single, index) in content.data" :key="`data-overview-${index}`">
              <td class="id fullHeight action hasDescription" :class="{ hasAttribute: single.app || single.model }">
                <router-link
                  :to="{
                    name: 'app-lists-project-single-home',
                    params: {
                      id: single._id.toString(),
                      company: $store.state.Company.company,
                      project: $store.state.Project.project,
                    },
                  }"
                >
                    <span class="id">
                      {{single.name}}
                    </span>
                    <span class="description">
                      {{single.description}}
                    </span>
                </router-link>
              </td>

              <td class="mode">
                  {{$translate(`app-lists-mode-${single.mode}`)}}
              </td>

              <td class="participants">
                <div>
                  {{single.participants.confirmed}}
                  <template v-if="single.participants.max">/ {{single.participants.max}}</template>
                </div>
              </td>

              <td>
                {{$translate(`app-lists-state-${single.state}`)}}
              </td>

            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <modal-module />

  </article>
</template>
<script lang="ts">
import { ExtComponent, Component, Watch } from '@/libs/lila-component';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import { DataObject, List } from '@lilaquadrat/studio/lib/interfaces';
import cleanObject from '@/mixins/cleanObject';
import hardCopy from '@/mixins/hardCopy';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppListsHomeScreen extends ExtComponent {

  componentName = 'app-lists-project-home';

  headContent = [
    {
      key: 'name',
      text: 'name',
      classes: 'name',
    },
    {
      text: 'app-lists-title-mode',
    },
    {
      text: 'app-lists-title-entries',
      key: 'participants.confirmed',
      classes: 'participants',
    },
    {
      text: 'app-lists-title-state',
    },
  ];

  stateOptions = [
    {
      text: 'app-lists-state-open',
      value: 'open',
    },
    {
      text: 'app-lists-state-closed',
      value: 'closed',
    },
    {
      text: 'app-lists-state-waiting',
      value: 'waiting',
    },
  ];

  modeOptions = [
    {
      text: 'app-lists-mode-contact',
      value: 'contact',
    },
    {
      text: 'app-lists-mode-reservation',
      value: 'reservation',
    },
    {
      text: 'app-lists-mode-content',
      value: 'content',
    },
    {
      text: 'app-lists-mode-shopping',
      value: 'shopping',
    },
  ];

  @Watch('$route')
  watchRoute() {

    this.setSearch();

  }

  get currentSite() {

    return this.$route.params.site ? +this.$route.params.site : null;

  }

  get filterState() {

    return this.$store.state.AppLists.search?.filterState;

  }

  get filterMode() {

    return this.$store.state.AppLists.search?.filterMode;

  }

  get sort() {

    return this.$store.state.AppLists.search?.sort;

  }

  get order() {

    return this.$store.state.AppLists.search?.order;

  }

  get content(): DataObject<List[]> {

    return this.$store.state.AppLists.data || undefined;

  }

  mounted() {

    this.setSearch();

  }


  getSearch() {

    const query: Record<string, string | string[]> = {};

    query.sort = this.$route.query.sort as string || 'name';
    query.order = this.$route.query.order as string || '1';

    query.filterState = this.$route.query.filterState || null;
    query.filterMode = this.$route.query.filterMode || null;
    query.search = this.$route.query.search || null;

    return query;

  }

  setSearch() {

    // only update search if the current component is used and no child is active
    if (this.$route.name !== this.componentName) return;
    this.$store.commit('AppLists/setSearch', this.getSearch());

  }


  async updateSearch(data: {data: string, type: 'filterState' | 'filterMode'}) {

    this.$store.commit('AppLists/updateSearch', { data: data.data, type: data.type });

    const query = hardCopy(this.$store.state.AppLists.search);

    cleanObject(query);

    this.$router.push({ params: { site: '1' }, query });

  }

  async reload() {

    StudioSDK.flushCache('lists', 'list');

    const data = await this.$store
      .dispatch('AppLists/get', {
        query: this.$route.query,
        settings: {
          project: this.$route.params.project,
          company: this.$route.params.company,
          site: this.$route.params.site,
        },
      });

    this.$store.commit('AppLists/data', data.data);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    console.log(params, query);
    return store
      .dispatch('AppLists/get', {
        query,
        settings: {
          project: params.project,
          company: params.company,
          site: params.site,
        },
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<DataObject<List>>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    console.log(preloadedData);
    store.commit('AppLists/data', preloadedData.data);
    return Promise.resolve();

  }

}
</script>
<style lang="less">

.app-lists-home-screen {

  .filter-group {
    display: grid;
    grid-auto-flow: column;

    gap: 0;

    @media @desktop {
      gap: 10px;

    }
  }

  table.contentTable, table.contentHead {

    tr {
      grid-template-columns: 1fr 75px 50px 50px;

      td {

        &.hasDescription {

          .id {
            display: grid;
            color: @textColor;
            text-transform: uppercase;
          }

        }

        &.participants {
          display: grid;
          grid-template-columns: max-content min-content;
          gap: 3px;
          justify-content: center;
        }

      }

    }

  }

  .sites-filter-container {

    display: grid;
    grid-template-columns: 1fr 1fr;
    .multi(padding-top, 2);
    .multi(padding-bottom, 4);
    .basePadding;

    .sites-container {
      justify-self: end;
    }

    .select {
      display: grid;
      grid-template-columns: 150px 1fr;
    }
  }
}
</style>


