<template>
  <article :class="navigationAnimation" class="app-publishadmin-home-screen">
    <div class="pagenator full" v-if="content">
      <header class="main">

        <section class="stats">
          <h1>{{ $translate('app-publishadmin') }}</h1>
          <h3 v-if="content.sites">
            <template v-if="!content.sites.last">{{ content.count }}</template>
            <template v-if="content.sites.last">{{ content.sites.range[0] }} - {{ content.sites.range[1] }} ({{ content.count }})</template>
            / {{ content.all }} {{ $translate('total') }}
          </h3>
        </section>

        <quickmenu-partial>
          <router-link :title="'app-publishadmin-add'" :aria-label="'app-publishadmin-add'" class="main icon" :to="{ name: 'app-publishadmin-company-add' }"><icons-partial type="add" size="large" /></router-link>
        </quickmenu-partial>
      </header>

      <quickfilter-partial reload store="AppPublishadmin" useEmit @update="updateSearch" @reload="reload" />
      <!-- quickfilter with select static options and category selection -->
      <!--<quickfilter-partial reload category categoryType="publishadmin" store="Apppublishadmin" useEmit @update="updateSearch" @reload="reload">
        <select-partial inline placeholder="app-publishadmin-type" :options="stateOptions" allowEmpty :multiple="false" :value="type" @input="updateSearch({ data: $event, type: 'type' })" />
      </quickfilter-partial>-->

      <section class="scroll-container">
        <table class="isSticky contentHead">
          <thead-partial :siteCurrent="currentSite" :search="$route.query.search" :sort="sort" :order="order" :content="headContent" />
        </table>

        <table class="data-switch contentTable" :class="contentAnimation">
          <tbody>
            <tr v-for="(single, index) in content.data" :key="`data-overview-${index}`">
              <td class="id fullHeight action hasDescription" :class="{ hasAttribute: single.app || single.model }">
                <router-link
                  :to="{
                    name: 'app-publishadmin-company-edit',
                    params: {
                      id: single._id.toString(),
                    },
                  }"
                >
                  <div>
                    <span class="description">
                      {{ single.displayName }}
                    </span>
                    <span class="id">
                      {{ single.email }}
                    </span>
                  </div>
                </router-link>
              </td>

            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <modal-module />

  </article>
</template>
<script lang="ts">
import { ExtComponent, Component, Watch } from '@/libs/lila-component';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import { BasicData, MailFrom } from '@lilaquadrat/interfaces';
import { DataObject } from '@lilaquadrat/studio/lib/interfaces';
import cleanObject from '@/mixins/cleanObject';
import hardCopy from '@/mixins/hardCopy';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppPublishadminHomeScreen extends ExtComponent {

  componentName = 'app-publishadmin-project-home';

  headContent = [
    {
      key: 'name',
      text: 'name',
      classes: 'name',
    },
  ];

  // stateOptions = [
  //  {
  //    text: 'app-publishadmin-type-company',
  //    value: 'company',
  //  },
  //  {
  //   text: 'app-publishadmin-type-persons',
  //    value: 'person',
  //  },
  // ];

  @Watch('$route')
  watchRoute() {

    this.setSearch();

  }

  get currentSite() {

    return this.$route.params.site ? +this.$route.params.site : null;

  }

  get type() {

    return this.$store.state.AppPublishadmin.search?.type;

  }

  get sort() {

    return this.$store.state.AppPublishadmin.search?.sort;

  }

  get order() {

    return this.$store.state.AppPublishadmin.search?.order;

  }

  get content(): DataObject<BasicData<MailFrom>[]> {

    return this.$store.state.AppPublishadmin.data || undefined;

  }

  mounted() {

    this.setSearch();

  }


  getSearch() {

    const query: Record<string, string | string[]> = {};

    query.sort = this.$route.query.sort as string || 'name';
    query.order = this.$route.query.order as string || '1';

    query.tags = this.$route.query.tags || null;
    query.type = this.$route.query.type || null;
    query.search = this.$route.query.search || null;

    return query;

  }

  setSearch() {

    // only update search if the current component is used and no child is active
    if (this.$route.name !== this.componentName) return;
    this.$store.commit('AppPublishadmin/setSearch', this.getSearch());

  }


  async updateSearch(data: {data: string, type: 'string'}) {

    this.$store.commit('AppPublishadmin/updateSearch', { data: data.data, type: data.type });

    const query = hardCopy(this.$store.state.AppPublishadmin.search);

    cleanObject(query);

    this.$router.push({ params: { site: '1' }, query });

  }

  async reload() {

    StudioSDK.flushCache('Publishadmin', 'list');

    const data = await this.$store
      .dispatch('AppPublishadmin/get', {
        query: this.$route.query,
        settings: {
          project: this.$route.params.project,
          company: this.$route.params.company,
          site: this.$route.params.site,
        },
      });

    this.$store.commit('AppPublishadmin/data', data.data);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    console.log(params, query);
    return store
      .dispatch('AppPublishadmin/get', {
        query,
        settings: {
          project: params.project,
          company: params.company,
          site: params.site,
        },
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<DataObject<MailFrom>>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    console.log(preloadedData);
    store.commit('AppPublishadmin/data', preloadedData.data);
    return Promise.resolve();

  }

}
</script>
<style lang="less">


.app-publishadmin-home-screen {

  table.contentTable, table.contentHead {

    tr {
      grid-template-columns: 1fr 1fr 100px;

      td {

        &.hasDescription {

          .id {
            display: grid;
            color: @textColor;
            text-transform: uppercase;
          }

        }

      }

    }

  }

  .sites-filter-container {

    display: grid;
    grid-template-columns: 1fr 1fr;
    .multi(padding-top, 2);
    .multi(padding-bottom, 4);
    .basePadding;

    .sites-container {
      justify-self: end;
    }

    .select {
      display: grid;
      grid-template-columns: 150px 1fr;
    }
  }
}
</style>


