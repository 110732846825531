<template>
  <article :class="navigationAnimation" class="app-customers-home-screen">
    <div v-if="content" class="pagenator full">
      <header class="main">

        <section class="stats">
          <h1>{{$translate('app-customers')}}</h1>
          <h3 v-if="content.sites">
            <template v-if="!content.sites.last">{{ content.count }}</template>
            <template v-if="content.sites.last">{{ content.sites.range[0] }} - {{ content.sites.range[1] }} ({{ content.count }})</template>
            / {{ content.all }} {{$translate('total')}}
          </h3>
        </section>

        <quickmenu-partial v-if="$scope(['customers:create'])">
          <router-link :title="$translate('app-customers-add')" :aria-label="$translate('app-customers-add')" class="main icon" :to="{ name: 'app-customers-company-add' }"><icons-partial type="add" size="large" /></router-link>
          <router-link :title="$translate('app-customers-import')" :aria-label="$translate('app-customers-import')" class="main icon" :to="{ name: 'app-customers-company-import' }"><icons-partial type="clipboard" size="large" /></router-link>
          <router-link v-if="$scope(['customers:structuresRead'])" :title="$translate('app-customers-structures-home')" :aria-label="$translate('app-customers-structures-home')" class="main icon" :to="{ name: 'app-customers-company-structures-home' }"><icons-partial type="structure" size="large" /></router-link>
        </quickmenu-partial>
      </header>

      <quickfilter-partial reload category category-type="customers" store="AppCustomers" use-emit @update="updateSearch" @reload="reload">
        <select-partial inline placeholder="app-customers-type" :options="stateOptions" allow-empty :multiple="false" :value="type" @input="updateSearch({ data: $event, type: 'type' })" />
      </quickfilter-partial>

      <section class="scroll-container">
        <table class="isSticky contentHead">
          <thead-partial :site-current="currentSite" :search="$route.query.search" :sort="sort" :order="order" :content="headContent" />
        </table>

        <table class="data-switch contentTable" :class="contentAnimation">
          <tbody>
            <tr v-for="(single, index) in content.data" :key="`data-overview-${index}`">
              <td class="id fullHeight action hasDescription" :class="{ hasAttribute: single.app || single.model }">
                <router-link
                  :to="{
                    name: 'app-customers-company-single',
                    params: {
                      id: single._id,
                      company: $store.state.Company.company,
                      project: $store.state.Project.project,
                    },
                  }"
                >
                  <div>
                    <span class="description">
                      {{ single.name }}
                      <template v-if="single.prename">, {{ single.prename }}</template>
                    </span>
                    <span class="id">
                      {{single.id}}
                    </span>
                  </div>
                </router-link>
              </td>

              <td class="">
                <div>{{single.street}} {{single.streetNumber}}</div>
                <div>{{single.zipcode}} {{single.city}}</div>
              </td>

              <td class="">
                <tags-list-partial categories-only :value="single.tags" />
              </td>

            </tr>
          </tbody>
        </table>
      </section>

    </div>

    <modal-module />

  </article>
</template>
<script lang="ts">
import { ExtComponent, Component, Watch } from '@/libs/lila-component';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import {
  Customers, DataObject,
} from '@lilaquadrat/studio/lib/interfaces';
import cleanObject from '@/mixins/cleanObject';
import hardCopy from '@/mixins/hardCopy';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppCustomersHomeScreen extends ExtComponent {

  componentName = 'app-customers-company-home';

  headContent = [
    {
      key: 'name',
      text: 'name',
      classes: 'name',
    },
    {
      text: 'app-customers-address',
    },
    {
      text: 'app-customers-categories',
    },
  ];

  stateOptions = [
    {
      text: 'app-customers-type-company',
      value: 'company',
    },
    {
      text: 'app-customers-type-persons',
      value: 'person',
    },
  ];

  @Watch('$route')
  watchRoute() {

    this.setSearch();

  }

  get currentSite() {

    return this.$route.params.site ? +this.$route.params.site : null;

  }

  get type() {

    return this.$store.state.AppCustomers.search?.type;

  }

  get sort() {

    return this.$store.state.AppCustomers.search?.sort;

  }

  get order() {

    return this.$store.state.AppCustomers.search?.order;

  }

  get content(): DataObject<Customers[]> {

    return this.$store.state.AppCustomers.data || undefined;

  }

  mounted() {

    this.setSearch();

  }


  getSearch() {

    const query: Record<string, string | string[]> = {};

    query.sort = this.$route.query.sort as string || 'name';
    query.order = this.$route.query.order as string || '1';

    query.tags = this.$route.query.tags || null;
    query.type = this.$route.query.type || null;
    query.search = this.$route.query.search || null;

    return query;

  }

  setSearch() {

    // only update search if the current component is used and no child is active
    if (this.$route.name !== this.componentName) return;
    this.$store.commit('AppCustomers/setSearch', this.getSearch());

  }


  async updateSearch(data: {data: string, type: string}) {

    this.$store.commit('AppCustomers/updateSearch', { data: data.data, type: data.type });

    const query = hardCopy(this.$store.state.AppCustomers.search);

    cleanObject(query);

    this.$router.push({ params: { site: '1' }, query });

  }

  async reload() {

    StudioSDK.flushCache('customers', 'list');

    const data = await this.$store
      .dispatch('AppCustomers/get', {
        query: this.$route.query,
        settings: {
          project: this.$route.params.project,
          company: this.$route.params.company,
          site: this.$route.params.site,
        },
      });

    this.$store.commit('AppCustomers/data', data.data);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    console.log(params, query);
    return store
      .dispatch('AppCustomers/get', {
        query,
        settings: {
          project: params.project,
          company: params.company,
          site: params.site,
        },
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<DataObject<Customers>>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    console.log(preloadedData);
    store.commit('AppCustomers/data', preloadedData.data);
    return Promise.resolve();

  }

}
</script>
<style lang="less">

.app-customers-home-screen {

  table.contentTable, table.contentHead {

    tr {
      grid-template-columns: 1fr 1fr 100px;

      td {

        &.hasDescription {

          .id {
            display: grid;
            color: @textColor;
            text-transform: uppercase;
          }

        }

      }

    }

  }

  .sites-filter-container {

    display: grid;
    grid-template-columns: 1fr 1fr;
    .multi(padding-top, 2);
    .multi(padding-bottom, 4);
    .basePadding;

    .sites-container {
      justify-self: end;
    }

    .select {
      display: grid;
      grid-template-columns: 150px 1fr;
    }
  }
}
</style>


