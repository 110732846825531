<template>
  <article :class="navigationAnimation" class="app-learn-home-screen">

    <learn-search-module @search="updateRoute" mode="history" :site="site" :baseSearch="baseSearch" />

  </article>
</template>
<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import { Content } from '@lilaquadrat/studio/lib/interfaces';
import MainStoreState from '@/store/mainStoreState.interface';
import { Route } from 'vue-router';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppLearnHomeScreen extends ExtComponent {

  componentName = ['app-learn-project-home'];

  single: Content = null;

  routeBase: string = '';

  mounted() {

    this.$root.$on('tag', (text: string) => {

      if (text.indexOf('#') === 0) {

        this.$store.dispatch('AppLearn/addTags', [text.slice(1)]);
        this.updateRoute(this.$store.state.AppLearn.analyzedInput.search);

      }

    });


  }

  updateRoute(search: string) {

    const route = search ? { name: 'app-learn-project-home', query: { search } } : { name: 'app-learn-project-home' };

    console.log('search', search);

    if (search !== this.$route.query.search) {

      this.$router.replace(route);

    }

  }

  get baseSearch() {

    return this.$route.query.search;

  }

  get site() {

    return +this.$route.params.site || 1;

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string|string[]>, store: Store<MainStoreState>, to: Route) {

    return store.dispatch('AppLearn/search', { search: query.search, site: params.site });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData, params: Dictionary<string>, query: Dictionary<string|string[]>, store: Store<MainStoreState>, to: Route) {

    console.log(preloadedData);

    if (preloadedData?.data) store.commit('AppLearn/data', preloadedData);
    return Promise.resolve();

  }


}
</script>
<style lang="less" scoped>


.screen.app-learn-home-screen {

  display: grid;
  max-width: 100%;
  .multi(padding-top, 12);
  .multi(margin-bottom, 10);

}

</style>
