<template>
  <article :class="navigationAnimation">
    <side-screen-header-partial>{{$translate('CREATE_PROJECT')}}</side-screen-header-partial>
    <section class="content-container">
      <section class="scroll-container">
        <form class="content" @submit="save">
          <fieldset>
            <legend>
              <span class="text">{{$translate('PROJECT_SETTINGS')}}</span>
            </legend>

            <input-partial v-model="model.name" :error="errorsObject.name" placeholder="name" required :validation="/^[a-z0-9 \.\_\-\&\!\:]{1,50}$/i">{{$translate('project-name')}}</input-partial>
            <input-partial v-model="model.id" :error="errorsObject.id" description="project-id-format" placeholder="id" required :validation="/^[a-z0-9]{1,15}$/i">{{$translate('project-id')}}</input-partial>
          </fieldset>

          <apps-appstore-ext v-model="activeApps" active />

          <sample-partner-ext v-if="sampleAllowed" v-model="sampleData" />

          <action-notice-partial :state="state" :translation-pre="translationPre" :errors="errors" @update="updateErrors">
            <button-partial v-model="state" type="submit" :disabled="ready" save>{{$translate('CREATE_PROJECT')}}</button-partial>
          </action-notice-partial>
        </form>
      </section>
    </section>
  </article>
</template>

<script lang="ts">
import { ErrorsObject } from '@/libs/ActionNotice';
import { Auth } from '@/libs/lila-auth';
import { ExtComponent, Component } from '@/libs/lila-component';
import ModelsClass from '@/libs/Models.class';
import StudioSDK from '@/libs/StudioSDK';
import Project from '@/models/Project.model';
import { ErrorObject } from 'ajv';

@Component
export default class ProjectCreateScreen extends ExtComponent {

  model = ModelsClass.add<Project>({}, 'project');

  state = '';

  componentName = 'project-create';

  activeApps = [];

  translationPre = 'project';

  errors: {message?: string, errors?: ErrorObject[]} = {};

  errorsObject: ErrorsObject = {};

  get ready(): boolean {

    return !this.model.id.length || !this.model.name.length;

  }

  updateErrors(errorsObject: ErrorsObject) {

    this.errorsObject = errorsObject;

  }

  // eslint-disable-next-line class-methods-use-this
  get sampleAllowed() {

    const userPermissions = Auth.getPermissions('lilaquadrat');
    
    return userPermissions?.scope?.includes('partner:read');

  }
  
  get sampleData() {

    return {
      active: this.model.sample,
      models: this.model.sampleModels,
    };

  }

  set sampleData(data: {active: boolean, models: string[]}) {

    this.model.sample = data.active;
    this.model.sampleModels = data.models;

  }


  save($event: MouseEvent): void {

    $event.preventDefault();

    this.state = '';

    this.$store
      .dispatch('Project/create', {
        name: this.model.name,
        id: this.model.id,
        company: this.$store.state.Company.company,
        apps: this.activeApps,
        sample: this.model.sample,
        models: this.model.sampleModels,
      })
      .then(() => {

        this.state = 'success';
        this.errors = {};
        StudioSDK.flushCache('me');
        this.$store.dispatch('getPermissions', {
          company: this.$store.state.Company.company,
        });

      })
      .catch((e) => {

        this.errors = e.r;
        this.state = 'error';

      });

  }

}
</script>