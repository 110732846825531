<template>
  <section class="editor open emotion-module-editor" :class="{ changed: safeContent.changed }">
    <module-editor-header-partial v-model="content" :position="position" :index="index" :type="type" @move="move($event)" />
    <crumbs-partial :type="safeContent.type" @clearCrumbs="unsetCurrentChild" />

    <section class="editor-module-container" :class="{ optionsVisible: !child }">

      <moduleList-partial v-if="!child" class="module-editor-navigation">
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('textblock')">{{$translate('textblock')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('links')">{{$translate('links')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('picture')">{{$translate('picture')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('background')">{{$translate('background')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('video')">{{$translate('video')}}</button-partial>
        </li>
      </moduleList-partial>

      <textblock-partial-editor v-if="child === 'textblock'" v-model="safeContent.textblock" />
      <picture-partial-editor v-if="child === 'picture'" v-model="safeContent.picture" />

      <picture-partial-editor v-if="child === 'background'" v-model="safeContent.background" />
      <video-partial-editor v-if="child === 'video'" v-model="safeContent.video" />
      <list-elements-partial-editor v-if="child === 'links'" v-model="safeContent.links" type="link-partial" :model="linkModel" />

      <module-options-partial
        v-if="child === 'options' || !child"
        v-model="safeContent"
        :available-variants="availableVariants"
        :selected-variants="safeContent.variant"
        @setChild="setChild('options', true)"
        @unsetChild="setChild('options')"
        @remove="remove()"
        @clone="clone()"
      />

    </section>
  </section>
</template>

<script lang="ts">
import { Component, ModuleEditorComponent } from '@/libs/ModuleEditorComponent';
import EmotionModule from '../models/EmotionModule.model';


@Component
// eslint-disable-next-line global-require
export default class EmotionModuleEditor extends ModuleEditorComponent<EmotionModule> {

  linkModel = 'link';

  model = 'emotion-module';

}
</script>
