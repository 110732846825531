<template>
  <section class="picture-module-editor open editor" :class="{ changed: safeContent.changed }">
    <module-editor-header-partial v-model="content" :position="position" :index="index" :type="type" @move="move($event)" />
    <crumbs-partial :type="safeContent.type" @clearCrumbs="unsetCurrentChild" />

    <section :class="{ optionsVisible: !child }" class="editor-module-container">
      <editor-content-group-partial v-if="!child">

        <select-partial v-if="customModules.length" allowEmpty :placeholder="$translate('app-editor-custom-module-choose')"  :options="selectOptions" :multiple="false" v-model="safeContent.hint">{{$translate('app-editor-custom-module-title')}}</select-partial>
        
        <template v-if="!customModules.length">
          <input-partial v-model="safeContent.hint" placeholder="hint">{{$translate('hint')}}</input-partial>
          <input-partial v-model="safeContent.additionalData" placeholder="hintData">{{$translate('hintData')}}</input-partial>
        </template>

      </editor-content-group-partial>

      <editor-content-group-partial v-if="selectedModule && selectedModule.additionalData">
        <input-partial :description="selectedModule.additionalData.description" v-model="safeContent.additionalData" :placeholder="selectedModule.additionalData.name" type="text">{{selectedModule.additionalData.name || $translate('additionalData')}}</input-partial>
      </editor-content-group-partial>

      <module-options-partial
        v-if="child === 'options' || !child"
        v-model="safeContent"
        :available-variants="availableVariants"
        :selected-variants="safeContent.variant"
        @setChild="setChild('options', true)"
        @unsetChild="setChild('options')"
        @remove="remove()"
        @clone="clone()"
      />

    </section>
  </section>
</template>
<script lang="ts">
import { Component, ModuleEditorComponent } from '@/libs/ModuleEditorComponent';
import PlaceholderModule from '../models/PlaceholderModule.model';


@Component
export default class PlaceholderModuleEditor extends ModuleEditorComponent<PlaceholderModule> {

  model = 'placeholder-module';

  get customModules() {

    return this.$store.state.AppEditorData.customModules;

  }

  get selectOptions() {

    return this.customModules.map((single) => ({
      value: single.hint,
      text: single.name,
      description: single.description
    }));

  }

  get selectedModule() {

    return this.customModules.find((single) => single.hint === this.safeContent.hint);

  }

}

</script>
