<template>
  <thead>
    <tr>
      <td v-for="(item, i) in content" :key="`thead-${i}`" :class="[item.classes]">
        <router-link v-if="item.key" :class="{ active: sort === item.key, asc: order > 0, desc: order < 0 }" :to="{ params: params, query: getQuery(item) }">
          {{$translate(item.text)}}
          <icons-partial v-if="item.icon" :type='item.icon' size='small' />
          <icons-partial v-if="sort === item.key && order > 0" type='arrow-left' size='small' :rotate='90' />
          <icons-partial v-if="sort === item.key && order < 0" type='arrow-right' size='small' :rotate='90' />
        </router-link>
        <span v-if="!item.key">
          <template v-if="!item.icon">{{$translate(item.text)}}</template>
          <icons-partial v-if="item.icon" v-bind='item.icon' />
        </span>
      </td>
    </tr>
  </thead>
</template>

<script lang="ts">
import { ExtPartial } from '@/libs/lila-partial';
import hardCopy from '@/mixins/hardCopy';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
// eslint-disable-next-line global-require
export default class theadPartial extends ExtPartial {

  @Prop(String) search: string;

  @Prop(String) sort: string;

  @Prop(String) order: string;

  @Prop(Number) siteCurrent: number;

  @Prop(Array) content;


  getQuery(item) {

    const query = hardCopy(this.$route.query);

    query.sort = item.key;
    query.order = this.order === '1' && this.sort === item.key ? '-1' : '1';

    return query;

  }

  get params() {

    const params = { ...this.$route.params };

    params.site = this.siteCurrent;

    return params;

  }

}
</script>
<style lang="less">

.pagenator {

  thead {

    tr {

      td{
        .multi(padding, 2, 0);
        .font-normal;
        color: @textColor;
        font-size: @fontTextSmaller;
        text-transform: uppercase;
      }
    }

    a {

      &.active {

        display: grid;
        grid-template-columns: max-content 10px;
        gap: 5px;
      }
    }
  }

}
</style>
