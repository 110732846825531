<template>
  <article class="app-publishadmin-single-screen" :class="navigationAnimation">
    <side-screen-header-partial>{{$translate('app-publishadmin-add-title')}}</side-screen-header-partial>
    <section class="content-container">

      <form class="content" @submit="save">
        <fieldset>
          <input-partial v-model="model.email" :disabled="edit" required placeholder="app-publishadmin-email-label">{{$translate('app-publishadmin-email-label')}}</input-partial>
          <input-partial v-model="model.displayName" required placeholder="app-publishadmin-displayName-label">{{$translate('app-publishadmin-displayName-label')}}</input-partial>

          <select-publishadmin-ext v-model="model.restrictedTo" multiple></select-publishadmin-ext>

        </fieldset>

        <action-notice-partial :state="state" :translation-pre="translationPre" :errors="errors" @update="updateErrors">
          <button-partial v-if="edit" class="base colorScheme2" doublecheck @confirmed="remove">{{$translate('app-publishadmin-remove-button')}}</button-partial>
          <button-partial v-model="state" type="submit" save>
            <template v-if="!edit">{{$translate('app-publishadmin-create-button')}}</template>
            <template v-if="edit">{{$translate('app-publishadmin-edit-button')}}</template>
          </button-partial>
        </action-notice-partial>
      </form>

    </section>
  </article>
</template>

<script lang="ts">
import { ErrorsObject } from '@/libs/ActionNotice';
import { ExtComponent, Component } from '@/libs/lila-component';
import ModelsClass from '@/libs/Models.class';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import { ErrorObject, MailFrom } from '@lilaquadrat/interfaces';
import { Publish } from '@lilaquadrat/studio/lib/interfaces';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppPublishadminSettingsScreen extends ExtComponent {

  componentName = 'app-publishadmin-company-single';

  model = null;

  state: string = '';

  edit: boolean = false;

  errors: {message?: string, errors?: ErrorObject[]} = {};

  errorsObject: ErrorsObject = {};

  translationPre = 'app-publishadmin';

  get content(): Publish {

    return this.$store.state.AppPublishadmin.single;

  }

  beforeMount(): void {

    this.setModel();
    
  }

  setModel() {

    this.edit = this.$route.name === 'app-publishadmin-company-edit';

    this.model = ModelsClass.add(this.content, 'app-publishadmin-mailfrom');

  }

  remove() {

  this.$store
    .dispatch(
      'AppPublishadmin/remove',
      this.$route.params.id,
    )
    .then(() => {

      this.state = 'success';
      return true;

    })
    .catch((e) => {

      console.error(e);
      this.state = 'error';

    });

  StudioSDK.flushCache('mailFrom', 'list');

}


  updateErrors(errorsObject: ErrorsObject) {

    this.errorsObject = errorsObject;

  }

  save($event: MouseEvent) {

    let action: string;
    let data: MailFrom | {id: string, data: MailFrom};

    $event.preventDefault();
    this.state = '';

    const model = ModelsClass.save<MailFrom>(this.model, 'app-publishadmin-mailfrom');

    this.errors = null;

    if (this.edit) {

      delete model.email;

      action = 'AppPublishadmin/update';
      data = { id: this.$route.params.id, data: model };

    } else {

      action = 'AppPublishadmin/add';
      data = model;

    }


    console.log(data);

    this.$store
      .dispatch(
        action,
        data,
      )
      .then((response) => {

        this.state = 'success';

        console.log(response);

        if (!this.edit) {

          this.$router.push({ name: 'app-publishadmin-company-edit', params: { id: response.data._id } });
          StudioSDK.flushCache('mailFrom', 'list');

        } else {

          StudioSDK.flushId(this.$route.params.id);

        }


        return true;

      })
      .catch((e) => {

        this.errors = e.response.data;
        this.state = 'error';

      });

  }


  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store
      .dispatch('AppPublishadmin/single', params.id);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<MailFrom>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppPublishadmin/single', preloadedData.data);
    return Promise.resolve();

  }


}
</script>
