<template>
  <section class="editor-filename">

    <h4>{{$translate('app-editor-filename')}}</h4>

    <list-partial-editor class="noPadding" regex="^[a-z-_0-9/]$" v-model="value.settings.filename" noHead />

    <section class="details-partial">
      <p>{{$translate('if a language is defined, it will result in a subfolder. subfolders can be also added directly to the filename e.g. subfolder/filename')}}</p>
      <p>{{$translate('the .html ending is omitted from the filename and will be added automatically in the publish process')}}</p>
    </section>

    <h4 class="preview">{{$translate('app-editor-files-after-publish')}}</h4>
    <ul>
      <li v-for="(single, index) in filenames" :key="`concatFilename-${index}`">{{single}}</li>
    </ul>

  </section>
</template>
<script lang="ts">
import SiteSettings from '@/interfaces/SiteSettings.interface';
import { ExtPartial, Prop } from '@/libs/lila-partial';
import Component from 'vue-class-component';

@Component
export default class editorFilenamePartial extends ExtPartial {

  @Prop(Object) value: SiteSettings;

  get filenames() {

    const filenamesConcat: string[] = [];


    this.value.settings.filename.forEach((single) => {

      const singleArray = [];

      if (this.value.language) singleArray.push(this.value.language);
      singleArray.push(`${single}.html`);

      filenamesConcat.push(singleArray.join('/'));

    });


    return filenamesConcat;

  }

}
</script>
<style lang="less" scoped>


.editor-filename {

  display: grid;
  gap: 15px;

  h4 {
    .font-bold;
    text-transform: uppercase;
    color: @textColor;
    font-size: @fontText;

    &.preview {
      text-transform: none;
    }
  }

  ul {
    li {
      .font-normal;
      font-size: @fontText;
    }
  }

}
</style>
