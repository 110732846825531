// import PartialModel from '@/libs/lila-model';

// export default class PriceModel extends PartialModel {

//   uuid = false;

//   amount = 0;

//   currency = '';

//   tax = 19;

// }


import Models, { ModelDeclaration } from '@/libs/Models.class';

export default interface AppListsPrice {
  currency: string
  amount: number
  tax: number
}

const declaration: ModelDeclaration<AppListsPrice> = {
  currency: { type: 'string' },
  amount: { type: 'number' },
  tax: { type: 'number', default: 19 },
};

Models.register('app-lists-price', declaration);