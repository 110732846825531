<template>
  <ul>
    <li><router-link :to="{ name: 'me-home' }">Home</router-link></li>
    <li><router-link :to="{ name: 'me-settings' }">Settings</router-link></li>
    <li>
      <button type="button" v-if="$route.path !== myHome" @click="home">Set as Home</button>
      <button type="button" v-if="$route.path === myHome">This is your Home</button>
    </li>
    <li><button type="button" @click="logout">sign out</button></li>
  </ul>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import auth from '@/libs/lila-auth';

@Component
// eslint-disable-next-line global-require
export default class MeQuickmenuPartial extends Vue {

  logout() {

    auth.logout();

  }

  home() {

    this.$store.dispatch('Settings/update', { home: this.$route.path });

  }

  get myHome() {

    return this.$store.state.Settings.settings.home;

  }

}
</script>
