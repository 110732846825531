<template>
  <article class="app-payment-providers-single-screen" :class="navigationAnimation">
    <form class="content" @submit="save">
      <fieldset>
        <legend>
          <span class="text">{{$translate('app-payment-providers-type')}}</span>
        </legend>
        <button-group-partial left>
          <label class="radio">
            <input v-model="type" :disabled="edit" value="shopify" name="type" type="radio" />
            {{$translate('app-payment-providers-type-shopify')}}
          </label>
          <label class="radio">
            <input v-model="type" :disabled="edit" value="stripe" name="type" type="radio" />
            {{$translate('app-payment-providers-type-stripe')}}
          </label>
        </button-group-partial>

      </fieldset>

      <fieldset v-if="type">
        <legend>
          <span class="text">{{$translate(`app-payment-providers-type-${type}`)}}</span>
        </legend>

        <input-partial v-model="label" required placeholder="label">{{$translate('app-publish-method-label')}}</input-partial>

        <template v-if="type === 'shopify'">
          <input-partial v-model="model.validationKey" placeholder="app-payment-providers-shopify-validation-key" :error="errorsObject.host" required>{{$translate('app-payment-providers-shopify-validation-key')}}</input-partial>
        </template>
        
        <template v-if="type === 'stripe'">
          <input-partial v-model="model.token" placeholder="app-payment-providers-stripe-token" :error="errorsObject.host" required>{{$translate('app-payment-providers-stripe-token')}}</input-partial>
          <input-partial v-model="model.validationKey" placeholder="app-payment-providers-stripe-validation-key" :error="errorsObject.host" required>{{$translate('app-payment-providers-shopify-validation-key')}}</input-partial>
        </template>

        <checkbox-partial v-model="model.active">{{$translate('app-payment-providers-active')}}</checkbox-partial>

      </fieldset>

      <action-notice-partial :state="state" :translation-pre="translationPre" :errors="errors" @update="updateErrors">
        <button-partial v-if="edit" class="base colorScheme2" doublecheck @confirmed="remove">{{$translate('app-payment-providers-remove-button')}}</button-partial>
        <button-partial v-model="state" type="submit" save :disabled="!type">
          <template v-if="!edit">{{$translate('app-payment-providers-create-button')}}</template>
          <template v-if="edit">{{$translate('app-payment-providers-edit-button')}}</template>
        </button-partial>
      </action-notice-partial>

    </form>

  </article>
</template>

<script lang="ts">
import { ExtComponent, Component, Watch } from '@/libs/lila-component';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';
import { ErrorsObject } from '@/libs/ActionNotice';
import { ErrorObject } from 'ajv/dist/types';
import hardCopy from '@/mixins/hardCopy';
import MainStoreState from '@/store/mainStoreState.interface';
import ModelsClass from '@/libs/Models.class';
import { PaymentProvider, PaymentProviderShopify } from '@lilaquadrat/interfaces';
import AppPaymentProvidersShopify from '../models/shopify.model';
import AppPaymentProvidersStripe from '../models/stripe.model';

@Component
export default class AppEditorSettingsScreen extends ExtComponent {

  componentName = ['app-payment-providers-add', 'app-payment-providers-edit'];

  realType = '';

  modelsList = {
    shopify: 'app-payment-providers-shopify',
    stripe: 'app-payment-providers-stripe',
  };

  models: {shopify: AppPaymentProvidersShopify, stripe: AppPaymentProvidersStripe} = {
    shopify: null,
    stripe: null,
  };

  model = null;

  label = '';

  state: string = '';

  edit: boolean = false;

  errors: {message?: string, errors?: ErrorObject[]} = {};

  errorsObject: ErrorsObject = {};


  affectedStates: string[] = [];

  loading: boolean = false;

  loadingIndicatorTimeout = null;

  @Watch('$route')
  watchRouteFunction() {

    this.setModel();

  }

  get content(): PaymentProvider {

    return this.$store.state.AppPublish.singleProvider;

  }

  get translationPre() {

    return `app-payment-providers-${this.realType}`;

  }

  get type() {

    return this.realType;

  }

  set type(type: string) {

    this.realType = type;
    this.model = this.models[type];

  }

  updateErrors(errorsObject: ErrorsObject) {

    this.errorsObject = errorsObject;

  }

  mounted(): void {

    this.setModel();
      
  }

  setModel() {

    this.edit = this.$route.name === 'app-payment-providers-edit';

    if (this.edit) {

      const type = this.$store.state.AppPayment.singleProvider.type;

      // this.models[type] = new this.modelsList[type]().setData(this.$store.state.AppPublish.singleproviders, true, 'add');
      this.models[type] = ModelsClass.add(this.$store.state.AppPayment.singleProvider, `app-payment-providers-${type}`);
      this.type = type;
      this.label = this.$store.state.AppPayment.singleProvider.label;

    } else {

      
      this.models.shopify = ModelsClass.add<PaymentProviderShopify>({}, 'app-payment-providers-shopify');
      this.models.stripe = ModelsClass.add<AppPaymentProvidersStripe>({}, 'app-payment-providers-stripe');
      this.type = 'shopify';

    }

  }


  save($event: MouseEvent) {

    let action: string;
    let data: PaymentProvider | {id: string, data: PaymentProvider};

    $event.preventDefault();
    this.state = '';

    const model = hardCopy<PaymentProvider>(this.model);

    model.label = this.label;

    this.errors = null;

    if (this.edit) {

      action = 'AppPayment/updateProvider';
      data = { id: this.$route.params.id, data: model };

    } else {

      action = 'AppPayment/addProvider';
      data = {
        ...model,
      };

      StudioSDK.flushCache('payment-providers', 'list');

    }

    this.$store
      .dispatch(
        action,
        data,
      )
      .then((response) => {

        this.state = 'success';

        console.log(response);

        if (!this.edit) {

          this.$router.push({ name: 'app-payment-providers-edit', params: { id: response.data._id } });
          StudioSDK.flushCache('payment-providers', 'list');

        } else {

          StudioSDK.flushId(this.$route.params.id);

        }


        return true;

      })
      .catch((e) => {

        this.errors = e.response.data;
        this.state = 'error';

      });

  }

  remove() {

    this.$store
      .dispatch(
        'AppPayment/removeProvider',
        this.$route.params.id,
      )
      .then(() => {

        this.state = 'success';
        return true;

      })
      .catch((e) => {

        console.error(e);
        this.state = 'error';

      });

      StudioSDK.flushCache('payment-providers', 'list');

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    if (!params.id) return Promise.resolve();
    
    return store
      .dispatch('AppPayment/singleProvider', {
        _id: params.id,
        settings: {
          project: params.project,
          company: params.company,
        },
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<PaymentProvider>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    if (!params.id) return Promise.resolve();

    store.commit('AppPayment/singleProvider', preloadedData.data);
    return Promise.resolve();

  }


}
</script>
