<template>
  <article class="picture-editor">
    <moduleList-partial v-if="!child" class="module-editor-navigation">
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('textblock')">{{$translate('textblock')}}</button-partial>
      </li>
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('picture')">{{$translate('picture')}}</button-partial>
      </li>
    </moduleList-partial>

    <textblock-partial-editor v-if="child === 'textblock'" v-model="value.textblock" :inactive="['list']" @keyup="check($event)" />

    <picture-partial-editor v-if="child === 'picture'" v-model="value.picture" />
  </article>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import FactsElement from '@/models/FactsElement.model';

@Component
export default class FactsElementEditor extends PartialEditorComponent<FactsElement> {

  check(event: any) {

    if (event.key === 'Backspace' && this.value.textblock.headline.length === 0 && this.value.textblock.subline.length === 0 && this.value.textblock.intro.length === 0) {

      this.$emit('remove', event);

    }

    if (event.key === 'Enter' && event.target.value.length > 0) {

      this.$emit('add', event);

    }

    this.$emit('keyup', event);

  }

  model = 'facts-element';

}
</script>
