<template>
  <section class="notice-partial" :class="[type]">
    <h4 v-if="title">{{$translate(title)}}</h4>
    <p v-if="$slots.default">
      <slot />
    </p>
  </section>
</template>
<script lang="ts">
import Component from 'vue-class-component';
import { ExtPartial, Prop } from '@/libs/lila-partial';

@Component
export default class Notice extends ExtPartial {

  @Prop(String) type: string;

  @Prop(String) title: string;

}
</script>
<style lang="less" scoped>

.notice-partial {
  display: grid;
  gap: 10px;

  h4 {
    font-size: @fontTextSmaller;
    text-transform: uppercase;
    .font-head;
  }

  p {
    font-size: @fontTextSmaller;
  }

  &.error {
    .font-bold;
    .multi(padding, 0, 0, 0, 2);
    border-left: solid 2px @error;
    font-size: @fontTextSmaller;
    text-align: left;

    h4, p {
      color: @error;
    }
  }

  &.padding {
    .multi(padding, 4);

    &.notice {
      background-color: @grey1;
    }

    &.success {
      background-color: @success;
      color: @white;
    }

    &.error {
      background-color: @error;
      color: @white;

      h4, p {
        color: @white;
      }
    }

  }

  &.margin {
    .multi(margin-top, 4);
    .multi(margin-bottom, 4);
  }

}
</style>
