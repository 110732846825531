<template>
  <article :class="navigationAnimation" class="me-home-screen">

    <header-partial />

    <section :class="{ noData: !permissions.length }" class="scroll-container">

        <app-drawer-module>
          <companies-module showFavorites />
        </app-drawer-module>

    </section>

    <modal-module>{{$translate('settings')}}</modal-module>
  </article>
</template>

<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import auth from '@/libs/lila-auth';
import { Permissions } from '@lilaquadrat/studio/lib/interfaces';
import Favorites from '@/interfaces/Favorites.interface';

@Component
export default class MeHomeScreen extends ExtComponent {

  componentName = 'me-home';

  noticeCalled: boolean = false;

  editMode: boolean = false;

  changes: boolean = false;

  favorites: Favorites = {};

  mounted() {

    this.DOM.title = `${this.$store.state.user.prename} HOME`;

    if (this.$store.state.Company.company) {

      this.$store.commit('Company/company', null);

    }

    if (this.$store.state.Project.project) {

      this.$store.commit('Project/project', null);

    }

  }

  get permissions(): Permissions {

    return this.$store.state.permissionsAll;

  }

  get favoritesStore() {

    return this.$store.state.Settings.settings?.homeSettings?.favorites;

  }


  get notice() {

    return this.$store.state.AppEditorMain.noticeCache['no-company'] || {};

  }


  getNotice() {

    this.noticeCalled = true;
    this.$store.dispatch('AppEditorMain/getNotice', 'no-company');

  }

  // eslint-disable-next-line class-methods-use-this
  logout() {

    auth.logout();

  }


}
</script>