<template>
  <article class="select-media extension">
    <header>
      <section class="stats">
        <h1>{{$translate('SELECT_FILE')}}</h1>
        <h3 v-if="sites">
          <template v-if="!sites.last">{{ count }}</template>
          <template v-if="sites.last">{{ sites.range[0] }} - {{ sites.range[1] }} ({{ count }})</template> / {{ data.all }} total
        </h3>
      </section>

      <quickmenu-partial>
        <upload-partial stateOnly :uploadState="uploadState" />
        <button v-if="bigScreen" type="button" class="base colorScheme1 text" @click="triggerDropzone">{{$translate('new')}}</button>
        <button-partial v-if="bigScreen" class="colorScheme2" @confirmed="close">{{$translate('cancel')}}</button-partial>
        <button-partial v-if="!bigScreen" :title="$translate('new')" buttonContentType="icon" icon="add" colorScheme="colorScheme4" @click="triggerDropzone" />
        <button-partial v-if="!bigScreen" :title="$translate('cancel')" buttonContentType="icon" icon="close" colorScheme="colorScheme4" @confirmed="close" />
      </quickmenu-partial>
    </header>

    <media-sites-filter-partial inline reload @update="update" :content="data" :search="search" @reload="reload" />

    <media-view-partial>

      <template v-for="item in content">
        <button type="button" v-if="!item.folder" :key="`item-${item.id}`" @click="select(item)" class="item" :class="{ image: item.image }" :style="{ width: `${item.view.width}px`, flexGrow: item.view.width }">
          <i :style="{ paddingBottom: `${item.view.padding}%` }" />

          <picture-internal-partial v-if="item.image" :key="`item-picture-${item.id}`" :picture="{ src: item.view.thumb, mimetype: item.mimetype }" />

          <div class="placeholder" v-if="!item.video && !item.image" />

          <h3>{{$translate('USE_THIS')}}</h3>

          <div class="infos">
            <div class="filename">
              <span>{{ item.filename }}</span>
            </div>
            <div class="typeSize">
              <span v-if="!item.video && !item.image">{{ item.mimetype }}</span>
              <span>{{ $formatBytes(item.size, 2) }}</span>
            </div>
          </div>
        </button>

        <button type="button" :style="{ width: '200px', flexGrow: 350 }" :key="`folder-${item.filename}`" @click="update({ data: item.filename, type: 'folder' })" class="item folder" v-if="item.folder">
          <i :style="{ paddingBottom: `50%` }" />
          <div class="placeholder" />
          <div class="infos">
            <span>{{ item.filename }}</span>
          </div>
          <icons-partial colorScheme="white" type="folder" size="large" />
        </button>
      </template>
    </media-view-partial>

    <media-dropzone-partial @refresh="reload" v-show="$store.state.AppMedia.dropzoneActive" />
  </article>
</template>
<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import { ListOfModels } from '@lilaquadrat/interfaces';
import { DataObject, Media } from '@lilaquadrat/studio/lib/interfaces';

@Component
export default class SelectMediaExtension extends ExtComponent {

  site: number = 1;

  query: Record<string, string> = {};

  data: DataObject<Media[]> = null;

  filename: string = '';

  tags: string[] = [];

  folder: string = null;

  route = 'test';

  get content() {

    return this.data?.data;

  }

  get sites() {

    return this.data?.sites;

  }

  get count() {

    return this.data?.count;

  }

  get active() {

    return this.$store.state.AppMedia.showMediaSelect;

  }

  get search() {

    return {
      tags: this.tags,
      filename: this.filename,
      folder: this.folder,
    };

  }

  get bigScreen() {

    if (this.$store.state.media === 'desktop' || this.$store.state.media === 'wide') {

      return true;

    }

    return false;

  }

  get uploadState() {

    return this.$store.state.AppMedia.uploadState;

  }


  triggerDropzone() {

    this.$store.commit('AppMedia/setDropzoneActive', true);

  }

  setSite(site: number) {

    console.log('NEW SITE', site);
    this.site = site;
    this.callContent();

  }


  mounted() {

    this.DOM.onElement('dragover dragstart', this.$el, () => {

      if (this.$store.state.AppMedia.dropzoneActive) return;
      this.$store.commit('AppMedia/setDropzoneActive', true);

    });

    this.callContent();

  }

  update(input: {data: string | string[], type: string}) {

    StudioSDK.flushCache('media');
    
    this[input.type] = input.data;
    
    this.callContent();
    
  }
  
  reload() {
    
    StudioSDK.flushCache('media');
    this.callContent();

  }

  async callContent() {

    const query: Record<string, string | string[]> = {};

    if (this.filename) query.filename = this.filename;
    if (this.tags) query.tags = this.tags;
    if (this.folder) query.folder = this.folder;

    try {

      const data: SDKResponse<ListOfModels<Media>> = await this.$store.dispatch('AppMedia/get', {
        company: this.$store.state.Company.company,
        project: this.$store.state.Project.project,
        site: this.site,
        query,
      });

      this.data = data.data;
      
    } catch (e) {
      
      console.error(e);

    }


  }

  close() {

    this.$store.commit('AppMedia/showMediaSelect', false);

  }

  select(file: Media) {

    this.$store.state.AppMedia.selectModel(file);

    // this.$store.commit('AppMedia/setSelectModel', file);
    this.$store.commit('AppMedia/showMediaSelect', false);

  }

}
</script>
<style lang="less">

.select-media.extension {

  position: absolute;
  top: 40px;
  display: grid;
  grid-template-rows: min-content min-content 1fr;
  width: 100%;
  height: calc(100% - 40px);

  background-color: @white;

  .infos {
    position: absolute;
    display: grid;
    align-self: center;
    justify-self: center;

    width: 100%;
    height: 100%;

    grid-template-rows: 1fr 1fr;

    .typeSize {
      display: grid;
      grid-template-columns: max-content max-content;

      align-self: end;
      justify-content: end;
      width: 100%;

      span {
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
      }
    }

    .filename {
      display: grid;
      grid-template-rows: min-content;
      justify-self: start;

      span {
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
      }
    }

    span {
      display: inline-block;
      background-color: @color1;
      color: @white;

      &:last-child {
        justify-self: end;
      }
    }
  }

  .index(8);

  header {
    .basePadding;
    .multi(padding-top, 4);
    .multi(padding-bottom, 4);
    display: grid;
    grid-template-columns: 2fr 1fr;

    h3 {
      font-size: @fontTextSmaller;
      text-transform: uppercase;
    }
  }

  .media-view-partial {
    align-content: start;
    overflow-y: scroll;
  }

  .sites-filter-container {

    .filter-text {
      grid-template-columns: 1fr;
      width: 100%;

      .group {
        display: grid;
        grid-template-columns: max-content 1fr;
        gap: 10px;
        margin: 0;

        .tags-search {

          .values {
            display: grid;
            grid-template-columns: 200px max-content;
            gap: 5px;

            label {
              grid-row-start: 1;
              grid-column-start: 1;

              input {
                max-width: 200px;
              }
            }

            span {
              grid-row-start: 1;
              grid-column-start: 2;

              .single {
                margin: 0;
                margin-right: 5px;
                padding: 0;
                line-height: 21px;

                .unset {
                  line-height: 21px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
