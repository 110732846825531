<template>
  <section class="label-parent-container">
    <label
      :class="[textType, {
        error: error, checked: value, disabled: disabled, noIndicator,
      }]"
      class="checkbox"
      tabindex="">
      <div class="indicator-text">
        <span class="indicator">
          <icons-partial type="checked" size="small" color-scheme="white" />
        </span>

        <span v-if="textType !== 'noText'" class="label" :class="[textType]">
          <slot v-if="!text" />
          <description-partial v-if="description && !text" inline>{{$translate(description)}}</description-partial>
          {{ text }}
        </span>

        <div v-if="!text" class="label-container">
          <span v-if="required && !disabled" class="required">{{$translate('required')}}</span>
          <span v-if="disabled" class="required">{{$translate('disabled')}}</span>
        </div>
      </div>

      <div v-if="error" class="errors">
        <p>{{ error }}</p>
      </div>

      <input type="checkbox" :name="name" :required="required" :disabled="disabled" :checked="value" @change="changeHandler" />
    </label>
    <div v-if="text" class="indicator-text">
      <span class="indicator" />
      <span v-if="textType !== 'noText'" class="label" :class="[textType]">
        <slot />
        <description-partial v-if="description" inline>{{$translate(description)}}</description-partial>
      </span>

      <div class="label-container">
        <span v-if="required && !disabled" class="required">{{$translate('required')}}</span>
        <span v-if="disabled" class="required">{{$translate('disabled')}}</span>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { Component, ExtPartial, Prop } from '@/libs/lila-partial';

@Component
export default class checkboxPartial extends ExtPartial {

  @Prop(String) name: string;

  @Prop(Boolean) value: boolean;

  @Prop(String) error: string;

  @Prop(Boolean) required: boolean;

  @Prop(String) description: string;

  @Prop(Boolean) disabled: boolean;

  @Prop(Boolean) noIndicator: boolean;

  @Prop(String) text: string;

  textType: string = 'word';

  changeHandler($event: Event): void {

    const target = $event.target as HTMLInputElement;

    this.$emit('input', target.checked);

  }

  beforeUpdate(): void {

    this.setTextType();

  }

  mounted(): void {

    this.setTextType();

  }

  setTextType(): void {

    const defaultSlot = this.$slots.default ? this.$slots.default[0] : undefined;
    const useText = this.text ? this.text : defaultSlot?.text;

    if (useText) {

      if (useText.length >= 30) this.textType = 'text';

      if (useText.length < 30) this.textType = 'word';

      if (useText.length === 0) this.textType = 'noText';

    } else {

      this.textType = 'noText';

    }

  }

}
</script>
<style lang="less" scoped>

.label-parent-container {

  display: grid;

  .indicator-text {
    display: grid;
    grid-template-columns: 25px auto;
    gap: 0 20px;

    .label-container {
      grid-template-columns: 100%;
      grid-column-start: 2;
      text-align: right;
    }
  }

}

label.checkbox {
  display: grid;
  gap: 10px;

  .label,
  .description {
    .trans(color);
  }

  input {

    &[type='checkbox'] {
      display: none;
    }
  }

  &.noText {
    display: inline-block;
    min-width: auto;
    margin: 0;

    .indicator-text {
      display: inline;
    }

    .label-container {
      display: none;
    }
  }

  .label {
    .multi(margin-bottom, 2);

    justify-self: start;
    margin: 0;
    text-align: left;

    cursor: pointer;

    &.text {
      padding-top: 2px;
    }

    &.word {
      .font-bold;

      display: grid;
      gap: 5px;
      align-self: center;
      min-width: 160px;
      margin: 0;
      padding: 0;
    }

    &.noText {
      display: block;
      min-width: auto;
      margin: 0;
    }

    .description {
      .multi(padding-top, 1);
    }
  }

  .indicator {
    content: '';
    display: grid;
    width: 25px;
    height: 25px;
    border: solid 1px @textColor;

    cursor: pointer;

    transition: background .4s ease, border .4s ease;

    .icon-partial {
      display: grid;
      align-self: center;
      justify-self: center;
    }
  }

  &.noIndicator {

    align-items: center;
    justify-items: center;

    .indicator {
      display: none;
    }

    .indicator-text {
      grid-template-rows: min-content;
      grid-template-columns: max-content;

      .label {
        min-width: auto;
      }

      .label-container {
        display: none
      }
    }

    &.checked {

      .indicator-text {

        .label {

          .font-head;
        }
      }
    }
  }

  &:hover {

    .indicator {
      border: solid 1px @color1;

      svg {
        stroke: @color1;
      }
    }

    .label {
      color: @color3;
    }
  }

  &.checked {

    .indicator {
      border: solid 1px @color1;
      background-color: @color1;

      svg {
        stroke: @white;
        stroke-width: 2;
      }
    }

    &:hover {

      .indicator {
        border: solid 3px @color3;
        background-color: @color3;

        svg {
          stroke: @white;
          stroke-width: 2;
        }
      }
    }
  }

  &.disabled {

    pointer-events: none;
    user-select: none;

    .indicator {
      border: solid 3px @grey;
    }

    &.checked {

      .indicator {
        background-color: @grey;
      }
    }

    .label,
    .description {
      color: @grey;
    }
  }
}
</style>
