<template>
  <article :class="navigationAnimation">
    <section class="content">
      <form v-if="model" @submit="save">
        <checkbox-partial v-model="model.redirectToMainDomain" description="app-hosting-settings-redirectToMainDomain-description">{{$translate('app-hosting-settings-redirectToMainDomain')}}</checkbox-partial>
        <checkbox-partial v-model="model.isApp" description="app-hosting-settings-isApp-description">{{$translate('app-hosting-settings-isApp')}}</checkbox-partial>

        <action-notice-partial :state="state" :translation-pre="translationPre" :errors="errors" @update="updateErrors($event)">
          <button-partial v-model="state" type="submit" save>{{$translate('save')}}</button-partial>
        </action-notice-partial>

      </form>
    </section>
  </article>
</template>

<script lang="ts">
import { ErrorsObject } from '@/libs/ActionNotice';
import {
  ExtComponent, Component,
} from '@/libs/lila-component';
import ModelsClass from '@/libs/Models.class';
import { SDKResponse } from '@/libs/StudioSDK';
import { AppHostingSettings, ErrorObject, Hosting } from '@lilaquadrat/interfaces';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppEditorSettingsScreen extends ExtComponent {

  componentName = 'app-hosting-project-settings-nginx';

  state: string = '';

  model: AppHostingSettings = null;

  errors: {message?: string, errors?: ErrorObject[]} = {};

  errorsObject: ErrorsObject = {};

  translationPre = 'app-hosting-projects-settings';

  get hosting(): Hosting {

    return this.$store.state.AppHosting.single || {};

  }

  mounted() {

    this.model = ModelsClass.add(
      {
        isApp: this.hosting.isApp, 
        redirectToMainDomain: this.hosting.redirectToMainDomain
      }, 
      'app-hosting-nginx'
    );

  }

  async save($event) {

    $event.preventDefault();

    const settings = ModelsClass.save(this.model, 'app-hosting-nginx');


    try {

      await this.$store.dispatch('AppHosting/updateSettings', settings);
      this.state = 'success';
      
    } catch (e) {
      
      this.errors = e.response.data;
      this.state = 'error';

    }


  }

  updateErrors(errorsObject: ErrorsObject) {

    this.errorsObject = errorsObject;

  }


  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store.dispatch('AppHosting/get', { project: params.project, company: params.company });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<Hosting>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppHosting/single', preloadedData.data);
    return Promise.resolve();

  }

}

</script>
