// import PartialModel from '@/libs/lila-model';

// export default class ParticipantStateModel extends PartialModel {

//   uuid = false;

//   state = '';

// }


import Models, { ModelDeclaration } from '@/libs/Models.class';

export default interface AppListsParticipantState {
  state: string
}


const declaration: ModelDeclaration<AppListsParticipantState> = {
  state: { type: 'string' },
};

Models.register('app-lists-participant-state', declaration);
