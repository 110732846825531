import Models, { ModelDeclaration } from '@/libs/Models.class';
import Link from './Link.model';
import PicturegroupElement from './PicturegroupElement.model';

export default interface FooterSocial {
  title: string
  link: Link
  elements: PicturegroupElement[]
}

const declaration: ModelDeclaration<FooterSocial> = {
  title: { type: 'string' },
  link: { model: 'link' },
  elements: { type: 'array', contains: { model: 'picturegroup-element' } },
};

Models.register('footer-social', declaration);