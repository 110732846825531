<template>
  <article :class="navigationAnimation" class="app-tracker-home-screen">

    <quickfilter-partial useEmit @update="updateSearch($event, 'filter')" :options="categories" store="AppTracker">
      <select-partial filterMode placeholder="app-tracker-category" :multiple="false" allowEmpty :options="categories" @input="updateSearch($event, 'category')" :value="selectedCategories" />
    </quickfilter-partial>

    <div class="pagenator full" v-if="history">
      <section class="scroll-container">
        <table class="isSticky">
          <thead-partial :content="headContent" />
        </table>

        <table class="data-switch" :class="contentAnimation">
          <tbody>
            <tr v-for="(single, index) in history" :key="`data-overview-${index}`">
              <td class="id fullHeight action">
                <router-link :to="{ name: 'app-tracker-project-single', params: { id: single._id.toString() } }">
                  <div>
                    <span>
                      {{ $translate(`app-tracker-category-${single.category}`) }}
                    </span>
                    <span class="name">{{ single.description }}</span>
                  </div>
                </router-link>
              </td>

              <td class="status fullHeight">
                <span>{{ $date(single.startTime, '', true) }}</span>
                <span>{{ single.startTimeRendered }} - {{ single.endTimeRendered }}</span>
              </td>

              <td class="status fullHeight">
                {{ single.currentTimeRendered }}
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <modal-module>{{$translate('settings')}}</modal-module>
  </article>
</template>
<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import {
  ListOfModels, Tracker,
} from '@lilaquadrat/studio/lib/interfaces';
import hardCopy from '@/mixins/hardCopy';
import MainStoreState from '@/store/mainStoreState.interface';
import dayjs from 'dayjs';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';
import duration from 'dayjs/plugin/duration';
import { SDKResponse } from '@/libs/StudioSDK';
import SelectOption from '@/interfaces/selectOption.interface';
import cleanObject from '@/mixins/cleanObject';
import TrackerRendered from '../interfaces/TrackerRendered.interface';

dayjs.extend(duration);

@Component
export default class AppTrackerHomeScreen extends ExtComponent {

  componentName = 'app-tracker-project-home';

  headContent = [
    {
      text: 'app-tracker-description',
    },
    {
      text: 'app-tracker-startEnd',
    },
    {
      text: 'app-tracker-currentTime',
    },
  ];

  categories: SelectOption[] = [
    { value: 'call', text: 'app-tracker-calls' },
    { value: 'worktime', text: 'app-tracker-worktime' },
  ];

  createTracker(mode: Tracker['mode'], category: Tracker['category']) {

    this.$store.dispatch('AppTracker/createLocal', { mode, category, ...this.$store.getters.companyProject });

  }

  get viewportSize() {

    return this.$store.state.media;

  }

  get selectedCategories() {

    // if (!this.$route.query.category) return '';
    return this.$route.query.category || '';

  }

  get history(): TrackerRendered[] {

    return this.$store.state.AppTracker.data?.data?.map((single: Tracker) => {

      const formatString = this.viewportSize !== 'mobile' ? 'DD.MM.YYYY HH:mm:ss' : 'HH:mm';
      const baseData = hardCopy(single);
      const hours = dayjs.duration(baseData.currentTime, 'milliseconds').get('hours').toString().padStart(2, '0');
      const minutes = dayjs.duration(baseData.currentTime, 'milliseconds').get('minutes').toString().padStart(2, '0');
      const seconds = dayjs.duration(baseData.currentTime, 'milliseconds').get('seconds').toString().padStart(2, '0');
      const timePassed = `${hours}:${minutes}:${seconds}`;
      const renderedData = {
        ...baseData,
        currentTimeRendered: timePassed,
        startTimeRendered: dayjs(baseData.startTime).format(formatString),
        endTimeRendered: dayjs(baseData.endTime).format(formatString)
      };

      return renderedData as TrackerRendered;

    });

  }

  updateSearch(data: string[] | {data: string, type: 'string'}, type: 'category' | 'filter') {

    console.log(data, type);

    const query = hardCopy(this.$route.query);

    if (type === 'category') {

      query.category = data as string[];

    }

    if (type === 'filter') {

      const useData = data as {data: string, type: 'string'};

      query.search = useData.data;

    }

    cleanObject(query);

    this.$router.push({ params: { site: '1' }, query });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store.dispatch('AppTracker/list', {
      category: query?.category, search: query?.search, site: params.site, user: query?.user,
    });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<ListOfModels<History>>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    console.log(preloadedData);
    store.commit('AppTracker/data', preloadedData.data);
    return Promise.resolve();

  }

}
</script>
<style lang="less" scoped>

.app-tracker-home-screen {
  display: grid;
  gap: 10px;

  padding: 0;

  .pagenator.full {
    display: grid;
    gap: 30px;

    @media @tablet, @desktop {
      gap: 50px;
    }

    header.main {
      padding-bottom: 0;
    }
  }

  table::v-deep {

    thead,
    tbody {

      tr {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;

        @media @tablet, @desktop {
          grid-template-columns: 1fr 240px 150px;
        }
      }
    }
  }

  .tracker-container,
  .history-preview-container {
    .basePadding;
    display: grid;
    gap: 10px;
  }

  .tracker-container {
    display: grid;
    grid-template-rows: 41px 41px;
    gap: 20px;
    align-items: center;
    justify-items: start;

    width: 100%;

    @media @tablet, @desktop {
      grid-template-rows: 260px;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      justify-self: center;
      max-width: @desktopWidth;
    }

    .button {
      align-content: center;

      .trans(all);

      &:hover {
        border-color: @color1;
      }
    }
  }
}
</style>

