import { UnifiedRouteGuard } from '@/libs/lila-auth';
import HomeScreen from './screens/home.screen.vue';
import DomainsScreen from './screens/domains.screen.vue';
import DomainsSingleScreen from './screens/domains-single.screen.vue';
import DomainsHomeScreen from './screens/domains-home.screen.vue';
import SettingsScreen from './screens/settings.screen.vue';
// import SettingsBaseScreen from './screens/settings-base.screen.vue';
import SettingsMainScreen from './screens/settings-main.screen.vue';
import SettingsNginxScreen from './screens/settings-nginx.screen.vue';
import SettingsOverviewScreen from './screens/settings-overview.screen.vue';

export default [
  {
    path: '/:company/:project/a/hosting',
    name: 'app-hosting-project-home',
    beforeEnter: UnifiedRouteGuard,
    component: HomeScreen,
    meta: {
      animationGroup: 'hosting',
      app: 'hosting',
      parent: true,
    },
    children: [
      {
        path: 'domains',
        component: DomainsScreen,
        meta: {
          sidescreen: true,
          defaultRoute: 'app-hosting-project-domains',
          app: 'hosting',
        },
        children: [
          {
            path: 'add',
            name: 'app-hosting-project-domains-single-add',
            component: DomainsSingleScreen,
            meta: {
              sidescreen: true,
              name: 'app-hosting-project-domains-single-add',
              app: 'hosting',
            },
          },
          {
            path: ':id',
            name: 'app-hosting-project-domains-single-edit',
            component: DomainsSingleScreen,
            meta: {
              sidescreen: true,
              name: 'app-hosting-project-domains-single-edit',
              app: 'hosting',
            },
          },
          {
            path: '',
            name: 'app-hosting-project-domains',
            component: DomainsHomeScreen,
            meta: {
              sidescreen: true,
              name: 'app-hosting-project-domains',
              app: 'hosting',
            },
          },
        ],
      },
      {
        path: 'settings',
        component: SettingsScreen,
        meta: {
          sidescreen: true,
          defaultRoute: 'app-hosting-project-settings-overview',
          app: 'hosting',
        },
        children: [
          // {
          //   path: 'base',
          //   name: 'app-hosting-project-settings-base',
          //   component: SettingsBaseScreen,
          //   meta: {
          //     sidescreen: true,
          //     name: 'app-hosting-project-settings-base',
          //     app: 'hosting',
          //   },
          // },
          {
            path: 'main',
            name: 'app-hosting-project-settings-main',
            component: SettingsMainScreen,
            meta: {
              sidescreen: true,
              name: 'app-hosting-project-settings-main',
              app: 'hosting',
            },
          },
          {
            path: 'nginx',
            name: 'app-hosting-project-settings-nginx',
            component: SettingsNginxScreen,
            meta: {
              sidescreen: true,
              name: 'app-hosting-project-settings-nginx',
              app: 'hosting',
            },
          },
          {
            path: '',
            name: 'app-hosting-project-settings-overview',
            component: SettingsOverviewScreen,
            meta: {
              sidescreen: true,
              name: 'app-hosting-project-settings-overview',
              app: 'hosting',
            },
          },
        ],
      },
    ],
  },
];
