import MainStore, { state } from '@/store/main.store';
import GenericStore from '@/interfaces/GenericStore.interface';
import AppEditorDataStore from '@/apps/editor/store/data.store';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import { Content, CompatibleGenericDataType, Editor, GenericData, GenericDataWithContent } from '@lilaquadrat/studio/lib/interfaces';
import hardCopy from '@/mixins/hardCopy';
import { Project } from '@lilaquadrat/interfaces';
import CacheStoreState from './CacheStoreState.interface';

const CacheStore: GenericStore<CacheStoreState> = {

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },


  namespaced: true,
  strict: true,

  state: {
    genericDataCache: {
      customers: {},
      editor: {},
      media: {},
      lists: {},
      structures: {},
    }
  },

  mutations: {

    genericDataCacheSingle(appEditorState, childData: { data: Content, type: string }) {

      const genericData = hardCopy(appEditorState.genericDataCache);

      if (!genericData[childData.type]) genericData[childData.type] = {};

      genericData[childData.type][childData.data._id?.toString()] = childData.data;

      appEditorState.genericDataCache = genericData;

    },

    genericDataCache(appEditorState, genericDataCache) {

      appEditorState.genericDataCache = genericDataCache;

    },


  },

  actions: {
    readFromCache(store, params: { type: string, id: string }) {

      if (store.state.genericDataCache[params.type]) {

        return store.state.genericDataCache[params.type][params.id];

      }

      return undefined;

    },

    fillGenericDataCache(store) {

      const PROMISEARRAY = [];

      Object.keys(store.state.genericDataCache).forEach((single) => {

        const keys = Object.keys(store.state.genericDataCache[single]);

        if (keys.length) {

          PROMISEARRAY.push(store.dispatch('genericDataCache', { type: 'editor', idArray: keys }));

        }


      });

      return Promise.all(PROMISEARRAY);

    },

    genericDataCache(store, params: { type: string, idArray: string[] }) {

      const sdk = new StudioSDK('editor-app', AppEditorDataStore.sdkOptions());
      // eslint-disable-next-line no-unused-vars
      let useSdkFunction: (id: string) => Promise<SDKResponse<Editor|Project>>;
      const PROMISEARRAY: Promise<unknown>[] = [];

      useSdkFunction = sdk[params.type].getByInternalId;
      
      if(params.type === 'projects') {

        useSdkFunction = sdk.projects.admin.getByInternalId;
      
      }

      if(!useSdkFunction) {

        throw new Error(`SDK_FUNCTION_MISSING_${params.type}`);
      
      }

      const baseCache = store.state.genericDataCache[params.type] || {};


      params.idArray.forEach((single) => {

        // check if cache already exists
        if (baseCache[single]) return;

        PROMISEARRAY.push(
          useSdkFunction(single)
            .then((data) => {

              console.log(data);
              this.commit('Cache/genericDataCacheSingle', { type: params.type, data: data.data });
              return true;

            })
            .catch((error) => {

              console.error(error);

            }),
        );

      });

      return Promise.all(PROMISEARRAY);

    },

    generateGenericDataCache(store, genericDataCache: GenericData) {

      const generatedCache = hardCopy<GenericDataWithContent>(store.state.genericDataCache);

      if (!genericDataCache) return;

      Object.keys(genericDataCache).forEach((key) => {

        if (key === 'data') return;

        const idArray = genericDataCache[key] || [];

        idArray.forEach((single) => {

          if (!generatedCache[key]) generatedCache[key] = {};

          generatedCache[key][single] = genericDataCache.data.find((singleData) => singleData._id === single);

        });

      });

      console.log(407, generatedCache);

      this.commit('Cache/genericDataCache', generatedCache);

    },

  },

};

export default CacheStore;
