import { UnifiedRouteGuard } from '@/libs/lila-auth';
import OverviewScreen from './screens/overview.screen.vue';
import StatisticsScreen from './screens/statistics.screen.vue';
import HomeScreen from './screens/home.screen.vue';
import SingleScreen from './screens/single.screen.vue';
import AddScreen from './screens/add.screen.vue';
import AddMultipleScreen from './screens/add-multiple.screen.vue';

export default [
  {
    path: '',
    beforeEnter: UnifiedRouteGuard,
    component: OverviewScreen,
    meta: {
      app: 'tracker',
    },
    children: [
      {
        path: '/:company/:project/a/tracker/statistics/:month?/:day?',
        name: 'app-tracker-project-statistics',
        beforeEnter: UnifiedRouteGuard,
        component: StatisticsScreen,
        meta: {
          app: 'tracker',
          noScroll: true,
          rememberSettings: true,
        },
      },
      {
        path: '/:company/:project/a/tracker/:site?',
        name: 'app-tracker-project-home',
        beforeEnter: UnifiedRouteGuard,
        component: HomeScreen,
        meta: {
          app: 'tracker',
          noScroll: true,
          parent: true,
          rememberSettings: true,
        },
        children: [
          {
            path: 'single/:id',
            name: 'app-tracker-project-single',
            beforeEnter: UnifiedRouteGuard,
            component: SingleScreen,
            meta: {
              app: 'tracker',
              sidescreen: true,
              noScroll: true,
              defaultRoute: 'app-tracker-project-single',
            },
          },
          {
            path: 'add',
            name: 'app-tracker-project-add',
            beforeEnter: UnifiedRouteGuard,
            component: AddScreen,
            meta: {
              app: 'tracker',
              sidescreen: true,
              noScroll: true,
              defaultRoute: 'app-tracker-project-add',
            },
          },
          {
            path: 'add-multiple',
            name: 'app-tracker-project-add-multiple',
            beforeEnter: UnifiedRouteGuard,
            component: AddMultipleScreen,
            meta: {
              app: 'tracker',
              sidescreen: true,
              noScroll: true,
              defaultRoute: 'app-tracker-project-add',
            },
          },
        ],
      },
    ],
  },
];
