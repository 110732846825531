<template>
  <article :class="navigationAnimation">
    <section class="content">
      <link-group-partial>
        <router-link class="button base colorScheme1" :to="{ name: 'app-hostingadmin-company-settings-main' }">{{$translate('app-hostingadmin-company-settings-main')}}</router-link>
      </link-group-partial>
    </section>

  </article>
</template>

<script lang="ts">
import {
  ExtComponent, Component,
} from '@/libs/lila-component';

@Component
export default class AppEditorSettingsScreen extends ExtComponent {

  state: string = '';

  componentName = 'app-hostingadmin-company-settings-overview';

}

</script>
