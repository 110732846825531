<template functional>
  <section class="details-partial">
    <slot />
  </section>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@Component({ functional: true })
export default class detailsPartial extends Vue {}
</script>
<style lang="less">

.details-partial {
  display: grid;

  overflow: hidden;
  text-overflow: ellipsis;

  p {
    .multi(margin-top, 4);
    .font-bold;

    overflow: hidden;

    border-left: solid 2px @color1;
    color: @color1;
    font-size: @fontTextSmaller;
    text-overflow: ellipsis;

    .multi(padding, 0, 0, 0, 2);
  }

}
</style>
