<template>
  <section class="integrated-container" :class="[{ integratedOpen, fullscreenContent }, integratedViewMode]">

    <article class="screen">
      <section class="mobile-overlay" @click="toggleLearn" @keyup="toggleLearn" />
      <section class="container-top">
        <div class="top-left">
          <top-navigation-partial />
        </div>

        <div class="top-center">
        </div>

        <div class="top-right">
          <button-partial :title="$translate('app-learn')" button-content-type="icon" icon="lightbulb" color-scheme="transparent" @click="toggleLearn" />
        </div>
      </section>

      <router-view :key="`editor-children-${$route.params.link}`" class="screen main" />
    </article>
    
    <article class="integrated-view" :class="[integratedViewMode]">
      <integrated-learn-ext />
    </article>

  </section>

</template>

<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';

@Component
export default class MainScreen extends ExtComponent {

  fullscreenContent: boolean = false;


  get integratedOpen() {

    return this.$store.state.AppLearnIntegrated.open;

  }

  get integratedViewMode() {

    return this.$store.state.AppLearnIntegrated.integratedViewMode;

  }

  mounted() {

    if(this.$route.path === '/') {

      window.history.pushState({}, '', window.location.href);
    
    }

    this.$root.$on('fullscreen', (fullscreen: boolean) => {

      console.log(fullscreen);
      // this.$store.dispatch('setFullscreen', fullscreen);
      this.fullscreenContent = fullscreen;

    });

    // this.listenCommands();

  }

  // beforeDestroy() {

  //   this.unlistenCommands();

  // }

  //   // eslint-disable-next-line class-methods-use-this
  // listenCommands(): void {  

  //   this.$root.$on('command', (key: string) => {

  //     console.info('got commands doo', key);
  //     this.toggleAppOverlay();


  //   });

  // }

  // unlistenCommands() {

  //   this.$root.$off('command');

  // }

  toggleLearn() {

    this.$store.dispatch('AppLearnIntegrated/toggle', !this.integratedOpen);

  }


}
</script>
<style lang="less" scoped>

.container-top {

  position: fixed;

  top: 0;
  left: 0;

  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr max-content max-content;
  overflow: hidden;

  width: 100%;

  height: 35px;

  background: @color2;

  .basePadding;

  .index(8);

  .top-left, .top-center, .top-right {
    display: grid;
    overflow: hidden;
  }

  .top-left {
    grid-template-columns: 1fr;
    overflow: hidden;
  }

  .top-right {
    display: grid;
    grid-template-rows: 35px;
    align-content: start;
    justify-content: end;

    width: 100%;
    color: @color1;
    line-height: 35px;

    .font-head;

  }
}

.integrated-container {
  display: grid;
  grid-template-columns: 100%;

  .integrated-view {

    .index(10);
    position: fixed;
    top: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: scroll;

    width: 100vw;

    height: 100vh;
    background-color: @white;
    box-shadow: 5px 5px 5px 5px @grey;
    opacity: 0;
    pointer-events: none;

    transition: transform @aTime @aType, opacity @aTime @aType;
    transition-delay: .2s;

    transform: translateX(50px);

    @media @wideExt {
      transition: none;
    }

    &.full {
      max-width: 650px;
    }

  }

  .mobile-overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    opacity: 0;
    .index(9);
    .trans(opacity);
    cursor: pointer;
    pointer-events: none;

    @media @wideExt {
      display: none;
    }
  }

  &.integratedOpen {

    width: 100vw;

    @media @wideExt {

      width: calc(100% - 650px);

      &.full {

        .container-top {
          width: calc(100% - 650px);
        }

      }

    }

    .container-top {
      width: 100vw;
    }

    .integrated-view {
      opacity: 1;
      pointer-events: all;
      transform: translateX(0);
    }

    .mobile-overlay {
      opacity: .6;
      pointer-events: all;
    }

    &.fullscreenContent {

      .integrated-view {
        max-width: 100%;
      }
    }

  }

}
</style>
