import Models, { ModelDeclaration, ModelHooks } from '@/libs/Models.class';

export default interface AppPublishPublish {
  contentGroup: string
  planned: Date
  timing: string
  category: string
  date: string
  time: string
  method: string
  modelType: string
  referenceId: string
  app: string
  appModel: string
  model: string
}

const declaration: ModelDeclaration<AppPublishPublish> = {
  contentGroup: { type: 'string' },
  planned: { type: 'string' },
  timing: { type: 'string' },
  category: { type: 'string' },
  date: { type: 'string' },
  time: { type: 'string' },
  method: { type: 'string' },
  modelType: { type: 'string' },
  referenceId: { type: 'string' },
  app: { type: 'string' },
  appModel: { type: 'string' },
  model: { type: 'string' },
};
const hooks: ModelHooks<AppPublishPublish> = {
  preSave(data) {

    if (data.timing === 'planned') {

      data.planned = new Date(`${data.date} ${data.time}`);

    }

    data.model = data.appModel;

    delete data.appModel;
    delete data.timing;
    delete data.date;
    delete data.time;

  },
};

Models.register<AppPublishPublish>('app-publish-publish', declaration, hooks);