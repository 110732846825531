
import StructureBase from '@/interfaces/StructureBase.interface';
import Models, { ModelDeclaration } from '@/libs/Models.class';

const declaration: ModelDeclaration<StructureBase> = {
  id: { type: 'string' },
  question: { type: 'string' },
  type: { type: 'string' },
  description: { type: 'string' },
  model: { type: 'string' },
};

Models.register('structure-base', declaration);
