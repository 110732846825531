import MainStore, { state } from '@/store/main.store';
import GenericStore from '@/interfaces/GenericStore.interface';
import StudioSDK from '@/libs/StudioSDK';
import StructureExtensionState from './StructuresExtension-state.interface';

const StructureExtensionStore: GenericStore<StructureExtensionState> = {

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },

  namespaced: true,
  strict: true,

  state: {

    data: {},
    search: {
      tags: [],
      models: [],
      search: null,
      type: null,
      sort: '_id',
      order: '1',
    },

    site: null,
  },

  mutations: {

    data(appListsState, data) {

      appListsState.data = data;

    },

    setSearch(appListsState, search: { tags?: string[], search?: string }) {

      if (search.tags) {

        if (typeof search.tags === 'string') {

          appListsState.search.tags = [search.tags];

        } else {

          appListsState.search.tags = search.tags;

        }

      } else {

        appListsState.search.tags = [];

      }

      if (search.search) {

        appListsState.search.search = search.search;

      } else {

        delete appListsState.search.search;

      }

    },

    updateSearch(appListsState, update: { data: any, type: string }) {

      console.log(update, update.data.length, !update.data.length, appListsState.search);

      if (!update.data.length) {

        appListsState.search[update.type] = null;
        return;

      }

      console.log(update, appListsState.search);

      appListsState.search[update.type] = update.data;
    
    },

    setSite(appListsState, site: number) {

      appListsState.site = site || 0;

    },

    // toggleFilter(appEditorState, active: boolean) {

    //   appEditorState.filterActive = active;

    // },

  },

  actions: {

    get(store, data: { query?: {search?: string, sort?: string, order?: number, models: string[]}, site?: number }) {

      const sdk = new StudioSDK('structures-extension', StructureExtensionStore.sdkOptions());

      return sdk.structures.list(+(data?.site || 1), {search: data?.query?.search, sort: data?.query?.sort, order: data?.query?.order, models: data.query.models});

    },

  },

};

export default StructureExtensionStore;
