<template>
  <article class="all-lists-categories-editor">
    <crumbs-partial inline @clearCrumbs="unsetCurrentChild" />

    <select-editor-ext target="mail" v-model="value.contentId">{{$translate('app-lists-emails-contentid')}}</select-editor-ext>

    <select-partial v-model="value.audience" required placeholder="app-lists-emails-audience" :options="audienceOptions" :multiple="false">{{$translate('app-lists-emails-audience')}}</select-partial>
    
    <select-partial v-model="value.affectedState" required placeholder="app-lists-emails-affectedState" :options="stateOptions" :multiple="false">{{$translate('app-lists-emails-affectedState')}}</select-partial>


  </article>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { ErrorsObject } from '@/libs/ActionNotice';
import { ExtPartial } from '@/libs/lila-partial';
import { Prop } from 'vue-property-decorator';
import { EmailsContent } from '@lilaquadrat/interfaces';
import stateOptions from '../data/stateOptions';

@Component
export default class AppListsCategoriesEditor extends ExtPartial {

  @Prop(Object) value: EmailsContent;

  @Prop({ type: Object, default: () => ({}) }) errors: ErrorsObject;

  audienceOptions = [
    {
      text: 'app-lists-emails-audience-public',
      value: 'public',
      description: 'app-lists-emails-audience-public-description',
    },
    {
      text: 'app-lists-emails-audience-members',
      value: 'members',
      description: 'app-lists-emails-audience-members-description',
    },
  ];

  stateOptions = stateOptions;

}
</script>
<style lang="less" scoped>

.all-lists-categories-editor {

  display: grid;
  gap: 20px;

  .price {
    display: grid;
    grid-template-columns: 2fr 2fr 3fr;
    gap: 5px;
  }

}
</style>
