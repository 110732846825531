<template>
  <section class="editor open pictureandtext-module-editor" :class="{ changed: safeContent.changed }">
    <module-editor-header-partial v-model="content" :position="position" :index="index" :type="type" @move="move($event)" />
    <crumbs-partial :type="safeContent.type" @clearCrumbs="unsetCurrentChild" />

    <section :class="{ optionsVisible: !child }" class="editor-module-container">
      <moduleList-partial v-if="!child" class="module-editor-navigation">
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('textblock')">{{$translate('textblock')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('list')">{{$translate('list')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('links')">{{$translate('links')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('picture')">{{$translate('picture')}}</button-partial>
        </li>
      </moduleList-partial>

      <textblock-partial-editor v-if="child === 'textblock'" v-model="safeContent.textblock" class="top" />
      <picture-partial-editor v-if="child === 'picture'" v-model="safeContent.picture" />

      <list-partial-editor v-if="child === 'list'" v-model="safeContent.list" />
      <link-list-partial-editor v-if="child === 'links'" v-model="safeContent.links" />

      <module-options-partial
        v-if="child === 'options' || !child"
        v-model="safeContent"
        :available-variants="availableVariants"
        :selected-variants="safeContent.variant"
        @setChild="setChild('options', true)"
        @unsetChild="setChild('options')"
        @remove="remove()"
        @clone="clone()"
      />

    </section>
  </section>
</template>

<script lang="ts">
import { Component, ModuleEditorComponent } from '@/libs/ModuleEditorComponent';
import PictureAndTextModule from '../models/PictureAndTextModule.model';

@Component
// eslint-disable-next-line global-require
export default class PictureAndTextModuleEditor extends ModuleEditorComponent<PictureAndTextModule> {

  model = 'pictureandtext-module';

}

</script>
