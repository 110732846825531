<script lang="ts" setup>
import { useStore } from '@/store/main.store';
import Vue, { computed, ref, watch } from 'vue';

const store = useStore();
const style = ref({});
const container = ref<HTMLElement>(null);
const props = defineProps<{
  visible: boolean
  attachTo: Vue;
}>();
 const emit = defineEmits<{
   (event: 'hide'): void
 }>();
const media = computed<string>(() => store.state.media);
const isVisible = computed<boolean>(() => props.visible);
const calculateOptionsStyle = () => {

console.log('calc style', media.value, props.attachTo);

if(media.value === 'mobile') {

   style.value = {};
   return;

}

const attachTo = props.attachTo.$el as HTMLElement;
const element = container.value;
const bounds = element.getBoundingClientRect();
const targetBounds = attachTo.getBoundingClientRect();
let left = targetBounds.left;
const top = targetBounds.top + targetBounds.height;
const body = document.querySelector('body');
const positionLeft = targetBounds.left + bounds.width + 50 > body.offsetWidth;


if(positionLeft) {

   left = targetBounds.left - bounds.width + targetBounds.width;

}

style.value = {
   top: `${top}px`,
   left: `${left}px`,
};

// if(this.inline) {

// style.value =  {
// style.value =  {
//    top: `${top}px`,
//    left: `${bounds.left}px`,
// };

};
const toggle = () => {

    emit('hide');

};

watch(() => container.value, () => {

    if(container.value) calculateOptionsStyle();

});

watch(media, () => {

    if(container.value) calculateOptionsStyle();

});

</script>
<template>
    <overlay-background-partial v-if="isVisible" @close="toggle">
        
        <article ref="container" class="generic-overlay" :style="style">
            <slot />
        </article> 

    </overlay-background-partial>
</template>
<style lang="less" scoped>
.generic-overlay {

  position: absolute;
  display: grid;
  grid-template-rows: 1fr max-content max-content;
  grid-template-columns: 1fr;

  align-self: center;
  justify-self: center;
  overflow: hidden;
  width: calc(100% - 40px);
  max-height: 60vh;
  background-color: @white;
  box-shadow: 0 5px 5px @shadow;

  @media @tablet, @desktop {
    align-self: auto;
    justify-self: auto;
    max-width: 400px;
    max-height: 40vh;
  }
}
</style>