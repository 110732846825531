import GenericModel from '@/interfaces/GenericModel.interface';
import Models, { ModelDeclarationExtended, ModelOptions } from '@/libs/Models.class';
import Module from '@/models/Module.model';
import StoryElement from './StoryElement.model';

export default interface StoryModule extends GenericModel {
  elements: StoryElement[]
}

const declaration: ModelDeclarationExtended<StoryModule, Module> = {
  elements: { type: 'array', contains: { model: 'story-element' } },
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('story-module', declaration, undefined, options);