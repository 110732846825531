<template>
  <article :class="navigationAnimation" class="app-payment-endpoints-screen">
    <side-screen-header-partial>{{$translate('app-payment-providers')}}</side-screen-header-partial>
    <router-view class="content-container" :key="`app-payment-providers-overview-${$route.params.link}`" />
  </article>
</template>
<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';

@Component
export default class AppPublishHomeScreen extends ExtComponent {

  componentName = 'app-payment-providers-overview';

}
</script>
