import Models, { ModelDeclaration } from '@/libs/Models.class';
import { EmailsContent } from '@lilaquadrat/interfaces';


const declaration: ModelDeclaration<EmailsContent> = {
  contentId: { type: 'string' },
  audience: { type: 'string' },
  affectedState: { type: 'string'}
};

Models.register('app-lists-emails', declaration);
