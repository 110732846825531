<template>
  <section class="dateTime-picker label-replacement" :class="{ hasSlot, full }">
    <input-partial type="date" v-model="date" v-if="isDateTime" />
    <time-picker-partial :full="full" :value="tempTimeValue" @input="setTime">Uhr</time-picker-partial>
    <button-partial ref="trigger" type="button" buttonContentType="icon" colorScheme="inline" inline icon="arrow-down" size="small" @click="toggleOptions" />
    <span v-if="hasSlot" class="label"><slot /></span>

    <notice-partial v-if="error" type="error">
      {{$translate(`date-picker-${error}`)}}
    </notice-partial>

    <overlay-background-partial v-if="open" @mounted="calculateOptionsStyle" @close="closeOptions">
      <section ref="options" class="options-container" :style="optionsStyle">
        <section class="options">
          <div class="date-view">{{dateFormatted}} {{tempTimeValue}}</div>

          <section class="group" v-if="isDateTime">
            <header>{{$translate('app-tracker-day')}}</header>
            <div class="controls">
              <button-partial colorScheme="transparent" @click="manipulateDate(null, null, null, 'today')">{{$translate('app-tracker-today')}}</button-partial>
            </div>
            <div class="controls">
              <button-partial colorScheme="transparent" @click="manipulateDate(null, null, null, 'tomorrow')">{{$translate('app-tracker-tomorrow')}}</button-partial>
              <button-partial colorScheme="transparent" @click="manipulateDate(null, null, null, 'yesterday')">{{$translate('app-tracker-yesterday')}}</button-partial>
            </div>
          </section>

          <section class="group">
            <header>{{$translate('app-tracker-time')}}</header>
            <div class="controls">
              <button-partial colorScheme="transparent" @click="manipulateTime(null, null, null, 'now')">{{$translate('app-tracker-now')}}</button-partial>
              <button-partial colorScheme="transparent" @click="setTime({ hours: '00', minutes: '00', seconds: '00' })">00:00</button-partial>
              <button-partial colorScheme="transparent" @click="setTime({ hours: '06', minutes: '00', seconds: '00' })">06:00</button-partial>
              <button-partial colorScheme="transparent" @click="setTime({ hours: '12', minutes: '00', seconds: '00' })">12:00</button-partial>
              <button-partial colorScheme="transparent" @click="setTime({ hours: '15', minutes: '00', seconds: '00' })">15:00</button-partial>
              <button-partial colorScheme="transparent" @click="setTime({ hours: '20', minutes: '00', seconds: '00' })">20:00</button-partial>
            </div>
          </section>

          <section class="group">
            <header>{{$translate('app-tracker-manipulation')}}</header>
            <div class="controls" v-if="isDateTime">
              <button-partial colorScheme="transparent" @click="manipulateDate(1, 'day', 'subtract')">{{$translate('app-tracker-subtract-1-day')}}</button-partial>
              <button-partial colorScheme="transparent" @click="manipulateDate(1, 'day', 'add')">{{$translate('app-tracker-add-1-day')}}</button-partial>
            </div>
            <div class="controls">
              <button-partial colorScheme="transparent" @click="manipulateTime(15, 'minute', 'subtract')">{{$translate('app-tracker-subtract-15-minutes')}}</button-partial>
              <button-partial colorScheme="transparent" @click="manipulateTime(15, 'minute', 'add')">{{$translate('app-tracker-add-15-minutes')}}</button-partial>
            </div>
            <div class="controls">
              <button-partial colorScheme="transparent" @click="manipulateTime(1, 'hour', 'subtract')">{{$translate('app-tracker-subtract-1-hour')}}</button-partial>
              <button-partial colorScheme="transparent" @click="manipulateTime(1, 'hour', 'add')">{{$translate('app-tracker-add-1-hour')}}</button-partial>
            </div>
          </section>
          <section class="group" v-if="allowEmpty">
            <div class="controls">
              <button-partial class="full" colorScheme="transparent" @click="remove">{{$translate('datetimepicker_remove_date')}}</button-partial>
            </div>
          </section>
        </section>
      </section>
    </overlay-background-partial>

  </section>
</template>

<script lang="ts">
import {
  ExtComponent, Component, Prop, Watch,
} from '@/libs/lila-component';
import dayjs from 'dayjs';

@Component
export default class dateTimePickerPartial extends ExtComponent {

  @Prop(Date) value: Date;

  @Prop(String) after: string;

  @Prop(String) before: string;

  @Prop(Boolean) future: boolean;

  @Prop(Boolean) allowEmpty: boolean;

  @Prop(Boolean) full: boolean;

  @Prop({type: String, default: 'dateTime'}) mode: 'dateTime' | 'time';

  @Watch('value')
  watchValue() {

    this.setTemp();

  }

  error: 'after' | 'before' | 'future' | null = null;

  tempDate: Date = new Date();

  tempTime: Record<'hours'|'minutes'|'seconds', string> = {} as Record<'hours'|'minutes'|'seconds', string>;

  calculatedOptions = {};

  open: boolean = false;

  get date() {

    if(this.allowEmpty && !this.value) {

      return undefined;
    
    }

    if(dayjs(this.value).isValid()) {

      return this.value.toISOString().slice(0, 10);

    }

    return undefined;
  
}

  set date(date: Date) {

    console.log(date);
    this.tempDate = new Date(date);
    this.update();

  }

  get dateFormatted() {

    return dayjs(this.value).format('DD.MM.YYYY');

  }

  get timeFormatted() {

    return dayjs(this.value).format('HH:mm:ss');

  }

  get tempTimeValue() {

    return `${this.tempTime.hours?.toString().padStart(2, '0')}:${this.tempTime.minutes?.toString().padStart(2, '0')}:${this.tempTime.seconds?.toString().padStart(2, '0')}`;

  }

  get hasSlot() {

    return !!this.$slots.default?.length;

  }

  get optionsStyle() {

    if (this.$store.state.media === 'mobile') return {};

    return this.calculatedOptions;

  }

  get isDateTime() {

    return this.mode === 'dateTime';

  }

  mounted() {

    this.setTemp();

  }

  setTemp() {

    this.tempDate = this.date;

    const useDate = this.value || dayjs().set('m', 0).set('s', 0).set('h', 0);

    this.tempTime = { hours: dayjs(useDate).format('HH'), minutes: dayjs(useDate).format('mm'), seconds: dayjs(useDate).format('ss') };

  }

  setTime(time: Record<string, string>) {

    console.log(time);
    this.tempTime = time;
    this.update();

  }

  remove() {

    this.tempDate = undefined;
    this.update();

  }

  manipulateDate(amount: number, unit: 'day', type: 'add' | 'subtract', predefined?: 'today' | 'tomorrow' | 'yesterday') {

    let baseDate: dayjs.Dayjs;

    if (predefined === 'today') {

      baseDate = dayjs();

    } else if (predefined === 'tomorrow') {

      baseDate = dayjs().add(1, 'day');

    } else if (predefined === 'yesterday') {

      baseDate = dayjs().subtract(1, 'day');

    } else {

      if(!this.tempDate) this.tempDate = new Date();

      baseDate = dayjs(this.tempDate)[type](amount, unit);

    }

    console.log(baseDate);
    this.tempDate = baseDate.toDate();
    this.update();

  }

  manipulateTime(amount: number, unit: 'hour' | 'minute', type: 'add' | 'subtract', predefined?: 'now') {

    let baseDate: dayjs.Dayjs;

    if (predefined === 'now') {

      baseDate = dayjs();

    } else {

      baseDate = dayjs(this.tempDate)
        .set('hour', +this.tempTime.hours)
        .set('minute', +this.tempTime.minutes)
        .set('second', +this.tempTime.seconds)
        // eslint-disable-next-line no-unexpected-multiline
        [type](amount, unit);

    }

    if(baseDate.get('date') !== dayjs(this.value).get('date')) return;

    this.tempTime = { hours: baseDate.get('hour').toString(), minutes: baseDate.get('minute').toString(), seconds: baseDate.get('second').toString() };

    this.update();

  }

  update() {

    console.log('update', this.tempTime, this.tempDate, dayjs(this.tempDate));

    if(!this.tempDate) {

      this.$emit('input', undefined);
      return;

    }

    const outputDate = dayjs(this.tempDate).set('hour', +this.tempTime.hours).set('minute', +this.tempTime.minutes).set('second', +this.tempTime.seconds);

    if (this.after) {

      if (!outputDate.isAfter(dayjs(this.after))) {

        console.log('not after');
        this.error = 'after';
        return;

      }

    }

    if (this.before) {

      if (!outputDate.isBefore(dayjs(this.before))) {

        console.log('not before');
        this.error = 'before';
        return;

      }

    }

    if (this.future) {

      if (outputDate.isBefore(dayjs())) {

        console.log('not in future');
        this.error = 'future';
        return;

      }

    }

    this.$emit('input', outputDate.isValid() ? outputDate.toDate() : undefined);
    this.error = null;

  }

  calculateOptionsStyle() {

    const element = this.$refs.trigger.$el as HTMLElement;
    const optionsContainer = this.$refs.options as HTMLElement;
    const main = this.$el as HTMLElement;

    console.log(element, optionsContainer);

    const bounds = element.getBoundingClientRect();
    const boundsMain = main.getBoundingClientRect();
    let top = bounds.top + element.offsetHeight;
    const body = document.querySelector('body');
    const positionTop = bounds.bottom + optionsContainer.offsetHeight + 50 > body.offsetHeight;

    if (positionTop) {

      top = bounds.top - 5 - optionsContainer.offsetHeight;

    }

    this.calculatedOptions = {
      top: `${top}px`,
      left: `${boundsMain.left}px`,
      'min-width': '200px',
      'max-width': `${main.offsetWidth}px`,
    };

  }

  closeOptions() {

    this.open = false;

  }

  toggleOptions() {


    if (this.open) {

      this.open = false;

    } else {

      this.open = true;

    }

  }

}
</script>
<style lang="less" scoped>

.dateTime-picker {

  display: grid;
  grid-template-columns: 110px max-content min-content;
  gap: 0 5px;

  .input-partial::v-deep {

    input {
      font-size: @fontTextSmaller;
    }

  }

  &.hasSlot {
    grid-template-rows: min-content min-content;

    .label {
      grid-column-start: 1;
      grid-column-end: 4;
      border-top: solid 1px @textColor;
      .multi(padding-top, 2);
    }
  }

  &.full {
    grid-template-columns: 1fr 1fr 20px;

    .input-partial::v-deep {

      input {
        font-size: @fontText;
      }

    }

    .button::v-deep {
      display: grid;
      grid-template-columns: 20px;

      .icon-partial {
        display: grid;
        justify-self: end;
      }
    }

  }

  .error {
    grid-column-start: 1;
    grid-column-end: 3;
  }

}

.overlay-background {

  .options-container {
    position: absolute;
    display: grid;
    align-self: center;
    justify-self: center;
    width: 100%;
    .basePadding;

    @media @tablet, @desktop {
      max-height: 30vh;
      padding: 0;
    }

  }

  .options {

    display: grid;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    max-width: 100%;

    background-color: @white;

    transition: opacity .3s ease, transform .3s ease;

    @media @tablet, @desktop {
      max-height: 30vh;
    }

    @media @tablet, @desktop {
      box-shadow: 0 0 5px -3px @textColor;
    }

    .controls {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .date-view {
      .font-head;
      .multi(padding, 4);
      display: grid;
      justify-content: center;
      border-bottom: solid 1px @grey;
      color: @color1;
      font-size: @headline_XS;

      @media @tablet, @desktop {
        display: none;
      }
    }

    .group {
      display: grid;
      border-bottom: solid 1px @grey;
      .multi(padding-top, 2);
      .multi(padding-bottom, 2);

      header {
        .multi(padding, 0, 4);
        .font-head;
        color: @grey;
        display: grid;
      }
      
      .full {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }

  }
}
</style>
