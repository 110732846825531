<template>
    <p>{{ name }}</p>
</template>
<script lang="ts">
import { Prop } from '@/libs/lila-component';
import { ExtPartial } from '@/libs/lila-partial';
import { Content } from '@lilaquadrat/studio/lib/interfaces';
import Component from 'vue-class-component';

@Component
export default class AppListsAgreementDetailsPartial extends ExtPartial {

    @Prop(String) id: string;

    @Prop(Number) version: number;

    agreementDetails: Content = null;

    async beforeMount() {
        
        const response = await this.$store.dispatch('AppLists/getAgreement', {id: this.id, version: this.version});

        this.agreementDetails = response.data;

    }

    get name() {

        return this.agreementDetails?.id;

    }

}
</script>
<style lang="less">

</style>
  