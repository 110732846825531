<template>
  <article class="linklistElement-editor">
    <moduleList-partial v-if="!child" class="module-editor-navigation">
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('title')">{{$translate('title')}}</button-partial>
      </li>
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('links')">{{$translate('links')}}</button-partial>
      </li>
    </moduleList-partial>

    <editor-content-group-partial v-if="child === 'title'">
      <input-partial v-model="value.title" placeholder="title">{{$translate('title')}}</input-partial>
    </editor-content-group-partial>

    <list-elements-partial-editor v-if="child === 'links'" v-model="value.value" type="link-partial" :model="linkModel" />
  </article>
</template>
<script lang="ts">
import Component from 'vue-class-component';
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import ListLink from '@/models/ListLink.model';

@Component
export default class LinkListEditor extends PartialEditorComponent<ListLink> {

  linkModel = 'link';

}
</script>
