<template>
  <article class="picture-editor">
    <!-- <content-generator-partial v-model="value" mode="picture" /> -->
    <mediaSelect-partial v-if="child !== 'responsive'" v-model="value" />

    <moduleList-partial v-if="!child" class="module-editor-navigation">
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('alt')">{{$translate('alt')}}</button-partial>
      </li>
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('copyright')">{{$translate('copyright')}}</button-partial>
      </li>
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('responsive')">{{$translate('responsive')}}</button-partial>
      </li>
    </moduleList-partial>

    <section v-if="child === 'alt'" class="editor-content-container">
      <input-partial v-model="value.alt" placeholder="ALT_TEXT" @keyup="check($event)">{{$translate('ALT_TEXT')}}</input-partial>
    </section>

    <section v-if="child === 'copyright'" class="editor-content-container">
      <input-partial v-model="value.copyright" placeholder="copyright" @keyup="check($event)">{{$translate('copyright')}}</input-partial>
    </section>

    <section v-if="child === 'responsive'" class="responsive-container">
      <section class="editor-content-container">
        <description-partial>
          {{$translate('app-editor-responsive-image-description')}}
        </description-partial>
      </section>

      <mediaSelect-partial v-model="value.source[0]" media="tablet" />
      <mediaSelect-partial v-model="value.source[1]" media="desktop" />
      <mediaSelect-partial v-model="value.source[2]" media="wide" />
    </section>
  </article>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';

@Component
export default class PictureEditor extends PartialEditorComponent<any> {

  check(event: any) {

    this.$emit('keyup', event);

  }

  model = 'picture-partial';

}

</script>
<style lang="less" scoped>

.picture-editor {

  .responsive-container {
    display: grid;
    gap: 20px;

  }
}
</style>
