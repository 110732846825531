import Models, { ModelDeclaration } from '@/libs/Models.class';

export default interface ProjectHosting {
  active: boolean
}

const declaration: ModelDeclaration<ProjectHosting> = {
  active: { type: 'boolean', default: false },
};

Models.register('project-hosting', declaration);