<template>
  <header>
    <section class="head">
      <input-partial mode="inlineHead" v-model="value.id" placeholder="ID" />
    </section>

    <div class="move" v-if="type === 'overview'">
      <button type="button" class="move up" @click="move('up')"><icons-partial type="arrow-left" /></button>
      <button type="button" class="move down" @click="move('down')"><icons-partial type="arrow-right" /></button>
    </div>

    <button-partial buttonContentType="icon" icon="arrow-up" colorScheme="colorScheme2" @click="toggle" />
  </header>
</template>

<script lang="ts">
import ModuleGenericExtended from '@/apps/editor/interfaces/ModuleGenericExtended.interface';
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import { Component, Prop } from '@/libs/lila-component';

@Component
// eslint-disable-next-line global-require
export default class ModuleEditorHeaderPartial extends PartialEditorComponent<ModuleGenericExtended> {

  @Prop(String) position: string;

  toggle() {

    if (this.type !== 'overview') {

      this.$store.dispatch('AppEditorData/toggleModule', false);

    } else {

      this.$store.dispatch('AppEditorData/toggleModule', { position: this.position, uuid: this.value.uuid });

    }

  }

}
</script>
<style lang="less" scoped>

header {
  display: grid;

  .basePadding;
  .multi(padding-top, 2);

  @media @tablet, @desktop {
    .multi(padding, 4, 4, 0, 4);
  }

  grid-template-columns: 1fr 35px;
  gap: 10px;
  width: 100%;

}
</style>
