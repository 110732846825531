import { ListParticipants, Customers } from '@lilaquadrat/interfaces';

export default function mapStructuresWithData(data: ListParticipants | Customers) {

    const structureKeys = Object.keys(data).filter((key) => key.startsWith('s-'));
    const structureData = structureKeys.map((key) => {

        let useValue = data[key];
        let outputValue = data[key];
        const matchedStructure = data.structures.find((single) => single.id === key);

        if (matchedStructure.type === 'select') {

            if(!matchedStructure.multiple) {

                const matchedOption = matchedStructure.options.find((options) => options.value === data[key]);

                if(matchedOption) {

                    useValue = matchedOption.text;
                    outputValue = matchedOption.text;
                    
                }
                

            } else {

                useValue = useValue.map((single) => {

                    const matchedOption = matchedStructure.options.find((options) => options.value === single);

                    if(matchedOption) return matchedOption.text;

                    return single;

                });

                outputValue = useValue.join(', ');

            }


        } 

        return {
            id: key,
            question: matchedStructure.question,
            value: useValue,
            outputValue
        };

    });


    return structureData;

}