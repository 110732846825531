import Models, { ModelDeclaration } from '@/libs/Models.class';
import Link from './Link.model';

export default interface FooterContact {
  title: string
  link: Link
}

const declaration: ModelDeclaration<FooterContact> = {
  title: { type: 'string' },
  link: { model: 'link' },
};

Models.register('footer-contact', declaration);