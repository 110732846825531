<template>
  <article :class="navigationAnimation">
    <div class="content data-cy=side-screen">
      <link-group-partial>
        <router-link class="button base colorScheme1" :to="{ name: 'me-settings-personal' }">{{$translate('personal data')}}</router-link>
      </link-group-partial>
    </div>
  </article>
</template>

<script lang="ts">
import ModelsClass from '@/libs/Models.class';
import {
  ExtComponent, Component,
} from '@/libs/lila-component';
import Me from '@/models/Me.model';
import log from 'loglevel';

@Component
export default class MeSettingsOverviewScreen extends ExtComponent {

  content: any = {};

  model: Me = null;

  state: string = '';

  registrationComplete: boolean = false;

  componentName = 'me-settings-overview-sidescreen';

  sidescreen = true;

  mounted() {

    log.debug('MOUNTED MY SETTINGS');

    this.model = ModelsClass.add(this.user, 'me');
    return this.user;

  }

  get user(): Me {

    return this.$store.state.user;

  }

  save($event: MouseEvent) {

    $event.preventDefault();

    if (!this.model.lastname || !this.model.prename || !this.model.email || !this.model.terms) {

      this.state = 'error';
      return;

    }

    this.$store
      .dispatch('saveMe', this.model)
      .then((data) => {

        if (data && !data.state.includes('new')) {

          this.registrationComplete = true;
          this.$router.push({ name: 'me-home' });

        }

        this.state = 'success';
        return true;

      })
      .catch(() => {

        this.state = 'error';

      });

  }

}
</script>
