<template>
    <section class="services-selection">
        <button-group-partial>
            <button-partial color-scheme="transparent" @click="toggleAll">toggle all</button-partial>
        </button-group-partial>
        
        <checkbox-partial
            v-for="service in sortedAvailableForExport" :key="service.name"
            :label="$translate(`app-importadmin-${service.name}`)" :value="isSelected(service.name)"
            :description="service.required.length ? $translate('app-importadmin-required-service', [service.required.join(', ')]) : ''"
            :text="service.name" @input="change(service.name)" />
    </section>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface Service {
    name: string;
    required: string[];
}

const props = defineProps(['value']);
const emit = defineEmits(['input']);
const availableServices: Service[] = [
    { name: 'editor', required: [] },
    { name: 'lists', required: [] },
    { name: 'listParticipants', required: ['lists', 'customers'] },
    { name: 'payments', required: ['webhookCalls', 'customers'] },
    { name: 'webhookCalls', required: ['payments', 'customers'] },
    { name: 'media', required: [] },
    { name: 'customers', required: [] },
    { name: 'permissions', required: [] }
];
const sortedAvailableForExport = computed(() => [...availableServices].sort((a, b) => a.name.localeCompare(b.name)));
const isSelected = (serviceName: string): boolean => props.value.includes(serviceName);
const toggleAll = () => {

    const allSelected = availableServices.every(service => isSelected(service.name));

    emit('input', allSelected ? [] : availableServices.map(s => s.name));

};
const change = (serviceName: string) => {

    const newSelection = [...props.value];
    const serviceIndex = newSelection.indexOf(serviceName);

    if (serviceIndex === -1) {

        // Add service and required services
        newSelection.push(serviceName);

        const service = availableServices.find(s => s.name === serviceName);

        service?.required.forEach(req => {

            if (!newSelection.includes(req)) {

                newSelection.push(req);

            }

        });

    } else {

        // Remove service
        newSelection.splice(serviceIndex, 1);

    }

    console.log(newSelection);
    emit('update:value', newSelection);
    emit('input', newSelection);

};
</script>