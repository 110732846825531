<script setup lang="ts">
import { computed } from 'vue';

const hasTitle = computed(() => true);

</script>
<template>
  <section class="projects-group-module" :class="{hasTitle}">
    <template v-if="hasTitle">

      <header>
        <slot name="title"/>
      </header>
      <section class="project-group-content-container">
        <slot />
      </section>
    
    </template>
    <template v-if="!hasTitle">
      <slot />
    </template>
  </section>
</template>
<style lang="less" scoped>

.projects-group-module {

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media @tablet, @desktop {
    grid-template-columns: repeat(auto-fit, 140px);
  }

  &.hasTitle {
    position: relative;
    grid-template-columns: 1fr;

    header {

      position: sticky;
      top: 35px;
      left: 0;
      background-color: @white;
      .multi(padding-top, 2);
      .multi(padding-bottom, 2);
      .index(3);

    }

    .project-group-content-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      .basePadding;

      @media @tablet, @desktop {
        grid-template-columns: repeat(auto-fit, 180px);
      }
    }

  }

}
</style>
