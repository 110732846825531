import Models, { ModelDeclaration } from '@/libs/Models.class';
import SelectedFile from './SelectedFile.model';

export default interface PictureSource {
  media: string
  src: string
  file: SelectedFile
}

const declaration: ModelDeclaration<PictureSource> = {
  media: { type: 'string' },
  src: { type: 'string' },
  file: { model: 'selected-file' },
};

Models.register('picture-source', declaration);