import Models, { ModelDeclaration, ModelHooks } from '@/libs/Models.class';
import { Content } from '@lilaquadrat/studio/lib/interfaces';

export default interface GenericDataSingle {
  editor: string
  lists: string
  customers: string
  media: string
  data: Record<string, Content>
}

const declaration: ModelDeclaration<GenericDataSingle> = {
  editor: { type: 'string'},
  lists: { type: 'string'},
  customers: { type: 'string'},
  media: { type: 'string'},
  data: { type: 'object', default: {} },
};
const functions: ModelHooks<GenericDataSingle> = {
  preSave: (data) => {

    delete data.data;

  }
};

Models.register('generic-data-single', declaration, functions);