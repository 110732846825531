
import StructureMinMax from '@/interfaces/StructureMinMax.interface';
import Models, { ModelDeclaration } from '@/libs/Models.class';

const declaration: ModelDeclaration<StructureMinMax> = {
  min: { type: 'number' },
  max: { type: 'number', default: 500 },
};

Models.register('structure-string', declaration, undefined);
