<template>
  <section class="editor open contact-module-editor" :class="{ changed: safeContent.changed }">
    <module-editor-header-partial v-model="safeContent" :position="position" :index="index" :type="type" @move="move($event)" />
    <crumbs-partial :type="safeContent.type" @clearCrumbs="unsetCurrentChild" />

    <section :class="{ optionsVisible: !child }" class="editor-module-container">
      <moduleList-partial v-if="!child" class="module-editor-navigation">
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('textblock')">{{$translate('textblock')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('app-editor-contact-module-category-textblock')">{{$translate('app-editor-contact-module-category-textblock')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('list')">{{$translate('Liste')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('structures')">{{$translate('structures')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('feedback')">{{$translate('feedback')}}</button-partial>
        </li>
      </moduleList-partial>

      <editor-content-group-partial  v-if="child === 'list'">
        <select-lists-ext v-model="safeContent.genericData.lists" is-generic-data />
      </editor-content-group-partial>

      <editor-content-group-partial  v-if="child === 'feedback'">
        <select-editor-ext v-model="safeContent.genericData.editor" partial is-generic-data />
      </editor-content-group-partial>

      <list-elements-partial-editor v-model="safeContent.categories" v-if="child === 'structures'" type="contactCategory-partial" model="contact-category-element" partial />

      <textblock-partial-editor v-if="child === 'textblock'" v-model="safeContent.textblock" />
      <textblock-partial-editor v-if="child === 'app-editor-contact-module-category-textblock'" v-model="safeContent.categoryTextblock" />

      <module-options-partial
        v-if="child === 'options' || !child"
        v-model="safeContent"
        :available-variants="variants"
        :editor-modes="editorModes"
        :selected-variants="content.variant"
        @setChild="setChild('options', true)"
        @unsetChild="setChild('options')"
        @remove="remove()"
        @clone="clone()"
      />

    </section>
  </section>
</template>

<script lang="ts">
import { Component, ModuleEditorComponent } from '@/libs/ModuleEditorComponent';
import ContactModule from '../models/ContactModule.model';

@Component
// eslint-disable-next-line global-require
export default class ContactModuleEditor extends ModuleEditorComponent<ContactModule> {

  model = 'contact-module';

  get editorModes() {

    return this.moduleSettings?.editor?.modes;

  }

  get variants() {

    return this.moduleSettings?.variants;

  }
 
}

</script>
