<template>
  <section class="learn-search" :class="[mode, visible, { homeVisible: !resultData.length && !searchInput && searchHome && visible === 'results' }]">

    <router-link v-if="!isIntegrated" class="title" :to="{ name: 'app-learn-project-home' }"><h1>{{$translate('app-learn')}}</h1></router-link>
    <section v-if="!visible || visible === 'input'" class="input-container">
      <input-partial placeholder="app-learn-input-placeholder" autofocus :value="useSearch" @focus="focus" debounce="500" @input="search" />
      <button-partial v-if="suggestions && isIntegrated" class="suggestions-button" colorScheme="transparent" @click="addSuggestions">{{$translate('app-learn-content-suggestions')}}</button-partial>
    </section>

    <template v-if="!visible || visible === 'results'">
      <section class="statistics" v-if="resultData.length || searchInput">
        <div :class="optionalAnimation">{{results.all}} {{$translate('app-learn-results')}} &bdquo;{{analyzedInput.cache}}&rdquo;</div>
        <sites-partial v-if="resultData.length" @update="navigateSite" :mode="isIntegrated ? 'inline' : 'history'" :data="results" />
      </section>

      <section class="scroll-container">
        <article v-if="resultData.length || searchInput" class="resultsContainer" :class="optionalAnimation">

          <template v-if="resultData.length">
            <section v-for="(single, index) in resultData" class="result" :key="`search-result-data-${index}`">
              <button type="button" v-if="isIntegrated" @click="showContent(single.settings.url)"><h2>{{single.settings.title}}</h2></button>
              <router-link v-if="!isIntegrated" :to="{ name: 'app-learn-project-content', params: { link: single.settings.url } }"><h2>{{single.settings.title}}</h2></router-link>
              <div>
                <button type="button" v-if="isIntegrated" @click="showContent(single.settings.url)">/{{single.settings.url}}</button>
                <router-link v-if="!isIntegrated" :to="{ name: 'app-learn-project-content', params: { link: single.settings.url } }">/{{single.settings.url}}</router-link>
                <p>{{single.settings.description}}</p>
              </div>
              <button-group-partial left class="tags">
                <button-partial colorScheme="transparent" @click="addTag(single)" v-for="(single, index) in single.tags" :key="`search-result-data-tags-${index}`">#{{single}}</button-partial>
              </button-group-partial>
            </section>
          </template>

          <template v-if="!resultData.length && searchInput">
            <h2>{{$translate('app-learn-no-results-pre')}} &bdquo;{{analyzedInput.cache}}&rdquo; {{$translate('app-learn-no-results')}}</h2>
          </template>

        </article>

        <lila-content-module class="home" v-if="!resultData.length && !searchInput && searchHome" inline :linkEvents="mode === 'integrated' ? true : false" :content="searchHome" />
        <lila-content-module class="footer" v-if="searchFooter" inline :content="searchFooter" />
      </section>
    </template>

  </section>
</template>
<script lang="ts">
import { ExtComponent, Prop, Watch } from '@/libs/lila-component';
import { prepareContent } from '@lilaquadrat/studio/lib/frontend';
import { DataObject, Editor } from '@lilaquadrat/studio/lib/interfaces';
import Component from 'vue-class-component';

@Component
export default class learnSearchPartial extends ExtComponent {

  @Prop(String) baseSearch: string | undefined;

  @Prop(Number) site: string | undefined;

  @Prop(String) mode: 'history' | 'integrated';

  @Prop(String) visible: 'results' | 'input';

  resultData: Editor[] = [];

  results: DataObject<Editor> = {};

  inited: boolean = false;

  useSearch: string = '';

  @Watch('$route')
  routeFunction() {

    if (this.mode === 'integrated') {

      this.updateSuggestions();
      return;

    }

    if (!this.$route.query.search?.length) {

      this.$store.dispatch(this.useStore('reset'));

      // only update the search input if its not focues
      if (document.activeElement.tagName !== 'INPUT') {

        this.useSearch = this.$route.query.search as string;

      }

    } else {

      this.$store.dispatch(this.useStore('analyzeInput'), this.$route.query.search);

    }

  }

  @Watch('baseSearch')
  baseSearchFunction() {

    if (document.activeElement.tagName !== 'INPUT') {

      this.useSearch = this.baseSearch;

    }

  }

  @Watch('searchCache')
  searchCacheFunction() {

    if (document.activeElement.tagName !== 'INPUT') {

      this.useSearch = this.storeState.searchCache;

    }

  }

  @Watch('analyzedInput')
  analyzedInputFunction() {

    if (this.visible !== 'input' && this.visible) return;

    if (this.mode === 'integrated') this.$emit('search', this.storeState.analyzedInput.cache);
    this.$store.commit(this.useStore('searchCache'), this.storeState.analyzedInput.cache);
    this.updateSuggestions();

  }

  @Watch('contentAnimation')
  contentAnimationFunction() {

    if (!this.contentAnimation.started && !this.contentAnimation.resolving) {

      this.updateResultData();
      // this.$store.dispatch('AppLearn/analyzeInput', this.$route.query.search as string);

    }

  }

  @Watch('resultDataStore')
  resultDataStoreFunction() {

    if (this.mode === 'integrated') {

      this.updateResultData();

    }
    // this.$store.dispatch(
    //   'AppLearn/analyzeInput',
    //   this.mode === 'integrated'
    //     ? this.$store.state.AppLearn.cache.search
    //     : this.$route.query.search as string,
    // );

  }

  get optionalAnimation() {

    if (this.mode === 'history') {

      return this.contentAnimation;

    }

    return false;

  }

  get storeState() {

    return this.mode === 'integrated'
      ? this.$store.state.AppLearnIntegrated
      : this.$store.state.AppLearn;

  }

  get analyzedInput() {

    return this.storeState.analyzedInput;

  }

  async search(input: string) {

    if (this.mode === 'integrated') {

      if (!input?.length) {

        this.$store.dispatch(this.useStore('reset'));
        this.useSearch = '';

      } else {

        this.$store.dispatch(this.useStore('analyzeInput'), input);

      }

    } else {

      this.$emit('search', input);

    }

  }

  navigateSite(site: number) {

    this.$emit('search', this.searchCache, site);

  }

  updateSuggestions() {

    if (this.mode !== 'integrated') return;

    const suggestions = [];

    if (this.$route.meta.app) {

      suggestions.push(this.$translate(`app-${this.$route.meta.app}`));

    }

    if (this.$route.meta.learnSuggestions) {

      suggestions.push(this.$route.meta.learnSuggestions);

    }

    this.$store.dispatch(this.useStore('updateSuggestions'), suggestions);

  }

  addSuggestions() {

    this.$store.dispatch(this.useStore('replaceTags'), this.suggestions);
    this.$store.commit(this.useStore('activeTab'), 0);

  }

  get suggestions() {

    return this.$store.state.AppLearnIntegrated.suggestions?.length
      ? this.$store.state.AppLearnIntegrated.suggestions
      : null;

  }

  get resultDataStore() {

    return this.storeState.data?.data?.data;

  }

  get searchInput() {

    if (this.mode === 'integrated') {

      return this.storeState.searchCache;

    }

    return this.$route.query.search;

  }

  get searchHome() {

    return this.storeState.home ? prepareContent(this.storeState.home, null) : null;

  }

  get searchFooter() {

    return this.storeState.footer ? prepareContent(this.storeState.footer, null) : null;

  }

  get isIntegrated() {

    return this.mode === 'integrated';

  }

  get searchCache() {

    return this.storeState.searchCache;

  }

  mounted() {

    this.$store.dispatch(this.useStore('home'));
    this.$store.dispatch(this.useStore('footer'));
    this.updateResultData();
    this.updateSuggestions();

    if (this.mode === 'integrated') {

      if (this.searchCache) {

        this.useSearch = this.searchCache;

      }

    } else if (this.baseSearch) {

      this.$store.dispatch(this.useStore('analyzeInput'), this.baseSearch);
      this.useSearch = this.baseSearch;

    }


  }

  focus() {

    this.$emit('focus');

  }

  updateResultData() {

    this.resultData = this.resultDataStore || [];
    this.results = this.storeState.data?.data || {};

  }

  showContent(url: string) {

    console.log('show content', url);
    this.$emit('showType', { type: 'content', url });

  }

  reset() {

    this.resultData = [];
    this.results = {};

    this.$store.dispatch(this.useStore('reset'));
    this.$router.replace({ name: 'app-learn-project-home' });

  }


  addTag(tag: string) {

    console.log(tag, this.mode);
    this.$store.dispatch(this.useStore('addTags'), [tag]);
    if (this.mode === 'history') this.$emit('search', this.storeState.analyzedInput.cache);

  }

  useStore(commitOrDispatch: string) {

    return this.mode === 'integrated'
      ? `AppLearnIntegrated/${commitOrDispatch}`
      : `AppLearn/${commitOrDispatch}`;

  }


}
</script>
<style lang="less" scoped>


.learn-search {
  display: grid;
  gap: 30px 0;
  overflow: hidden;
  width: 100%;
  max-width: 850px;

  @media @desktop {
    grid-template-columns: 1fr minmax(300px, @desktopWidth);
  }

  &:not(.homeVisible) {
    grid-template-rows: min-content 1fr;
  }

  &.input {

    &:not(.homeVisible) {
      grid-template-rows: min-content;
    }

  }

  .input-partial::v-deep {

    input {
      padding: 0;
    }
  }

  .scroll-container {
    display: grid;
    overflow: hidden;
    overflow-y: scroll;

    @media @desktop {
      grid-column-start: 2;
    }

    .fullscreenContent & {
      overflow: hidden;
    }
  }

  .title {
    display: grid;
    justify-self: center;
    width: 100%;
    max-width: @desktopWidth;
    .multi(padding, 0, 0, 0, 4);

    @media @tablet, @desktop {
      .multi(padding, 0, 0, 0, 8);
    }

    @media @desktop {
      display: grid;
      grid-column-start: 1;
      align-content: center;
      justify-content: end;
      .multi(padding, 0, 0, 0, 8);
    }

  }

  .input-container {

    .suggestions-button {
      position: absolute;
      display: grid;
      align-self: center;
      justify-self: end;
    }
  }

  .input-container, .resultsContainer, .statistics {
    display: grid;
    justify-self: center;
    width: 100%;
    max-width: @desktopWidth;

    .multi(padding, 0, 4);

    @media @tablet, @desktop {
      .multi(padding, 0, 8);
    }
  }

  .input-container, .resultsContainer, .lila-content-module, .statistics {

    @media @desktop {
      grid-column-start: 2;
    }

  }

  .lila-content-module.footer {
    .multi(padding-top, 20);
    display: grid;
    justify-self: center;
    width: 100%;
    max-width: @desktopWidth;
  }

  .statistics {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;

    .sites-container {
      justify-self: end;
    }
  }

  .input-container {

    .input-partial::v-deep {

      input {
        font-size: @headline_S;
      }
    }
  }

  &.integrated {

    .input-container {

      padding: 0;

      .input-partial::v-deep {

        input {
          font-size: @headline_XS;
        }
      }
    }

    .scroll-container {
      grid-column-start: 1;
    }
  }

  .resultsContainer {

    display: grid;
    grid-auto-rows: min-content;
    gap: 20px;

    @media @desktop {
      width: @desktopWidth;
    }

    .result {
      display: grid;
      gap: 5px;

      button {
        text-align: left;

        h2 {
          font-size: @headline_S;
        }

        .basicHover();
      }

      p {
        font-size: @fontText;
      }

    }

    .statistics {
      border-bottom: solid 1px @grey;
      .multi(padding, 0, 0, 4, 0);
    }
  }

  &.integrated {

    @media @desktop {
      grid-template-columns: 1fr;
    }

    .input-container, .resultsContainer, .lila-content-module, .statistics {

      @media @desktop {
        grid-column-start: 1;
      }

    }

    .title {
      grid-column-start: 1;
      grid-column-end: 2;

      justify-content: start;
      height: 40px;
      line-height: 40px;

      h1 {
        font-size: @fontText;
      }
    }

  }

}

</style>
