<template>
  <label class="textarea" :class="{ error: errorMessage }" tabindex="">
    <span class="textarea-placement-container">
      <textarea :placeholder="$translate(placeholder)" :name="name" :readonly="readonly" :disabled="disabled" v-on:input="input($event.target.value)" @keyup="checkInput($event)">{{value}}</textarea>
      <div class="length">
        {{ length }}
        <span v-if="maxLength">/ {{ maxLength }}</span>
      </div>
    </span>

    <div class="label-container">
      <span class="label"> <slot /> </span>
      <span class="required" v-if="disabled">{{$translate('disabled')}}</span>
      <span class="required" v-if="required && !disabled">{{$translate('required')}}</span>
    </div>

    <notice-partial v-if="errorMessage" type="error">
      {{errorMessage}}
    </notice-partial>

    <description-partial class="input-description" v-if="description">{{$translate(description)}}</description-partial>

  </label>
</template>

<script lang="ts">
import { ParsedError } from '@/libs/ActionNotice';
import { ExtPartial } from '@/libs/lila-partial';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class textareaPartial extends ExtPartial {

  @Prop(String) type: string;

  @Prop(String) placeholder: string;

  @Prop(String) name: string;

  @Prop(Object) error: ParsedError;

  @Prop(String) regex: string;

  @Prop(String) label: string;

  @Prop(String) validation: string;

  @Prop(Boolean) required: boolean;

  @Prop(Boolean) disabled: boolean;

  @Prop(String) maxLength: string;

  @Prop(String) value: string;

  @Prop(Boolean) readonly: boolean;

  @Prop(String) description: string;

  @Prop(Number) debounce: number;

  length: number = 0;

  timeout: any;

  checkInput($event: KeyboardEvent) {

    const input: HTMLTextAreaElement = this.$el.querySelector('textarea');

    this.updateLength(input.value.length, +this.maxLength);

    if ($event.key === 'Escape') {

      input.blur();

    }

  }

  mounted() {

    this.updateLength(this.$el.querySelector('textarea').value.length, +this.maxLength);

  }

  get errorMessage() {

    return this.error?.error;

  }

  updateLength(currentLength: number, maxLength: number) {

    this.length = currentLength;

    if (maxLength) {

      if (currentLength > maxLength) {

        this.$el.querySelector('textarea').value = this.$el.querySelector('textarea').value.substring(0, maxLength);
        this.input(this.$el.querySelector('textarea').value);

      }

    }

  }

  input(value: string) {

    if (!this.debounce) return this.$emit('input', value);

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {

      this.$emit('input', value);

    }, +this.debounce);

    return value;

  }

}
</script>
<style lang="less" scoped>


label.textarea {
  position: relative;

  display: grid;
  gap: 5px;
  width: 100%;

  .length {
    position: absolute;
    align-self: end;
    justify-self: end;
    background-color: @grey1;
    opacity: .8;
    pointer-events: none;
    .multi(padding, 1);

    .multi(margin, 2);
  }

  .label-container {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .textarea-placement-container {

    display: grid;

    textarea {
      display: grid;

      width: 100%;
      min-height: 200px;
      padding: 5px;
      border: solid 1px @color1;

      background-color: transparent;

      color: @textColor;

      font-size: @fontText;

      &::selection {
        background: @color1;
        color: @white;
      }

      .trans(border);

      &:hover {
        border-color: @grey;
      }

    }

  }

  &.error {

    .textarea-placement-container {

      textarea {
        border-color: @error;
      }

    }

    .required {
      color: @error;
    }

  }

}
</style>

