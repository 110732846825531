<template>
  <section class="loading-partial">
    <section class="content-container">
      <h1>PLEASE WAIT</h1>

      <div class="animation">
        <span />
      </div>
    </section>
  </section>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class loadingPartial extends Vue {

  @Prop(String) label: string;

}
</script>
