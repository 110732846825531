<template>
  <article :class="navigationAnimation">
    <section class="content">
      <h1>{{$translate(hosting.active ? 'app-hosting-active' : 'app-hosting-inactive')}}</h1>
      <button-group-partial v-if="$scope(['hosting:toggle'])">
        <button-partial @confirmed="toggle('deactivate')" doublecheck v-if="hosting.active">{{$translate('app-hosting-deactivate')}}</button-partial>
        <button-partial @confirmed="toggle('activate')" doublecheck v-if="!hosting.active">{{$translate('app-hosting-activate')}}</button-partial>
      </button-group-partial>
    </section>
  </article>
</template>

<script lang="ts">
import {
  ExtComponent, Component,
} from '@/libs/lila-component';
import { SDKResponse } from '@/libs/StudioSDK';
import { Hosting } from '@lilaquadrat/studio/lib/interfaces';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppEditorSettingsScreen extends ExtComponent {

  componentName = 'app-hosting-project-settings-main';

  state: string = '';

  get hosting(): Hosting {

    return this.$store.state.AppHosting.single || {};

  }

  async toggle(type: 'activate' | 'deactivate') {

    console.log('type', type);
    await this.$store.dispatch('AppHosting/toggle', { project: this.$route.params.project, company: this.$route.params.company, type });
    this.$store.dispatch('AppHosting/refreshSingle', { project: this.$route.params.project, company: this.$route.params.company });

  }


  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store.dispatch('AppHosting/get', { project: params.project, company: params.company });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<Hosting>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppHosting/single', preloadedData.data);
    return Promise.resolve();

  }

}

</script>
