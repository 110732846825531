<template>
  <section class="transition hoverInfo dropzone-partial">

    <div class="content-container">

      <h2>{{$translate('upload new files')}}</h2>
      <input class="file" multiple type="file">

      <button-partial class="base colorScheme1" @confirmed="triggerDropzone">{{$translate('cancel')}}</button-partial>

    </div>

  </section>
</template>
<script lang="ts">
import {
  ExtComponent, Component,
} from '@/libs/lila-component';
import { Auth } from '@/libs/lila-auth';
import log from 'loglevel';

@Component
export default class MediaDropzonePartial extends ExtComponent {

  mounted() {

    const fileInput: HTMLInputElement = this.$el.querySelector('.file');

    this.DOM.onElement('drop', this.$el, (event: DragEvent) => {

      this.uploadEvent(event);
      this.$emit('dropped');
      this.$store.commit('AppMedia/setDropzoneActive', false);

    });

    this.DOM.onElement('change', fileInput, (event: any) => {

      for (let a = 0; a < event.target.files.length; a += 1) {

        const file = event.target.files[a];

        this.upload(file);

      }

      this.triggerDropzone();

    });

  }

  triggerDropzone() {

    this.$store.commit('AppMedia/setDropzoneActive', false);

  }

  upload(file: File) {

    log.debug(file);

    const uploadFile = {
      file: file.name,
      state: 'new',
      size: file.size,
      progress: 0,
    };

    if (this.$store.state.AppMedia.upload?.find((single) => uploadFile.file === single.file && single.state !== 'error')) {

      console.log('file already uploading');
      return;

    }

    this.$store.commit('AppMedia/addUpload', uploadFile);

    const uploadWorker = new Worker(new URL('@/worker/upload.worker.ts', import.meta.url), { type: 'module' });

    uploadWorker.postMessage({
      file,
      authHeader: Auth.getAuthHeader(),
      api: this.$store.state.settings.endpoints[1].url,
      company: this.$store.state.Company.company,
      project: this.$store.state.Project.project,
    });

    uploadWorker.onmessage = (event) => {

      console.log('worker update', event.data);

      if (event.data.progress >= 100) {

        this.$emit('refresh');

      }

      this.$store.commit('AppMedia/updateUpload', { file, data: event.data });

    };

  }

  uploadEvent(event: DragEvent) {

    const { files } = event.dataTransfer;

    event.preventDefault();
    event.stopPropagation();

    for (let a = 0; a < files.length; a += 1) {

      this.upload(files[a]);

    }

  }

}

</script>
<style lang="less">

.dropzone-partial {
  position: absolute;
  top: 0;
  left: 0;

  // pointer-events: none;

  display: grid;

  width: 100%;
  height: 100%;

  background-color: @white;

  .content-container {
    display: grid;

    gap: 20px;
    align-content: center;
    justify-content: center;
  }
}

</style>

