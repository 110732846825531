import InstallableApp from '../../interfaces/InstallableApp.interface';
import routes from './routes';
import translations from './translations/translations';
import './models/command.model';
import './models/settings.model';


const app: InstallableApp = {
  name: 'hostingadmin',
  publisher: 'lilaquadrat',
  translations,
  routes,
  store: 'AppHostingAdmin',
};

export default app;
