import Models, { ModelDeclaration } from '@/libs/Models.class';

export default interface SelectedFile {
  id: string
  filename: string
  src: string
  thumb: string
}

const declaration: ModelDeclaration<SelectedFile> = {
  id: { type: 'string' },
  filename: { type: 'string' },
  src: { type: 'string' },
  thumb: { type: 'string' },
};

Models.register('selected-file', declaration);