import Models, { ModelDeclaration } from '@/libs/Models.class';

export default interface ModulePdf {
  newSiteAfter: boolean
  newSiteBefore: boolean
}

const declaration: ModelDeclaration<ModulePdf> = {
  newSiteAfter: { type: 'boolean', default: false },
  newSiteBefore: { type: 'boolean', default: false },
};

Models.register('module-pdf', declaration);