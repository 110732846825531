<template>
  <article class="app-lists-participant-note-partial" :class="{ inline, icon }">
    <button v-if="!icon" ref="trigger" class="trigger-button" type="button" @click="toggleEdit">
      <span class="text">
        <template v-if="!hasNote">{{$translate('app-lists-participant-no-note')}}</template>
        <template v-if="hasNote">{{note}}</template>
      </span>
    </button>
    <button-partial v-if="icon" ref="trigger" size="small" button-content-type="icon" icon="edit" color-scheme="transparent" @click="toggleEdit">{{$translate('edit')}}</button-partial>

    <overlay-background-partial v-if="edit" ref="options" class="app-lists-participants-edit" @mounted="calculateOptionsStyle" @close="toggleEdit">

      <section :style="calculatedOptions" class="form-container">
        <form @submit="save">
          <textarea-partial v-model="model.note" max-length="2500" />
          <action-notice-partial :state="state" :translation-pre="translationPre" :errors="errors" @update="updateErrors">
            <button-partial variant="iconColor" button-content-type="icon" icon="close" color-scheme="icon-grey" @click="toggleEdit">{{$translate('close')}}</button-partial>
            <button-partial v-model="state" type="submit" variant="iconColor" button-content-type="icon" icon="checked" color-scheme="icon-green">{{$translate('close')}}</button-partial>
          </action-notice-partial>
        </form>
      </section>

    </overlay-background-partial>

  </article>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { ListParticipants } from '@lilaquadrat/studio/lib/interfaces';
import { EditComponent, Prop, vue } from '@/libs/EditComponent';
import StudioSDK from '@/libs/StudioSDK';
import hardCopy from '@/mixins/hardCopy';
import ModelsClass from '@/libs/Models.class';
import AppListsParticipantNote from '../models/participant-note.model';

@Component
export default class AppListsParticipantNotePartial extends EditComponent<AppListsParticipantNote> {

  @Prop(String) list: string;

  @Prop(String) participant: string;

  @Prop(String) note: ListParticipants['note'];

  @Prop(Boolean) inline: boolean;

  @Prop(Boolean) icon: boolean;

  useModel = 'app-lists-participant-note';

  edit = false;

  model = null;

  calculatedOptions = {};

  translationPre = 'app-lists';

  get hasNote() {

    return this.note?.length > 0;

  }

  toggleEdit() {

    this.edit = !this.edit;

    if (this.edit) {

      this.model = ModelsClass.add({ note: this.note }, 'app-lists-participant-note');

    }

  }

  calculateOptionsStyle() {

    let element: HTMLElement;

    // eslint-disable-next-line no-unused-expressions
    this.$store.state.height;
    // eslint-disable-next-line no-unused-expressions
    this.$store.state.width;

    if (!this.icon) element = this.$refs.trigger as HTMLElement;

    if (this.icon) {

      const vueElement = this.$refs.trigger as vue;

      element = vueElement.$el as HTMLElement;

    }

    const optionsContainer = document.querySelector('.overlay-background .form-container') as HTMLElement;
    const bounds = element.getBoundingClientRect();
    let top = bounds.top + element.offsetHeight;
    const body = document.querySelector('body');
    const positionTop = bounds.bottom + optionsContainer.offsetHeight + 50 > body.offsetHeight;

    if (positionTop) {

      top = bounds.top - 5 - optionsContainer.offsetHeight;

    }

    if (this.$store.state.media === 'mobile') {

      this.calculatedOptions = {};
      return;

    }

    this.calculatedOptions = {
      top: `${top}px`,
      left: `${bounds.left}px`,
      'min-width': '210px',
      'max-width': `${element.offsetWidth}px`,
    };

  }


  async save($event: MouseEvent) {

    $event.preventDefault();

    this.state = '';

    const model = {
      ...hardCopy(ModelsClass.save(this.model, 'app-lists-participant-note')),
      ...this.$store.getters.CompanyProject,
    };


    this.errors = null;

    try {

      await this.$store.dispatch(
        'AppListParticipants/updateNote',
        {
          id: this.$route.params.participantId,
          list: this.$route.params.id,
          note: model.note,
        },
      );


      StudioSDK.flushId(this.$route.params.participantId);

      this.$emit('update');
      this.state = 'success';

      setTimeout(() => {

        this.edit = false;

      }, 1500);


    } catch (error) {

      this.errors = error.response.data;
      this.state = 'error';

    }


  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost() { return Promise.resolve(); }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre() { return Promise.resolve(); }


}
</script>
<style lang="less" scoped>

.app-lists-participant-note-partial {

  &.inline {
    display: inline;
  }

  .text {
    .lineHeight(@fontText);
    min-width: 50px;
    text-align: left;
    white-space: pre;
  }

  .trigger-button {

    display: grid;
    grid-template-columns: max-content min-content;
    gap: 5px;

    padding: inherit;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: @buttonHeight;
    text-transform: inherit;

    .icon-partial {
      display: grid;
    }

    &:hover {
      color: inherit;
    }
  }
}

.form-container {
  position: absolute;
  display: grid;
  align-self: center;
  justify-self: center;
  width: 100%;
  max-width: 100%;
  min-width: 300px;
  .basePadding;

  @media @tablet, @desktop {
    padding: 0;
  }

  form {
    gap: 10px;
    background-color: @white;
    transition: opacity .3s ease, transform .3s ease;

    .multi(padding, 1, 2);

    @media @tablet, @desktop {
      max-height: 30vh;
      box-shadow: 0 0 5px @grey;
      .multi(padding, 1);
    }

  }
}
</style>
