<template>
  <article :class="navigationAnimation">
    <side-screen-header-partial>{{$translate('PROJECT_SETTINGS')}}</side-screen-header-partial>

    <section v-if="model" class="content-container">
      <form class="content" @submit="save">
        
        <fieldset>
          <legend>
            <span class="text">{{$translate('PROJECT_SETTINGS')}}</span>
          </legend>

          <input-partial v-model="model.name" placeholder="name" :required="true" :validation="/^[a-z0-9 \.\_\-\&\!\:]{1,50}$/i">{{$translate('name')}}</input-partial>
          <input-partial v-model="model.id" disabled placeholder="id" :required="true" :validation="/^[a-z0-9]{1,15}$/i">{{$translate('id')}}</input-partial>

          <textarea-partial v-model="model.description" max-length="200">{{$translate('short description of this project')}}</textarea-partial>

          <!-- <tags-partial type="editor" :allowNew="true" v-model="model.tags">tags</tags-partial> -->
        </fieldset>
        <fieldset>
          <legend>
            <span class="text">{{$translate('project-settings-auth0')}}</span>
          </legend>
          <description-partial>{{$translate('project-settings-auth0-description')}}</description-partial>

          <input-partial v-model="model.auth0.domain" placeholder="project-settings-auth0-domain">{{$translate('project-settings-auth0-domain')}}</input-partial>
        </fieldset>

        <button-group-partial>
          <button-partial v-model="state" type="submit" save>{{$translate('SAVE_CHANGES')}}</button-partial>
        </button-group-partial>
      </form>
    </section>
  </article>
</template>

<script lang="ts">
import {
  ExtComponent, Component,
} from '@/libs/lila-component';
import ModelsClass from '@/libs/Models.class';
import { SDKResponse } from '@/libs/StudioSDK';
import { Project } from '@lilaquadrat/studio/lib/interfaces';

@Component
export default class ProjectSettingsScreen extends ExtComponent {

  model: Project = null;

  state: string = '';

  componentName = 'project-settings';

  mounted() {

    this.$store
      .dispatch('Project/getSettings', { company: this.$route.params.company, project: this.$route.params.project })
      .then((data: SDKResponse<Project>) => {

        // this.model.setData(data.data);
        this.model = ModelsClass.add<Project>(data.data, 'project');

      })
      .catch((e) => {

        console.log(e);

      });

  }

  save($event: MouseEvent) {

    $event.preventDefault();

    this.state = '';

    const settings = ModelsClass.save<Project>(this.model, 'project');

    delete settings.id;

    this.$store
      .dispatch('Project/saveSettings', 
        { 
          settings,
          project: this.$store.state.Project.project, 
          company: this.$store.state.Company.company 
        })
      .then(() => {

        this.state = 'success';

      })
      .catch(() => {

        this.state = 'error';

      });

  }

}


</script>
