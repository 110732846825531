<template>
  <section class="compareElement-editor">
    <section v-if="!child" class="editor-content-container">
      <input-partial v-model="value.headline" placeholder="headline" @keyup="$emit('keyup', $event)">{{$translate('headline')}}</input-partial>

      <textarea-partial v-model="value.description" @keyup="$emit('keyup', $event)">{{$translate('description')}}</textarea-partial>
    </section>

    <moduleList-partial v-if="!child" class="module-editor-navigation">
      <li v-for="(element, groupIndex) in parent" :key="`element-${groupIndex}`">
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild(`group${groupIndex}`)">
          {{ element.headline }}
        </button-partial>
      </li>
    </moduleList-partial>

    <section v-for="(element, groupIndex) in parent" v-if="child === `group${groupIndex}`" :key="`element-${groupIndex}`" class="editor-content-container">
      <h2>{{ element.headline }}</h2>
      <h3>{{ element.description }}</h3>

      <ul v-for="(single, index) in element.list" :key="`single-${index}`">
        <li>
          <input-partial :value="getElement(groupIndex, index)" @input="update($event, groupIndex, index)">{{$translate('single ')}}</input-partial>
        </li>
      </ul>
    </section>
  </section>
</template>

<script lang="ts">
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import { Component, Prop } from '@/libs/lila-component';
import CompareElement from '@/models/CompareElement.model';

@Component
export default class CompareElementEditor extends PartialEditorComponent<CompareElement> {


  @Prop(Array) parent: any;

  model = 'compare-element';

  update(input: string, group: number, element: number) {

    if (!this.value.elements[group]) this.value.elements[group] = [];
    this.value.elements[group][element] = input;

    this.$emit('input', this.value);

  }

  getElement(group: number, element: number) {

    if (!this.value.elements[group]) this.value.elements[group] = [];

    return this.value.elements[group][element];

  }

}

export { CompareElementEditor };
</script>
