<template>
  <article class="structuresOptionsElement-editor">
    <crumbs-partial inline @clearCrumbs="unsetCurrentChild" />
    <fieldset>
      <input-partial v-model="value.text" required :placeholder="$translate('structures-answer')" :label="$translate('structures-answer')" @input="updateValue">{{ $translate('structures-answer') }}</input-partial>
      <textarea-partial v-model="value.description" :placeholder="$translate('structures-description')" :label="$translate('structures-description')">{{ $translate('structures-description') }}</textarea-partial>
    </fieldset>

  </article>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import StructureOptions from '@/interfaces/StructureOptions.interface';
import createIdFromContent from '@/mixins/createIdFromContent';

@Component
// eslint-disable-next-line global-require
export default class StructuresOptionsElementEditor extends PartialEditorComponent<StructureOptions> {

  async updateValue() {

    this.value.value = await createIdFromContent(this.value.text, {replaceSpecial: true});
  
  }

}

</script>
