<template>
  <article class="user-list extension">

    <slot />

    <ul class="selected">
      <li v-for="(single, index) in value" :key="`single-user-${index}`">
        <div>{{single.lastname}}, {{single.prename}}</div>
        <!-- <button-partial type="button" class="mode up" buttonContentType="icon" icon="close" size="small" colorScheme="transparent" @confirmed="remove(index)" /> -->
      </li>
    </ul>

  </article>
</template>
<script lang="ts">
import {
  ExtComponent, Component, Prop, Watch,
} from '@/libs/lila-component';

@Component
export default class SelectMediaExtension extends ExtComponent {

    @Prop(Array) value: Record<string, string>[];

}
</script>
<style lang="less">


.user-list.extension {

  display: grid;
  gap: 20px;

  ul.selected li {

    display: grid;
    grid-template-columns: 1fr 40px;
    line-height: @buttonHeight;

    font-size: @fontText;

  }

}
</style>
