import Models, { ModelDeclaration } from '@/libs/Models.class';
import Link from '@/models/Link.model';
import Textblock from '../interfaces/textblock.interface';
import Media from './Media.model';

export default interface StoryElement {
  id: string
  textblock: Textblock
  media: Media
  link: Link
  animation?: string 
  variant?: string[]
}

const declaration: ModelDeclaration<StoryElement> = {
  id: { type: 'string' },
  textblock: { model: 'textblock' },
  media: { model: 'media' },
  link: { model: 'link' },
  animation: { type: 'string' },
  variant: { type: 'array', contains: { type: 'string' } },
};

Models.register('story-element', declaration);