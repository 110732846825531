import Models, { ModelDeclaration } from '@/libs/Models.class';
import {Auth0ProjectSettings, ShopifyProjectSettings} from '@lilaquadrat/inferfaces';

export default interface Project {
  name: string
  id: string
  company: string
  description: string
  sample: boolean
  sampleModels: string[]
  tags: string[]
  auth0: Auth0ProjectSettings
  shopify: ShopifyProjectSettings
}

const declaration: ModelDeclaration<Project> = {
  name: { type: 'string' },
  id: { type: 'string' },
  company: { type: 'string' },
  description: { type: 'string' },
  sample: { type: 'boolean', default: false },
  sampleModels: { type: 'array', contains: { type: 'string' } },
  tags: { type: 'array', contains: { type: 'string' } },
  auth0: { model: 'auth0ProjectSettings' },
  shopify: { model: 'shopifyProjectSettings' }
};

Models.register('project', declaration);
