<template>
  <article :class="navigationAnimation" class="app-history-home-screen">
    <div class="pagenator full">
      <header class="main">
        <section class="stats">
          <h1>{{$translate('history')}}</h1>
          <h3>
            <template v-if="!content.sites.last">{{ content.count }}</template>
            <template v-if="content.sites.last">{{ content.sites.range[0] }} - {{ content.sites.range[1] }} ({{ content.count }})</template>
            / {{ content.all }} {{$translate('total')}}
          </h3>
        </section>

        <quickmenu-partial />
      </header>

      <!-- <filter-partial type="data" /> -->
      <section class="sites-filter-container">
        <section class="filter-text" />
        <sites-partial paramKey="site" :data="content" />
      </section>

      <section class="scroll-container">
        <table class="isSticky">
          <thead-partial :siteCurrent="currentSite" :search="$route.query.search" :sort="sort" :order="order" :content="headContent" />
        </table>

        <table :class="contentAnimation">
          <tbody>
            <tr v-for="(single, index) in content.data" :key="`data-overview-${index}`">
              <td class="id action" :class="{ hasDescription: isCompany }">
                <router-link
                  :to="{
                    name: `app-${single.history.model}-${isCompany ? 'company' : 'project'}-history`,
                    params: {
                      id: single.id,
                      company: $store.state.Company.company,
                      project: $store.state.Project.project,
                    },
                  }"
                >
                  <span v-if="single.id">{{ single.id }}</span>
                  <span v-if="single.label">{{ single.label }}</span>
                  <span v-if="!single.id && !single.label" class="not-applicable">{{$translate('app-history-not-applicable')}}</span>
                  <span class="description" v-if="isCompany">{{ single.project }}</span>
                </router-link>
              </td>

              <td class="reason">
                {{ $translate(`app-history-${single.history.reason}`) }}
              </td>

              <td class="model">
                <span>{{ $translate(`${single.history.model}-model`) }}</span> <span>via {{ single.history.app }}</span>
              </td>

              <td class="last">
                {{ single.history.user }}
                <abbr :title="$date(single.history.date, 'DD.MM.YYYY HH:mm')">{{$date(single.history.date, null, true) }}</abbr>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  </article>
</template>
<script lang="ts">
import { ExtComponent, Component, Watch } from '@/libs/lila-component';
import { CallResponse, ListOfModels } from '@lilaquadrat/studio/lib/interfaces';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppHistoryHomeScreen extends ExtComponent {

  componentName = ['app-history-company-home', 'app-history-project-home'];

  headContent: any[] = [];

  order: string = '-1';

  sort: string = 'date';

  @Watch('$route')
  watchRoute() {

    this.updateSort();

  }

  constructor() {

    super();

    this.headContent.push({
      text: 'id',
      classes: 'id',
    });

    this.headContent.push({
      text: 'reason',
      classes: 'reason',
    });


    this.headContent.push({
      text: 'model',
      classes: 'model',
    });

    this.headContent.push({
      key: 'date',
      text: 'date',
    });

  }

  mounted() {

    this.updateSort();

  }

  get currentSite() {

    return this.$route.params.site ? +this.$route.params.site : null;

  }

  get content() {

    return this.$store.state.AppHistory.data || null;

  }

  get isCompany() {

    return this.$route.name === 'app-history-company-home';

  }

  updateSort() {

    if (this.$route.query.sort) this.sort = this.$route.query.sort as string;
    if (this.$route.query.order) this.order = this.$route.query.order as string;

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store.dispatch('AppHistory/list', {
      query,
      project: params.project,
      company: params.company,
      site: params.site,
    });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: CallResponse<ListOfModels<History>>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppHistory/data', preloadedData.r);
    return Promise.resolve();

  }


}
</script>
<style lang="less">


.app-history-home-screen {

  .sites-filter-container {
    display: grid;
    .multi(padding-top, 2);
    .multi(padding-bottom, 4);
    .basePadding;

    .sites-container {
      justify-self: end;
    }
  }

  tr {
    grid-template-columns: minmax(100px, 1fr) 80px minmax(150px, 250px) [end] 150px;

    td {

      &.reason {
        align-content: center;
        text-align: center;
      }

      &.model {
        align-content: right;
        text-align: right;
      }

      .not-applicable {
        color: @grey;
      }
    }
  }
}
</style>
