<template>
  <article class="app-tracker-add-multiple-screen" :class="navigationAnimation">

    <side-screen-header-partial>{{$translate('app-tracker-add-multiple')}}</side-screen-header-partial>

    <form class="content-container" :class="{edit: this.editDay.length}" @submit="save">

      <button-group-partial left class="main-controls">
        <button-partial icon="arrow-left" :title="$translate('app-tracker-prev-month')" color-scheme="icon-grey" button-content-type="icon" @click="changeMonth('prev')" />
        <h3>{{$date(baseDate.toDate(), 'MMMM YYYY')}}</h3>
        <button-partial icon="arrow-right" :title="$translate('app-tracker-next-month')" color-scheme="icon-grey" button-content-type="icon" @click="changeMonth('next')" />
        <button-partial color-scheme="transparent" @click="changeMonth">{{$translate('app-tracker-current-month')}}</button-partial>
      </button-group-partial>

      <section class="month-container">

        <section v-for="(week, weekIndex) in monthObject" class="week" :class="{activeDays: week.hasActiveDays}" :key="`single-week-${weekIndex}`">

          <h3 class="weekNumber">{{ $date(week.start, 'DD.MM.') }} - {{ $date(week.end, 'DD.MM.') }} {{ $translate('app-tracker-calenderWeek') }} {{ week.weekNumber }}</h3>

          <section v-for="(single, index) in week.days" class="singleDate" :class="{inactive: !single.currentMonth, edit: single.modelKey === editDay, today: single.today}" :key="`single-month-${index}`">
            
            <section class="date">
              <div class="day">{{ single.day }}</div>
              <div class="weekday">{{ single.weekdayShort }}</div>
              <!-- <div v-if="single.today" class="today">{{$translate('app-tracker-today')}}</div> -->
            </section>

            <section class="description-container" :class="{empty: !models[single.modelKey].endTime}" @click="setEditDay(single.modelKey)" @keyup="setEditDay(single.modelKey)" role="button" tabindex="0" v-if="models[single.modelKey] && single.currentMonth">
              <app-tracker-time-partial text :start="models[single.modelKey].startTime" :end="models[single.modelKey].endTime" />
              <div class="placeholder" v-if="!models[single.modelKey].endTime">{{$translate('app-tracker-day-empty')}}</div>
              <div class="category" v-if="models[single.modelKey].endTime">{{ models[single.modelKey].category }}</div> 
              <p class="description">{{ models[single.modelKey].description || $translate('app-tracker-day-no-description') }}</p> 
            </section>
            
            <section class="description-container differentMonth" v-if="!single.currentMonth">
              <div class="placeholder">{{$translate('app-tracker-different-month')}}</div>
            </section>
            
            <section class="controls" v-if="single.currentMonth">
              <button-partial icon="arrow-up" color-scheme="icon-grey" button-content-type="icon" @click="copy(single.modelKey, 'up')" />
              <button-partial icon="arrow-down" color-scheme="icon-grey" button-content-type="icon" @click="copy(single.modelKey, 'down')" />
            </section>

            <section class="edit-container" v-if="single.modelKey === editDay">

              <select-partial v-model="models[single.modelKey].category" placeholder="app-tracker-category" :multiple="false" required :error="errorsObject.categories" :options="categories">{{$translate('app-tracker-description')}}</select-partial>
              
              <textarea-partial v-model="models[single.modelKey].description" max-length="200" placeholder="app-tracker-description">{{$translate('app-tracker-description')}}</textarea-partial>
              <date-picker-partial v-model="range" mode="rangeTime">Zeitraum</date-picker-partial>

              <button-partial color-scheme="transparent" @click="remove(single.modelKey)">{{$translate('app-tracker-remove-day')}}</button-partial>

            </section>

          </section>

        </section>
          
      </section>

      <section class="statistics-container">
        <h3>{{ $translate('app-tracker-statistics-all') }}</h3>
        <section class="statistics">
          <h3 v-if="!statistics.hours && !statistics.minutes">00:00</h3>
          <h3 v-if="statistics.hours">{{ $translateWithDiff('app-tracker-hours', statistics.hours) }}</h3>
          <h3 v-if="statistics.minutes">{{ $translateWithDiff('app-tracker-minutes', statistics.minutes) }}</h3>
        </section>
      </section>

      <action-notice-partial :state="state" class="content" translation-pre="app-tracker" :errors="errors" @update="updateErrors">
          <button-partial type="submit" v-model="state" save>{{$translate('app-tracker-add-button')}}</button-partial>
      </action-notice-partial>


    </form>

  </article>
</template>
<script lang="ts">
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import weekYear from 'dayjs/plugin/weekYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { ErrorObject } from 'ajv';
import SelectOption from '@/interfaces/selectOption.interface';
import DateRange from '@/interfaces/DateRange.interface';
import { ErrorsObject } from '@/libs/ActionNotice';
import ModelsClass from '@/libs/Models.class';
import { ExtComponent, Component, Watch, vue } from '@/libs/lila-component';
import hardCopy from '@/mixins/hardCopy';
import Tracker from '../models/Tracker.model';
import TrackerDay from '../interfaces/TrackerDay.interface';

dayjs.extend(duration);
dayjs.extend(weekYear);
dayjs.extend(weekOfYear);

@Component
export default class AppTrackerHomeScreen extends ExtComponent {

  componentName = 'app-tracker-project-add';

  model: Tracker = null;

  state: string = '';

  errors: {message?: string, errors?: ErrorObject[]} = {};

  errorsObject: ErrorsObject = {};

  categories: SelectOption[] = [
    { value: 'call', text: 'app-tracker-calls' },
    { value: 'worktime', text: 'app-tracker-worktime' },
  ];

  editDay: string = '';

  monthObject: Record<string, {days: (TrackerDay & {weekdayShort: string, weekNumber: number, modelKey: string})[], start: Date, end: Date, weekNumber: number, hasActiveDays: boolean}> = {};

  models: Record<string, Tracker> = {};

  statisticsTimeout: any;

  statistics: {all: number, hours: number, minutes: number} = {all: 0, hours: 0, minutes: 0};

  baseDate = dayjs().startOf('month'); 

  @Watch('models', {deep: true})
  watchMonthFunction() {

    clearTimeout(this.statisticsTimeout);
    this.statisticsTimeout = setTimeout(this.calculateStatistics, 1000);

  }

  setEditDay(key: string | undefined) {

    if(this.editDay === key) {

      this.editDay = '';
      return;
    
    }

    this.editDay = key;

  }

  remove(key: string) {

    this.models[key] = ModelsClass.add<Tracker>({}, 'tracker');
    this.editDay = '';

  }

  changeMonth(direction: 'next' | 'prev' | undefined) {

    console.log(direction);

    if(direction) {

      if(direction === 'next') this.baseDate = this.baseDate.add(1, 'month');
      if(direction === 'prev') this.baseDate = this.baseDate.subtract(1, 'month');

    } else {

      this.baseDate = dayjs().startOf('month');

    }

    this.createMonth();
    this.editDay = '';

    clearTimeout(this.statisticsTimeout);
    this.statisticsTimeout = setTimeout(this.calculateStatistics, 1000);

  }

  copy(key: string, direction: 'up' | 'down') {

    let startTime: Date | null = null;
    let endTime: Date | null = null;
    const baseModel = this.models[key];
    const baseDate = dayjs(`${key.slice(2, 4)}-${key.slice(4, 6)}-20${key.slice(0, 2)}`);
    const targetDate = baseDate[direction === 'up' ? 'subtract' : 'add'](1, 'day');
    const targetModel = this.models[targetDate.format('YYMMDD')];

    if(baseDate.get('month') !== targetDate.get('month')) return;
    if(!targetModel) return;

    if(baseModel.startTime) {

      startTime = targetDate
        .set('minutes', dayjs(baseModel.startTime).get('minutes'))
        .set('hours', dayjs(baseModel.startTime).get('hours'))
        .set('seconds', dayjs(baseModel.startTime).get('seconds'))
        .set('milliseconds', dayjs(baseModel.startTime).get('milliseconds'))
        .toDate();
      
    }

    if(baseModel.endTime) {

      endTime = targetDate
        .set('minutes', dayjs(baseModel.endTime).get('minutes'))
        .set('hours', dayjs(baseModel.endTime).get('hours'))
        .set('seconds', dayjs(baseModel.endTime).get('seconds'))
        .set('milliseconds', dayjs(baseModel.endTime).get('milliseconds'))
        .toDate();
    
    }


    this.models[targetDate.format('YYMMDD')] = ModelsClass.add<Tracker>({...baseModel, startTime, endTime}, 'tracker');

  }

  calculateStatistics() {

    const statistics = {
      all: 0,
      hours: 0,
      minutes: 0,
    };

    console.log('calc');


    Object.values(this.models).forEach((model) => {

      if(model.startTime && model.endTime) {

          console.log(model.startTime, dayjs(model.startTime).format('YYMM'), this.baseDate.format('YYMM'));

          if(dayjs(model.startTime).format('YYMM') !== this.baseDate.format('YYMM')) return;

          statistics.all += model.endTime.getTime() - model.startTime.getTime();

        }

    
    });

    statistics.hours = dayjs.duration(statistics.all, 'milliseconds').get('days') * 24 + dayjs.duration(statistics.all, 'milliseconds').get('hours');
    statistics.minutes = dayjs.duration(statistics.all, 'milliseconds').get('minutes');

    this.statistics = statistics;

  }

  get range() {

    const startDate = dayjs(`${+this.editDay.slice(2, 4)}-${+this.editDay.slice(4, 6)}-20${this.editDay.slice(0, 2)}`).set('hour', 8).toDate();

    // startTime and endTime is null if nothing is set yet, therefore we need to provide the startDate which reflects the selected day
    return {from: this.models[this.editDay].startTime || startDate, to: this.models[this.editDay].endTime || startDate};

  }

  set range(range: DateRange) {

    const model = this.models[this.editDay];

    model.startTime = range.from;
    model.endTime = range.to;

  }

  mounted() {

    this.createMonth();

  }

  createMonth() {

    const monthObject = {};
    const baseDate = this.baseDate;
    const currentMonth = baseDate.get('month');

    for (let a = 0; a < 42; a += 1) {

      const date = baseDate.startOf('month').startOf('week').add(a, 'day');
      const isCurrentMonth = date.get('month') === currentMonth;
      const weekNumber = date.week();
      const weekId = date.week();

      if(!monthObject[weekId]) {
        
        monthObject[weekId] = {
          days: [],
          start: date.startOf('week'),
          end: date.endOf('week'),
          weekNumber,
          currentMonth: isCurrentMonth,
          hasActiveDays: false
        };
        
      }

      const useWeek = monthObject[weekId];
      const dayObject: TrackerDay & {weekdayShort: string, weekNumber: number, modelKey: string} = {
        dateObject: date,
        date: date.format('DD.MM.YYYY'),
        day: date.format('DD'),
        month: date.format('MM'),
        year: date.format('YYYY'),
        weekday: date.format('dddd'),
        weekdayShort: date.format('dd'),
        weekNumber,
        currentMonth: isCurrentMonth,
        modelKey: date.format('YYMMDD'),
        today: date.format('DDMMYYYY') === dayjs().format('DDMMYYYY'),
        selected: date.format('YYYY-MM-DD') === this.$route.params.day,
      };

      if(isCurrentMonth) {

        if(!this.models[date.format('YYMMDD')]) vue.set(this.models, date.format('YYMMDD'),  ModelsClass.add<Tracker>({}, 'tracker'));
        useWeek.hasActiveDays = true;

      }

      useWeek.days.push(dayObject);

    }

    this.monthObject = monthObject;

  }


  async save($event: MouseEvent) {

    $event.preventDefault();


    let saveData = hardCopy(Object.values(this.models));

    saveData = saveData.filter((single) => {

      if(!single.endTime) return false;

      return dayjs(single.startTime).isSame(this.baseDate, 'month');

    });

    saveData = saveData.map((single) => ModelsClass.save(single, 'tracker'));

    console.log(saveData);

    try {

      await this.$store.dispatch('AppTracker/saveMultiple', saveData);

      this.state = 'success';


    } catch (error) {

      this.errors = error.response.data;
      this.state = 'error';

    }


  }

  updateErrors(errorsObject: ErrorsObject) {

    this.errorsObject = errorsObject;

  }


}
</script>
<style lang="less" scoped>

.app-tracker-add-multiple-screen {

  .content-container {

    display: grid;
    gap: 20px;

    .main-controls {
      .multi(padding-top, 4);
      .basePadding;

      h3 {
        color: @color1;
        line-height: @buttonHeight;
      }
    }

    .statistics-container {
      .basePadding;
      display: grid;
      grid-template-columns: 1fr max-content;

      .statistics {
        display: grid;
        grid-template-columns: max-content max-content;
        gap: 5px;

        h3 {
          color: @color1;
        }
      }
    }

    .month-container {

      display: grid;

      .week {

        .weekNumber {
          .basePadding;
          .font-mono;
          color: @grey;
          font-weight: normal;
          text-align: center;
          .multi(padding, 4, 2);
        }

        &.activeDays {

          .weekNumber {
            color: @textColor;
          }

        }

        .singleDate {

          display: grid;
          grid-template-rows: max-content max-content;
          grid-template-columns: 60px 1fr max-content;
          align-items: center;

          border-top: solid 1px @grey;
          color: @textColor;
          .basePadding;
          .multi(padding-top, 2);
          .multi(padding-bottom, 2);

          &:last-child {
            border-bottom: solid 1px @grey;
          }

          &:hover {
            background-color: @grey2;
          }

          &.edit {
            grid-template-rows: max-content max-content;
            color: @textColor;
          }

          .date {
            display: grid;
            grid-template-rows: max-content max-content;
            color: @color1;
            font-size: @fontText;
            .font-mono;

            .day {
              font-weight: bold;
            }

            .weekday {
              text-transform: uppercase;
            }

            .today {
              .font-head;
            }
          }

          &:nth-child(7), &:nth-child(8) {

            background-color: @grey1;

            &:hover {
              background-color: @grey2;
            }

            .date {
              color: @textColor;
            }
          }

          &.inactive {
            color: @grey;

            pointer-events: none;

            .date {
              color: @grey;
            }

            &:nth-child(7), &:nth-child(8) {

              .date {
                color: @grey;
              }
            }

          }

          .description-container {
            display: grid;
            grid-template-rows: 20px 20px;

            grid-template-columns: max-content 1fr;
            gap: 0 10px;
            overflow: hidden;
            color: @textColor;
            cursor: pointer;

            .description, .placeholder {
              grid-column-start: 1;
              grid-column-end: 3;
            }

            .placeholder {
              color: @grey;
            }

            &.empty {
              grid-template-rows: 40px;

              .placeholder {
                display: grid;
                align-content: center;
              }

              .app-tracker-time-partial, .description {
                display: none;
              }
            }

            &:hover {

              .placeholder {
                color: @textColor;
              }
            }
          }

          .controls {
            display: grid;
            grid-template-columns: 35px 35px;
          }

          .edit-container {
            display: grid;
            grid-column-start: 1;
            grid-column-end: 4;
          }
        }

      }

    }

    &.edit {

      .singleDate {
        color: @grey;

        .controls {
          display: none;
        }

        &.edit {
          color: @textColor;
        }
      }

    }
  }

}
</style>

