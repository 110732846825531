<template>
  <section class="text-module-editor open editor" :class="{ changed: safeContent.changed }">
    <module-editor-header-partial v-model="safeContent" :position="position" :index="index" :type="type" @move="move($event)" />
    <crumbs-partial :type="safeContent.type" @clearCrumbs="unsetCurrentChild" />

    <section :class="{ optionsVisible: !child }" class="editor-module-container">
      <moduleList-partial v-if="!child" class="module-editor-navigation">
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('textblock')">{{$translate('textblock')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('elements')">{{$translate('elements')}}</button-partial>
        </li>
      </moduleList-partial>
      <textblock-partial-editor v-if="child === 'textblock'" v-model="safeContent.textblock" />
      <list-elements-partial-editor v-if="child === 'elements'" v-model="safeContent.elements" type="eventsListElement-partial" listTitle="artist" :model="listModel" partial/>

      <module-options-partial
        v-if="child === 'options' || !child"
        v-model="safeContent"
        :available-variants="availableVariants"
        :selected-variants="safeContent.variant"
        @setChild="setChild('options', true)"
        @unsetChild="setChild('options')"
        @remove="remove()"
        @clone="clone()"
      />

    </section>
  </section>
</template>

<script lang="ts">
import { Component, ModuleEditorComponent } from '@/libs/ModuleEditorComponent';
import EventsListModule from '../models/EventsListModule.model';

@Component
export default class EventsListModuleEditor extends ModuleEditorComponent<EventsListModule> {

  listModel = 'events-list-element';

  model = 'events-list-module';

}

</script>

