<template>
  <article class="app-customers-edit-screen" :class="navigationAnimation">


    <form class="content" @submit="save">
      <copy-partial :id="componentName" :value="model" @paste="handlePaste" />
      <fieldset>
        <legend>
          <span class="text">{{$translate('app-publish-method-type')}}</span>
        </legend>
        <button-group-partial left>
          <label class="radio">
            <input v-model="type" :disabled="edit" value="person" name="type" type="radio" />
            {{$translate('app-customers-type-person')}}
          </label>
          <label class="radio">
            <input v-model="type" :disabled="edit" value="company" name="type" type="radio" />
            {{$translate('app-customers-type-company')}}
          </label>
        </button-group-partial>

      </fieldset>


      <fieldset v-if="type === 'person'">

        <legend>
          <span class="text">{{$translate('app-customers-personal')}}</span>
        </legend>

        <data-grid-partial>
          <section class="data-grid-container">

            <section class="data-grid-single">
              <input-partial v-model="model.name" debounce="1000" placeholder="app-customers-person-lastname" :error="errorsObject.name" required @input="getSimilar">{{$translate('app-customers-person-lastname')}}</input-partial>
            </section>

            <section class="data-grid-single">
              <input-partial v-model="model.prename" placeholder="app-customers-person-prename" :error="errorsObject.prename" required>{{$translate('app-customers-person-prename')}}</input-partial>
            </section>

          </section>

          <section class="similarContainer">

            <notice-partial type="notice">{{$translate('app-customers-similar-description')}}</notice-partial>

            <template v-if="similar">
              <notice-partial v-if="similar.all === 0" type="notice">{{$translate('app-customers-similar-no-entries')}}</notice-partial>
              <notice-partial v-if="similar.all > 0" type="notice">{{$translate('app-customers-similar-entries')}} ({{similar.all}})</notice-partial>

              <div v-if="similar.all > 0" class="table-container">
                <table class="inline">
                  <tbody>
                    <tr v-for="(single, index) in similar.data" :key="`data-overview-${index}`">
                      <td class="id fullHeight action hasDescription" :class="{ hasAttribute: single.app || single.model }">
                        <router-link
                          :to="{
                            name: 'app-customers-company-single',
                            params: {
                              id: single._id.toString(),
                              company: $store.state.Company.company,
                              project: $store.state.Project.project,
                            },
                          }"
                        >
                          <div>
                            <span class="description">
                              {{ single.name }}
                              <template v-if="single.prename">, {{ single.prename }}</template>
                            </span>
                            <span class="id">
                              {{single.id}}
                            </span>
                          </div>
                        </router-link>
                      </td>

                      <td class="">
                        <div>{{single.street}} {{single.streetNumber}}</div>
                        <div>{{single.zipcode}} {{single.city}}</div>
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </section>


        </data-grid-partial>

        <select-partial v-model="model.gender" :options="availableGenders" placeholder="app-customers-person-gender" :error="errorsObject.gender" :multiple="false">{{$translate('app-customers-person-gender')}}</select-partial>

      </fieldset>

      <fieldset v-if="type === 'company'">

        <legend>
          <span class="text">{{$translate('app-customers-companyData')}}</span>
        </legend>

        <input-partial v-model="model.name" placeholder="app-customers-company-name" :error="errorsObject.name" required>{{$translate('app-customers-company-name')}}</input-partial>

        <input-partial v-model="model.legalRepresentative" placeholder="company-representive" :error="errorsObject.legalRepresentative">{{$translate('company-representive')}}</input-partial>

        <input-partial v-model="model.vat" placeholder="company-vat" :error="errorsObject.vat">{{$translate('company-vat')}}</input-partial>

        <input-partial v-model="model.register" placeholder="company-register" :error="errorsObject.register">{{$translate('company-register')}}</input-partial>

      </fieldset>

      <fieldset v-if="type">
        <legend>
          <span class="text">{{$translate('company-address')}}</span>
        </legend>

        <data-grid-partial>
          <section class="data-grid-container focusFirst">

            <section class="data-grid-single">
              <input-partial v-model="model.street" placeholder="app-customers-street" :error="errorsObject.street">{{$translate('app-customers-street')}}</input-partial>
            </section>

            <section class="data-grid-single">
              <input-partial v-model="model.streetNumber" placeholder="app-customers-streetNumber" :error="errorsObject.streetNumber">{{$translate('app-customers-streetNumber')}}</input-partial>
            </section>

          </section>
        </data-grid-partial>

        <data-grid-partial>
          <section class="data-grid-container focusLast">

            <section class="data-grid-single">
              <input-partial v-model="model.zipcode" placeholder="company-zipcode" :error="errorsObject.zipcode">{{$translate('company-zipcode')}}</input-partial>
            </section>

            <section class="data-grid-single">
              <input-partial v-model="model.city" placeholder="company-city" :error="errorsObject.city">{{$translate('company-city')}}</input-partial>
            </section>

          </section>
        </data-grid-partial>

        <data-grid-partial>
          <section class="data-grid-container">

            <section class="data-grid-single full">
              <input-partial v-model="model.addressAddition" placeholder="app-customers-addressAddition" :error="errorsObject.addressAddition">{{$translate('app-customers-addressAddition')}}</input-partial>
            </section>

          </section>
        </data-grid-partial>


        <select-partial v-model="model.country" :options="availableCountries" placeholder="company-country-select" :error="errorsObject.country" :multiple="false">{{$translate('company-country')}}</select-partial>

      </fieldset>


      <fieldset v-if="type === 'company'">
        <legend>
          <span class="text">{{$translate('company-contact')}}</span>
        </legend>

        <input-partial v-model="model.internet" placeholder="company-internet" :error="errorsObject.internet">{{$translate('company-internet')}}</input-partial>

        <input-partial v-model="model.phone" placeholder="company-phone" :error="errorsObject.phone">{{$translate('company-phone')}}</input-partial>

        <input-partial v-model="model.email" placeholder="email" :error="errorsObject.email">{{$translate('email')}}</input-partial>
      </fieldset>

      <fieldset v-if="type === 'person'">
        <legend>
          <span class="text">{{$translate('app-customers-contact')}}</span>
        </legend>

        <input-partial v-model="model.email" placeholder="email" :error="errorsObject.email">{{$translate('email')}}</input-partial>

        <input-partial v-model="model.phone" placeholder="company-phone" :error="errorsObject.phone">{{$translate('company-phone')}}</input-partial>

      </fieldset>

      <fieldset v-if="type">
        <legend>
          <span class="text">{{$translate('app-customers-internal')}}</span>
        </legend>

        <tags-partial v-model="model.tags" type="customers" category="category" :allow-new="true">{{$translate('category')}}</tags-partial>
        <textarea-partial v-model="model.note" max-length="500" :error="errorsObject.note" />

      </fieldset>

      <action-notice-partial :state="state" :translation-pre="translationPre" :errors="errors" @update="updateErrors">
        <button-partial v-if="edit" type="button" class="base colorScheme2" doublecheck @confirmed="remove">{{$translate('app-customers-remove-button')}}</button-partial>
        <button-partial v-model="state" save type="submit" :disabled="!type">
          <template v-if="!edit">{{$translate('app-customers-add')}}</template>
          <template v-if="edit">{{$translate('app-customers-edit-button')}}</template>
        </button-partial>
      </action-notice-partial>

    </form>

  </article>
</template>

<script lang="ts">
import { ErrorsObject } from '@/libs/ActionNotice';
import { ExtComponent, Component, Watch } from '@/libs/lila-component';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import { CustomerCompany, CustomerPerson, Customers, DataObject } from '@lilaquadrat/studio/lib/interfaces';
import MainStoreState from '@/store/mainStoreState.interface';
import { ErrorObject } from 'ajv';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';
import SelectOption from '@/interfaces/selectOption.interface';
import hardCopy from '@/mixins/hardCopy';
import ModelsClass from '@/libs/Models.class';


@Component
export default class AppEditorSettingsScreen extends ExtComponent {

  componentName = 'app-customers-company-edit';

  modelsList = {
    person: 'app-customers-person',
    company: 'app-customers-company',
  };

  models: {person: CustomerPerson, company: CustomerCompany} = {
    person: null,
    company: null,
  };

  model = null;

  edit: boolean = false;

  errors: {message?: string, errors?: ErrorObject[]} = {};

  errorsObject: ErrorsObject = {};

  realType = '';

  similar: DataObject<Customers[]> = null;

  availableCountries: SelectOption[] = [
    { value: 'deu', text: 'country-deu' },
    { value: 'aut', text: 'country-aut' },
    { value: 'che', text: 'country-che' },
  ];

  availableGenders: SelectOption[] = [
    { value: 'm', text: 'gender-m' },
    { value: 'f', text: 'gender-f' },
    { value: 'd', text: 'gender-d' },
  ];


  get content(): Customers {

    return this.$store.state.AppCustomers.single;

  }

  get type() {

    return this.realType;

  }

  set type(type: string) {

    this.realType = type;
    this.model = this.models[type];

  }

  get translationPre() {

    return `app-publish-method-${this.realType}`;

  }

  @Watch('$route')
  watchRouteFunction() {

    this.setModel();

  }

  updateErrors(errorsObject: ErrorsObject) {

    this.errorsObject = errorsObject;

  }

  mounted() {

    this.setModel();

  }

  setModel() {

    this.edit = this.$route.name === 'app-customers-company-edit';

    if (this.edit) {

      const type = this.$store.state.AppCustomers.single.type;

      if(this.content.type === 'person') this.models.person = ModelsClass.add<CustomerPerson>(this.content, 'app-customers-person');
      if(this.content.type === 'company') this.models.company = ModelsClass.add<CustomerCompany>(this.content, 'app-customers-company');
      this.type = type;

    } else {

      this.models.person = ModelsClass.add<CustomerPerson>({type: 'person'}, 'app-customers-person');
      this.models.company = ModelsClass.add<CustomerCompany>({type: 'company'}, 'app-customers-company');

    }

  }

  handlePaste(data: Customers) {

    console.log('HANDLEPASTE', data.type, data, typeof data);

    if (data.type) {

      delete data.id;

      if(data.type === 'person') this.models.person = ModelsClass.add<CustomerPerson>(data, 'app-customers-person');
      if(data.type === 'company') this.models.company = ModelsClass.add<CustomerCompany>(data, 'app-customers-company');
      this.type = data.type;

      this.getSimilar();

    }

  }

  async getSimilar() {

    const similar: SDKResponse<DataObject<Customers[]>> = await this.$store.dispatch('AppCustomers/get', {
      query: {
        type: this.model.type,
        search: this.model.name,
      },
      settings: {
        project: this.$route.params.project,
        company: this.$route.params.company,
      },

    });
    const filteredData = similar.data.data.filter((single) => single.id !== this.content.id);

    this.similar = filteredData
      ? { all: filteredData.length, data: filteredData }
      : { all: 0, data: [] };

  }

  save($event: MouseEvent) {

    let action: string;
    let data: Customers | {id: string, data: Customers};

    $event.preventDefault();
    this.state = '';

    const model = hardCopy<Customers>(ModelsClass.save(this.model, `app-customers-${this.type}`));


    this.errors = null;

    if (this.edit) {

      action = 'AppCustomers/update';
      data = { id: this.$route.params.id, data: model };

    } else {

      action = 'AppCustomers/add';
      data = {
        ...model,
      };

    }

    this.$store
      .dispatch(
        action,
        data,
      )
      .then((response) => {

        this.state = 'success';

        console.log(response);

        if (!this.edit) {

          this.$router.push({ name: 'app-customers-company-edit', params: { id: response.data._id } });

        } else {

          StudioSDK.flushId(this.$route.params.id);

        }

        return true;

      })
      .catch((e) => {

        this.errors = e.response.data;
        this.state = 'error';

      });

  }

  remove() {

    this.$store
      .dispatch(
        'AppCustomers/remove',
        this.$route.params.id,
      )
      .then(() => {

        this.state = 'success';
        return true;

      })
      .catch((e) => {

        console.error(e);
        this.state = 'error';

      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store
      .dispatch('AppCustomers/single', {
        _id: params.id,
        settings: {
          company: params.company,
        },
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<Customers>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppCustomers/single', preloadedData.data);
    return Promise.resolve();

  }


}
</script>
<style lang="less" scoped>

.app-customers-edit-screen {

  table {

    tr {
      grid-template-columns: 1fr 1fr;

      td {

        &.hasDescription {

          .id {
            display: grid;
            color: @textColor;
            text-transform: uppercase;
          }

        }

      }

    }

  }

  .similarContainer {
    display: grid;
    gap: 10px;

    .table-container {
      overflow: auto;
      max-height: 200px;

    }

  }

}
</style>
