<template>
  <article class="menuItemElement-editor">

    <picture-partial-editor v-if="child === 'picture'" v-model="value.picture" />

    <section v-if="child === 'app-editor-menu-details'" class="editor-content-container">
      <input-partial v-model="value.title" placeholder="title">{{$translate('title')}}</input-partial>
      <textarea-partial v-model="value.description" placeholder="description">{{$translate('description')}}</textarea-partial>
      <input-partial v-model="value.price" placeholder="price">{{$translate('price')}}</input-partial>
    </section>

    <list-partial-editor v-if="child === 'app-editor-menu-allergens'" v-model="value.allergens" :predefined="allergensPredefined" no-head />
    <list-partial-editor v-if="child === 'app-editor-menu-properties'" v-model="value.properties" :predefined="foodClassifications" no-head />

    <moduleList-partial v-if="!child" class="module-editor-navigation">
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('app-editor-menu-details')">{{$translate('app-editor-menu-details')}}</button-partial>
      </li>
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('app-editor-menu-allergens')">{{$translate('app-editor-menu-allergens')}}</button-partial>
      </li>
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('app-editor-menu-properties')">{{$translate('app-editor-menu-properties')}}</button-partial>
      </li>
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('picture')">{{$translate('picture')}}</button-partial>
      </li>
    </moduleList-partial>

  </article>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import MenuItemElement from '@/models/MenuItemElement.model';

@Component
// eslint-disable-next-line global-require
export default class MenuCategoryElementEditor extends PartialEditorComponent<MenuItemElement> {

  itemModel = 'menu-item-element';

  allergensPredefined = [
    'celery', 
    'crustaceans', 
    'eggs', 
    'fish', 
    'gluten-containing-grains', 
    'lupin', 
    'milk', 
    'molluscs',
    'mustard', 
    'nuts', 
    'peanuts', 
    'sesame-seeds', 
    'soybeans', 
    'sulfur-dioxide-and-sulphites'
  ];

  foodClassifications = [
    'clean-label',
    'fair-trade', 
    'gluten-free', 
    'gmo-free', 
    'halal',
    'kosher',
    'lactose-free', 
    'low-carb/ketogenic', 
    'organic', 
    'paleo', 
    'raw-food', 
    'sugar-free', 
    'vegan',
    'vegetarian', 
    'whole30',
  ];


}

</script>
