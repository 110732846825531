<template>
  <article :class="navigationAnimation" class="app-hosting-home-screen">
    <div class="pagenator full">
      <header class="main">

        <section class="stats">
          <h1>{{$translate('app-hosting')}}</h1>
        </section>

        <quickmenu-partial>
          <router-link :title="$translate('app-hosting-project-domains')" :aria-label="$translate('app-hosting-project-domains')" class="icon" :to="{ name: 'app-hosting-project-domains' }"><icons-partial type="endpoints" size="large" /></router-link>
          <router-link :title="$translate('app-hosting-project-settings')" :aria-label="$translate('app-hosting-project-settings')" class="icon" :to="{ name: 'app-hosting-project-settings-overview' }"><icons-partial type="settings" size="large" /></router-link>
        </quickmenu-partial>
      </header>
    </div>

    <section class="content">

      <section class="settings-container content-section">
        <h2>{{ $translate('app-hosting-settings') }}</h2>
        <h3>{{$translate(hosting.active ? 'app-hosting-active' : 'app-hosting-inactive')}}</h3>
        <h3>{{$translate('app-hosting-settings-isApp')}}: {{$translate(hosting.isApp ? 'yes' : 'no')}}</h3>
        <h3>{{$translate('app-hosting-settings-redirectToMainDomain')}}: {{$translate(hosting.redirectToMainDomain ? 'yes' : 'no')}}</h3>

        <router-link :to="{name: 'app-hosting-project-settings-overview'}">{{ $translate('app-hosting-settings-view') }}</router-link>
      </section>

      <section class="domains-container content-section">
        <h2>{{ $translate('app-hosting-domains') }}</h2>
        <ul>
          <li v-for="(domain, index) in domainsActive" :key="`single-domain-${index}`">
            <a :href="`https://${domain.domain}`">https://{{ domain.domain }}</a> <router-link class="editLink" :to="{name: 'app-hosting-project-domains-single-edit', params: {id: domain._id}}">{{$translate('edit')}}</router-link>
          </li>
        </ul>
        <router-link :to="{name: 'app-hosting-project-domains'}">{{ $translate('app-hosting-domains-view') }}</router-link>
      </section>

    </section>


    <modal-module />
  </article>
</template>
<script lang="ts">
import { SDKResponse } from '@/libs/StudioSDK';
import { ExtComponent, Component } from '@/libs/lila-component';
import { Domain, Hosting } from '@lilaquadrat/interfaces';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppHostingHomeScreen extends ExtComponent {

  componentName = 'app-hosting-project-home';

  get hosting(): Hosting {

    return this.$store.state.AppHosting.single;

  }

  get domainsActive() {

    return this.$store.state.AppHosting.domains.data?.filter((single) => single.active && single.validated);

  }

  // eslint-disable-next-line class-methods-use-this
  async preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    const domains = await store
      .dispatch('AppHosting/getDomains', {
        settings: {
          project: params.project,
          company: params.company,
          site: params.site,
        },
      });
    const hosting = await store.dispatch('AppHosting/get', { project: params.project, company: params.company });

    return {domains, hosting};

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: {domains: SDKResponse<Domain[]>, hosting: SDKResponse<Hosting>}, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppHosting/single', preloadedData.hosting.data);
    store.commit('AppHosting/domains', preloadedData.domains.data);
    return Promise.resolve();

  }

}
</script>
<style lang="less" scoped>
.app-hosting-home-screen {

  .content {
    .basePadding;
    display: grid;
    gap: 50px;

    a {
      font-size: @fontText;
    }

    .content-section {
      display: grid;
      gap: 20px;

      ul {
        display: grid;
        gap: 10px;

        li {

          display: grid;
          grid-template-columns: max-content max-content;
          align-items: center;
          gap: 15px;

          a {

            &.editLink {
              font-size: @fontTextSmaller;
            }
          }
        }

      }
    }
  }
}
</style>

