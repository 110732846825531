import Models, { ModelDeclaration } from '@/libs/Models.class';

export default interface ModuleIndex {
  anchor: string
  title: string
}

const declaration: ModelDeclaration<ModuleIndex> = {
  anchor: { type: 'string' },
  title: { type: 'string' },
};

Models.register('module-index', declaration);