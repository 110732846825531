<template>
  <label v-bind:class="{ error: error, disabled: disabled }" class="lilab toggle" tabindex="">

    <span class="state-container">
      <div class="state button base" :class="{ colorScheme1: value, colorScheme2: !value }">{{on}}</div>
      <div class="state button base" :class="{ colorScheme1: !value, colorScheme2: value }">{{off}}</div>
    </span>

    <div v-if="error" class="errors">
      <p>{{error}}</p>
    </div>
    <input type="checkbox" :name="name" :checked="value" :disabled="disabled" v-on:change="$emit('input', $event.target.checked)"></input>
    <p v-if="description" class="description">{{$translate('description ')}}</p>
  </label>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ExtComponent } from '@/libs/lila-component';

@Component
// eslint-disable-next-line global-require
export default class togglePartial extends ExtComponent {

  @Prop(String) type: string;

  @Prop(String) name: string;

  @Prop(Boolean) value: boolean;

  @Prop(String) error: string;

  @Prop(String) description: string;

  @Prop(String) on: string;

  @Prop(String) off: string;

  @Prop(Boolean) disabled: boolean;

}
</script>
