import Models, { ModelDeclaration } from '@/libs/Models.class';
import Link from './Link.model';

export default interface ListLink {
  title: string
  value: Link[]
}

const declaration: ModelDeclaration<ListLink> = {
  title: { type: 'string' },
  value: { type: 'array', contains: { model: 'link' } },
};

Models.register('list-link', declaration);
