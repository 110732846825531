import Models, { ModelDeclaration } from '@/libs/Models.class';

export default interface CompareElement {
  elements: string[]
  headline: string
  description: string
}

const declaration: ModelDeclaration<CompareElement> = {
  headline: { type: 'string' },
  description: { type: 'string' },
  elements: { type: 'array', contains: { type: 'string' }, default: [] }, // change from array of arrays to object
};

Models.register('compare-element', declaration);