<script lang="ts">

import log from 'loglevel';
import { filterMergeModules } from '@lilaquadrat/studio/lib/frontend';
import { Route } from 'vue-router';
import SiteSettings from '@/interfaces/SiteSettings.interface';
import EditorActive from '@/interfaces/EditorActive';
import {
Content, Editor, GenericData, GenericDataWithContent, ModuleGeneric, ModuleSettings,
} from '@lilaquadrat/studio/lib/interfaces';
import { DesignModule , BasicData, DesignEvent, DesignCustomModule } from '@lilaquadrat/interfaces';
import equal from 'fast-deep-equal/es6';
import ActionNotice from '@/libs/ActionNotice';
import hardCopy from '@/mixins/hardCopy';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import ModelsClass from '@/libs/Models.class';
import isObjectId from '@/mixins/isObjectId';
import {
  ExtComponent, Component, vue, Watch,
} from '@/libs/lila-component';
import translation from '@/mixins/translation';
import {ModuleMapping, ModuleMappingV1} from '../editor-modules';
import ModuleGenericExtended from '../interfaces/ModuleGenericExtended.interface';
import UpdateIframe from '../functions/UpdateIframe';
import AppEditorDataStore from '../store/data.store';
import findObjectsWithKeys from '@/mixins/findObjetcsWithKeys';
import distributeGenericData from '@/mixins/distributeGenericData';

@Component
export default class AppEditorEditScreen extends ExtComponent {

  availableModules: { type: string; partial?: boolean }[] = [];

  additionalModuleData: Record<string, Record<string, unknown>> = {};

  availableVariants: Record<string, {value: string, text: string, description?: string, group?: string}[]> = {};

  moduleSettings: Record<string, ModuleSettings> = {};

  history: ModuleGenericExtended[] = [];

  routedName: string = null;

  created: boolean = false;

  iframeCurrent: HTMLIFrameElement;

  iframeHistory: HTMLIFrameElement;

  componentName = ['app-editor-project-edit', 'app-editor-project-new'];

  dragActive: boolean = false;

  draggedModule: string = '';

  draggedIndex: number = null;

  draggedContainer: string = null;

  mouseHold = null;

  /**
   * defines if this version is the parent or the child
   * the child only shows the content and no editor related stuff
   *
   * @type {boolean}
   * @memberof EditorEditScreen
   */
  parent: boolean = true;

  useHistory: boolean;

  showEditor: boolean = false;

  tempSwipe: number = 0;

  swipeX: number = 0;

  dragging: boolean = false;

  activeContent: ModuleGenericExtended = null;

  /**
  * revision of the design
  * defined which modules are loaded und used
  */
  revision: number = 0;

  revisionMapping = {
    0: {
      'menu-module': 'menu-module-v1' 
    }
  };

  dialog: {
    message?: string;
    description?: string;
    type?: 'confirm' | 'check';
    variant?: string[];
    // eslint-disable-next-line no-unused-vars
    callback?: (confirm: boolean) => void;
    active: boolean;
  } = { active: false };

  constructor() {

    super();

    Object.keys(ModuleMappingV1).forEach((key) => {

      this.availableModules.push({ type: key});

    });

  }

  @Watch('editor', { deep: true })
  watchEditor(): void {

    if (this.parent && this.created) UpdateIframe(this.iframeCurrent.contentWindow, {type: 'studio-content', data: this.contentLayout});

  }

  @Watch('siteSettings', { deep: true })
  watchSiteSettings(): void {

    if (this.parent && this.created) UpdateIframe(this.iframeCurrent.contentWindow, {type: 'studio-editor-settings', data: this.siteSettings});

  }

  @Watch('active')
  watchActive(): void {

    if (this.parent && this.created) UpdateIframe(this.iframeCurrent.contentWindow, {type: 'studio-active', data: this.active});

  }

  @Watch('layout')
  watchLayout(): void {

    if (this.parent && this.created) UpdateIframe(this.iframeCurrent.contentWindow, {type: 'studio-content', data: this.contentLayout});

  }

  @Watch('$route')
  watchRoute(): void {

    const updateArray = [];

    /**
     * if current route and new route are not the same an we are navigation through history or creating a new
     */
    if (this.routedName !== this.$route.name && !['app-editor-project-edit-history-index', 'app-editor-project-new'].includes(this.$route.name)) {
      return;
    };

    /**
    * this clears the iframe from the old content when triggering to create new content
    */
    if (this.routedName !== this.$route.name) {

      if (this.parent && this.created) UpdateIframe(this.iframeCurrent.contentWindow, {type: 'studio-content', data: []});

    };

    if (this.$store.state.AppEditorData.cache === this.cacheString) {

      return;

    }

    if (this.$route.name === 'editor-media-edit') return;

    if (
      // if param id is not the same as vuex, use is not set and we are not in history index or new mode
      // eslint-disable-next-line no-underscore-dangle
      (this.$route.params.id !== this.$store.state.AppEditorData.content._id && !this.$route.params.use && !['app-editor-project-edit-history-index', 'app-editor-project-new'].includes(this.$route.name))
      // history index mode and is not matching vuex id
      || (this.$route.name === 'editor-edit-history-index'
        // eslint-disable-next-line no-underscore-dangle
        && this.$route.params.id !== this.$store.state.AppEditorData.content._id)
    ) {

      updateArray.push('content');

    }

    if (this.$route.params.history || this.$route.params.index || +this.$route.params.index === 0) {

      updateArray.push('history');

    }

    if (this.$route.params.use) {

      this.useHistory = true;

    }

    if (this.$route.params.base) {

      updateArray.push('base');

    }

    log.debug('ROUTE', updateArray, this.$route.params);
    this.getData(updateArray);

  }

  get availablePositions() {

    return [
      {
        key: 'top',
        title: 'POSITION_TOP',
        modules: this.positionTopLayout,
        modulesCount: this.positionTopLayout.length,
        modulesCountWithoutLayout: this.positionTopLayout.filter((single) => !single.source).length,
      },
      {
        key: 'content',
        title: 'POSITION_CONTENT',
        modules: this.positionContentLayout,
        modulesCount: this.positionContentLayout.length,
        modulesCountWithoutLayout: this.positionContentLayout.filter((single) => !single.source).length,
      },
      {
        key: 'bottom',
        title: 'POSITION_BOTTOM',
        modules: this.positionBottomLayout,
        modulesCount: this.positionBottomLayout.length,
        modulesCountWithoutLayout: this.positionBottomLayout.filter((single) => !single.source).length,
      },
    ];

  }

  get historyContent() {

    return this.$store.state.AppEditorData.history;

  }

  get showHistoryCurrent() {

    return this.$store.state.AppEditorData.showHistoryCurrent;

  }

  get iframeUrl() {

    return this.$store.state.AppEditorMain.settings?.editorUrl || `${this.$store.state.settings.cdn}/${this.$store.state.Company.company}/${this.$store.state.Project.project}/editor/index.html`;

  }

  get cacheString() {

    return JSON.stringify({
      id: this.$route.params.id,
      index: this.$route.params.index,
      base: this.$route.params.base,
      use: this.$route.params.use,
      history: this.$route.params.history,
    });

  }

  get activeComponent(): ModuleGeneric {

    if (this.active?.uuid && !this.active?.pre) {

      const activeComponent = this.storeModules.find((single) => single.uuid === this.active.uuid) || {} as ModuleGenericExtended;
      const componentName = this.getModuleVersion(activeComponent.type as string);

      activeComponent.useEditor = `${componentName}-editor`;

      return activeComponent as ModuleGeneric;

    }

    return {} as ModuleGeneric;

  }

  get activeVariants() {

    const activeComponent = this.activeComponent;

    if (!activeComponent.type) return [];

    return this.availableVariants[activeComponent.type];

  }

  get activeSettings() {

    const activeComponent = this.activeComponent;

    if (!activeComponent.type) return [];

    return this.moduleSettings[activeComponent.type];

  }

  get activeAdditionalData() {

    const activeComponent = this.activeComponent;


    if (!activeComponent.type) return {};

    return this.additionalModuleData[activeComponent.type];

  }

  get storeData() {

    return this.$store.state.AppEditorData.content;

  }

  get storeModules(): ModuleGenericExtended[] {

    return this.$store.state.AppEditorData.content?.modules || [];

  }

  get Modules() {

    if(!this.revision) {

      return ModuleMappingV1;

    } 

    return ModuleMapping;
    

  }

  setActiveContent(): void {

    this.activeContent = this.$store.state.AppEditorData.content?.modules?.find((single: ModuleGenericExtended) => single.uuid === this.active.uuid) || undefined;

  }

  unsetActiveContent(): void {

    this.activeContent = null;

  }

  get siteSettings(): SiteSettings {

    const settings = hardCopy<Content>(this.$store.state.AppEditorData.content);

    delete settings.modules;
    delete settings.genericData;
    delete settings.childData;

    return settings as unknown as SiteSettings;

  }

  // eslint-disable-next-line class-methods-use-this
  listenCommands(): void {

    this.$root.$on('command', (key: string) => {

      log.info('got commands', key);
      this.save();

    });

  }

  unlistenCommands(): void {

    this.$root.$off('command');

  }

  leaveGuard = (to: Route, from: Route, next: (boolean?) => void, component: this): void => {

    if (to.name !== 'app-media-project-upload') this.$store.commit('AppMedia/showMediaSelect', false);

    if (this.$store.state.AppEditorData.state !== 'changes') {

      next(true);
      return;

    }

    component.dialog = {
      message: 'You have unsaved changes, do you really want to leave?',
      type: 'check',
      active: true,
      callback: (confirm) => {

        component.dialog = { active: false };

        next(confirm);

      },
    };

  };

  get layout() {

    return this.$store.state.AppEditorData.layout;

  }

  get showDialog() {

    if (this.dialog) return this.dialog;

    return null;

  }

  resetDialog() {

    this.dialog.callback(false);
    this.dialog = { active: false };

  }

  // eslint-disable-next-line class-methods-use-this
  handleHold(event: DragEvent | MouseEvent, draggedModuleId: string, draggedIndex: number, draggedContainer: string) {

    console.log(544, event, this.dragActive, event.type);

    if (event.type === 'mouseup') {

      // if (this.dragActive) event.preventDefault();
      // this.dragActive = false;
      clearTimeout(this.mouseHold);
      return;

    }

    this.mouseHold = setTimeout(() => {

      console.log('start drag');
      event.preventDefault();
      this.dragActive = true;
      this.draggedModule = draggedModuleId;
      this.draggedIndex = draggedIndex;
      this.draggedContainer = draggedContainer;

    }, 500);

  }

  cookieReset() {

    UpdateIframe(this.iframeCurrent.contentWindow, { type: 'studio-cookie-reset', data: undefined });

  }

  // eslint-disable-next-line class-methods-use-this
  handledrag(event: DragEvent, draggedModuleId: string, draggedIndex: number, draggedContainer: string) {

    console.log('HANDLEDRAG', event, draggedModuleId);

    if (event.type === 'dragstart') {

      console.log('DRAGSTART', event, draggedModuleId);
      setTimeout(() => {

        this.dragActive = true;
        this.draggedModule = draggedModuleId;
        this.draggedIndex = draggedIndex;
        this.draggedContainer = draggedContainer;

      }, 250);

    }

    if (event.type === 'dragend') {

      console.log('DRAGEND', event, draggedModuleId);
      clearTimeout(this.mouseHold);
      this.resetDrag();

    }

  }

  // eslint-disable-next-line class-methods-use-this
  handleDropped(event: { index: number; targetContainer: string }) {

    console.log('DROPPED', event, this.draggedModule);
    this.move(this.draggedModule, undefined, event.targetContainer, event.index);

    this.resetDrag();
    clearTimeout(this.mouseHold);

  }

  toggleEditor() {

    this.showEditor = !this.showEditor;

  }

  resetDrag() {

    this.dragActive = false;
    this.draggedModule = null;
    this.draggedIndex = null;
    this.draggedContainer = null;

  }

  get cssSwipe(): { [key: string]: string | number } {

    return {
      '--ts': `${this.tempSwipe}px`,
    };

  }

  swipeEndHandler(event) {

    console.log(this.dragging, this.tempSwipe, event);


    if (this.dragging) {

      this.dragging = false;

      if (this.showEditor) {

        console.log(window.screen.width / 2 < this.tempSwipe);

        if (window.screen.width / 2 < this.tempSwipe) {

          this.showEditor = false;

        }

      } else {

        console.log(window.screen.width / 2 > this.tempSwipe);

        if (window.screen.width / 2 > this.tempSwipe) {

          this.showEditor = true;

        }

      }

    }


  }

  swipeStartHandler(event) {

    console.log(event);

    const useEvent = 'changedTouches' in event ? event.changedTouches[0] : event;

    this.swipeX = useEvent.clientX;

    this.dragging = true;

  }

  swipeMoveHandler(event) {

    if (!this.dragging) return;

    const useEvent = 'changedTouches' in event ? event.changedTouches[0] : event;

    this.tempSwipe = Math.round(useEvent.clientX - this.swipeX);

  }

  mounted() {

    this.listenCommands();

    this.initIframe();
    this.parent = true;
    this.routedName = 'app-editor-project-edit';

    this.$store.commit('AppEditorData/active', false);

    /**
     * wait for the ready message from the iframe
     * send the inital data
     */
    window.addEventListener(
      'message',
      (message: 
        {data: 'studio-design-settings' | 'studio-design-ready'} | 
        {data: {type: 'studio-design-modules', data: DesignModule[]}} | 
        {data: {type: 'studio-design-custom-modules', data: DesignCustomModule[]}} | 
        {data: {type: 'studio-design-events', data: DesignEvent[]}} | 
        {data: {type: 'studio-design-modules-with-revision', data: {revision: number, modules: DesignModule[]}}
      }) => {

        if (message.data === 'studio-design-settings') {

          UpdateIframe(this.iframeCurrent.contentWindow, {type: 'studio-settings', data: this.$store.state.AppEditorMain.settings});

          if (this.isHistory) {

            this.updateIframeHistory('settings', this.$store.state.AppEditorMain.settings);

          }

        }

        if (message.data === 'studio-design-ready') {

          /**
           * only update the content when the content is rdy.
           * if the content is not yet ready it will send the data after the initalization is done
           */
          if (this.created) UpdateIframe(this.iframeCurrent.contentWindow, {type: 'studio-content', data: this.contentLayout});
          UpdateIframe(this.iframeCurrent.contentWindow, {type: 'studio-editor-settings', data: this.siteSettings});

          if (this.isHistory) {

            this.updateIframeHistory('content', this.history);
            // this.updateIframeHistory('editor-settings', AppEditorEditScreen.getSiteSettings(this.$store.state.AppEditorData.history));

          }

        }

        if(typeof message.data === 'object') {

            const useBase = this.$store.state.AppEditorData.type === 'new'
            ? this.$store.state.AppEditorData.content
            : this.$store.state.AppEditorData.untouched;


            if (message.data?.type === 'studio-design-modules') {

              console.log('studio-design-modules');
                this.updateAvailableModules(message.data.data);
                this.createEditor(useBase, this.Modules);
    
            }

            if (message.data?.type === 'studio-design-custom-modules') {

                console.log('studio-design-custom-modules', message.data.data);
                this.$store.commit('AppEditorData/customModules', message.data.data);
                
              }
              
              if (message.data?.type === 'studio-design-events') {
                
                console.log('studio-design-events', message.data.data);
                this.$store.commit('AppEditorData/events', message.data.data);
    
            }

            if (message.data?.type === 'studio-design-modules-with-revision') {

                console.log('studio-design-modules-with-revision');

                this.revision = message.data.data.revision;
                this.updateAvailableModules(message.data.data.modules);
                this.createEditor(useBase, this.Modules);
    
            }

        }

      },

      false,
    );

    if (this.$route.params.history && this.$route.params.use === 'use') {

      this.getData(['history']);
      this.useHistory = true;

    } else if (this.$route.params.base) {

      this.getData(['base']);

    } else {

      this.getData(['content', 'history']);

    }

  }

  beforeDestroy() {

    this.unlistenCommands();

  }

  async getData(updateArray: string[]) {

    this.created = false;

    if (this.$route.name === 'app-editor-project-edit' || this.$route.name === 'app-editor-project-edit-history-index') {

      if (updateArray.includes('content')) {

        try {
          
          await this.$store.dispatch('AppEditorData/getContent', this.$route.params);

          this.DOM.title = `${this.$store.state.AppEditorData.content.id} - ${this.$store.state.AppEditorData.content.state}`;
          this.$store.commit('AppEditorData/setCache', this.cacheString);
        
        } catch (error) {

          console.error(error);
          
        }


        if (this.$store.state.AppEditorData.content.settings?.useLayout) {
 
          try {
            
            await this.$store.dispatch('AppEditorData/layout', {
              company: this.$store.state.Company.company,
              project: this.$store.state.Project.project,
              id: this.$store.state.AppEditorData.content.settings?.useLayout,
            });

          } catch (error) {

            console.error(error);
            this.$store.commit('AppEditorData/layout');
            
          }


        } else {

          this.$store.commit('AppEditorData/layout');

        }

        if (!this.created) {

          this.createEditor(this.$store.state.AppEditorData.content, this.Modules);
          this.created = true;

        }

      }

      if ((this.$route.params.history || this.$route.params.index || +this.$route.params.index === 0) && updateArray.includes('history')) {

        return this.$store.dispatch('AppEditorData/getEditorHistory', this.$route.params).then(() => {

          if (!this.useHistory) {

            this.$store.commit('AppEditorData/type');
            this.$store.commit('AppEditorData/state');

            return true;

          }

          const history = hardCopy(this.$store.state.AppEditorData.history);

          history.immutable_from = this.$route.params.history;
          // eslint-disable-next-line no-underscore-dangle
          history._id = history.history.parent;

          this.$store.commit('AppEditorData/type', 'edit');
          this.$store.commit('AppEditorData/content', history);
          this.$store.commit('AppEditorData/untouched', history);
          this.$store.commit('AppEditorData/active', false);
          this.$store.commit('AppEditorData/setCache', this.cacheString);
          
          this.DOM.title = this.$store.state.AppEditorData.content.id;
          // this.setSiteSettings();
          
          this.createEditor(this.$store.state.AppEditorData.content, this.Modules);
          this.created = true;

          this.useHistory = false;
          this.$store.commit('AppEditorData/history', {});

          return true;

        });

      }

      this.$store.commit('AppEditorData/history', {});
      this.$store.commit('AppEditorData/type', 'edit');
      this.$store.commit('AppEditorData/active', false);
      this.$store.commit('AppEditorData/state', 'success');

    } else if (updateArray.includes('base')) {

      console.log('GET BASE');

      return this.$store
        .dispatch('AppEditorData/getEditorBase', {
          company: this.$store.state.Company.company,
          project: this.$store.state.Project.project,
          id: this.$route.params.base,
          type: this.$route.params.type,
        })
        .then((data: BasicData<Content>) => {


          const baseData = ModelsClass.add(data, 'editor-data');

          /**
          * id and _id needs to be removed from the data otherwise this will not be a new dataset
          */
          delete baseData._id;
          delete baseData.id;

          this.$store.commit('AppEditorData/content', baseData);

          this.$store.commit('AppEditorData/type', 'new');
          this.$store.commit('AppEditorData/active', { pre: 'settings', child: 'app-editor-base-settings' });
          this.$store.commit('AppEditorData/state', 'new');
          this.$store.commit('AppEditorData/setCache', this.cacheString);

          if (data.settings?.useLayout) {

            return this.$store.dispatch('AppEditorData/layout', {
              company: this.$store.state.Company.company,
              project: this.$store.state.Project.project,
              id: data.settings?.useLayout,
            });

          }

          return this.$store.commit('AppEditorData/layout');

        })
        .then(() => {

          this.DOM.title = this.$store.state.AppEditorData.content.id;
          this.$store.commit('AppEditorData/history', {});
          return this.createEditor(this.$store.state.AppEditorData.content, this.Modules);

        })
        .then(() => {

          this.created = true;

        })
        .catch((e) => {

          log.error(e);

        });

    } else {

      const baseData = ModelsClass.add({}, 'editor-data');

      this.$store.commit('AppEditorData/history', {});
      this.$store.commit('AppEditorData/content', baseData);
      this.$store.commit('AppEditorData/untouched', baseData);

      this.DOM.title = 'New';

      this.$store.commit('AppEditorData/type', 'new');
      this.$store.commit('AppEditorData/active', { pre: 'settings', child: 'app-editor-base-settings' });
      this.$store.commit('AppEditorData/state', 'new');
      this.$store.commit('AppEditorData/layout');

      this.created = true;
      // this.setSiteSettings();

    }

    return true;

  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  // eslint-disable-next-line class-methods-use-this
  debounce(timeout: any, debounceTime: number, trigger: () => void) {

    clearTimeout(timeout);
    // eslint-disable-next-line no-param-reassign
    timeout = setTimeout(() => {

      trigger();

    }, debounceTime);

  }

  initIframe() {

    const iframe = this.$el.querySelector('iframe.current') as HTMLIFrameElement;

    this.iframeCurrent = iframe;

  }

  initIframeHistory() {

    const iframe = this.$el.querySelector('iframe.history') as HTMLIFrameElement;

    this.iframeHistory = iframe;

  }

  updateIframeHistory(type: string, data: any) {

    if (!this.iframeHistory) return;
    if (!this.iframeHistory.contentWindow) return;

    this.iframeHistory.contentWindow.postMessage({ type, data }, '*');

  }

  toggle(index: { uuid?: string; position?: string; pre?: string }, active?: boolean): void {

    console.log(index, active);

    if (this.dragActive) {

      this.dragActive = false;
      return;

    }

    if (!active) this.unsetActiveContent();
    this.active = active ? index : {};

  }

  get contentHistory() {

    const content = { ...this.historyContent };

    if (content.immutable_history !== 0) content.previous = content.immutable_history - 1;
    if (content.immutable_history + 1 !== this.$store.state.AppEditorData.content.immutable_history) content.next = content.immutable_history + 1;

    vue
      .nextTick()
      .then(() => {

        // this.history = this.createEditor(content.modules, this.modules);

        this.initIframeHistory();
        // this.updateIframeHistory('editor-settings', AppEditorEditScreen.getSiteSettings(content));
        this.updateIframeHistory('content', this.history);

        return true;

      })
      .catch((e) => {

        log.error(e);

      });

    return content;

  }

  get active(): EditorActive {

    return this.$store.state.AppEditorData.active;

  }

  set active(active: EditorActive) {

    this.$store.commit('AppEditorData/active', active);
    this.$store.commit('AppEditorData/clearCrumbs');
    this.setActiveContent();


    UpdateIframe(this.iframeCurrent.contentWindow, {type: 'studio-active', data: active});

  }

  get type(): string {

    return this.$store.state.AppEditorData.type;

  }

  get content() {

    return this.storeModules;

  }

  get contentLayout() {

    const modules = hardCopy([...this.positionTopLayout, ...this.positionContentLayout, ...this.positionBottomLayout]);
    const genericData: GenericDataWithContent = this.$store.state.Cache.genericDataCache;

    distributeGenericData(modules, genericData);

    return modules;

  }

  get positionContent() {

    return filterMergeModules<ModuleGenericExtended>(this.storeModules, 'content');

  }

  get positionTop() {

    return filterMergeModules<ModuleGenericExtended>(this.storeModules, 'top');

  }

  get positionBottom() {

    return filterMergeModules<ModuleGenericExtended>(this.storeModules, 'bottom');

  }

  get positionContentLayout() {

    if (this.siteSettings?.layout) {

      return this.positionContent;

    }

    return filterMergeModules<ModuleGenericExtended>(this.storeModules, 'content', this.$store.state.AppEditorData.layout?.modules, 'layout');

  }

  get positionTopLayout() {

    if (this.siteSettings?.layout) {

      return this.positionTop;

    }

    return filterMergeModules(this.storeModules, 'top', this.$store.state.AppEditorData.layout?.modules, 'layout');

  }

  get positionBottomLayout() {

    if (this.siteSettings?.layout) {

      return this.positionBottom;

    }

    return filterMergeModules(this.storeModules, 'bottom', this.$store.state.AppEditorData.layout?.modules, 'layout');

  }

  get viewport() {

    return this.$store.state.AppEditorData.viewport;

  }

  get isHistory() {

    return !!this.$store.state.AppEditorData.history.immutable_parent;

  }

  get isLayout() {

    return this.$store.state.AppEditorData.content.settings.layout;

  }

  get publish() {

    return this.$store.state.AppEditorData.content.publish;

  }

  get settings() {

    return this.$store.state.AppEditorData.content.settings || {};

  }

  getModuleVersion(moduleName: string) {

    const revisionMapping = this.revisionMapping[this.revision];

    if(!revisionMapping) return moduleName;

    if(!revisionMapping[moduleName]) return moduleName;

    return revisionMapping[moduleName];

  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  // eslint-disable-next-line class-methods-use-this
  async legacy(content: Partial<Content> & { modules: ModuleGenericExtended[] }, modules: typeof this.Modules) {

    /**
    * switch useLayout from id to _id
    */
    if (content.settings?.useLayout) {

      if (!isObjectId(content.settings.useLayout)) {

        content.settings.useLayout = this.$store.state.AppEditorData.layout._id;

      }

    }

    if (content.childData?.index) {

      if(!content.genericData) content.genericData = {data: [], editor: [], lists: [], customers: [], media: []};
      if (!content.genericData?.editor) content.genericData.editor = [];

      const sdk = new StudioSDK('editor-app', AppEditorDataStore.sdkOptions());
      const PromiseArray: Promise<SDKResponse<Editor>>[] = [];

      content.childData.index.forEach((single) => PromiseArray.push(
        sdk.editor.getById(single)
          .catch((error) => {

            console.error(error);
            return null;

          })
      ));

      const genericData = await Promise.all(PromiseArray);

      genericData.forEach((single) => {

        if (!single) return;

        content.genericData.editor.push(single.data._id?.toString());
        this.$store.commit('Cache/genericDataCacheSingle', { type: 'editor', data: single.data });

      });

    }

    delete content.childData;

    if (content?.modules) {

      const moduleClasses = [];

      content?.modules.forEach((module: ModuleGenericExtended) => {

        if (modules[module.type]) {

          if (module.modules && module.type !== 'partial-module') {

            module.modules = this.legacy(module.modules, modules);

          }

          // update the genericData and remove childData at module level
          if ('childData' in module) {

            if (module.childData?.index?.length) {

              const indexWithObjectId = [];

              module.childData?.index.forEach((single) => {

                console.info('CHILDDATA LEGACY', module.childData.index, single);

                const cachedContent: Content = Object.values<Content>(this.$store.state.Cache.genericDataCache?.editor).find((singleContent) => singleContent.id === single);

                indexWithObjectId.push(cachedContent._id);

              });

              module.genericData = { editor: indexWithObjectId };
              delete module.childData;

            }

          }

          const singleModuleData = ModelsClass.add(module, this.getModuleVersion(module.type));

          moduleClasses.push(singleModuleData as unknown as ModuleGenericExtended);

        }

      });

      content.modules = moduleClasses;

    }


  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  // eslint-disable-next-line class-methods-use-this
  async createEditor(content: Content & { modules: ModuleGenericExtended[] }, modules: typeof this.Modules) {

    console.log('TYPE create');

    const safeContent = hardCopy(content);

    await this.legacy(safeContent, this.Modules);

    const moduleClasses: ModuleGenericExtended[] = [];

    if (safeContent.modules) {

      safeContent?.modules.forEach((module: ModuleGenericExtended) => {

        const type: keyof typeof this.Modules = module.type;

        if (modules[type]) {

          const newModule = ModelsClass.add(module, this.getModuleVersion(type));

          moduleClasses.push(newModule);

        }

      });

    }

    safeContent.modules = moduleClasses;

    // const siteSettings = new AppEditorDataModel().setData(safeContent, true, 'add');
    const siteSettings = ModelsClass.add(safeContent.settings, 'editor-data-html-settings');

    this.$store.dispatch('Cache/generateGenericDataCache', safeContent.genericData);

    this.$store.commit('AppEditorData/content', { ...safeContent, ...{ settings: siteSettings } });

    UpdateIframe(this.iframeCurrent.contentWindow, {type: 'studio-content', data: this.contentLayout});

    this.activeElement = safeContent.id;

  }

  updateAvailableModules(availableModules: DesignModule[]) {

    const filteredAvailableModules = [];

    availableModules.forEach((single) => {

      /**
      * only adds a module to available if the editor supports it
      */
      if(!this.Modules[single.name]) return;

      const singleModule = {type: single.name};

      filteredAvailableModules.push(singleModule);

      const variants = single.variants.map((variant) => ({
        value: variant.key,
        text: variant.name,
        description: variant.description,
        group: variant.group,
      }));

      this.availableVariants[single.name] = variants;
      
      this.moduleSettings[single.name] = {
        variants,
        editor: single.editor
      };

      if(single.additionalData) {

        this.additionalModuleData[single.name] = single.additionalData;

      }
      
    });

    this.availableModules = filteredAvailableModules;

  }

  pushData(data) {

    this.$store.dispatch('pushEditorContent', JSON.parse(JSON.stringify(data)));

  }

  /**
   * sets the state of the site do changes
   * to inidicate that there were changes made
   */
  changed(data: Partial<Content>, noChanges?: boolean) {

    if (this.$store.state.AppEditorData.state !== 'changes' && !noChanges) {

      this.$store.commit('AppEditorData/state', 'changes');

    }

    this.$store.commit('AppEditorData/content', { ...this.$store.state.AppEditorData.content, ...data });
    UpdateIframe(this.iframeCurrent.contentWindow, {type: 'studio-content', data: this.contentLayout});


  }

  changedModule(data: ModuleGenericExtended) {

    const modules = hardCopy(this.storeModules);
    const compareData = hardCopy(data);
    let compareBase = modules.find((single) => single.uuid === data.uuid);

    compareBase = compareBase ? hardCopy(compareBase) : null;

    const index = modules.findIndex((single) => single.uuid === data.uuid);
    const ignoredKeys = ['editor'];

    if(compareBase) {

      /**
      * remove specific keys before comparing the modules for differences
      * 
      * e.g. editor is only for the current editor relevant and will not be saved therefore changes in editor should not trigger changed
      */
      ignoredKeys.forEach((single) => {
  
        delete compareBase[single];
        delete compareData[single];
  
      });
    
    }

    modules[index] = data;

    this.changed({ modules }, equal(compareBase, compareData));

  }

  changedSettings(settings: Partial<Content>) {

    console.log('SETTINGS CHANGED', settings);
    // the empty modules would overwrite the real module date
    delete settings.modules;
    this.changed(settings);

  }

  // eslint-disable-next-line class-methods-use-this
  getPositionArray(position: string): ModuleGeneric[] {

    return this[`position${position.charAt(0).toUpperCase() + position.slice(1)}`];

  }

  remove(uuid?: string): void {

    console.log('remove', uuid);

    const useIndex = uuid || this.active.uuid;
    const modules = hardCopy(this.storeModules);


    modules.splice(
      modules.findIndex((single) => single.uuid === useIndex),
      1,
    );

    if (this.active?.uuid === useIndex) this.active = {};

    this.changed({ modules });
    this.resetDrag();

  }

  async add(module: keyof typeof this.Modules, partial: boolean = false, data?: ModuleGenericExtended) {

    console.log(module, partial, data);
    if (!this.Modules[module] && !partial) return;

    const editor = hardCopy<ModuleGenericExtended[]>(this.storeModules);
    let newData: ModuleGenericExtended;

    if (partial) {

      try {

        const partialData = await this.$store
          .dispatch(
            'AppEditorData/getPartial',
            // eslint-disable-next-line no-underscore-dangle
            {
              company: this.$store.state.Company.company,
              project: this.$store.state.Project.project,
              id: data._id,
            },
          );

        // eslint-disable-next-line no-underscore-dangle
        partialData.partialId = partialData._id;

        // eslint-disable-next-line new-cap
        // const newModule = ModulesFactory('partial-module');

        // newData = newModule.getData(partialData, true, 'add') as unknown as ModuleGenericExtended;
        newData = ModelsClass.add(partialData || {}, 'partial-module');

      } catch (error) {

        log.error(error);

      }


    } else {

      const modelName = this.getModuleVersion(module);


      newData = ModelsClass.add(data || {}, modelName);

    }

    editor.push(newData);

    console.log(editor);

    this.changed({ modules: editor });
    this.active = { uuid: newData.uuid, position: 'content' };

  }

  importModules(modules: ModuleGenericExtended[]) {

    const content = { modules };

    this.legacy(content, this.Modules);

    content.modules.forEach((module: ModuleGenericExtended) => {

      module.id = translation.translate('app-editor-import', undefined, undefined, [module.id]);

      this.add(module.type, module.partial, module);

    });

  }

  clone(uuid?: string) {

    // const source = uuid ? this.getPositionArray(position) : this.getPositionArray(this.active.position);
    const useIndex = uuid || this.active.uuid;
    const modules = hardCopy(this.storeModules);
    const baseModule: ModuleGenericExtended = modules.find((single) => single.uuid === useIndex);

    if (!baseModule) return;

    const newModule = ModelsClass.clone(
      {
        ...baseModule,
        id: this.$translate('app-editor-clone-of', [baseModule.id])
      },
      baseModule.type
    );

    modules.push(newModule);

    this.active = {};

    /**
     * scroll back up
     */
    this.$el.querySelector('.editor-container.base').scrollTo({ top: 0 });
    this.changed({ modules });
    this.resetDrag();

  }

  move(uuid: string, direction: string | undefined, position: string, targetIndex?: number): void {

    const module = this.storeModules.find((single) => single.uuid === uuid);
    const sourcePosition = module.position || 'content';
    const targetPosition = position;
    const changePosition = sourcePosition !== targetPosition;

    if (changePosition) {

      module.position = targetPosition;

    }

    const targetArray = this.getPositionArray(targetPosition);
    const index = targetArray.findIndex((single) => single.uuid === uuid);
    let to: number;

    /** up and down button were used */
    if (direction) {

      to = direction === 'up' ? index - 1 : index + 1;

      console.log(targetArray, uuid, index, to, direction, position);

    }

    /** direct index move  */
    if (targetIndex) {

      to = targetIndex;
      /**
       * if the module is moved down inside of the current position (top, content, bottom), the index muste be lowered
       * because the module gets first removed and then inserted again
       */
      if (to > index && !changePosition) to -= 1;

    }

    if (to < 0) return;

    targetArray.splice(to, 0, targetArray.splice(index, 1)[0]);

    const modules = [...this.positionTop, ...this.positionContent, ...this.positionBottom];

    this.changed({ modules });
    this.resetDrag();

  }

    /**
   * updates the genericData with the data from the sourceData
   */
  // eslint-disable-next-line class-methods-use-this
  updateGenericData(sourceData: unknown, genericData: GenericData) {

    /**
    * find all genericData in this module
    */
    findObjectsWithKeys(sourceData, ['genericData']).forEach((single) => {

      console.log('UPDATE GENERIC DATA', single);

      Object.entries(single.genericData as Record<string, string[]>).forEach(([key, values]) => {

        if(key === 'data') return;
        console.log(1560, key, values);

        genericData[key] = genericData[key] || [];
        values.forEach((value) => {

            if (!genericData[key].includes(value)) {

              genericData[key].push(value);
            
            }
                    
          });

      });

    });

  }

  async save() {

    const content = hardCopy(this.$store.state.AppEditorData.content);
    let method = 'AppEditorData/update';
    let dispatchData: Record<string, any> = {
      company: this.$store.state.Company.company,
      project: this.$store.state.Project.project,
    };
    const genericData: GenericData = {} as GenericData;

    /**
     * remove modules from partials for saving
     */
    content.modules = content.modules?.map((module: ModuleGenericExtended) => {

      // const newModule = ModulesFactory(module.type);
      // const newData = newModule.getData(module, true, 'save') as unknown as ModuleGenericExtended;

      const modelName = this.getModuleVersion(module.type);
      const newData = ModelsClass.save(module, modelName as string);

      if (newData.partialId) {

        delete newData.modules;

      }

      this.updateGenericData(newData, genericData);
      
      if (newData.genericDataSingle) {

        Object.keys(newData.genericDataSingle).forEach((key) => {

          if (!genericData[key]) genericData[key] = [];
          if (!genericData[key].includes(newData.genericDataSingle[key])) genericData[key].push(newData.genericDataSingle[key]);

        });

      }

      return newData;

    });

    content.genericData = genericData;

    // const cleanedContent = new AppEditorDataModel().setData(content, true, 'save');
    const cleanedContent = ModelsClass.save(content, 'editor-data');

    console.log(cleanedContent);

    if (this.siteSettings._id) {

      dispatchData = { ...dispatchData, id: content.id, content: cleanedContent };
      delete dispatchData.content.id;
      delete dispatchData.content._id;
      delete dispatchData.content.layout;
      delete dispatchData.content.partial;
      delete dispatchData.content.target;

    } else {

      method = 'AppEditorData/create';

      dispatchData = { ...dispatchData, content: cleanedContent };

    }

    this.$store.commit('AppEditorData/state', 'saving');

    try {

      // send the create or update request
      await this.$store.dispatch(method, dispatchData);

      this.$store.commit('AppEditorData/state', 'success');
      this.$store.commit('AppEditorData/lastSaved', new Date());

      // if type is new change into edit mode
      if (this.type === 'new') {

        this.animationDisabled = true;

        this.DOM.title = `${this.siteSettings.id} - ${this.$store.state.AppEditorData.content.state}`;
        this.$store.commit('AppEditorData/type', 'edit');
        this.$store.commit('AppEditorData/errors', null);

        this.$router.replace({
          name: 'app-editor-project-edit',
          params: {
            company: this.$store.state.Company.company,
            project: this.$store.state.Project.project,
            id: this.siteSettings.id,
          },
        });

        return;

      }

      StudioSDK.flushId(this.siteSettings.id);

    } catch (response) {

      this.$store.commit('AppEditorData/state', 'error');
      this.$store.commit('AppEditorData/errors', ActionNotice.parse(response.r.errors, 'app-editor', this.$translate));
      return;

    }

    this.$store.commit('AppEditorData/errors', null);
    // try {

    //   await this.$store.dispatch('AppEditorData/getContent', this.$route.params);
    //   this.createEditor(this.$store.state.AppEditorData.content, this.Modules);
    //   this.$store.commit('AppEditorData/errors', null);

    // } catch (response) {

    //   this.$store.commit('AppEditorData/state', 'error');
    //   this.$store.commit('AppEditorData/errors', ActionNotice.parse(response.r.errors, 'app-editor', this.$translate));

    // }

  }

  deleteSite() {

    this.$store
      .dispatch('editor_delete', this.siteSettings.id)
      .then(() => this.$router.push({ name: 'editor' }))
      .catch((e) => {

        log.error(e);

      });

  }

}
</script>
<template>
  <article class="editor-screen" :class="[navigationAnimation, { historyMode: isHistory, showEditor, dragging }]">
    <portal to="overlay">
      <transition name="overlay">
        <overlay-partial v-if="showDialog.active" @click="resetDialog">
          <dialog-partial v-bind="showDialog" />
        </overlay-partial>
      </transition>
    </portal>

    <select-media-ext v-if="$store.state.AppMedia.showMediaSelect" />

    <section class="content-left-container">
      <section class="editor-container top">
        <editor-responsive-module-editor />
      </section>

      <section class="iframe-container">
        <iframe v-if="isHistory" title="live preview" :class="[viewport]" class="history" :src="iframeUrl" />
        <iframe title="live preview" :class="[viewport, { visible: showHistoryCurrent }]" class="current" :src="iframeUrl" />
      </section>
      <section class="mobile-overlay" @click="toggleEditor" @keyup="toggleEditor" />
    </section>

    <section v-if="created" class="content-right-container">
      <section class="editor-container base" :class="{ details: active.uuid || active.pre }">
        <template v-if="!isHistory">
          <section v-if="!active.uuid && !active.pre" class="editor-sub base">
            <section class="content">
              <section class="publish-button-container">
                <status-publish-ext v-if="siteSettings._id" :_id="siteSettings._id" indicator />
                <button-partial color-scheme="transparent" @confirmed="toggle({ pre: 'publish' }, true)">{{
                  $translate('publish') }}</button-partial>
              </section>
              <button-partial color-scheme="transparent" @confirmed="toggle({ pre: 'tools' }, true)">{{
                $translate('tools') }}</button-partial>
              <button-partial color-scheme="transparent" @confirmed="toggle({ pre: 'settings' }, true)">{{
                $translate('settings') }}</button-partial>
            </section>
          </section>

          <section v-if="!active.uuid && !active.pre" class="editor-sub selection">
            <section class="content">
              <section v-if="content" class="component-container">

                <template v-for="(position) in availablePositions">

                  <transition-group v-if="position.modules.length || dragActive" :key="`${position.key}-trans-group`" tag="div" name="sort-list" class="position-container">
                    <div :key="`module-content-header-editor-top`" class="container-head">
                      <h3>{{ $translate(position.title) }}</h3>
                      <h3>{{ position.modulesCountWithoutLayout }} {{ $translate('modules') }}</h3>
                    </div>

                    <template v-for="(single, i) in position.modules">
                      <dropzone-partial v-if="i === 0 && (!single.source || isLayout)" :key="`${position.key}-pre-drag-${i}`" :drag-active="dragActive" :target-container="position.key" :source-container="draggedContainer" :source-index="draggedIndex" :index="0" @dropped="handleDropped" />

                      <section v-if="!single.source || isLayout" :key="`module-${single.uuid}`" class="sort-element editor-module" :class="{ isDragged: draggedModule === single.uuid }" draggable @mousedown="handleHold($event, single.uuid, i, position.key)" @mouseup="handleHold($event, single.uuid, i, position.key)" @dragstart="handledrag($event, single.uuid, i, position.key)" @dragend="handledrag($event, single.uuid, i, position.key)">
                        <header>
                          <button type="button" class="head" @click="toggle({ uuid: single.uuid }, $event)">
                            <div class="indicator">
                              {{ single.id }}
                              <template v-if="!single.id">{{ $translate('no id') }}</template>
                            </div>
                            <div class="type">{{ $translate(single.type) }}</div>
                          </button>

                          <div class="move">
                            <button type="button" class="move up" @click="move(single.uuid, 'up', position.key)"><icons-partial type="arrow-left" /></button>
                            <button type="button" class="move down" @click="move(single.uuid, 'down', position.key)"><icons-partial type="arrow-right" /></button>
                          </div>
                        </header>
                      </section>

                      <dropzone-partial v-if="!single.source || isLayout" :key="`${position.key}-post-drag-${i}`" :drag-active="dragActive" :target-container="position.key" :source-container="draggedContainer" :source-index="draggedIndex" :index="i + 1" @dropped="handleDropped" />

                      <layout-placeholder-partial v-if="single.source === 'layout' && !isLayout" :key="`module-${position.key}-${i}-layout`" :content="single" />

                      <dropzone-partial v-if="i === 0 && single.source" :key="`${position.key}-pre-drag-${i}`" :drag-active="dragActive" :target-container="position.key" :source-container="draggedContainer" :source-index="draggedIndex" :index="i" @dropped="handleDropped" />

                    </template>

                    <dropzone-partial v-if="!position.modulesCountWithoutLayout && dragActive" :key="`${position.key}-single-drag`" :drag-active="dragActive" :target-container="position.key" :index="0" @dropped="handleDropped" />

                  </transition-group>

                </template>

              </section>

              <button v-if="!dragActive" class="colorScheme2 base full extendedHeight toggleAddButton" type="button" @click="toggle({ pre: 'add' }, $event)">{{ $translate('ADD_NEW_MODULE') }}</button>
              <section v-if="dragActive" class="drag-options">
                <dropzone-partial :drag-active="dragActive" option @drop="clone(draggedModule)">{{
                  $translate('app-editor-drag-clone') }}</dropzone-partial>
                <dropzone-partial :drag-active="dragActive" option @drop="remove(draggedModule)">{{
                  $translate('app-editor-drag-delete') }}</dropzone-partial>
              </section>
            </section>
          </section>

          <section v-if="active.uuid || active.pre" class="editor-sub details">
            <editor-settings-module-editor v-if="active.pre === 'settings'" :content="siteSettings" @active="toggle(null, $event)" @cookieReset="cookieReset" @changed="changedSettings" />

            <editor-publish-module-editor v-if="active.pre === 'publish'" v-model="publish" @active="toggle(null, $event)" />

            <editor-tools-module-editor v-if="active.pre === 'tools'" v-model="storeModules" @active="toggle(null, $event)" @import="importModules($event)" />

            <component :is="`${activeComponent.useEditor}`" v-if="activeComponent.type" :key="`active-${activeComponent.type}-${active.uuid}-${active.position}`" :class="activeComponent.classes" :content="activeContent" :available-variants="activeVariants" :module-settings="activeSettings" :additional-data="activeAdditionalData" @remove="remove" @clone="clone" @active="toggle(null, $event)" @changed="changedModule" />

            <editor-add-module-editor v-if="active.pre === 'add'" :modules="availableModules" @add="add" @active="toggle({ pre: 'add' }, $event)" />
          </section>
        </template>

        <section class="editor-sub details">
          <editor-history-module-editor v-if="isHistory" :history="$store.state.AppEditorData.content.immutable_history" :content="contentHistory" @pushData="pushData($event)" />
        </section>

        <editor-controls-module-editor :is-history="isHistory" @save="save()" @deleteSite="deleteSite()" />
        <section class="mobile-overlay" @click="toggleEditor" @keyup="toggleEditor" />
      </section>

      <section class="editor-container bottom" />
    </section>
  </article>
</template>
<style lang="less" scoped>
.editor-screen {

  .publish-button-container {
    display: grid;
    grid-template-columns: min-content min-content;
    gap: 5px;
  }

  .toggleAddButton {
    width: auto;
    margin: 0 20px;

    @media @tablet,
    @desktop {
      width: 100%;
      margin: 0;
    }
  }

  .editor-module {

    header {
      background-color: @white;
      .multi(padding, 0, 4);

      .index(7);

      .editor-sub.selection & {
        display: grid;
        grid-template-columns: auto 50px;

        &:hover {

          button {

            h1 {
              color: @color3;
            }

            // .indicator {
            //     color: @color2;
            // }
          }
        }
      }

      button {
        line-height: 20px;

        text-align: left;

        .editor-sub.selection & {

          &.move {
            padding: 0;
          }
        }

        &:hover {

          .indicator {
            color: @color2;
          }
        }

        .indicator,
        .type {
          .trans(color);
        }

        .indicator {
          overflow: hidden;
          color: @color1;

          font-size: @fontText;
          text-transform: uppercase;

          text-overflow: ellipsis;

          .font-head;
          .lineHeight(@fontText);
        }

        &.move {
          display: grid;
          align-self: end;

          height: 20px;

          .icon-partial {
            display: grid;
            align-items: center;
            justify-items: end;

            text-indent: 0;

            svg {
              width: 10px;
              fill: @textColor;

              .trans(fill);
            }
          }

          &:hover {

            .icon-partial {

              svg {
                fill: @color1;
              }
            }
          }

          &.up {

            .icon-partial {

              svg {
                transform: rotate(90deg);
              }
            }
          }

          &.down {

            .icon-partial {

              svg {
                transform: rotate(90deg);
              }
            }
          }
        }

        &.back {
          background-color: @color1;
          color: @white;
          .lineHeight(@fontTextSmaller, 2);

          .multi(padding, 0, 2);

          .trans(background);

          &:hover {
            background-color: @color3;
          }
        }
      }

      div.move {
        display: grid;
        align-content: end;
        justify-content: end;
      }

      h1 {
        margin-top: 3px;
        font-size: 1.5em;

        .trans(color);
      }

      .trans(background);

      .open& {

        &:after {
          transform: rotate(90deg);
        }
      }

      .clearfix();
    }
  }

  .editor-container {
    display: grid;

    .editor-responsive-editor {
      display: grid;
      grid-template-columns: auto auto auto auto auto;
    }
  }
}
</style>
