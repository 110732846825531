import { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';
import { NavigationGuardNext, Route } from 'vue-router';
import log from 'loglevel';
import { Company, Me, Project, Permissions, ProjectPermissions, CompanyWithProjectsPermissions } from '@lilaquadrat/studio/lib/interfaces';
import store, { state } from '@/store/main.store';
import { SDKResponse } from './StudioSDK';

let isNode: boolean;

class Auth {

  authenticated: boolean = false;

  inProgess: boolean = false;

  token: string;

  origin: string;

  auth0: Auth0Client;

  dev: boolean;

  constructor() {

    if (isNode) return;

    if (typeof process !== 'undefined') {

      if (process?.env?.mock) {

        this.dev = true;

      }

    }

    this.origin = window.location.origin;

  }

  // eslint-disable-next-line camelcase
  async init(dev: boolean, auth0?: {domain: string, clientId: string, audience: string}) {

    log.info('%c[STUDIO-APP]%c%c[AUTH]%c INIT', 'background: #3f2d56; color: #CCC;', 'padding: 10px', 'background: #042940; color: #FFF;');


    let isAuth: boolean;

    if (dev) {

      log.info('%c[STUDIO-APP]%c%c[AUTH]%c IN DEV MODE', 'background: #3f2d56; color: #CCC;', 'padding: 10px', 'background: #042940; color: #FFF;');

      return this.setDev();

    }

    const auth0Connection: Auth0ClientOptions = auth0
      ? {
        ...auth0,
        useRefreshTokens: true,
        cacheLocation: 'localstorage',
        authorizationParams: {
          redirect_uri: `${this.origin}/login/callback`,
          scope: 'openid profile',
        }
      }
      : {
        domain: 'lila2.eu.auth0.com',
        clientId: 'IJTK7qwslPZsZ9A20HJylfu8ef3abHar',
        authorizationParams: {
          redirect_uri: `${this.origin}/login/callback`,
          scope: 'openid profile',
          audience: 'https://editor.lilaquadrat.de/api',
        },
        useRefreshTokens: true,
        cacheLocation: 'localstorage',
      };

    this.auth0 = new Auth0Client(auth0Connection as Auth0ClientOptions);

    try {

      isAuth = await this.checkAuth();

    } catch (e) {

      console.error(e.message);

      if (e.message === 'USER_NOT_FOUND') {

        return this.auth0.logout({ returnTo: `${window.location.protocol}//${window.location.host}` });

      }


    }

    log.info('%c[STUDIO-APP]%cAUTH INITED', 'background: #3f2d56; color: #CCC;', 'padding: 10px');
    return isAuth;

  }

  setDev() {

    const { localStorage } = window;

    localStorage.setItem('token', 'DEVTOKEN');
    this.dev = true;

  }

  async checkAuth(): Promise<boolean> {

    this.inProgess = true;

    let token: string;

    if (state.authToken) return true;

    if (this.dev) {

      store.commit('setUser', state.settings.devUser);
      store.commit('setAuthToken', 'DEVTOKEN');

      await store.dispatch('getPermissionsAll');

      return true;

    }

    const isAuth = await this.auth0.isAuthenticated();

    if (!isAuth) throw new Error('NOT_AUTHENTICATED');

    try {

      token = await this.auth0.getTokenSilently({authorizationParams: { scope: 'openid profile' }});

    } catch (error) {

      log.error(error);
      throw new Error('could not get token');

    }

    store.commit('setAuthToken', token);

    try {

      await store.dispatch('getMe');

    } catch (error) {

      throw new Error('USER_NOT_FOUND');

    }

    try {

      await store.dispatch('getPermissionsAll');

    } catch (error) {

      throw new Error('PERMISSONS_ERROR');

    }

    store.commit('Settings/clean');

    if (state.user?.settings) {

      store.commit('Settings/init', state.user?.settings);

    }

    return true;

  }

  static checkScope(user: Me, scope: string[], company?: string, project?: string) {

    let permissions: ProjectPermissions | CompanyWithProjectsPermissions;

    if(company && project) {

      permissions = state.Project.permissions;

    }

    if(company && !project) {

      permissions = state.Company.permissions;

    }


    const foundScope = permissions?.scope.filter((single) => scope.includes(single as string));

    return foundScope?.length === scope.length;

  }

  static getCompany(permissions: any[], company: string): Permissions {

    console.log(permissions, company);

    return permissions.filter((single) => (single.company === company || single.company.id === company) && !single.project)[0];

  }

  static getProject(permissions: any[], company: string, project: string): Permissions {

    return permissions.filter(
      (single) => (single.company === company || single.company.id === company) && single.project.id === project,
    )[0];

  }

  static getPermissionType(type: string) {

    return state.permissions.filter((single) => single.type === type)[0];

  }

  // /**
  //    * get permissions for a specific company or project
  //    *
  //    */
  // static getPermissions(company?: string, project?: string) {

  //   let permissions: Permissions;
  //   let typePermissions: any;

  //   /**
  //        * if only a company is given, we get alls permissions with the type user
  //        * type user contains all permissions for companies
  //        * type company contains all permissions for projects
  //       */
  //   if (company) typePermissions = Auth.getPermissionType('user');
  //   if (project) typePermissions = Auth.getPermissionType('company');

  //   if (typePermissions?.permissions) {

  //     permissions = project
  //       ? Auth.getProject(typePermissions.permissions, company, project)
  //       : Auth.getCompany(typePermissions.permissions, company);

  //     if (!permissions) return false;

  //   }

  //   return permissions;

  // }

  /**
     * get permissions for a specific company or project
     *
     */
  static getPermissions(company: string): CompanyWithProjectsPermissions<ProjectPermissions>;

  static getPermissions(company: string, project: string): ProjectPermissions;

  static getPermissions(company: string, project?: string) {

    const permissionsAll = state.permissionsAll;
    let targetObject = {};
    const companyPermissions = permissionsAll.find((single) => single._id.toString() === company);
    const projectPermissions = companyPermissions?.projects.find((single) => single.id === project);

    if(!project) targetObject = companyPermissions;
    if(project) targetObject = projectPermissions;

    return targetObject;

  }

  authorize() {

    // if (this.auth0) {

    this.auth0.loginWithRedirect();

    // }

  }

  register() {

    this.auth0.loginWithRedirect({ mode: 'signUp' });

  }

  async handle() {

    await this.auth0.handleRedirectCallback();
    await this.checkAuth();

  }

  logout() {

    store.commit('setAuthToken', null);
    this.auth0.logout({ returnTo: `https://${window.location.host}` });

  }

  static getAuthHeader() {

    return { Authorization: `Bearer ${state.authToken}` };

  }

  static async loadProject(company: string, project: string): Promise<Project> {

    if (state.Company.company === company && state.Project.project === project) return Promise.resolve(state.Project.settings);

    // await store.dispatch('getPermissions', { company, project });
    await store.dispatch('Appstore/getActive', { company, project });
    store.commit('Appstore/installedProject', false);

    const settings: SDKResponse<Project> = await store.dispatch('Project/getSettings', { company, project, details: false });

    store.commit('Project/settings', settings.data);
    store.commit('Project/project', project);
    store.commit('Project/permissions', Auth.getPermissions(company, project));

    return settings.data;

  }

  
  static async loadCompany(company: string): Promise<Company> {

    if (state.Company.company === company) return Promise.resolve(state.Company.settings);

    const settings: SDKResponse<Company> = await store.dispatch('Company/getSettings', company);

    // await store.dispatch('getPermissions', { company });
    await store.dispatch('Appstore/getActive', { company });
    store.commit('Appstore/installedCompany', false);

    store.commit('Company/settings', settings.data);
    store.commit('Company/company', company);
    store.commit('Company/permissions', Auth.getPermissions(company));

    await store.dispatch('Company/validate', settings.data);

    return settings.data;

  }

}

const authInstance = new Auth();
const Guard = (to: Route, from: Route, next: (to?: string) => void) => {

  authInstance.checkAuth()
    .then(() => next())
    .catch((e) => {

      log.error('auth error', e);
      next('/login');

    });

};
/**
 * check if the user is locked in an a selected route
 */
const HandleLock = (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {

  if (state.lock && to.name !== state.lock) {

    if (from.name !== state.lock) {

      next({ name: state.lock });
      return false;

    }

    return false;

  }

  return true;

};
const HandleCompanyLock = (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {

  if (state.Company.lock && to.name !== state.Company.lock) {

    next({ name: state.Company.lock, params: { company: to.params.company } });
    return false;

  }

  return true;

};

async function UnifiedRouteGuard(to: Route, from: Route, next: NavigationGuardNext<Vue>) {

  try {

    await authInstance.checkAuth();

  } catch (error) {

    console.error('auth error', error);
    next({name: 'login'});
    return;

  }

  try {

    if (to.params.company) {

      await Auth.loadCompany(to.params.company);

    } else {

      store.dispatch('Company/unset');

    }

    if (!HandleCompanyLock(to, from, next)) return;


    if (to.params.project) {

      await Auth.loadProject(to.params.company, to.params.project);

    } else {

      store.dispatch('Project/unset');

    }

    next();

  } catch (error) {

    log.error(error);
    next({ path: '/error/auth', replace: true });

  }

}

;

export default authInstance;
export {
  Auth,
  Guard,
  // getCompany,
  // getProject,
  HandleLock,
  HandleCompanyLock,
  UnifiedRouteGuard,
};
