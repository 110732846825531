<template>
  <article class="app-hosting-domains-single-screen" :class="navigationAnimation">
    <section>
      <form v-if="model" class="content" @submit="save">

        <input-partial v-model="model.domain" placeholder="app-hosting-domains-domain" :error="errorsObject.save.host" required :disabled="isEdit">{{$translate('app-hosting-domains-domain')}}</input-partial>
        <a class="domainLink" :href="`http://${model.domain}`">{{ $translate('app-hosting-domains-visit', [model.domain])}}</a>

        <section v-if="model.isDefault" class="details-partial">
          <h5>{{$translate('app-hosting-domains-isDefault-title')}}</h5>
          <p>{{$translate('app-hosting-domains-isDefault-description')}}</p>
        </section>

        <checkbox-partial v-model="model.isMain">{{$translate('app-hosting-domains-isMain')}}</checkbox-partial>

        <checkbox-partial v-model="model.active">{{$translate('app-hosting-domains-active')}}</checkbox-partial>

        <action-notice-partial :state="state" :translation-pre="translationPre" :errors="errors.save" @update="updateErrors($event, 'save')">
          <button-partial v-if="isEdit && !model.isDefault" type="button" class="base colorScheme2" doublecheck @confirmed="remove">{{$translate('app-hosting-domains-remove-button')}}</button-partial>
          <button-partial v-model="state" type="submit" save>
            <template v-if="!isEdit">{{$translate('app-hosting-domains-add-button')}}</template>
            <template v-if="isEdit">{{$translate('app-hosting-domains-edit-button')}}</template>
          </button-partial>
        </action-notice-partial>

        <template v-if="!model.isDefault">

          <notice-partial class="padding" type="notice" title="app-hosting-domains-a-record">
            {{$translate('app-hosting-domains-a-record-description')}}
          </notice-partial>

          <notice-partial class="padding" type="notice">
            <p>{{hostingIp}}</p>
          </notice-partial>

        </template>

        <notice-partial v-if="!model.isDefault && model.validated" class="padding" type="success" title="app-hosting-domains-validation-success" />

        <template v-if="!model.isDefault && !model.validated && isEdit">

          <notice-partial class="padding" type="error" title="app-hosting-domains-validation-required">
            {{$translate('app-hosting-domains-validation-description')}}
          </notice-partial>

          <notice-partial class="padding" type="notice">
            lila-studio-{{model.secret.key}}
          </notice-partial>

          <action-notice-partial :state="validateState" :translation-pre="translationPre" :errors="errors.validate" @update="updateErrors($event, 'validate')">
            <button-partial v-model="validateState" type="button" :translations="{ saving: 'app-hosting-domains-dns-check', error: 'validation-error-DNS_RECORD_MISSING' }" save @save="validate">
              {{$translate('app-hosting-domains-validate-button')}}
            </button-partial>
          </action-notice-partial>

        </template>


      </form>
    </section>

  </article>
</template>

<script lang="ts">
import { ExtComponent, Component, Watch } from '@/libs/lila-component';
import { SDKResponse } from '@/libs/StudioSDK';
import {
  ApiResponses,
} from '@lilaquadrat/studio/lib/interfaces';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';
import { ErrorsObject } from '@/libs/ActionNotice';
import { ErrorObject } from 'ajv/dist/types';
import AppInterface from '@/interfaces/App.interface';
import hardCopy from '@/mixins/hardCopy';
import SelectOption from '@/interfaces/selectOption.interface';
import MainStoreState from '@/store/mainStoreState.interface';
import ModelsClass from '@/libs/Models.class';
import Domain from '@/models/Domain.model';

@Component
export default class AppHostingDomainsSingleScreen extends ExtComponent {

  componentName = ['app-hosting-project-domains-single-add', 'app-hosting-project-domains-single-edit'];

  realType = '';

  affectedStatesOptions: SelectOption[] = [
    { value: 'draft', text: 'Draft' },
    { value: 'publish', text: 'Publish' },
  ];

  model: Domain = null;

  label = '';

  state: string = '';

  validateState: string = '';

  edit: boolean = false;

  errors: Record<'save' | 'validate', {message?: string, errors?: ErrorObject[]}> = { save: {}, validate: {} };

  errorsObject: Record<'save' | 'validate', ErrorsObject> = { save: {}, validate: {} };

  possibleEntries: {name: string, value: AppInterface['id']}[] = [];

  availableForApps: string[] = [];

  affectedStates: string[] = [];

  loading: boolean = false;

  loadingIndicatorTimeout = null;

  translationPre = 'app-hosting-projects-domains';


  @Watch('$route')
  watchRouteFunction() {

    this.setModel();

  }

  get content(): Domain {

    return this.$store.state.AppHosting.singleDomain;

  }

  get hostingIp(): string {

    return this.$store.state.AppHosting.single?.settings?.ip;

  }

  get isEdit(): boolean {

    return this.$route.name === 'app-hosting-project-domains-single-edit';

  }

  mounted() {

    this.setModel();

  }

  setModel() {

    this.model = ModelsClass.add<Domain>(this.isEdit ? this.content : {}, 'domain');
    // this.model = new DomainModel().setData(this.isEdit ? this.content : {}, true, 'add');

  }


  updateErrors(errorsObject: ErrorsObject, type: 'save' | 'validate') {

    this.errorsObject[type] = errorsObject;

  }


  save($event: MouseEvent) {

    let action: string;
    let data: Domain | {id: string, data: Partial<Domain>};

    $event.preventDefault();
    this.state = '';

    const model = hardCopy<Domain>(this.model);

    this.errors.save = null;

    if (this.isEdit) {

      action = 'AppHosting/updateDomain';
      data = {
        _id: this.$route.params.id,
        data: {
          active: model.active === true,
          isMain: model.isMain === true,
        },
      };

    } else {

      action = 'AppHosting/addDomain';
      data = {
        ...model,
      };

    }


    this.$store
      .dispatch(
        action,
        data,
      )
      .then((response) => {

        this.state = 'success';

        console.log(response);

        if (!this.isEdit) {

          this.$router.push({ name: 'app-hosting-project-domains-single-edit', params: { id: response.data._id } });

        }

        return true;

      })
      .catch((e) => {

        this.errors.save = e.response.data;
        this.state = 'error';

      });

  }

  remove() {

    this.$store
      .dispatch(
        'AppHosting/removeDomain',
        this.$route.params.id,
      )
      .then(() => {

        this.state = 'success';
        return true;

      })
      .catch((e) => {

        console.error(e);
        this.state = 'error';

      });

  }

  async validate() {

    this.errors.validate = null;

    let response: ApiResponses['post'];

    console.log('validate start');

    try {

      response = await this.$store.dispatch('AppHosting/validateDomain', this.$route.params.id);

    } catch (e) {

      console.error(e);
      this.errors.validate = e.response.data;
      this.validateState = 'error';

    }

    if (response.status === 200) {

      this.validateState = 'success';

      const domain: SDKResponse<Domain> = await this.$store.dispatch('AppHosting/singleDomain', {
        _id: this.$route.params.id,
        settings: {
          project: this.$route.params.project,
          company: this.$route.params.company,
        },
      });

      this.$store.commit('AppHosting/singleDomain', domain.data);
      this.setModel();

      return true;

    }

    this.validateState = 'error';
    return false;


  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    if (!params.id) return Promise.resolve();

    return store
      .dispatch('AppHosting/singleDomain', {
        _id: params.id,
        settings: {
          project: params.project,
          company: params.company,
        },
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<Domain>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    if (!params.id) return Promise.resolve();

    store.commit('AppHosting/singleDomain', preloadedData.data);
    return Promise.resolve();

  }


}
</script>
<style lang="less" scoped>

.app-hosting-domains-single-screen {

  .content {
    display: grid;
    gap: 20px;
  }

  .domainLink {
    .font-head;
    font-size: @fontText;
  }

}
</style>
