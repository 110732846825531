<template>
    <section class="datepicker-partial" ref="datepickerElement" :class="[`monthVisible${monthVisibleMediaAware}`, {range, icon, time, seconds, showRange: !hideRange}]" :style="monthVisibleCss">
      <app-tracker-time-partial v-if="!hideRange && range" text days :seconds="seconds" :value="rangeDuration" />  
      <section class="input-container" ref="triggerElement">

          <button-partial v-if="icon" @click="toggleCalendar()" class="front-toggle-icon" color-scheme="transparent"><icons-partial size="small" :type="icon" /></button-partial>

            <section class="input-group from">
              <input @keydown="checkInput('date', $event)" class="dayFrom" ref="dayFrom" @focus="focus" @blur="blur" v-model="tempDayFrom"  />
              <span class="delimiter">.</span>
              <input @keydown="checkInput('month', $event)" class="monthFrom" ref="monthFrom" @focus="focus" @blur="blur" v-model="tempMonthFrom" />
              <span class="delimiter">.</span>
              <input @keydown="checkInput('year', $event)" class="yearFrom" ref="yearFrom" @focus="focus" @blur="blur" v-model="tempYearFrom" />
              <template v-if="time">
                <button-partial color-scheme="icon" @click="toggleCalendar"><icons-partial size="small" type="chevron-down" /></button-partial>
                <input @keydown="checkInput('hours', $event)" class="hoursFrom" ref="hoursFrom" @focus="focus" @blur="blur" v-model="tempHoursFrom"  />
                <span class="delimiter">:</span>
                <input @keydown="checkInput('minutes', $event)" class="minutesFrom" ref="minutesFrom" @focus="focus" @blur="blur" v-model="tempMinutesFrom" />
                <template v-if="seconds">
                  <span class="delimiter">:</span>
                  <input @keydown="checkInput('seconds', $event)" class="secondsFrom" ref="secondsFrom" @focus="focus" @blur="blur" v-model="tempSecondsFrom" />
                </template>
                <button-partial color-scheme="icon" @click="toggleCalendar(undefined, 'time', 'from')"><icons-partial size="small" type="chevron-down" /></button-partial>
              </template>
            </section>

            <template v-if="range">

              <icons-partial class="range-separator-icon" size="medium" type="arrow-right-long" />
              
              <section class="input-group to">
                <input @keydown="checkInput('date', $event, 'to')" class="dayTo" ref="dayTo" @focus="focus" @blur="blur" v-model="tempDayTo"  />
                <span class="delimiter">.</span>
                <input @keydown="checkInput('month', $event, 'to')" class="monthTo" ref="monthTo" @focus="focus" @blur="blur" v-model="tempMonthTo" />
                <span class="delimiter">.</span>
                <input @keydown="checkInput('year', $event, 'to')" class="yearTo" ref="yearTo" @focus="focus" @blur="blur" v-model="tempYearTo" />
                <template v-if="time">
                  <button-partial color-scheme="icon" @click="toggleCalendar"><icons-partial size="small" type="chevron-down" /></button-partial>
                  <input @keydown="checkInput('hours', $event, 'to')" class="hoursTo" ref="hoursTo" @focus="focus" @blur="blur" v-model="tempHoursTo"  />
                  <span class="delimiter">:</span>
                  <input @keydown="checkInput('minutes', $event, 'to')" class="minutesTo" ref="minutesTo" @focus="focus" @blur="blur" v-model="tempMinutesTo" />
                  <template v-if="seconds">
                    <span class="delimiter">:</span>
                    <input @keydown="checkInput('seconds', $event, 'to')" class="secondsTo" ref="secondsTo" @focus="focus" @blur="blur" v-model="tempSecondsTo" />
                  </template>
                  <button-partial color-scheme="icon" @click="toggleCalendar(undefined, 'time', 'to')"><icons-partial size="small" type="chevron-down" /></button-partial>
                </template>
        
              </section>

            </template>

            <button-partial v-if="!time" color-scheme="icon" @click="toggleCalendar"><icons-partial size="small" type="chevron-down" /></button-partial>
        </section>

        <div v-if="slotUsed || required || disabled" class="label-container">
          <span v-if="slotUsed" class="label">
            <slot></slot>
          </span>
          <span v-if="required && !disabled" class="required">{{$translate('required')}}</span>
          <span v-if="disabled" class="required">{{$translate('disabled')}}</span>
        </div>

        <overlay-background-partial v-if="renderCalendar" background="none" ref="options" @mounted="() => {calculateOptionsStyle(); setTime(timeTarget)}" @close="toggleCalendar(false)">
          <article class="calendar-container time" v-if="calendarMode === 'time'" ref="calendarContainer" :style="calculatedOptions">
            <header class="main">
                <section class="controls">
                  <button-group-partial>
                    <button-partial @click="toggleCalendar()" color-scheme="transparent"><icons-partial size="small" type="chevron-up" /></button-partial>
                  </button-group-partial>
                </section>
              </header>
            <section class="time-selector-container" :class="{seconds}">
              <div class="scroll-overlay-gradient top"></div>
              <section class="scroll-container" ref="hoursScroll" @scroll="handleScroll($event, 'hours')">
                <section class="hours-container">
                  <div class="scroll-space"></div>
                  <button type="button" @click="setPredefinedTime(0, undefined, undefined)">00</button>
                  <button type="button" v-for="number in 23" @click="setPredefinedTime(number, undefined, undefined)" :key="`hours-${number}`">
                    {{ number.toString().padStart(2, '0') }}
                  </button>
                  <div class="scroll-space"></div>
                </section>
              </section>
              <section class="scroll-container" ref="minutesScroll" @scroll="handleScroll($event, 'minutes')">
                <section class="minutes-container">
                  <div class="scroll-space"></div>
                  <button type="button" @click="setPredefinedTime(undefined, 0, undefined)">00</button>
                  <button type="button" v-for="number in 59" @click="setPredefinedTime(undefined, number, undefined)" :key="`minutes-${number}`">
                    {{ number.toString().padStart(2, '0') }}
                  </button>
                  <div class="scroll-space"></div>
                </section>
              </section>
              <section v-if="seconds" class="scroll-container" ref="secondsScroll" @scroll="handleScroll($event, 'seconds')">
                <section class="seconds-container">
                  <div class="scroll-space"></div>
                  <button type="button" @click="setPredefinedTime(undefined, undefined, 0)">00</button>
                  <button type="button" v-for="number in 59" @click="setPredefinedTime(undefined, undefined, number)" :key="`seconds-${number}`">
                    {{ number.toString().padStart(2, '0') }}
                  </button>
                  <div class="scroll-space"></div>
                </section>
              </section>
              <div class="scroll-overlay-gradient bottom"></div>
            </section>
            <section class="time-selector-predefined-container">
              <section class="border-container">
                <section class="time-predefined-group">
                  <button-partial color-scheme="colorScheme2" @click="setPredefinedTime(0)">00:00</button-partial>
                  <button-partial color-scheme="colorScheme2" @click="setPredefinedTime(9)">09:00</button-partial>
                  <button-partial color-scheme="colorScheme2" @click="setPredefinedTime(12)">12:00</button-partial>
                  <button-partial color-scheme="colorScheme2" @click="setPredefinedTime(15)">15:00</button-partial>
                  <button-partial color-scheme="colorScheme2" @click="setPredefinedTime(18)">18:00</button-partial>
                  <button-partial color-scheme="colorScheme2" @click="setPredefinedTime(20)">20:00</button-partial>
                </section>
                
                <section class="time-predefined-group">
                  <button-partial color-scheme="colorScheme2" @click="setPredefinedTime(undefined, 0)">00:00</button-partial>
                  <button-partial color-scheme="colorScheme2" @click="setPredefinedTime(undefined, 15)">00:15</button-partial>
                  <button-partial color-scheme="colorScheme2" @click="setPredefinedTime(undefined, 30)">00:30</button-partial>
                  <button-partial color-scheme="colorScheme2" @click="setPredefinedTime(undefined, 45)">00:45</button-partial>
                  <button-partial color-scheme="colorScheme2" @click="setPredefinedTime(undefined, undefined, undefined, true)">{{$translate('datepicker-predefined-now')}}</button-partial>
                </section>
                
              </section>
            </section>
            <footer class="main">
              <button-partial @click="toggleCalendar()" color-scheme="colorScheme1">{{ $translate('datepicker-select-time-button') }}</button-partial>
            </footer>
          </article>

          <article
class="calendar-container" v-if="calendarMode === 'date'" 
            ref="calendarContainer" :key="$date(useMonthForCalender, 'MMYYYY')" :class="[`monthVisible${monthVisibleMediaAware}`, {range, icon}]" :style="calculatedOptions">
              <header class="main">
                <section class="details">
                    <app-tracker-time-partial v-if="range" text :value="rangeDuration" />
                </section>
                <section class="controls">
                  <button-group-partial>
                    <button-partial @click="modifyCalendarMonth('next', monthVisibleMediaAware)" color-scheme="icon"><icons-partial size="small" type="chevron-right" /></button-partial>
                    <button-partial @click="modifyCalendarMonth('prev', monthVisibleMediaAware)" color-scheme="icon"><icons-partial size="small" type="chevron-left" /></button-partial>
                    <button-partial @click="toggleCalendar()" color-scheme="icon" ><icons-partial size="small" type="chevron-up" /></button-partial>
                    <button-partial v-if="allowEmpty" @click="removeDate()" color-scheme="icon"><icons-partial size="small" type="close" /></button-partial>
                  </button-group-partial>
                </section>
              </header>
              <section class="scroll-container">

                <section class="elements-container">
                  <article class="single-element" v-for="(singleElement, index) in calendarElements" :key="`month-${index}`">
  
                    <template v-if="mode ==='days'">
  
                      <h3 class="selectTitle" role="button" @click="toggleMode">{{ $date(singleElement.month, 'MMMM') }} {{ $date(singleElement.month, 'YYYY') }}</h3>
                      <header>
                        <section class="header-day">MO</section>
                        <section class="header-day">DI</section>
                        <section class="header-day">MI</section>
                        <section class="header-day">DO</section>
                        <section class="header-day">FR</section>
                        <section class="header-day">SA</section>
                        <section class="header-day">SO</section>
                      </header>
                      <section class="days-container">
                        <template v-for="(singleDay, dayIndex) in singleElement.days" >
    
                          <div
v-if="singleDay.isCurrentMonth && !singleDay.isAfter && !singleDay.isBefore" role="button" class="day active" 
                            :class="{
                                currentMonth: singleDay.isCurrentMonth, 
                                today: singleDay.isToday, 
                                isSelected: singleDay.isSelected, 
                                isFrom: singleDay.isFrom, 
                                isTo: singleDay.isTo,
                                inRange: singleDay.inRange,
                                isHover: singleDay.isHover,
                                isBefore: singleDay.isBefore,
                                isAfter: singleDay.isAfter
                              }" 
                              :key="`day-${dayIndex}`"
                              @mouseenter="updateHover(singleDay.day)" 
                              @mouseleave="updateHover()" 
                              @click="selectDate(singleDay, selectMode)"
                            >
                            {{ $date(singleDay.day, 'D') }}
                          </div>
                          <div v-if="!singleDay.isCurrentMonth || singleDay.isAfter || singleDay.isBefore" class="day">
                            {{ $date(singleDay.day, 'D') }}
                          </div>
    
                        </template>
                      </section>
                      
                    </template>
  
                    <template v-if="mode ==='month'">
  
                      <h3 class="selectTitle" role="button" @click="toggleMode">{{ $date(singleElement.year, 'YYYY') }}</h3>
                      <article class="selection-container">
                        <section role="button" class="single-month" v-for="(singleMonth, index) in singleElement.months" :key="`month-${index}`" @click="selectCalendarMonth(singleMonth)">
                          {{ $date(singleMonth, 'MMMM') }}
                        </section>
                      </article>
  
                    </template>
  
                    <template v-if="mode ==='years'">
  
                      <h3 class="selectTitle" role="button" @click="toggleMode">{{ $date(singleElement.from, 'YYYY') }} - {{ $date(singleElement.to, 'YYYY') }}</h3>
                      <article class="selection-container">
                        <section role="button" class="single-year" v-for="(singleYear, index) in singleElement.years" :key="`month-${index}`" @click="selectCalendarYear(singleYear)">
                          {{ $date(singleYear, 'YYYY') }}
                        </section>
                      </article>
  
                    </template>
  
                  </article>
                </section>

              </section>

              <footer class="main">
                <button-partial @click="toggleCalendar()" color-scheme="colorScheme1">{{ $translate('datepicker-select-button') }}</button-partial>
                <button-partial v-if="allowEmpty" @click="removeDate()" color-scheme="colorScheme2">{{ $translate('datepicker-remove-button') }}</button-partial>
              </footer>

          </article>
      </overlay-background-partial>

    </section>
</template>
  
<script lang="ts">
import { Component,  Prop, Watch } from 'vue-property-decorator';
import dayjs, { ManipulateType } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import duration from 'dayjs/plugin/duration';
// import { useResize } from '@/plugins/resize';
import { ExtComponent } from '@/libs/lila-component';
import DatepickerCalendarElement from '@/interfaces/DatepickerCalendarElement.interface';
import DatepickerCalendarDay from '@/interfaces/DatepickerCalendarDay.interface';
// import type DatepickerCalendarElement from '@/interfaces/DatepickerCalendarElement.interface';

// import type DatepickerCalendarDay from '@/interfaces/DatepickerCalendarDay.interface';
// import type IconsPartial from '@/interfaces/IconsPartial.interface';

dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.locale('de');

@Component({inheritAttrs: false})
export default class DatepickerComponent extends ExtComponent {

    @Prop() from?: Date | string;

    @Prop() to?: Date | string;

    @Prop({ default: 1 }) monthVisible?: number;

    @Prop(Boolean) stayOpenOnSelect?: boolean;

    @Prop(Boolean) range?: boolean;

    @Prop(Boolean) hideRange?: boolean;

    @Prop(Boolean) future?: boolean;

    @Prop(Boolean) past?: boolean;

    @Prop({ default: undefined }) after?: Date | string;

    @Prop({ default: undefined }) before?: Date | string;

    @Prop({ default: undefined }) icon?: string;

    @Prop(Boolean) time?: boolean;

    @Prop(Boolean) seconds?: boolean;

    @Prop(Boolean) required: boolean;

    @Prop(Boolean) disabled: boolean;

    @Prop(Boolean) allowEmpty: boolean;

    tempDayFrom = '00';

    tempMonthFrom = '00';

    tempYearFrom = '0000';

    tempHoursFrom = '00';

    tempMinutesFrom = '00';

    tempSecondsFrom = '00';

    tempDateFrom: dayjs.Dayjs | undefined = undefined;

    tempDayTo = '00';

    tempMonthTo = '00';

    tempYearTo = '0000';

    tempHoursTo = '00';

    tempMinutesTo = '00';

    tempSecondsTo = '00';

    tempDateTo: dayjs.Dayjs | undefined = dayjs();

    hoverDate: dayjs.Dayjs | undefined = undefined;

    whitelistedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Home', 'End', 'Delete', 'Tab'];

    calendarElements: any = {};

    useMonthForCalender: dayjs.Dayjs | undefined = dayjs();

    calculatedOptions: any = {};

    renderCalendar = false;

    monthVisibleMediaAware = 1;

    mode: 'days' | 'month' | 'years' = 'days';

    calendarMode: 'date' | 'time' = 'date';

    timeTarget: 'from' | 'to' = 'from';

    useFrom: dayjs.Dayjs | undefined = dayjs();

    useTo: dayjs.Dayjs | undefined = dayjs();

    selectMode: 'from' | 'to' | '' = '';

    hoursScrollTimeout: any;

    minutesScrollTimeout: any;

    secondsScrollTimeout: any;

    rangeDuration: number = 0;

    $refs: {
        datepickerElement: HTMLElement;
        triggerElement: HTMLElement;
        dayFrom: HTMLInputElement;
        monthFrom: HTMLInputElement;
        yearFrom: HTMLInputElement;
        hoursFrom: HTMLInputElement;
        minutesFrom: HTMLInputElement;
        secondsFrom: HTMLInputElement;
        dayTo: HTMLInputElement;
        monthTo: HTMLInputElement;
        yearTo: HTMLInputElement;
        hoursTo: HTMLInputElement;
        minutesTo: HTMLInputElement;
        secondsTo: HTMLInputElement;
        options: HTMLElement;
        calendarContainer: HTMLElement;
        hoursScroll: HTMLElement;
        minutesScroll: HTMLElement;
        secondsScroll: HTMLElement;
    };

    get slotUsed() {

      return !!this.$slots.default?.length;

    }

    get monthVisibleCss() {

        return { '--monthVisible': this.monthVisible || 1 };

    }

    updateRangeDuration() {

      let range: number = 0;

      if(this.useFrom.isAfter(this.useTo)) {

        // the time needs to be switched if from is after to otherwise the temporary calculation is wrong
        range = this.useFrom
          .set('milliseconds', 0)
          .set('hours', this.useTo.get('hours'))
          .set('minutes', this.useTo.get('minutes'))
          .set('seconds', this.useTo.get('seconds'))
          .diff(this.useTo.set('milliseconds', 0)
            .set('hours', this.useFrom.get('hours'))
            .set('minutes', this.useFrom.get('minutes'))
            .set('seconds', this.useFrom.get('seconds'))
          );
      
      } else {

        range = this.useFrom.set('milliseconds', 0).diff(this.useTo.set('milliseconds', 0));
        
      }
        

      if(range < 0) range *= -1;
      this.rangeDuration = range;

    }

    get timeElementHeight() {

        if (this.$store.state.media === 'mobile') return 75;
        return 100;

    }

    mounted() {

        this.setTemp(this.from, 'from', true, true);
        if (this.range) this.setTemp(this.to, 'to', true, true);
        this.setmonthVisibleMediaAware();

        window.addEventListener('resized', () => {

          this.calculateOptionsStyle();

        });

    }

    @Watch('from')
    onFromChange() {

      this.setTemp(this.from, 'from', true, true);

    }

    @Watch('to')
    onToChange() {

      this.setTemp(this.to, 'to', true, true);

    }

    @Watch('tempHoursFrom')
    @Watch('tempMinutesFrom')
    @Watch('tempSecondsFrom')
    @Watch('tempDayFrom')
    @Watch('tempMonthFrom')
    @Watch('tempYearFrom')
    onFromDateChange() {

        this.update('from');
    
    }

    @Watch('tempHoursTo')
    @Watch('tempMinutesTo')
    @Watch('tempSecondsTo')
    @Watch('tempDayTo')
    @Watch('tempMonthTo')
    @Watch('tempYearTo')
    onToDateChange() {

        this.update('to');
    
    }

    @Watch('$store.state.media')
    onResize() {

        this.calculateOptionsStyle();
        this.setmonthVisibleMediaAware();

    }
    
    get media() {

        return this.$store.state.media;

    }

    setmonthVisibleMediaAware() {

        this.monthVisibleMediaAware = this.monthVisible || 1;
        if (this.monthVisible === 6 && (this.media === 'mobile' || this.media === 'tablet')) this.monthVisibleMediaAware = 3;
        this.updateMonth();

    }

    setTemp(baseDate?: Date | string | dayjs.Dayjs, target: 'from' | 'to' = 'from', updateCalendarMonth: boolean = true, updateTime = false) {

      console.log('setTemp', baseDate);

      if(!baseDate) {

        this.removeDate();
        return;
      
      }

      const date = baseDate ? dayjs(baseDate).clone() : dayjs();

      if (target === 'from') {

        if (updateTime) {

            this.tempHoursFrom = date.get('hours').toString().padStart(2, '0');
            this.tempMinutesFrom = date.get('minutes').toString().padStart(2, '0');
            this.tempSecondsFrom = date.get('seconds').toString().padStart(2, '0');
          
        }

        this.tempDayFrom = date.get('date').toString().padStart(2, '0');
        this.tempMonthFrom = (date.get('month') + 1).toString().padStart(2, '0');
        this.tempYearFrom = date.get('year').toString();
        this.tempDateFrom = dayjs()
            .set('date', +this.tempDayFrom)
            .set('month', +this.tempMonthFrom - 1)
            .set('year', +this.tempYearFrom)
            .set('hours', +this.tempHoursFrom)
            .set('minutes', +this.tempMinutesFrom)
            .set('seconds', +this.tempSecondsFrom);
          
      }

      if (target === 'to') {

        if (updateTime) {

            this.tempHoursTo = date.get('hours').toString().padStart(2, '0');
            this.tempMinutesTo = date.get('minutes').toString().padStart(2, '0');
            this.tempSecondsTo = date.get('seconds').toString().padStart(2, '0');
        
        }

        this.tempDayTo = date.get('date').toString().padStart(2, '0');
        this.tempMonthTo = (date.get('month') + 1).toString().padStart(2, '0');
        this.tempYearTo = date.get('year').toString();
        this.tempDateTo = dayjs()
            .set('date', +this.tempDayTo)
            .set('month', +this.tempMonthTo - 1)
            .set('year', +this.tempYearTo)
            .set('hours', +this.tempHoursTo)
            .set('minutes', +this.tempMinutesTo)
            .set('seconds', +this.tempSecondsTo);

      }

        if (updateCalendarMonth && !!this.tempDateFrom) this.setCalendarMonth(this.tempDateFrom);
        this.updateUseFromTo();
        if (!this.range) this.updateMonth();

    }

    removeDate() {

      if(!this.allowEmpty) return;

      this.tempDayFrom = '00';
      this.tempMonthFrom = '00';
      this.tempYearFrom = '0000';
      this.tempHoursFrom = '00';
      this.tempMinutesFrom = '00';
      this.tempSecondsFrom = '00';

      this.tempDateFrom = undefined;

      if(this.range) {
        
        this.tempDayTo = '00';
        this.tempMonthTo = '00';
        this.tempYearTo = '0000';
        this.tempHoursTo = '00';
        this.tempMinutesTo = '00';
        this.tempSecondsTo = '00';

        this.tempDateTo = undefined;

      }

      this.toggleCalendar(false);

    }

    emitDate(target: 'from' | 'to' = 'from') {

        if (target === 'from') this.$emit('updateFrom', this.tempDateFrom?.toDate());
        // if (target === 'to') this.$emit('updateTo', this.tempDateTo?.toDate());

    }

    checkInput(type: 'hours' | 'minutes' | 'seconds' | 'date' | 'month' | 'year', input: KeyboardEvent, target: 'from' | 'to' = 'from') {

        if (this.whitelistedKeys.includes(input.key)) return;

        const element = input.target as HTMLInputElement;
        const newDate = target === 'from' ? this.tempDateFrom?.clone() : this.tempDateTo?.clone();
        let tempCompareDate = newDate;

        if (input.key === 'Escape') {

        element.blur();
        return;
        
    }

        if (!input.key.match(/[0-9]/)) {

        input.preventDefault();
        return;
        
    }

        const daysOfMonth = [];

        if (!element || !newDate) return;

        for (let month = 0; month < 12; month++) {

        daysOfMonth.push({ month, days: dayjs().set('year', newDate.get('year')).set('month', month).daysInMonth() });
        
    }

        let tempValue: string;

        if (element.selectionStart !== null && element.selectionEnd && element.selectionStart < element.selectionEnd) {

        tempValue = input.key;
        
    } else {

        tempValue = element.value + input.key;
        
    }

        if (['hours', 'minutes', 'seconds', 'date', 'month'].includes(type)) {

        if (tempValue.length > 2) {

            input.preventDefault();
            return;
        
    }

        if (tempValue === '0' && tempValue.length === 1) return;
        if (tempValue.length < 2) return;
        
    }

        if (type === 'year') {

        if (tempValue.length > 4) {

            input.preventDefault();
            return;
        
    }

        if (tempValue.length < 4) return;
        
    }

        if (type === 'date') {

        const targetMonth = daysOfMonth.find((single) => single.month === newDate?.get('month'));

        tempCompareDate = newDate.clone().set('date', +tempValue);
        if (!targetMonth) return;

        if (targetMonth.days < +tempValue) {

            input.preventDefault();
            return;
        
    }
        
    }

        if (type === 'month') {

        const selectedMonth = daysOfMonth.find((single) => single.month === +tempValue - 1);

        tempCompareDate = newDate.clone().set('month', +tempValue - 1);

        if (!selectedMonth || selectedMonth.days < +this.tempDayFrom) {

            input.preventDefault();
            return;
        
    }
        
    }

        if (type === 'year') {

        const daysOfTargetMonth = dayjs().set('date', +this.tempDayFrom).set('month', +this.tempMonthFrom - 1).set('year', +tempValue).daysInMonth();

        tempCompareDate = newDate.set('year', +tempValue);

        if (daysOfTargetMonth < +this.tempDayFrom) {

            input.preventDefault();
            return;
        
    }
        
    }

        if (type === 'hours') {

        if (+tempValue > 23) {

            input.preventDefault();
            return;
        
    }

        tempCompareDate = newDate.set('hours', +tempValue);
        
    }

        if (type === 'minutes' || type === 'seconds') {

        if (+tempValue > 59) {

            input.preventDefault();
            return;
        
    }

        tempCompareDate = newDate.set(type, +tempValue);
        
    }

        if (!tempCompareDate) return;

        if (this.range) {

        if (target === 'from') {

            if (tempCompareDate.isAfter(this.tempDateTo, !this.time ? 'date' : undefined)) {

            input.preventDefault();
            return;
            
    }
        
    }

        if (target === 'to') {

            if (tempCompareDate.isBefore(this.tempDateFrom, !this.time ? 'date' : undefined)) {

            input.preventDefault();
            return;
            
    }
        
    }
        
    }

        if (this.after || this.future) {

        const afterDate = dayjs(this.after);

        if (tempCompareDate.startOf('date').isBefore(afterDate)) {

            input.preventDefault();
            
        }
        
    }

    }

    calculateOptionsStyle() {

        const element = this.$refs.triggerElement;
        const calendarContainerElement = this.$refs.calendarContainer;
        const newOptions = {
            '--monthVisible': this.monthVisibleMediaAware,
            '--elementHeight': `${this.timeElementHeight}px`,
            top: '0',
            left: '0'
        };

        if (element && calendarContainerElement && this.media !== 'mobile' && this.media !== 'tablet') {

            const bounds = element.getBoundingClientRect();
            const calendarBounds = calendarContainerElement.getBoundingClientRect();
            let top = bounds.top + element.offsetHeight;
            const positionTop = bounds.bottom + element.offsetHeight + calendarBounds.height + 50 > window.innerHeight;
            const positionLeft = bounds.x + calendarBounds.width + 50 > window.innerWidth;
            let left = bounds.left;

            if (positionTop) {

                top = bounds.top - 10 - calendarBounds.height;
            
            }

            if (positionLeft) {

                left = bounds.right - calendarBounds.width;
            
            }

            newOptions.top = `${top}px`;
            newOptions.left = `${left}px`;

        }
        
        this.calculatedOptions = newOptions;

    }

    async toggleCalendar(open?: boolean, setCalendarMode: 'date' | 'time' = 'date', setTimeTarget?: 'from' | 'to') {

      this.calculateOptionsStyle();
      this.mode = 'days';
      this.calendarMode = setCalendarMode;

      this.updateMonth();
      if (this.tempDateFrom) this.setCalendarMonth(this.tempDateFrom);

      if (open === undefined) {

        this.renderCalendar = !this.renderCalendar;
          
      } else {

        this.renderCalendar = open;
          
      }

      if (setTimeTarget) {

        this.timeTarget = setTimeTarget;
        this.setTime(setTimeTarget);

      }

      if (!this.renderCalendar) {

        this.hoverDate = undefined;
        this.emitDate('from');
        if (this.range) this.emitDate('to');
          
      }

    }

    focus(input: FocusEvent) {

        const element = input.target as HTMLInputElement;

        element.select();

    }

    blur(input: FocusEvent) {

        const element = input.target as HTMLInputElement;
        const type = element.classList[0];
        const requiredLength = ['dayFrom', 'dayTo', 'monthFrom', 'monthTo'].includes(type) ? 2 : 4;

        if (!this.tempDateFrom) return;

        if (element.value.length !== requiredLength) {

            if (type === 'dayFrom') {

                this.tempDayFrom = this.tempDateFrom.get('date').toString().padStart(2, '0');
            
            }

            if (type === 'dayTo' && this.tempDateTo) {

                this.tempDayTo = this.tempDateTo.get('date').toString().padStart(2, '0');
            
            }

            if (type === 'monthFrom') {

                this.tempMonthFrom = (this.tempDateFrom.get('month') + 1).toString().padStart(2, '0');
            
            }

            if (type === 'monthTo' && this.tempDateTo) {

                this.tempMonthTo = (this.tempDateTo.get('month') + 1).toString().padStart(2, '0');
            
            }

            if (type === 'yearFrom') {

                this.tempYearFrom = (this.tempDateFrom.get('year')).toString();
            
            }

            if (type === 'yearTo' && this.tempDateTo) {

                this.tempYearTo = (this.tempDateTo.get('year')).toString();
            
            }
            
        }

    }

    focusNext() {

        const orderArray = ['dayFrom', 'monthFrom', 'yearFrom'];
        const inputElements = [this.$refs.dayFrom, this.$refs.monthFrom, this.$refs.yearFrom];

        if (this.time) {

        orderArray.push('hoursFrom', 'minutesFrom');
        inputElements.push(this.$refs.hoursFrom, this.$refs.minutesFrom);

        if (this.seconds) {

            orderArray.push('secondsFrom');
            inputElements.push(this.$refs.secondsFrom);
        
    }
        
    }

        if (this.range) {

        orderArray.push('dayTo', 'monthTo', 'yearTo');
        inputElements.push(this.$refs.dayTo, this.$refs.monthTo, this.$refs.yearTo);

        if (this.time) {

            orderArray.push('hoursTo', 'minutesTo');
            inputElements.push(this.$refs.hoursTo, this.$refs.minutesTo);

            if (this.seconds) {

            orderArray.push('secondsTo');
            inputElements.push(this.$refs.secondsTo);
            
    }
        
    }
        
    }

        const currentFocus = document.activeElement;

        if (currentFocus?.nodeName !== 'INPUT') return;

        const currentPart = currentFocus?.classList[0];
        const currentIndex = orderArray.findIndex((single) => single === currentPart);

        if (currentIndex < inputElements.length - 1) {

        const newFocus = inputElements[currentIndex + 1];

        newFocus?.focus();
        
    }

    }

    update(type: 'from' | 'to') {

      if (document.activeElement?.nodeName !== 'INPUT' || !this.$refs.datepickerElement?.contains(document.activeElement)) return;

      if (type === 'from') {

      if (!this.tempDayFrom || this.tempDayFrom.length < 2 ||
          !this.tempMonthFrom || this.tempMonthFrom.length < 2 ||
          !this.tempYearFrom || this.tempYearFrom.length < 4) return;

      if (this.time) {

        if (!this.tempHoursFrom || this.tempHoursFrom.length < 2 ||
        !this.tempMinutesFrom || this.tempMinutesFrom.length < 2) return;

        if (this.seconds) {

          if (!this.tempSecondsFrom || this.tempSecondsFrom.length < 2) return;
            
        }
        
      }

        this.tempDateFrom = dayjs()
            .set('date', +this.tempDayFrom)
            .set('month', +this.tempMonthFrom - 1)
            .set('year', +this.tempYearFrom)
            .set('hours', +this.tempHoursFrom)
            .set('minutes', +this.tempMinutesFrom)
            .set('seconds', +this.tempSecondsFrom);

        if (document.activeElement?.nodeName === 'INPUT' && this.$refs.datepickerElement?.contains(document.activeElement)) this.setCalendarMonth(this.tempDateFrom);
        
    }

        if (type === 'to') {

        if (!this.tempDayTo || this.tempDayTo.length < 2 ||
            !this.tempMonthTo || this.tempMonthTo.length < 2 ||
            !this.tempYearTo || this.tempYearTo.length < 4) return;

        if (this.time) {

            if (!this.tempHoursTo || this.tempHoursTo.length < 2 ||
            !this.tempMinutesTo || this.tempMinutesTo.length < 2) return;

            if (this.seconds) {

            if (!this.tempSecondsTo || this.tempSecondsTo.length < 2) return;
            
    }
        
    }

        this.tempDateTo = dayjs()
            .set('date', +this.tempDayTo)
            .set('month', +this.tempMonthTo - 1)
            .set('year', +this.tempYearTo)
            .set('hours', +this.tempHoursTo)
            .set('minutes', +this.tempMinutesTo)
            .set('seconds', +this.tempSecondsTo);
        
    }

        this.focusNext();
        this.updateUseFromTo();
        this.emitDate(type);

    }

    setCalendarMonth(date: dayjs.Dayjs) {

        this.useMonthForCalender = date;
        if (!this.renderCalendar) this.updateMonth();

    }

    selectCalendarMonth(date: dayjs.Dayjs) {

        this.useMonthForCalender = this.useMonthForCalender?.set('year', date.get('year')).set('month', date.get('month'));
        this.mode = 'days';
        this.updateMonth();

    }

    selectCalendarYear(date: dayjs.Dayjs) {

        this.useMonthForCalender = this.useMonthForCalender?.set('year', date.get('year'));
        this.mode = 'month';
        this.updateMonth();

    }

    modifyCalendarMonth(direction: 'prev' | 'next', amount?: number) {

        let useAmount = amount || 1;
        let type: ManipulateType = 'month';

        if (this.mode === 'month') {

        type = 'year';
        
    }

        if (this.mode === 'years') {

        useAmount *= 9;
        type = 'year';
        
    }

        if (direction === 'prev') {

        this.useMonthForCalender = this.useMonthForCalender?.subtract(useAmount, type);
        
    }

        if (direction === 'next') {

        this.useMonthForCalender = this.useMonthForCalender?.add(useAmount, type);
        
    }

        this.updateMonth();

    }

    updateUseFromTo() {


      if (!this.range) {

        if(!this.tempDateFrom) return;
        this.useFrom = this.tempDateFrom;
        this.updateRangeDuration();
        return;
      
      }

      if(!this.tempDateFrom || !this.tempDateTo) return;

      if (this.selectMode) {

        if (this.selectMode === 'to') {

            this.useTo = this.tempDateTo.clone().set('date', this.hoverDate.get('date')).set('month', this.hoverDate.get('month')).set('year', this.hoverDate.get('year'));
            this.useFrom = this.tempDateFrom;
            
        } else if (this.selectMode === 'from') {

            this.useTo = this.tempDateTo;
            this.useFrom = this.tempDateFrom.clone().set('date', this.hoverDate.get('date')).set('month', this.hoverDate.get('month')).set('year', this.hoverDate.get('year'));
            
        }
        
        if(this.useFrom && this.useTo) this.updateRangeDuration();
        return;
      
      }
      
      this.useTo = this.tempDateTo;
      this.useFrom = this.tempDateFrom;
      this.updateRangeDuration();
          

    }

    getCalendarElements(date: dayjs.Dayjs) {

        const elementsToDisplay = this.monthVisibleMediaAware;
        const elements: DatepickerCalendarElement[] = [];
        const days = (elementsToAdd = 0) => {

        const baseDate = date.set('date', 15).add(elementsToAdd, 'month').startOf('month').startOf('week');
        const targetElement: DatepickerCalendarElement = {
            days: [],
            month: date.set('date', 15).add(elementsToAdd, 'month')
        };

        for (let day = 0; day < 42; day++) {

            const currentWorkDate = baseDate.add(day, 'day');
            const addElement: DatepickerCalendarDay = {
              day: currentWorkDate,
              isToday: currentWorkDate.isSame(dayjs(), 'day'),
              isCurrentMonth: currentWorkDate.isSame(targetElement.month, 'month'),
              isFrom: currentWorkDate.isSame(this.useFrom, 'date'),
              isTo: currentWorkDate.isSame(this.useTo, 'date') && this.range,
              inRange: currentWorkDate.isBetween(this.useFrom, this.useTo, 'date') && this.range,
            };

            if (!this.checkAfter(currentWorkDate) && !addElement.isFrom && !addElement.isTo) addElement.isBefore = true;
            if (!this.checkBefore(currentWorkDate) && !addElement.isFrom && !addElement.isTo) addElement.isAfter = true;

            targetElement.days?.push(addElement);
        
    }

        elements.push(targetElement);
        
    };
        const month = (elementsToAdd = 0) => {

        const baseDate = date.clone().startOf('year').add(elementsToAdd, 'year');
        const targetElement: DatepickerCalendarElement = {
            year: baseDate,
            months: []
        };

        for (let count = 0; count < 12; count++) {

            targetElement.months?.push(baseDate.add(count, 'month'));
        
    }

        elements.push(targetElement);
        
    };
        const years = (elementsToAdd = 0) => {

        const baseDate = date.clone().startOf('year').add(elementsToAdd * 9, 'year');
        const targetElement: DatepickerCalendarElement = {
            year: baseDate,
            from: baseDate.subtract(4, 'year'),
            to: baseDate.add(4, 'year'),
            years: []
        };

        targetElement.years?.push(baseDate.subtract(4, 'year'));
        targetElement.years?.push(baseDate.subtract(3, 'year'));
        targetElement.years?.push(baseDate.subtract(2, 'year'));
        targetElement.years?.push(baseDate.subtract(1, 'year'));
        targetElement.years?.push(baseDate);
        targetElement.years?.push(baseDate.add(1, 'year'));
        targetElement.years?.push(baseDate.add(2, 'year'));
        targetElement.years?.push(baseDate.add(3, 'year'));
        targetElement.years?.push(baseDate.add(4, 'year'));

        elements.push(targetElement);
        
    };
        const functions = { days, month, years };

        if (elementsToDisplay === 1) {

        functions[this.mode]();
        
    } else if (elementsToDisplay === 2) {

        functions[this.mode]();
        functions[this.mode](1);
        
    } else if (elementsToDisplay === 3) {

        functions[this.mode](-1);
        functions[this.mode]();
        functions[this.mode](1);
        
    } else if (elementsToDisplay === 6) {

        functions[this.mode](-1);
        functions[this.mode]();
        functions[this.mode](2);
        functions[this.mode](3);
        functions[this.mode](4);
        functions[this.mode](4);
        
    }

        return elements;

    }

    updateMonth() {

        const elements = this.getCalendarElements(this.useMonthForCalender as dayjs.Dayjs);

        this.calendarElements = elements;

    }

    checkAfter(date: dayjs.Dayjs) {

        if (!this.after && !this.future) return true;

        const afterDate = dayjs(this.after);

        return this.time ? date.isAfter(afterDate) : date.startOf('date').isAfter(afterDate, 'date');

    }

    checkBefore(date: dayjs.Dayjs) {

        if (!this.before && !this.past) return true;

        const beforeDate = dayjs(this.before);

        return this.time ? date.isBefore(beforeDate) : date.subtract(1, 'day').startOf('date').isBefore(beforeDate);

    }

  selectDate(singleDay: { day: dayjs.Dayjs }, target: 'from' | 'to' = 'from') {

    // if tempDate does not exist, set it to overwrite it
    if(!this.tempDateFrom) this.setTemp(dayjs(), 'from');
    
    if (!this.range) {
      
      if (this.checkAfter(singleDay.day) && this.checkBefore(singleDay.day)) {
        
        this.setTemp(singleDay.day, 'from', false);
        if (!this.stayOpenOnSelect) this.toggleCalendar(false);
        
      }
      
      return;
      
    }

    // if tempDate does not exist, set it to overwrite it
    if(!this.tempDateTo) this.setTemp(dayjs(), 'to');

    if (!this.selectMode) {

      if (singleDay.day?.isSame(this.tempDateFrom, 'date')) {

        this.selectMode = 'from';
        return;
        
      }
      
      if (singleDay.day?.isSame(this.tempDateTo, 'date')) {
        
        this.selectMode = 'to';
        return;
      
      }

      return;
      
    }

    if (target === 'from') {

      if (singleDay.day.isAfter(this.tempDateTo)) {

          if (this.checkAfter(singleDay.day) && this.checkBefore(singleDay.day)) {

          this.selectMode = undefined;
          this.setTemp(this.tempDateTo, 'from', false);
          this.setTemp(singleDay.day, 'to', false);
          return;
          
        }
      
      }
        
    }

    if (target === 'to') {

      if (singleDay.day.isBefore(this.tempDateFrom)) {

          if (this.checkAfter(singleDay.day) && this.checkBefore(singleDay.day)) {

          this.selectMode = undefined;
          this.setTemp(this.tempDateFrom, 'to', false);
          this.setTemp(singleDay.day, 'from', false);
          return;
          
        }
      
      } 
      
    } 

    if (this.checkAfter(singleDay.day) && this.checkBefore(singleDay.day)) {

      this.setTemp(singleDay.day, target, false);
      this.selectMode = undefined;
        
    }

  }

    selectTime(number: number, type: 'hours' | 'minutes' | 'seconds', target: 'from' | 'to') {

        const newDate = target === 'from' ? (this.tempDateFrom || dayjs())?.clone() : (this.tempDateTo || dayjs())?.clone();
        let tempCompareDate = newDate as dayjs.Dayjs;

        tempCompareDate = tempCompareDate.set(type, number);
        if (!this.checkAfter(tempCompareDate) || !this.checkBefore(tempCompareDate)) return false;

        if (target === 'from') {

            if (this.range && tempCompareDate.isAfter(this.tempDateTo)) {

                return false;
            
        }

            if (type === 'hours') this.tempHoursFrom = number.toString().padStart(2, '0');
            if (type === 'minutes') this.tempMinutesFrom = number.toString().padStart(2, '0');
            if (type === 'seconds') this.tempSecondsFrom = number.toString().padStart(2, '0');

            this.tempDateFrom = this.tempDateFrom?.clone()
                .set('hours', +this.tempHoursFrom)
                .set('minutes', +this.tempMinutesFrom)
                .set('seconds', +this.tempSecondsFrom);
            
        }

        if (target === 'to') {

            if (this.range && tempCompareDate.isBefore(this.tempDateFrom)) {

                return false;
            
            }

            if (type === 'hours') this.tempHoursTo = number.toString().padStart(2, '0');
            if (type === 'minutes') this.tempMinutesTo = number.toString().padStart(2, '0');
            if (type === 'seconds') this.tempSecondsTo = number.toString().padStart(2, '0');

            this.tempDateTo = this.tempDateTo?.clone()
                .set('hours', +this.tempHoursTo)
                .set('minutes', +this.tempMinutesTo)
                .set('seconds', +this.tempSecondsTo);
            
        }

        this.updateUseFromTo();
        return true;

    }

    updateHover(day?: dayjs.Dayjs) {

        if (!this.selectMode) return;

        this.hoverDate = day ? day.set('milliseconds', 0) : undefined;
        if(day) this.updateUseFromTo();
        this.updateMonth();

    }

    toggleMode() {

        if (this.mode === 'years') {

        this.mode = 'days';
        
    } else if (this.mode === 'days') {

        this.mode = 'month';
        
    } else if (this.mode === 'month') {

        this.mode = 'years';
        
    }

        this.updateMonth();

    }

    setTime(target: 'from' | 'to') {

        const useTempDate = target === 'from' ? this.tempDateFrom : this.tempDateTo;

        if (!useTempDate) return;

        this.scrollToIndex(+(useTempDate.get('hours') || 0), 'hours');
        this.scrollToIndex(+(useTempDate.get('minutes') || 0), 'minutes');
        this.scrollToIndex(+(useTempDate.get('seconds') || 0), 'seconds');

    }

    async scrollToIndex(index: number, target: 'hours' | 'minutes' | 'seconds', animate = false) {

        await this.$nextTick();

        const targetElement = this.$refs[`${target}Scroll`];

        if (!targetElement) return;

        targetElement.scrollTo({ top: index * this.timeElementHeight, behavior: animate ? 'smooth' : 'instant' });

    }

    handleScroll(event: UIEvent, target: 'hours' | 'minutes' | 'seconds') {

      const timeoutName = `${target}ScrollTimeout`;

      clearTimeout(this[timeoutName]);
      this[timeoutName] = setTimeout(() => this.executeScroll(event), 250);

    }

    executeScroll(event: UIEvent) {

        const target = event.target as HTMLElement;
        const childContainer = target.childNodes[0] as HTMLElement;
        const type = childContainer.classList[0].split('-')[0] as 'seconds' | 'hours' | 'minutes';
        const scrollTop: number = target.scrollTop;
        const selectedNumber: number = Math.round(scrollTop / this.timeElementHeight);

        if (!this.selectTime(selectedNumber, type, this.timeTarget)) {

            const useDate = this.timeTarget === 'from' ? this.tempDateFrom : this.tempDateTo;

            this.scrollToIndex(useDate?.get(type) as number, type, true);
            
        }

    }

    setPredefinedTime(hours?: number, minutes?: number, seconds?: number, now?: true) {

        if (hours !== undefined) {

            if (this.selectTime(hours, 'hours', this.timeTarget)) {

                this.scrollToIndex(hours, 'hours', true);
            
            }
        
        }

        if (minutes !== undefined) {

            if (this.selectTime(minutes, 'minutes', this.timeTarget)) {

                this.scrollToIndex(minutes, 'minutes', true);
            
            }
            
        }

        if (seconds !== undefined) {

            if (this.selectTime(seconds, 'seconds', this.timeTarget)) {

                this.scrollToIndex(seconds, 'seconds', true);
            
            }
        
        }

        if (now) {

            if (this.selectTime(dayjs().get('hours'), 'hours', this.timeTarget)) this.scrollToIndex(dayjs().get('hours'), 'hours', true);
            if (this.selectTime(dayjs().get('minutes'), 'minutes', this.timeTarget)) this.scrollToIndex(dayjs().get('minutes'), 'minutes', true);
            if (this.selectTime(dayjs().get('seconds'), 'seconds', this.timeTarget)) this.scrollToIndex(dayjs().get('seconds'), 'seconds', true);
        
        }

    }

}
</script>
  
<style lang="less" scoped>
.datepicker-partial {
  display: grid;
  grid-template-columns: max-content;


  .label-container {
    display: grid;
    grid-template-columns: 50% 50%;

    span.label {
      .font-normal;
      .multi(padding, 1, 0, 0, 0);

      color: @textColor;

      font-size: @fontTextSmaller;

      text-transform: uppercase;
    }
    span.required {
    .font-normal;
    .multi(padding, 1, 0, 0, 0);

    color: @textColor;

    font-size: @fontTextSmaller;

    text-align: right;
    text-transform: uppercase;
  }
  }


  .input-container {
    display: grid;
    grid-template-columns: 1fr max-content;
    border-bottom: 1px @color1 solid;

    .input-group {

      display: grid;
      grid-template-columns: 17px max-content 17px max-content 35px;
      align-content: center;
      align-items: center;
      overflow: hidden;

      .delimiter {
        display: grid;
        width: 7px;
        line-height: 20px;
        text-align: center;
      }

      input {
        padding: 5px 0;
        border: 0;
        outline: none;
        text-align: center;
      }
    }

  }

  &.icon {

    .front-toggle-icon::v-deep {
      width: 25px;

      .icons-partial {
        width: 20px;

      }
    }

    .input-container {
      grid-template-columns: 25px 1fr max-content;
    }
  }

  &.range {

    &.showRange {
      grid-template-rows: max-content 1fr;
      gap: 5px;
    }

    .input-container {
      grid-template-columns: max-content 35px max-content max-content;

      .range-separator-icon {
        justify-self: center;
      }

    }

    &.icon {

      .input-container {
        grid-template-columns: 25px max-content 35px max-content max-content;
      }

    }

    &.time {

      .input-container {
        grid-template-rows: max-content max-content;
        justify-items: start;

        .input-group.to {
          grid-row-start: 2;
        }

      }

    }

    &.icon.time {

      .input-container {
        grid-template-columns: 25px max-content;

        .range-separator-icon, .input-group.to {
          grid-row-start: 2;
        }

        .range-separator-icon {
          grid-column-start: 1;
        }
      }
    }

  }

  &.time {
    /* stylelint-disable-next-line no-descending-specificity */

    .input-container {

      .input-group {
        grid-template-columns: 17px max-content 17px max-content 35px 35px 17px max-content 17px 35px;
      }

    }

    &.seconds {
      /* stylelint-disable-next-line no-descending-specificity */

      .input-container {

        .input-group {
          grid-template-columns: 17px max-content 17px max-content 35px 35px 17px max-content 17px max-content 17px 35px;
        }

      }

    }

  }
}

.calendar-container {

  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: 40px calc(100vh - 120px) 80px;
  width: 100%;

  background-color: @white;

  header.main {

    display: grid;
    grid-template-columns: 1fr max-content;

    background-color: @grey1;

    .details {
      .multi(padding, 0, 2);
      line-height: @buttonHeight;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  footer.main {

    display: grid;
    align-items: center;
    justify-items: center;

    @media @desktop {
      display: none;
    }

  }

  .scroll-container {
    overflow-y: auto;
  }

  @media @desktop {
    position: absolute;
    grid-template-rows: 40px 1fr;
    width: calc(var(--monthVisible) * 350px);
    border: solid 1px @grey1;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .13);

    &.time {
      grid-template-rows: 1fr max-content;
      grid-template-columns: 2fr 1fr;
      width: 450px;
    }

  }

  &.time {
    grid-template-rows: 35px max-content 1fr 80px;
    height: 100vh;
    border: 0;
    box-shadow: none;

    @media @desktop {
      grid-template-rows: 1fr;
      align-items: end;
      height: auto;
    }

    header.main {
      display: grid;
      grid-template-columns: 1fr;

      @media @desktop {
        display: none;
      }

    }

  }

  h3.selectTitle {
    display: grid;

    align-content: center;
    justify-content: center;
    width: 100%;
    height: 60px;

    &[role=button] {
      cursor: pointer;

      user-select: none;

      &:hover {
        color: @color1;
      }

      .trans(color);

    }

  }

  .selection-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    width: 100%;

    .single-month,
    .single-year {
      display: grid;
      align-content: center;
      justify-content: center;
      cursor: pointer;

      .multi(padding, 4);

      &:hover {
        background-color: @color1;
        color: @white;
      }
    }

  }

  .elements-container {
    display: grid;

    transition: opacity .5s, transform .5s;

    @media @desktop {
      grid-template-rows: 365px;
    }

    .single-element {
      display: grid;

      grid-template-rows: 60px 1fr;
      align-self: center;
      justify-items: center;
      justify-self: center;
      width: 100%;
      max-width: 450px;

      .multi(padding, 0, 2);

      @media @desktop {
        align-self: auto;
        justify-self: auto;

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          border-top: solid 1px @grey;
        }

        &:nth-child(2),
        &:nth-child(5) {
          border-right: solid 1px @grey;
          border-left: solid 1px @grey;
        }

      }

      header,
      .days-container {
        display: grid;
        grid-template-columns: repeat(7, 1fr);

        width: 100%;
        text-align: center;

        .day {
          align-content: center;

          justify-content: center;
          width: 1fr;

          border: solid 1px @white;
          color: @grey;
          cursor: pointer;
          pointer-events: none;
          aspect-ratio: 1/1;

          &.currentMonth {
            color: @textColor;
          }

          &.today {
            color: @color1;
            .font-head;
          }

          &.isSelected,
          &.isTo,
          &.isFrom {
            background-color: @color3;
            color: @white;
          }

          &.inRange {
            background-color: @color2;
          }

          &.isHover {
            background-color: @black;
          }

          &.active {

            pointer-events: all;

            &:hover {
              background-color: @color1;
              color: @white;
            }
          }

        }
      }

      .days-container {

        .day {

          &:nth-child(7n),
          &:nth-child(7n -1) {
            .font-bold;
          }
        }
      }

      header {

        .header-day {

          &:nth-child(7n),
          &:nth-child(7n -1) {
            .font-bold;
          }
        }
      }

    }

  }

  .time-selector-container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: solid 1px @grey1;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .13);

    &.seconds {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .scroll-overlay-gradient {
      position: absolute;
      align-self: start;

      justify-self: start;
      width: 100%;
      height: calc(var(--elementHeight) * 1);

      background: linear-gradient(
        to bottom,
        rgba(255,255,255,.9) 0%,
        rgba(255,255,255,.7) 100%
      );
      pointer-events: none;

      &.bottom {
        align-self: end;
        justify-self: end;

        background: linear-gradient(
          to top,
          rgba(255,255,255,.9) 0%,
          rgba(255,255,255,.7) 100%
        );

      }
    }

    .scroll-container {
      height: calc(var(--elementHeight) * 3);
      background-color: @white;
      scroll-snap-type: y mandatory;

      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      button, .scroll-space {
        display: grid;
        align-content: center;
        justify-content: center;
        height: var(--elementHeight);
        scroll-snap-align: start;
      }

      button {
        border: 0;
        background-color: @grey2;
        color: @color1;
        font-size: @headline_XS;
        .font-head;
      }

    }

    .hours-container {
      display: grid;
      grid-template-rows: repeat(24, var(--elementHeight));
    }

    .minutes-container, .seconds-container {
      display: grid;
      grid-template-rows: repeat(60, var(--elementHeight));

    }

  }

  .time-selector-predefined-container {
    display: grid;
    grid-auto-rows: max-content;

    .border-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border: solid 1px @grey1;
      background-color: @grey1;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .13);
    }

    .time-predefined-group {

      display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: max-content;

      &.select-button {

        grid-column-start: 1;
        grid-column-end: 3;

      }

      .title {
        display: grid;

        grid-column-start: 1;
        grid-column-end: 2;
        font-size: @fontTextSmaller;

        text-align: center;

        .multi(padding, 2);
      }

    }

  }

  &.monthVisible1 {

    .elements-container {
      display: grid;
      grid-template-columns: 1fr;
    }
  }

  &.monthVisible2 {

    .elements-container {
      display: grid;

      @media @desktop {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  &.monthVisible3 {

    .elements-container {
      display: grid;

      @media @desktop {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  &.monthVisible6 {

    @media @desktop {
      width: calc(3 * 350px);
    }

    .elements-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      @media @desktop {
        grid-template-rows: 365px 365px;
      }
    }
  }
}
</style>