<template>
  <div class="header-container">
    <header class="side-header">
      <h1><slot /></h1>
      <router-link class="button base icon" :to="parentLink"><icons-partial type="close" size="large" colorScheme="colorScheme2" /></router-link>
    </header>

    <section class="modal-crumbs" v-if="allPaths">
      <template v-for="(single, index) in allPaths">
        <router-link class="liPaths" v-if="single.to" :key="`allpath-${index}`" :to="single.to">{{$translate(single.name)}}</router-link>
        <a class="noHover liPaths" :key="`allpath-${index}`" v-if="!single.to">{{$translate(single.name)}}</a>
      </template>
    </section>
  </div>
</template>
<script lang="ts">
import { Component, ExtPartial } from '@/libs/lila-partial';
import { RouteRecord } from 'vue-router';

@Component
export default class SideScreenHeader extends ExtPartial {

  get parentLink(): RouteRecord {

    return this.$route.matched.find((link) => link.meta.parent === true);

  }

  get parentIndex() {

    return this.$route.matched.findIndex((index) => index.meta.parent === true);

  }

  get allPaths() {

    const { matched } = this.$route;
    const routes = matched.slice(
      matched.findIndex((single) => single.meta.parent) + 1,
    );

    return routes
      .map((single, index) => {

        if (this.$route.matched.length - 1 > this.parentIndex) {

          if (routes[0].meta.defaultRoute !== routes[routes.length - 1].name) {

            if (index + 1 < routes.length) {

              if (!single.name && !single.meta?.defaultRoute) return null;
              if (
                single.name === this.$route.name
                || single.meta?.defaultRoute === this.$route.name
              ) return null;

              return {
                name: single.name || single.meta.defaultRoute,
                to: { name: single.name || single.meta.defaultRoute },
              };

            }

            return { name: single.name };

          }

        }

        return false;

      })
      .filter((single) => single);

  }

}
</script>
<style lang="less" scoped>

.side-header {

  .basePadding;
  .multi(padding-top, 4);
  .multi(padding-bottom, 4);
  display: grid;
  grid-template-columns: 1fr 25px;
  align-items: center;
  background-color: @color1;
  color: @white;

  a {
    align-self: center;
    justify-self: end;

  }

  h1 {
    color: @white;
  }

  .icon {
    width: 35px;
    padding: 0;
  }

}
</style>
