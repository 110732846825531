<template>
  <article class="events-list-editor">

    <moduleList-partial v-if="!child" class="module-editor-navigation">
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('id')">{{$translate('id')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('textblock')">{{$translate('textblock')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('media')">{{$translate('media')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('callToAction')">{{$translate('callToAction')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('animation')">{{$translate('animation')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('textPosition')">{{$translate('text-position')}}</button-partial>
        </li>
      </moduleList-partial>

      <textblock-partial-editor v-if="child === 'textblock'" v-model="value.textblock" />

      <editor-content-group-partial v-if="child === 'id'">
        <input-partial v-model="value.id" />
      </editor-content-group-partial>

      <link-partial-editor v-if="child === 'callToAction'" v-model="value.link" />

      <editor-content-group-partial v-if="child === 'animation'">
        <radio-partial v-for="(animation, index) in animations" :key="`variant-radio-${index}`"  type="radio" name="target" v-model="value.animation" :defaultValue="animation.key">{{$translate(animation.name)}}</radio-partial>
      </editor-content-group-partial>

      <editor-content-group-partial v-if="child === 'textPosition'">
          <template v-for="(variant, index) in storyVariants">
            <checkbox-partial :key="`variant-checkbox-${index}`" :description="variant.description" type="text" :value="isSelected(variant.key)" @input="toggleVariant(variant.key)">
              {{$translate(variant.name)}}
            </checkbox-partial>
          </template>
      </editor-content-group-partial>

      <media-partial-editor v-if="child === 'media'" v-model="value.media" />

  </article>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { PartialEditorComponent } from '@/libs/PartialEditorComponent';
import StoryElement from '@/apps/editor/models/StoryElement.model';
import ArrayTools from '@/libs/lila-array';

@Component
export default class EventsListElementEditor extends PartialEditorComponent<StoryElement> {

  itemModel = 'story-element';

  get storyVariants () {

    return this.additionalData.storyVariants as {key: string, name: string, description?: string}[];

  }

  get animations () {

    return this.additionalData.animations as {key: string, name: string, description?: string}[];

  }

  isSelected(key: string) {

    return this.value?.variant.includes(key);

  }

  toggleVariant(key: string) {


  if (this.value?.variant.includes(key)) {

    ArrayTools.remove(this.value.variant.indexOf(key), this.value.variant);

  } else {

    this.value.variant.push(key);

  }

  }


}

</script>
