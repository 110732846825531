<script setup lang="ts">
import { ref, computed, onBeforeMount } from 'vue';
import type { SDKResponse } from '@/libs/StudioSDK';
import type { BasicData, ImportJob } from '@lilaquadrat/interfaces';
import type { Dictionary } from 'vue-router/types/router';
import { useStore } from '@/store/main.store';
import { useRoute } from 'vue-router/composables';
// // Optionally set the component name (if your build setup supports defineOptions)
// defineOptions({
//   name: 'app-importadmin-project-single'
// });

// Access the Vuex store
const store = useStore();
const route = useRoute();
// Local reactive state
// Placeholder for navigationAnimation (e.g. provided by a mixin in ExtComponent)
const navigationAnimation = ref('');
// Computed property for the content (from the Vuex store)
const content = computed<BasicData<ImportJob>>(() => store.state.AppImportadmin.job);
// The createImport function dispatches the import action

// Preload functions (exposed so that external logic can call them)
function preloadDataPre(
  params: Dictionary<string>,
) {

  return store.dispatch('AppImportadmin/singleJob', params.id);

}

const preloadDataPost = (
  preloadedData: SDKResponse<BasicData<ImportJob>>,
) => {

  store.commit('AppImportadmin/job', preloadedData.data);
  return Promise.resolve();

};

onBeforeMount(async () => {

  // Preload the data
  const preloadData = await preloadDataPre(route.params);

  await preloadDataPost(preloadData);

});


</script>
<template>
  <article class="app-importadmin-single-screen" :class="navigationAnimation">
    <side-screen-header-partial>
      {{ $translate('app-importadmin-single-job-title') }}
    </side-screen-header-partial>
    <section class="content-container">
      <data-grid-partial class="content" v-if="content">
        <section class="data-grid-container">
          <section class="data-grid-single">
            <h4>{{ $translate('app-importadmin-created-at') }}</h4>
            <p>{{ $date(content.history.created) }}</p>
          </section>
          <section class="data-grid-single">
            <h4>{{ $translate('app-importadmin-updated-at') }}</h4>
            <p>{{ $date(content.history.updated) }}</p>
          </section>
          <section class="data-grid-single full">
            <h4>{{ $translate('app-importadmin-state') }}</h4>
            <p>{{ content.state }}</p>
          </section>
          <section class="data-grid-single">
            <h4>{{ $translate('app-importadmin-company') }}</h4>
            <p>{{ content.targetCompany }}</p>
          </section>
          <section class="data-grid-single">
            <h4>{{ $translate('app-importadmin-project') }}</h4>
            <p>{{ content.targetProject }}</p>
          </section>
          <section class="data-grid-single full">
            <h4>{{ $translate('app-importadmin-selected-services') }}</h4>
            <p>{{ content.services.join(', ') }}</p>
          </section>
        </section>

      </data-grid-partial>
    </section>
  </article>
</template>
<style lang="less">


.app-importadmin-single-screen {

  .content {
    display: grid;
    gap: 20px;

    .services-selection {
      display: grid;
      gap: 5px;
    }
  }

  .action {
    display: grid;
    gap: 20px;
  }
}
</style>
