<template>
  <article class="app-hostingadmin-single-screen" :class="navigationAnimation">
    <side-screen-header-partial>{{$translate('app-hostingadmin-single-hosting-title')}}</side-screen-header-partial>
    <section class="content-container">

      <data-grid-partial class="content">
        <section class="data-grid-container">
            Place Data here
            
        </section>
      </data-grid-partial>


    </section>
  </article>
</template>

<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import { SDKResponse } from '@/libs/StudioSDK';
import { Publish } from '@lilaquadrat/studio/lib/interfaces';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppHostingAdminSettingsScreen extends ExtComponent {

  componentName = 'app-hostingadmin-company-single';

  get content(): Publish {

    return this.$store.state.AppHostingAdmin.single;

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store
      .dispatch('AppHostingAdmin/single', {
        _id: params.id,
        settings: {
          project: params.project,
          company: params.company,
        },
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<HostingAdmin>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppHostingAdmin/single', preloadedData.data);
    return Promise.resolve();

  }


}
</script>
<style lang="less">


.app-hostingadmin-single-screen {


}
</style>
