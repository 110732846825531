<template>
  <article class="app-tracker-single-screen" :class="navigationAnimation">

    <side-screen-header-partial>{{$translate('app-tracker')}}</side-screen-header-partial>

    <section class="content-container">
      <data-grid-partial class="content">

        <section class="data-grid-container">

          <section class="data-grid-single">
            <h4>{{$translate('username')}}</h4>
            <p>{{tracker.user.lastname}}, {{tracker.user.prename}}</p>
          </section>

          <section class="data-grid-single">
            <h4>{{$translate('app-tracker-category')}}</h4>
            <p>{{$translate(`app-tracker-category-${tracker.category}`)}}</p>
          </section>

          <section class="data-grid-single">
            <h4>{{$translate('app-tracker-description')}}</h4>
            <p>
              <template v-if="!tracker.description">{{$translate('app-tracker-no-description')}}</template>
              {{tracker.description}}
            </p>
          </section>

          <section class="data-grid-single">
            <h4>{{$translate('app-tracker-currentTime')}}</h4>
            <app-tracker-time-partial text :value="tracker.currentTime" />
          </section>

          <section class="data-grid-single full">
            <h4>{{$translate('app-tracker-startEnd')}}</h4>
            <p>{{$date(tracker.startTime, 'DD.MM.YYYY')}} {{$date(tracker.startTime, 'HH:mm:ss')}} - {{$date(tracker.endTime, 'HH:mm:ss')}} ( {{ $date(tracker.startTime, '', true) }} )</p>
          </section>
        </section>

        <section v-if="scope(['tracker:admin']) || canEditOwn && model" class="data-grid-container">

          <section class="data-grid-single full edit">

            <section v-if="edit" class="edit-container">

              <textarea-partial v-if="scope(['tracker:admin']) || canEditOwn" v-model="model.description" max-length="200" placeholder="app-tracker-description">{{$translate('app-tracker-description')}}</textarea-partial>
              <datepicker-partial v-if="scope(['tracker:admin'])" range time :from="model.startTime" :to="model.endTime" @updateTo="(toDate) => model.endTime = toDate" @updateFrom="(fromDate) => model.startTime = fromDate" />

              <action-notice-partial :state="state" translation-pre="app-tracker" :errors="errors" @update="updateErrors">
                <button-partial v-if="scope(['tracker:admin']) || scope(['tracker:edit'])" class="base colorScheme2" doublecheck @confirmed="remove">{{$translate('app-tracker-permissions-remove')}}</button-partial>
                <button-partial v-model="state" save @save="save">{{$translate('SAVE_CHANGES')}}</button-partial>
              </action-notice-partial>

            </section>
          </section>

        </section>

        <button-group-partial v-if="!edit">
          <button-partial v-if="canEditOwn || scope(['tracker:admin'])" @click="toggleEdit">{{$translate('edit')}}</button-partial>
        </button-group-partial>

      </data-grid-partial>


    </section>

  </article>
</template>
<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import {
  Tracker, TrackerCategories,
} from '@lilaquadrat/studio/lib/interfaces';
import MainStoreState from '@/store/mainStoreState.interface';
import dayjs from 'dayjs';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';
import duration from 'dayjs/plugin/duration';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import { ErrorObject } from 'ajv';
import { ErrorsObject } from '@/libs/ActionNotice';
import ModelsClass from '@/libs/Models.class';
import DateRange from '../../../interfaces/DateRange.interface';

dayjs.extend(duration);

@Component
export default class AppTrackerHomeScreen extends ExtComponent {

  componentName = 'app-tracker-project-single';

  model: Tracker = null;

  state: string = '';

  errors: {message?: string, errors?: ErrorObject[]} = {};

  errorsObject: ErrorsObject = {};

  edit: boolean = false;


  mounted() {

    this.setModel();

  }

  setModel() {

    // this.model = new TrackerModel().setData(this.tracker, true, 'add');
    this.model = ModelsClass.add(this.tracker, 'tracker');

  }

  toggleEdit() {

    this.edit = !this.edit;

  }

  get canEditOwn() {

    return this.scope(['tracker:edit']) && this.tracker.user.id === this.$store.state.user.id;

  }

  get range(): DateRange {

    return { from: this.model.startTime as Date, to: this.model.endTime as Date };

  }

  set range(range: DateRange) {

    this.model.startTime = range.from;
    this.model.endTime = range.to;

  }

  async remove() {

    try {

      await this.$store.dispatch('AppTracker/remove', this.$route.params.id);

    } catch (error) {

      console.error(error);

    }

  }

  async save() {

    const cleanData = ModelsClass.save(this.model, 'tracker');
    let trackerData: Partial<Tracker> = {
      description: cleanData.description,
    };
    let action: string = 'AppTracker/updateName';

    this.state = '';


    if (this.scope(['tracker:admin'])) {

      trackerData = {
        description: cleanData.description,
        category: cleanData.category as TrackerCategories,
        startTime: dayjs(cleanData.startTime).toDate(),
        endTime: dayjs(cleanData.endTime).toDate(),
      };

      action = 'AppTracker/update';

    }

    try {

      await this.$store.dispatch(action, { id: this.$route.params.id, tracker: trackerData });
      StudioSDK.flushCache('tracker', 'single');

      this.state = 'success';

      const single = await this.$store.dispatch('AppTracker/single', this.$route.params.id);

      this.$store.commit('AppTracker/single', single.data);
      this.setModel();

    } catch (error) {

      this.errors = error.response.data;
      this.state = 'error';

    }


  }

  updateErrors(errorsObject: ErrorsObject) {

    this.errorsObject = errorsObject;

  }


  get tracker(): Tracker {

    return this.$store.state.AppTracker.single || {};

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store.dispatch('AppTracker/single', params.id);

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<Tracker>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    console.log(preloadedData);
    store.commit('AppTracker/single', preloadedData.data);
    return Promise.resolve();

  }

}
</script>
<style lang="less" scoped>


.app-tracker-single-screen {

  .edit-container {
    display: grid;
    gap: 20px;
  }

}
</style>

