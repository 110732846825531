import GenericModel from '@/interfaces/GenericModel.interface';
import Models, { ModelDeclarationExtended, ModelOptions } from '@/libs/Models.class';
import Module from '@/models/Module.model';
import { GenericData } from '@lilaquadrat/interfaces';
import Link from '@/models/Link.model';
import ListLink from '@/models/ListLink.model';
import ListString from '@/models/ListString.model';
import Textblock from '../interfaces/textblock.interface';
import Media from './Media.model';

export default interface EventModule extends GenericModel {
  textblock: Textblock
  artist: string
  name: string
  location: string
  list: ListString
  links: ListLink
  callToAction: Link
  genericData: GenericData
  start: Date
  end: Date
  media: Media
}

const declaration: ModelDeclarationExtended<EventModule, Module> = {
  textblock: { model: 'textblock' },
  artist: { type: 'string' },
  name: { type: 'string' },
  location: { type: 'string' },
  media: { model: 'media' },
  list: { model: 'list-string' },
  links: { model: 'list-link' },
  genericData: { model: 'generic-data' },
  start: { type: 'Date' },
  end: { type: 'Date' },
  callToAction: { model: 'link' },
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('event-module', declaration, undefined, options);