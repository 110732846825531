import GenericStore from '@/interfaces/GenericStore.interface';
import log from 'loglevel';
import NavigationStoreState from './NavigationState.interface';

let loadingTimeout;
const NavigationStore: GenericStore<NavigationStoreState> = {
  namespaced: true,
  strict: true,

  state: {
    status: 'done',
    locked: false,
    ready: false,
    currentComponent: null,
    targetComponent: null,
    contentUpdate: null,
    appEntry: null,
    activeElement: null,
    // parents: [],
    init: false,
    animationTime: 0,
    animationTimeContentUpdate: 0,
  },

  mutations: {

    init(state) {

      state.init = true;

    },

    enableAnimation(state) {

      state.animationTime = 400;
      state.animationTimeContentUpdate = 150;

    },

    activeElement(state, activeElement: string) {

      state.activeElement = activeElement;

    },

    start(state) {

      state.status = 'started';

    },

    resolving(state) {

      state.status = 'resolving';

    },

    loading(state) {

      state.status = 'loading';

    },

    done(state) {

      state.status = 'done';

    },

    lock(state) {

      state.locked = true;

    },

    unlock(state) {

      state.locked = false;

    },

    ready(state, ready: boolean) {

      state.ready = ready;

    },

    component(state, name: string) {

      state.currentComponent = name;

    },

    targetComponent(state, name: string) {

      state.targetComponent = name;

    },

    contentUpdate(state, isContentUpdate: boolean) {

      state.contentUpdate = isContentUpdate;

    },

    appEntry(state, isAppEntry: boolean) {

      state.appEntry = isAppEntry;

    },

    // parents(state, parents: string[]) {

    //   state.parents = parents;

    // },

  },

  actions: {

    /**
         *
         *
         * @returns
         */
    start() {

      this.commit('Navigation/start');
      this.commit('Navigation/ready', false);


      return Promise.resolve();

    },

    /**
         * only if the state is ready, the navigation will be finished.
         * loading data / the async function of the target component will trigger this and the loading spinner after 3500ms
         * if the spinner was shown is the first place.
         *
         * @param {*} store
         */
    tryDone(store) {

      if (store.state.ready) {

        this.commit('Navigation/done');
        clearTimeout(loadingTimeout);

      } else {

        this.commit('Navigation/ready', true);

      }

    },

    /**
         * will be called after resolving
         *
         * @link resolving
         * @param {GenericStore<NavigationStoreState>} store
         * @returns
         */
    done(store) {


      return new Promise<void>((resolve) => {

        setTimeout(() => {

          if (store.state.status === 'resolving') {

            this.dispatch('Navigation/tryDone');
            clearTimeout(loadingTimeout);

            return resolve();

          }

          resolve();
          return true;

        }, store.state.contentUpdate ? store.state.animationTimeContentUpdate : store.state.animationTime);

      });

    },

    /**
         * this gets called when the async function of the target component is done
         * or no async function exists.
         *
         * @param {GenericStore<NavigationStoreState>} store
         * @returns
         */
    resolving(store) {


      return new Promise<void>((resolve) => {

        setTimeout(() => {

          this.dispatch('Navigation/tryDone');

          if (store.state.status !== 'loading') {

            this.commit('Navigation/resolving');

          }

          resolve();
          return true;

        }, store.state.contentUpdate ? store.state.animationTimeContentUpdate : store.state.animationTime);

      });

    },

    /**
         * if the async function of the target component has not finished in 2000ms,
         * we are going into the 'loading' state. this state will be active at least 3500ms.
         * after the 3500ms the tryDone function will be called.
         * if the async function has already finished, the navigation will be done.
         * if the async function is not yet done, the status of the navigation will be set to 'ready' and
         * the navigation will be done after async function has finished.
         *
         * @param {*} store
         * @returns
         */
    loading(store) {

      return new Promise<void>((resolve) => {

        loadingTimeout = setTimeout(() => {

          if (store.state.status === 'started') {

            this.commit('Navigation/loading');
            this.commit('Navigation/lock');

            setTimeout(() => {

              this.commit('Navigation/unlock');
              this.dispatch('Navigation/tryDone');

            }, 2500);

          }

          resolve();


        }, 1500);

      });

    },

  },

  getters: {
    navigationState(state) {

      return state.status;

    },
  },

};

export default NavigationStore;
