<script setup lang="ts">
import hardCopy from '@/mixins/hardCopy';
import { CompanyWithProjectsPermissions, ProjectPermissions, Permissions } from '@lilaquadrat/studio/interfaces';
import vue, { computed, ref , onMounted, getCurrentInstance } from 'vue';
import { useStore } from '@/store/main.store';
import Favorites from '@/interfaces/Favorites.interface';
import ArrayTools from '@/libs/lila-array';

const vm = getCurrentInstance().proxy;
const store = useStore();
const props = defineProps<{
  company?: string;
  isHidden?: boolean;
  noEdit?: boolean;
  noLink?: boolean;
  showFavorites?: boolean
}>();
const favorites = ref<Favorites>({});
const hidden = ref<Favorites>({});
const hiddenCompanies = ref<string[]>([]);
const changes = ref(false);
const editMode = ref(false);
const permissions = computed<Permissions>(() => store.state.permissionsAll);
const $translate = ((text: string, values?: (string | number)[]) => vm.$translate(text, values));
const permisionsCompanySensitive = computed(() => {

    if(!props.company) return permissions.value;
    
    return permissions.value.filter((single) => single._id === props.company);

});
const permissionsRender = computed(() => {

    const targetArray: CompanyWithProjectsPermissions<ProjectPermissions & { isHidden?: boolean, isFavorite?: boolean}>[] = [];
    const permissionsSafe = hardCopy<CompanyWithProjectsPermissions<ProjectPermissions & { isHidden?: boolean, isFavorite?: boolean}>[]>(permisionsCompanySensitive.value);


    permissionsSafe.forEach((company) => {

        let companyProjectsArray = [];

        /**
        * if not in editMode hide hidden projects and remove empty companies
        */
        if(!editMode.value) {

            // check if the complete company is hidden
            if(hiddenCompanies.value.includes(company._id)) return;

            if(hidden.value[company._id]?.length) {

                companyProjectsArray = company.projects.filter((project) => hidden.value[company._id].findIndex((singleHidden) => project.id === singleHidden.id) === -1);

            } else {

                companyProjectsArray = company.projects;
            
            }


            targetArray.push({...company, projects: companyProjectsArray});


        } else {

            /**
            * in editmode mark projects as hidden and favorite
            */
            company.projects.forEach((single) => {

                if(hidden.value[company._id]?.length) {
                
                    single.isHidden = hidden.value[company._id].findIndex((singleHidden) => single.id === singleHidden.id) > -1;
                    
                }
                
                if(favorites.value[company._id]?.length) {
                    
                    single.isFavorite = favorites.value[company._id].findIndex((singleFavorite) => single.id === singleFavorite.id) > -1;

                }

            });


            targetArray.push(company);

        }

    
    });

    return targetArray;

});
const favoritesRender = computed(() => {

    const keys = Object.keys(favorites.value);

    if(!keys.length) return null;

    const targetArray: { apps?: (Partial<ProjectPermissions> & {mode?: 'app' | 'project', to?: {name: string}})[], projects?: (Partial<ProjectPermissions> & {mode?: 'app' | 'project', to?: {name: string}})[]} = {apps: [], projects: []};

    keys.forEach((key) => {

        const favoriteObjects = favorites.value[key];
        const company = permissions.value.find((single) => single._id === key);

        favoriteObjects.forEach((single) => {

            if(single.mode ===  'app') {

                targetArray.apps.push({...single, name: single.id, to: {name: `app-${single.id}-company-home`}});

            }

            if(single.mode ===  'project') {

                targetArray.projects.push({...company.projects.find((singleProject) => single.id === singleProject.id), mode: 'project'});

            }

        });

    });

    targetArray.apps.sort((a, b) => a.name.localeCompare(b.name));
    targetArray.projects.sort((a, b) => a.name.localeCompare(b.name));

    return [...targetArray.apps, ...targetArray.projects];

});
const updateStore = () => {

    const baseObject: Record<string, unknown> = {};

    if(!props.company) {

        baseObject.homeSettings = {favorites: favorites.value, hidden: hidden.value, hiddenCompanies: hiddenCompanies.value};
    
    } else {

        console.log('update company Settings', hidden.value);
        /**
         * if company is set use the already existing companySettings as based or a empty object
         * so multiple companies can be stored
         */
        baseObject.companySettings = hardCopy(store.state.Settings.settings.companySettings || {});
        baseObject.companySettings[props.company] = {favorites: favorites.value, hidden: hidden.value, hiddenCompanies: hiddenCompanies.value};

    }

    store.commit('Settings/update', hardCopy(baseObject));
    changes.value = true;

};
const toggle = (id: string, name: string, company: string | undefined, type: 'favorites' | 'hidden', mode: 'app' | 'project') => {

    const useCompany = company || props.company;
    const base = type === 'favorites' ? favorites : hidden;

    if(!base.value[useCompany]) vue.set(base.value, useCompany, []);

    const foundIndex = base.value[useCompany].findIndex((single) => single.id === id && single.mode === mode);

    if(foundIndex > -1) {

        base.value[useCompany].splice(foundIndex, 1);

        if(!base.value[useCompany].length) delete base.value[useCompany];

    } else {

        base.value[useCompany].push({id, name, mode});

    }

    updateStore();

};
const toggleCompany = (company: string) => {

    if(hiddenCompanies.value.includes(company)) {

        ArrayTools.findAndRemove(company, hiddenCompanies.value);

    } else {

        hiddenCompanies.value.push(company);

    }

    updateStore();

};
const toggleEditMode = () => {

    editMode.value = !editMode.value;

    if(changes.value) {

        store.dispatch('Settings/sync');

    }

};

onMounted(() => {

    let useSettings = hardCopy(store.state.Settings.settings?.homeSettings || {});

    // add or use companySettings as base when in company mode
    if(props.company) {

        const companySettings = hardCopy(store.state.Settings.settings?.companySettings || {});

        if(!companySettings) {

            const newSettings = {};

            newSettings[props.company] = {};

            useSettings = newSettings; 
        
        } else {

            useSettings = companySettings[props.company];

        }
        
    } else {

        useSettings = store.state.Settings.settings?.homeSettings 
            ? hardCopy(store.state.Settings.settings?.homeSettings) 
            : null;
    
    }


    if(useSettings) {

        favorites.value = useSettings.favorites || {};
        hidden.value = useSettings.hidden || {};
        hiddenCompanies.value = useSettings.hiddenCompanies || [];

    }

});

</script>
<template>
    <article :class="{edit: editMode}" class="companies-module">
        <section class="controls">
            <button-partial :variant="['iconColor']" button-content-type="icon" :color-scheme="isHidden ? 'icon-red' : 'icon-color1'" icon="edit" @click="toggleEditMode" />
        </section>

        <projects-group-module v-if="favoritesRender && showFavorites">

            <template #title>
              <projects-group-title-partial noLink noEdit>{{$translate('favorites')}}</projects-group-title-partial>
            </template>

            <template v-for="(favorite, projectIndex) in favoritesRender">

              <project-link-partial v-if="!editMode && favorite.mode === 'project'" :key="favorite.id" :edit-mode="editMode" :to="{name: 'project-home', params: {company: favorite.company, project: favorite.id}}">
                <span class="name">{{favorite.name}}</span>
                <span v-if="!company" class="company">{{favorite.company}}</span>
              </project-link-partial>

              <app-link-partial v-if="!editMode && favorite.mode === 'app'" :key="favorite.id" :name="favorite.id" :to="favorite.to">
                <span class="name">{{favorite.name}}</span>
                <span v-if="!company" class="company">{{favorite.company}}</span>
              </app-link-partial>
              
              <project-link-edit-partial v-if="editMode" v-bind="favorite" :key="`project-link-edit-${projectIndex}`" favorite-mode @toggle="toggle($event.id, $event.name, favorite.company, $event.type, favorite.mode)" />

            </template>

        </projects-group-module>

        <apps-module v-if="props.company" :hidden="hidden" :favorites="favorites" :company="props.company" :editMode="editMode" @toggle="toggle($event.id, $event.name, company, $event.type, 'app')" />

        <projects-group-module v-for="(single, index) in permissionsRender" :key="`permission_${index}`">

            <template #title>
                <projects-group-title-partial v-if="!company" :editMode="editMode" :isHidden="hiddenCompanies.includes(single._id)" :company="single._id" @toggleCompany="toggleCompany($event)">{{single.name}}</projects-group-title-partial>
                <h1 v-if="company">{{ $translate('projects') }}</h1>
            </template>

            <template v-for="(project, projectIndex) in single.projects">

                <project-link-partial v-if="!editMode" :key="project.id" :edit-mode="editMode" :to="{name: 'project-home', params: {company: single._id, project: project.id}}">
                    {{project.name}}
                </project-link-partial>
                
                <project-link-edit-partial v-if="editMode" v-bind="project" :key="`project-link-edit-${projectIndex}`" @toggle="toggle($event.id, $event.name, $event.company, $event.type, 'project')" />

            </template>
        </projects-group-module>

    </article>
</template>
<style lang="less" scoped>
.companies-module {

  position: relative;
  display: grid;
  gap: 50px 0;

  align-items: start;

  .controls {
    position: fixed;
    top: 35px;
    right: 0;

    display: grid;
    justify-items: end;

    width: 100%;
    line-height: 35px;
    .basePadding;
    .index(4);
    .multi(padding-top, 2);
    .multi(padding-bottom, 2);
  }

  .projects-group-module, .app-drawer {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  h1 {
    .basePadding;
    font-size: @headline_S;
    text-transform: none;
  }
}
</style>