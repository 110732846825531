import Models, { ModelDeclaration } from '@/libs/Models.class';

export default interface ProjectEditor {
  studioURL: string
}

const declaration: ModelDeclaration<ProjectEditor> = {
  studioURL: { type: 'string' },
};

Models.register('project-editor', declaration);