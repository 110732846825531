<template>
  <section class="app-tracker-range-statistics-partial" :class="{ inline, noHeadlineMobile }">

    <h2 v-if="hasTitle" class="title"><slot /></h2>

    <section class="range-statistic-container">

      <section v-for="(statistics, index) in monthStatistics" class="range-statistic" :key="`statistics-single-${index}`">
        <span class="title">{{$translate(`app-tracker-statistics-${statistics.key}`)}}</span>
        <span class="count">({{statistics.count}})</span>
        <app-tracker-time-partial seconds :includeDays="includeDays" :value="statistics.time" />
      </section>

    </section>


  </section>
</template>
<script lang="ts">
import { ExtPartial } from '@/libs/lila-partial';
import {
  TrackerCategories, TrackerData,
} from '@lilaquadrat/studio/lib/interfaces';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class AppTrackerDayStatisticsPartial extends ExtPartial {

  @Prop() value: Partial<Record<TrackerCategories, TrackerData>>;

  @Prop(Boolean) inline: boolean;

  @Prop(Boolean) includeDays: boolean;

  @Prop(Boolean) noHeadlineMobile: boolean;

  get hasTitle() {

    return !!this.$slots.default?.length;

  }

  get monthStatistics() {

    const statisticsArray: {key: string, count: number, time: string}[] = [];

    if (this.value) {

      Object.keys(this.value).forEach((key) => {

        const singleStatistics = this.value[key];

        statisticsArray.push({ count: singleStatistics.count, time: singleStatistics.time, key });

      });

    }


    return statisticsArray;

  }

}
</script>
<style lang="less" scoped>


.app-tracker-range-statistics-partial {
  display: grid;
  grid-auto-rows: min-content;
  gap: 10px;

  .title {
    font-size: @headline_XS;

    @media @desktop {
      display: grid;
    }
  }

  &.noHeadlineMobile {

    .title {
      display: none;

      @media @desktop {
        display: grid;
      }
    }
  }

  .range-statistic-container {
    display: grid;
    grid-auto-rows: min-content;
    gap: 5px;

    .range-statistic {
      display: grid;
      grid-template-columns: min-content min-content 1fr;
      gap: 5px;

      span {
        display: grid;

        color: @textColor;
        font-size: @fontTextSmaller;
        font-variant-numeric: tabular-nums;
        .font-head;

        &.app-tracker-time-partial {
          justify-content: end;
        }
      }
    }

  }

  &.inline {

    .range-statistic-container {

      .range-statistic {
        display: grid;
        grid-template-columns: min-content min-content 1fr;
        gap: 5px;

        span {
          display: grid;

          color: @textColor;
          font-size: @fontTextSmaller;
          font-variant-numeric: tabular-nums;
          .font-normal;

          &.app-tracker-time-partial {
            justify-content: start;
          }
        }
      }

    }

  }

}

</style>
