<template>
  <section class="save-partial">
    <notice-partial v-if="finished && message" :title="`ERROR-${httpStatus}`" class="padding" type="error">
      {{$translate(message)}}
    </notice-partial>

    <button-group-partial>
      <button-partial ref="saveButton" class="base colorScheme1" @save="save" @finished="done" save v-model="state">{{$translate('save')}}</button-partial>
      <slot />
    </button-group-partial>
  </section>
</template>
<script lang="ts">
import { ExtPartial, Prop } from '@/libs/lila-partial';
import { CallResponse } from '@lilaquadrat/studio/lib/interfaces';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class savePartial extends ExtPartial {

  @Prop(Function) callback: () => Promise<CallResponse<any>>;

  state: string = '';

  message: string = '';

  finished: boolean = true;

  httpStatus: number = 0;

  done() {

    this.finished = true;

    if (this.message) {

      this.$nextTick(() => {

        const button = this.$refs.saveButton as Vue;

        button.$el.scrollIntoView();

      });

    }


  }

  async save() {

    this.finished = false;
    this.httpStatus = 0;
    this.message = '';

    try {

      await this.callback();

    } catch (error) {

      this.state = 'error';
      this.message = error.r.message;
      this.httpStatus = error.status;

    }

    if (this.state !== 'error') {

      this.state = 'success';

    }


  }


}
</script>
<style lang="less" scoped>


.save-partial {

  display: grid;
  gap: 10px;

}
</style>
