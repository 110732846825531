<template>
  <section class="colors label-replacement">
    <div class="color-container">
      <label v-for="(color, index) in options" v-bind:key="`color-${index}`">
        <input
          type="checkbox"
          :id="`value-${index}`"
          :value="color.value"
          v-model="selected"
          @change="updateValue"
        />
        <span class="icon" :style="{ 'background-color': color.hex }">I</span>
      </label>
    </div>

    <span class="label"><slot /></span>
  </section>
</template>

<script lang="ts">
import { ExtComponent, Component, Prop } from '@/libs/lila-component';

@Component
export default class colorsPartial extends ExtComponent {

  @Prop(Array) options: [];

  @Prop(Array) value: [];

  selected = [];

  mounted() {

    this.selected = this.value;

  }

  updateValue() {

    this.$emit('input', this.selected);

  }

}
</script>
