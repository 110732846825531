<template>
    <quickmenu-partial class="app-hostingadmin-quickmenu">
        <router-link :title="$translate('app-hostingadmin-commands-add')" :aria-label="$translate('app-hostingadmin-commands-add')" class="main icon" :to="{ name: 'app-hostingadmin-company-commands-add' }"><icons-partial type="add" size="large" /></router-link>
        <router-link :title="$translate('app-hostingadmin-commands')" :aria-label="$translate('app-hostingadmin-commands')" class="main icon" :to="{ name: 'app-hostingadmin-company-commands-home' }"><icons-partial type="server" size="large" /></router-link>
        <router-link :title="$translate('app-hostingadmin-domains')" :aria-label="$translate('app-hostingadmin-domains')" class="main icon" :to="{ name: 'app-hostingadmin-company-domains-home' }"><icons-partial type="editor-add" size="large" /></router-link>
        <router-link :title="$translate('app-hostingadmin-certificatesActions')" :aria-label="$translate('app-hostingadmin-certificatesActions')" class="main icon" :to="{ name: 'app-hostingadmin-company-certificatesActions-home' }"><icons-partial type="statistics" size="large" /></router-link>
        <router-link :title="$translate('app-hostingadmin-settings')" :aria-label="$translate('app-hostingadmin-settings')" class="main icon" :to="{ name: 'app-hostingadmin-company-settings-overview' }"><icons-partial type="settings" size="large" /></router-link>
    </quickmenu-partial>
</template>
  
<script lang="ts">
import { Component } from '@/libs/lila-component';
import { ExtPartial } from '@/libs/lila-partial';

@Component
// eslint-disable-next-line global-require
export default class AppHostingAdminQuickmenu extends ExtPartial {


}

</script>
<style lang="less" scoped>
.app-hostingadmin-quickmenu {

  a {

    &.icon {

      &.router-link-active {
        opacity: .5;
      }

    }

  }

}
</style>