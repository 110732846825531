import Models, { ModelDeclarationExtended, ModelOptions } from '@/libs/Models.class';
import LinkGroupElement from '@/models/LinkGroupElement.model';
import Module from '@/models/Module.model';
import PicturePartial from '@/models/PicturePartial.model';

export default interface MenuModelV1 extends Module {
  name: string
  picture: PicturePartial
  elements: LinkGroupElement[]
}

const declaration: ModelDeclarationExtended<MenuModelV1, Module> = {
  name: { type: 'string' },
  picture: { model: 'picture-partial' },
  elements: { type: 'array', contains: { model: 'linkgroup-element' } },
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  overwriteName: 'menu-module',
  extends: 'module'
};

Models.register('menu-module-v1', declaration, undefined, options);