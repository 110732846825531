import { UnifiedRouteGuard } from '@/libs/lila-auth';
import StructureHomeScreen from '@/screens/structure-home.screen.vue';
import StructureAddScreen from '@/screens/structure-single.screen.vue';
import StructureOverviewScreen from '@/screens/structure-overview.screen.vue';
import ImportScreen from './screens/import.screen.vue';
import HomeScreen from './screens/home.screen.vue';
import OverviewScreen from './screens/overview.screen.vue';
import EditScreen from './screens/edit.screen.vue';
import SingleScreen from './screens/single.screen.vue';

export default [
  {
    path: '/:company/a/customers/import/:site?',
    name: 'app-customers-company-import',
    beforeEnter: UnifiedRouteGuard,
    component: ImportScreen,
    meta: {
      animationGroup: 'customers',
      app: 'customers',
      parent: true,
      rememberSettings: true,
    }
  },
  {
    path: '/:company/a/customers/:site?',
    name: 'app-customers-company-home',
    beforeEnter: UnifiedRouteGuard,
    component: HomeScreen,
    meta: {
      animationGroup: 'customers',
      app: 'customers',
      parent: true,
      rememberSettings: true,
    },
    children: [
      {
        path: 'structures',
        component: StructureOverviewScreen,
        meta: {
          sidescreen: true,
          defaultRoute: 'app-customers-company-structures-home',
          app: 'customers',
          model: 'customers',
          linkBase: 'app-customers-company'
        },
        children: [
          {
            path: 'add',
            component: StructureAddScreen,
            name: 'app-customers-company-structures-add',
            meta: {
              sidescreen: true,
              app: 'customers',
              model: 'customers',
              linkBase: 'app-customers-company'
            },
          },
          {
            path: 'edit/:id',
            component: StructureAddScreen,
            name: 'app-customers-company-structures-edit',
            meta: {
              sidescreen: true,
              app: 'customers',
              model: 'customers',
              linkBase: 'app-customers-company'
            },
          },
          {
            path: ':site?',
            component: StructureHomeScreen,
            name: 'app-customers-company-structures-home',
            meta: {
              sidescreen: true,
              app: 'customers',
              model: 'customers',
              linkBase: 'app-customers-company'
            },
          },
        ]
      },
      {
        path: 'single/:id',
        component: OverviewScreen,
        meta: {
          sidescreen: true,
          defaultRoute: 'app-customers-company-single',
          app: 'customers',
        },
        children: [
          {
            path: 'edit',
            component: EditScreen,
            name: 'app-customers-company-edit',
            meta: {
              sidescreen: true,
              defaultRoute: 'app-customers-company-edit',
              app: 'customers',
            },
          },
          {
            path: '',
            component: SingleScreen,
            name: 'app-customers-company-single',
            meta: {
              sidescreen: true,
              defaultRoute: 'app-customers-company-single',
              app: 'customers',
            },
          },
        ],
      },
      {
        path: 'add',
        component: OverviewScreen,
        meta: {
          sidescreen: true,
          defaultRoute: 'app-customers-company-add',
          app: 'customers',
        },
        children: [
          {
            path: '',
            component: EditScreen,
            name: 'app-customers-company-add',
            meta: {
              sidescreen: true,
              defaultRoute: 'app-customers-company-add',
              app: 'customers',
            },
          },
        ],
      },
    ],
  }
];
