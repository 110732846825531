/**
 * Recursively searches through an object and finds all objects that contain any of the specified keys.
 *
 * ```typescript
 * const obj = {
 *   foo: { bar: 1 },
 *   baz: { bar: 2 }
 * };
 * const keys = ['bar'];
 * findObjectsWithKeys(obj, keys); // Returns [{ bar: 1 }, { bar: 2 }]
 * ```
 */
export default function findObjectsWithKeys<K extends string>(
  obj: unknown,
  keys: K[],
): (Record<string, unknown> & Record<K, unknown>)[] {


  if (!obj || typeof obj !== 'object') {

    return [] as (Record<string, unknown> & Record<K, unknown>)[];

  }

  const found: unknown[] = [];

  // Check if current object has any of the requested keys
  if (keys.some(key => Object.prototype.hasOwnProperty.call(obj, key))) {

    found.push(obj);

  }

  // Recursively traverse nested objects
  return Object.values(obj)
    .reduce((acc, value) => {

      if (value && typeof value === 'object') {

        return acc.concat(findObjectsWithKeys(value, keys));

      }

      return acc;

    }, found);

}
