import { UnifiedRouteGuard } from '@/libs/lila-auth';
import HomeScreen from './screens/home.screen.vue';
import SingleScreen from './screens/single.screen.vue';

export default [
  {
    path: '/:company/a/appstore/:site?',
    name: 'app-appstore-company-home',
    beforeEnter: UnifiedRouteGuard,
    component: HomeScreen,
    meta: {
      parent: true,
      app: 'appstore',
      rememberSettings: true,
    },
    children: [
      {
        path: 'add',
        name: 'app-appstore-company-single-add',
        component: SingleScreen,
        meta: {
          sidescreen: true,
          name: 'company-settings-sidescreen',
          defaultRoute: 'app-appstore-company-single-add',
          app: 'appstore',
        },
      },
      {
        path: 'single/:app',
        name: 'app-appstore-company-single',
        component: SingleScreen,
        meta: {
          sidescreen: true,
          name: 'company-settings-sidescreen',
          defaultRoute: 'app-appstore-company-single',
          app: 'appstore',
        },
      },
    ],
  },
  {
    path: '/:company/:project/a/appstore/:site?',
    name: 'app-appstore-project-home',
    beforeEnter: UnifiedRouteGuard,
    component: HomeScreen,
    meta: {
      parent: true,
      app: 'appstore',
      rememberSettings: true,
    },
    children: [
      {
        path: '/:company/:project/a/appstore/add',
        name: 'app-appstore-project-single-add',
        component: SingleScreen,
        meta: {
          sidescreen: true,
          name: 'company-settings-sidescreen',
          defaultRoute: 'app-appstore-project-single-add',
          app: 'appstore',
        },
      },
      {
        path: '/:company/:project/a/appstore/:app',
        name: 'app-appstore-project-single',
        component: SingleScreen,
        meta: {
          sidescreen: true,
          name: 'company-settings-sidescreen',
          defaultRoute: 'app-appstore-project-single',
          app: 'appstore',
        },
      },
    ],
  },
];
