<template>
  <article :class="[navigationAnimation, { showRight }]" class="app-editor-history-screen">

    <section class="content-left-container history-view">
      <renderer-module :content-state="contentState" :content="single" />
      <section class="mobile-overlay" @click="toggleMode" @keyup="toggleMode" />
    </section>

    <section class="list-container content-right-container">
      <list-history-ext :id="currentId" :parent-content-animation="contentAnimation" :content="content" :current="version">
        <editor-history-options-partial />
      </list-history-ext>
      <section class="mobile-overlay" @click="toggleMode" @keyup="toggleMode" />
    </section>

  </article>
</template>

<script lang="ts">
import { ExtComponent, Component, Watch } from '@/libs/lila-component';
import { CallResponse, ListOfModels } from '@lilaquadrat/studio/lib/interfaces';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppEditorHistoryScreen extends ExtComponent {

  componentName = 'app-editor-project-history';

  contentState: 'ready' | 'loading' | 'error' = 'loading';

  showRight = false;

  @Watch('version')
  routeWatch() {

    this.getSingle(this.$store.state.Company.company, this.$store.state.Project.project, this.$route.params.id, this.version);

  }

  get content() {

    return this.$store.state.AppHistory.data;

  }

  get single() {

    return this.$store.state.AppHistory.single;

  }

  get currentId() {

    return this.$route.params.id;

  }

  get current() {

    return this.single?.history.version + 1;

  }

  get version() {

    const versionParam = this.$store.state.AppHistory.preview;

    if (versionParam === 0 || Number.isNaN(versionParam)) return 0;

    return versionParam || this.$store.state.AppHistory.data?.all - 1;

  }

  mounted() {

    this.getSingle(this.$store.state.Company.company, this.$store.state.Project.project, this.$route.params.id, this.version);

  }

  toggleMode() {

    this.showRight = !this.showRight;

  }

  async getSingle(company: string, project: string, id: string, version?: number) {

    console.log(company, project, id, version);

    this.contentState = 'loading';

    try {

      await this.$store.dispatch('AppHistory/single', {
        company,
        project,
        id,
        version,
      });

    } catch (error) {

      console.error(error);
      this.contentState = 'error';

    }


    this.contentState = 'ready';

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store.dispatch('AppHistory/list', {
      company: params.company,
      project: params.project,
      site: params.site,
      query: {
        model: 'editor',
        id: params.id,
      },
    });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: CallResponse<ListOfModels<History>>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppHistory/data', preloadedData.r);
    return Promise.resolve();

  }

}
</script>
<style lang="less" scoped>


.app-editor-history-screen {

  display: grid;
  grid-template-columns: calc(100vw - 40px) calc(100vw - 40px);
  overflow: hidden;
  width: 200vw;

  @media @tablet, @desktop {
    grid-template-columns: auto 380px;
    gap: 0 20px;
    width: unset;
  }

  .list-container::v-deep {
    display: grid;
    overflow: hidden;

    .scroll-container {
      display: grid;
      overflow-y: scroll;

      @media @tablet, @desktop {
        margin: 5px;
        box-shadow: 0 0 5px 0 @shadow;
      }
    }
  }

  .content-left-container, .content-right-container {
    position: relative;
    overflow: hidden;
    height: calc(100vh - 40px);
    transition: transform @aTimeFast @aType;

    .mobile-overlay {
      position: absolute;
      top: 0;
      left: 0;
      display: grid;
      width: 100%;
      height: 100%;
      background-color: @white;
      opacity: .6;
      .index(8);
      .trans(opacity);
      cursor: pointer;

      @media @tablet, @desktop {
        display: none;
      }
    }
  }

  .content-left-container {

    .mobile-overlay {
      opacity: 0;
      pointer-events: none;
    }
  }

  .content-right-container {

    .mobile-overlay {
      pointer-events: all;
    }
  }

  &.showRight {

    .content-left-container, .content-right-container {
      transform: translateX(calc(-100% + 40px));

      @media @tablet, @desktop {
        transform: translateX(0);
      }

    }

    .content-left-container {

      .mobile-overlay {
        opacity: .6;
        pointer-events: all;
      }
    }

    .content-right-container {

      .mobile-overlay {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  // .history-content-container {

  //   display: grid;
  //   grid-template-rows: 1fr;
  //   grid-template-columns: 1fr 380px;

  //   gap: 0 20px;

  //   height: 100%;

  //   height: calc(100vh - 40px);

  //   .list-container {
  //     display: grid;
  //     overflow: hidden;

  //     .scroll-container {
  //       display: grid;
  //       overflow-y: scroll;
  //       margin: 5px;
  //       box-shadow: 0 0 5px 0 @shadow;
  //     }
  //   }

  // }
}
</style>
