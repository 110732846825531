<template functional>
  <section class="content-section" :class="data.staticClass || ''">
    <slot />
  </section>
</template>
<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@Component({ functional: true })
export default class contentSectionPartial extends Vue {}
</script>
<style lang="less">


.content-section {
  display: block;

  header {
    display: block;

    h1 {
      font-size: @fontText;
      color: @color1;
      display: grid;
      gap: 20px;
      grid-template-columns: max-content min-content;

      a {
        text-transform: none;
        font-size: @fontTextSmaller;
      }
    }
  }

  .content-module {
    .multi(padding-top, 4);
    // display: grid;
    // gap: 20px;
    // align-items: start;
  }
}
</style>
