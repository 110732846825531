import InstallableApp from '@/interfaces/InstallableApp.interface';
import routes from './routes';
import translations from './translations/translations';

const app: InstallableApp = {
  name: 'learn',
  publisher: 'lilaquadrat',
  translations,
  routes,
  store: 'AppLearn',
};

export default app;
