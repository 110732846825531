const specialCharMap: Record<string, string> = {
    '@': 'att', '&': 'and', '%': 'pct', '$': 'dlr', '+': 'pls', '=': 'equ',
    '#': 'hsh', '/': 'slh', '\\': 'bsl', '*': 'str', '!': 'exc', '?': 'qst',
    '^': 'crt', '~': 'tld', '<': 'lss', '>': 'gtr', '|': 'pip', '"': 'quo',
    '\'': 'apo', ':': 'cln', ';': 'scn', '.': 'dot', ',': 'cma', '(': 'lpr',
    ')': 'rpr', '[': 'lbr', ']': 'rbr', '{': 'lbc', '}': 'rbc'
  };
  const replaceSpecialChars = (input: string): string =>
    input.replace(/[&@%$+=#\/\\*!?^~<>|"'`:;.,()\[\]{}]/g,
      char => `-${specialCharMap[char]}-`
    );
  const shortHash = async (input: string): Promise<string> => {

    const data = new TextEncoder().encode(input);
    const digest = await crypto.subtle.digest('SHA-1', data);
    const hashArray = Array.from(new Uint8Array(digest));

    return hashArray.slice(0, 4).map(b => b.toString(16).padStart(2, '0')).join('');
  
};
  const createIdFromContent = async (input: string, options: {replaceSpecial?: boolean, prefix?: string}): Promise<string | null> => {
    
    if (!input.trim()) return null;
  
    let processed = input.toLowerCase();

    if (options?.replaceSpecial) {

      processed = replaceSpecialChars(processed);
    
    }
  
    let cleaned = processed
      .replace(/[^a-z0-9-]+/g, '-')
      .replace(/^-+|-+$/g, '')
      .replace(/-+/g, '-');
  
    if (!cleaned) cleaned = 'id';
  
    const prefix = options?.prefix || 's-';
    const maxLength = 40;
  
    if ((prefix + cleaned).length <= maxLength) {

      return `${prefix}${cleaned}`;
    
    }
  
    const hash = await shortHash(input);
    const allowedLength = maxLength - prefix.length - hash.length - 1;
    const readablePart = cleaned.slice(0, allowedLength).replace(/-+$/g, '');
  
    return `${prefix}${readablePart}-${hash}`;
  
};
  
  export default createIdFromContent;
  