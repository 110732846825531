<template>
  <section class="stats">
    <slot />
    <template v-if="last === 1">{{ count }} {{$translate('total')}}</template>
    <template v-if="last !== 1">{{ range[0] }} - {{ range[1] }} ({{ count }}) / {{ all }} {{$translate('total')}}</template>
  </section>
</template>
<script lang="ts">
import { Prop } from '@/libs/lila-component';
import { ExtPartial } from '@/libs/lila-partial';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class statsPartial extends ExtPartial {

  @Prop(Number) last: number;

  @Prop(Number) first: number;

  @Prop(Number) current: number;

  @Prop(Number) count: number;

  @Prop(Number) all: number;

  @Prop(Array) range: [number, number];

}
</script>
<style lang="less">


.stats {

  .font-bold;
  font-weight: bold;
  text-transform: uppercase;

}
</style>
