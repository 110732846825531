import vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import inview from '@/libs/lila-inview';
import { ChildData, ModuleSettings } from '@lilaquadrat/studio/lib/interfaces';
import SelectOption from '@/interfaces/selectOption.interface';
import hardCopy from '@/mixins/hardCopy';
import GenericModel from '@/interfaces/GenericModel.interface';
import { Auth } from './lila-auth';
import { BaseEditorComponent } from './BaseEditorComponent';
import ModelsClass from './Models.class';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave',
]);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@Component
abstract class ModuleEditorComponent<T> extends BaseEditorComponent {

  @Prop(String) type: string;

  @Prop(Number) index: number;

  @Prop(String) position: string;

  @Prop(Array) availableVariants: SelectOption[];

  @Prop(Object) moduleSettings: ModuleSettings;

  @Prop(Object) additionalData: Record<string, unknown>;

  @Prop(Object) content: T;

  showContent: boolean = false;

  /**
   * name of the registered model
  */
  model: string;

  safeContent: T & { uuid?: string, type?: string } = {} as T;

  @Watch('content', { deep: true })
  watchContentChanges(newValue: T, oldValue: T) {

    if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {

      console.log('CONTENT CHANGED', newValue, oldValue, this.model);
      // this.setSelected();
      this.setSafeContent();

    }


  }

  @Watch('safeContent', { deep: true })
  watchSafeContent(newValue: T, oldValue: T) {


    if (newValue === oldValue) {

      this.debounce(500, () => {

        console.log('SAFECONTENT CHANGED', newValue, oldValue, this.model);
        console.log(hardCopy(this.safeContent));

        // oldValue.changed = true;
        this.$emit('changed', hardCopy(this.safeContent));

      });

    }

  }

  beforeMount() {

    this.setSafeContent();

  }

  setSafeContent() {

    if (!this.model) return;
    if (!this.content) return;

    console.log('SET SAFE CONTNT', this.model, this.content);

    const content = this.content ? hardCopy<T>(this.content) : {} as T;

    // eslint-disable-next-line new-cap
    // this.safeContent = new this.model().setData(content, false, 'add');
    this.safeContent = ModelsClass.add<T>(content, this.model);
    this.uuid = this.safeContent.uuid as string;

  }

  toggle() {

    if (this.type !== 'overview') {

      this.$emit('active', false);

    } else {

      this.showContent = false;
      this.$emit('active', true);

    }

  }

  remove() {

    this.$emit('remove');

  }

  clone() {

    this.$emit('clone');

  }

  add(module: string, partial: boolean, data: any) {

    this.$emit('add', module, partial, data);

  }

  move(direction: string) {

    this.$emit('move', direction);

  }

  // eslint-disable-next-line default-param-last
  getData(data: GenericModel, legacy: boolean = false, usecase?: 'save' | 'add' | 'clone', childData?: ChildData): T {

    // eslint-disable-next-line new-cap
    // const cleanModel = new this.model();

    // return cleanModel.setData(data, legacy, usecase, childData);
    return ModelsClass.setDataV2(data, {}, ModelsClass.getDeclaration(this.model), usecase) as T;

  }

  scope(scope: string[]): boolean {

    if (!scope) return true;
    return Auth.checkScope(this.$store.state.user, scope, this.$store.state.Company.company, this.$store.state.Project.project);

  }

}


export {
  Component,
  ModuleEditorComponent,
  Prop,
  Watch,
  inview,
  vue,
};
