import Models, { ModelDeclarationExtended, ModelOptions } from '@/libs/Models.class';
import GalleryElement from '@/models/GalleryElement.model';
import Module from '@/models/Module.model';
import Textblock from '@/models/Textblock.model';

export default interface GalleryModule {
  description: Textblock
  elements: GalleryElement[]

}

const declaration: ModelDeclarationExtended<GalleryModule, Module> = {
  description: { type: 'array', contains: { model: 'textblock' } },
  elements: { type: 'array', contains: { model: 'gallery-element' } },
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('gallery-module', declaration, undefined, options);