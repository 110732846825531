import vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import inview from '@/libs/lila-inview';
import { Auth } from './lila-auth';
import { BaseEditorComponent } from './BaseEditorComponent';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave',
]);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
@Component
abstract class PartialEditorComponent<T> extends BaseEditorComponent {

  @Prop(String) type: string;

  @Prop(Object) value: T;

  @Prop(Object) additionalData: Record<string, unknown>;

  showContent: boolean = false;

  remove() {

    this.$emit('remove', true);

  }

  clone() {

    this.$emit('clone', true);

  }

  add(module: string, partial: boolean, data: any) {

    this.$emit('add', module, partial, data);

  }

  move(direction: string) {

    this.$emit('move', direction);

  }


  scope(scope: string[]): boolean {

    if (!scope) return true;
    return Auth.checkScope(this.$store.state.user, scope, this.$store.state.Company.company, this.$store.state.Project.project);

  }

}


export {
  Component,
  PartialEditorComponent,
  Prop,
  Watch,
  inview,
  vue,
};
