<template>
  <article :class="navigationAnimation">
    <form class="content" @submit="save">
      <fieldset>
        <legend>
          <span class="text">{{$translate('editor')}} {{$translate('settings')}}</span>
        </legend>

        <fieldset>
          <legend><span class="text">Studio Editor URL</span></legend>

          <input-partial v-model="model.editorUrl" placeholder="url">{{$translate('url')}}</input-partial>
        </fieldset>
      </fieldset>

      <fieldset v-if="model.title">
        <legend>
          <span class="text">{{$translate('html settings')}}</span>
        </legend>

        <fieldset>
          <legend>
            <span class="text">{{ $translate('Title & Description') }}</span>
          </legend>

          <input-partial v-model="model.title.full" placeholder="Sitetitle">{{$translate('Sitetitle Full')}}</input-partial>
          <input-partial v-model="model.title.short" placeholder="Sitetitle">{{$translate('Sitetitle Short')}}</input-partial>

          <textarea-partial v-model="model.description.full" placeholder="Description" max-length="250">{{$translate('Description Full')}}</textarea-partial>
          <textarea-partial v-model="model.description.short" placeholder="Description" max-length="75">{{$translate('Description Short')}}</textarea-partial>
        </fieldset>

        <fieldset>
          <legend>
            <span class="text">{{$translate('Images')}}</span>
          </legend>

          <input-partial v-model="model.breakpointTablet" placeholder="only screen and ()">breakpoint tablet</input-partial>
          <input-partial v-model="model.breakpointDesktop" placeholder="only screen and ()">breakpoint desktop</input-partial>
          <input-partial v-model="model.breakpointWide" placeholder="only screen and ()">breakpoint wide</input-partial>

          <checkbox-partial v-model="model.preloadImages" description="If async loading if deactivated, all images will be loaded traditionally via html instead of javascript">
            {{$translate('Deactivate async image loading')}}
          </checkbox-partial>
        </fieldset>
      </fieldset>

      <button-group-partial>
        <button-partial v-model="state" type="submit" save>{{$translate('Save Changes')}}</button-partial>
      </button-group-partial>
    </form>

  </article>
</template>

<script lang="ts">
import {
  ExtComponent, Component,
} from '@/libs/lila-component';
import ModelsClass from '@/libs/Models.class';
import AppEditorConfiguration from '../models/Configuration.model';

@Component
export default class AppEditorSettingsScreen extends ExtComponent {

  content: any = {};

  model: AppEditorConfiguration = null;

  state: string = '';

  componentName = 'app-editor-project-settings-main';

  beforeMount() {

    // this.model.setData(this.$store.state.AppEditorMain.settings, true, 'add');
    this.model = ModelsClass.add<AppEditorConfiguration>(this.$store.state.AppEditorMain.settings, 'editor-configuration');
    console.log('MOUNTED SETTINGS', this.$store.state.AppEditorMain.settings);

  }

  save($event: MouseEvent) {

    $event.preventDefault();
    this.state = '';

    this.$store
      .dispatch('AppEditorMain/settings', {
        company: this.$store.state.Company.company,
        project: this.$store.state.Project.project,
        settings: this.model,
      })
      .then(() => {

        this.state = 'success';
        return true;

      })
      .catch(() => {

        this.state = 'error';

      });

  }

}

</script>
