<template>
  <section class="app-group-module"  :class="{hasTitle}">
    <template v-if="hasTitle">

      <header>
        <slot name="title"/>
      </header>
      <section class="app-group-content-container">
        <slot />
      </section>

    </template>
    <template v-if="!hasTitle"><slot /></template>
  </section>
</template>
<script setup lang="ts">
import { computed, useSlots } from 'vue';

const hasTitle = computed(() => !!useSlots().title);
</script>
<style lang="less" scoped>

.app-group-module {

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media @tablet, @desktop {
    grid-template-columns: repeat(auto-fit, 180px);
  }

  &.hasTitle {
    position: relative;
    grid-template-columns: 1fr;

    header {

      position: sticky;
      top: 35px;
      left: 0;
      background-color: @white;
      .multi(padding-top, 2);
      .multi(padding-bottom, 2);
      .index(3);

    }

    .app-group-content-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      @media @tablet, @desktop {
        grid-template-columns: repeat(auto-fit, 180px);
      }
    }

  }

}
</style>
