<template>
  <article :class="navigationAnimation" class="app-lists-home-screen">
    <div v-if="content && list" class="pagenator full">
      <header class="main">

        <section class="stats">
          <h1>{{ list.name }}</h1>
          <h3>{{ list.mode }}</h3>
          <h3 v-if="content.sites">
            <template v-if="!content.sites.last">{{ content.count }}</template>
            <template v-if="content.sites.last">{{ content.sites.range[0] }} - {{ content.sites.range[1] }} ({{ content.count }})</template>
            / {{ content.all }} {{$translate('total')}}
          </h3>
          <h3 v-if="percentFilled">{{$translate('app-lists-participants-filled-percent', [percentFilled, list.participants.max]) }}</h3>
          <h3 v-if="list.mode === 'shopping'">{{ $translate('app-lists-participants-total-paid', [$currency(list.participants.totalPaid)]) }}</h3>
        </section>

        <quickmenu-partial v-if="$scope(['lists:create'])">
          <router-link :title="$translate('app-lists-edit-title')" :aria-label="$translate('app-lists-edit-title')" class="main icon" :to="editLink"><icons-partial type="edit" size="large" /></router-link>
        </quickmenu-partial>
      </header>

      <!-- quickfilter with select static options and category selection -->
      <quickfilter-partial reload store="AppListParticipants" use-emit @update="updateSearch" @reload="reload">
        <span v-if="list.mode !== 'shopping'" class="filter-group">
          <select-partial mobile inline placeholder="app-lists-title-state" :options="stateOptions" allow-empty :value="filterState" @input="updateSearch({ data: $event, type: 'filterState' })" />
          <select-partial mobile inline placeholder="app-lists-title-category" :options="filterCategoryOptions" allow-empty :value="filterCategory" @input="updateSearch({ data: $event, type: 'filterCategory' })" />
        </span>
      </quickfilter-partial>

      <section class="scroll-container">
        <table class="data-switch contentTable" :class="[contentAnimation, list.mode]">
          <thead-partial :site-current="currentSite" :search="$route.query.search" :sort="sort" :order="order" :content="headContent" />
          <tbody>
            <tr v-for="(single, index) in content.data" :key="`data-overview-${index}`" :class="single.state">
              <td class="id fullHeight action hasDescription user" :class="{ hasAttribute: single.app || single.model }">
                <router-link
                  :to="{
                    name: 'app-lists-project-participant',
                    params: {
                      participantId: single._id.toString(),
                      company: $store.state.Company.company,
                      project: $store.state.Project.project,
                    },
                  }">
                  <div>
                    <span class="id">
                      {{single.user?.name || $translate('app-lists-participant-missing')}}
                    </span>
                    <span class="description">
                      {{single.user.email}}
                    </span>
                  </div>
                </router-link>
              </td>

              <td v-if="list.mode === 'contact'" class="message">
                <router-link
                  :to="{
                    name: 'app-lists-project-participant',
                    params: {
                      participantId: single._id.toString(),
                      company: $store.state.Company.company,
                      project: $store.state.Project.project,
                    },
                  }">
                {{ single.message }}
                </router-link>
              </td>

              <td  v-if="list.mode !== 'shopping'" class="category">
                {{ getCategoryName(single.category) }}
              </td>

              <td v-if="list.mode === 'shopping'" class="paid">
                {{ $currency(single.payment.amount) }} 
              </td>

              <td class="state attributes">
                <span v-if="single.state !== 'unchecked' && single.state !== 'notuseful'" class="attribute color1">{{$translate(`app-lists-participant-state-${single.state}`)}}</span>
                <span v-if="single.state === 'unchecked'" class="attribute grey">{{$translate(`app-lists-participant-state-${single.state}`)}}</span>
                <span v-if="single.state === 'notuseful'" class="attribute red">{{$translate(`app-lists-participant-state-${single.state}`)}}</span>
              </td>

              <td class="created">
                {{$date(single.history.created)}}
              </td>

            </tr>
          </tbody>
        </table>
      </section>
    </div>

    <modal-module />

  </article>
</template>
<script lang="ts">
import { ExtComponent, Component, Watch } from '@/libs/lila-component';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import { BasicData, DataObject, List, ListParticipants, ListParticipantWithUserAndPayment } from '@lilaquadrat/interfaces';
import cleanObject from '@/mixins/cleanObject';
import hardCopy from '@/mixins/hardCopy';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppListsHomeScreen extends ExtComponent {

  componentName = 'app-lists-project-single-home';

  headContentContact = [
    {
      key: 'name',
      text: 'name',
      classes: 'name',
    },
    {
      text: 'app-lists-participant-message',
      classes: 'message'
    },
    {
      text: 'app-lists-participant-category',
      classes: 'category'
    },
    {
      text: 'app-lists-participant-state',
      key: 'state',
      classes: 'state'
    },
    {
      text: 'app-lists-participant-date',
      key: 'history.created',
      classes: 'created'
    },
  ];

  headContentReservation = [
    {
      key: 'name',
      text: 'name',
      classes: 'name',
    },
    {
      text: 'app-lists-participant-category',
      classes: 'category'
    },
    {
      text: 'app-lists-participant-state',
      key: 'state',
      classes: 'state'
    },
    {
      text: 'app-lists-participant-date',
      key: 'history.created',
      classes: 'created'
    },
  ];

  headContentShopping = [
    {
      key: 'name',
      text: 'name',
      classes: 'name',
    },
    {
      text: 'app-lists-participant-amount',
      classes: 'category'
    },
    {
      text: 'app-lists-participant-state',
      key: 'state',
      classes: 'state'
    },
    {
      text: 'app-lists-participant-date',
      key: 'history.created',
      classes: 'created'
    },
  ];

  stateOptions = [
    {
      text: 'app-lists-participant-state-enlisted',
      value: 'enlisted',
    },
    {
      text: 'app-lists-participant-state-confirmed',
      value: 'confirmed',
    },
    {
      text: 'app-lists-participant-state-reserved',
      value: 'reserved',
    },
    {
      text: 'app-lists-participant-state-waiting',
      value: 'waiting',
    },
    {
      text: 'app-lists-participant-state-finalized',
      value: 'finalized',
    },
    {
      text: 'app-lists-participant-state-blocked',
      value: 'blocked',
    },
    {
      text: 'app-lists-participant-state-unchecked',
      value: 'unchecked',
    },
    {
      text: 'app-lists-participant-state-notuseful',
      value: 'notuseful',
    },
  ];

  @Watch('$route')
  watchRoute() {

    this.setSearch();
    this.activeElement = this.list.name;

  }

  get currentSite() {

    return this.$route.params.site ? +this.$route.params.site : null;

  }

  get filterState() {

    return this.$store.state.AppListParticipants.search?.filterState;

  }

  get filterCategory() {

    return this.$store.state.AppListParticipants.search?.filterCategory;

  }

  get sort() {

    return this.$store.state.AppListParticipants.search?.sort;

  }

  get order() {

    return this.$store.state.AppListParticipants.search?.order;

  }

  get content(): DataObject<BasicData<ListParticipantWithUserAndPayment>[]> {

    return this.$store.state.AppListParticipants.data || undefined;

  }

  get list(): List {

    return this.$store.state.AppLists.single;

  }

  get editLink() {

    return { name: 'app-lists-project-single-edit', params: { id: this.$route.params.id } };

  }

  get percentFilled() {

    if (!this.list.participants.max) return null;
    return Math.round((this.list.participants.confirmed / this.list.participants.max) * 100);

  }

  get headContent() {

    if(this.list.mode === 'contact') return this.headContentContact;
    if(this.list.mode === 'reservation' || this.list.mode === 'content') return this.headContentReservation;
    if(this.list.mode === 'shopping') return this.headContentShopping;

    return [];

  }

  get filterCategoryOptions() {

    return this.list.categories?.map((single) => ({
        text: single.name,
        value: single.id
      }));

  }

  mounted() {

    this.setSearch();
    this.activeElement = this.list?.name;

  }

  getSearch() {

    const query: Record<string, string | string[]> = {};

    query.sort = this.$route.query.sort as string || 'name';
    query.order = this.$route.query.order as string || '1';

    query.search = this.$route.query.search || null;

    if (this.$route.query.filterState) {

      query.filterState = !Array.isArray(this.$route.query.filterState) ? [this.$route.query.filterState] : this.$route.query.filterState;

    } else {

      query.filterState = null;

    }

    if (this.$route.query.filterCategory) {

      query.filterCategory = !Array.isArray(this.$route.query.filterCategory) ? [this.$route.query.filterCategory] : this.$route.query.filterCategory;

    } else {

      query.filterCategory = null;

    }


    return query;

  }

  setSearch() {

    // only update search if the current component is used and no child is active
    if (this.$route.name !== this.componentName) return;
    this.$store.commit('AppListParticipants/setSearch', this.getSearch());

  }

  getCategoryName(id: string) {

    const category = this.list.categories?.find((single) => single.id === id);

    return category?.name || id;

  }


  async updateSearch(data: {data: string | string[], type: 'search' | 'filterState' | 'filterCategory'}) {

    let useData = data.data;

    if (data.type === 'filterState' || data.type === 'filterCategory') {

      useData = !Array.isArray(data.data) ? [data.data] : data.data;

    }

    this.$store.commit('AppListParticipants/updateSearch', { data: useData, type: data.type });

    const query = hardCopy(this.$store.state.AppListParticipants.search);

    cleanObject(query);

    this.$router.push({ params: { site: '1' }, query });

  }

  async reload() {

    StudioSDK.flushCache('listParticipants');

    const data = await this.$store
      .dispatch('AppListParticipants/get', {
        query: { listId: this.$route.params.id, ...this.$route.query },
        settings: {
          project: this.$route.params.project,
          company: this.$route.params.company,
          site: this.$route.params.site,
        },
      });

    this.$store.commit('AppListParticipants/data', data.data);

  }

  // eslint-disable-next-line class-methods-use-this
  async preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    const list: SDKResponse<List> = await store.dispatch('AppLists/single', params.id);

    store.commit('AppLists/single', list.data);

    if (query.filterState) {

      query.filterState = !Array.isArray(query.filterState) ? [query.filterState] : query.filterState;

    }

    return store
      .dispatch('AppListParticipants/get', {
        query: { listId: params.id, ...query },
        settings: {
          project: params.project,
          company: params.company,
          site: params.site,
        },
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<DataObject<ListParticipants>>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    console.log(preloadedData);
    store.commit('AppListParticipants/data', preloadedData.data);
    return Promise.resolve();

  }

  // eslint-disable-next-line class-methods-use-this
  preloadError(error: Error, store: Store<MainStoreState>) {

    store.commit('AppListParticipants/data', {});

  }

}
</script>
<style lang="less">

.app-lists-home-screen {

  .filter-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  table.contentTable, table.contentHead {

    tr {
      // grid-template-columns: 1fr 50px 150px;

      td {

        &.hasDescription {

          .id {
            display: grid;
            color: @textColor;
            text-transform: uppercase;
          }

        }

        &.participants {
          display: grid;
          grid-template-columns: max-content min-content;
          gap: 3px;
          justify-content: center;
        }

      }

      &.blocked {

        td {
          color: @grey;

          .id {
            color: @grey;
          }

          &.action.hasDescription {

            a {

              .description {
                color: @grey;
              }

              .id {
                color: @grey;
              }

            }

          }
        }
      }

    }

    &.reservation, &.content, &.shopping {

      thead, tbody {
        display: grid;

        tr {

          grid-template-columns: 1fr 80px 100px;

          td {

            &.category, &.paid {
              display: none;
            }

          }

          @media @desktop {
            grid-template-columns: 1fr 150px 80px 100px;

            td {

              &.category, &.paid {
                display: grid;
              }

            }
          }

          &:hover {

            td {
              background-color: @grey1;
            }
          }

        }
      }

    }

    &.contact {

      thead {

        gap: 10;

        tr {
          grid-template-columns: min-content max-content max-content max-content max-content;

          .message, .category {
            display: none;
          }
        }
      }

      tr {
        grid-template-columns: auto;
      }

      tbody {
        display: grid;
        gap: 0;

        tr {
          grid-template-rows: max-content 1fr;
          gap: 10px 5px;
          
          @media @desktop {
            grid-template-rows: 1fr 1fr;
            grid-template-columns: min-content max-content max-content;
            gap: 5px 20px;
          }

          td {
            align-content: center;
          }

          // &:hover {
          //   background-color: transparent;

          //   td {

          //     background-color: transparent;
          //   }
          // }

          .message {
            grid-row-start: 2;
            grid-column-start: 1;
            grid-column-end: 3;
            font-size: @fontText;
            text-align: left;
          }

          .category {
            text-align: left;
          }

          .state, .created {
            text-align: right;
          }

          .state {
            grid-row-start: 2;
            grid-column-start: 4;
          }

          .created {
            grid-row-start: 1;
            grid-column-start: 4;
          }

          .user, .created, .category {
            .multi(padding-top, 4);
            height: 40px;
          }

          .message, .state {
            align-content: start;
            .multi(padding-bottom, 4);
            height: auto;
            min-height: 20px;
          }

          .state {
            display: grid;
            grid-template-columns: max-content;
            justify-content: end;
          }

          .user {
            height: 40px;

            &.hasDescription {

              a {
                height: 40px;
                line-height: 10px;

                .id {
                  text-transform: none;
                }

                div {
                  display: grid;
                  grid-template-columns: max-content max-content;
                  gap: 10px;
                }

              }

            }

          }

        }
      }

    }

  }

  .sites-filter-container {

    display: grid;
    grid-template-columns: 1fr 1fr;
    .multi(padding-top, 2);
    .multi(padding-bottom, 4);
    .basePadding;

    .sites-container {
      justify-self: end;
    }

    .select {
      display: grid;
      grid-template-columns: 150px 1fr;
    }
  }
}
</style>


