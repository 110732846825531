<script setup lang="ts">
import { useStore } from '@/store/main.store';
import { computed, onBeforeMount , ref } from 'vue';
import Tracker from '../models/Tracker.model';

const store = useStore();
const props = defineProps<{
  company?: string,
  project?: string
  data?: {indexedKey: number, data: Tracker}
}>();
const tracker = ref();
const useCompany = computed(() => props.company || props.data?.data?.company);
const useProject = computed(() => props.project || props.data?.data?.project);
const createTracker = async (baseTracker?: Tracker) => {

  tracker.value = await store.dispatch('AppTracker/createLocal', { company: useCompany.value, project: useProject.value, category: baseTracker?.category});

};
const loadTracker = async () => {

  tracker.value = props.data;

};

onBeforeMount(async () => {

  console.log(29, props.data);

  /**
  * if the component is added without a indexedKey we need to create a new tracker
  * otherwise load the tracker with the given id
  */
  if(props.data?.indexedKey) {

    console.log('load tracker');
    await loadTracker();
    
  } else {
    
    console.log('create tracker');
    await createTracker();

  }

});

</script>
<template>
  <article class="app-tracker overlay">
    <time-tracker-module v-if="tracker" @restart="createTracker" :key="tracker.indexedKey" overlay :indexedKey="tracker.indexedKey" :company="useCompany" :project="useProject" :tracker="tracker.data"/>
  </article>
</template>
