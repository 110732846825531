<template>
  <section :class="{ changed: safeContent.changed }" class="quote-module-editor open editor">
    <module-editor-header-partial
v-model="content" :position="position" :index="index" :type="type"
      @move="move($event)" />
    <crumbs-partial :type="safeContent.type" @clearCrumbs="unsetCurrentChild" />

    <section :class="{ optionsVisible: !child }" class="editor-module-container">
      <editor-content-group-partial v-if="child === 'quote'" :key="'quote-quote'">
        <input-partial v-model="safeContent.quote" placeholder="quote">{{ $translate('Quote') }}</input-partial>
      </editor-content-group-partial>

      <editor-content-group-partial v-if="child === 'citation'" :key="'quote-citation'">
        <input-partial v-model="safeContent.citation" placeholder="citation">{{ $translate('citation') }}</input-partial>
      </editor-content-group-partial>
      <link-partial-editor v-if="child === 'link'" v-model="safeContent.link" :inactive="['text']" />

      <moduleList-partial v-if="!child" class="module-editor-navigation">
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('quote')">{{ $translate('Quote')
          }}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('link')">{{ $translate('link')
          }}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('citation')">{{ $translate('Citation')
          }}</button-partial>
        </li>
      </moduleList-partial>

      <module-options-partial
        v-if="child === 'options' || !child" 
        v-model="safeContent"
        :available-variants="availableVariants" 
        :selected-variants="safeContent.variant"
        @setChild="setChild('options', true)" 
        @unsetChild="setChild('options')" 
        @remove="remove()" 
        @clone="clone()"
      />

    </section>
  </section>
</template>

<script lang="ts">
import { Component, ModuleEditorComponent, Prop } from '@/libs/ModuleEditorComponent';
import QuoteModule from '@/apps/editor/models/QuoteModule.model';

@Component
// eslint-disable-next-line global-require
export default class QuoteModuleEditor extends ModuleEditorComponent<QuoteModule> {

  @Prop(Array) inactive: string[];

  model = 'quote-module';

  get quote() {

    if (typeof this.safeContent.quote === 'undefined') return undefined;

    if (typeof this.safeContent.quote !== 'object') {

      this.safeContent.quote = '';
      return undefined;

    }

    if(this.safeContent.quote === null) return null;

    return this.safeContent?.quote.join('\n');

  }

  set quote(quote: string) {

    const baseArray = quote.trim().split('\n');
    let cleanArray: string[] = [];

    cleanArray = baseArray.filter((string: string) => string.trim().length > 0);

    if (this.safeContent) {
      this.safeContent.quote = cleanArray;
    }

  }

  active(value: any) {

    if (!this.inactive) return true;
    return this.inactive.indexOf(value) === -1;

  }


}
</script>
