import Models, { ModelDeclaration, ModelHooks } from '@/libs/Models.class';
import PicturePartial from './PicturePartial.model';
import Textblock from './Textblock.model';

export default interface GalleryElement {
  picture: PicturePartial
  textblock: Textblock
}

const declaration: ModelDeclaration<GalleryElement> = {
  picture: { model: 'picture-partial' },
  textblock: { model: 'textblock' },
};
const functions: ModelHooks<GalleryElement> = {
  legacy: (data: any) => {

    if (data.textblock?.links) {

      data.links = data.textblock.links;
      console.log(data.textblock.links);

    }

  }
};

Models.register('gallery-element', declaration, functions);