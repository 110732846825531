<template>
  <section :class="navigationAnimation">
    <side-screen-header-partial>{{$translate(title)}}</side-screen-header-partial>
    <section class="content-container">
      <section v-if="ready" class="content app-team-permissions-add-edit">
        <section>
          <search-partial v-if="target === 'project' && !edit" v-model="user" callback :multiple="false" :possible-entries="possibleEntries" @callback="search">{{$translate('user last name')}}</search-partial>
          <input-partial v-if="target === 'company' && !edit" v-model="email">{{$translate('e-mail')}}</input-partial>
        </section>

        <template v-if="scopeArray">
          <permissionGroup-partial v-for="(single, index) in permissionsScope" :key="`permission-group-${index}`" :values="scopeArray" :value="single" @input="update($event, single.group)" />
        </template>

        <save-partial :callback="save">
          <button-partial v-if="edit" class="base colorScheme2" doublecheck @confirmed="remove">{{$translate('REMOVE_PERMISSIONS')}}</button-partial>
        </save-partial>
      </section>
    </section>
  </section>
</template>
<script lang="ts">
import { CompanyScope, ProjectScope } from '@lilaquadrat/studio/lib/src/models/permissions.model';
import { CallResponse } from '@lilaquadrat/studio/lib/interfaces';
import { ExtComponent, Component } from '@/libs/lila-component';
import { Call } from '@/libs/lila-call';
import { Auth } from '@/libs/lila-auth';
import hardCopy from '@/mixins/hardCopy';
import translate from '@/mixins/translation';

@Component
export default class AppTeamEditScreen extends ExtComponent {

  componentName = ['app-team-company-edit', 'app-team-project-edit', 'app-team-company-add', 'app-team-project-add'];

  state: string = '';

  possibleEntries: string[] = [];

  permissionsScope: any = {};

  scopeArray: string[] = null;

  completeScopeArray: string[] = [];

  user: Record<string, string> = null;

  email: string = null;

  id: string = null;

  target: string = null;

  edit: boolean = false;

  ready: boolean = false;

  beforeMount() {

    if (this.$route.name === 'app-team-company-add' || this.$route.name === 'app-team-company-edit') {

      this.target = 'company';

    }

    if (this.$route.name === 'app-team-project-add' || this.$route.name === 'app-team-project-edit') {

      this.target = 'project';

    }

    this.getPermissionsDefinition()
      .then(() => {

        if (this.$route.params.id) {

          this.edit = true;
          this.getPermissions();

        } else {

          this.scopeArray = [];

        }


      })
      .then(() => {

        this.ready = true;

      });

  }

  update(scope: string[], type: string) {

    console.log(scope, type);

    this.scopeArray = this.scopeArray.filter((single) => !single.match(type));
    this.scopeArray = [...new Set([...this.scopeArray, ...scope])];

  }

  get content() {

    return this.$store.state.AppEditorMain.data;

  }

  get options() {

    return this.scopeArray?.map((single) => ({
      value: single,
      text: translate.translate(`app-team-permissions-${single}`),
      description: single,
    }));

  }

  get title() {

    if (this.$route.name.match(/-edit$/i)) {

      return this.user?.prename ? `${this.user?.lastname}, ${this.user?.prename}` : '';

    }

    if (this.$route.name.match(/-add$/i)) {

      return 'ADD_USER';

    }

    return '';

  }

  save() {

    const call = new Call<any>('team', this.$store.getters.endpoints);
    const urlArray = ['permissions', this.$store.state.Company.company];
    const data: {scope: string[], user?: string, email?: string} = {
      scope: this.scopeArray.filter((single) => this.completeScopeArray.includes(single)),
    };
    let action: Promise<CallResponse<any>>;

    if (this.target === 'project') {

      if (!this.edit) {

        if (!this.user) {

          this.state = 'error';
          return;

        }

        if (!this.user[0]) {

          this.state = 'error';
          return;

        }

        data.user = this.user[0];

      }

      urlArray.push(this.$store.state.Project.project);

    } else if (!this.edit) {

      data.email = this.email;

    }

    if (this.edit) {

      urlArray.push(this.id);
      action = call.put('api', `/${urlArray.join('/')}`, data, Auth.getAuthHeader());

    } else {

      urlArray.push('add');
      action = call.post('api', `/${urlArray.join('/')}`, data, Auth.getAuthHeader());

    }

    return action;

  }

  remove() {

    const call = new Call<any>('team', this.$store.getters.endpoints);
    const urlArray = ['permissions', this.$store.state.Company.company];

    if (this.target === 'project') {

      urlArray.push(this.$store.state.Project.project);

    }

    call
      .delete('api', `/${urlArray.join('/')}/${this.id}`, Auth.getAuthHeader())
      .then(() => {

        this.state = 'success';

      })
      .catch(() => {

        this.state = 'error';

      });

  }

  search($event) {

    console.log($event);

    const searchObject: any = {
      query: {
        search: $event,
      },
      settings: { company: this.$store.state.Company.company, add: ['team'] },
    };

    if (this.$route.name === 'app-team-project-add') {

      searchObject.settings.endpoint = 'projects';
      searchObject.settings.project = this.$store.state.Project.project;
      searchObject.query.not = true;

    }

    this.$store
      .dispatch('AppEditorMain/getEndpoint', searchObject)
      .then((data: CallResponse<any>) => {

        const entries = [];

        data.r.data.forEach((single) => {

          entries.push({ name: `${single.user.lastname}, ${single.user.prename}`, value: single.user.id });

        });

        this.possibleEntries = entries;

      })
      .catch((e) => {

        console.error(e);

      });

  }

  getPermissionsDefinition() {

    this.completeScopeArray = [];

    const settings = { endpoint: 'companies', add: [] };

    if (this.target === 'project') {

      settings.endpoint = 'projects';
      settings.add.push(this.$store.state.Company.company);
      settings.add.push(this.$store.state.Project.project);

    }

    if (this.target === 'company') {

      settings.add.push(this.$store.state.Company.company);

    }

    settings.add.push('scope');

    return this.$store
      .dispatch('AppEditorMain/getEndpoint', {
        settings,
      })
      .then((data: CallResponse<typeof ProjectScope | typeof CompanyScope>) => {

        const scope = data.r;
        const scopeArray = [];

        Object.keys(scope).forEach((key: string) => {

          if (!Array.isArray(scope[key])) {

            Object.keys(scope[key]).forEach((childKey: string) => {

              scopeArray.push({ parent: key, group: childKey, scope: scope[key][childKey] });

              scope[key][childKey].forEach((single: string) => {

                this.completeScopeArray.push(`${key}:${childKey}:${single}`);

              });

            });

          } else {

            scopeArray.push({ group: key, scope: scope[key] });
            scope[key].forEach((single: string) => {

              this.completeScopeArray.push(`${key}:${single}`);

            });


          }


        });

        console.log(data);
        this.permissionsScope = scopeArray;

      })
      .catch((e) => {

        console.error(e);

      });

  }

  getPermissions() {

    return this.$store
      .dispatch('AppEditorMain/getEndpoint', {
        settings: {
          endpoint: 'permissions',
          add: [this.$store.state.Company.company, this.$store.state.Project.project, 'single', this.$route.params.id].filter((single) => single),
        },
      })
      .then((data: CallResponse<any>) => {

        const scope = data.r;

        this.scopeArray = hardCopy(scope.scope);
        this.user = scope.user;
        this.id = scope._id;

      })
      .catch((e) => {

        console.error(e);

      });

  }

}
</script>
<style lang="less" scoped>

.app-team-permissions-add-edit {

  display: grid;
  gap: 40px;

}
</style>
