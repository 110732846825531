<template>
  <section class="text-module-editor open editor" :class="{ changed: safeContent.changed }">
    <module-editor-header-partial v-model="content" :position="position" :index="index" :type="type" @move="move($event)" />
    <crumbs-partial :type="safeContent.type" @clearCrumbs="unsetCurrentChild" />

    <section :class="{ optionsVisible: !child }" class="editor-module-container">

      <textblock-partial-editor v-if="child === 'app-editor-menu-title'" v-model="safeContent.textblock" :inactive="['subline', 'intro', 'text']" class="top" />
      <textblock-partial-editor v-if="child === 'intro'" v-model="safeContent.intro" class="top" />
      <list-elements-partial-editor v-if="child === 'app-editor-menu-categories'" v-model="categories" type="menuCategoryElement-partial" :model="listModel" partial @input="update($event)" />

      <moduleList-partial v-if="!child" class="module-editor-navigation">
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('app-editor-menu-title')">{{$translate('app-editor-menu-title')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('intro')">{{$translate('intro')}}</button-partial>
        </li>
        <li>
          <button-partial :variant="['moduleNavigation']" @confirmed="setChild('app-editor-menu-categories')">{{$translate('app-editor-menu-categories')}}</button-partial>
        </li>
      </moduleList-partial>

      <module-options-partial
        v-if="child === 'options' || !child"
        v-model="safeContent"
        :available-variants="availableVariants"
        :selected-variants="safeContent.variant"
        @setChild="setChild('options', true)"
        @unsetChild="setChild('options')"
        @remove="remove()"
        @clone="clone()"
      />

    </section>
  </section>
</template>

<script lang="ts">
import { Component, ModuleEditorComponent } from '@/libs/ModuleEditorComponent';
import MenuModule from '@/apps/editor/models/MenuModule.model';
import MenuCategoryElement from '@/models/MenuCategoryElement.model';

@Component
// eslint-disable-next-line global-require
export default class MenuModuleEditor extends ModuleEditorComponent<MenuModule> {

  listModel = 'menu-category-element';

  model = 'menu-module';

  get categories() {

    return this.safeContent.categories;

  }

  set categories(categories: MenuCategoryElement[]) {

    this.safeContent.categories = categories;

  }

  update(categories: MenuCategoryElement[]) {

    this.safeContent.categories = categories;

  }

}
</script>
