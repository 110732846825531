import Models, { ModelDeclaration } from '@/libs/Models.class';

export default interface VideoSource {
  media: string
  source: string
}

const declaration: ModelDeclaration<VideoSource> = {
  media: { type: 'string' },
  source: { type: 'string' },
};

Models.register('video-source', declaration);