<template>
  <article class="app-history list-extension">
    <h2 :title="id">{{ id }}</h2>
    <section v-if="!viewDetails && viewDetails !== 0" class="list-sites-container">
      <section class="sites" v-if="content">
        <h3 class="stats">
          <template v-if="!content.sites.last">{{ content.count }}</template>
          <template v-if="content.sites.last">{{ content.sites.range[0] }} - {{ content.sites.range[1] }}</template>
          / {{ content.all }}
        </h3>
        <sites-partial paramKey="site" :data="content" />
      </section>
    </section>

    <section class="scroll-container">
      <ul :class="parentContentAnimation" v-if="!viewDetails && viewDetails !== 0 && content">
        <li v-for="(single, index) in content.data" :class="{ current: single.history.version === current }" :key="`editor-history-list-${index}`">
          <div class="version">{{ single.history.version + 1 }}</div>
          <div class="text">
            <button-partial colorScheme="inlineBold" @click="updatePreview(single.history.version)">
              {{ $translate(`app-history-${single.history.reason}`) }} durch {{ single.history.user }}
            </button-partial>
            <div>
              <abbr :title="$date(single.history.date, 'DD.MM.YYYY HH:mm')">{{$date(single.history.date, null, true) }}</abbr>
            </div>
          </div>

          <router-link
            class="toggleDetails"
            :to="{
              name: 'app-editor-project-history',
              params: {
                id: single.id,
                company: $store.state.Company.company,
                project: $store.state.Project.project,
                site: $route.params.site,
                version: single.history.version,
              },
            }"
          >
            <icons-partial type="info" colorScheme="colorScheme1" />
          </router-link>
        </li>
      </ul>

      <section v-if="viewDetails || viewDetails === 0" class="single">
        <router-link :to="backLink" class="backToList" colorScheme="transparent" size="small">{{$translate('back to overview')}}</router-link>
        <slot>{{ singleData }}</slot>
      </section>
    </section>
  </article>
</template>
<script lang="ts">
import { History, DataObject } from '@lilaquadrat/studio/lib/interfaces';
import { Component } from '@/libs/lila-component';
import hardCopy from '@/mixins/hardCopy';
import { ExtPartial, Prop, Watch } from '@/libs/lila-partial';
import PartialModuleEditor from '@/apps/editor/modules/partial.module.editor.vue';

@Component
export default class AppHistoryListExtension extends PartialModuleEditor {

  @Prop() content: DataObject<History>;

  @Prop() id: string;

  @Prop() current: number;

  @Prop() parentContentAnimation: any;

  @Watch('$route')
  routeWatch() {

    this.viewDetails = +this.$route.params.version;
    this.updatePreview(+this.$route.params.version);

  }

  mounted() {

    this.viewDetails = +this.$route.params.version;
    this.updatePreview(+this.$route.params.version);

  }

  updatePreview(version: number) {

    if (version || version === 0) this.$store.commit('AppHistory/preview', version);

  }

  set viewDetails(version: number | undefined) {

    this.$store.commit('AppHistory/details', version);

  }

  get viewDetails() {

    return this.$store.state.AppHistory.details;

  }

  get backLink() {

    const baseLink = hardCopy(this.$route.params);

    delete baseLink.version;

    return { name: this.$route.name, params: baseLink };

  }

  get singleData() {

    if (!this.viewDetails && this.viewDetails !== 0) return {};

    return this.content.data.find((single) => single.history.version === this.viewDetails);

  }

}
</script>
<style lang="less">


.app-history.list-extension {
  display: grid;

  grid-template-rows: max-content max-content minmax(50px, min-content);
  gap: 5px;
  overflow: hidden;
  width: 100%;
  .multi(margin-top, 2);

  @media @tablet, @desktop {
    gap: 15px;
    max-width: 355px;
    .multi(margin, 4, 2);
  }

  .backToList {
    .multi(margin, 0, 4);
    display: grid;
    line-height: @buttonLineHeight;
    text-transform: uppercase;
    .font-head;
  }

  h2 {
    .multi(padding, 0, 4);
    overflow: hidden;
    text-transform: uppercase;
    text-overflow: ellipsis;
  }

  .single {
    display: grid;

    @media @tablet, @desktop {
      .multi(padding, 2, 0);
      gap: 10px

    }
  }

  .list-sites-container {

    .sites {
      .multi(padding, 0, 4);

      display: grid;
      grid-template-columns: max-content 1fr;

      justify-items: end;

      .stats {
        height: @buttonHeight;
        line-height: @buttonLineHeight;
      }
    }
  }

  li {
    position: relative;
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    gap: 10px;
    align-items: center;

    height: 50px;
    margin: 0;
    font-size: @fontTextSmaller;

    .version {
      display: grid;
      align-content: center;
      color: @color1;
      .multi(padding-left, 4);
    }

    a {
      font-size: @fontTextSmaller;
    }

    .toggleDetails {
      display: grid;
      height: 100%;
    }

    &.current {
      background-color: @grey1;

      .version {
        .font-head;
      }
    }

    &:hover {
      background-color: @grey2;
    }
  }
}
</style>
