<script setup lang="ts">
import { ListContent } from '@lilaquadrat/interfaces';
import { ref } from 'vue';

const props = defineProps<{
    value: ListContent
    mode?: 'mail' | 'shopping'
}>();
const view = ref<string>();

function updateView(type?: string) {

    view.value = type;

}

</script>
<template>
    <section class="app-lists-content-selector">
        <universal-crumbs-partial v-if="view" inline>
            <li>
                <button-partial :variant="['crumbs']" @click="updateView">{{$translate('overview')}}</button-partial> 
            </li>
            <li>
                <span>
                    {{$translate(`app-lists-content-${view}`)}}
                </span>
            </li>
        </universal-crumbs-partial>

        <template v-if="!mode">

            <ul v-if="!view">
                <li><button-partial :variant="['listElement']" @click="updateView('pre')">{{$translate('app-lists-content-pre')}}</button-partial></li>
                <li><button-partial :variant="['listElement']" @click="updateView('open')">{{$translate('app-lists-content-open')}}</button-partial></li>
                <li><button-partial :variant="['listElement']" @click="updateView('waiting')">{{$translate('app-lists-content-waiting')}}</button-partial></li>
                <li><button-partial :variant="['listElement']" @click="updateView('active')">{{$translate('app-lists-content-active')}}</button-partial></li>
                <li><button-partial :variant="['listElement']" @click="updateView('post')">{{$translate('app-lists-content-post')}}</button-partial></li>
                <li><button-partial :variant="['listElement']" @click="updateView('paid')">{{$translate('app-lists-content-paid')}}</button-partial></li>
            </ul>

            <select-editor-ext v-if="view === 'pre'" v-model="value.pre">{{$translate('app-lists-content-pre')}}</select-editor-ext>
            <select-editor-ext v-if="view === 'open'" v-model="value.open">{{$translate('app-lists-content-open')}}</select-editor-ext>
            <select-editor-ext v-if="view === 'waiting'" v-model="value.waiting">{{$translate('app-lists-content-waiting')}}</select-editor-ext>
            <select-editor-ext v-if="view === 'active'" v-model="value.active">{{$translate('app-lists-content-active')}}</select-editor-ext>
            <select-editor-ext v-if="view === 'post'" v-model="value.post">{{$translate('app-lists-content-post')}}</select-editor-ext>
            <select-editor-ext v-if="view === 'paid'" v-model="value.paid">{{$translate('app-lists-content-paid')}}</select-editor-ext>
            
            <description-partial v-if="view">{{$translate(`app-lists-content-${view}-description`)}}</description-partial>
        </template>

        <template v-if="mode === 'shopping'">

            <ul v-if="!view">
                <li><button-partial :variant="['listElement']" @click="updateView('paid')">{{$translate('app-lists-content-paid')}}</button-partial></li>
            </ul>

            <select-editor-ext v-if="view === 'paid'" v-model="value.paid">{{$translate('app-lists-content-paid')}}</select-editor-ext>
            <description-partial v-if="view">{{$translate(`app-lists-content-${view}-description`)}}</description-partial>
      
        </template>

        <template v-if="mode === 'mail'">

            <ul v-if="!view">
                <li><button-partial :variant="['listElement']" @click="updateView('waiting')">{{$translate('app-lists-emails-waiting')}}</button-partial></li>
                <li><button-partial :variant="['listElement']" @click="updateView('active')">{{$translate('app-lists-emails-active')}}</button-partial></li>
                <li><button-partial :variant="['listElement']" @click="updateView('post')">{{$translate('app-lists-emails-post')}}</button-partial></li>
                <li><button-partial :variant="['listElement']" @click="updateView('paid')">{{$translate('app-lists-emails-paid')}}</button-partial></li>
            </ul>

            <select-editor-ext v-if="view === 'waiting'" target="mail" v-model="value.waiting">{{$translate('app-lists-emails-waiting')}}</select-editor-ext>
            <select-editor-ext v-if="view === 'active'" target="mail" v-model="value.active">{{$translate('app-lists-emails-active')}}</select-editor-ext>
            <select-editor-ext v-if="view === 'post'" target="mail" v-model="value.post">{{$translate('app-lists-emails-post')}}</select-editor-ext>
            <select-editor-ext v-if="view === 'paid'" target="mail" v-model="value.paid">{{$translate('app-lists-emails-paid')}}</select-editor-ext>

            <description-partial v-if="view">{{$translate(`app-lists-emails-${view}-description`)}}</description-partial>
        </template>
    </section>
</template>
<style lang="less" module>
.app-lists-content-selector {

    display: grid;
    gap: 20px;

}
</style>