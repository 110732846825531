<template>
  <section class="accordion-partial-editor">

    <moduleList-partial v-if="!child" class="module-editor-navigation">
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('textblock')">{{$translate('textblock')}}</button-partial>
      </li>
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('list')">{{$translate('list')}}</button-partial>
      </li>
      <li>
        <button-partial :variant="['moduleNavigation']" @confirmed="setChild('links')">{{$translate('links')}}</button-partial>
      </li>
    </moduleList-partial>
    <textblock-partial-editor v-if="child === 'textblock'" v-model="value.textblock" />

    <list-partial-editor v-if="child === 'list'" v-model="value.list" />
    <link-list-partial-editor v-if="child === 'links'" v-model="value.links" />

  </section>
</template>

<script lang="ts">
import { ExtComponent, Component, Prop } from '@/libs/lila-component';
import AccordionElementModel from '@/models/AccordionElement.model';

@Component
// eslint-disable-next-line global-require
export default class AccordionElementPartialEditor extends ExtComponent {

  @Prop(Object) value: AccordionElementModel;

}

</script>
