<template>
    <article :class="navigationAnimation" class="app-customers-import-screen">
            <header class="main">

                <section class="stats">
                    <h1>{{ $translate('app-customers-import') }}</h1>
                    <h3 v-if="importData.length">
                        {{ importData.length }} {{ $translate('total') }} - {{ importType }}
                    </h3>
                    <h3 v-if="importDone">{{ $translate('app-customers-import-statistics', [importStatistics.success, importStatistics.skipped, importStatistics.error]) }}</h3>
                </section>

                <quickmenu-partial v-if="$scope(['customers:create'])">
                    <router-link :title="$translate('app-customers-home')" :aria-label="$translate('app-customers-add')" class="main icon" :to="{ name: 'app-customers-company-home' }"><icons-partial type="home" size="large" /></router-link>
                    <button-partial doublecheck v-if="importData.length" :disabled="importDone" @confirmed="executeImport">{{ $translate('app-customers-import-execute') }}</button-partial>
                    <button-partial doublecheck v-if="importData.length" @confirmed="reset">{{ $translate('app-customers-import-reset') }}</button-partial>
                </quickmenu-partial>
            </header>

            <section class="scroll-container">
                <section class="type-select-upload" v-if="!importData.length">

                    <h4 class="title">
                        {{ $translate('app-customers-import-how-to') }}
                    </h4>

                    <section class="type-select">
                        <h4>{{ $translate('app-customers-import-csv-prompt') }}</h4>
                        <button-partial @click="createCsv('person')">{{ $translate('app-customers-csv-person') }}</button-partial>
                        <button-partial @click="createCsv('company')">{{ $translate('app-customers-csv-company') }}</button-partial>
                        <section class="link-download-container">
                            <a v-if="csvLink" class="csv-link" :href="csvLink" :download="`${csvType}-import.csv`">
                                {{ $translate('app-customers-download-csv') }}
                            </a>
                        </section>
                    </section>
                    
                    <section class="upload">
                        <h4>{{ $translate('app-customers-import-csv-upload') }}</h4>
                        <label>
                            <input id="importCsvInput" type="file" @change="importCsv" />
                        </label>
                    </section>

                </section>

                <template v-if="importData.length">
                    <table class="importDataTable">
                        <thead>
                            <tr>
                                <th v-for="header in importHeaders" :key="header">
                                    {{ header }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in importData" :key="index" :class="[row.status]">
                                <td v-for="header in importHeaders" :key="header">

                                    <template v-if="header === 'tags'">
                                        {{ row[header].join(', ') }}
                                    </template>
                                    
                                    <template v-if="header !== 'tags' && header !== 'status'">
                                        {{ row[header] }}
                                    </template>

                                    <template v-if="header === 'status'">
                                        <template v-if="row.status === 'error'">
                                            <action-notice-partial :state="state" :errors="row.error"></action-notice-partial>
                                        </template>
                                        <template v-if="row.status === 'skipped'">
                                            <icons-partial type="info" title="skipped" />
                                        </template>
                                        <template v-if="row.status === 'success'">
                                            <icons-partial type="checked" />
                                        </template>
                                    </template>
                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>

            </section>


        <modal-module />

    </article>
</template>
<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import { CustomerImportResult } from '@lilaquadrat/interfaces';
import {
    Customers, DataObject,
} from '@lilaquadrat/studio/lib/interfaces';
import ImportData from '../interfaces/ImportData.interface';

@Component
export default class AppCustomersImportScreen extends ExtComponent {

    componentName = 'app-customers-company-import';

    csvLink: string | null = null;

    csvType: 'person' | 'company';

    importData: ImportData[] = [];

    importType: 'person' | 'company';

    importHeaders: string[] = [];

    importDone: boolean = false; 

    importStatistics = {
        success: 0,
        error: 0,
        skipped: 0,
    };

    get content(): DataObject<Customers[]> {

        return this.$store.state.AppCustomers.data || undefined;

    }

    reset() {

        this.csvLink = null;
        this.importData = [];
        this.importHeaders = [];
        this.importDone = false;
        this.importType = undefined;
        this.csvType = undefined;
    
    }

    async executeImport() {

        const importDataWithCompanyAndType = this.importData.map(data => ({
            ...data,
            company: this.$route.params.company,
            type: this.importType,
        }));
        const response = await this.$store.dispatch('AppCustomers/import', importDataWithCompanyAndType);

        this.updateImportDataWithResponse(response.data.responses, response.data.duplicateEmails);

        this.importDone = true;
        this.importStatistics = response.data.statistics;
        
    
    }

    updateImportDataWithResponse(responses: CustomerImportResult['responses'], duplicateEmails: CustomerImportResult['duplicateEmails']) {

        console.log('Updating import data with response:', responses, duplicateEmails);

        const tempImportData = [];

        this.importData.forEach((single) => {

            const tempData = single;

            if(duplicateEmails.includes(single.email as string)) {

                tempData.status = 'skipped';
                tempData.error = 'duplicate';
            
            } else {

                const response = responses.find((res) => res.email === single.email);
    
                if(response.error) {
    
                    tempData.status = 'error';
                    tempData.error = response.error;

                } else {
    
                    tempData.status = 'success';
    
                }
            
            }

            tempImportData.push(tempData);

        });

        this.importData = tempImportData;

    }

    async createCsv(type: 'person' | 'company') {

        const response = await this.$store.dispatch('AppCustomers/structure', type);
        const structure = response.data;

        console.log(structure);

        const headers = Object.values(structure).join(',');
        const blob = new Blob([headers], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        this.csvLink = url;
        this.csvType = type;

    }

    importCsv(event: Event) {

        const target = event.target as HTMLInputElement;

        if (!target.files || !target.files.length) {

            console.error('No file selected.');
            return;

        }

        const file = target.files[0];
        const reader = new FileReader();

        reader.onload = (ev: ProgressEvent<FileReader>) => {

            const text = ev.target?.result as string;
            const lines = text.split('\n')
                .map(line => line.trim())
                .filter(line => line !== '');

            if (lines.length === 0) {

                console.error('CSV file is empty.');
                return;

            }

            const headers = lines[0].split(',').map(h => h.trim());

            headers.push('status');

            const tagsIndex = headers.indexOf('tags');
            const emailConfirmendIndex = headers.indexOf('emailConfirmed');
            // when the vat header exists, we know it's a company import
            const isCompanyData = headers.includes('vat');

            this.importType = isCompanyData ? 'company' : 'person';

            const jsonData: ImportData[] = [];

            for (let i = 1; i < lines.length; i += 1) {

                const row = lines[i].split(',').map(item => item.trim());
                const rowObj: ImportData = {};

                headers.forEach((header, index) => {

                    let content: string | string[] | boolean = row[index] || '';

                    if(header === 'status') return;

                    if(index === tagsIndex) {

                        content = content.split(' ').map(tag => tag.toLowerCase().trim());

                    }

                    if(index === emailConfirmendIndex) {

                        content = content.toString().toLowerCase() === 'true';

                    }

                    rowObj[header] = content;

                });
                
                jsonData.push(rowObj);

            }

            console.log('CSV JSON:', jsonData);
            this.importData = jsonData;
            this.importHeaders = headers;

        };

        reader.onerror = (err) => {

            console.error('Error reading file:', err);

        };

        reader.readAsText(file);

    }

}
</script>
<style lang="less">
.app-customers-import-screen {

  display: grid;
  grid-template-rows: max-content 1fr;
  min-height: 70vh;

  header.main {
    .basePadding;
    .multi(padding-top, 4);
    .multi(padding-bottom, 4);

    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .scroll-container {

    display: grid;
    grid-template-rows: max-content;

    .type-select-upload {

      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 40px;
      align-self: center;
      justify-self: center;

      max-width: 650px;

      .title {
        grid-column-start: 1;
        grid-column-end: 3;
        text-align: center;
      }

      .type-select, .upload {
        display: grid;
        grid-template-rows: max-content 1fr;
        grid-template-columns: 1fr 1fr;
        gap: 20px 10px;
        align-items: start;

        h4 {
          grid-column-start: 1;
          grid-column-end: 3;
          text-align: center;
        }

        .link-download-container {
          grid-column-start: 1;
          grid-column-end: 3;
          height: @buttonLineHeight;
          text-align: center;

          .csv-link {
            .font-head;
            font-size: @headline_XS;
          }
        }

      }

    }

    .importDataTable {
      display: table;
      width: 100%;

      thead {
        position: sticky;
        top: 35px;
        left: 0;

        background-color: @grey1;
      }

      tr {

        td {
          text-align: center;

          &:first-child {
            text-align: left;
          }

          &:last-child {
            width: 40px;
            text-align: center;

            .icon-partial {
              display: inline-block;
            }
          }
        }

        // &.skipped {

        //   td {
        //     background-color: @grey;
        //   }

        // }

        // &.error {

        //   td {
        //     background-color: @error;
        //   }

        // }

        // &.success {

        //   td {
        //     background-color: @success;
        //   }

        // }

      }
    }

  }
}
</style>