<template>
  <article class="app-publish all extension" :class="{active: open}">

    <button-partial button-content-type="icon" icon="publish" size="large" :color-scheme="open ? 'colorScheme6' : 'transparent'" @click="toggle" />

    <overlay-background-partial v-if="open" @close="toggle">
      <add-publish-ext class="app-publish-all-container" :type="type" pre="all" :content-groups="contentGroups" :app="app" :app-model="appModel" :style="optionsStyle" />
    </overlay-background-partial>

  </article>
</template>
<script lang="ts">
import { Component, ExtComponent, Prop } from '@/libs/lila-component';
import { Publish, PublishContentGroup } from '@lilaquadrat/studio/lib/interfaces';

@Component
export default class AppPublishExtension extends ExtComponent {

  @Prop({ required: true, type: String }) type: Publish['type'];

  @Prop(Array) contentGroups: PublishContentGroup[];

  @Prop(String) app?: Publish['app'];

  @Prop(String) appModel?: Publish['model'];

  open: boolean = false;

  toggle() {

    this.open = !this.open;

  }

  get optionsStyle() {

    if (this.$store.state.media === 'mobile') return {};

    const element = this.$el as HTMLElement;
    const bounds = element.getBoundingClientRect();
    let top = bounds.top + element.offsetHeight;
    const body = document.querySelector('body');
    const maxHeight = body.offsetHeight * 0.3;
    const positionTop = top + maxHeight > body.offsetHeight;

    // eslint-disable-next-line no-unused-expressions
    this.$store.state.height;
    // eslint-disable-next-line no-unused-expressions
    this.$store.state.width;

    if (positionTop) {

      top = element.offsetTop - maxHeight;

    }

    return {
      top: `${top}px`,
      left: `${bounds.left + 35}px`,
    };

  }

}
</script>
<style lang="less" scoped>


.app-publish-all-container {

  top: 15%;
  display: grid;

  align-self: center;
  justify-self: center;
  overflow: hidden;
  width: calc(100% - 40px);

  min-width: @overlayMinWidth;
  border-top: solid 5px @color1;
  background-color: @white;
  box-shadow: 0 0 5px -3px @textColor;

  .multi(padding, 4);

  @media @tablet, @desktop {
    width: initial;
    box-shadow: 0 5px 5px @grey;
    transform: translateX(-100%);
  }

}

</style>
