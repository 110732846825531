import Models, { ModelDeclaration } from '@/libs/Models.class';
import AccordionElement from './AccordionElement.model';

export default interface Accordion {
  openOnStart: string
  multiOpen: boolean
  disableControls: boolean
  elements: AccordionElement[]
}

const declaration: ModelDeclaration<Accordion> = {
  openOnStart: { type: 'string' },
  multiOpen: { type: 'boolean', default: false },
  disableControls: { type: 'boolean', default: false },
  elements: { type: 'array', contains: { model: 'accordion-element' } },
};

Models.register('accordion', declaration);