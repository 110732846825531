<template>
  <section class="description" :class="{ inline }">
    <slot />
  </section>
</template>
<script lang="ts">
import { Prop } from '@/libs/lila-component';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class descriptionPartial extends Vue {

  @Prop(Boolean) inline: boolean;

}
</script>
<style lang="less" scoped>

.description {
  clear: both;

  .font-bold;
  color: @color1;
  font-size: @fontTextSmaller;
  text-align: left;
  text-transform: none;

  border-left: solid 2px @color1;

  .multi(padding, 0, 0, 0, 2);

  &.inline {
    padding: 0;
    border: 0;
  }

}
</style>
