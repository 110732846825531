import MainStore, { state } from '@/store/main.store';
import GenericStore from '@/interfaces/GenericStore.interface';
import StudioSDK from '@/libs/StudioSDK';
import BaseCallData from '@/interfaces/BaseCallData.interface';
import AppCustomersExtensionState from '../interfaces/extension-state.interface';

const AppCustomersExtensionStore: GenericStore<AppCustomersExtensionState> = {

  sdkOptions() {

    return {
      authToken: state.authToken,
      customEndpoints: MainStore.getters.endpointsSDK,
      ...MainStore.getters.companyProject,
    };

  },

  namespaced: true,
  strict: true,

  state: {

    data: {},
    search: {
      tags: [],
      filterState: null,
      search: null,
      type: null,
      sort: '_id',
      order: '1',
    },

    site: null,
  },

  mutations: {

    data(appCustomersState, data) {

      appCustomersState.data = data;

    },

    setSearch(appCustomersState, search: { tags?: string[], search?: string }) {

      if (search.tags) {

        if (typeof search.tags === 'string') {

          appCustomersState.search.tags = [search.tags];

        } else {

          appCustomersState.search.tags = search.tags;

        }

      } else {

        appCustomersState.search.tags = [];

      }

      if (search.search) {

        appCustomersState.search.search = search.search;

      } else {

        delete appCustomersState.search.search;

      }

    },

    updateSearch(appCustomersState, update: { data: any, type: string }) {

      console.log(update, update.data.length, !update.data.length, appCustomersState.search);

      if (!update.data.length) {

        appCustomersState.search[update.type] = null;
        return;

      }

      console.log(update, appCustomersState.search);

      appCustomersState.search[update.type] = update.data;
    
    },

    setSite(appCustomersState, site: number) {

      appCustomersState.site = site || 0;

    },

    // toggleFilter(appEditorState, active: boolean) {

    //   appEditorState.filterActive = active;

    // },

  },

  actions: {

    get(store, data: { settings: BaseCallData, query?: any, site?: any }) {

      const sdk = new StudioSDK('customers-app', AppCustomersExtensionStore.sdkOptions());

      return sdk.customers.list(
        +(data?.site || 1), 
        data?.query?.search, 
        data?.query?.tags, 
        data?.query?.type, 
        data?.query?.sort, 
        data?.query?.order
      );

    },

  },

};

export default AppCustomersExtensionStore;
