import { Call } from '@/libs/lila-call';
import { Auth } from '@/libs/lila-auth';
import MainStore from '@/store/main.store';
import GenericStore from '@/interfaces/GenericStore.interface';
import { ListOfModels } from '@lilaquadrat/studio/lib/interfaces';
import AppHistoryMainState from '../interfaces/main-state.interface';

const AppHistoryStore: GenericStore<AppHistoryMainState> = {
  namespaced: true,
  strict: true,

  state: {
    settings: null,
    data: null,
    single: null,
    cache: null,
    details: null,
    preview: null,
  },

  mutations: {

    data(state, data) {

      state.data = data;

    },

    single(state, single) {

      state.single = single;

    },

    cache(state, data) {

      state.cache = JSON.stringify(data);

    },

    details(state, version: number) {

      state.details = version;

    },

    preview(state, version: number) {

      state.preview = version;

    },

  },

  actions: {

    list(store, data) {

      if (store.state.cache === JSON.stringify(data)) return new Promise((resolve) => resolve({ r: store.state.data }));

      const call = new Call<ListOfModels<History>>('history', MainStore.getters.endpoints);
      const queryArray = ['history', data.company];

      if (data.project) {

        queryArray.push(data.project);

      }

      queryArray.push(...['list', data.site || 1]);

      return call
        .get('api', `/${queryArray.join('/')}`, data.query, Auth.getAuthHeader())
        .then((response) => {


          store.commit('cache', data);
          return response;

        });

    },

    single(store, data) {

      if (store.state.single?.history?.version === data.version && store.state.single.id === data.id) return new Promise((resolve) => resolve(true));

      const call = new Call<History>('history', MainStore.getters.endpoints);
      const queryArray = ['history', data.company];

      if (data.project) {

        queryArray.push(data.project);

      }

      queryArray.push('single', data.id, data.version);

      return call
        .get('api', `/${queryArray.join('/')}`, data.query, Auth.getAuthHeader())
        .then((response) => store.commit('single', response.r));

    },
    // getSettings(store, data) {

    //   console.log('GET SETTINGS', store.state.settings?.app);

    //   const call = new Call<any>(MainStore.getters.endpoints);

    //   return call.get('api', `/publish/${data.company}/${data.project}/settings`, {}, Auth.getAuthHeader())
    //     .then((settings) => store.commit('settings', settings.r?.settings));

    // },

    // settings(store, data) {

    //   const call = new Call(MainStore.getters.endpoints);

    //   return call.put('api', `/publish/${data.company}/${data.project}/settings`, data.settings, Auth.getAuthHeader())
    //     .then(() => store.dispatch('getSettings', data))
    //     .then((settings) => store.commit('settings', settings.r));

    // },

    activate(store, params) {

      // if (!store.state.settings?.app) {

      //   return store.dispatch('getSettings', params);

      // }

      return new Promise((resolve) => resolve(true));

    },

  },

};

export default AppHistoryStore;
