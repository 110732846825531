import Models, { ModelDeclaration } from '@/libs/Models.class';
import { EmailsContent, ListCategory, ListContent } from '@lilaquadrat/interfaces';
import Agreements from './agreements.model';


export default interface AppListsList {
  name: string
  description: string
  state: string
  mode: string
  categories: ListCategory[];
  agreements: Agreements[]
  emails: EmailsContent
  content: Pick<ListContent, 'paid'>
}

const declaration: ModelDeclaration<AppListsList> = {
  name: { type: 'string' },
  description: { type: 'string' },
  state: { type: 'string' },
  mode: { type: 'string' },
  categories: { type: 'array', contains: { model: 'app-lists-categories' } },
  agreements: { type: 'array', contains: { model: 'app-lists-agreements' } },
  content: { model: 'app-lists-content-shopping' },
  emails: { model: 'app-lists-emails' },
};


Models.register('app-lists-list-shopping', declaration);