<template>
  <section class="index-module-editor open editor" :class="{ changed: safeContent.changed }">
    <module-editor-header-partial v-model="content" :position="position" :index="index" :type="type" @move="move($event)" />
    <crumbs-partial :type="safeContent.type" @clearCrumbs="unsetCurrentChild" />

    <section :class="{ optionsVisible: !child }" class="editor-module-container">

      <link-list-partial-editor v-if="!child" v-model="safeContent.links" />

      <module-options-partial
        v-if="child === 'options' || !child"
        v-model="safeContent"
        :available-variants="availableVariants"
        :selected-variants="safeContent.variant"
        @setChild="setChild('options', true)"
        @unsetChild="setChild('options')"
        @remove="remove()"
        @clone="clone()"
      />

    </section>
  </section>
</template>
<script lang="ts">
import { Component, ModuleEditorComponent } from '@/libs/ModuleEditorComponent';
import IndexModule from '@/apps/editor/models/IndexModule.model';

@Component
export default class IndexModuleEditor extends ModuleEditorComponent<IndexModule> {

  model = 'index-module';

}

</script>
