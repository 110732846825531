<template>
  <article class="app-publish-single-screen" :class="navigationAnimation">
    <side-screen-header-partial>Publish from {{$date(content.history.created)}}</side-screen-header-partial>
    <section class="content-container">

      <data-grid-partial class="content">
        <section class="data-grid-container">
          <section class="data-grid-single full">
            <h4>{{$translate('app-publish-state')}}</h4>
            <p>{{$translate(`app-publish-state-${content.state}`)}}</p>
            <notice-partial type="notice">{{$translate(`app-publish-state-${content.state}-description`)}}</notice-partial>
          </section>
          <section class="data-grid-single">
            <h4>{{$translate('app-publish-app')}} - {{$translate('app-publish-model')}}</h4>
            <p>{{$translate(content.appPublish)}} {{$translate(content.modelPublish)}}</p>
          </section>
          <section class="data-grid-single">
            <h4>{{$translate('app-publish-type')}}</h4>
            <p>{{$translate(content.type)}}</p>
          </section>
          <section class="data-grid-single">
            <h4>{{$translate('app-publish-created')}}</h4>
            <p>{{$date(content.history.created, null, true)}} - {{$date(content.history.created)}}</p>
          </section>
          <section class="data-grid-single">
            <h4>{{$translate('app-publish-planned')}}</h4>
            <p v-if="content.planned">{{$date(content.planned, null, true)}} - {{$date(content.planned)}}</p>
            <p v-if="!content.planned">{{$translate('app-publish-immediately')}}</p>
          </section>
          <section class="data-grid-single">
            <h4>{{$translate('app-publish-method')}}</h4>
            <p>
              <template v-if="content.publishMethod">
                via {{ content.publishMethod.label }} ({{$translate(`app-publish-method-type-${content.publishMethod.type}`)}})
              </template>
              <template v-if="!content.publishMethod">
                via {{ content.method }}
              </template>
            </p>
          </section>
          <section class="data-grid-single">
            <h4>{{$translate('app-publish-pre')}}</h4>
            <p>
              <template v-if="content.contentGroup">{{$translate(`app-publish-${content.contentGroup}`)}}</template>
              <template v-if="content.contentGroup === 'category' && content.category">"{{content.category.split(':')[1]}}"</template>
            </p>
          </section>
          <section class="data-grid-single full">
            <h4>{{$translate('app-publish-actions')}}</h4>
            <button-group-partial left>
              <button-partial v-if="content.state === 'planned' || content.state === 'inQueue'" v-model="cancelState" doublecheck @confirmed="cancel(content._id)">{{$translate('app-publish-cancel')}}</button-partial>
            </button-group-partial>
          </section>

        </section>
        <section v-if="content.result" class="data-grid-container">
          <header>
            <h3>{{$translate('app-publish-result')}}</h3>
          </header>
          <table class="data-grid-single full">
            <h4>{{$translate('app-publish-result-statistics')}}</h4>
            <p>{{ content.statistics.affected }} {{$translate('app-publish-result-affected-success')}} {{ content.statistics.successPercent }}% {{$translate('app-publish-result-succesful')}}</p>
          </table>
          <table class="data-grid-single full table">
            <tr v-for="(single, index) in content.result" :key="`result-${index}`">
              <td><router-link :to='{ params: { id: single.id }, name: content.type === "editor" ? "app-editor-project-edit" : `app-${content.app}-project-edit` }'>{{single.id}} {{single.name}}</router-link></td>
              <td>{{single.error}} {{$translate(single.message)}}</td>
              <td>{{$translate(single.state)}}</td>
            </tr>
          </table>
        </section>
      </data-grid-partial>


    </section>
  </article>
</template>

<script lang="ts">
import { ExtComponent, Component } from '@/libs/lila-component';
import StudioSDK, { SDKResponse } from '@/libs/StudioSDK';
import { Publish } from '@lilaquadrat/studio/lib/interfaces';
import MainStoreState from '@/store/mainStoreState.interface';
import { Dictionary } from 'vue-router/types/router';
import { Store } from 'vuex';

@Component
export default class AppEditorSettingsScreen extends ExtComponent {

  componentName = 'app-publish-project-single';

  cancelState = '';

  get content(): Publish {

    return this.$store.state.AppPublish.single;

  }

  cancel(_id: string) {

    console.log('cancel pls');
    this.$store.dispatch('AppPublish/cancel', {
      _id,
      settings: {
        ...this.$store.getters.companyProject,
      },
    })
      .then(() => {

        this.cancelState = 'success';
        StudioSDK.flushId(_id);

        return this.$store
          .dispatch('AppPublish/single', {
            _id,
            settings: {
              ...this.$store.getters.companyProject,
            },
          });

      })
      .then((preloadedData: SDKResponse<Publish>) => {

        this.$store.commit('AppPublish/single', preloadedData.data);

      })
      .catch(() => {

        this.cancelState = 'error';

      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPre(params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    return store
      .dispatch('AppPublish/single', {
        _id: params.id,
        settings: {
          project: params.project,
          company: params.company,
        },
      });

  }

  // eslint-disable-next-line class-methods-use-this
  preloadDataPost(preloadedData: SDKResponse<Publish>, params: Dictionary<string>, query: Dictionary<string | string[]>, store: Store<MainStoreState>) {

    store.commit('AppPublish/single', preloadedData.data);
    return Promise.resolve();

  }


}
</script>
<style lang="less">


.app-publish-single-screen {


}
</style>
