<template>
  <article :class="{ visible: !backLinkVisible }" class="modal-module">
    <router-link class="go-back-link" :to="parentLink" />
    <router-view :key="`modal-module-${$route.params.link}`" class="side-screen" />
    <router-view :key="`modal-module-${$route.params.link}`" class="side-screen" name="modal" />
    <router-view name="children" class="screen" />
  </article>
</template>
<script lang="ts">
import { Component, ExtPartial } from '@/libs/lila-partial';


@Component
export default class ModalModule extends ExtPartial {

  get backLinkVisible(): boolean {

    return (
      this.$route.matched[this.$route.matched.length - 1].meta.parent === true
    );

  }

  get parent() {

    return this.$route.matched.find((link) => link.meta.parent === true);

  }

  get parentLink() {

    return this.$route.matched.find((link) => link.meta.parent === true);

  }

}
</script>

<style lang="less" scoped>

.integratedOpen {

  .modal-module {
    width: calc(100% - 650px);
  }
}

.modal-module::v-deep  {
  position: fixed;

  top: 0;
  left: 0;

  display: grid;
  grid-template-columns: 100%;
  width: @moduleWidth_Full;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);

  opacity: 0;
  pointer-events: none;

  .index(10);

  .inited & {
    transition: opacity @aTime @aType, transform @aTime @aType;

    &.visible {
      grid-template-columns: 1fr 100%;
      opacity: 1;
      pointer-events: all;

      @media @tablet, @desktop {
        grid-template-columns: 1fr 600px;
      }
    }
  }

  .ismock & {
    transition: none;
    
    .side-screen {
      
      transition: none;
      .content-container {
        transition: none;
      }
    }

    &.visible {
      grid-template-columns: 1fr 100%;
      opacity: 1;
      pointer-events: all;

      @media @tablet, @desktop {
        grid-template-columns: 1fr 600px;
      }
    }
  }

  .go-back-link {
    display: grid;

    width: 100%;
    height: 100vh;

    cursor: pointer;

    -webkit-user-drag: none;
  }

  .modal-crumbs {
    height: 40px;
    background-color: @grey1;
    line-height: 40px;
    // .multi(padding, 0, 8);
    .basePadding;

    a {
      position: relative;
      .multi(padding, 0, 1);

      &:first-child {
        padding-left: 0;
      }

      & + a {
        .multi(margin-left, 2);

        &:before {
          content: '›';
          position: absolute;
          top: 0;
          left: -7px;
          display: block;
          color: @color3;
          font-size: 10px;
          line-height: 14px;
        }
      }
    }
  }

  .noHover {
    pointer-events: none;
  }

  .liPaths {
    font-size: @fontTextSmaller;
    text-transform: uppercase;
  }

  .side-screen {
    display: grid;

    grid-template-rows: max-content 1fr;

    overflow: hidden;
    width: 100%;
    height: 100vh;
    background-color: @white;

    transition: opacity @aTime @aType, transform @aTime @aType;

    &.started {
      opacity: 0;
      transition-delay: .2s;
      transform: translateX(50px);
    }

    &.resolving,
    &.loading {
      opacity: 0;
      transition-delay: .2s;
      transform: translateX(50px);
    }

    //disable all animation for side screen child views when side screen is animating

    &.started,
    &.resolving,
    &.loading {

      .content-container {

        &.started,
        &.resolving,
        &.loading {
          opacity: 1;
          transition: none;
          transform: translateY(0);
        }
      }
    }

    &.no-navigation-animation {
      opacity: 1;
      transform: none;
    }

    .content-container {

      overflow-y: auto;
      transition: opacity @aTime @aType, transform @aTime @aType;

      &.started {
        opacity: 0;
        transition-delay: @aTime;
        transform: translateY(2%);
      }

      &.resolving,
      &.loading {
        opacity: 0;
        transform: translateY(-2%);
      }

      .content {
        .basePaddingWithTop;

        &.pagenatorHead {
          display: grid;
          grid-template-columns: max-content 1fr;
          justify-items: end;
        }

        &.sections {
          display: grid;
          gap: 40px;
        }
      }

      .pagenator-sidescreen {

        table {

          .button {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
