import Models, { ModelDeclaration } from '@/libs/Models.class';
import Link from './Link.model';

export default interface LinkGroupElement {
  text: string
  event: string
  link: string
  icon: string
  classes: string[]
  attributes: string[]
  variant: string[]
  external: boolean
  links: Link[]
}

const declaration: ModelDeclaration<LinkGroupElement> = {
  text: { type: 'string' },
  event: { type: 'string' },
  link: { type: 'string' },
  icon: { type: 'string' },
  links: { type: 'array', contains: { model: 'link' } },
  classes: { type: 'array', contains: { type: 'string' } },
  attributes: { type: 'array', contains: { type: 'string' } },
  variant: { type: 'array', contains: { type: 'string' } },
  external: { type: 'boolean', default: false },
};

Models.register('linkgroup-element', declaration);
