import Models, { ModelDeclarationExtended, ModelOptions } from '@/libs/Models.class';
import FooterContact from '@/models/FooterContact.model';
import FooterSitemap from '@/models/FooterSitemap.model';
import FooterSocial from '@/models/FooterSocial.model';
import ListLink from '@/models/ListLink.model';
import ListString from '@/models/ListString.model';
import Module from '@/models/Module.model';
import Textblock from '@/models/Textblock.model';
import Picture from '../interfaces/picture.interface';

export default interface FooterModule extends Module {
  legal: string
  contact: FooterContact
  social: FooterSocial
  sitemap: FooterSitemap[]
  list: ListString
  links: ListLink
  textblock: Textblock
  picture: Picture
}

const declaration: ModelDeclarationExtended<FooterModule, Module> = {
  legal: { type: 'string' },
  contact: { model: 'footer-contact' },
  social: { model: 'footer-social' },
  sitemap: { type: 'array', contains: { model: 'footer-sitemap' } },
  textblock: { model: 'textblock' },
  links: { model: 'list-link' },
  list: { model: 'list-string' },
  picture: { model: 'picture-partial' },
};
const options: ModelOptions = {
  uuid: true,
  nameAsType: true,
  extends: 'module'
};

Models.register('footer-module', declaration, undefined, options);