export default {
  'app-payment': 'Zahlungen',
  'app-payment-tests': 'Testzahlungen',
  'app-payment-tests-customer-name': 'Testkunde',
  'app-payment-tests-customer-id': 'TT0001P',
  'app-payment-title-customer': 'Kunde',
  'app-payment-title-amount': 'Betrag',
  'app-payment-title-state': 'Status',
  'app-payment-title-date': 'Datum',
  'app-payment-state-paid': 'bezahlt',
  'app-payment-customer': 'Kunde',
  'app-payment-source': 'Quelle',
  'app-payment-provider': 'Anbieter',
  'app-payment-created': 'Datum',
  'app-payment-customer-link': 'anzeigen',
  'app-payment-providers': 'Zahlungsanbieter',
  'app-payment-providers-overview': 'Übersicht',
  'app-payment-providers-home': 'Übersicht',
  'app-payment-providers-add': 'Anbieter konfigurieren',
  'app-payment-providers-type': 'Anbieter',
  'app-payment-providers-create-button': 'Anbieter konfigurieren',
  'app-payment-providers-remove-button': 'entfernen',
  'app-payment-providers-edit': 'Anbieter bearbeiten',
  'app-payment-providers-edit-button': 'Anbieter bearbeiten',
  'app-payment-providers-type-shopify': 'Shopify',
  'app-payment-providers-type-stripe': 'Stripe',
  'app-payment-providers-label': 'Label',
  'app-payment-providers-active': 'Aktiv',
  'app-payment-providers-shopify-validation-key': 'Webhook Validation Key',
  'app-payment-providers-stripe-token': 'API Token',
  'app-payment-providers-stripe-validation-key': 'Webhook Validation Key',
};
